import routesName from "../constants/routesName";
import sideBarName from "../constants/sidebar";
import {
  DevicesIcon,
  FeedbackIcon,
  GridIcon,
  HospitalIcon,
  LoginIcons,
  NotificationsIcon,
  PersonIcon,
} from "../components/muiIconsImport";

export const sideBarData = [
  {
    name: sideBarName.mis,
    path: routesName.managementInfoSystemPage,
    anotherPath: routesName.managementInfoSystemPage,
    icon: GridIcon,
  },
  {
    name: sideBarName.hospitalManagement,
    path: routesName.hospitalManagementPage,
    anotherPath: routesName.hospitalManagementPage,
    icon: HospitalIcon,
  },
  {
    name: sideBarName.accountManagers,
    path: routesName.accountManagerPage,
    anotherPath:  routesName.accountManagerPage,
    icon: PersonIcon,
  },
  {
    name: sideBarName.Notification,
    path: routesName.notificationPage,
    anotherPath: routesName.notificationPage,
    icon: NotificationsIcon,
  },
  {
    name: sideBarName.Devices,
    path: `${routesName.devicesPage}/${routesName.alldevice}`,
    anotherPath: `${routesName.devicesPage}`,
    icon: DevicesIcon,
  },
  {
    name: sideBarName.Logins,
    path: routesName.loginsDetailsPage,
    anotherPath:routesName.loginsDetailsPage,
    icon: LoginIcons,
  },

  {
    name: sideBarName.Feedback,
    path: routesName.feedbackPage,
    anotherPath: routesName.feedbackPage,
    icon: FeedbackIcon,
  },
];
