import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import { AddAccountManager } from "./IaccountManager";
import { AxiosError } from "axios";
import { UserAuditInfoType } from "../../modals/IuserAuditInfo";
import routesName from "../../constants/routesName";

// add account manager
export const addAccountManager = createAsyncThunk(
  "post/add-account-manager",
  async (data: AddAccountManager, { rejectWithValue }) => {
    try {
      const response = await API.post(
        "AccountManager/AccountManager/Add",
        data
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// get permissions of user
export const getPermissions = createAsyncThunk(
  "get/permissions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        "AccountManager/AccountManagers/RolePermissions"
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// get all account manager
export const getAllAccontManager = createAsyncThunk(
  "get/all-accountManager",
  async (
    data: {
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `AccountManager/AccountManagers?order=${data.order}&orderBy=${
          data.orderBy
        }${data.searchQuery ?? ""}&page=${data.page + 1}&pageSize=${
          data.rowsPerPage
        }&searchKeywords=${data?.searchKeywords}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// get account manager
export const getAccontManager = createAsyncThunk(
  "get/accountManager",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `AccountManager/AccountManager/Id?userid=${id}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// update account manager
export const updateAccontManager = createAsyncThunk(
  "update/accountManager",
  async (data: AddAccountManager, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `AccountManager/AccountManager/Update`,
        data
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// get hospital data by account manager linked
export const getHospitalsLinkedAccManager = createAsyncThunk(
  "get/hospital-linked-to-accountManager",
  async (
    data: {
      userId: number;
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `AccountManager/AccountManager/Hospitals?userid=${data?.userId}&order=${
          data.order
        }&orderBy=${data.orderBy}${data.searchQuery ?? ""}&page=${
          data.page + 1
        }&pageSize=${data.rowsPerPage}&searchKeywords=${data?.searchKeywords}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// get status wise hospital data by account manager linked
export const getStatusWiseHospitalsLinkedAccManager = createAsyncThunk(
  "get/status-wise-hospital-linked-to-accountManager",
  async (
    data: {
      userId: number;
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords: string;
      status: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `AccountManager/AccountManager/Hospitals/Status?userid=${
          data?.userId
        }&status=${data.status}&order=${data.order}&orderBy=${data.orderBy}${
          data.searchQuery ?? ""
        }&page=${data.page + 1}&pageSize=${data.rowsPerPage}&searchKeywords=${
          data?.searchKeywords
        }`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// get hopsital list

export const getHospitalsIdList = createAsyncThunk(
  "get/hospitals-id-list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `AccountManager/AccountManagers/HospitalsDropdownList`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// activate deactivate account manager user

export const activateDeactiveAccManager = createAsyncThunk(
  "active-deactivate/account-manager",
  async (data: { userId: number; isActive: number }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `AccountManager/AccountManager/ActivateOrDeActivate?userid=${data.userId}&isactive=${data.isActive}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

//  delete account manager user
export const deleteAccManager = createAsyncThunk(
  "delete/account-manager",
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `AccountManager/AccountManager/delete/id?userid=${userId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

//  delete account manager user
export const linkHospitalToAccManager = createAsyncThunk(
  "link/hosiptal-to-account-manager",
  async (
    data: { userId: number; hospitalId: string[]; isToBeLinked?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.post(
        `AccountManager/AccountManager/LinkOrDeLink?userid=${data?.userId}&isToBeLinked=${data?.isToBeLinked}`,
        data?.hospitalId
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// resest password

//  delete account manager user
export const resetPasswordAccManager = createAsyncThunk(
  "reset/account-manager-password",
  async (
    data: {
      email: string;
      password: string;
      isTempPassword: boolean;
      userAuditInfo: UserAuditInfoType;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.post(`Profile/passwordreset`, data);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

//get user audit data by account amnager linked
export const getUserAuditLinkedAccManager = createAsyncThunk(
  "get/Audit-linked-to-accountManager",
  async (
    data: {
      userId: number;
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/UserAudit/getAudit?userid=${data.userId}&order=${
          data.order
        }&orderBy=${data.orderBy}${data.searchQuery ?? ""}&page=${
          data.page + 1
        }&pageSize=${data.rowsPerPage}&searchKeywords=${data?.searchKeywords}`
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);
