import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import routesName from "../../constants/routesName";
import useDisplayToggle from "../../utils/useDisplayToggle";
import { useAppDispatch } from "../../redux/store";
import { getHospitalById } from "../../redux/addHospital/hospitalManagementReducer";
import successToast from "../../utils/successToast";
import errorToast from "../../utils/errorToast";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import {
  LinkIcon,
  MailOutlineIcons,
  MoreOptionIcon,
  PersonIcon,
  PhoneEnabledIcons,
} from "../../components/muiIconsImport";
import StatusWithColor from "../../utils/StatusWithColor";
import { removeBedSize, removeUnderScore } from "../../helper";
import { BootstrapTooltip } from "../../utils/toolTip";
import ConfirmationModal from "../../components/modals/confirmationModal";
import LogoutIcon from "../../assests/icons/logoutIcon.svg";
import { resetLinkHospitalToAccManagerState } from "../../redux/accountManager/accountManagerSlice";
import AccountManagerViewAllModal from "../../components/hospitalManagement/accountManagerViewAllModal";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import useAxios from "../../hooks/useAxios";
import { hospitalApi } from "../../jsonData/apiPathData/hospital";
import AdminIcon from "../../assests/icons/adminIcon.svg";
import enums from "../../constants/enums";
import LinkAccountManagerModal from "../../components/hospitalManagement/linkAccountMangerModal";
import DefaultImage from "../../assests/images/defaultImage.svg";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";

const HospiatDetailsCard = ({
  hospitalBasicInfo,
  managementInfo,
  accountManagerInfo,
  hospitalAdminInfo,
  params,
}: any) => {
  const userDetails = AccessTokenDecoder();
  const [showOptions, setShowOptions] = useState(false);
  const [delinkModal, setDelinkModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [accManagerModal, setAccManagerModal] = useState(false);
  const [linkAccManagerModal, setLinkAccManagerModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const editHospitalFn = () => {
    navigate(
      `${routesName.hospitalManagementPage}/edit-hospital-info/${params?.id}`
    );
  };

  const resetHospitalFn = () => {
    navigate(
      `${routesName.hospitalManagementPage}/edit-hospital-info/${params?.id}`,
      {
        state: { action: "resetPassword" },
      }
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetLinkHospitalToAccManagerState());
    };
  }, []);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setShowOptions,
  });

  // delink account manager axios call
  const {
    data: delinkSuccess,
    error: delinkError,
    loading: delinkLoading,
    postFn: delinkFn,
    toggle: delinkToggle,
  } = useAxios({
    method: "post",
    path: `${hospitalApi.delinkAccountManger}userid=${hospitalBasicInfo?.accountManager?.userId}&isToBeLinked=0`,
    body: [params?.id],
  });

  useEffect(() => {
    if (delinkSuccess?.success) {
      setDelinkModal(false);
      dispatch(getHospitalById(params?.id));
      successToast("Delinked Successfully");
    } else if (delinkError) {
      errorToast("Something Went Wrong");
    }
  }, [delinkSuccess, delinkError, delinkToggle]);

  const viewAllAccountManagerFn = () => {
    setAccManagerModal(true);
  };

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${params?.id}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital deleted successfully");
      navigate(routesName.hospitalManagementPage);
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  return (
    <>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      <AccountManagerViewAllModal
        isVisible={accManagerModal}
        onClose={() => setAccManagerModal(false)}
        managementInfo={managementInfo}
        accountManagerInfo={accountManagerInfo}
        hospitalAdminInfo={hospitalAdminInfo}
      />
      <LinkAccountManagerModal
        isVisible={linkAccManagerModal}
        onClose={() => setLinkAccManagerModal(false)}
      />
      <ConfirmationModal
        isVisible={delinkModal}
        onClose={() => setDelinkModal(false)}
        onClick={() => delinkFn()}
        actionText={"De-Link"}
        title={"Are you sure you want to de-link the account manager? "}
        icon={LogoutIcon}
        isLoading={delinkLoading}
      />
      <div className="rounded-lg shadow-sm1 bg-white relative">
        <div className="grid grid-cols-12 items-center p-6">
          <div className="lg:col-span-1 col-span-2">
            <img
              src={
                hospitalBasicInfo?.logoURL
                  ? hospitalBasicInfo?.logoURL
                  : DefaultImage
              }
              height={"100px"}
              width={"100px"}
              className="rounded-full shadow-ex1"
            />
          </div>
          <div className="lg:col-span-11 col-span-10 w-full px-3 ">
            <div className="flex justify-between">
              <div>
                <span className="text-20 font-semibold text-primary">
                  {hospitalBasicInfo?.name}
                </span>
                <span className="ps-4">
                  <StatusWithColor
                    value={
                      hospitalBasicInfo?.status === enums.inactive
                        ? enums?.deactivated
                        : hospitalBasicInfo?.status
                    }
                  />
                </span>
              </div>
              <div className="absolute top-5 right-5">
                <button
                  className="text-blue-300 w-[24px] h-[24px]"
                  ref={onClickRef}
                  onClick={() => setShowOptions(!showOptions)}
                >
                  <MoreOptionIcon />
                </button>
              </div>
            </div>

            {showOptions && (
              <div
                className="absolute top-10 right-12 flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap"
                ref={insideClickRef}
              >
                <button
                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                  type="button"
                  onClick={() => {
                    editHospitalFn();
                  }}
                >
                  Edit
                </button>
                <button
                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold"
                  type="button"
                  onClick={() => {
                    resetHospitalFn();
                  }}
                >
                  Reset Password
                </button>
                <button
                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg"
                  type="button"
                  onClick={() => {
                    setIsDeleteModal(true);
                  }}
                >
                  {deleteLoading ? <LoadingSpinner /> : "Delete"}
                </button>
              </div>
            )}
            <div className="flex overflow-x-auto scroll_bar whitespace-nowrap pt-3">
              <span className="border-r-2 pe-2">
                <span className="font-normal text-14 text-secondary">
                  ID :{" "}
                </span>

                <span className="font-semibold text-14 text-primary">
                  {hospitalBasicInfo?.code}
                </span>
              </span>
              <span className="border-r-2 px-2">
                <span className="font-normal text-14 text-secondary">
                  Location :{" "}
                </span>
                <span className="font-semibold text-14 text-primary">
                  {hospitalBasicInfo?.city}
                </span>
              </span>
              <span className="border-r-2 px-2">
                <span className="font-normal text-14 text-secondary">
                  Speciality :{" "}
                </span>
                {hospitalBasicInfo?.speciality
                  ?.slice(0, 2)
                  .map((item: any, index: number) => (
                    <span
                      className="font-semibold text-14 text-primary"
                      key={index}
                    >
                      {item?.name}{" "}
                      {hospitalBasicInfo?.speciality?.length - 1 !== index
                        ? ","
                        : ""}
                    </span>
                  ))}
                {hospitalBasicInfo?.speciality?.length > 2 && (
                  <BootstrapTooltip
                    title={
                      <div className="p-1">
                        {hospitalBasicInfo?.speciality?.map(
                          (ser: any, index: number) => (
                            <div
                              className="text-secondary p-0.5 text-14 font-medium flex items-center gap-2"
                              key={index}
                            >
                              <span className="text-18">&#x2022;</span>
                              {ser?.name}
                            </div>
                          )
                        )}
                      </div>
                    }
                    placement="top"
                  >
                    <span className="text-blue-300 text-14 font-semibold cursor-pointer">
                      + {hospitalBasicInfo.speciality.length - 2} more
                    </span>
                  </BootstrapTooltip>
                )}
              </span>
              <span className="px-2">
                <span className="font-normal text-14 text-secondary">
                  Bed Size :{" "}
                </span>
                <span className="font-semibold text-14 text-primary">
                  {removeBedSize(hospitalBasicInfo?.bedSize)}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className="rounded-b-xl bg-blue-150">
          <div className="grid lg:grid-cols-2 gap-4 p-6">
            <div className="lg:border-r-2 flex flex-col gap-4 overflow-x-auto scroll_bar">
              <div className="flex justify-between pe-3">
                <span className="font-semibold text-18 text-primary">
                  Hospital Management
                </span>
                <button
                  className="text-blue-300 text-14 font-bold"
                  type="button"
                  onClick={() => viewAllAccountManagerFn()}
                >
                  View All
                </button>
              </div>
              <div className="flex items-center overflow-x-auto scroll_bar whitespace-nowrap text-secondary text-14 font-medium">
                <span className="border-r-2 pe-2 flex items-center gap-2 ">
                  <PersonIcon />
                  {managementInfo?.[0]?.name}
                </span>
                <span className="border-r-2 px-2 flex items-center gap-2">
                  <PhoneEnabledIcons />
                  +91 {managementInfo?.[0]?.phone}
                </span>
                <span className="px-2 flex items-center gap-2">
                  <MailOutlineIcons />
                  {managementInfo?.[0]?.email}
                </span>
              </div>
            </div>
            {/* {accountManagerInfo?.name && ( */}
            <div className="flex flex-col gap-4 overflow-x-auto scroll_bar">
              <div className="flex justify-between ">
                <span className="font-semibold text-18 text-primary">
                  Account Manager
                </span>
                {accountManagerInfo?.name ? (
                  <button
                    className={`${
                      userDetails?.Roles === enums.Admin
                        ? "text-disabled"
                        : "text-blue-300"
                    } text-14 font-bold flex items-center gap-2`}
                    type="button"
                    onClick={() => setDelinkModal(true)}
                    disabled={userDetails?.Roles === enums.Admin}
                  >
                    <LinkIcon /> Delink Manager
                  </button>
                ) : (
                  <button
                    className={`${
                      userDetails?.Roles === enums.Admin
                        ? "text-disabled"
                        : "text-blue-300"
                    } text-14 font-bold flex items-center gap-2`}
                    type="button"
                    onClick={() => setLinkAccManagerModal(true)}
                    disabled={userDetails?.Roles === enums.Admin}
                  >
                    <LinkIcon /> Link Manager
                  </button>
                )}
              </div>
              <div className="flex items-center overflow-x-auto scroll_bar whitespace-nowrap text-secondary text-14 font-medium">
                <span className="border-r-2 pe-2 flex items-center gap-2 ">
                  <PersonIcon />
                  {accountManagerInfo?.name}
                </span>
                <span className="border-r-2 px-2 flex items-center gap-2">
                  <img src={AdminIcon} />
                  {accountManagerInfo?.roleName}
                </span>
                <span className="px-2 flex items-center gap-2">
                  <PhoneEnabledIcons />
                  +91 {accountManagerInfo?.phone}
                </span>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HospiatDetailsCard;
