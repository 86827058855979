import { useState } from "react";
import DynamicTable from "../../../components/tables/dynamicTable";
import { TableBody, TableCell, TableRow } from "@mui/material";
import SearchInput from "../../../components/forms/searchInput";
import SelectComponent from "../../../components/forms/selectComponent";
import DefaulthospitalLogo from "../../../assests/images/default-hospital-logo.png";
import StatusWithColor from "../../../utils/StatusWithColor";
import enums from "../../../constants/enums";
import { TableFilterData } from "../../../modals/Itables";

const AcrossHospitalsDepartments = ({
  data,
  dataLength,
  loading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setorder,
  orderBy,
  setOrderBy,
  filter,
  setFilter,
  setAllSearch,
}: {
  data: any;
  dataLength: number;
  loading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  order: string;
  setorder: React.Dispatch<React.SetStateAction<string>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  filter: TableFilterData;
  setFilter: React.Dispatch<React.SetStateAction<TableFilterData>>;
  setAllSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const styles = {
    // borderLeft: "1px solid #F2F4F7",
    borderRight: "1px solid #F2F4F7",
    borderBottom: "1px solid #F2F4F7",
    background: "white",
    minWidth: "150px",
    maxWidth: "150px",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#667085",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
    height: "70px",
  };
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cases, setCases] = useState("all");

  const casesOption = [
    {
      label: "All Cases",
      value: "all",
    },
    {
      label: "Top 5",
      value: 5,
    },
    {
      label: "Top 10",
      value: 10,
    },
  ];

  const totalCases = data?.totalNos;

  const sortedData = data?.cmCasesAndDiagnosesSummary?.map((item: any) => {
    const sortedModels = [...item?.cMCasesAndDiagnosisBaseModels].sort(
      (a: any, b: any) => b?.totalCount - a?.totalCount
    );
    return { ...item, cMCasesAndDiagnosisBaseModels: sortedModels };
  });

  const casesNoShow = sortedData?.[0]?.cMCasesAndDiagnosisBaseModels?.length;

  const nameOdSpecility = sortedData?.[0]?.cMCasesAndDiagnosisBaseModels
    ?.map((item: any) => item)
    ?.slice(0, cases === "all" ? casesNoShow : parseInt(cases));

  const labelData = [
    {
      label: "Hospital",
      value: "name",
      width :"250px",
    },
    {
      label: "Status",
      value: "status",
      search: false,
      width :"150px",
    },
    {
      label: "Location",
      value: "city",
      width :"180px",
    },
    {
      label: "Departments",
      value: "deparments",
      sort: false,
      search: false,
      colSpan: cases === "all" ? casesNoShow : parseInt(cases),
    },
  ];

  const TblBodyDepartments = ({ labelData, data }: any) => {
    return (
      <TableBody>
        <TableRow className="">
          <TableCell
            style={{
              ...styles,
              minWidth: "250px",
              maxWidth: "250px",
              position: "sticky",
              left: "0px",
              height: "20px",
              borderRight: "",
            }}
          ></TableCell>
          <TableCell
            style={{
              ...styles,
              minWidth: "150px",
              maxWidth: "150px",
              position: "sticky",
              left: "250px",
              height: "20px",
              borderRight: "",
            }}
          ></TableCell>
          <TableCell
            style={{
              ...styles,
              minWidth: "180px",
              maxWidth: "180px",
              position: "sticky",
              left: "400px",
              height: "20px",
            }}
          ></TableCell>
          {nameOdSpecility?.map((specialty: any, index: any) => (
            <TableCell
              key={index}
              style={{
                ...styles,
                // textWrap: "nowrap",
                color: "#212121",
                fontWeight: 600,
                height: "20px",
              }}
            >
              <span className="whitespace-nowrap">{specialty?.name}</span>
              <div>
                {" "}
                {`(${specialty?.totalCount} | ${(
                  (specialty?.totalCount / totalCases) *
                  100
                ).toFixed(0)}%)`}
              </div>
            </TableCell>
          ))}
        </TableRow>
        {data?.map((item: any, index: any) => {
          return (
            <TableRow key={index}>
              <TableCell
                style={{
                  ...styles,
                  minWidth: "250px",
                  maxWidth: "250px",
                  position: "sticky",
                  left: "0px",
                }}
              >
                <div className={`grid grid-cols-5 items-center cursor-pointer`}>
                  <div className="col-span-1 ">
                    <img
                      src={item?.logo ?? DefaulthospitalLogo}
                      className="rounded-full w-[36px] h-[36px] border"
                      alt="hospital-logo"
                    />
                  </div>

                  <div className="col-span-4 ps-2">
                    <div>
                      <span className="text-blue-300 text-16 font-semibold">
                        {item?.hospitalName}
                      </span>
                    </div>

                    <span className="text-secondary text-14 font-medium">
                      ID: {item?.hospitalCode}
                    </span>
                  </div>
                </div>
              </TableCell>
              <TableCell
                style={{
                  ...styles,
                  minWidth: "150px",
                  maxWidth: "150px",
                  position: "sticky",
                  left: "250px",
                }}
              >
                <StatusWithColor
                  value={
                    item?.hospitalStatus === enums.inactive
                      ? enums?.deactivated
                      : item?.hospitalStatus
                  }
                  subValue={item?.productStatus}
                />
              </TableCell>
              <TableCell
                style={{
                  ...styles,
                  minWidth: "180px",
                  maxWidth: "180px",
                  position: "sticky",
                  left: "400px",
                }}
              >
                {item?.hospitalLocation}
              </TableCell>
              {nameOdSpecility?.map((specialty: any, index: any) => {
                const specialtyData = item?.cMCasesAndDiagnosisBaseModels?.find(
                  (model: any) => model.name === specialty?.name
                );
                return (
                  <TableCell
                    key={index}
                    style={{
                      ...styles,
                      minWidth: "250px",
                      maxWidth: "250px",
                    }}
                  >
                    {specialtyData
                      ? `${specialtyData.individualCount} cases | ${(
                          (specialtyData.individualCount /
                            specialty?.totalCount) *
                          100
                        ).toFixed(0)}%`
                      : "-"}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };
  return (
    <div className="">
      <div className="flex justify-between px-6 pb-6">
        <div>
          <SearchInput setAllSearch={setAllSearch} />
        </div>
        <div>
          <SelectComponent
            label="Select"
            id="status"
            defaultValue={"all_cases"}
            value={cases}
            onChange={(e: any) => {
              setCases(e.target.value);
            }}
            options={casesOption}
          />
        </div>
      </div>
      <div className="h-[52vh]">
        <DynamicTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setorder={setorder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filter={filter}
          setFilter={setFilter}
          columnData={labelData}
          status={loading}
          errorMessage={errorMessage}
          dataLength={dataLength ?? 10}
          noData={data?.cmCasesAndDiagnosesSummary?.length < 1}
          // height={"h-full"}
          isFirstSticky={true}
          stickyWidth={[
            { width: "0px" },
            { width: "250px" },
            { width: "400px" },
          ]}
        >
          <TblBodyDepartments labelData={labelData} data={sortedData} />
        </DynamicTable>
      </div>
    </div>
  );
};

export default AcrossHospitalsDepartments;
