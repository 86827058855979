import { useNavigate } from "react-router-dom";
import "../components/components.css";
import { ArrowForwardIos, ArrowLeft } from "./muiIconsImport";
import React from "react";

type Breadcrumbs = {
  label: string;
  navigate?: string;
  highLight: boolean;
};

export default function Breadcrumbs({ data }: { data?: Breadcrumbs[] }) {
  const navigate = useNavigate();
  const backFn = () => {
    navigate(-1);
  };
  const navigation = data?.map((item: Breadcrumbs, index: number) => {
    return (
      <React.Fragment key={index}>
        <button
          type="button"
          onClick={() => {
            if (item.navigate) {
              navigate(item?.navigate);
            }
          }}
          className={`text-14  ${
            item?.highLight
              ? "text-blue-300 font-bold"
              : "text-secondary font-medium"
          } hover:underline`}
        >
          {item.label}
        </button>
        <span className="text-secondary">
          {index !== data?.length - 1 && (
            <ArrowForwardIos styles={{ width: "12px", height: "12px" }} />
          )}
        </span>
      </React.Fragment>
    );
  });

  return (
    <div className="flex items-center gap-3">
      <div>
        <button
          className="text-blue-300"
          type="button"
          onClick={() => backFn()}
        >
          <ArrowLeft />
        </button>
      </div>
      {navigation}
    </div>
  );
}
