import { IconButton, TextField } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import { inputStyles } from "../../components/mui-styles";

function AddorEditDevice({
  isEdit,
  handledelete,
  control,
  index,
  defaultValue,
  register,
  unregister,
  item,
  count,

  islinked,
}: {
  isEdit?: boolean;
  handledelete?: any;
  control?: any;
  index?: any;
  defaultValue?: any;
  register?: any;
  data?: any;
  setvalue?: any;
  unregister?: any;
  item?: any;
  count?: any;
  dataarray?: any;
  requried?: any;
  getValue?: any;
  islinked?: boolean;
}) {
  const deletefun = (i: any, item: any) => {
    handledelete(i, item);
    unregister(`modelNumber${item}`);
    unregister(`SerialNumber${item}`);
    unregister(`MACAddress${item}`);
    unregister(`IMEINumber${item}`);
  };

  return (
    <div className="relative">
      {!islinked && count > 1 && (
        <div className="absolute top-0 right-0">
          <button
            type="button"
            className="pr-7 mb-5"
            onClick={() => deletefun(index, item)}
          >
            <IconButton>
              <DeleteOutlineIcon style={{ color: "#534FA1" }} />
            </IconButton>
          </button>
        </div>
      )}

      <span className="text-18 font-[600] text-primary mb-10 ">
        Device {index}
      </span>

      <div className="grid lg:grid-cols-12">
        <div className="w-100"></div>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-x-6 gap-y-8 my-6 pe-6">
        <div className="">
          <Controller
            name={`modelNumber${item}`}
            control={control}
            defaultValue={defaultValue?.modelName || ""}
            rules={{
              required: "Model number is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={inputStyles.select}
                fullWidth
                variant="outlined"
                className="my-2"
                autoComplete="off"
                label={`Model Number*`}
                error={Boolean(error?.message)}
                helperText={error?.message}
                // inputProps={{
                //   maxLength: 5,
                // }}
                {...register(`modelNumber${item}`, {
                  maxLength: {
                    value: 50,
                    message: "Too long",
                  },
                })}
                id={`modelNumber${item}`}
                control={control}
                defaultValue={defaultValue?.modelName || ""}
              />
            )}
          />
        </div>

        <div className="">
          <Controller
            name={`SerialNumber${item}`}
            control={control}
            defaultValue={defaultValue?.serialNumber || ""}
            rules={{
              required: "Serial number is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={inputStyles.select}
                fullWidth
                variant="outlined"
                className="my-2"
                autoComplete="off"
                label={`Serial Number*`}
                error={Boolean(error?.message)}
                helperText={error?.message}
                {...register(`SerialNumber${item}`, {
                  maxLength: {
                    value: 50,
                    message: "Too long",
                  },
                })}
                id={`SerialNumber${item}`}
                control={control}
                defaultValue={defaultValue?.serialNumber || ""}
              />
            )}
          />
        </div>
        <div className="">
          <Controller
            name={`MACAddress${item}`}
            control={control}
            defaultValue={defaultValue?.macAddress || ""}
            rules={{
              required: "MAC address is required",
              pattern: {
                value: /^[a-zA-Z0-9]{17}$/,
                message: "Please enter a valid MAC Address",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={inputStyles.select}
                fullWidth
                variant="outlined"
                className="my-2"
                autoComplete="off"
                maxlength={4}
                label={`MAC Address*`}
                error={Boolean(error?.message)}
                helperText={error?.message}
                {...register(`MACAddress${item}`)}
                id={`MACAddress${item}`}
                control={control}
                defaultValue={defaultValue?.macAddress || ""}
              />
            )}
          />
        </div>
        <div className="">
          <Controller
            name={`IMEINumber${item}`}
            control={control}
            defaultValue={defaultValue?.imeiNumber || ""}
            rules={{
              required: "IMEI number is required",
              pattern: {
                value: /^[0-9]{15}$/,
                message: "Please enter a valid IMEI Number",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={inputStyles.select}
                fullWidth
                variant="outlined"
                className="my-2"
                autoComplete="off"
                label={`IMEI Number*`}
                error={Boolean(error?.message)}
                helperText={error?.message}
                {...register(`IMEINumber${item}`)}
                id={`IMEINumber${item}`}
                control={control}
                defaultValue={defaultValue?.imeiNumber || ""}
              />
            )}
          />
        </div>
      </div>
      {count !== index && !isEdit ? (
        <hr className="bg-gray-200 dark:bg-gray-300 mb-6 me-6" />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AddorEditDevice;
