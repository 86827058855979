import React, { useEffect, useRef, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  TextField,
  TableBody,
} from "@mui/material";
import { LoadingSpinner } from "../ui/loadinSpinner";
import { FilterAppliedIcon, FilterIcon } from "../muiIconsImport";
import "./table.css";
import { inputStyles } from "../mui-styles";
// import useDisplayToggle from "../../utils/useDisplayToggle";
import SortUpIcon from "../../assests/icons/topSortIocn.svg";
import SortDownIcon from "../../assests/icons/bottomSortIcon.svg";
import { ThemeProvider } from "@mui/material/styles";
import { paginationTheme } from "../themeProvider";
import { SearchTooltip } from "../../utils/toolTip";

const DynamicTable = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setorder,
  orderBy,
  setOrderBy,
  filter,
  setFilter,
  columnData,
  children,
  dataLength,
  stickyWidth,
  height = "h-full",
  status,
  errorMessage,
  pagination = true,
  isFirstSticky,
  noData = false,
}: any) => {
  // const [searchShow, setSearchShow] = useState<any>(null);
  // const [isSearchOption, setisSearchOption] = useState<any>(false);

  // const onClickRef = useRef(null);
  // const insideClickRef = useRef(null);
  // useDisplayToggle({
  //   onClickRef,
  //   insideClickRef,
  //   setDisplay: setisSearchOption,
  // });

  // useEffect(() => {
  //   if (isSearchOption === false) {
  //     setSearchShow(null);
  //   }
  // }, [isSearchOption]);

  // hover is used instead of onclick
  // const toggleFn = (index: any) => {
  //   if (searchShow === index) {
  //     setisSearchOption(false);
  //     setSearchShow(null);
  //   } else {
  //     setSearchShow(index);
  //     setisSearchOption(true);
  //   }
  // };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: any) => {
    const isAsc = order === property && orderBy === "asc";
    setOrderBy(isAsc ? "desc" : "asc");
    setorder(property);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newFilter: any = {};
    newFilter[name] = value;

    columnData.forEach((item: any) => {
      if (item?.value !== name) {
        newFilter[item.value] = "";
      }
    });

    setFilter(newFilter);
  };

  return (
    <>
      <TableContainer
        style={{
          overflowY: "scroll",
          overflowX: "auto",
          border: "1px solid #F2F4F7",
        }}
        className={`table_scroll_bar ${height}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow
              style={{
                position: "sticky",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
                background: "#EEF1F6",
                zIndex: "10",
                top: "0",
              }}
            >
              {columnData
                ?.filter((filter: any) => !filter?.hide)
                ?.map((item: any, index: number) => {
                  let leftPosition = "";
                  let zIndex = "";

                  if (index === 0 && !isFirstSticky) {
                  } else {
                    leftPosition = !item?.removeSticky
                      ? stickyWidth?.[index]?.width
                      : "";
                    zIndex =
                      !item?.removeSticky &&
                      index >= 0 &&
                      index < stickyWidth?.length
                        ? "10"
                        : "";
                  }

                  return (
                    <TableCell
                      style={{
                        position: `${
                          !item?.removeSticky ? "sticky" : "static"
                        }`,
                        left: leftPosition,
                        zIndex: zIndex,
                        top: 0,
                        borderRight: "1px solid #F2F4F7",
                        borderBottom: "1px solid #F2F4F7",
                        background: "#EEF1F6",
                        fontFamily: "Inter, sans-serif",
                        width: `${item?.width ?? ""}`,
                        minWidth: `${item?.width ?? ""}`,
                        maxWidth: `${item?.width ?? ""}`,
                      }}
                      colSpan={item?.colSpan}
                      className="border-r-2"
                      key={index}
                    >
                      <div className="flex gap-4 items-center justify-between">
                        {item?.sort === false ? (
                          <span className="text-14 font-semibold text-primary">
                            {item.label}
                          </span>
                        ) : (
                          <TableSortLabel
                            className="custom-table-sort-label"
                            hideSortIcon={true}
                            onClick={() => handleRequestSort(item?.value)}
                          >
                            <span className="text-14 font-semibold text-primary">
                              {item.label}
                            </span>
                            {order === item?.value ? (
                              orderBy === "asc" ? (
                                <img
                                  src={SortUpIcon}
                                  alt="Ascending"
                                  className="ps-2"
                                />
                              ) : (
                                <img
                                  src={SortDownIcon}
                                  alt="Descending"
                                  className="ps-2"
                                />
                              )
                            ) : null}
                          </TableSortLabel>
                        )}
                        {item?.search === false ? null : (
                          <SearchTooltip
                            content={
                              <div
                                className="bg-white p-3 rounded-lg shadow-lg z-50"
                                // ref={insideClickRef}
                              >
                                <TextField
                                  id={item.label}
                                  label={`Search by ${item.label}`}
                                  variant="outlined"
                                  type="search"
                                  sx={inputStyles.select}
                                  style={{ width: "200px" }}
                                  name={item.value}
                                  value={filter[item.value] || ""}
                                  onChange={handleFilterChange}
                                  autoComplete="off"
                                />
                              </div>
                            }
                            position="top-[-10px] right-[-90px]"
                          >
                            <label
                              className="text-blue-300"
                              htmlFor={item?.label}
                              // onClick={() => toggleFn(index)}
                              // ref={onClickRef}
                            >
                              {filter[item?.value] ? (
                                <FilterAppliedIcon />
                              ) : (
                                <FilterIcon />
                              )}
                            </label>
                          </SearchTooltip>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          {errorMessage && !status && (
            <TableBody>
              <TableRow style={{ height: height, position: "relative" }}>
                <TableCell
                  style={{
                    position: "absolute",
                    left: "30vw",
                    top: "25vh",
                    borderBottom: "none",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <span className="text-secondary text-20 font-medium">
                    {errorMessage}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {status && (
            <TableBody>
              <TableRow style={{ height: height, position: "relative" }}>
                <TableCell
                  style={{
                    position: "absolute",
                    left: "35vw",
                    top: "25vh",
                    borderBottom: "none",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <LoadingSpinner />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!status && !errorMessage && children}
          {!errorMessage && !status && (dataLength <= 0 || noData) && (
            <TableBody>
              <TableRow style={{ height: height, position: "relative" }}>
                <TableCell
                  style={{
                    position: "absolute",
                    left: "30vw",
                    top: "25vh",
                    borderBottom: "none",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <span className="text-secondary text-20 font-medium">
                    There is no data found
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pagination && (
        <ThemeProvider theme={paginationTheme}>
          <TablePagination
            rowsPerPageOptions={[3, 5, 10, 25]}
            component="div"
            count={dataLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                color: "#667085 !important",
                fontFamily: "Inter !important",
                fontSize: "14px",
                fontWeight: 400,
              },
              "& .MuiInputBase-root, .MuiTablePagination-displayedRows": {
                color: "#101828 !important",
                fontWeight: 600,
                fontFamily: "Inter !important",
                fontSize: "14px",
              },
            }}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default DynamicTable;
