import { Closeicon } from "../muiIconsImport";
import ModalHeader from "../../utils/modalHeader";
import "../../components/components.css";
import "./feedback.css";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  data: string;
};
const DescriptionSeeMoreModal = ({ isVisible, onClose, data }: ModalProps) => {
  if (!isVisible) return null;
  return (
    <ModalHeader>
      <div className="items-center py-5 sticky top-0 bg-white">
        <div className="lg:w-[900px] sm:w-[600px] w-[300px] relative">
          <div className="sticky top-0 left-0 pt-2 bg-white px-5">
            <div className="flex justify-between ">
              <span className="text-primary text-18 font-semibold">
                Error Message
              </span>
              <button
                type="button"
                className="text-blue-300 font-bold text-14"
                onClick={onClose}
              >
                <Closeicon />
              </button>
            </div>
            <hr className="mt-2 mb-4" />
          </div>

          <div className="overflow-x-auto  scroll_bar_feedback h-full overflow-y-auto ms-5">
            <div className="sm:h-[500px] h-[500px] ">
              <div className="pb-3">{data}</div>
            </div>
          </div>
        </div>
      </div>
    </ModalHeader>
  );
};

export default DescriptionSeeMoreModal;
