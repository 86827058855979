import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
import GridViewIcon from "@mui/icons-material/GridView";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DevicesOtherOutlinedIcon from "@mui/icons-material/DevicesOtherOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailIcon from "@mui/icons-material/Email";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PersonRemoveAlt1OutlinedIcon from "@mui/icons-material/PersonRemoveAlt1Outlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import FilterListIcon from "@mui/icons-material/FilterList";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ShareIcon from "@mui/icons-material/Share";
import ArticleIcon from "@mui/icons-material/Article";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DownloadIcon from "@mui/icons-material/Download";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const ArrowLeft = ({ color = "#534FA1" }: { color?: string }) => (
  <KeyboardBackspaceIcon style={{ color }} />
);
export const Closeicon = ({ color = "#534FA1" }: { color?: string }) => (
  <CloseIcon style={{ color }} />
);
export const ArrowRight = ({ color = "#534FA1" }: { color?: string }) => (
  <EastIcon style={{ color }} />
);

export const GridIcon = ({ color }: { color?: string }) => (
  <GridViewIcon style={{ color }} />
);
export const HospitalIcon = ({ color }: { color?: string }) => (
  <LocalHospitalOutlinedIcon style={{ color }} />
);

export const PersonIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <PersonOutlinedIcon style={styles} />
);

export const PhoneEnabledIcons = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <LocalPhoneOutlinedIcon style={styles} />;
export const NotificationsIcon = ({ color }: { color?: string }) => (
  <NotificationsNoneOutlinedIcon style={{ color }} />
);
export const DevicesIcon = ({ color }: { color?: string }) => (
  <DevicesOtherOutlinedIcon style={{ color }} />
);
export const FeedbackIcon = ({ color }: { color?: string }) => (
  <FeedbackOutlinedIcon style={{ color }} />
);
export const LoginIcons = ({ color }: { color?: string }) => (
  <LoginIcon style={{ color }} />
);
export const LogoutIcons = ({ styles }: { styles?: React.CSSProperties }) => (
  <LogoutIcon style={styles} />
);
export const Emailicons = ({ styles }: { styles?: React.CSSProperties }) => (
  <EmailIcon style={styles} />
);
export const MailOutlineIcons = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <MailOutlineIcon style={styles} />;
export const DownArrow = ({ styles }: { styles?: React.CSSProperties }) => (
  <KeyboardArrowDownIcon style={styles} />
);
export const UpArrow = ({ styles }: { styles?: React.CSSProperties }) => (
  <KeyboardArrowUpIcon style={styles} />
);
export const ErrorMessageIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <ErrorOutlineOutlinedIcon style={styles} />;

export const AddIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <AddOutlinedIcon style={styles} />
);

export const CheckCircleIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <CheckCircleOutlineOutlinedIcon style={styles} />;
export const DeleteOutlinedIcons = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <DeleteOutlinedIcon style={styles} />;
export const DeleteIcons = ({ styles }: { styles?: React.CSSProperties }) => (
  <DeleteIcon style={styles} />
);
export const ClearIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <ClearOutlinedIcon style={styles} />
);
export const FilterIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <FilterAltOutlinedIcon style={styles} />
);
export const FilterAppliedIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <FilterAltIcon style={styles} />;

export const MoreOptionIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <MoreVertIcon style={styles} />;
export const SearchIcons = ({ styles }: { styles?: React.CSSProperties }) => (
  <SearchIcon style={styles} />
);

export const ArrowDropDown = ({ styles }: { styles?: React.CSSProperties }) => (
  <ArrowDropDownIcon style={styles} />
);
export const ArrowDropUp = ({ styles }: { styles?: React.CSSProperties }) => (
  <ArrowDropUpIcon style={styles} />
);

export const ArrowBackIos = ({ styles }: { styles?: React.CSSProperties }) => (
  <ArrowBackIosNewIcon style={styles} />
);
export const ArrowForwardIos = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <ArrowForwardIosIcon style={styles} />;

export const ProfileCardIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <AssignmentIndOutlinedIcon style={styles} />;

export const EditIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <ModeEditOutlineOutlinedIcon style={styles} />
);

export const RemovePersonIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <PersonRemoveAlt1OutlinedIcon style={styles} />;

export const LockOpenIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <LockOpenOutlinedIcon style={styles} />
);

export const LinkIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <InsertLinkIcon style={styles} />
);

export const AddCircledIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <AddCircleIcon style={styles} />;

export const VisibilityIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <VisibilityOutlinedIcon style={styles} />;

export const VisibilityOffIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <VisibilityOffOutlinedIcon style={styles} />;
export const AddCircleOutlineIcons = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <AddCircleOutlineIcon style={styles} />;
export const DoneIcons = ({ styles }: { styles?: React.CSSProperties }) => (
  <DoneIcon style={styles} />
);

export const RemoveCircleOutlineIcons = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <RemoveCircleOutlineIcon style={styles} />;

export const FilterListIcons = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <FilterListIcon style={styles} />;

export const CalendarIcon = ({ styles }: { styles?: React.CSSProperties }) => (
  <CalendarMonthIcon style={styles} />
);
export const SendNotificationIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <SendOutlinedIcon style={styles} />;

export const ShareNotificationIcon = ({ styles, onClick }: any) => (
  <ShareIcon style={styles} onClick={onClick} />
);

export const AttachmentIcon = ({
  styles,
}: {
  styles?: React.CSSProperties;
}) => <AttachFileIcon style={styles} />;

export const ArticleIcons = ({ styles }: { styles?: React.CSSProperties }) => (
  <ArticleIcon style={styles} />
);

export const CalendarIconOutlined = ({
  color = "#534FA1",
}: {
  color?: string;
}) => <DateRangeIcon style={{ color }} />;

export const FileIcons = ({ color = "#534FA1" }: { color?: string }) => (
  <TextSnippetIcon style={{ color }} />
);

export const DownloadIcons = ({ color = "#534FA1" }: { color?: string }) => (
  <DownloadIcon style={{ color }} />
);

export const CancelOutlinedIcons = ({
  color = "#534FA1",
}: {
  color?: string;
}) => <CancelOutlinedIcon style={{ color }} />;
