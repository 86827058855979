import CloseIcon from "@mui/icons-material/Close";
import AutoCompleteForm from "../../../../components/forms/autoCompleteForm";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../components/buttons/buttons";
import {
  ArticleIcons,
  AttachmentIcon,
} from "../../../../components/muiIconsImport";
import { useAppDispatch } from "../../../../redux/store";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import HospitalIdSelectDropdown from "../sentNotification/hospitalIdSelectDropdown";
import { InputForm } from "../../../../components/forms/inputForm";
import ProductSelectDropdown from "../sentNotification/productSelectDropdown";
import PlanTypeDropdown from "../sentNotification/planTypeDropdown";
import { TextForm } from "../../../../components/forms/textForm";
import {
  getNotifications,
  sendNotifications,
} from "../../../../redux/notifications/notificationReducer";
import { ResponseType } from "../../../../modals/IreduxResponse";
import { resetSendnotifications } from "../../../../redux/notifications/notificationSlice";
import errorToast from "../../../../utils/errorToast";
import SuccessMessage from "../../../../components/modals/successMessage";
import {
  CountryList,
  DistrictList,
  HobliList,
  StateList,
  TalukList,
} from "../../../hospitalManagement/apicalls/locationList";
import ModalHeader from "../../../../utils/modalHeader";
import sortValues from "../../../../constants/sortColumnValue";
import { AccessTokenDecoder } from "../../../../utils/accessTokenDecoder";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onClick?: () => void;
  activeTab?: string;
};

const NotificationModal = ({
  isVisible,
  onClose,
  onClick,
  activeTab,
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const [isReset, setIsReset] = useState(false);
  const [imgUpload, setImgUpload] = useState<any>();
  const [sentModal, setSentModal] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { errors: formErrors },
    setError,
  } = useForm<any>();
  const fileInputRef = useRef<any>();
  const userDetails = AccessTokenDecoder();
  const countryId: number = watch("countryId");
  const stateId: number = watch("stateId");
  const districtId: number = watch("districtId");
  const talukId: number = watch("talukId");
  const hobliId: number = watch("hobliId");
  const { countryLists, countryError, countryLoading, fetchCountry } =
    CountryList();
  const { stateLists, stateError, stateLoading, fetchState } = StateList(
    countryId ?? 0
  );
  const { districtLists, districtError, districtLoading, fetchDistrict } =
    DistrictList(stateId ?? 0);
  const { talukLists, talukError, talukLoading, fetchTaluk } = TalukList(
    districtId ?? 0
  );
  const { hobliLists, hobliError, hobliLoading, fetchHobli } = HobliList(
    talukId ?? 0
  );

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    fetchState();
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  }, [countryId]);
  useEffect(() => {
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  }, [stateId]);
  useEffect(() => {
    fetchTaluk();
    fetchHobli();
  }, [districtId]);

  useEffect(() => {
    fetchHobli();
  }, [talukId]);
  const countryChange = () => {
    setValue("stateId", 0);
    setValue("districtId", 0);
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("state", "");
    setValue("district", "");
    setValue("taluk", "");
    setValue("hobli", "");
  };
  const stateChange = () => {
    setValue("districtId", 0);
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("district", "");
    setValue("taluk", "");
    setValue("hobli", "");
  };
  const districtChange = () => {
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("taluk", "");
    setValue("hobli", "");
  };

  const talukChange = () => {
    setValue("hobliId", 0);
    setValue("hobli", "");
  };
  const onSubmit: SubmitHandler<any> = async (data) => {
    if (data?.hospitalsId?.length > 5) {
      setError("hospitalsId", {
        type: "manual",
        message: "Not more than 5 hospitals can be selected",
      });
    } else {
      dispatch(
        sendNotifications({
          hospital_Id: data?.hospitalsId,
          hospital_Name: "",
          subject: data?.subject,
          description: data?.body,
          logoURL: "",
          userEmails: [""],
          plantypeList: data?.planType,
          productIds: data?.productId,
          attachment: imgUpload?.attachment,
          userName: userDetails?.name,
          signature: data?.signature,
        })
      );
    }
  };

  const sendNotificationsRes = useSelector(
    (data: { sendNotifications: ResponseType }) => data.sendNotifications
  );

  useEffect(() => {
    if (sendNotificationsRes?.status === "success") {
      // onClose();
      setSentModal(true);
      reset(() => ({
        hospitalsId: [],
        productId: [],
        planType: [],
      }));
      setIsReset((prev) => !prev);
      setImgUpload(null);
      dispatch(
        getNotifications({
          status: activeTab === "System Notifications" ? 0 : 3,
          order: "notification_sent",
          orderBy: "desc",
          page: sortValues.page,
          rowsPerPage: 25,
          searchQuery: "",
          searchKeywords: "",
        })
      );
    } else if (sendNotificationsRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [sendNotificationsRes]);

  useEffect(() => {
    return () => {
      dispatch(resetSendnotifications());
      setValue("countryId", 0);
      setValue("stateId", 0);
      setValue("districtId", 0);
      setValue("talukId", 0);
      setValue("hobliId", 0);
    };
  }, []);

  const handleFileChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileSizeInBytes = file.size;
      let fileSize;
      if (fileSizeInBytes >= 1024 * 1024) {
        // Convert to MB
        fileSize = (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        // Convert to KB
        fileSize = (fileSizeInBytes / 1024).toFixed(2) + " KB";
      }
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      if (fileSizeInMB <= 10) {
        setImgUpload({
          attachment: file,
          attachmentFileName: file.name,
          contentType: file.type,
          fileSize: fileSize,
        });
      } else {
        alert("File size exceeds 10MB limit.");
      }
    }
  };

  const removeAttachmentFn = () => {
    setImgUpload(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  if (!isVisible) return null;
  return (
    <ModalHeader>
      <div className="items-center px-5 sticky top-0 bg-white">
        <div className="sm:h-[80vh] h-[500px]  flex flex-col relative">
          <>
            <div className="sticky top-[0px] bg-white z-10 pt-5">
              <div className="absolute top-3 right-[-10px]">
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                    reset(() => ({
                      hospitalsId: [],
                      productId: [],
                      planType: [],
                    }));
                    setIsReset((prev) => !prev);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="items-start">
                <span className="text-24 text-primary font-semibold whitespace-nowrap">
                  Send a Notification
                </span>
              </div>
              <hr className="my-4" />
            </div>

            <form
              className="pt-3 overflow-y-auto px-[12px] scroll_bar_with_side_notification"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="grid lg:grid-cols-5 pt-2">
                <div className="col-span-1">
                  <span className="text-secondary text-14 font-medium">
                    Select Region:
                  </span>
                </div>
                <div className="grid md:grid-cols-3 lg:mt-0 mt-3 gap-x-6 gap-y-8 col-span-4">
                  <div className="xl:w-[250px] md:w-[200px]">
                    <div className="hidden w-0 h-0 invisible">
                      <InputForm
                        label={"Country Id"}
                        id="countryId"
                        control={control}
                        defaultValue={""}
                        required={false}
                      />
                    </div>

                    <AutoCompleteForm
                      label={"Country"}
                      id={"country"}
                      options={countryLists ?? []}
                      control={control}
                      defaultValue={""}
                      handleChange={(e: any, seletedOption: any) => {
                        setValue("countryId", seletedOption?.value);
                        countryChange();
                      }}
                      required={false}
                    />
                  </div>

                  <div className="hidden w-0 h-0 invisible">
                    <InputForm
                      label={"State Id"}
                      id="stateId"
                      control={control}
                      defaultValue={""}
                      required={false}
                    />
                  </div>
                  {stateLoading ? (
                    <>
                      <AutoCompleteForm
                        label={"State"}
                        id={"dummystate"}
                        options={[]}
                        control={control}
                      />
                    </>
                  ) : (
                    <AutoCompleteForm
                      label={"State*"}
                      id={"state"}
                      options={stateLists ?? []}
                      control={control}
                      defaultValue={""}
                      handleChange={(e: any, seletedOption: any) => {
                        setValue("stateId", seletedOption?.value);
                        stateChange();
                      }}
                      required={false}
                    />
                  )}
                  <div className="hidden w-0 h-0 invisible">
                    <InputForm
                      label={"District Id"}
                      id="districtId"
                      control={control}
                      defaultValue={""}
                      required={false}
                    />
                  </div>
                  {districtLoading ? (
                    <>
                      <AutoCompleteForm
                        label={"District"}
                        id={"dummydistrict"}
                        options={[]}
                        control={control}
                      />
                    </>
                  ) : (
                    <AutoCompleteForm
                      label={"District"}
                      id={"district"}
                      options={districtLists ?? []}
                      control={control}
                      defaultValue={""}
                      handleChange={(e: any, seletedOption: any) => {
                        setValue("districtId", seletedOption?.value);
                        districtChange();
                      }}
                      required={false}
                    />
                  )}

                  <div className="hidden w-0 h-0 invisible">
                    <InputForm
                      label={"Taluk Id"}
                      id="talukId"
                      control={control}
                      defaultValue={""}
                      required={false}
                    />
                  </div>
                  {talukLoading ? (
                    <>
                      <AutoCompleteForm
                        label={"Taluk"}
                        id={"dummytaluk"}
                        options={[]}
                        control={control}
                      />
                    </>
                  ) : (
                    <AutoCompleteForm
                      label={"Taluk"}
                      id={"taluk"}
                      options={talukLists ?? []}
                      control={control}
                      defaultValue={""}
                      handleChange={(e: any, seletedOption: any) => {
                        setValue("talukId", seletedOption?.value);
                        talukChange();
                      }}
                      required={false}
                    />
                  )}

                  <div className="hidden w-0 h-0 invisible">
                    <InputForm
                      label={"Hobli Id"}
                      id="hobliId"
                      control={control}
                      defaultValue={""}
                      required={false}
                    />
                  </div>
                  {hobliLoading ? (
                    <>
                      <AutoCompleteForm
                        label={"Hobli"}
                        id={"dummyhobli"}
                        options={[]}
                        control={control}
                      />
                    </>
                  ) : (
                    <AutoCompleteForm
                      label={"Hobli"}
                      id={"hobli"}
                      options={hobliLists ?? []}
                      control={control}
                      defaultValue={""}
                      handleChange={(e: any, seletedOption: any) => {
                        setValue("hobliId", seletedOption?.value);
                      }}
                      required={false}
                    />
                  )}
                </div>
              </div>
              <div className="grid lg:grid-cols-5 pt-8">
                <div className="py-[10px] col-span-1">
                  <span className="text-secondary text-14 font-medium ">
                    Send to*:
                  </span>
                </div>
                <div className="grid md:grid-cols-3 gap-x-6 gap-y-8 col-span-4">
                  <div className="xl:w-[250px] md:w-[200px]">
                    <div className="hidden w-0">
                      <InputForm
                        label={"Hospitals"}
                        id="hospitalsId"
                        control={control}
                        required={true}
                      />
                    </div>
                    <HospitalIdSelectDropdown
                      formErrors={formErrors}
                      name={"hospitalsId"}
                      setValue={setValue}
                      watch={watch}
                      clearErrors={clearErrors}
                      isReset={isReset}
                    />
                  </div>
                  <div>
                    <div className="hidden w-0">
                      <InputForm
                        label={"Products"}
                        id="productId"
                        control={control}
                        required={true}
                      />
                    </div>
                    <ProductSelectDropdown
                      formErrors={formErrors}
                      name={"productId"}
                      setValue={setValue}
                      watch={watch}
                      clearErrors={clearErrors}
                      isReset={isReset}
                    />
                  </div>

                  <div>
                    <div className="hidden w-0">
                      <InputForm
                        label={"Plan Type"}
                        id="planType"
                        control={control}
                        required={true}
                      />
                    </div>
                    <PlanTypeDropdown
                      formErrors={formErrors}
                      name={"planType"}
                      setValue={setValue}
                      watch={watch}
                      clearErrors={clearErrors}
                      isReset={isReset}
                    />
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-5 pt-1">
                <div className="col-span-1 mt-2">
                  <span className="text-secondary text-14 font-medium">
                    Subject*:
                  </span>
                </div>
                <div className="grid md:grid-cols-1 gap-6 col-span-4">
                  <div className="w-full rounded mb-3 flex">
                    <TextForm
                      label="Subject"
                      id="subject"
                      control={control}
                      rows={1}
                      maxRows={1}
                      isMaxlength
                    />
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-5 pt-2">
                <div className="col-span-1 mt-2">
                  <span className="text-secondary text-14 font-medium">
                    Body*:
                  </span>
                </div>
                <div className="grid md:grid-cols-1 gap-6 col-span-4">
                  <div className="w-full rounded mb-3 flex">
                    <TextForm label="Body" id="body" control={control} />
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-5 pt-2">
                <div className="col-span-1 mt-2">
                  <span className="text-secondary text-14 font-medium">
                    Signature*:
                  </span>
                </div>
                <div className="grid md:grid-cols-1 gap-6 col-span-4">
                  <div className="w-full rounded mb-3 flex">
                    <TextForm
                      label="Signature"
                      id="signature"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-5 pt-2">
                <div className="col-span-1">
                  <span className="text-secondary text-14 font-medium">
                    Attachments:
                  </span>
                </div>
                <div className="grid md:grid-cols-1 gap-6 col-span-4">
                  <div className="w-full rounded mb-3 flex">
                    {imgUpload?.attachment ? (
                      <div className="border border-blue-300 round rounded flex gap-2 items-center p-3">
                        <span className="text-blue-300">
                          <ArticleIcons />
                        </span>
                        <div className="flex flex-col">
                          <span className="text-primary text-16 font-semibold">
                            {imgUpload?.attachmentFileName}
                          </span>
                          <span className="text-12 text-secondary font-medium">
                            {imgUpload?.fileSize}
                          </span>
                        </div>
                        <button
                          className="ps-5"
                          type="button"
                          onClick={() => removeAttachmentFn()}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    ) : (
                      <span className="text-secondary text-14 font-medium">
                        No file is attached
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="sticky bottom-[0px] pb-[20px] bg-white z-10">
                <hr className="my-4" />
                <div className="flex justify-between gap-7 mt-5">
                  <div>
                    <input
                      id="attach_file"
                      className="invisible w-0 h-0 hidden"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />

                    <label className="cursor-pointer" htmlFor="attach_file">
                      <span className="bg-white border-2 border-blue-300 font-semibold text-blue-300 py-3 px-7 rounded-full flex items-center justify-center gap-2 hover:shadow-xl">
                        <AttachmentIcon />
                        Attach File
                      </span>
                    </label>
                  </div>
                  <div className="flex gap-7">
                    <SecondaryButton
                      type="button"
                      name="Cancel"
                      onClick={() => {
                        reset(() => ({
                          hospitalsId: [],
                          productId: [],
                          planType: [],
                          countryId: 0,
                          country: "",
                          stateId: 0,
                          districtId: 0,
                          talukId: 0,
                          hobliId: 0,
                        }));
                        setIsReset((prev) => !prev);
                        onClose();
                      }}
                    />
                    <PrimaryButton
                      name="Send"
                      loading={sendNotificationsRes?.isLoading}
                    />
                  </div>
                </div>
              </div>
            </form>

            <SuccessMessage
              isVisible={sentModal}
              onClose={() => {
                setSentModal(false);
                onClose();
              }}
              title={`The notification has been successfully sent!`}
            />
          </>
        </div>
      </div>
    </ModalHeader>
  );
};

export default NotificationModal;
