export const allHospitalCheckBoxData = [
  {
    label: "Action",
    value: "action",
    display: false,
  },
  {
    label: "Hospital",
    value: "name",
    display: false,
  },
  {
    label: "Status",
    value: "status",
    display: true,
  },
  {
    label: "Location",
    value: "city",
    display: true,
  },
  {
    label: "Onboarded Date",
    value: "onboarding_date",
    display: true,
  },
  {
    label: "Products",
    value: "products",
    display: true,
  },
  {
    label: "Add-on Services",
    value: "add_on_service",
    display: true,
  },
  {
    label: "Devices",
    value: "devices",
    display: true,
  },
  {
    label: "Service Type",
    value: "service_type",
    display: true,
  },
  {
    label: "Plan Type",
    value: "plan_type",
    display: true,
  },
  {
    label: "Patient Type",
    value: "patient_type",
    display: true,
  },
  {
    label: "Price",
    value: "price",
    display: true,
  },
  {
    label: "Plan Validity",
    value: "plan_validity",
    display: true,
  },
  {
    label: "Ownership",
    value: "ownerShip",
    display: true,
  },
  {
    label: "Onboarding Source",
    value: "onboarding_source",
    display: true,
  },
  {
    label: "Onboarding Name",
    value: "onboarding_source_name",
    display: true,
  },
  {
    label: "Security Deposit",
    value: "security_deposit",
    display: true,
  },
  {
    label: "Device Cost",
    value: "device_cost",
    display: true,
  },
  {
    label: "Instalments",
    value: "instalments",
    display: true,
  },
  {
    label: "One-time Cost",
    value: "one_time_cost",
    display: true,
  },
  {
    label: "Speciality",
    value: "speciality",
    display: true,
  },
  {
    label: "Bed Size",
    value: "bed_size",
    display: true,
  },
  {
    label: "ICU Beds",
    value: "ICU_beds",
    display: true,
  },
  {
    label: "Ward Beds",
    value: "ward_beds",
    display: true,
  },
  {
    label: "OT Tables",
    value: "OT_tables",
    display: true,
  },
  {
    label: "Equipments",
    value: "equipments",
    display: true,
  },
];


export const inProgressHosCheckBoxData = [
  {
    label: "Action",
    value: "action",
    display: false,
  },
  {
    label: "Hospital",
    value: "name",
    display: false,
  },
  {
    label: "Location",
    value: "city",
    display: false,
  },
  {
    label: "Speciality",
    value: "speciality",
    display: true,
  },
  {
    label: "Bed Size",
    value: "bed_size",
    display: true,
  },
  {
    label: "Owner & Operator",
    value: "owner_operator",
    display: true,
  },
  {
    label: "Source of Onboarding",
    value: "source_of_onboarding",
    display: true,
  },
  {
    label: "Hospital Management",
    value: "management_name",
    display: true,
  },
  {
    label: "Account Manager",
    value: "account_manager",
    display: true,
  },
];
