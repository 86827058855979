import { useEffect } from "react";
import { AmountInputForm, InputForm } from "../../components/forms/inputForm";
import SelectForm from "../../components/forms/selectForm";
import { useSelector } from "react-redux";
import { labelObject } from "../../helper";

const CostDetails = ({ control, setValue, watch, previousDataPA }: any) => {
  const totalPaidDevices =
    (isNaN(+watch("maPaidDevicesCount")) ? 0 : +watch("maPaidDevicesCount")) +
    (isNaN(+watch("clPaidDevicesCount")) ? 0 : +watch("clPaidDevicesCount"));

  useEffect(() => {
    setValue("noOfPaidDevices", totalPaidDevices);
  }, [totalPaidDevices]);

  const enumData = useSelector((data: any) => data?.enumData?.data);

  const instalmentsList = labelObject(enumData?.Instalments?.data);

  useEffect(() => {
    if (totalPaidDevices <= 0) {
      setValue("deviceCost", "");
      setValue("instalments", "");
    } else {
      setValue("deviceCost", previousDataPA?.deviceCost);
      setValue("instalments", previousDataPA?.instalments);
    }
  }, [totalPaidDevices, previousDataPA]);

  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
      <AmountInputForm
        label={"Device Cost"}
        id="deviceCost"
        control={control}
        defaultValue={previousDataPA?.deviceCost ?? ""}
        maxLength={50}
        disabled={totalPaidDevices <= 0}
        required={totalPaidDevices <= 0 ? false : true}
      />
      <InputForm
        label={"No. of Paid Devices"}
        id="noOfPaidDevices"
        control={control}
        defaultValue={previousDataPA?.noOfPaidDevices}
        applyNumberValidation={true}
        disabled={true}
      />
      <SelectForm
        label={"Instalments"}
        id={"instalments"}
        options={instalmentsList}
        control={control}
        defaultValue={previousDataPA?.instalments}
        disabled={totalPaidDevices <= 0}
        required={totalPaidDevices <= 0 ? false : true}
      />
      <AmountInputForm
        label={"Security Deposit"}
        id="securityDeposit"
        control={control}
        defaultValue={previousDataPA?.securityDeposit}
        maxLength={50}
        required={false}
      />
      <AmountInputForm
        label={"One-time Cost"}
        id="onetimeCost"
        control={control}
        defaultValue={previousDataPA?.onetimeCost}
        maxLength={50}
        required={false}
      />
    </div>
  );
};

export default CostDetails;
