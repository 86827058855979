import { Navigate, Outlet } from "react-router-dom";
import { AsyncAccessTokenDecoder } from "../utils/accessTokenDecoder";
import routesName from "../constants/routesName";

const AccountManagerRoute = () => {
  const decodeToken = AsyncAccessTokenDecoder();

  return decodeToken?.Roles === "Super Admin" ||
    decodeToken?.Roles === "Management" ? (
    <>
      <Outlet />
    </>
  ) : (
    <>
      <Navigate to={routesName.hospitalManagementPage} />
    </>
  );
};

export default AccountManagerRoute;
