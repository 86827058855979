import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const inputStyles = {
  select: {
    "& label.Mui-focused": {
      color: "#101828",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "Inter !important",
    },

    "& label": {
      color: "#757B84",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Inter !important",
    },

    "& .MuiInputLabel-root.Mui-error": {
      color: "#D33C52",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Inter !important",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#91959D",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C5C7C8 !important",
      },
      "&:hover fieldset": {
        borderColor: "#322F61",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#322F61",
      },
      "& .MuiSvgIcon-root": {
        color: "#101828",
      },
    },
    "& .MuiInputBase-root": {
      color: "#101828",
      fontWeight: 600,
      fontSize: "16px",
      fontFamily: "Inter !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#322F61",
      borderWidth: "1px",
    },
    "& .MuiInputBase-input::placeholder": {
      fontWeight: 400,
    },
    "& .MuiFormHelperText-root": {
      color: "#D33C52 !important",
      fontFamily: "Inter !important",
    },
  },
};

const autoCompleteTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          color: "#667085",
        },
        option: {
          color: "#667085",
          "&.Mui-focusVisible, &.Mui-selected, &.Mui-focused": {
            backgroundColor: "#D7E2FF !important",
            color: "#101828",
            fontWeight: 600,
            fontFamily: "Inter",
            fontSize: "16px",
          },
          "&:hover": {
            backgroundColor: "#ECF1FF !important",
            color: "#101828",
            fontWeight: 500,
            fontFamily: "Inter",
            fontSize: "16px",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#534FA1 !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingTop: "6px !important",
          paddingBottom: "6px !important",
        },
      },
    },
  },
});

export const FilterAutoCompleteForm = ({
  id,
  defaultValue,
  required = true,
  label,
  placeholder,
  options,
  control,
  customErrorMessage = `${label} is required`,
  selectValue = "label",
  handleChange = () => {},
}: any) => {
  return (
    <ThemeProvider theme={autoCompleteTheme}>
      <Box sx={{ width: "100%" }}>
        <Controller
          name={id}
          control={control}
          defaultValue={defaultValue || ""}
          rules={{
            required: required ? customErrorMessage : false,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              id={id}
              options={options}
              getOptionLabel={(option) => option?.[selectValue]}
              onChange={(e, newValue) => {
                onChange(newValue?.[selectValue] || "");
                handleChange(e, newValue);
              }} 
              value={options.find(
                (option: any) => option?.[selectValue] === value
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={inputStyles.select}
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}
        />
      </Box>
    </ThemeProvider>
  );
};

export const FilterAutoCompleteComponent = ({
  id,
  defaultValue,
  label,
  placeholder,
  options,
  selectValue = "label",
  value,
  handleChange = () => {},
}: any) => {
  return (
    <ThemeProvider theme={autoCompleteTheme}>
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          id={id}
          options={options}
          getOptionLabel={(option) => option?.[selectValue]}
          onChange={(e, newValue) => {
            //   onChange(newValue?.[selectValue] || "");
            handleChange(e, newValue?.value);
          }} // Selecting only the label
          value={options.find((option: any) => option?.[selectValue] === value)} // Setting value based on label
          renderInput={(params) => (
            <TextField
              {...params}
              sx={inputStyles.select}
              defaultValue={defaultValue}
              id={id}
              label={label}
              placeholder={placeholder}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Box>
    </ThemeProvider>
  );
};
