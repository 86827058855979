import { useLocation, useNavigate } from "react-router-dom";
import useManagementRoleType from "../../utils/managementRoleType";
import LogoImage from "../../assests/images/logo.png";
import sideBarName from "../../constants/sidebar";
import { sideBarData } from "../../jsonData/sidebarData";
import "../components.css";
import { ArrowLeft, ArrowRight, LogoutIcons } from "../muiIconsImport";
import personImage from "../../assests/images/default-profile.jpg";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import { useState } from "react";
import { CharacterHidder } from "../../helper";
import ConfirmationModal from "../modals/confirmationModal";
import LogoutIcon from "../../assests/icons/logoutIcon.svg";
import routesName from "../../constants/routesName";

type Props = {
  open: boolean;
  setOpen(open: boolean): void;
};
const Sidebar = ({ open, setOpen }: Props) => {
  const userDetails = AccessTokenDecoder();
  const navigate = useNavigate();
  const loaction = useLocation();
  const { canWrite } = useManagementRoleType();
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const logoutFn = () => {
    sessionStorage.clear();
    // navigate("/");
    window.location.reload();
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isLogoutModal}
        onClose={() => setIsLogoutModal(false)}
        onClick={() => logoutFn()}
        actionText={"Logout"}
        title={"Are you sure you want to log out? "}
        icon={LogoutIcon}
      />
      <div className="">
        <div className="text-24 flex items-center h-20 relative">
          {open ? (
            <div className="flex w-[100%] ps-[14px]">
              <img src={LogoImage} width={50} height={50} className="" />
            </div>
          ) : (
            <div className="flex gap-3 ps-[14px] items-center">
              <img src={LogoImage} width={50} height={50} />
              <span className="text-black">{sideBarName.jeevText}</span>
            </div>
          )}

          <button
            className={`bg-white absolute text-20 flex items-center border-2 border-gray-100 rounded-full shadow-lg p-1.5 z-30 ${
              open ? "left-[60px]" : "left-[244px]"
            } `}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <ArrowRight /> : <ArrowLeft />}
          </button>
        </div>

        {sideBarData.map(
          (item, index) =>
            canWrite(item.name) && (
              <div
                key={index}
                className={`flex ${
                  loaction.pathname.includes(item?.anotherPath)
                    ? "text-blue-350 sideBar_gardient font-semibold"
                    : "text-secondary font-medium"
                } `}
                onClick={() => {
                  navigate(item.path);
                  if (item.name === sideBarName.hospitalManagement) {
                    sessionStorage.setItem("hospitalStatus", "allStatus");
                  }
                }}
              >
                {loaction.pathname.includes(item?.anotherPath) && (
                  <div className="border-2 border-blue-350 rounded-tr-lg rounded-br-lg"></div>
                )}

                <button
                  className={`px-4 py-3 text-16 cursor-pointer flex items-center  ${
                    open && "justify-center text-center w-[100%]"
                  } ${
                    loaction.pathname.includes(item?.anotherPath) && "ps-3"
                  } `}
                >
                  <item.icon />
                  {!open && <span className="ps-3">{item.name}</span>}
                </button>
              </div>
            )
        )}

        <div
          className={`fixed bottom-0 text-blue-350 ${
            open ? "w-[80px]" : "w-[264px]"
          }`}
        >
          <div className="border-t-2 flex py-4 items-center justify-around">
            {/* {!open && ( */}
            <button
              className="flex items-center"
              onClick={() => navigate(routesName.userProfilePage)}
            >
              <img
                src={userDetails?.photo ? userDetails?.photo : personImage}
                className="rounded-full w-[43px] h-[43px]"
              />
              {!open && (
                <div className="flex items-center text-start ps-3">
                  <div className="flex flex-col">
                    <span className="text-14 text-start text-blue-300 font-bold ">
                      {CharacterHidder(userDetails.name)}
                    </span>
                    <span className="text-14 text-start text-secondary">
                      {userDetails.Roles}
                    </span>
                  </div>
                </div>
              )}
            </button>
            {/* )} */}

            {!open && (
              <div className="relative ">
                <button
                  className="bg-blue-300 rounded-full p-2 text-white flex items-center"
                  onClick={() => setIsLogoutModal(true)}
                >
                  <LogoutIcons styles={{ width: "20px", height: "20px" }} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
