import { useState } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import RegionsViewAll from "../../features/mis/misGraphs/reginosVewAll";
import InnerLayout from "../../layout/innerLayout";
import DepartmentList from "../../features/mis/misGraphs/departmentList";
import TotalDiagonisis from "../../features/mis/misGraphs/totalDiagonisis";
import SpecialityList from "../../features/mis/misGraphs/specialityList";
import AllSourceNames from "../../features/mis/misGraphs/allSourceNames";
import MisGraphFilter from "../../features/mis/misGraphFilter";
import Breadcrumbs from "../../components/breadCrumbs";
import { addSpacesToCamelCase } from "../../helper";
import routesName from "../../constants/routesName";

const MisGraphs = () => {
  const location = useLocation();
  const label = location?.state && location?.state?.label;
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [taluk, setTaluk] = useState("");
  const [hobli, setHobli] = useState("");
  const [hospitalArray, setHospitalArray] = useState<any>([]);
  const [filterApplyToggle, setFilterApplyToggle] = useState(false);

  const renderComponent = () => {
    switch (label) {
      case "Regions":
        return (
          <RegionsViewAll
            country={country}
            state={state}
            district={district}
            taluk={taluk}
            hobli={hobli}
            filterApplyToggle={filterApplyToggle}
          />
        );
      case "Departments":
        return (
          <DepartmentList
            country={country}
            state={state}
            district={district}
            taluk={taluk}
            hobli={hobli}
            filterApplyToggle={filterApplyToggle}
            hospitalArray={hospitalArray}
          />
        );
      case "Diagnosis":
        return (
          <TotalDiagonisis
            country={country}
            state={state}
            district={district}
            taluk={taluk}
            hobli={hobli}
            filterApplyToggle={filterApplyToggle}
            hospitalArray={hospitalArray}
          />
        );
      case "Speciality":
        return (
          <SpecialityList
            country={country}
            state={state}
            district={district}
            taluk={taluk}
            hobli={hobli}
            filterApplyToggle={filterApplyToggle}
          />
        );
      case "OnboardingSource":
        return (
          <AllSourceNames
            country={country}
            state={state}
            district={district}
            taluk={taluk}
            hobli={hobli}
            filterApplyToggle={filterApplyToggle}
          />
        );
      default:
        return null;
    }
  };

  const departmentsTableBreadcrumData = [
    {
      label: "MIS",
      navigate: `${routesName.managementInfoSystemPage}`,
      highLight: false,
    },

    {
      label: addSpacesToCamelCase(label),
      highLight: true,
    },
  ];

  return (
    <>
      <InnerLayout title="MIS">
        <div className="p-6">
          <Breadcrumbs data={departmentsTableBreadcrumData} />
        </div>
        <div className="px-6 ">
          <Grid container spacing={2}>
            {label !== "" && (
              <Grid item xs={12} sm={12} md={12}>
                <MisGraphFilter
                  country={country}
                  setCountry={setCountry}
                  state={state}
                  setState={setState}
                  district={district}
                  setDistrict={setDistrict}
                  taluk={taluk}
                  setTaluk={setTaluk}
                  hobli={hobli}
                  setHobli={setHobli}
                  setFilterApplyToggle={setFilterApplyToggle}
                  label={label}
                  hospitalArray={hospitalArray}
                  setHospitalArray={setHospitalArray}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={12}>
              {renderComponent()}
            </Grid>
          </Grid>
        </div>
      </InnerLayout>
    </>
  );
};
export default MisGraphs;
