import { accessTokenType } from "../../modals/IloginCard";
import { UserAuditInfoType } from "../../modals/IuserAuditInfo";
import { AddAccountManager } from "../../redux/accountManager/IaccountManager";
import DetectDevice from "../../utils/detectDevice";
import UserAuditDetails from "../../utils/userAuditDetails";

type addAccountManager = {
  name: string;
  phone: string;
  email: string;
  userId: number;
  userName: string;
  role: string;
  photo: string;
  gender: string;
  isActive: number;
  isDelete: number;
  employeeID: string;
  permissions: {
    roleId: number;
    roleName: string;
    permissions: [
      {
        permissionId: number;
        permissionName: string;
      }
    ];
  };
  loggedInUserName: string;
  password: string | undefined;
  setNewPAssword: boolean;
  lastSignedIn?: string | null;
};

export const addAccManagerDispatchData = (
  finalData: addAccountManager,
  userDetails: accessTokenType,
  userAuditInfo?: UserAuditInfoType
) => {
  const {
    name,
    phone,
    email,
    photo,
    gender,
    employeeID,
    permissions,
    password,
    setNewPAssword,
    userName,
    userId,
  } = finalData;
  const dispatchData: AddAccountManager = {
    name: name,
    phone: phone,
    email: email.toLowerCase(),
    userId: userId ?? 0,
    userName: userName ?? "",
    role: "",
    photo: photo,
    gender: gender,
    isActive: 0,
    isDelete: 0,
    employeeID: employeeID,
    rolePermission: permissions,
    loggedInUserName: userDetails?.userName,
    password: password ?? null,
    isTempPassword: setNewPAssword ? true : false,
    userAuditInfo,
  };

  return dispatchData;
};
