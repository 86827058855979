export const allAcountMancheckBoxData = [
  {
    label: "Action",
    value: "action",
    display: false,
  },
  {
    label: "A/C Manager",
    value: "firstname",
    display: false,
  },
  {
    label: "Gender",
    value: "gender",
    display: true,
  },
  {
    label: "Status",
    value: "isactive",
    display: true,
  },
  {
    label: "Mobile Number",
    value: "phone",
    display: true,
  },
  {
    label: "Email ID",
    value: "email",
    display: true,
  },
  {
    label: "No. of Hospitals",
    value: "no_of_hospitals",
    display: true,
  },
  {
    label: "Role",
    value: "rolename",
    display: true,
  },
  {
    label: "Joining Date",
    value: "joiningdate",
    display: true,
  },
  {
    label: "Last Signed In",
    value: "lastsignedin",
    display: true,
  },
];
