import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useAppDispatch } from "../../redux/store";
import { posthospitalLogo } from "../../redux/addHospital/hospitalManagementReducer";
import { useSelector } from "react-redux";
import { resetPosthospitalLogoState } from "../../redux/addHospital/hospitalManagementSlice";
import DefaulthospitalLogo from "../../assests/images/defaultImage.svg";
import { LoadingSpinner } from "../ui/loadinSpinner";
import { ResponseType } from "../../modals/IreduxResponse";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

type ImageUploadProps = {
  name: string;
  setValue: any;
  control: any;
  watch: any;
  image: string | undefined;
  buttonText?: string;
  imageSize?: string;
  required?: boolean;
};

const ImageUpload = ({
  name,
  setValue,
  control,
  watch,
  image,
  buttonText = "Upload",
  imageSize = "w-[80px] h-[80px]",
  required = false,
}: ImageUploadProps) => {
  const dispatch = useAppDispatch();

  const [imageUploadError, setImageUploadError] = useState(true);
  const fileInputRef = useRef<any>(null);
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size <= 200 * 1024) {
        if (file.type === "image/jpeg" || file.type === "image/png") {
          const formData = new FormData();
          formData.append("file", file);
          dispatch(posthospitalLogo(formData));
        } else {
          alert("Please select a JPG or PNG file.");
        }
      } else {
        alert("File size exceeds 200KB.");
      }
    }
  };

  const hospitalLogoRes = useSelector(
    (data: { posthospitalLogo: ResponseType }) => data?.posthospitalLogo
  );

  useEffect(() => {
    if (hospitalLogoRes?.status === "success") {
      setValue(name, hospitalLogoRes?.data?.message);
      setImageUploadError(false);
    } else if (hospitalLogoRes?.status === "failed") {
      setImageUploadError(true);
      setValue(name, "");
    }
  }, [hospitalLogoRes]);

  useEffect(() => {
    setValue(name, image);

    return () => {
      dispatch(resetPosthospitalLogoState());
    };
  }, []);

  const removeImgFn = () => {
    setValue(name, "");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="col-span-2 flex items-center gap-3">
      <div className="relative">
        <img
          src={watch(name) ? watch(name) : DefaulthospitalLogo}
          className={`border rounded-full ${imageSize} shadow-ex1`}
          alt="hospital Logo"
          style={{ objectFit: "cover" }}
        />
        {watch(name) && (
          <div className="absolute top-0 right-0 text-error">
            <button className="" type="button" onClick={() => removeImgFn()}>
              <DeleteOutline />
            </button>
          </div>
        )}
      </div>

      <input
        id="logo_upload"
        className="invisible w-0 h-0"
        type="file"
        accept=".jpg,.jpeg,.png"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <div className="flex flex-col gap-3 pt-6">
        <label className="cursor-pointer" htmlFor="logo_upload">
          <span className="bg-blue-300 text-white py-3 px-7 rounded-full flex items-center justify-center gap-2 hover:shadow-xl">
            {hospitalLogoRes?.isLoading ? (
              <span className="flex items-center gap-3 ">
                <LoadingSpinner width="w-4" height="h-4" /> Loading...
              </span>
            ) : (
              buttonText
            )}
          </span>
        </label>
        <span className="text-14 text-gray-400 font-medium">
          JPG or PNG. Max size of 200K
        </span>
      </div>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required ? `Please ${buttonText}` : false,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <input type="hidden" name={name} />
            <span className="text-error text-14">
              {imageUploadError && error?.message}
            </span>
          </div>
        )}
      />
    </div>
  );
};

export default ImageUpload;
