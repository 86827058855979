import DashboardCard from "../ui/dashboardCard";
import logo from "../../assests/images/susscess.svg";
import { PrimaryButton } from "../buttons/buttons";
import { LoginButton } from "../ui/loginButton";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onClick?: () => void;
  title?: string;
  description?: string;
  text?: string;
};

const SuccessModal = ({
  isVisible,
  onClose,
  onClick,
  title,
  description,
  text = "Login",
}: ModalProps) => {
  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="px-5 flex flex-col items-center mt-5 pb-6">
        <div className="w-[100px] h-[100px]">
          <img src={logo} className="w-[100%]" />
        </div>
        <span className="text-24 font-semibold pt-6 pb-4 text-primary">
          {title}
        </span>
        <span className="pb-6 text-20 font-normal text-secondary">
          {description}
        </span>
        <LoginButton name="Login" onClick={onClick} width="w-[100%]" />
      </div>
    </DashboardCard>
  );
};

export default SuccessModal;
