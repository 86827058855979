import { useEffect, useRef, useState } from "react";
import useDisplayToggle from "../../../../utils/useDisplayToggle";
import {
  ArrowDropDown,
  ArrowDropUp,
} from "../../../../components/muiIconsImport";
import useAxios from "../../../../hooks/useAxios";
import errorToast from "../../../../utils/errorToast";
import { labelObject, removeUnderScore } from "../../../../helper";
import { LoadingSpinner } from "../../../../components/ui/loadinSpinner";

const PlanTypeDropdown = ({
  formErrors,
  name,
  setValue,
  watch,
  clearErrors,
  isReset,
}: {
  formErrors: any;
  name: any;
  setValue: any;
  watch: any;
  clearErrors: any;
  isReset: any;
}) => {
  const required = formErrors?.[name];
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedPlanType, setSelectedPlanType] = useState<any>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [planTypeLists, setPlanTypeLists] = useState<any>([]);
  const {
    data: planTypeData,
    error: planTypeError,
    loading: PlanTypeLoading,
  } = useAxios({
    method: "get",
    path: `Enums/PlanType`,
  });

  useEffect(() => {
    if (planTypeData?.success) {
      const planTypData = labelObject(planTypeData?.data);
      setPlanTypeLists(planTypData);
    } else if (planTypeError) {
      errorToast("Something Went Wrong");
    }
  }, [planTypeData, planTypeError]);

  const handleCheckboxChange = (value: string) => {
    if (selectedPlanType?.includes(value)) {
      setSelectedPlanType(
        selectedPlanType.filter((item: any) => item !== value)
      );
    } else {
      setSelectedPlanType([...selectedPlanType, value]);
    }
  };

  useEffect(() => {
    if (selectedPlanType?.length === planTypeLists?.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedPlanType, planTypeLists]);

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setSelectedPlanType([]);
    } else {
      const allHospitalIds = planTypeLists.map((item: any) => item.value);
      setSelectedPlanType(allHospitalIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  useEffect(() => {
    setSelectedPlanType([]);
    setSelectAllChecked(false);
  }, [isReset]);

  useEffect(() => {
    setValue(name, selectedPlanType);
    if (selectedPlanType?.length > 0) {
      clearErrors(name);
    }
  }, [selectedPlanType]);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setShowDropdown,
  });

  const arrayofNames = selectedPlanType?.map(
    (item: any) =>
      planTypeLists?.find((plan: any) => plan?.value === item)?.label
  );
  const displayName = arrayofNames?.join(", ");

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        ref={onClickRef}
        className={` ${
          required
            ? `border hover:border-blue-350 ${
                required && showDropdown ? "border-blue-350" : "border-error"
              } `
            : showDropdown
            ? "border outline outline-1 border-blue-350"
            : "border hover:border-blue-350 border-gray-450"
        }  text-primary ps-5 pe-2 py-[14px] rounded w-full text-16 font-semibold text-start  flex justify-between bg-white`}
      >
        {selectedPlanType?.length === planTypeLists?.length ? (
          "All Plans"
        ) : selectedPlanType?.length === 0 ? (
          <span className="text-[#757B84] font-normal">Select Plans</span>
        ) : selectedPlanType?.length === 1 ? (
          removeUnderScore(selectedPlanType[0])
        ) : (
          displayName
        )}
        <span className="text-blue-300">
          {showDropdown ? <ArrowDropUp /> : <ArrowDropDown />}
        </span>
      </button>

      <div className="text-error text-12 ps-3 pb-3 pt-1">
        {required?.message}
      </div>

      <label className="absolute text-12 font-normal text-primary left-3 px-1 bg-white top-[-8px] ">
        Plan Type
      </label>

      <div
        className={`w-[100%] absolute z-20 top-16 bg-white px-2 py-4 shadow-md rounded-lg ${
          showDropdown ? "visible" : "invisible"
        }`}
        ref={insideClickRef}
      >
        {PlanTypeLoading ? (
          <div className="flex justify-center py-4">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="overflow-y-auto scroll_bar_with_side">
            {planTypeLists?.length >= 1 && (
              <div
                className={`${
                  selectAllChecked ? "bg-blue-150" : "hover:bg-blue-50"
                } p-3 grid grid-cols-12 gap-3 items-center`}
              >
                <div className="col-span-2 flex items-center">
                  <input
                    id="selectAllPlanType"
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                    className={`checkbox-input`}
                  />
                </div>
                <div className="col-span-10 flex items-center">
                  <label
                    htmlFor="selectAllPlanType"
                    className={`font-semibold text-14 cursor-pointer ${
                      selectAllChecked ? "text-primary" : "text-secondary"
                    }`}
                  >
                    All Plans
                  </label>
                </div>
              </div>
            )}

            {planTypeLists?.map((item: any) => (
              <div
                className={`${
                  selectedPlanType?.includes(item.value)
                    ? "bg-blue-150"
                    : "hover:bg-blue-50"
                } p-3 grid grid-cols-12 gap-3 items-center`}
                key={item.value}
              >
                <div className="col-span-2 flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedPlanType?.includes(item.value)}
                    id={item.value}
                    name={item.value}
                    onChange={() => handleCheckboxChange(item.value)}
                    className="checkbox-input"
                  />
                </div>
                <div className="col-span-10 flex items-center">
                  <label
                    htmlFor={item.value}
                    className={`font-semibold text-14 ${
                      selectedPlanType?.includes(item.value)
                        ? "text-primary"
                        : "text-secondary"
                    } cursor-pointer`}
                  >
                    {item.label}
                  </label>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanTypeDropdown;
