import { TableBody, TableCell, TableRow } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import routesName from "../../../constants/routesName";
import { useAppDispatch } from "../../../redux/store";
import {
  deLinkDeviceAction,
  getHospitalById,
} from "../../../redux/addHospital/hospitalManagementReducer";
import { resetDeLinkDeviceState } from "../../../redux/addHospital/hospitalManagementSlice";
import errorToast from "../../../utils/errorToast";
import successToast from "../../../utils/successToast";
import { useSelector } from "react-redux";
import NormalTable from "../../../components/tables/normalTable";
import SearchInput from "../../../components/forms/searchInput";
import useDisplayToggle from "../../../utils/useDisplayToggle";
import ConfirmationModal from "../../../components/modals/confirmationModal";
import DeleteIcon from "../../../assests/icons/deleteIcon.png";
import { ActionTooltip } from "../../../utils/toolTip";

const HospitalDevices = ({ hospitalDetailsRes }: any) => {
  const styles = {
    // borderLeft: "1px solid #F2F4F7",
    borderRight: "1px solid #F2F4F7",
    borderBottom: "1px solid #F2F4F7",
    background: "white",
    minWidth: "150px",
    maxWidth: "150px",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#667085",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
    height: "70px",
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [productId, setProductId] = useState<number>();
  const [deviceId, setDeviceId] = useState<number>();

  const hospitalId = hospitalDetailsRes?.data?.data?.hospitalId;

  const productAndPlanInfo =
    hospitalDetailsRes?.data?.data?.productandPlanInfos;

  const modifiedDevices = productAndPlanInfo?.flatMap((product: any) => {
    const paidDevices = product?.paidDevices?.map((device: any) => ({
      productId: product?.productInfo?.productId,
      deviceType: "Paid",
      ...device,
    }));
    const packageDevices = product?.packageDevices?.map((device: any) => ({
      productId: product?.productInfo?.productId,
      deviceType: "Package",
      ...device,
    }));
    return [...paidDevices, ...packageDevices];
  });

  const [sortedData, setSortedData] = useState<any>(modifiedDevices);

  const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
  const [isMoreOption, setisMoreOption] = useState<any>(false);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisMoreOption,
  });

  useEffect(() => {
    if (isMoreOption === false) {
      setMoreOption(null);
    }
  }, [isMoreOption]);

  const toogleFn = (index: number) => {
    if (moreOption === index) {
      setisMoreOption(false);
      setMoreOption(null);
    } else {
      setMoreOption(index);
      setisMoreOption(true);
    }
  };
  const editHospitalFn = () => {
    navigate(
      `${routesName.hospitalManagementPage}/edit-product-plan/${hospitalId}`
    );
  };

  const delinkDeviceFn = (productId: number, deviceId: number) => {
    setProductId(productId);
    setDeviceId(deviceId);
    setIsShowModal(true);
    // dispatch(deLinkDeviceAction({ hospitalId, productId, deviceId }));
  };

  const deLinkDeviceRes = useSelector((data: any) => data?.deLinkDevice);
  useEffect(() => {
    if (deLinkDeviceRes?.status === "success") {
      successToast(deLinkDeviceRes?.data?.message);
      dispatch(getHospitalById(hospitalId));
    } else if (deLinkDeviceRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [deLinkDeviceRes]);

  useEffect(() => {
    return () => {
      dispatch(resetDeLinkDeviceState());
    };
  }, []);

  const TblBodyProductPlan = () => {
    return (
      <TableBody>
        {sortedData?.map((item: any, index: any) => {
          return (
            <TableRow key={index}>
              <TableCell
                style={{
                  ...styles,
                  minWidth: "80px",
                  maxWidth: "80px",
                  // textAlign: "center",
                  // position: "relative",
                  left: "0px",
                }}
              >
                <ActionTooltip
                  content={
                    <div
                      className="bg-white absolute bottom-[-30px] left-[-15px] mt-1 ms-12 border z-30 flex flex-col shadow-lg rounded-lg whitespace-nowrap"
                      ref={insideClickRef}
                    >
                      <button
                        className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                        type="button"
                        onClick={() => editHospitalFn()}
                      >
                        Edit
                      </button>
                      <button
                        className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg"
                        onClick={() =>
                          delinkDeviceFn(item.productId, item.deviceId)
                        }
                      >
                        De-link
                      </button>
                    </div>
                  }
                >
                  <button
                    className="text-blue-300 w-[24px] h-[24px]"
                    // onClick={() => toogleFn(index)}
                    type="button"
                    ref={onClickRef}
                  >
                    <MoreVertIcon />
                  </button>
                </ActionTooltip>
              </TableCell>
              <TableCell style={styles}>{item?.deviceType}</TableCell>
              <TableCell style={styles}>{item?.modelName}</TableCell>
              <TableCell style={styles}>{item?.serialNumber}</TableCell>
              <TableCell style={styles}>{item?.macAddress}</TableCell>
              <TableCell style={styles}>{item?.imeiNumber}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isShowModal}
        onClose={() => {
          setIsShowModal(false);
        }}
        onClick={() => {
          if (productId && deviceId)
            dispatch(deLinkDeviceAction({ hospitalId, productId, deviceId }));
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to de-link this device?`}
        description=""
        actionText={"De-Link"}
        isLoading={deLinkDeviceRes?.isLoading}
      />
      <div className="mt-4">
        <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 mb-5">
          <div className="col-span-1">
            <SearchInput setAllSearch={setSearchInput} />
          </div>
        </div>
        <div className="bg-white">
          <NormalTable
            columnData={columnHeader}
            setSortedData={setSortedData}
            data={modifiedDevices}
            searchValue={searchInput}
            columnsName={[
              "deviceType",
              "imeiNumber",
              "macAddress",
              "modelName",
              "serialNumber",
            ]}
            height={sortedData?.length <= 0 ? "h-[200px]" : "h-full"}
          >
            <TblBodyProductPlan />
          </NormalTable>
        </div>
      </div>
    </>
  );
};

export default HospitalDevices;

const columnHeader = [
  {
    label: "Action",
    value: "action",
    sort: false,
    search: false,
  },
  {
    label: "Device Type",
    value: "deviceType",
    sort: true,
    search: true,
  },
  {
    label: "Model",
    value: "modelName",
    sort: true,
    search: true,
  },
  {
    label: "Serial Number",
    value: "serialNumber",
    sort: true,
    search: true,
  },
  {
    label: "MAC Address",
    value: "macAddress",
    sort: true,
    search: true,
  },
  {
    label: "IMEI Number",
    value: "imeiNumber",
    sort: true,
    search: true,
  },
];
