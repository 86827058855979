import { useEffect } from "react";
import "./homePage.css";
import "./commonJeev.css";
import HomePageImage from "../../assests/jeevwebsite/images/homePageImage.png";
import UnderLineImage from "../../assests/jeevwebsite/icons/underLine.svg";
import BusinessmanImage from "../../assests/jeevwebsite/images/businessmanImage.png";
import LaptolAnalyticImage from "../../assests/jeevwebsite/icons/laptopAnalytic.png";
import ProcessImprovementImage from "../../assests/jeevwebsite/icons/processImprovement.png";
import HealthCheckupImage from "../../assests/jeevwebsite/icons/healthCheckup.png";
import HospitalBedWithIVMachineImage from "../../assests/jeevwebsite/icons/hospitalBedWithIVMachine.png";
import StaffImage from "../../assests/jeevwebsite/icons/staff.png";
import BillImage from "../../assests/jeevwebsite/icons/bill.png";
import ManageAssistLeftTop from "../../assests/jeevwebsite/icons/manageAsisstLeftTop.png";
import ClinicalAssistLeftTop from "../../assests/jeevwebsite/icons/clinicalAssistBottomLeft.png";
import NurseImage from "../../assests/jeevwebsite/images/nurseImage.png";
import NetworkImage from "../../assests/jeevwebsite/icons/network.png";
import CaseStudyImage from "../../assests/jeevwebsite/icons/caseStudy.png";
import SummaryListImage from "../../assests/jeevwebsite/icons/summaryList.png";
import ArtificialIntelligenceImage from "../../assests/jeevwebsite/icons/artificialIntelligence.png";
import ScheduleIcon from "../../assests/jeevwebsite/icons/scheduleIcon.svg";
import NeedADemoBottomLeftIcon from "../../assests/jeevwebsite/icons/needADemoBottomLeft.png";
// import CustomerImage from "../../assests/jeevwebsite/images/customerImage.png";
import HospitalImage from "../../assests/jeevwebsite/images/hospital.png";
import OpdClinicImage from "../../assests/jeevwebsite/images/opdClinic.png";
import ProcedureCenterImage from "../../assests/jeevwebsite/images/procedureCenter.png";
import MedicalCollege from "../../assests/jeevwebsite/images/medicalCollege.png";
import ResearchOrgImage from "../../assests/jeevwebsite/images/researchOrganisation.png";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const manageAssistantData = [
    {
      label: "Data-driven  Business Intelligence",
      icon: LaptolAnalyticImage,
    },
    {
      label: "Standardised Admin Process for Scalability",
      icon: ProcessImprovementImage,
    },
    {
      label: "In-Patients & Out-Patients Management",
      icon: HealthCheckupImage,
    },
    {
      label: "Optimal utilisation of Infrastructure",
      icon: HospitalBedWithIVMachineImage,
    },
    {
      label: "Effective management of Staff",
      icon: StaffImage,
    },
    {
      label: "Real-time updates of Revenue & Billing",
      icon: BillImage,
    },
  ];

  const clinicalAssistantData = [
    {
      label: "Interactive Patient Case Sheet ",
      icon: CaseStudyImage,
    },
    {
      label: "Simplified & Connected Workflows",
      icon: NetworkImage,
    },
    {
      label: "Effortless Clinical Documentation",
      icon: SummaryListImage,
    },
    {
      label: "Standardised Care Process across Specialities",
      icon: ProcessImprovementImage,
    },
    {
      label: "Personalised & Adaptive Clinical Intelligence",
      icon: ArtificialIntelligenceImage,
    },
    {
      label: "Instant Analytics & Decision Support",
      icon: LaptolAnalyticImage,
    },
  ];

  const ourCustomersData = [
    { image: HospitalImage, label: "Hospitals" },
    { image: ProcedureCenterImage, label: "Procedure Centers" },
    { image: OpdClinicImage, label: "OPD Clinics" },
    { image: MedicalCollege, label: "Medical Colleges" },
    { image: ResearchOrgImage, label: "Research Organisations" },
  ];

  const navigateToOtherPage = () => {
    window.open("https://calendly.com/vinayakasiddesh", "_blank");
  };

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  return (
    <div className="h-full font_family">
      <div className="home_page_image_conatiner">
        <div className="flex flex-col text-center pt-4">
          <span className="lg:text-[38px] md:text-[28px] text-[22px] primary_color font-semibold px-5">
            Smart Technology Solutions For Healthcare
          </span>
          <span className="lg:text-[20px] md:text-[16px] text-[12px] secondary_color font-medium sm:pt-4 pt-2 px-5">
            Transforming Hospital Management And Patient Care With Technology &
            AI
          </span>
        </div>
        <div className="flex justify-center mt-4 sm:mt-6">
          <img
            src={HomePageImage}
            className="homepage_image w-full h-full"
            alt="HomePageImage"
            onContextMenu={disableRightClick}
          />
        </div>
      </div>

      <div className="ourProducts_container pt-8 sm:pb-20 sm:pt-16 pb-6">
        <div className="text-center">
          <span className="lg:text-[32px] md:text-[25px] text-[18px] tertiary_color font-semibold">
            Our Products
          </span>
          <div className="w-full flex justify-center pt-3">
            <img
              src={UnderLineImage}
              alt="UnderLineImage"
              onContextMenu={disableRightClick}
            />
          </div>
        </div>

        <div className="management_assistant_card relative">
          <div className="block md:hidden">
            <div className="flex flex-col text-center">
              <span className="lg:text-[32px] xsm:text-[24px] text-[18px] tertiary_color">
                Management Assistant
              </span>
              <span className="lg:text-[20px] xsm:text-[16px] text-[14px] secondary_color">
                Strategic Hospital Management App for Owners & Admins
              </span>
            </div>
          </div>

          <div className="w-full flex flex-wrap items-center">
            <div className="w-full md:w-1/2 order-last md:order-first">
              <div className="hidden md:block ps-0 xl:ps-8">
                <div className="flex flex-col">
                  <span className="lg:text-[32px] xsm:text-[25px] text-[20px] tertiary_color">
                    Management Assistant
                  </span>
                  <span className="lg:text-[20px] xsm:text-[16px] text-[12px] secondary_color">
                    Strategic Hospital Management App for Owners & Admins
                  </span>
                </div>
              </div>

              <div className="pt-2 sm:pt-8 xl:ps-10 md:ps-5 sm:ps-10 xsm:ps-5 pb-2">
                {manageAssistantData?.map((item, index) => (
                  <div
                    key={index}
                    className="flex gap-3 items-center py-2 xl:py-3 ps-1 xl:ps-5"
                  >
                    <img
                      src={item?.icon}
                      alt="icons"
                      className="assistant_icons"
                      onContextMenu={disableRightClick}
                    />
                    <span className="lg:text-[18px] xsm:text-[16px] text-[14px] primary_color font-normal">
                      {item?.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <div className="absolute top-0 right-0 block sm:hidden">
                <img
                  src={ManageAssistLeftTop}
                  alt="ManageAssistLeftTop"
                  className="ManageAssistLeftTop"
                  onContextMenu={disableRightClick}
                />
              </div>

              <img
                src={BusinessmanImage}
                className="bussines_man_image w-full h-full"
                alt="BusinessmanImage"
                onContextMenu={disableRightClick}
              />
            </div>
          </div>
        </div>

        <div className="management_assistant_card mt-0 sm:mt-4 relative">
          <div className="block md:hidden">
            <div className="flex flex-col text-center">
              <span className="lg:text-[32px] xsm:text-[24px] text-[18px] tertiary_color">
                Clinical Assistant
              </span>
              <span className="lg:text-[20px] xsm:text-[16px] text-[14px] secondary_color">
                Smart Patient Care App for Clinicians
              </span>
            </div>
          </div>

          <div className="w-full flex flex-wrap items-center">
            <div className="w-full md:w-1/2 flex justify-center">
              <div className="absolute bottom-0 left-0 block sm:hidden">
                <img
                  src={ClinicalAssistLeftTop}
                  alt="ClinicalAssistLeftTop"
                  className="ManageAssistLeftTop"
                  onContextMenu={disableRightClick}
                />
              </div>

              <img
                src={NurseImage}
                className="bussines_man_image w-full h-full"
                alt="NurseImage"
                onContextMenu={disableRightClick}
              />
            </div>
            <div className="w-full md:w-1/2 ">
              <div className="hidden md:block ps-0 xl:ps-8">
                <div className="flex flex-col">
                  <span className="lg:text-[32px] xsm:text-[24px] text-[18px] tertiary_color">
                    Clinical Assistant
                  </span>
                  <span className="lg:text-[20px] xsm:text-[16px] text-[14px] secondary_color">
                    Smart Patient Care App for Clinicians
                  </span>
                </div>
              </div>

              <div className="pt-2 sm:pt-6 xl:ps-10 md:ps-5 sm:ps-10 xsm:ps-5 pb-2">
                {clinicalAssistantData?.map((item, index) => (
                  <div
                    key={index}
                    className="flex gap-3 items-center py-2 xl:py-3 ps-1 xl:ps-5"
                  >
                    <img
                      src={item?.icon}
                      alt="icons"
                      className="assistant_icons"
                      onContextMenu={disableRightClick}
                    />
                    <span className="lg:text-[18px] xsm:text-[16px] text-[14px] primary_color font-normal">
                      {item?.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="need_a_demo_card py-6 relative">
        <div className="flex text-center flex-col justify-center">
          <span className="text-white lg:text-[32px] md:text-[24px] text-[20px] font-semibold">
            Need a demo?
          </span>
          <span className="text-white lg:text-[24px] md:text-[20px] sm:text-[14px] py-3 px-3 sm:px-0">
            Talk to our passionate team to experience jeev.ai products.
          </span>
          <div className="flex justify-center mt-3 my-2">
            <button
              className="schedule_button lg:text-[20px] md:text-[16px] sm:text-base font-medium flex items-center gap-2 border-none"
              type="button"
              onClick={navigateToOtherPage}
            >
              <img
                src={ScheduleIcon}
                width={24}
                height={24}
                alt="ScheduleIcon"
                onContextMenu={disableRightClick}
              />
              Schedule Demo
            </button>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <img
            className="needADemoBottomLeftIcon"
            src={NeedADemoBottomLeftIcon}
            alt="NeedADemoBottomLeftIcon"
            onContextMenu={disableRightClick}
          />
        </div>
      </div>
      <div className="ourCustomers_container pb-5">
        <div className="text-center pt-5 md:pt-10">
          <span className="lg:text-[32px] md:text-[24px] text-[18px] tertiary_color font-semibold">
            Our Customers
          </span>
          <div className="w-full flex justify-center py-2">
            <img
              src={UnderLineImage}
              alt="UnderLineImage"
              onContextMenu={disableRightClick}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-6 ourCustomerContainer sm:my-8 my-3">
          {ourCustomersData?.map((item) => (
            <div className="ourCustomerCard grid grid-cols-12">
              <div className="col-span-6 w-full flex justify-center items-center">
                <img src={item.image} className="p-3 object-fill" />
              </div>
              <div className="col-span-6 flex flex-wrap justify-center items-center text-center ">
                <span className="ourCustomerText">{item.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
