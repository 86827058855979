import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { inputStyles } from "../mui-styles";

interface InputProps {
  label: string;
  id: string;
  type?: string;
  control: any;
  errorMessage?: string | undefined;
  placeholder?: string;
  defaultValue?: string | number | undefined;
  disabled?: boolean;
  prefix?: boolean;
  prefixText?: string;
  required?: boolean;
  customErrorMessage?: string;
  applyNumberValidation?: boolean;
  rows?: number;
  maxRows?: number;
  maxlength?: number;
  isMaxlength?: boolean;
  maxlengthMessage?: string;
}

export const TextForm: React.FC<InputProps> = ({
  label,
  id,
  type = "text",
  control,
  errorMessage,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
  prefix = false,
  prefixText,
  required = true,
  customErrorMessage = `${label} is required`,
  applyNumberValidation = false, 
  rows = 2,
  maxRows = 4,
  maxlength = 100,
  isMaxlength = false,
  maxlengthMessage = "Limit exceeded",
}) => {
  const patternRule = applyNumberValidation
    ? {
        pattern: {
          value: /^\d+$/,
          message: "Please enter a valid number.",
        },
      }
    : {};

  const maxLengthRule = isMaxlength
    ? {
        maxLength: {
          value: maxlength,
          message: maxlengthMessage,
        },
      }
    : {};

  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || ""}
        rules={{
          required: required ? customErrorMessage : false,
          ...maxLengthRule,
          ...patternRule,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            sx={inputStyles.select}
            fullWidth
            rows={rows}
            maxRows={maxRows}
            multiline
            id={id}
            {...field}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            error={Boolean(error?.message) || Boolean(errorMessage)}
            helperText={error?.message}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            InputProps={{
              startAdornment: prefix && (
                <InputAdornment position="start">{prefixText}</InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
