import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import PageNotFound from "../pages/pageNotFound";
import ForgotPassword from "../pages/forgotPassword";
// import ManagementInfoSystem from "../pages/managementInformationSystem/managementInfoSys";
import HospitalManagement from "../pages/hospitalManagement/hospitalManage";
import NotificationTabs from "../pages/notificationModule/notificationTabs";
import FeedBack from "../pages/feedBack";
import DashBoard from "../pages/dashBoard";
import PublicRoutes from "./publicRoutes";
import PrivateRoutes from "./privateRoutes";
import routesName from "../constants/routesName";
import { ToastContainer } from "react-toastify";
import ResetPasswordRoutes from "./resetPasswordRoutes";
import SetNewPassword from "../pages/setNewPassword";
import ErrorPage from "../pages/errorPage";
import AccountManager from "../pages/accountManager/accountManager";
import Devices from "../pages/devices";
import LoginsDetails from "../pages/loginsDetails";
import AddHospital from "../pages/hospitalManagement/addHospitalInformation";
import OnboardHospital from "../pages/hospitalManagement/onboardHospital";
import HospitalProductActivation from "../pages/hospitalManagement/productActivation";
import HospitalDetails from "../pages/hospitalManagement/hospitalDetails";
import EditHospitalInformation from "../pages/hospitalManagement/editHospitalInfo";
import EditProductAndPlan from "../pages/hospitalManagement/editProductAndPlan";
import OnlyTesting from "../pages/onlytesting";
import AddEditPersonalInfo from "../pages/accountManager/addEditPersonalInfo";
import AddEditRolesAuthorities from "../pages/accountManager/addRolesAuthorities";
import enums from "../constants/enums";
import EditRolesAuthorities from "../pages/accountManager/editRolesAuthorities";
import AccountManagerProfile from "../pages/accountManager/accountManagerProfile";
import MISDepartments from "../pages/managementInformationSystem/departments";
import MISDiagnosis from "../pages/managementInformationSystem/diagnosis";
import MisGraphs from "../pages/managementInformationSystem/misGraphs";
import Userprofile from "../pages/userProfile";
import ProfilePasswordReset from "../pages/profilePasswordReset";
import DeviceRoute from "./DeviceRoute";
import UnLinkDevice from "../pages/device/unLinkDevice";
import LinkDevices from "../pages/device/linkDevices";
import AddDevice from "../pages/device/addDevice";
import EditDevice from "../pages/device/editDevice";
import AllkDevice from "../pages/device/allDevice";
import { Suspense, lazy } from "react";
import { LoadingSpinner } from "../components/ui/loadinSpinner";
import AccountManagerRoute from "./accountManagerRoute";
import NotificationRoutes from "./notificationRoutes";
import MisRoutes from "./misRoutes";
import HospitalModuleRoutes from "./hospitalModuleRoutes";
import LoginDetailsRoutes from "./loginDetailsRoutes";
import FeedbackRoutes from "./feedbackRoutes";
import JeevwebsiteRoutes from "./jeevwebsiteRoutes";
import HomePage from "../pages/jeevwebiste/homePage";
import AboutUsPage from "../pages/jeevwebiste/aboutUsPage";
import ContactUsPage from "../pages/jeevwebiste/contactUsPage";
const MisPage = lazy(
  () => import("../pages/managementInformationSystem/managementInfoSys")
);

export const Index = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path={routesName.errorPage} element={<ErrorPage />} />
        <Route element={<PublicRoutes />}>
          <Route path={routesName.cmpHomePage} element={<Login />} />
          <Route
            path={routesName.forgotPasswordPage}
            element={<ForgotPassword />}
          />
          <Route
            path={routesName.resetPasswordPage}
            element={<SetNewPassword />}
          />
        </Route>
        <Route element={<JeevwebsiteRoutes />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path={routesName.loginDashboardPage} element={<DashBoard />} />
          <Route element={<MisRoutes />}>
            <Route
              path={routesName.managementInfoSystemPage}
              // element={<ManagementInfoSystem />}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <MisPage />
                </Suspense>
              }
            />
            <Route path={routesName.MisGraphs} element={<MisGraphs />} />
            <Route
              path={routesName.misDepartment}
              element={<MISDepartments />}
            />
            <Route path={routesName.misDiagnosis} element={<MISDiagnosis />} />
          </Route>
          <Route element={<HospitalModuleRoutes />}>
            <Route
              path={routesName.hospitalManagementPage}
              element={<HospitalManagement />}
            />
            <Route
              path={`${routesName.hospitalManagementPage}/:id`}
              element={<HospitalDetails />}
            />

            <Route
              path={`${routesName.hospitalManagementPage}/edit-hospital-info/:id`}
              element={<EditHospitalInformation />}
            />
            <Route
              path={`${routesName.hospitalManagementPage}/edit-product-plan/:id`}
              element={<EditProductAndPlan />}
            />

            <Route
              path={`${routesName.hospitalManagementPage}/hospital/:action`}
              element={<AddHospital />}
            />
            <Route
              path={`${routesName.hospitalManagementPage}/hospital/:action${routesName.productActivationPage}`}
              element={<HospitalProductActivation />}
            />
            <Route
              path={`${routesName.hospitalManagementPage}/hospital/:action${routesName.productActivationPage}${routesName.hospitalOnboardPage}`}
              element={<OnboardHospital />}
            />
          </Route>
          <Route element={<AccountManagerRoute />}>
            <Route
              path={routesName.accountManagerPage}
              element={<AccountManager />}
            />
            <Route
              path={`${routesName.accountManagerPage}/:id`}
              element={<AccountManagerProfile />}
            />
            <Route path={routesName.devicesPage} element={<Devices />} />
            <Route
              path={`${routesName.accountManagerPage}${routesName.personalInfoPage}/:action`}
              element={<AddEditPersonalInfo />}
            />
            <Route
              path={`${routesName.accountManagerPage}${routesName.rolesAuthoritiesPage}/${enums.add}`}
              element={<AddEditRolesAuthorities />}
            />
            <Route
              path={`${routesName.accountManagerPage}${routesName.rolesAuthoritiesPage}/${enums.edit}`}
              element={<EditRolesAuthorities />}
            />
            <Route
              path={`${routesName.accountManagerPage}${routesName.rolesAuthoritiesPage}/${enums.edit}`}
              element={<EditRolesAuthorities />}
            />
          </Route>
          <Route element={<NotificationRoutes />}>
            <Route
              path={routesName.notificationPage}
              element={<NotificationTabs />}
            />
          </Route>
          <Route path={routesName.addDevice} element={<AddDevice />} />
          <Route
            path={`${routesName.devicesPage}/${routesName.editdevice}`}
            element={<EditDevice />}
          />
          <Route element={<DeviceRoute />}>
            <Route
              path={`${routesName.devicesPage}/${routesName.unlinkDevice}`}
              element={<UnLinkDevice />}
            />

            <Route
              path={`${routesName.devicesPage}/${routesName.linkDevice}`}
              element={<LinkDevices />}
            />
            <Route
              path={`${routesName.devicesPage}/${routesName.alldevice}`}
              element={<AllkDevice />}
            />
          </Route>
          <Route element={<LoginDetailsRoutes />}>
            <Route
              path={routesName.loginsDetailsPage}
              element={<LoginsDetails />}
            />
          </Route>
          <Route element={<FeedbackRoutes />}>
            <Route path={routesName.feedbackPage} element={<FeedBack />} />
          </Route>
          <Route path={routesName.userProfilePage} element={<Userprofile />} />
          <Route
            path={routesName.profilePasswordReset}
            element={<ProfilePasswordReset />}
          />

          <Route path={"testing"} element={<OnlyTesting />} />
        </Route>
      </Routes>
      <ToastContainer limit={3} />
    </>
  );
};
