export const AllHospitalheaderDatas = ({
  hospitalMatrix,
  hospitalOccupancy,
  productPlan,
}: {
  hospitalMatrix: string;
  hospitalOccupancy: string;
  productPlan: string;
}) => {
  const LabelData = [
    {
      label: "Action",
      value: "action",
      sort: false,
      search: false,
      width: "80px",
    },
    {
      label: "Hospital",
      value: "name",
      width: "250px",
    },
    {
      label: "Status",
      value: "status",
      search: false,
      width: "150px",
      removeSticky: true,
    },
    {
      label: "Location",
      value: "city",
      width: "180px",
      removeSticky: true,
    },
    {
      label: "Onboarded Date",
      value: "onboarding_date",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      width: "200px",
    },
    {
      label: "Products",
      value: "products",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "200px",
    },
    {
      label: "Add-on Services",
      value: "add_on_service",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "250px",
    },
    {
      label: "Devices",
      value: "devices",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "220px",
    },
    {
      label: "Service Type",
      value: "service_type",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "Plan Type",
      value: "plan_type",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "Patient Type",
      value: "patient_type",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "Price",
      value: "price",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },

    {
      label: "Plan Validity",
      value: "plan_validity",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "320px",
    },
    {
      label: "Ownership",
      value: "ownerShip",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "Onboarding Source",
      value: "onboarding_source",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "170px",
    },
    {
      label: "Onboarding Name",
      value: "onboarding_source_name",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      width: "220px",
    },
    {
      label: "Security Deposit",
      value: "security_deposit",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "Device Cost",
      value: "device_cost",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "Instalments",
      value: "instalments",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "One-time Cost",
      value: "one_time_cost",
      removeSticky: true,
      hide: hospitalMatrix === hospitalOccupancy ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "Speciality",
      value: "speciality",
      removeSticky: true,
      hide: hospitalMatrix === productPlan ? true : false,
      search: false,
      sort: false,
      width: "200px",
    },
    {
      label: "Bed Size",
      value: "bed_size",
      removeSticky: true,
      hide: hospitalMatrix === productPlan ? true : false,
      search: false,
      sort: false,
      width: "150px",
    },
    {
      label: "ICU Beds",
      value: "ICU_beds",
      removeSticky: true,
      hide: hospitalMatrix === productPlan ? true : false,
      search: false,
      sort: false,
      width: "300px",
    },
    {
      label: "Ward Beds",
      value: "ward_beds",
      removeSticky: true,
      hide: hospitalMatrix === productPlan ? true : false,
      search: false,
      sort: false,
      width: "300px",
    },
    {
      label: "OT Tables",
      value: "OT_tables",
      removeSticky: true,
      hide: hospitalMatrix === productPlan ? true : false,
      search: false,
      sort: false,
      width: "420px",
    },
    {
      label: "Equipments",
      value: "equipments",
      removeSticky: true,
      hide: hospitalMatrix === productPlan ? true : false,
      search: false,
      sort: false,
      width: "600px",
    },
  ];

  return LabelData;
};

export const InProgressHosHeaderDatas = () => {
  const LabelData = [
    {
      label: "Action",
      value: "action",
      sort: false,
      search: false,
      width: "80px",
    },
    {
      label: "Hospital",
      value: "name",
      display: false,
      width: "250px",
    },
    {
      label: "Location",
      value: "city",
      removeSticky: true,
      display: false,
      width: "180px",
    },
    {
      label: "Speciality",
      value: "speciality",
      removeSticky: true,
      sort: false,
      search: false,
      width: "200px",
    },
    {
      label: "Bed Size",
      value: "bed_size",
      removeSticky: true,
      sort: false,
      search: false,
      width: "150px",
    },
    {
      label: "Owner & Operator",
      value: "owner_operator",
      removeSticky: true,
      sort: false,
      search: false,
      width: "200px",
    },
    {
      label: "Source of Onboarding",
      value: "source_of_onboarding",
      removeSticky: true,
      sort: false,
      search: false,
      width: "200px",
    },
    {
      label: "Hospital Management",
      value: "management_name",
      removeSticky: true,
      width: "230px",
    },
    {
      label: "Account Manager",
      value: "account_manager",
      removeSticky: true,
      sort: false,
      search: false,
      width: "200px",
    },
  ];

  return LabelData;
};
