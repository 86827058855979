import { createSlice } from "@reduxjs/toolkit";
import { getNotifications , deleteNotifications, shareNotifications, sendNotifications} from "./notificationReducer";

const notificationslice = createSlice({
  name: "getNotifications",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetNotificationbystatus: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getNotifications.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const { resetNotificationbystatus } = notificationslice.actions;
export const notificationbyStatusreducer = notificationslice.reducer;

const notificationDeleteslice = createSlice({
  name: "deleteNotifications",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetDeletenotifications: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteNotifications.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(deleteNotifications.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(deleteNotifications.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const { resetDeletenotifications } = notificationDeleteslice.actions;
export const deleteNotificationsreducer = notificationDeleteslice.reducer;

const notificationShareslice = createSlice({
  name: "shareNotifications",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetSharenotifications: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(shareNotifications.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(shareNotifications.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(shareNotifications.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const { resetSharenotifications } = notificationShareslice.actions;
export const shareNotificationsreducer = notificationShareslice.reducer;


const notificationSendslice = createSlice({
  name: "sendNotifications",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetSendnotifications: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendNotifications.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(sendNotifications.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(sendNotifications.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const { resetSendnotifications } = notificationSendslice.actions;
export const sendNotificationsreducer = notificationSendslice.reducer;
