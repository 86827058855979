import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DynamicTable from "../../components/tables/dynamicTable";
import useScreenSizeDetector from "../../utils/useScreenSizeDetector";
import { PrimaryButton } from "../../components/buttons/buttons";
import { useAppDispatch } from "../../redux/store";
import {
  getActiveHosCount,
  getActivePaidCount,
  getActivePilotCount,
  getAllHospital,
  getExpiredHosCount,
  getHospitalByStatus,
  getInactiveHosCount,
  getInprogressHosCount,
} from "../../redux/addHospital/hospitalManagementReducer";
import { useSelector } from "react-redux";
import {
  TblBodyInProgressProductPlan,
  TblBodyProductPlan,
} from "../../features/hospitalManagement/hospitalManagement/tableBody";
import {
  AllHospitalheaderDatas,
  InProgressHosHeaderDatas,
} from "../../features/hospitalManagement/hospitalManagement/labelData";
import {
  allHospitalCheckBoxData,
  inProgressHosCheckBoxData,
} from "../../features/hospitalManagement/hospitalManagement/checkboxData";
import {
  resetGetAllHospitalState,
  resetGetHospitalByIdState,
} from "../../redux/addHospital/hospitalManagementSlice";
import { StatusCard } from "../../features/hospitalManagement/hospitalManagement/statusCard";
import sortValues from "../../constants/sortColumnValue";
import { SearchQuery } from "../../utils/searchQuery";
import enums from "../../constants/enums";
import { hositalMatrixOptions } from "../../jsonData/addHospitalsData";
import { HospitalMatrixType } from "../../modals/IhospitalManagement";
import InnerLayout from "../../layout/innerLayout";
import { AddCircleOutlineIcons } from "../../components/muiIconsImport";
import ColumnSettingBtn from "../../components/columnSetting/columnSettingBtn";
import SearchInput from "../../components/forms/searchInput";
import SelectComponent from "../../components/forms/selectComponent";

const HospitalManagement = () => {
  const { width } = useScreenSizeDetector();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getAllHospitalRes = useSelector((data: any) => data?.getAllHospital);
  const getHospitalByStatusRes = useSelector(
    (data: any) => data?.getHospitalByStatus
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState(sortValues.order);
  const [orderBy, setOrderBy] = useState(sortValues.orderBy);
  const [filter, setFilter] = useState<any>({});
  const [allSearch, setAllSearch] = useState("");
  const [hospitalMatrix, setHospitalMatrix] = useState<HospitalMatrixType>(
    enums.hospitalMatrixTypeAll
  );
  const hospitalStatus = sessionStorage.getItem("hospitalStatus");
  const [hosStatus, setHosStatus] = useState<string>(
    (sessionStorage.getItem("hospitalStatus") as string) ?? "allStatus"
  );

  useEffect(() => {
    setHosStatus(
      (sessionStorage.getItem("hospitalStatus") as string) ?? "allStatus"
    );
  }, [hospitalStatus]);

  const searchQuery = SearchQuery({ filter });

  // all hospital table header data
  const headerData = AllHospitalheaderDatas({
    hospitalMatrix,
    hospitalOccupancy: enums.hospitalOccupancy,
    productPlan: enums.productPlan,
  });
  // inprogress hospital table header data
  const inprogessHeaderData = InProgressHosHeaderDatas();

  const [checkboxData, setCheckboxData] = useState(allHospitalCheckBoxData);
  const [labelData, setLabelData] = useState<any>(headerData);

  // initial value for column setting while checking the box
  const [initialCHKVal, setInitialCHKVal] = useState(
    checkboxData?.map((item) => item.value)
  );
  const [initialCHKOrder, setInitialCHKOrder] = useState(
    checkboxData.map((item) => item.value)
  );
  // applied value for column setting
  const [selectedValues, setSelectedValues] = useState<string[]>(
    checkboxData?.map((item) => item.value)
  );
  const [selectedValuesOrder, setSelectedValuesOrder] = useState<string[]>(
    checkboxData.map((item) => item.value)
  );

  useEffect(() => {
    // handling ckeckbox and column header data based on inprogress or for all hospital
    if (hosStatus !== enums.inprogress) {
      setCheckboxData(allHospitalCheckBoxData);
      setFilterValues(allHospitalCheckBoxData);
      setLabelData(headerData);
      setInitialCHKVal(allHospitalCheckBoxData?.map((item) => item.value));
      setInitialCHKOrder(allHospitalCheckBoxData?.map((item) => item.value));
      setSelectedValues(allHospitalCheckBoxData?.map((item) => item.value));
      setSelectedValuesOrder(
        allHospitalCheckBoxData?.map((item) => item.value)
      );
    } else if (hosStatus === enums.inprogress) {
      setCheckboxData(inProgressHosCheckBoxData);
      setFilterValues(inProgressHosCheckBoxData);
      setLabelData(inprogessHeaderData);
      setInitialCHKVal(inprogessHeaderData?.map((item) => item.value));
      setInitialCHKOrder(inprogessHeaderData?.map((item) => item.value));
      setSelectedValues(inProgressHosCheckBoxData?.map((item) => item.value));
      setSelectedValuesOrder(
        inProgressHosCheckBoxData?.map((item) => item.value)
      );
    }
  }, [hosStatus]);

  // api call response data for all hospital
  const [currentHosiptalData, setCurrentHosiptalData] = useState<any>([]);
  // api call response data for status vise hospital
  const [hosDataByStatus, sethosDataByStatus] = useState<any>([]);

  const addHospitalFn = () => {
    navigate("hospital/add", { state: { action: enums.add } });
  };

  useEffect(() => {
    dispatch(getActiveHosCount(enums.active));
    dispatch(getExpiredHosCount(enums.expired));
    dispatch(getInactiveHosCount(enums.inactive));
    dispatch(getInprogressHosCount(enums.inprogress));
    dispatch(
      getActivePaidCount({ status: enums.active, servicetype: enums.paid })
    );
    dispatch(
      getActivePilotCount({ status: enums.active, servicetype: enums.pilot })
    );

    return () => {
      dispatch(resetGetAllHospitalState());
      dispatch(resetGetHospitalByIdState());
    };
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    // inital call - for all hospital api call
    if (hosStatus === "allStatus") {
      dispatch(
        getAllHospital({
          order: order,
          orderBy: orderBy,
          page: page,
          rowsPerPage: rowsPerPage,
          searchQuery: searchQuery ?? "",
          searchKeywords: allSearch,
        })
      );
    }
  }, [order, orderBy, page, rowsPerPage, allSearch, searchQuery, hosStatus]);

  const activeHosCountRes = useSelector((data: any) => data?.getActiveHosCount);
  const expiredHosCountRes = useSelector(
    (data: any) => data?.getExpiredHosCount
  );
  const inactiveHosCountRes = useSelector(
    (data: any) => data?.getInactiveHosCount
  );
  const inprogressHosCountRes = useSelector(
    (data: any) => data?.getInprogressHosCount
  );
  const activePaidCountRes = useSelector(
    (data: any) => data?.getActivePaidCount
  );
  const activePilotCountRes = useSelector(
    (data: any) => data?.getActivePilotCount
  );

  useEffect(() => {
    // for all hospiatl api call handling response
    if (hosStatus === "allStatus") {
      if (getAllHospitalRes?.status === "success") {
        setErrorMessage("");
        setCurrentHosiptalData(getAllHospitalRes?.data?.data ?? []);
      } else if (getAllHospitalRes?.status === "failed") {
        setErrorMessage("Something went Wrong");
      }
    }
  }, [getAllHospitalRes]);

  useEffect(() => {
    // for status vise hospiatl api call handling response
    if (hosStatus !== "allStatus") {
      if (getHospitalByStatusRes?.status === "success") {
        setErrorMessage("");
        sethosDataByStatus(getHospitalByStatusRes?.data?.data ?? []);
      } else if (getHospitalByStatusRes?.status === "failed") {
        setErrorMessage("Something went Wrong");
      }
    }
  }, [getHospitalByStatusRes]);

  useEffect(() => {
    // handling column header data based on inprogress or for all hospital
    if (hosStatus !== enums.inprogress) {
      const updatedLabelData = headerData.filter((item: any) =>
        selectedValues.includes(item.value)
      );
      setLabelData(updatedLabelData);
    } else if (hosStatus === enums.inprogress) {
      const updatedLabelData = inprogessHeaderData.filter((item: any) =>
        selectedValues.includes(item.value)
      );
      setLabelData(updatedLabelData);
    }
  }, [selectedValues, hospitalMatrix, hosStatus]);

  const handleCheckboxChange = (value: string) => {
    // show and hide column based on checkbox
    if (initialCHKVal.includes(value)) {
      setInitialCHKVal(initialCHKVal.filter((item) => item !== value));
    } else {
      setInitialCHKVal([...initialCHKVal, value]);
    }

    const newOrder = initialCHKOrder.includes(value)
      ? initialCHKOrder.filter((item) => item !== value)
      : [...initialCHKOrder, value];
    setInitialCHKOrder(newOrder);
  };

  // apply filter for column setting
  const handleApplyFilter = () => {
    setSelectedValues(initialCHKVal);
    setSelectedValuesOrder(initialCHKOrder);
  };

  // cancel filter for column setting
  const handleCancelFilter = () => {
    setInitialCHKVal(checkboxData?.map((item) => item.value));
    setInitialCHKOrder(checkboxData?.map((item) => item.value));
    setSelectedValues(checkboxData?.map((item) => item.value));
    setSelectedValuesOrder(checkboxData?.map((item) => item.value));
  };

  const [filterValues, setFilterValues] = useState(allHospitalCheckBoxData);

  let hospitalSearchtimer: ReturnType<typeof setTimeout>;
  const handleFilterSearch = (inputValue: string) => {
    // const inputValue = e?.target?.value;
    clearTimeout(hospitalSearchtimer);
    hospitalSearchtimer = setTimeout(() => {
      const filteredValue = checkboxData.filter((item) =>
        item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );

      if (filteredValue) {
        setFilterValues(filteredValue);
      }
    }, 1000);
  };

  // status wise hospital api call
  const handleStatusClickFn = (value: string) => {
    window.location.reload();
    setPage(sortValues.page);
    setRowsPerPage(sortValues.rowsPerPage);
    if (value === "allStatus") {
      dispatch(
        getAllHospital({
          order: order,
          orderBy: orderBy,
          page: page,
          rowsPerPage: rowsPerPage,
          searchQuery: searchQuery ?? "",
          searchKeywords: allSearch,
        })
      );
    } else {
      dispatch(
        getHospitalByStatus({
          status: value,
          orderBy: sortValues.orderBy,
          order: sortValues.order,
          page: sortValues.page,
          rowsPerPage: sortValues.rowsPerPage,
          searchQuery: searchQuery ?? "",
          searchKeywords: allSearch,
        })
      );
    }
    setHosStatus(value);
    sessionStorage.setItem("hospitalStatus", value);
  };

  // filter apply for status wise hospital
  useEffect(() => {
    if (hosStatus !== "allStatus") {
      dispatch(
        getHospitalByStatus({
          status: hosStatus,
          orderBy: orderBy,
          order: order,
          page: page,
          rowsPerPage: rowsPerPage,
          searchQuery: searchQuery ?? "",
          searchKeywords: allSearch,
        })
      );
    }
  }, [hosStatus, order, orderBy, page, rowsPerPage, allSearch, searchQuery]);

  return (
    <InnerLayout title="Hospital Management">
      <div className="px-6 py-5 ">
        <div className="grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-9 xl:grid-cols-11  gap-6">
          <div className="md:col-span-2 lg:col-span-3  xl:col-span-2 ">
            <StatusCard
              status="All"
              count={
                activeHosCountRes?.data?.data +
                expiredHosCountRes?.data?.data +
                inactiveHosCountRes?.data?.data +
                inprogressHosCountRes?.data?.data
              }
              value={"allStatus"}
              // active={enums.active}
              hosStatus={hosStatus}
              onClick={handleStatusClickFn}
              loading={
                getAllHospitalRes?.isLoading ||
                getHospitalByStatusRes?.isLoading
              }
              localstorageboolean={
                activeHosCountRes?.data?.data +
                  expiredHosCountRes?.data?.data +
                  inactiveHosCountRes?.data?.data +
                  inprogressHosCountRes?.data?.data !==
                  undefined &&
                !isNaN(
                  activeHosCountRes?.data?.data +
                    expiredHosCountRes?.data?.data +
                    inactiveHosCountRes?.data?.data +
                    inprogressHosCountRes?.data?.data
                )
              }
            />
          </div>
          <div className="md:col-span-2 lg:col-span-3 xl:col-span-3 ">
            <StatusCard
              status="Active"
              count={activeHosCountRes?.data?.data}
              value={enums.active}
              paidCount={activePaidCountRes?.data?.data}
              pilotCount={activePilotCountRes?.data?.data}
              active={enums.active}
              hosStatus={hosStatus}
              onClick={handleStatusClickFn}
              // while laoding other hospital status based api call cannot be called
              loading={
                getAllHospitalRes?.isLoading ||
                getHospitalByStatusRes?.isLoading
              }
              localstorageboolean={
                activeHosCountRes?.data?.data !== undefined &&
                !isNaN(activeHosCountRes?.data?.data)
              }
            />
          </div>
          <div className="md:col-span-2 lg:col-span-3 xl:col-span-2 ">
            <StatusCard
              status="Expired"
              count={expiredHosCountRes?.data?.data}
              value={enums.expired}
              // active={enums.active}
              hosStatus={hosStatus}
              onClick={handleStatusClickFn}
              loading={
                getAllHospitalRes?.isLoading ||
                getHospitalByStatusRes?.isLoading
              }
              localstorageboolean={
                expiredHosCountRes?.data?.data !== undefined &&
                !isNaN(expiredHosCountRes?.data?.data)
              }
            />
          </div>

          <div className="md:col-span-2 lg:col-span-3 xl:col-span-2 ">
            <StatusCard
              status="Deactivated"
              count={inactiveHosCountRes?.data?.data}
              value={enums.inactive}
              // active={enums.active}
              hosStatus={hosStatus}
              onClick={handleStatusClickFn}
              loading={
                getAllHospitalRes?.isLoading ||
                getHospitalByStatusRes?.isLoading
              }
              localstorageboolean={
                inactiveHosCountRes?.data?.data !== undefined &&
                !isNaN(inactiveHosCountRes?.data?.data)
              }
            />
          </div>

          <div className="md:col-span-2 lg:col-span-3 xl:col-span-2 ">
            <StatusCard
              status="In Progress"
              count={inprogressHosCountRes?.data?.data}
              value={enums.inprogress}
              // active={enums.active}
              hosStatus={hosStatus}
              onClick={handleStatusClickFn}
              loading={
                getAllHospitalRes?.isLoading ||
                getHospitalByStatusRes?.isLoading
              }
              localstorageboolean={
                inprogressHosCountRes?.data?.data !== undefined &&
                !isNaN(inprogressHosCountRes?.data?.data)
              }
            />
          </div>
        </div>

        <div className="shadow-sm1 border border-gray-250 mt-10 rounded-lg bg-white pb-12">
          <div className="flex max-md:flex-col justify-between flex-wrap px-5 gap-4 pt-5">
            <div className="flex max-md:justify-end">
              <div style={{ maxWidth: "250px" }}>
                <SearchInput setAllSearch={setAllSearch} />
              </div>
            </div>
            <div className="flex max-md:flex-col flex-wrap items-end gap-4">
              <ColumnSettingBtn
                filterValues={filterValues}
                initialCHKVal={initialCHKVal}
                handleCheckboxChange={handleCheckboxChange}
                handleCancelFilter={handleCancelFilter}
                handleApplyFilter={handleApplyFilter}
                handleFilterSearch={handleFilterSearch}
              />

              {hosStatus !== enums.inprogress && (
                <SelectComponent
                  label="Select"
                  id="label"
                  defaultValue={enums.hospitalMatrixTypeAll}
                  value={hospitalMatrix}
                  onChange={(e: any) => {
                    setHospitalMatrix(e.target.value as HospitalMatrixType);
                  }}
                  options={hositalMatrixOptions}
                />
              )}

              <PrimaryButton
                type="button"
                name="Add Hospital"
                padding="lg:py-3 lg:px-5 xl:py-3 xl:px-8 py-3 px-5"
                icon={width < 1200 ? <></> : <AddCircleOutlineIcons />}
                onClick={addHospitalFn}
              />
            </div>
          </div>

          <div className="mt-5 h-[56vh]">
            <DynamicTable
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              order={order}
              setorder={setorder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              filter={filter}
              setFilter={setFilter}
              columnData={labelData}
              status={
                hosStatus === "allStatus"
                  ? getAllHospitalRes?.isLoading
                  : getHospitalByStatusRes?.isLoading
              }
              errorMessage={errorMessage}
              dataLength={
                hosStatus === "allStatus"
                  ? getAllHospitalRes?.data?.count ?? 0
                  : getHospitalByStatusRes?.data?.count ?? 0
              }
              noData={
                hosStatus === "allStatus"
                  ? getAllHospitalRes?.data?.data?.length < 1
                  : getHospitalByStatusRes?.data?.data?.length < 1
              }
              height={"h-full"}
              stickyWidth={[{ width: "0px" }, { width: "0px" }]}
            >
              {hosStatus !== enums.inprogress ? (
                <TblBodyProductPlan
                  labelData={labelData}
                  selectedValuesOrder={selectedValuesOrder}
                  currentHosiptalData={
                    hosStatus === "allStatus"
                      ? currentHosiptalData
                      : hosDataByStatus
                  }
                />
              ) : hosStatus === enums.inprogress ? (
                <TblBodyInProgressProductPlan
                  labelData={labelData}
                  selectedValuesOrder={selectedValuesOrder}
                  currentHosiptalData={hosDataByStatus}
                />
              ) : (
                ""
              )}
            </DynamicTable>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
};

export default HospitalManagement;
