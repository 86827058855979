import { Controller } from "react-hook-form";
import {
  EmailInputForm,
  InputForm,
  PhoneNumInputForm,
} from "../../components/forms/inputForm";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import AutoCompleteForm from "../../components/forms/autoCompleteForm";
import { useEffect } from "react";
import AccountManagerList from "./apicalls/accountManagerList";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { useLocation, useParams } from "react-router-dom";
import enums from "../../constants/enums";

const JeevAccountManager = ({
  control,
  setValue,
  previousData,
  clearErrors,
}: any) => {
  const userdetails = AccessTokenDecoder();
  const params = useParams();
  const location = useLocation();
  const pathAction = params?.action;

  const { data, error, loading } = AccountManagerList();

  const handleChange = (e: any, values: any) => {
    if (!values) {
      setValue("jeevAccountUserId", "");
      setValue("jeevAccountMobileNumber", "");
      setValue("jeevAccountEmail", "");
      setValue("jeevAccountUserRole", "");
    } else {
      clearErrors([
        "jeevAccountUserId",
        "jeevAccountMobileNumber",
        "jeevAccountEmail",
        "jeevAccountUserRole",
      ]);
      setValue("jeevAccountUserId", values?.userId);
      setValue("jeevAccountMobileNumber", values?.phone);
      setValue("jeevAccountEmail", values?.email);
      setValue("jeevAccountUserRole", values?.role);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
            <AutoCompleteForm
              id="jeevAccountName"
              control={control}
              label="Name"
              options={data ?? []}
              selectValue="name"
              handleChange={handleChange}
              defaultValue={
                pathAction === enums.add
                  ? userdetails.name
                  : previousData?.jeevAccountName
              }
            />
            <div className="w-[0px] hidden">
              <InputForm
                label={"UserId"}
                id="jeevAccountUserId"
                control={control}
                defaultValue={
                  pathAction === enums.add
                    ? userdetails.userId
                    : previousData?.jeevAccountUserId
                }
              />
            </div>
            <div className="w-[0px] hidden">
              <EmailInputForm
                label={"Email ID"}
                id="jeevAccountEmail"
                control={control}
                defaultValue={
                  pathAction === enums.add
                    ? userdetails.email
                    : previousData?.jeevAccountEmail
                }
              />
            </div>

            <InputForm
              label={"Role"}
              id="jeevAccountUserRole"
              control={control}
              defaultValue={
                pathAction === enums.add
                  ? userdetails.Roles
                  : previousData?.jeevAccountUserRole
              }
              maxLength={50}
            />

            <PhoneNumInputForm
              label={"Mobile Number"}
              id="jeevAccountMobileNumber"
              control={control}
              defaultValue={
                pathAction === enums.add
                  ? userdetails.phone
                  : previousData?.jeevAccountMobileNumber
              }
              required={false}
              maxLength={50}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default JeevAccountManager;
