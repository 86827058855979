import { useEffect, useState } from "react";

const DetectDevice = () => {
  const [deviceType, setDeviceType] = useState("Laptop/Desktop");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceType("Mobile");
      } else if (width >= 768 && width < 1024) {
        setDeviceType("Tablet");
      } else {
        setDeviceType("Laptop/Desktop");
      }
    };

    // Initial call to set device type on component mount
    handleResize();

    // Add event listener to window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return deviceType;
};

export default DetectDevice;
