import { useEffect, useState } from "react";
import { CheckBoxDataType, TableHeaderType } from "../../modals/Itables";

const ColumnSetting = (
  headerData: TableHeaderType[],
  checkBoxData: CheckBoxDataType[]
) => {
  const [labelData, setLabelData] = useState<TableHeaderType[]>(headerData);
  const [checkboxData, setCheckboxData] = useState(checkBoxData);
  const [selectedValues, setSelectedValues] = useState<string[]>(
    checkboxData?.map((item: CheckBoxDataType) => item.value)
  );
  const [selectedValuesOrder, setSelectedValuesOrder] = useState<string[]>(
    checkboxData.map((item: CheckBoxDataType) => item.value)
  );

  useEffect(() => {
    // handling ckeckbox and column header data based on inprogress or for all hospital
    const updatedLabelData = headerData.filter((item: TableHeaderType) =>
      selectedValues.includes(item.value)
    );
    setLabelData(updatedLabelData);
  }, [selectedValues]);

  const [initialCHKVal, setInitialCHKVal] = useState(selectedValues);
  const [initialCHKOrder, setInitialCHKOrder] = useState(selectedValuesOrder);

  const handleCheckboxChange = (value: string) => {
    // show and hide column based on checkbox
    if (initialCHKVal.includes(value)) {
      setInitialCHKVal(initialCHKVal.filter((item) => item !== value));
    } else {
      setInitialCHKVal([...initialCHKVal, value]);
    }

    const newOrder = initialCHKOrder.includes(value)
      ? initialCHKOrder.filter((item) => item !== value)
      : [...initialCHKOrder, value];
    setInitialCHKOrder(newOrder);
  };

  const handleApplyFilter = () => {
    setSelectedValues(initialCHKVal);
    setSelectedValuesOrder(initialCHKOrder);
  };

  const handleCancelFilter = () => {
    setInitialCHKVal(checkboxData?.map((item: CheckBoxDataType) => item.value));
    setInitialCHKOrder(
      checkboxData?.map((item: CheckBoxDataType) => item.value)
    );
    setSelectedValues(
      checkboxData?.map((item: CheckBoxDataType) => item.value)
    );
    setSelectedValuesOrder(
      checkboxData?.map((item: CheckBoxDataType) => item.value)
    );
  };

  const [filterValues, setFilterValues] = useState(checkboxData);
  let searchtimer: ReturnType<typeof setTimeout>;
  const handleFilterSearch = (inputValue: string) => {
    // const inputValue = e.target.value;
    clearTimeout(searchtimer);
    searchtimer = setTimeout(() => {
      const filteredValue = checkboxData.filter((item: CheckBoxDataType) =>
        item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );

      if (filteredValue) {
        setFilterValues(filteredValue);
      } else {
        setFilterValues(checkboxData);
      }
    }, 1000);
  };

  return {
    labelData,
    selectedValuesOrder,
    filterValues,
    initialCHKVal,
    handleCheckboxChange,
    handleCancelFilter,
    handleApplyFilter,
    handleFilterSearch,
  };
};

export default ColumnSetting;
