import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginButton } from "../components/ui/loginButton";
import constants from "../constants";
import { useAppDispatch } from "../redux/store";
import {
  ec,
  profilforgotPassword,
  profilverifyOtp,
} from "../redux/profile/profilereducer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../components/ui/loadinSpinner";
import {
  EmailOtpDataType,
  ForgotPasswordFormData,
  otpFormType,
} from "../modals/IforgotPasswordCard";
import CloseIcon from "@mui/icons-material/Close";

import { Modal } from "@mui/material";
import {
  resetecState,
  resetprofilforgotPasswordState,
  resetprofilverifyOtpState,
} from "../redux/profile/profileSlice";
import { AccessTokenDecoder } from "../utils/accessTokenDecoder";
import routesName from "../constants/routesName";

export default function ProfileforgotPasswordCard({
  isopenvalue,
  handlecloseclick,
}: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [openvalue, setOpenvalue] = useState(isopenvalue);
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [otpValue, setOtpValue] = useState<otpFormType>({
    otp: ["", "", "", ""],
    error: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [otpverrifyError, setOtpverrifyError] = useState("");
  const [ShowModal, setShowModal] = useState(true);
  const profilforgotPasswordData = useSelector(
    (data: any) => data.profilforgotPassword
  );
  const verifyOtpData = useSelector((data: any) => data.profilverifyOtp);
  const profileemailCheck = useSelector((data: any) => data.ec);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>();
  const userdetailsemail = AccessTokenDecoder();

  //   const getAccontManagerRes = useSelector(
  //     (data: { getAccontManager: ResponseType }) => data.getAccontManager
  //   );
  const handleInputChange = (e: any, index: any) => {
    setOtpverrifyError("");
    setErrorMessage("");
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }
    const newOtp = [...otpValue.otp];
    newOtp[index] = value;
    setOtpValue((prev) => ({
      error: "",
      otp: newOtp,
    }));

    if (index < otpValue.otp?.length - 1 && value !== "") {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
    if (value === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };

  const verifyOtpFn = () => {
    if (otpValue?.otp?.every((value) => value === "")) {
      setErrorMessage("Please enter the OTP");
    } else {
      setErrorMessage("");
      const data: EmailOtpDataType = {
        email: userdetailsemail.email,
        otp: otpValue.otp.join(""),
      };
      dispatch(profilverifyOtp(data));
    }
  };
  useEffect(() => {
    if (isopenvalue) {
      const userData = {
        email: userdetailsemail.email,
      };
      dispatch(ec(userData));
    }
  }, [isopenvalue]);
  useEffect(() => {
    if (profileemailCheck?.status === "success") {
      const userData = {
        email: userdetailsemail.email,
      };
      dispatch(profilforgotPassword(userData));
      setErrorMessage("");
    } else if (profileemailCheck?.status === "failed") {
      if (profileemailCheck?.error?.data?.message === "user not authorized") {
        setShowModal(true);
      }
      setErrorMessage(
        profileemailCheck?.error?.data?.title === "Unauthorized"
          ? "Credentials are wrong"
          : ""
      );
    }
  }, [profileemailCheck]);

  useEffect(() => {
    if (profilforgotPasswordData?.status === "success") {
      setErrorMessage("");
    } else if (profilforgotPasswordData?.status === "failed") {
      setErrorMessage(
        profilforgotPasswordData?.error?.data?.title === "Unauthorized"
          ? "Unable to send email, Please try again"
          : ""
      );
    }
  }, [profilforgotPasswordData]);

  useEffect(() => {
    if (verifyOtpData?.status === "success") {
      navigate(routesName.profilePasswordReset, { state: { email: watch("email") } });
    } else if (verifyOtpData?.status === "failed") {
      setOtpverrifyError(verifyOtpData?.error?.data?.message);
    }
  }, [verifyOtpData]);

  useEffect(() => {
    return () => {
      dispatch(resetecState());
      dispatch(resetprofilforgotPasswordState());
      dispatch(resetprofilverifyOtpState());
    };
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Modal
        open={isopenvalue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="h-full flex items-center justify-center">
          <div className="bg-white sm:w-[480px]  h-auto flex flex-col mx-3 rounded-lg relative">
            <>
              <div className="absolute top-5 right-5">
                <button className="" onClick={handlecloseclick}>
                  <CloseIcon />
                </button>
              </div>

              <>
                <div className="w-[100%] sm:p-[56px] p-[40px]">
                  <div className="flex flex-col items-center justify-center text-center">
                    <span className="text-[#212121] text-24 font-semibold">
                      {constants.otpSentText}
                    </span>
                    <span className="py-2">
                      <span className="text-16 font-normal text-[#6D7476]">
                        Enter the 4-digit OTP sent to&nbsp;
                      </span>

                      <span className="text-[#212121] font-medium">
                        {userdetailsemail.email}
                      </span>
                    </span>
                  </div>
                  <div className="flex gap-[15px] justify-center my-8">
                    {otpValue.otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        id={`otp-input-${index}`}
                        value={digit}
                        onChange={(e) => handleInputChange(e, index)}
                        className={` sm:w-[72px] sm:h-[60px] w-[50px] h-[40px] text-center ${
                          otpverrifyError || errorMessage
                            ? "border-red-500/100 border-2"
                            : "border-[#6D747692] border-2"
                        }  rounded outline-none`}
                        onKeyPress={handleKeyPress}
                        maxLength={1}
                      />
                    ))}
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      className=""
                      onClick={() => {
                        setOtpModalShow(false);
                        setErrorMessage("");
                        setOtpverrifyError("");
                        setOtpValue((prev) => ({
                          error: "",
                          otp: ["", "", "", ""],
                        }));
                      }}
                    >
                      <span className="text-14 font-normal text-[#6D7476]">
                        Didn't recieve code?
                      </span>

                      <button
                        disabled={profilforgotPasswordData?.isLoading}
                        className={`${
                          profilforgotPasswordData?.isLoading
                            ? "text-blue-250"
                            : "text-blue-300"
                        }  font-semibold text-14`}
                        onClick={() =>
                          dispatch(
                            profilforgotPassword({
                              email: userdetailsemail.email,
                            })
                          )
                        }
                      >
                        &nbsp;&nbsp;Resend OTP
                      </button>
                    </button>
                  </div>
                  {otpverrifyError && (
                    <div className="flex justify-center">
                      <span className="text-error text-12 font-medium">
                        {otpverrifyError}
                      </span>
                    </div>
                  )}
                  {errorMessage && (
                    <div className="text-center">
                      <span className="text-error text-12 font-medium">
                        {errorMessage}
                      </span>
                    </div>
                  )}

                  <div className="flex justify-center mt-4">
                    <LoginButton
                      name={
                        profilforgotPasswordData?.isLoading ? (
                          <LoadingSpinner />
                        ) : verifyOtpData?.isLoading ? (
                          <LoadingSpinner />
                        ) : (
                          constants.verifyText
                        )
                      }
                      width="w-[100%]"
                      fontSize="text-lg"
                      onClick={() => {
                        verifyOtpFn();
                      }}
                    />
                  </div>
                </div>
              </>
            </>
          </div>
        </div>
      </Modal>
    </>
  );
}
