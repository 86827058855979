const enums = {
  add: "add",
  edit: "edit",
  active: "active",
  expired: "expired",
  inactive: "inactive",
  deactivated: "deactivated",
  inprogress: "inprogress",
  paid: "paid",
  pilot: "pilot",
  saveAsDraft: "saveAsDraft",
  continue: "continue",
  productPlan: "productPlan",
  hospitalOccupancy: "hospitalOccupancy",
  hospitalMatrixTypeAll: "all",
  today: "Today",
  older: "Older",
  selected: "Selected",
  linked: "linked",
  Management: "Management",
  SuperAdmin: "Super Admin",
  Admin: "Admin",
};

export default enums;
