import routesName from "../../constants/routesName";

export const addHospitalNavData = [
  {
    label: "Hospital Management",
    navigate: `${routesName.hospitalManagementPage}`,
    highLight: false,
  },

  {
    label: "Add Hospital",
    highLight: true,
  },
];

export const editHospitalNavData = [
  {
    label: "Hospital Management",
    navigate: `${routesName.hospitalManagementPage}`,
    highLight: false,
  },

  {
    label: "Edit Hospital",
    highLight: true,
  },
];
