interface LoginButton {
  onClick?: () => void;
  name?: string | JSX.Element;
  type?: "submit" | "reset" | "button" | undefined;
  width?: string;
  fontSize?: string;
  disabled?: boolean;
}
export function LoginButton({
  onClick = () => {},
  name = "Ok",
  type = "submit",
  width = "",
  fontSize = "",
  disabled = false,
}: LoginButton) {
  return (
    <button
      disabled={disabled}
      className={`${
        type === "reset" ? "bg-red" : "bg-blue-300"
      }  text-white font-medium py-3 px-4 rounded-full ${fontSize} ${width} flex justify-center hover:shadow-xl`}
      type={type}
      onClick={onClick}
    >
      {name}
    </button>
  );
}
