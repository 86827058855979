const sideBarName = {
  mis: "MIS",
  hospitalManagement: "Hospital Management",
  accountManagers: "Account Managers",
  Notification: "Notification",
  Devices: "Devices",
  Logins: "Logins",
  Feedback: "Feedback",
  jeevText: "jeev.ai",
};

export default sideBarName;
