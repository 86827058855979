import { Controller, Control, Path, FieldValues } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { inputStyles } from "../mui-styles";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { ThemeProvider } from "@mui/material/styles";
import { autoCompleteTheme } from "../themeProvider";

export const MultiSelectChipForm = <
  O extends { specialisationId: string; name: string },
  TField extends FieldValues
>(
  props: any
) => {
  const { control, options, name, setValue, defaultValue, placeholder } = props;

  const [arrayValues, setArrayValues] = useState<any>([]);

  useEffect(() => {
    if (defaultValue) {
      setArrayValues(
        options?.filter((option: any) =>
          defaultValue?.includes(option?.specialisationId)
        )
      );
    }
  }, []);

  const handleDelete = (indexToDelete: number) => {
    const newOptions = arrayValues.filter(
      (_: any, index: number) => index !== indexToDelete
    );
    setArrayValues(newOptions);
    setValue(
      name,
      newOptions?.map((item: any) => item?.specialisationId),
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  };

  return (
    <ThemeProvider theme={autoCompleteTheme}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: `${placeholder} is required`,
        }}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => {
          const { onChange, value = [], ref } = field;

          return (
            <div className="flex flex-col">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  value={
                    value
                      ? options?.filter((option: any) =>
                          value?.includes(option.specialisationId)
                        )
                      : []
                  }
                  getOptionLabel={(option: any) => option?.name}
                  onChange={(event: any, newValue) => {
                    onChange(
                      newValue?.map((item: any) => item?.specialisationId)
                    );
                    setValue(
                      name,
                      newValue?.map((item: any) => item?.specialisationId),
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                      }
                    );
                    setArrayValues(newValue);
                  }}
                  id="controllable-states-demo"
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      sx={inputStyles.select}
                      {...params}
                      label={props.placeholder}
                      inputRef={ref}
                      error={error?.message ? true : false}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    const [firstOption, ...remainingOptions] = value;
                    return [
                      <Chip
                        key={value?.length}
                        label={`${firstOption?.name} ${
                          value?.length > 1
                            ? `(+ ${remainingOptions?.length} more)`
                            : ``
                        } `}
                        style={{
                          backgroundColor: "transparent",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      />,
                    ];
                  }}
                />
              </div>

              <div className="mt-4">
                {arrayValues?.map((option: any, index: any) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option?.name}
                    style={{
                      fontFamily: "Inter",
                      margin: "5px",
                      borderColor: "#534FA1",
                      color: "#534FA1",
                      fontSize: "14px",
                      fontWeight: 600,
                      padding: "5px",
                    }}
                    onDelete={() => handleDelete(index)} // Call a function to handle deletion
                    deleteIcon={
                      <ClearOutlinedIcon
                        style={{ color: "#534FA1" }}
                        onClick={() => handleDelete(index)}
                      />
                    }
                  />
                ))}
              </div>
            </div>
          );
        }}
      />
    </ThemeProvider>
  );
};
