import { useEffect, useState } from "react";
import {
  Grid,
  LinearProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { getReasonsGraphData } from "../../../redux/mis/misReducer";
import { LoadingSpinner } from "../../../components/ui/loadinSpinner";
import SelectComponent from "../../../components/forms/selectComponent";

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#eeeeee00",
        },
        bar: {
          background: "linear-gradient(to right, #E5DCEF 30%, #A389BF 70%)",
        },
      },
    },
  },
});

const regionsViewAllList = [
  { label: "All Regions", value: "all" },
  { label: "Top 5 Regions", value: 5 },
  { label: "Top 10 Regions", value: 10 },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 22,
  borderRadius: 0,
}));

const RegionsViewAll = ({
  country,
  state,
  district,
  taluk,
  hobli,
  filterApplyToggle,
}: any) => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [regionsGraphData, setRegionsGraphData] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const reasonsData = useSelector((data: any) => data?.getReasonsGraphData);

  let highestPercentage = 0;

  regionsGraphData?.data?.regionsHospitalsCount?.forEach((region: any) => {
    if (region.percentage > highestPercentage) {
      highestPercentage = region.percentage;
    }
  });

  const casesNoShow = regionsGraphData?.data?.regionsHospitalsCount.length;

  useEffect(() => {
    if (reasonsData?.status === "success") {
      setErrorMessage("");
      setRegionsGraphData(reasonsData?.data ?? []);
    } else if (reasonsData?.status === "failed") {
      setErrorMessage("Something went Wrong");
    }
  }, [reasonsData]);

  useEffect(() => {
    dispatch(
      getReasonsGraphData({
        country: country,
        state: state,
        district: district,
        taluk: taluk,
        hobli: hobli,
        serviceType: "",
      })
    );
  }, [filterApplyToggle]);
  return (
    <>
      <div className="p-6 bg-white shadow-sm1 rounded-lg">
        {reasonsData.isLoading ? (
          <div className="flex justify-center py-5">
            <LoadingSpinner />
          </div>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <span className="text-18 font-medium text-primary">
                All Regions
              </span>

              <div>
                <span className="text-14 font-[400] text-secondary">
                  No. of Active Hospitals :
                </span>
                <span className="text-14 font-[700] text-primary pl-1">
                  {regionsGraphData?.data?.activeHospitalsCount.toLocaleString(
                    "en-IN"
                  )}
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <SelectComponent
                label="Select"
                id="status"
                value={selectedValue}
                onChange={handleChange}
                options={regionsViewAllList}
              />
            </Grid>

            {regionsGraphData?.data?.regionsHospitalsCount.length > 0 ? (
              <Grid item xs={12} sm={12} md={12}>
                {regionsGraphData?.data?.regionsHospitalsCount
                  ?.slice(
                    0,
                    selectedValue === "all"
                      ? casesNoShow
                      : parseInt(selectedValue)
                  )
                  .map((item: any, index: any) => {
                    return (
                      <div key={index}>
                        <ThemeProvider theme={theme}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={1.8}>
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.name}
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9.5}>
                              <span
                                style={{
                                  zIndex: 1,
                                  position: "relative",
                                  color: "#212121",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.totalCount?.toLocaleString("en-IN")}
                              </span>
                              <BorderLinearProgress
                                style={{
                                  right: "6px",
                                  position: "relative",
                                  bottom: 22,
                                  width: "100%",
                                }}
                                variant="determinate"
                                value={
                                  (item.percentage / highestPercentage) * 100
                                }
                              />
                            </Grid>
                            <Grid
                              style={{ display: "flex", justifyContent: "end" }}
                              item
                              xs={12}
                              sm={12}
                              md={0.7}
                            >
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.percentage}%
                              </span>
                            </Grid>
                          </Grid>
                        </ThemeProvider>
                      </div>
                    );
                  })}
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <div className="flex justify-center">
                  <span className="text-18 font-medium text-primary">
                    No Data Found
                  </span>
                </div>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
};
export default RegionsViewAll;
