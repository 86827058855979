import { useState } from "react";
import DynamicTable from "../../../components/tables/dynamicTable";
import SearchInput from "../../../components/forms/searchInput";
import SelectComponent from "../../../components/forms/selectComponent";
import TblBodyDepartments from "./tableBodywithin";
import { TableFilterData } from "../../../modals/Itables";

const WithInHospitalsDepartments = ({
  data,
  dataLength,
  loading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setorder,
  orderBy,
  setOrderBy,
  filter,
  setFilter,
  setAllSearch,
}: {
  data: any;
  dataLength: number;
  loading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  order: string;
  setorder: React.Dispatch<React.SetStateAction<string>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  filter: TableFilterData;
  setFilter: React.Dispatch<React.SetStateAction<TableFilterData>>;
  setAllSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cases, setCases] = useState("all");

  const casesOption = [
    {
      label: "All Cases",
      value: "all",
    },
    {
      label: "Top 5",
      value: 5,
    },
    {
      label: "Top 10",
      value: 10,
    },
  ];

  const sortedData = data?.cmCasesAndDiagnosesSummary?.map((item: any) => {
    const sortedModels = [...item?.cMCasesAndDiagnosisBaseModels].sort(
      (a: any, b: any) => b?.individualCount - a?.individualCount
    );
    return { ...item, cMCasesAndDiagnosisBaseModels: sortedModels };
  });

  const casesNoShow = sortedData?.[0]?.cMCasesAndDiagnosisBaseModels?.length;

  const nameOdSpecility = sortedData?.[0]?.cMCasesAndDiagnosisBaseModels
    ?.map((item: any) => item)
    ?.slice(0, cases === "all" ? casesNoShow : parseInt(cases));

  const labelData = [
    {
      label: "Hospital",
      value: "name",
      width: "250px",
    },
    {
      label: "Status",
      value: "status",
      search: false,
      width: "150px",
    },
    {
      label: "Location",
      value: "city",
      width: "180px",
    },
    {
      label: "Cases",
      value: "cases",
      sort: false,
      search: false,
      colSpan: cases === "all" ? casesNoShow : parseInt(cases),
    },
  ];

  return (
    <div className="">
      <div className="flex justify-between px-6 pb-6">
        <div>
          <SearchInput setAllSearch={setAllSearch} />
        </div>
        <div>
          <SelectComponent
            label="Select"
            id="status"
            defaultValue={"all_cases"}
            value={cases}
            onChange={(e: any) => {
              setCases(e.target.value);
            }}
            options={casesOption}
          />
        </div>
      </div>
      <div className="h-[52vh]">
        <DynamicTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setorder={setorder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filter={filter}
          setFilter={setFilter}
          columnData={labelData}
          status={loading}
          errorMessage={errorMessage}
          dataLength={dataLength ?? 10}
          noData={data?.cmCasesAndDiagnosesSummary?.length < 1}
          // height={"h-full"}
          isFirstSticky={true}
          stickyWidth={[
            { width: "0px" },
            { width: "250px" },
            { width: "400px" },
          ]}
        >
          <TblBodyDepartments
            data={sortedData}
            setorder={setorder}
            setOrderBy={setOrderBy}
            setFilter={setFilter}
            cases={cases}
            casesNoShow={casesNoShow}
            order={order}
            orderBy={orderBy}
            filter={filter}
          />
        </DynamicTable>
      </div>
    </div>
  );
};

export default WithInHospitalsDepartments;
