import { useEffect, useState } from "react";
import constants from "../constants";
import { jwtDecode } from "jwt-decode";
import { accessTokenType } from "../modals/IloginCard";

export const AccessTokenDecoder = () => {
  const [accessToken, setAccessToken] = useState<string | null>(
    sessionStorage.getItem(constants.accessTokenText)
  );
  useEffect(() => {
    setAccessToken(sessionStorage.getItem(constants.accessTokenText));
  }, []);

  const decodeToken: accessTokenType = jwtDecode(accessToken || "");

  return decodeToken;
};

export const AsyncAccessTokenDecoder = () => {
  const [accessToken, setAccessToken] = useState<string | null>(
    sessionStorage.getItem(constants.accessTokenText)
  );
  useEffect(() => {
    setAccessToken(sessionStorage.getItem(constants.accessTokenText));
  }, []);

  let decodeToken: accessTokenType | null = null;
  if (accessToken) {
    try {
      decodeToken = jwtDecode(accessToken);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }
  return decodeToken;
};
