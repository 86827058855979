import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import { selectStyles } from "../mui-styles";
import "./forms.css";
import { ThemeProvider } from "@mui/material/styles";
import { selectTheme } from "../themeProvider";

const SelectComponent = ({
  label,
  id,
  defaultValue,
  value,
  onChange,
  options,
}: any) => {
  const inputLabelRef = useRef<HTMLLabelElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <ThemeProvider theme={selectTheme}>
      <Box sx={{ maxWidth: "200px", minWidth: "200px" }}>
        <FormControl fullWidth>
          <InputLabel
            id={id}
            style={{
              color: isFocused ? "#322F61" : "#757B84",
              fontSize: "16px",
              fontFamily: "Inter",
            }}
            ref={inputLabelRef}
          >
            Select
          </InputLabel>
          <Select
            style={{ background: "#ffff" }}
            labelId={id}
            id={id}
            label={label}
            defaultValue={defaultValue}
            value={value !== undefined && value !== null ? value : ""}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => {
              onChange(e);
            }}
            sx={selectStyles}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {options?.map(
              (
                option: { label: string; value: string | number },
                index: number
              ) => (
                <MenuItem
                  className={`${index === hoveredIndex ? "hovered" : ""} ${
                    option.value === value ? "backgroundColor" : ""
                  }`}
                  key={option.value}
                  value={option.value}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  {option.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
};

export default SelectComponent;
