const addHospitalName = {
  BasicInformation: "Basic Information",
  Specialisation: "Specialisation",
  BedSize: "Bed Size",
  OwnerOperatorDetails: "Owner & Operator Details",
  OnboardingSource: "Onboarding Source",
  HospitalManagement: "Hospital Management",
  JeevAccountManager: "Jeev Account Manager",
  LicenseDetails: "License Details",
  Address: "Address",
  ContactDetails: "Contact Details",
  ChooseProducts: "Choose Products",
  AddonService: "Add-on Service",
  PlanDetails: "Plan Details",
  Validity: "Validity",
  Price: "Price",
  Package: "Package",
  Paid: "Paid",
  Cancel: "Cancel",
  SaveAsDraft: "Save as draft",
  Continue: "Continue",
  Submit: "Submit",
  HospitalID: "Hospital ID",
  CreateaHospitalID: "Create a Hospital ID",
  UserID: "User ID",
  CreateaUserID: "Create a User ID",
  Password: "Password",
  AutogeneratePassword: "Automatically generate a strong password",
  CreateaPassword: "Create a Password",
  HospitalOnboarding: "Hospital Onboarding",
  DeleteHospital: "Delete Hospital",
  Save: "Save",
  continue: "continue",
  saveAsDraft: "saveAsDraft",
  hospitalInformation: "hospitalInformation",
  productActivation: "productActivation",
  hospitalOnboarding: "hospitalOnboarding",
  OnboardingDate: "Onboarding Date",
};

export default addHospitalName;
