import {
  EmailInputForm,
  InputForm,
  PhoneNumInputForm,
} from "../../components/forms/inputForm";

const AddHospitalManagemnt = ({ control, setValue, previousData }: any) => {
  return (
    <div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
        <InputForm
          label={"Name*"}
          id="hospiatlManagementName"
          control={control}
          defaultValue={previousData?.hospiatlManagementName}
          maxLength={50}
        />
        <PhoneNumInputForm
          label={"Mobile Number*"}
          id="hospiatlManagementMobileNumber"
          control={control}
          defaultValue={previousData?.hospiatlManagementMobileNumber}
        />
        <EmailInputForm
          label={"Email ID*"}
          id="hospiatlManagementEmail"
          control={control}
          defaultValue={previousData?.hospiatlManagementEmail}
          maxLength={50}
        />
      </div>
    </div>
  );
};

export default AddHospitalManagemnt;
