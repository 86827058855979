import { createSlice } from "@reduxjs/toolkit";
import { getLogin } from "./loginreducer";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    status: "",
    isLoading: false,
    isauthenticated: true,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetLoginState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        isauthenticated: true,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogin.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getLogin.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
        state.isauthenticated = true;
      })
      .addCase(getLogin.rejected, (state,{ payload } : any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export default loginSlice.reducer;
export const { resetLoginState } = loginSlice.actions;
