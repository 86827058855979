import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import DynamicTable from "../../../components/tables/dynamicTable";
import { useAppDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import sortValues from "../../../constants/sortColumnValue";
import { SearchQuery } from "../../../utils/searchQuery";
import { getUserAuditLinkedAccManager } from "../../../redux/accountManager/accountManagerReducer";
import { resetgetUserAuditLinkedAccManagerState } from "../../../redux/accountManager/accountManagerSlice";
import { userAuditHeaderData } from "../tableData/UserAuditHeaderData";
import moment from "moment";
import { removeUnderScore } from "../../../helper";

const AccManagerAuditTrails = () => {
  const styles = {
    // borderLeft: "1px solid #F2F4F7",
    borderRight: "1px solid #F2F4F7",
    borderBottom: "1px solid #F2F4F7",
    background: "white",
    minWidth: "150px",
    maxWidth: "150px",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#667085",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
  };
  const params = useParams();
  const dispatch = useAppDispatch();

  const getUserAuditLinkedAccManagerRes = useSelector(
    (data: any) => data?.getUserAuditLinkedAccManager
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [filter, setFilter] = useState<any>("");
  const searchQuery = SearchQuery({ filter });

  // api call response data for all hospital
  const [authListData, setAuthListData] = useState<any>([]);

  useEffect(() => {
    return () => {
      dispatch(resetgetUserAuditLinkedAccManagerState());
    };
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, orderBy, order]);

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getUserAuditLinkedAccManager({
          userId: parseInt(params?.id),
          order: order,
          orderBy: orderBy,
          page: page,
          rowsPerPage: rowsPerPage,
          searchQuery: searchQuery,
          searchKeywords: "",
        })
      );
    }
  }, [order, orderBy, page, rowsPerPage, searchQuery]);

  useEffect(() => {
    // for all hospiatl api call handling response
    if (getUserAuditLinkedAccManagerRes?.status === "success") {
      setErrorMessage("");
      setAuthListData(getUserAuditLinkedAccManagerRes?.data?.data ?? []);
    } else if (getUserAuditLinkedAccManagerRes?.status === "failed") {
      setErrorMessage("Something went Wrong");
    }
  }, [getUserAuditLinkedAccManagerRes]);

  const dateformatter = (item: Date) => {
    const utcDate = moment.utc(item);
    const istDate = utcDate.clone().utcOffset("+05:30");
    const now = new Date(istDate.format());
    const date = now.toLocaleDateString();
    const time = now.toLocaleTimeString();
    return (
      <div className="flex flex-col">
        <span className="text-14 font-normal text-primary">
          {item && moment(item).format("DD-MM-YYYY")}
        </span>
        <span className="text-secondary text-12 font-normal pt-1">
          {item && time}
        </span>
      </div>
    );
  };

  const TblBodyProductPlan = () => {
    return (
      <TableBody>
        {authListData?.map((item: any, index: any) => {
          return (
            <TableRow key={index}>
              <TableCell className="" style={{ ...styles, height: "76px" }}>
                {dateformatter(item?.auditDate)}
              </TableCell>
              <TableCell style={{ ...styles, minWidth: "250px" }}>
                <div className="flex flex-col">
                  <span className="text-14 font-normal text-primary">
                    {item?.customer}
                  </span>
                  <span className=" text-secondary text-12 font-normal pt-1">
                    {item?.customerCode ? `ID : ${item?.customerCode}` : ``}
                  </span>
                </div>
              </TableCell>
              <TableCell style={{ ...styles, minWidth: "250px" }}>
                <span className="">{removeUnderScore(item?.userAction)}</span>
              </TableCell>

              <TableCell style={styles}>
                <span className="">{item?.device}</span>
              </TableCell>
              <TableCell style={styles}>
                <span className="">{item?.ipAddress}</span>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };
  return (
    <div className="">
      <div className="shadow-sm1 border border-gray-250 mt-5 rounded-lg bg-white">
        <div className="h-[62vh]">
          <DynamicTable
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setorder={setorder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            filter={filter}
            setFilter={setFilter}
            columnData={userAuditHeaderData}
            children={<TblBodyProductPlan />}
            status={getUserAuditLinkedAccManagerRes?.isLoading}
            errorMessage={errorMessage}
            dataLength={getUserAuditLinkedAccManagerRes?.data?.count ?? 10}
            height={"h-full"}
          />
        </div>
      </div>
    </div>
  );
};

export default AccManagerAuditTrails;
