import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/buttons/buttons";
import InnerLayout from "../../layout/innerLayout";
import routesName from "../../constants/routesName";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/store";
import { getAllAccontManager } from "../../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import errorToast from "../../utils/errorToast";
import DynamicTable from "../../components/tables/dynamicTable";
import { AllAccountManagerHeaderDatas } from "../../features/accountManager/tableData/headerData";
import { allAcountMancheckBoxData } from "../../features/accountManager/tableData/checkboxData";
import { TblBodyAccountManager } from "../../features/accountManager/tableData/tableBody";
import ColumnSetting from "../../components/columnSetting/columnSetting";
import ColumnSettingBtn from "../../components/columnSetting/columnSettingBtn";
import {
  resetGetAllAccontManagerState,
  resetUpdateAccontManagerState,
} from "../../redux/accountManager/accountManagerSlice";
import sortValues from "../../constants/sortColumnValue";
import { ResponseType } from "../../modals/IreduxResponse";
import { AccountManagerType } from "../../modals/IaccountManager";
import { TableFilterData } from "../../modals/Itables";
import { AddCircledIcon } from "../../components/muiIconsImport";
import SearchInput from "../../components/forms/searchInput";
import { SearchQuery } from "../../utils/searchQuery";

const AccountManager = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [filter, setFilter] = useState<TableFilterData>({});
  const [allSearch, setAllSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const headerData = AllAccountManagerHeaderDatas();
  const checkBoxData = allAcountMancheckBoxData;
  const searchQuery = SearchQuery({ filter });

  const [allAccountManager, setAllAccountManager] =
    useState<AccountManagerType[]>();

  const addAccountManagerFn = () => {
    navigate(
      `${routesName.accountManagerPage}${routesName.personalInfoPage}/add`
    );
  };

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    dispatch(
      getAllAccontManager({
        order: order,
        orderBy: orderBy,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
  }, [order, orderBy, page, rowsPerPage, allSearch, searchQuery]);

  const allAccountManagerRes = useSelector(
    (data: { getAllAccontManager: ResponseType }) => data.getAllAccontManager
  );
  useEffect(() => {
    if (allAccountManagerRes?.status === "success") {
      setAllAccountManager(allAccountManagerRes?.data?.data);
    } else if (allAccountManagerRes?.status === "failed") {
      setErrorMessage("Something Went Wrong");
      errorToast("Something Went Wrong While Fetching Account Managers");
    }
  }, [allAccountManagerRes]);

  const {
    labelData,
    selectedValuesOrder,
    filterValues,
    initialCHKVal,
    handleCheckboxChange,
    handleCancelFilter,
    handleApplyFilter,
    handleFilterSearch,
  } = ColumnSetting(headerData, checkBoxData);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllAccontManagerState());
      dispatch(resetUpdateAccontManagerState());
    };
    
  }, []);

  return (
    <>
      <InnerLayout title="Account Managers">
        <div className="px-6 relative">
          <div className="bg-white shadow-md1 rounded-lg pt-5 pb-14">
            <>
              <div className="flex max-lg:flex-col justify-between px-5 gap-4 pt-3">
                <div>
                  <SearchInput setAllSearch={setAllSearch} />
                </div>
                <div className="flex items-center gap-4">
                  <ColumnSettingBtn
                    filterValues={filterValues}
                    initialCHKVal={initialCHKVal}
                    handleCheckboxChange={handleCheckboxChange}
                    handleCancelFilter={handleCancelFilter}
                    handleApplyFilter={handleApplyFilter}
                    handleFilterSearch={handleFilterSearch}
                  />
                  <PrimaryButton
                    name="Add Account Manager"
                    padding="py-3 px-6"
                    icon={<AddCircledIcon />}
                    type="button"
                    onClick={() => {
                      addAccountManagerFn();
                    }}
                  />
                </div>
              </div>

              <div className="mt-5 h-[67vh]">
                <DynamicTable
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  order={order}
                  setorder={setorder}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  filter={filter}
                  setFilter={setFilter}
                  columnData={labelData}
                  status={allAccountManagerRes?.isLoading}
                  errorMessage={errorMessage}
                  dataLength={allAccountManagerRes?.data?.count ?? 10}
                  stickyWidth={[{ width: "0px" }, { width: "0px" }]}
                  noData={allAccountManagerRes?.data?.data?.length < 1}
                >
                  <TblBodyAccountManager
                    labelData={labelData}
                    selectedValuesOrder={selectedValuesOrder}
                    accountManagerData={allAccountManager}
                    rowsPerPage={rowsPerPage}
                  />
                </DynamicTable>
              </div>
            </>
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default AccountManager;
