import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import { AxiosError } from "axios";
import routesName from "../../constants/routesName";

export const getCountryData = createAsyncThunk(
  "get/countryData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(`AddressMaster/country`);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const getStateData = createAsyncThunk(
  "get/stateData",
  async (countryId: number, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `AddressMaster/state?countryid=${countryId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const getDistrictData = createAsyncThunk(
  "get/districtData",
  async (stateid: number, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `AddressMaster/district?stateid=${stateid}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const getTalukData = createAsyncThunk(
  "get/talukData",
  async (districtId: number, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `AddressMaster/taluk?districtid=${districtId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const getHobliData = createAsyncThunk(
  "get/hobliData",
  async (talukId: number, { rejectWithValue }) => {
    try {
      const response = await API.get(`AddressMaster/hobli?talukid=${talukId}`);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const getCityData = createAsyncThunk(
  "get/cityData",
  async (countryId: number, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `AddressMaster/city?countryid=${countryId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);
