const PageNotFound = () => (
  <div className="h-screen flex flex-col items-center justify-center ">
    <h3>404 page not found</h3>
    <p>
      We are sorry but the page you are looking for does not exist or Something
      Went wrong
    </p>
  </div>
);

export default PageNotFound;
