import { useEffect, useRef, useState } from "react";
import Fuse from "fuse.js";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TextField,
} from "@mui/material";
import "./table.css";
import { FilterAppliedIcon, FilterIcon } from "../muiIconsImport";
import useDisplayToggle from "../../utils/useDisplayToggle";
import { inputStyles } from "../mui-styles";
import SortUpIcon from "../../assests/icons/topSortIocn.svg";
import SortDownIcon from "../../assests/icons/bottomSortIcon.svg";
import { SearchTooltip } from "../../utils/toolTip";

const NormalTable = ({
  columnData,
  children,
  setSortedData,
  data,
  searchValue,
  columnsName,
  dataLength = data?.length,
  stickyWidth,
  height = "h-full",
  status,
  errorMessage,
}: any) => {
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState<"asc" | "desc">("asc");
  const [filter, setFilter] = useState<any>("");
  const [searchShow, setSearchShow] = useState<any>(null);
  const [isSearchOption, setisSearchOption] = useState<any>(false);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisSearchOption,
  });

  useEffect(() => {
    if (isSearchOption === false) {
      setSearchShow(null);
    }
  }, [isSearchOption]);

  const toggleFn = (index: any) => {
    if (searchShow === index) {
      setisSearchOption(false);
      setSearchShow(null);
    } else {
      setSearchShow(index);
      setisSearchOption(true);
    }
  };
  const handleRequestSort = (property: any) => {
    const isAsc = order === property && orderBy === "asc";
    setOrderBy(isAsc ? "desc" : "asc");
    setorder(property);
  };

  useEffect(() => {
    if (searchValue) {
      const fuse = new Fuse(data, {
        keys: columnsName,
        includeScore: true,
        threshold: 0.4,
      });
      const searchResult = fuse.search(searchValue);
      const filteredData = searchResult.map((result) => result.item);

      // Filter based on filterss object
      const filteredByFilters = filteredData?.filter((item: any) =>
        Object.entries(filter).every(([key, value]: any) => {
          // If the value in filterss is not empty, filter by it
          if (value !== "") {
            return item?.[key]?.toLowerCase()?.includes?.(value?.toLowerCase());
          }
          // If the value is empty, do not filter by it
          return true;
        })
      );
      const sortedData = filteredByFilters?.sort((a: any, b: any) => {
        let comparison = 0;
        if (a[order] < b[order]) {
          comparison = -1;
        } else if (a[order] > b[order]) {
          comparison = 1;
        }
        return orderBy === "asc" ? comparison : -comparison;
      });
      setSortedData(sortedData);
    } else {
      const filteredByFilters = data?.filter((item: any) =>
        Object.entries(filter).every(([key, value]: any) => {
          // If the value in filterss is not empty, filter by it
          if (value !== "") {
            return item?.[key]?.toLowerCase()?.includes?.(value?.toLowerCase());
          }
          // If the value is empty, do not filter by it
          return true;
        })
      );
      const sortedData = filteredByFilters?.sort((a: any, b: any) => {
        let comparison = 0;
        if (a[order] < b[order]) {
          comparison = -1;
        } else if (a[order] > b[order]) {
          comparison = 1;
        }
        return orderBy === "asc" ? comparison : -comparison;
      });
      setSortedData(sortedData);
    }
  }, [orderBy, order, searchValue, filter]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newFilter: any = {};
    newFilter[name] = value;

    columnData.forEach((item: any) => {
      if (item?.value !== name) {
        newFilter[item.value] = "";
      }
    });

    setFilter(newFilter);
  };

  return (
    <>
      <TableContainer
        style={{
          overflowY: "scroll",
          overflowX: "auto",
        }}
        className={`table_scroll_bar ${height}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow
              style={{
                position: "relative",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
                zIndex: "5",
              }}
            >
              {columnData
                ?.filter((filter: any) => !filter?.hide)
                ?.map((item: any, index: number) => {
                  let leftPosition = "";
                  let zIndex = "";

                  if (index === 0) {
                  } else {
                    leftPosition = !item?.removeSticky
                      ? stickyWidth?.[index]?.width
                      : "";
                    zIndex =
                      !item?.removeSticky &&
                      index >= 0 &&
                      index < stickyWidth?.length
                        ? "10"
                        : "";
                  }

                  return (
                    <TableCell
                      style={{
                        position: "sticky",
                        left: leftPosition,
                        zIndex: zIndex,
                        top: 0,
                        borderRight: "1px solid #F2F4F7",
                        borderBottom: "1px solid #F2F4F7",
                        background: "#EEF1F6",
                        fontFamily: "Inter, sans-serif",
                      }}
                      className="border-r-2"
                      key={index}
                    >
                      <div className="flex gap-4 items-center justify-between">
                        {item?.sort === false ? (
                          <span className="text-14 font-semibold text-primary">
                            {item.label}
                          </span>
                        ) : (
                          <TableSortLabel
                            className="custom-table-sort-label"
                            hideSortIcon={true}
                            onClick={() => handleRequestSort(item?.value)}
                          >
                            <span className="text-14 font-semibold text-primary">
                              {item.label}
                            </span>
                            <div className="w-[30px]">
                              {order === item?.value ? (
                                orderBy === "asc" ? (
                                  <img
                                    src={SortUpIcon}
                                    alt="Ascending"
                                    className="ps-2"
                                  />
                                ) : (
                                  <img
                                    src={SortDownIcon}
                                    alt="Descending"
                                    className="ps-2"
                                  />
                                )
                              ) : null}
                            </div>
                          </TableSortLabel>
                        )}
                        {item?.search === false ? null : (
                          <SearchTooltip
                            content={
                              <div
                                className="bg-white p-3 rounded-lg shadow-lg z-50"
                                ref={insideClickRef}
                              >
                                <TextField
                                  id={item.label}
                                  label={`Search by ${item.label}`}
                                  variant="outlined"
                                  type="search"
                                  sx={inputStyles.select}
                                  style={{ width: "200px" }}
                                  name={item.value}
                                  value={filter[item.value] || ""}
                                  onChange={handleFilterChange}
                                />
                              </div>
                            }
                            position="top-[-10px] right-[-90px]"
                          >
                            <label
                              className="text-blue-300"
                              htmlFor={item?.label}
                              onClick={() => toggleFn(index)}
                              ref={onClickRef}
                            >
                              {filter[item?.value] ? (
                                <FilterAppliedIcon />
                              ) : (
                                <FilterIcon />
                              )}
                            </label>
                          </SearchTooltip>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          {errorMessage && !status && (
            <TableBody>
              <TableRow
                style={{
                  height: height,
                  position: "relative",
                }}
              >
                <TableCell
                  style={{
                    position: "absolute",
                    left: "30vw",
                    borderBottom: "none",
                    fontFamily: "Inter, sans-serif",
                    paddingTop: "3%",
                  }}
                >
                  <span className="text-secondary text-20 font-medium">
                    {errorMessage}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!status && !errorMessage && children}
          {!errorMessage && !status && dataLength <= 0 && (
            <TableBody>
              <TableRow
                style={{
                  height: height,
                  position: "relative",
                }}
              >
                <TableCell
                  style={{
                    position: "absolute",
                    left: "30vw",
                    borderBottom: "none",
                    fontFamily: "Inter, sans-serif",
                    paddingTop: "3%",
                  }}
                >
                  <span className="text-secondary text-20 font-medium">
                    There is no data found
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default NormalTable;
