import { useEffect } from "react";
import { Controller } from "react-hook-form";
import "../../components/components.css";
const RolePermissionCheckBox = ({
  data,
  setValue,
  control,
  name,
  selectedItems,
  setSelectedItems,
  disabled = false,
}: any) => {
  const filteredData = data?.filter(
    (item: any) => item.permissionName !== "View"
  );

  useEffect(() => {
    let updatedSelectedItems = selectedItems;

    const viewPermission = data?.find(
      (item: any) => item.permissionName === "View"
    );
    if (
      viewPermission &&
      !updatedSelectedItems?.some(
        (item: any) => item.permissionId === viewPermission.permissionId
      )
    ) {
      updatedSelectedItems = [...updatedSelectedItems, viewPermission];
    }

    const selectedServices = updatedSelectedItems?.map(
      ({ permissionId, permissionName }: any) => ({
        permissionId,
        permissionName,
      })
    );
    setValue(name, selectedServices);
  }, [selectedItems]);

  const handleCheckboxChange = (item: any, isChecked: any) => {
    if (item.permissionId === 11) return;

    if (isChecked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems?.filter(
          (selectedItem: any) => selectedItem.permissionId !== item.permissionId
        )
      );
    }
  };

  return (
    <div className="mt-3 grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 lg:gap-6 md:gap-3 ">
      {filteredData?.map((item: any) => (
        <label
          htmlFor={item.permissionName}
          className={`flex gap-3 mt-3 border ${
            disabled
              ? "border-blue-250 shadow-sm"
              : selectedItems?.some(
                  (selectedItem: any) =>
                    selectedItem.permissionId === item.permissionId
                )
              ? "border-blue-300 shadow-md"
              : "border-gray-300 shadow-sm"
          }  p-4 items-center rounded`}
          key={item.permissionId}
        >
          <input
            type="checkbox"
            id={item.permissionName}
            checked={
              item.permissionId === 11 ||
              selectedItems?.some(
                (selectedItem: any) =>
                  selectedItem.permissionId === item.permissionId
              )
            }
            onChange={(e) => {
              handleCheckboxChange(item, e.target.checked);
            }}
            disabled={disabled || item.permissionId === 11}
            className="w-[20px] h-[20px] checkbox-input"
          />
          <label
            htmlFor={item.permissionName}
            className={`text-16 ${
              selectedItems?.some(
                (selectedItem: any) =>
                  selectedItem.permissionId === item.permissionId
              )
                ? "text-primary font-semibold"
                : "text-secondary font-medium"
            }`}
          >
            {item.permissionName}
          </label>
        </label>
      ))}

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div>
            <input
              type="hidden"
              name={name}
              value={JSON.stringify(value) || ""}
            />
          </div>
        )}
      />
    </div>
  );
};

export default RolePermissionCheckBox;
