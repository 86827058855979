type ModalHeaderProps = {
  children: React.ReactNode;
};

const ModalHeader = ({ children }: ModalHeaderProps) => {
  return (
    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-primary bg-opacity-25">
      <div className="relative w-auto my-6 mx-10">
        <div className="border-0 overflow-y-auto sm:h-full min-h-100 max-h-203  rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none scroll_bar">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
