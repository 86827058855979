import React, { useState } from "react";
import "./utils.css";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

type ToolTip = {
  content: string | React.ReactNode;
  children: React.ReactNode;
};

type ActionToolTip = {
  content: string | React.ReactNode;
  children: React.ReactNode;
  position?: string;
};

export const CustomTooltip = ({ content, children }: ToolTip) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={content ? "tooltip-container" : ""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && <div className="tooltip-text z-40">{content}</div>}
    </div>
  );
};

export const ActionTooltip = ({
  content,
  children,
  position,
}: ActionToolTip) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={content ? "tooltip-container" : ""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div className={`tooltip-action ${position} z-50`}>{content}</div>
      )}
    </div>
  );
};

export const SearchTooltip = ({
  content,
  children,
  position,
}: ActionToolTip) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={content ? "tooltip-container" : ""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div className={`tooltip-search ${position} z-50`}>{content}</div>
      )}
    </div>
  );
};

export const NotificationTooltip = ({ content, children }: ToolTip) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={content ? "tooltip-container" : ""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && <div className="tooltip-text z-40">{content}</div>}
    </div>
  );
};

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      disableTouchListener={false}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#101828",
    boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.15)",
  },
}));

export const DisabledTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#555",
    color: "#101828",
    boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.15)",
  },
}));
