import routesName from "../../constants/routesName";

export const departmentsTableBreadcrumData = [
  {
    label: "MIS",
    navigate: `${routesName.managementInfoSystemPage}`,
    highLight: false,
  },

  {
    label: "Departments",
    highLight: true,
  },
];

export const diagnosisTableBreadcrumData = [
  {
    label: "MIS",
    navigate: `${routesName.managementInfoSystemPage}`,
    highLight: false,
  },

  {
    label: "Diagnosis",
    highLight: true,
  },
];
