import { useSelector } from "react-redux";
import { MultiSelectChipForm } from "../../components/forms/multiSelectChipForm";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";

const Specialisation = ({ control, setValue, previousData }: any) => {
  const specialisationList = useSelector(
    (data: any) => data?.getSpecialisation
  );

  return (
    <div>
      <div className="">
        {specialisationList?.status === "success" ? (
          <MultiSelectChipForm
            options={specialisationList?.data?.data}
            control={control}
            setValue={setValue}
            name="specialisation"
            placeholder="Specialisation*"
            defaultValue={previousData?.specialisation}
          />
        ) : (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default Specialisation;
