import { useEffect, useState } from "react";
import InnerLayout from "../../layout/innerLayout";
import { PrimaryButton } from "../../components/buttons/buttons";
import { SendNotificationIcon } from "../../components/muiIconsImport";
import SystemNotifications from "../../features/notifications/notificationTabs/systemNotification/systemNotification";
import SentNotifications from "../../features/notifications/notificationTabs/sentNotification/sentnotifications";
import NotificationModal from "../../features/notifications/notificationTabs/systemNotification/notificationModal";
import api from "../../datasource/api";

const NotificationTabs = () => {
  const [systemCount, setSystemCount] = useState(0);
  const [reload, setReload] = useState(false);
  const systenCountFn = (value: any) => {
    setReload((prev) => !prev);
  };

  const [activeTab, setActiveTab] = useState("System Notifications");
  const [isNotify, setNotify] = useState(false);

  useEffect(() => {
    // api call for unforward messages count
    api
      .get(`Notification/notificationspaginated?notifyType=1`)
      .then((response) => {
        setSystemCount(response?.data?.count ?? 0);
      })
      .catch((error) => {
        console.log(error);
        
      });
  }, [reload]);

  const tabs = [
    {
      name: "System Notifications",
      count: systemCount,
      component: <SystemNotifications systenCountFn={systenCountFn} />,
    },
    {
      name: "Sent Notifications",
      component: <SentNotifications />,
    },
  ];

  return (
    <InnerLayout title="Notifications">
      <>
        <div className="px-6 flex justify-between">
          <div className="mt-6 flex items-center gap-4 border-b border-gray-300 flex-1">
            {tabs.map((tab: any) => (
              <button
                key={tab.name}
                type="button"
                onClick={() => setActiveTab(tab.name)}
                className={`my-1 py-2 px-4 text-16 rounded-lg ${
                  activeTab === tab.name
                    ? "text-white font-semibold bg-blue-300"
                    : "text-gray-400 font-medium bg-gray-150"
                }`}
              >
                {tab.name} {tab?.count ? `(${tab?.count})` : ""}
              </button>
            ))}
          </div>
          <div style={{ marginTop: "26px" }}>
            <PrimaryButton
              name="Send a Notification"
              padding="py-3 px-6"
              icon={<SendNotificationIcon />}
              type="button"
              onClick={() => {
                setNotify(!isNotify);
              }}
            />
          </div>
        </div>
        <div className="mt-3 px-6">
          {tabs.map((tab: any) => (
            <div key={tab.name}>
              {activeTab === tab.name && <div>{tab.component}</div>}
            </div>
          ))}
        </div>
      </>

      <NotificationModal
        isVisible={isNotify}
        onClose={() => {
          setNotify(false);
        }}
        onClick={() => {}}
        activeTab={activeTab}
      />
    </InnerLayout>
  );
};

export default NotificationTabs;
