import CheckedIcon from "../../assests/icons/checked.svg";
import AccountManagerIcon from "../../assests/icons/account-manager-icon.svg"
import RoleAndAuthorities from "../../assests/icons/role-authorities-icon.svg"
import DisabledRolesAuthoritiesIcon from "../../assests/icons/rolesAuthorities-disabledIcon.svg"


interface addHospitalProgressProps {
  active: number;
}

const AccountManagerProgressBar = ({ active }: addHospitalProgressProps) => {
  return (
    <div className="flex justify-center items-center mx-10">
      <div
        className={`${
          active === 1
            ? "text-primary font-bold"
            : active > 1
            ? "text-blue-300 font-semibold"
            : "text-secondary font-normal"
        } flex justify-center items-center gap-2 text-14`}
      >
        <div
          className={`border-blue-300 rounded-[50%]  flex items-center ${
            active <= 1 ? " w-[48px] h-[48px]" : " w-[40px] h-[40px]"
          }`}
        >
          {active <= 1 ? (
            <img src={AccountManagerIcon} />
          ) : (
            <img src={CheckedIcon} />
          )}
        </div>
        <span className="md:block hidden whitespace-nowrap">
          Personal Information
        </span>
      </div>
      <div
        className={`w-[30%] border-b mx-5 border-4 rounded m-auto ${
          active === 1
            ? "border-disabled"
            : active > 1
            ? "border-blue-350"
            : "border-disabled"
        }`}
      ></div>
      <div
        className={`${
          active === 2
            ? "text-primary font-bold"
            : active > 2
            ? "text-blue-300 font-semibold"
            : "text-secondary font-normal"
        } flex justify-center items-center gap-2 text-14`}
      >
        <div
          className={`border-blue-300 rounded-[50%] flex items-center ${
            active === 2 ? " w-[48px] h-[48px]" : " w-[40px] h-[40px]"
          }`}
        >
          {active === 2 ? (
            <img src={RoleAndAuthorities} />
          ) : active > 2 ? (
            <img src={CheckedIcon} />
          ) : (
            <img src={DisabledRolesAuthoritiesIcon} />
          )}
        </div>
        <span className="md:block hidden whitespace-nowrap">
          Roles & Authorities
        </span>
      </div>
    </div>
  );
};

export default AccountManagerProgressBar;
