import { HubConnectionBuilder } from "@microsoft/signalr";
// import { getConfig } from "../datasource/api";

// const { SIGNAL_R_URL } = getConfig();

const hubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_SIGNAL_R_URL as string).build();

const startConnection = async () => {
  try {
    await hubConnection.start();
    console.log("SignalR connected");
  } catch (err) {
    console.error("Error while establishing connection: ", err);
  }
};

const addListener = (eventName: any, callback: any) => {
  hubConnection.on(eventName, callback);
};

const removeListener = (eventName: any, callback: any) => {
  hubConnection.off(eventName, callback);
};

export { startConnection, addListener, removeListener };
