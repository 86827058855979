import React from "react";
import Devices from "../pages/devices";
import { Outlet } from "react-router-dom";

function DeviceRoute() {
  return (
    <>
      <Devices>
        <Outlet />
      </Devices>
    </>
  );
}

export default DeviceRoute;
