import "./components.css";

const RoundedDynamicTabs = ({ tabs, activeTab, setActiveTab }: any) => {
  return (
    <div>
      <div className="mt-6 flex items-center gap-4 border-b border-gray-300 whitespace-nowrap overflow-x-auto scroll_bar">
        {tabs.map((tab: any) => (
          <button
            key={tab.name}
            type="button"
            onClick={() => setActiveTab(tab.name)}
            className={`my-1 py-2 px-4 text-16 rounded-lg ${
              activeTab === tab.name
                ? "text-white font-semibold bg-blue-300"
                : "text-gray-400 font-medium bg-gray-150"
            }`}
          >
            {tab.name} {tab?.subName}
          </button>
        ))}
      </div>

      <div className="mt-3">
        {tabs.map((tab: any) => (
          <div key={tab.name}>
            {activeTab === tab.name && <div>{tab.component}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoundedDynamicTabs;
