import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type ChartData = {
  [key: string]: number;
};

type PercentageDisplay = {
  labels: string[];
  values: number[];
  colors: string[];
};

const TableDoughnutChart = ({
  chartData,
  colors,
  width = "w-[100px]",
}: {
  chartData: ChartData;
  colors: string[];
  width?: string;
}) => {
  const labels = Object.keys(chartData);
  const values = Object.values(chartData);

  const PercentageDisplay = ({ labels, values, colors }: PercentageDisplay) => {
    const total = values.reduce((acc, curr) => acc + curr, 0);
    return (
      <div>
        {labels.map((label, index) => (
          <div key={index} className="whitespace-nowrap">
            <span style={{ marginRight: "5px", color: colors[index] }}>●</span>
            <span className="text-secondary text-14 font-medium">
              {" "}
              {label}:
            </span>
            <span className="text-primary text-14 font-semibold">
              {" "}
              {values[index]} ({((values[index] / total) * 100).toFixed(0)}%)
            </span>
          </div>
        ))}
      </div>
    );
  };

  const totalValue = values.reduce((acc: any, curr) => acc + curr, 0);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "value",
        data: values,
        backgroundColor: colors,
      },
    ],
  };

  // const options = {
  //   // maintainAspectRatio: false,
  //   layout: {
  //     padding: {
  //       left: 0,
  //       right: 0,
  //       top: 0,
  //       bottom: 0,
  //     },
  //   },
  //   elements: {
  //     arc: {
  //       spacing: 0.5,
  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   cutout: 20,
  // };

  const options = {
    // maintainAspectRatio: false,
    animation: false,
    aspectRatio: 1,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      arc: {
        spacing: 0.5,
      },
    },
    cutout: 20,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
            family: "Inter",
          },
        },
      },
      tooltip: {
        enabled: true,
        position: "nearest" as "nearest",
        backgroundColor: "#FFFFFF",
        titleColor: "#101828",
        titleFont: {
          size: 14,
          weight: 600 as const,
          family: "Inter",
        },
        bodyColor: "#101828",
        boxPadding: 4,
        bodyFont: {
          size: 12,
          weight: 500 as const,
          family: "Inter",
        },
        padding: 10,
        cornerRadius: 6,
        borderColor: "#EAECF0",
        borderWidth: 2,
        callbacks: {
          label: function (context: any) {
            const label = context?.label;
            const value = context.formattedValue;
            return `${value}`;
          },
          title: function (context: any) {
            return "";
          },
        },
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart: any, args: any, pluginOptions: any) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = "bolder 14px sans-serif";
      ctx.fillStyle = "#101828";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        totalValue,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };

  return (
    <div className="flex items-center gap-1">
      <div className={`${width}`}>
        <Doughnut data={data} options={options} plugins={[textCenter]} />
      </div>
      <div className="flex flex-col">
        <PercentageDisplay labels={labels} values={values} colors={colors} />
      </div>
    </div>
  );
};

export default TableDoughnutChart;
