import { TableBody, TableCell, TableRow } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PrimaryButton } from "../../components/buttons/buttons";
import { getAllDevices } from "../../redux/devices/deviceManagmentReducer";
import { useAppDispatch } from "../../redux/store";
import {
  AddCircledIcon,
  MoreOptionIcon,
} from "../../components/muiIconsImport";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/forms/searchInput";
import DynamicTable from "../../components/tables/dynamicTable";
import sortValues from "../../constants/sortColumnValue";
import { TableFilterData } from "../../modals/Itables";
import useDisplayToggle from "../../utils/useDisplayToggle";
import { SearchQuery } from "../../utils/searchQuery";
import { useSelector } from "react-redux";
import errorToast from "../../utils/errorToast";
import { ActionTooltip } from "../../utils/toolTip";
import ConfirmationModal from "../../components/modals/confirmationModal";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import useAxios from "../../hooks/useAxios";
import successToast from "../../utils/successToast";
import routesName from "../../constants/routesName";

const mainHeader = [
  {
    label: "Action",
    value: "action",
    sort: false,
    search: false,
    width: "80px",
    maxWidth: "80px",
  },

  {
    label: "Model",
    value: "modelName",
    sort: true,
    search: true,
    width: "260px",
  },
  {
    label: "Serial Number",
    value: "serialNumber",
    sort: true,
    search: true,
    width: "280px",
  },
  {
    label: "MAC Address",
    value: "macAddress",
    sort: true,
    search: true,
    width: "280px",
  },
  {
    label: "IMEI Number",
    value: "imeiNumber",
    sort: true,
    search: true,
    width: "280px",
  },
  {
    label: "Status",
    value: "Status",
    sort: true,
    search: false,
    width: "260px",
  },
];
const styles = {
  // borderLeft: "1px solid #F2F4F7",
  borderRight: "1px solid #F2F4F7",
  borderBottom: "1px solid #F2F4F7",
  background: "white",
  paddingLeft: "16px",
  paddingRight: "16px",
  fontsize: "14px",
  color: "#667085",
  fontWeight: 500,
  fontFamily: "Inter, sans-serif",
};
function AllkDevice() {
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
  const [filter, setFilter] = useState<TableFilterData>({});
  const [sortedData, setSortedData] = useState<any>([]);
  const [allSearch, setAllSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  const [isMoreOption, setisMoreOption] = useState<any>(false);
  const [isDeleteModal, setisDeleteModal] = useState<any>(false);
  const [deviceId, setDeviceId] = useState<any>();
  const searchQuery = SearchQuery({ filter });
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisMoreOption,
  });
  const toogleFn = (index: number) => {
    if (moreOption === index) {
      setisMoreOption(false);
      setMoreOption(null);
    } else {
      setMoreOption(index);
      setisMoreOption(true);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    dispatch(
      getAllDevices({
        order: order,
        orderBy: orderBy,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
  }, [order, orderBy, page, rowsPerPage, allSearch, searchQuery]);
  const linkedDevicesRes = useSelector(
    (data: { getAllDevicesreducer: any }) => data?.getAllDevicesreducer
  );
  useEffect(() => {
    if (linkedDevicesRes?.status === "success") {
      setSortedData(linkedDevicesRes?.data?.data);
    } else if (linkedDevicesRes?.status === "failed") {
      errorToast("Something went wrong");
      setErrorMessage("Something went wrong");
    }
  }, [linkedDevicesRes]);
  const dekletedeviceFn = () => {
    deleteFn();
  };
  const { data, error, loading, deleteFn } = useAxios({
    method: "delete",
    path: `Devices/devices/delete/id?deviceid=${deviceId}`,
  });
  useEffect(() => {
    if (data?.success) {
      successToast("Devices deleted successfully");
      window.location.reload();
      setisDeleteModal(false);
    } else if (error) {
      errorToast("Something Went Wrong");
    }
  }, [data, error]);
  const editDeviceFn = (
    deviceId: number,
    imeiNumber: string,
    macAddress: string,
    modelName: string,
    status: string,
    serialNumber: string,
    islinked: boolean
  ) => {
    navigate(`${routesName.devicesPage}/editdevice`, {
      state: {
        deviceId,
        imeiNumber,
        macAddress,
        modelName,
        status: "Unlinked",
        serialNumber,
        islinked,
      },
    });
  };

  return (
    <div>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setisDeleteModal(false);
        }}
        onClick={() => {
          dekletedeviceFn();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Device?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={false}
      />
      <div className="flex max-lg:flex-col justify-between px-5 gap-4 pt-5  mb-5">
        <div className="flex max-lg:justify-end">
          <div style={{ maxWidth: "250px" }}>
            <SearchInput setAllSearch={setAllSearch} />
          </div>
        </div>
        <div className="flex max-lg:flex-col items-end gap-4 ">
          <PrimaryButton
            name="Add Device"
            padding="py-3 px-6"
            icon={<AddCircledIcon />}
            type="button"
            onClick={() => navigate(routesName.addDevice)}
          />
        </div>
      </div>
      <div className="h-[55vh]">
        <DynamicTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setorder={setorder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filter={filter}
          setFilter={setFilter}
          columnData={mainHeader}
          children={
            <TableBody>
              {sortedData?.map((device: any, ind: any) => (
                <TableRow key={ind}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      ...styles,
                      width: "80px",
                      minWidth: "80px",
                      maxWidth: "80px",
                      textAlign: "center",
                      height: "80px",
                      position: "relative",
                      // position: "sticky",
                      left: "0px",
                    }}
                  >
                    <ActionTooltip
                      content={
                        <div
                          className={`text-14  absolute left-8 z-30  ${
                            ind >= sortedData?.length - 5
                              ? "bottom-[-20px]"
                              : "top-[-20px]"
                          }  flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                          ref={insideClickRef}
                        >
                          <button
                            type="button"
                            className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-lg "
                            onClick={() => {
                              editDeviceFn(
                                device?.deviceId,
                                device?.imeiNumber,
                                device?.macAddress,
                                device?.modelName,
                                device?.status,
                                device?.serialNumber,
                                device?.status === "Linked"
                              );
                            }}
                          >
                            Edit
                          </button>
                          {device?.status === "Unlinked" && (
                            <button
                              type="button"
                              className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-lg "
                              onClick={() => {
                                setisDeleteModal(true);
                                setDeviceId(device?.deviceId);
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      }
                    >
                      <button
                        type="button"
                        onClick={() => toogleFn(ind)}
                        ref={onClickRef}
                        className="text-blue-300 w-[24px] h-[24px]"
                      >
                        <MoreOptionIcon />
                      </button>
                    </ActionTooltip>
                  </TableCell>
                  <TableCell style={styles}> {device?.modelName}</TableCell>
                  <TableCell style={styles}> {device?.serialNumber}</TableCell>
                  <TableCell style={styles}>{device?.macAddress}</TableCell>
                  <TableCell style={styles}>{device?.imeiNumber}</TableCell>
                  <TableCell style={styles}>
                    {device?.status === "Linked" ? (
                      <div
                        className="grid grid-cols-5 items-center cursor-pointer"
                        onClick={() =>
                          navigate(`${routesName.hospitalManagementPage}/${device?.hospitalId}`)
                        }
                      >
                        <span className="text-blue-300 text-16 font-semibold">
                          {device?.status}
                        </span>
                      </div>
                    ) : (
                      device?.status
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          status={linkedDevicesRes?.isLoading}
          errorMessage={errorMessage}
          dataLength={linkedDevicesRes?.data?.count ?? 10}
        />
      </div>
    </div>
  );
}

export default AllkDevice;
