import { useRef, useState } from "react";
import { ArrowDropDown, ArrowDropUp, SearchIcons } from "../muiIconsImport";
import { PrimaryButton, SecondaryButton } from "../buttons/buttons";
import useDisplayToggle from "../../utils/useDisplayToggle";

const ColumnSettingBtn = ({
  filterValues,
  initialCHKVal,
  handleCheckboxChange,
  handleCancelFilter,
  handleApplyFilter,
  handleFilterSearch,
}: any) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setShowDropdown,
  });

  return (
    <div className="relative w-[250px]">
      <button
        type="button"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        ref={onClickRef}
        className={` ${
          showDropdown
            ? "border outline outline-1 border-blue-350"
            : "border hover:border-blue-350 border-gray-450"
        }  text-primary ps-5 pe-2 py-[14px] rounded w-full text-16 font-semibold text-start  flex justify-between bg-white`}
      >
        Column Settings
        <span className="text-blue-300">
          {showDropdown ? <ArrowDropUp /> : <ArrowDropDown />}
        </span>
      </button>

      <label className="absolute text-12 font-normal text-primary left-3 px-1 bg-white top-[-8px] ">
        Select
      </label>

      <div
        className={`absolute mt-2 z-20 bg-white w-[100%] p-4 shadow-md rounded-lg ${
          showDropdown ? "visible" : "invisible"
        }`}
        ref={insideClickRef}
      >
        <div className="border border-gray-450 py-2 px-3 rounded mb-3 flex">
          <input
            className="rounded-none outline-none w-[100%] text-16 font-400 text-primary"
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleFilterSearch(e.target.value);
            }}
            placeholder="Search"
            value={searchValue}
          />
          <span className="text-blue-300">
            <SearchIcons />
          </span>
        </div>
        <div className="h-80 overflow-y-auto scroll_bar_with_side">
          {filterValues
            ?.filter?.((data: any) => data.display)
            ?.map((item: any) => (
              <div className="p-3 flex gap-3 items-center" key={item.value}>
                <input
                  type="checkbox"
                  checked={initialCHKVal.includes(item.value)}
                  id={item.value}
                  name={item.value}
                  onChange={() => handleCheckboxChange(item.value)}
                  className="checkbox-input"
                />
                <label
                  htmlFor={item.value}
                  className={` text-14 ${
                    initialCHKVal.includes(item.value)
                      ? "text-primary font-semibold"
                      : "text-secondary font-medium"
                  }`}
                >
                  {item.label}
                </label>
              </div>
            ))}
        </div>

        <div className="flex justify-around mt-3 gap-3">
          <SecondaryButton
            padding="px-7 py-1"
            fontSize="text-14"
            name="Cancel"
            type="button"
            onClick={() => {
              handleCancelFilter();
              setShowDropdown(false);
              setSearchValue("")
              handleFilterSearch("");
            }}
          />
          <PrimaryButton
            padding="px-8 py-1"
            fontSize="text-14"
            name="Apply"
            type="button"
            onClick={() => {
              handleApplyFilter();
              setShowDropdown(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ColumnSettingBtn;
