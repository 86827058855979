export const feedbackColumnData = [
  {
    label: "Hospital",
    value: "hospital_name",
    width: "250px",
  },
  {
    label: "Product",
    value: "product_name",
    width: "220px",
  },
  {
    label: "Feedback",
    value: "error_description",
    removeSticky: true,
    width: "500px",
  },
  {
    label: "User",
    value: "user",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "Devices",
    value: "device",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "Model",
    value: "model",
    removeSticky: true,
    width: "160px",
  },

  {
    label: "IP Address",
    value: "ipaddress",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "Date & Time",
    value: "feedbackdate",
    removeSticky: true,
    search: false,
    width: "160px",
  },
];
