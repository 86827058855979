type Authorities = {
  permissionId: number;
  permissionName: string;
};

const AccManagerAuthorites = ({ authorities }: any) => {
  const filterAuthories = authorities?.filter?.(
    (item: Authorities) => item?.permissionName != "View"
  );

  return (
    <div className="flex flex-wrap gap-5">
      {filterAuthories?.map((item: Authorities, index: number) => (
        <div
          className="border border-gray-250 py-2 px-3 flex justify-center items-center text-center rounded bg-white text-16 font-medium text-primary shadow-sm"
          key={index}
        >
          {item?.permissionName}
        </div>
      ))}
      {filterAuthories?.length <= 0 && (
        <span className="text-secondary text-14 font-medium">
          No permission assigned yet
        </span>
      )}
    </div>
  );
};

export default AccManagerAuthorites;
