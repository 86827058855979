const routesName = {
  cmpHomePage: "/cmp",
  forgotPasswordPage: "/cmp/forgot-password",
  loginDashboardPage: "/cmp/dashBoard",
  managementInfoSystemPage: "/cmp/mis",
  hospitalManagementPage: "/cmp/hospital-management",
  accountManagerPage: "/cmp/account-manager",
  notificationPage: "/cmp/notifications",
  devicesPage: "/cmp/devices",
  unlinkDevice: "unlinkdevice",
  addDevice: "/cmp/devices/adddevice",
  linkDevice: "linkdevice",
  loginsDetailsPage: "/cmp/logins-details",
  feedbackPage: "/cmp/feedback",
  resetPasswordPage: "/cmp/reset-password",
  errorPage: "/cmp/errorPage",
  addhHospitalPage: "/cmp/add-hospital",
  productActivationPage: "/cmp/product-activation",
  hospitalOnboardPage: "/cmp/hospital-onboard",
  personalInfoPage: "/cmp/personal-info",
  rolesAuthoritiesPage: "/cmp/roles-authorities",
  misDepartment: "/cmp/mis/departments",
  misDiagnosis: "/cmp/mis/diagnosis",
  MisGraphs: "/cmp/mis/graphs",
  userProfilePage: "/cmp/profile",
  profilePasswordReset: "/cmp/profilepasswordreset",
  editdevice: "editdevice",
  alldevice: "alldevice",
};

export default routesName;
