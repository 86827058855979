import { TableBody, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { istDate } from "../../helper";
import routesName from "../../constants/routesName";
const styles = {
  background: "white",
  minWidth: "160px",
  maxWidth: "160px",
  borderRight: "1px solid #F2F4F7",
  borderBottom: "1px solid #F2F4F7",
  paddingLeft: "16px",
  paddingRight: "16px",
  fontsize: "14px",
  color: "#667085",
  fontWeight: 500,
  fontFamily: "Inter, sans-serif",
};

const TblLogin = ({ data }: any) => {
  const navigate = useNavigate();
  return (
    <TableBody>
      {data?.map((item: any, index: any) => {
        return (
          <TableRow key={index}>
            <TableCell
              style={{
                ...styles,
                position: "sticky",
                left: "0px",
                minWidth: "300px",
                maxWidth: "300px",
              }}
            >
              <div className="flex items-center gap-3 cursor-pointer">
                <div className="w-[36px] h-[36px] ">
                  <img
                    src={item?.photo}
                    className="rounded-full w-[36px] h-[36px]"
                    alt="user image"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="flex flex-col">
                  <button
                    onClick={() => navigate(`${routesName.accountManagerPage}/${item?.userID}`)}
                    className="text-start"
                  >
                    <span className="text-blue-300 text-16 font-semibold">
                      {item?.customer}
                    </span>
                  </button>
                  <div className="text-secondary text-14 font-medium flex gap-1">
                    <span>ID: {item?.employeeID}</span>|
                    <span>{item?.role}</span>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ ...styles }}>
              {item?.device ? item?.device : "-"}
            </TableCell>
            <TableCell style={{ ...styles }}>
              {item?.model ? item?.model : "-"}
            </TableCell>
            <TableCell style={{ ...styles }}>
              {item?.os ? item?.os : "-"}
            </TableCell>
            <TableCell style={{ ...styles }}>
              {item?.osVersion ? item?.osVersion : "-"}
            </TableCell>
            <TableCell style={{ ...styles }}>
              {item?.ipAddress ? item?.ipAddress : "-"}
            </TableCell>
            <TableCell style={{ ...styles }}>
              {item?.imeiNumber ? item?.imeiNumber : "-"}
            </TableCell>
            <TableCell style={{ ...styles }}>
              {item?.macAddress ? item?.macAddress : "-"}
            </TableCell>
            <TableCell style={{ ...styles }}>
              <button
                type="button"
                className="flex flex-col"
                onClick={() =>
                  navigate(`${routesName.accountManagerPage}/${item?.userID}`, {
                    state: { audittab: "Audit Trail" },
                  })
                }
              >
                <span className="text-primary text-16 font-semibold">
                  {moment(istDate(item.loginDate)).format("DD MMM YYYY")}
                </span>
                <span className="text-secondary text-14 font-medium">
                  {moment(istDate(item.loginDate)).format("hh:mm a")}
                </span>
              </button>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
export default TblLogin;
