import { createSlice } from "@reduxjs/toolkit";
import {
    ec,
  profilforgotPassword,
  profilresetPassword,
  profilverifyOtp,
} from "./profilereducer";

export const ecSlice = createSlice({
  name: "ec",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetecState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ec.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(ec.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(ec.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const profilforgotPasswordSlice = createSlice({
  name: "profilforgotPassword",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetprofilforgotPasswordState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(profilforgotPassword.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(profilforgotPassword.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(profilforgotPassword.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const profilverifyOtpSlice = createSlice({
  name: "profilverifyOtp",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetprofilverifyOtpState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(profilverifyOtp.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(profilverifyOtp.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(profilverifyOtp.rejected, (state, { payload }: any) => {
    
        
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const profilresetPasswordSlice = createSlice({
  name: "profilresetPassword",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    profilresetPasswordState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(profilresetPassword.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(profilresetPassword.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(profilresetPassword.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const profilverifyOtpSliceReducer = profilverifyOtpSlice.reducer;
export const profilforgotPasswordSliceReducer = profilforgotPasswordSlice.reducer;
export const profilresetPasswordSliceReducer = profilresetPasswordSlice.reducer;
export const ecSlicereducer = ecSlice.reducer;

export const { resetprofilforgotPasswordState } = profilforgotPasswordSlice.actions;
export const { resetprofilverifyOtpState } = profilverifyOtpSlice.actions;
export const { profilresetPasswordState } = profilresetPasswordSlice.actions;
export const { resetecState } = ecSlice.actions;
