import axios from "axios";
import constants from "../constants";

// interface Config {
//   API_URL: string;
//   LOGIN_URL: string;
//   SIGNAL_R_URL: string;
// }

// const config: { [key: string]: Config } = {
//   development: {
//     API_URL: "https://jeevdevbackend.azurewebsites.net/api/",
//     LOGIN_URL: "https://jeevdevlogin.azurewebsites.net/api/",
//     SIGNAL_R_URL: "https://jeevdevbackend.azurewebsites.net/chatHub",
//   },
//   production: {
//     API_URL: "https://jeevcmpbackendhospitalservice.azurewebsites.net/api/",
//     LOGIN_URL: "https://cmpbackend.azurewebsites.net/api/",
//     SIGNAL_R_URL:
//       "https://jeevcmpbackendhospitalservice.azurewebsites.net/chatHub",
//   },
// };

// export const getConfig = (): Config => {
//   const env = process.env.REACT_APP_ENV_VAR || "development";
//   if (!config[env]) {
//     throw new Error(`Unknown environment: ${env}`);
//   }
//   return config[env];
// };

// const { API_URL, LOGIN_URL } = getConfig();

// console.log(process.env, "sdsddsds");


export let baseURL = sessionStorage.getItem("accessToken")
  ? process.env.REACT_APP_API_URL
  : process.env.REACT_APP_LOGIN_URL;

export default axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(
      constants.accessTokenText
    )}`,
  },
  withCredentials: true,
});

