import React, { useEffect } from "react";

export type useDisplayToggleProps = {
  onClickRef: React.MutableRefObject<null | HTMLElement>;
  insideClickRef: React.MutableRefObject<null | HTMLElement>;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
};

const useDisplayToggle = ({
  onClickRef,
  insideClickRef,
  setDisplay,
}: useDisplayToggleProps) => {
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      onClickRef.current &&
      !onClickRef?.current?.contains(event.target as Node) &&
      !insideClickRef?.current?.contains(event.target as Node)
    ) {
      setDisplay(false);
    }
  };
  useEffect(() => {
    document?.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document?.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
};

export default useDisplayToggle;
