import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  ErrorWithDeleteButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import CustomAccordion from "../../components/ui/accordion";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import BasicInformation from "../../features/hospitalManagement/basicInformation";
import Specialisation from "../../features/hospitalManagement/specialisation";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../redux/store";
import {
  getHospitalById,
  getSpecialisationType,
  updateHospital,
} from "../../redux/addHospital/hospitalManagementReducer";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import BedSize from "../../features/hospitalManagement/bedSize";
import axios from "axios";
import { enumDataApiList } from "../../jsonData/addHospitalsData";
import {
  addEnumData,
  resetUpdateHospitalState,
} from "../../redux/addHospital/hospitalManagementSlice";
import OwnerOperatorDetails from "../../features/hospitalManagement/ownerOperatorDetails";
import OnBoardSource from "../../features/hospitalManagement/onBoardSource";
import AddHospitalManagemnt from "../../features/hospitalManagement/hospitalManagement";
import JeevAccountManager from "../../features/hospitalManagement/jeevAccountManager";
import EditHospitalOnboard from "../../features/hospitalManagement/editHospitalOnboard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AccountManagerObject,
  HosiptalInfoObject,
  HospitalManagementObject,
  HospitalOnboardingObject,
  OnBoardDispatchData,
  OnBoardSourceObject,
  OwnerOperatorObject,
  SpecialisationObject,
} from "../../features/hospitalManagement/dataformatter";
import successToast from "../../utils/successToast";
import errorToast from "../../utils/errorToast";
import constants from "../../constants";
import routesName from "../../constants/routesName";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import ConfirmationModal from "../../components/modals/confirmationModal";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import useAxios from "../../hooks/useAxios";
import { hospitalApi } from "../../jsonData/apiPathData/hospital";
import UserAuditDetails from "../../utils/userAuditDetails";
import { ErrorMessageIcon } from "../../components/muiIconsImport";

const EditHospitalInformation = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userDetails = AccessTokenDecoder();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [firstFormError, setFirstFormError] = useState("");
  const specialisationData = useSelector(
    (data: any) => data?.getSpecialisation
  );
  const [loading, setLoading] = useState(true);
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const scrollDown = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    dispatch(getHospitalById(params?.id as string));
  }, []);

  const hospitalDetailsRes = useSelector((data: any) => data?.getHospitalById);

  useEffect(() => {
    axios
      .all(
        enumDataApiList.map((endpoint) =>
          axios.get(endpoint, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem(
                constants.accessTokenText
              )}`,
            },
          })
        )
      )
      .then(
        axios.spread(
          (
            { data: OwnershipType },
            { data: AddOnServiceType },
            { data: BedStatus },
            { data: DeviceType },
            { data: HospitalsStatus },
            { data: Instalments },
            { data: PatientType },
            { data: SourceType },
            { data: PlanType },
            { data: ValidityPeriod },
            { data: BedSizeType }
          ) => {
            const data = {
              OwnershipType,
              AddOnServiceType,
              BedStatus,
              DeviceType,
              HospitalsStatus,
              Instalments,
              PatientType,
              SourceType,
              PlanType,
              ValidityPeriod,
              BedSizeType,
            };

            dispatch(addEnumData(data));
            setLoading(false);
          }
        )
      )
      .catch((err) => {
        setLoading(false);
      });
    dispatch(getSpecialisationType());
  }, []);

  const hospitalInfo = hospitalDetailsRes?.data?.data;
  const hospitalBasicInfo = hospitalDetailsRes?.data?.data?.hospitalInfo;
  const specialisationList = useSelector(
    (data: any) => data?.getSpecialisation
  );

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors: formError },
  } = useForm<any>();

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: userDetails?.userId,
    userName: userDetails?.userName,
    role: userDetails?.Roles,
    customer: hospitalBasicInfo?.name,
    customerCode: hospitalBasicInfo?.code,
    customerID:
      hospitalInfo?.hospitalId ?? "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: "string",
    auditDate: "2024-04-11T10:44:38.662Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    const data2 = {
      ...data,
      onboardingDate:
        hospitalDetailsRes?.data?.data?.hospitalInfo?.onboardingDate,
      status: hospitalDetailsRes?.data?.data?.hospitalInfo?.status,
      isActive: hospitalDetailsRes?.data?.data?.hospitalInfo?.isActive,
      isDelete: hospitalDetailsRes?.data?.data?.hospitalInfo?.isDelete,
    };
    const dispatchData = OnBoardDispatchData(
      data2,
      specialisationList,
      userDetails,
      userAuditInfo
    );

    const finalDispatchData = {
      ...dispatchData,
      productandPlanInfos: hospitalDetailsRes?.data?.data?.productandPlanInfos,
      deviceCost: hospitalDetailsRes?.data?.data?.deviceCost,
    };

    dispatch(
      updateHospital({
        id: params?.id,
        isSaveAsDraft: false,
        dispatchData: finalDispatchData,
      })
    );
  };

  const updateHospitalRes = useSelector((data: any) => data?.updateHospital);

  useEffect(() => {
    if (updateHospitalRes?.status === "success") {
      successToast("Hospital Updated Successfully");
      navigate(routesName.hospitalManagementPage);
    } else if (updateHospitalRes?.status === "failed") {
      if (updateHospitalRes?.error?.status === 503) {
        successToast("Hospital Updated Successfully");
        navigate(routesName.hospitalManagementPage);
      } else {
        errorToast(
          updateHospitalRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [updateHospitalRes]);

  const hosiptalInfoObject = HosiptalInfoObject(hospitalInfo);
  const specialisationObject = SpecialisationObject(hospitalInfo);
  const ownerOperatorDetailsObject = OwnerOperatorObject(hospitalInfo);
  const onBoardSourceObject = OnBoardSourceObject(hospitalInfo);
  const hospitalManagementObject = HospitalManagementObject(hospitalInfo);
  const accountManagerObject = AccountManagerObject(hospitalInfo);

  const hospitalOnboardingObject = HospitalOnboardingObject(hospitalInfo);

  useEffect(() => {
    setValue(
      "hospitalAdminManagementName",
      hospitalInfo?.hospitalAdminInfo?.name
    );
    setValue(
      "hospitalAdminManagementMobileNumber",
      hospitalInfo?.hospitalAdminInfo?.phone
    );
    setValue(
      "hospitalAdminManagementEmail",
      hospitalInfo?.hospitalAdminInfo?.email
    );
    setValue("hospitalID", hospitalBasicInfo?.code);
    setValue("userID", hospitalInfo?.hospitalAdminInfo?.userName);
    setValue("hospitalUserId", hospitalInfo?.hospitalAdminInfo?.userId);
  }, [hospitalDetailsRes]);

  const deleteHospitalFn = () => {
    setIsDeleteModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(resetUpdateHospitalState());
    };
  }, []);

  const cancelHospitalFn = () => {
    navigate(-1);
  };

  const editHospitalNavData = [
    {
      label: "Hospital Management",
      navigate: `${routesName.hospitalManagementPage}`,
      highLight: false,
    },

    {
      label: `Edit ${hospitalBasicInfo?.name}`,
      highLight: true,
    },
  ];

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${params?.id}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital deleted successfully");
      navigate(routesName.hospitalManagementPage);
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  const firstErrorField = Object.keys(formError).find(
    (fieldName) => formError[fieldName]
  );

  useEffect(() => {
    if (firstErrorField) {
      const errorMessage = formError?.[firstErrorField]?.message;
      setFirstFormError(errorMessage as string);
    }
  }, [firstErrorField]);

  const scrollToBottom = () => {
    scrollDown?.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  };
  const errorShowOnBottom = () => {
    if (firstFormError) {
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (scrollDown?.current && location?.state?.action === "resetPassword") {
      scrollToBottom();
    }
  }, [scrollDown?.current, location?.state?.action]);

  return (
    <>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      {loading || hospitalDetailsRes?.isLoading ? (
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="">
          <InnerLayout title={`${hospitalBasicInfo?.name}`}>
            <div className="p-6  z-10 top-[80px]">
              <Breadcrumbs data={editHospitalNavData} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="pb-20">
              <div className="mx-6">
                <CustomAccordion
                  title={addHospitalName.BasicInformation}
                  component={
                    <BasicInformation
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      previousData={hosiptalInfoObject}
                    />
                  }
                />
                {specialisationData?.status === "success" &&
                !specialisationData?.isLoading ? (
                  <CustomAccordion
                    title={addHospitalName.Specialisation}
                    component={
                      <Specialisation
                        control={control}
                        setValue={setValue}
                        previousData={specialisationObject}
                      />
                    }
                  />
                ) : (
                  <LoadingSpinner />
                )}

                <CustomAccordion
                  title={addHospitalName.BedSize}
                  component={
                    <BedSize
                      control={control}
                      setValue={setValue}
                      previousData={hospitalBasicInfo}
                    />
                  }
                />

                <CustomAccordion
                  title={addHospitalName.OwnerOperatorDetails}
                  component={
                    <OwnerOperatorDetails
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      previousData={ownerOperatorDetailsObject}
                    />
                  }
                />
                <CustomAccordion
                  title={addHospitalName.OnboardingSource}
                  component={
                    <OnBoardSource
                      control={control}
                      setValue={setValue}
                      previousData={onBoardSourceObject}
                    />
                  }
                />
                <CustomAccordion
                  title={addHospitalName.HospitalManagement}
                  component={
                    <AddHospitalManagemnt
                      control={control}
                      setValue={setValue}
                      previousData={hospitalManagementObject}
                    />
                  }
                />
                <CustomAccordion
                  title={addHospitalName.JeevAccountManager}
                  component={
                    <JeevAccountManager
                      control={control}
                      setValue={setValue}
                      previousData={accountManagerObject}
                      clearErrors={clearErrors}
                    />
                  }
                />
                <CustomAccordion
                  title={addHospitalName.HospitalOnboarding}
                  component={
                    <EditHospitalOnboard
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      previousData={hospitalOnboardingObject}
                      clearErrors={clearErrors}
                      formError={formError}
                    />
                  }
                />
                <div className="h-[2.5rem] flex justify-center">
                  {firstErrorField && (
                    <span className="text-error text-12 flex items-center">
                      <ErrorMessageIcon styles={{ width: "15px" }} /> :{" "}
                      {/* {firstFormError} */}
                      Mandatory fields are required to be filled
                    </span>
                  )}
                </div>
              </div>

              <div className="fixed bottom-0 fit_width z-20 shadow-2xl">
                <div className="bg-white p-3 px-7 flex justify-between">
                  <ErrorWithDeleteButton
                    loading={deleteLoading}
                    name={addHospitalName.DeleteHospital}
                    padding="lg:px-12 py-3 px-5"
                    type="button"
                    onClick={() => deleteHospitalFn()}
                  />

                  <div className="flex gap-3">
                    <SecondaryButton
                      padding="lg:px-18 py-3 px-5"
                      type="button"
                      onClick={() => cancelHospitalFn()}
                      name={addHospitalName.Cancel}
                    />
                    <PrimaryButton
                      padding="lg:px-18 py-3 px-5"
                      loading={updateHospitalRes?.isLoading}
                      name={addHospitalName.Save}
                      onClick={() => errorShowOnBottom()}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div ref={scrollDown}></div>
          </InnerLayout>
        </div>
      )}
    </>
  );
};

export default EditHospitalInformation;
