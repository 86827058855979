import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import {
  EmailOtpDataType,
  forgotPasswordDispatchType,
} from "../../modals/IforgotPasswordCard";
import { resetPasswordDataType } from "../../modals/IresetPasswordCard";
import routesName from "../../constants/routesName";

export const emailCheck = createAsyncThunk(
  "emailCheck",
  async (data: forgotPasswordDispatchType, { rejectWithValue }) => {
    try {
      const response = await API.post("Login/forgotpasswordadmincheck", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data: forgotPasswordDispatchType, { rejectWithValue }) => {
    try {
      const response = await API.post("Login/forgotpassword", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (data: EmailOtpDataType, { rejectWithValue }) => {
    try {
      const response = await API.post("Login/verifyotp", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data: resetPasswordDataType, { rejectWithValue }) => {
    try {
      const response = await API.post("Login/resetpassword", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);
