import enums from "../constants/enums";
import { removeUnderScore } from "../helper";

const StatusWithColor = ({
  value,
  subValue,
}: {
  value: string;
  subValue?: string;
}) => {
  if (value === enums.active && subValue === enums.paid) {
    return (
      <span className="text-green-600 p-1 px-3 bg-green-100 rounded-full text-14 font-medium">
        {`${removeUnderScore(value)} - ${removeUnderScore(subValue)}`}
      </span>
    );
  } else if (value === enums.active && subValue === enums.pilot) {
    return (
      <span className="text-blue-300 p-1 px-3 bg-purple-50 rounded-full text-14 font-medium">
        {`${removeUnderScore(value)} - ${removeUnderScore(subValue)}`}
      </span>
    );
  } else if (value === enums.active) {
    return (
      <span className="text-green-600 p-1 px-3 bg-green-100 rounded-full text-14 font-medium">
        {removeUnderScore(value)}
      </span>
    );
  } else if (value === enums.expired) {
    return (
      <span className="text-red-400 p-1 px-3 bg-red-100 rounded-full text-14 font-medium">
        {removeUnderScore(value)}
      </span>
    );
  } else if (value === enums.deactivated) {
    return (
      <span className="text-yellow-800 p-1 px-3 bg-yellow-100 rounded-full text-14 font-medium">
        {removeUnderScore(value)}
      </span>
    );
  } else if (value === enums.inactive) {
    return (
      <span className="text-yellow-800 p-1 px-3 bg-yellow-100 rounded-full text-14 font-medium">
        {removeUnderScore(value)}
      </span>
    );
  } else if (value === enums.inprogress) {
    return (
      <span className="text-blue-300 p-1 px-3 bg-purple-50 rounded-full text-14 font-medium">
        {removeUnderScore(value)}
      </span>
    );
  } else {
    return null;
  }
};

export default StatusWithColor;
