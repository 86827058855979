import Breadcrumbs from "../../components/breadCrumbs";
import AddHospitalProgressBar from "../../features/hospitalManagement/addHospitalProgressBar";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  EmailInputForm,
  InputForm,
  PhoneNumInputForm,
} from "../../components/forms/inputForm";
import {
  ErrorWithDeleteButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import { CheckBoxForm } from "../../components/forms/checkBoxForm";
import { useEffect, useRef, useState } from "react";
import DataPickForm from "../../components/forms/dataPickForm";
import moment from "moment";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import {
  addHospital,
  createHospitalId,
  getHospitalById,
  getSpecialisationType,
  updateHospital,
} from "../../redux/addHospital/hospitalManagementReducer";
import successToast from "../../utils/successToast";
import SuccessMessageModal from "../../components/hospitalManagement/successMessageModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  resetAddHospitalState,
  resetCreateHospitalIdState,
  resetCreateHospitalUserIdState,
  resetGetHospitalUserPasswordState,
  resetUpdateHospitalState,
} from "../../redux/addHospital/hospitalManagementSlice";
import { OnBoardDispatchData } from "../../features/hospitalManagement/dataformatter";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import errorToast from "../../utils/errorToast";
import routesName from "../../constants/routesName";
import enums from "../../constants/enums";
import InnerLayout from "../../layout/innerLayout";
import { addHospitalNavData } from "../../jsonData/breadcrumbData/hospitalManagement";
import AutoGeneratePassword from "../../components/autoGeneratePassword";
import useManagementRoleType from "../../utils/managementRoleType";
import ConfirmationModal from "../../components/modals/confirmationModal";
import useAxios from "../../hooks/useAxios";
import { hospitalApi } from "../../jsonData/apiPathData/hospital";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import UserAuditDetails from "../../utils/userAuditDetails";
import { Emailicons, PhoneEnabledIcons } from "../../components/muiIconsImport";
import { commonDevices } from "../../redux/devices/deviceMangmentSlice";

const OnboardHospital = () => {
  const hospitalInformation = localStorage.getItem(
    addHospitalName.hospitalInformation
  );
  const productActivation = localStorage.getItem(
    addHospitalName.productActivation
  );
  const formattedHospitalInfo = JSON.parse(hospitalInformation as string);
  const formattedProductActivation = JSON.parse(productActivation as string);
  const [errors, setErrors] = useState({
    hospitalId: true,
    userId: true,
    password: true,
  });
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = AccessTokenDecoder();
  const { canWrite } = useManagementRoleType();
  const pathAction = location?.state?.action;
  const hospitalIdRef = useRef<HTMLInputElement>(null);
  const hospitalUserIdRef = useRef<HTMLInputElement>(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [previousData, setPreviousData] = useState<any>(
    JSON.parse(localStorage.getItem(addHospitalName.hospitalOnboarding) || "{}")
  );

  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();

  useEffect(() => {
    // getting hospital onboarding data data from localStorage if there go in add flow with continue
    const previousData = localStorage.getItem(
      addHospitalName.hospitalOnboarding
    );
    if (previousData) {
      setPreviousData(previousData ? JSON.parse(previousData) : {});
    }
    // for edit initail data from api call
    if (location?.state?.action === enums.edit && location?.state?.id) {
      dispatch(getHospitalById(location?.state?.id));
    }
    // specialisationList action dispatch
    dispatch(getSpecialisationType());

    return () => {
      // dispatch(resetGetHospitalByIdState());
      dispatch(resetAddHospitalState());
      dispatch(resetUpdateHospitalState());
      dispatch(resetCreateHospitalIdState());
      dispatch(resetCreateHospitalUserIdState());
      dispatch(resetGetHospitalUserPasswordState());
    };
  }, []);

  const hospitalDetailsRes = useSelector((data: any) => data?.getHospitalById);
  const specialisationList = useSelector(
    (data: any) => data?.getSpecialisation
  );
  const hospitalDetailsData = hospitalDetailsRes?.data?.data;

  const deleteHospitalRes = useSelector(
    (data: any) => data?.deleteHospitalById
  );

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors: formError },
  } = useForm<any>();

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: userDetails?.userId,
    userName: userDetails?.userName,
    role: userDetails?.Roles,
    customer:
      pathAction === enums.add
        ? formattedHospitalInfo?.hospitalName
        : hospitalDetailsData?.hospitalInfo?.name ?? "",
    customerCode:
      pathAction === enums.add || pathAction === enums.inprogress
        ? watch("hospitalID")
        : hospitalDetailsData?.hospitalInfo?.code ?? "",
    customerID:
      hospitalDetailsData?.hospitalId ?? "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: "string",
    auditDate: "2024-04-11T10:44:38.662Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    const stringiftData = JSON.stringify(data);
    // storing hospital info in localstorage while only in adding flow
    if (pathAction === enums.add || pathAction === enums.inprogress) {
      localStorage.setItem(addHospitalName.hospitalOnboarding, stringiftData);
    }

    // for add hospiatl in normal flow getting data from local storage for add only
    if (hospitalInformation && productActivation && pathAction === enums.add) {
      //  merging data
      const finalData = {
        ...formattedHospitalInfo,
        ...formattedProductActivation,
        ...data,
      };

      // converting data in the format for dispatch
      const dispatchData = OnBoardDispatchData(
        finalData,
        specialisationList,
        userDetails,
        userAuditInfo
      );
      // add hospital dispatch in normal flow
      dispatch(addHospital({ isSaveAsDraft: false, dispatchData }));
    }
    // update the hospital, in edit or inprogress flow, the data is coming from location state for edit and inprogress
    else if (pathAction === enums.edit || pathAction === enums.inprogress) {
      const finalData = {
        ...location?.state?.data,
        ...data,
        status: hospitalDetailsData?.hospitalInfo?.status,
        isActive: hospitalDetailsData?.hospitalInfo?.isActive,
        isDelete: hospitalDetailsData?.hospitalInfo?.isDelete,
      };

      const dispatchData = OnBoardDispatchData(
        finalData,
        specialisationList,
        userDetails,
        userAuditInfo
      );

      dispatch(
        updateHospital({
          id: location?.state?.id,
          isSaveAsDraft: false,
          dispatchData,
        })
      );
    }
  };
  const addHospitalRes = useSelector((data: any) => data?.addHospital);
  const updateHospitalRes = useSelector((data: any) => data?.updateHospital);
  useEffect(() => {
    // add hospital Response handle
    if (addHospitalRes.status === "success") {
      successToast("Hospital Added Successfully");
      setShowModal(true);
      localStorage.removeItem(addHospitalName.hospitalInformation);
      localStorage.removeItem(addHospitalName.productActivation);
      localStorage.removeItem(addHospitalName.hospitalOnboarding);
    } else if (addHospitalRes.status === "failed") {
      if (addHospitalRes?.error?.status === 503) {
        successToast("Hospital Added Successfully");
        setShowModal(true);
        localStorage.removeItem(addHospitalName.hospitalInformation);
        localStorage.removeItem(addHospitalName.productActivation);
        localStorage.removeItem(addHospitalName.hospitalOnboarding);
      } else if (
        addHospitalRes?.error?.data?.error?.code === "DUPLICATE_DEVICE_ERROR"
      ) {
        errorToast(addHospitalRes?.error?.data?.error?.message);
        navigate(-1);
        dispatch(commonDevices(addHospitalRes.error?.data?.data));
      } else {
        errorToast(
          addHospitalRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [addHospitalRes]);

  useEffect(() => {
    // update hospital Response handle
    if (updateHospitalRes.status === "success") {
      successToast("Hospital Updated Successfully");
      setShowModal(true);
      localStorage.removeItem(addHospitalName.hospitalInformation);
      localStorage.removeItem(addHospitalName.productActivation);
      localStorage.removeItem(addHospitalName.hospitalOnboarding);
    } else if (updateHospitalRes.status === "failed") {
      // errorToast("Something went wrong");
      if (updateHospitalRes?.error?.status === 503) {
        successToast("Hospital Updated Successfully");
        setShowModal(true);
        localStorage.removeItem(addHospitalName.hospitalInformation);
        localStorage.removeItem(addHospitalName.productActivation);
        localStorage.removeItem(addHospitalName.hospitalOnboarding);
      } else if (
        updateHospitalRes?.error?.data?.error?.code === "DUPLICATE_DEVICE_ERROR"
      ) {
        errorToast(updateHospitalRes?.error?.data?.error?.message);
        navigate(-1);
        dispatch(commonDevices(updateHospitalRes.error?.data?.data));
      } else {
        errorToast(
          updateHospitalRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [updateHospitalRes]);

  useEffect(() => {
    const data = {
      name:
        formattedHospitalInfo?.hospitalName ??
        location?.state?.data?.hospitalName,
      location: formattedHospitalInfo?.city ?? location?.state?.data?.city,
    };

    // create hospital id
    if (
      (pathAction === enums.add || pathAction === enums.inprogress) &&
      !previousData?.hospitalID
    ) {
      dispatch(createHospitalId(data));
    }
  }, []);

  const hospitalIdRes = useSelector((data: any) => data?.createHospitalId);

  useEffect(() => {
    // create hospital id response hnadle
    if (hospitalIdRes?.status === "success") {
      setErrors((prev) => ({
        ...prev,
        hospitalId: false,
      }));
      setValue("hospitalID", hospitalIdRes?.data?.data);
      clearErrors(["hospitalID"]);
    } else if (hospitalIdRes?.status === "failed") {
      setErrors((prev) => ({
        ...prev,
        hospitalId: true,
      }));
      // unchecking the radio button if api fails
      if (hospitalIdRef.current) {
        hospitalIdRef.current.checked = false;
      }
      errorToast("Something went worng wile creating hospital Id");
    }
  }, [hospitalIdRes]);

  // create hospital user id (for time being this option is not in use)
  // const handleCreateUserID = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const email = watch("hospitalAdminManagementEmail");
  //   if (email) {
  //     dispatch(createHospitalUserId(email));
  //   } else {
  //     // unchecking the radio button if api fails
  //     if (hospitalUserIdRef.current) {
  //       hospitalUserIdRef.current.checked = false;
  //     }
  //     errorToast("Please provide email address to create User ID");
  //   }
  // };
  // const hospitalUserIdRes = useSelector(
  //   (data: any) => data?.createHospitalUserId
  // );
  // useEffect(() => {
  //   // create hospital user id response hnadle
  //   if (hospitalUserIdRes?.status === "success") {
  //     setErrors((prev) => ({
  //       ...prev,
  //       userId: false,
  //     }));
  //     setValue("userID", hospitalUserIdRes?.data?.data);
  //     clearErrors(["userID"]);
  //   } else if (hospitalUserIdRes?.status === "failed") {
  //     setErrors((prev) => ({
  //       ...prev,
  //       userId: true,
  //     }));
  //     // unchecking the radio button if api fails
  //     if (hospitalUserIdRef.current) {
  //       hospitalUserIdRef.current.checked = false;
  //     }
  //     errorToast("Something went worng while creating hospital User Id");
  //   }
  // }, [hospitalUserIdRes]);
  // const [createUserIdDisplay, setCreateUserIdDisplay] = useState(false);

  const currentDate = new Date();
  const formatedCurrentDate = moment(currentDate).format("YYYY-MM-DD");

  // setting initial values for edit flow
  useEffect(() => {
    if (pathAction === enums.edit) {
      setValue(
        "hospitalAdminManagementName",
        hospitalDetailsData?.hospitalAdminInfo?.name
      );
      setValue(
        "hospitalAdminManagementMobileNumber",
        hospitalDetailsData?.hospitalAdminInfo?.phone
      );
      setValue(
        "hospitalAdminManagementEmail",
        hospitalDetailsData?.hospitalAdminInfo?.email
      );
      setValue("hospitalID", hospitalDetailsData?.hospitalInfo?.code);
      setValue("userID", hospitalDetailsData?.hospitalAdminInfo?.userName);
    }
  }, [hospitalDetailsRes]);

  const cancelHospitalFn = () => {
    localStorage.removeItem(addHospitalName.hospitalInformation);
    localStorage.removeItem(addHospitalName.productActivation);
    localStorage.removeItem(addHospitalName.hospitalOnboarding);
    navigate(routesName.hospitalManagementPage);
  };

  useEffect(() => {
    // condition for to show create user id radio button in edit flow based on phone number and email both change
    if (
      hospitalDetailsData?.hospitalAdminInfo?.phone !==
        watch("hospitalAdminManagementMobileNumber") &&
      hospitalDetailsData?.hospitalAdminInfo?.email !==
        watch("hospitalAdminManagementEmail")
    ) {
      // setCreateUserIdDisplay(true);
      if (hospitalUserIdRef.current) {
        hospitalUserIdRef.current.checked = false;
      }
      // user id is set for empty so that to apply validation
      setValue("userID", "");
      // in edit flow when phone number and email both are changed then hospitalUserId should be send as 0
      setValue("hospitalUserId", 0);
    } else {
      // setCreateUserIdDisplay(false);
      // else same data
      setValue("userID", hospitalDetailsData?.hospitalAdminInfo?.userName);
      setValue(
        "hospitalUserId",
        hospitalDetailsData?.hospitalAdminInfo?.userId
      );
    }
  }, [
    watch("hospitalAdminManagementMobileNumber"),
    watch("hospitalAdminManagementEmail"),
  ]);
  const deleteHospitalFn = () => {
    setIsDeleteModal(true);
  };

  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${location?.state?.id}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital Deleted successfully");
      navigate(routesName.hospitalManagementPage);
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  const editHospitalNavData = [
    {
      label: "Hospital Management",
      navigate: `${routesName.hospitalManagementPage}`,
      highLight: false,
    },

    {
      label: `Edit ${hospitalDetailsData?.hospitalInfo?.name}`,
      highLight: true,
    },
  ];

  return (
    <>
      <SuccessMessageModal
        isVisible={ShowModal}
        onClose={() => {
          setShowModal(false);
          navigate(routesName.hospitalManagementPage);
          dispatch(resetAddHospitalState());
        }}
        title={
          pathAction === enums.add || pathAction === enums.inprogress
            ? "The hospital has been successfully onboarded!"
            : pathAction === enums.edit
            ? "Hospital Updated Successfully"
            : ""
        }
        onboarding={
          pathAction === enums.add || pathAction === enums.inprogress
            ? true
            : false
        }
        email={watch("hospitalAdminManagementEmail")}
        emailFailedMess={
          updateHospitalRes?.error?.status === 503 ||
          addHospitalRes?.error?.status === 503
            ? "Please contact for the Admin for any details"
            : ""
        }
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      {hospitalDetailsRes?.isLoading ? (
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
      ) : (
        <InnerLayout
          title={
            hospitalDetailsData
              ? hospitalDetailsData?.hospitalInfo?.name
              : "Add Hospital"
          }
        >
          <div className="h-full relative">
            <div className="px-6 py-5 bg-blue-150 z-10 sticky top-[80px]">
              <Breadcrumbs
                data={
                  pathAction === enums.add
                    ? addHospitalNavData
                    : editHospitalNavData
                }
              />
              <div className="py-5">
                <AddHospitalProgressBar active={3} />
              </div>
            </div>
            <div className="">
              <form onSubmit={handleSubmit(onSubmit)} className="pb-20">
                <div className="m-6 rounded-lg shadow-sm1 bg-white">
                  <div className="p-5">
                    <span className="text-16 font-semibold text-primary">
                      {addHospitalName.HospitalID}
                    </span>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
                      <InputForm
                        label={"Hospital ID"}
                        id="hospitalID"
                        control={control}
                        disabled={true}
                        defaultValue={
                          hospitalDetailsData?.hospitalInfo?.code ??
                          previousData?.hospitalID
                        }
                      />
                    </div>
                    <hr />
                    <div className="mt-5">
                      <span className="text-16 font-semibold text-primary">
                        {addHospitalName.HospitalManagement}
                      </span>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
                      <InputForm
                        label={"Name*"}
                        id="hospitalAdminManagementName"
                        control={control}
                        defaultValue={
                          hospitalDetailsData?.hospitalAdminInfo?.name ??
                          previousData?.hospitalAdminManagementName
                        }
                        maxLength={50}
                      />
                      <PhoneNumInputForm
                        label={"Mobile Number*"}
                        id="hospitalAdminManagementMobileNumber"
                        control={control}
                        defaultValue={
                          hospitalDetailsData?.hospitalAdminInfo?.phone ??
                          previousData?.hospitalAdminManagementMobileNumber
                        }
                        maxLength={50}
                      />
                      <EmailInputForm
                        label={"Email ID*"}
                        id="hospitalAdminManagementEmail"
                        control={control}
                        defaultValue={
                          hospitalDetailsData?.hospitalAdminInfo?.email ??
                          previousData?.hospitalAdminManagementEmail
                        }
                        maxLength={50}
                      />
                    </div>
                    <hr />
                    <div className="grid lg:grid-cols-11 md:grid-cols-4 gap-8 py-6">
                      <div
                        className={`md:pe-8 md:border-r lg:col-span-4 col-span-2`}
                      >
                        <div className="my-3">
                          <span className="text-16 font-semibold text-primary">
                            {addHospitalName.UserID}
                          </span>
                        </div>
                        <div className="text-14 font-semibold text-secondary pt-3">
                          <div className="flex items-center gap-2">
                            <span>
                              <PhoneEnabledIcons />
                            </span>
                            <span>
                              {watch("hospitalAdminManagementMobileNumber")}
                            </span>
                          </div>

                          <div className="ps-4 py-3">or</div>

                          <div className="flex items-center gap-2">
                            <span>
                              <Emailicons />
                            </span>
                            <span>{watch("hospitalAdminManagementEmail")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="lg:col-span-4 col-span-2">
                        <div className="pb-4">
                          <AutoGeneratePassword
                            name="userPassword"
                            control={control}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            errors={formError}
                            required={
                              pathAction === enums.add ||
                              (hospitalDetailsData?.hospitalAdminInfo?.phone !==
                                watch("hospitalAdminManagementMobileNumber") &&
                                hospitalDetailsData?.hospitalAdminInfo
                                  ?.email !==
                                  watch("hospitalAdminManagementEmail"))
                                ? true
                                : false
                            }
                          />
                        </div>

                        <CheckBoxForm
                          label={"Set a new password after logging in"}
                          id="setNewPAssword"
                          type="checkbox"
                          control={control}
                          required={false}
                          defaultValue={true}
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        pathAction === enums.edit ? "hidden" : "block"
                      }`}
                    >
                      <hr />
                      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 p-3">
                        <div>
                          <div className="py-6">
                            <span className="text-16 font-semibold text-primary">
                              {addHospitalName.OnboardingDate}
                            </span>
                          </div>

                          <DataPickForm
                            label={"Onboarding Date"}
                            id="onboardingDate"
                            control={control}
                            defaultValue={
                              pathAction === enums.edit
                                ? hospitalDetailsData?.hospitalInfo
                                    ?.onboardingDate
                                : formatedCurrentDate
                            }
                            minDate={moment(new Date())}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fixed bottom-0 fit_width z-20 shadow-2xl">
                  <div className="bg-white p-3 px-7 flex justify-between">
                    {pathAction === enums.edit ||
                    pathAction === enums.inprogress ? (
                      <>
                        <ErrorWithDeleteButton
                          loading={deleteHospitalRes?.isLoading}
                          name={addHospitalName.DeleteHospital}
                          padding="lg:px-12 py-3 px-5"
                          type="button"
                          onClick={() => deleteHospitalFn()}
                        />
                      </>
                    ) : (
                      <SecondaryButton
                        padding="lg:px-18 py-3 px-5"
                        type="button"
                        name={addHospitalName.Cancel}
                        onClick={() => cancelHospitalFn()}
                      />
                    )}
                    <div className="flex gap-3">
                      {pathAction === enums.edit ||
                      pathAction === enums.inprogress ? (
                        <>
                          <SecondaryButton
                            padding="lg:px-18 py-3 px-5"
                            type="button"
                            name={addHospitalName.Cancel}
                            onClick={() => cancelHospitalFn()}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {canWrite("OnboardingHospital") && (
                        <PrimaryButton
                          padding="lg:px-18 py-3 px-5"
                          loading={
                            addHospitalRes?.isLoading ||
                            updateHospitalRes?.isLoading
                          }
                          name={addHospitalName.Continue}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </InnerLayout>
      )}
    </>
  );
};

export default OnboardHospital;
