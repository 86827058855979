import React, { useEffect, useState } from "react";

import {
  Emailicons,
  LockOpenIcon,
  MailOutlineIcons,
  PersonIcon,
  PhoneEnabledIcons,
  ProfileCardIcon,
} from "../components/muiIconsImport";
import StatusWithColor from "../utils/StatusWithColor";
import DefaultImage from "../assests/images/defaultImage.svg";
import enums from "../constants/enums";

import InnerLayout from "../layout/innerLayout";
import { LoadingSpinner } from "../components/ui/loadinSpinner";
import GenderIcon from "../assests/icons/gender.svg";
import ProfileforgotPasswordCard from "../features/proffileForgotPasswordCard";
import { AccessTokenDecoder } from "../utils/accessTokenDecoder";
import { resetGetAccontManagerState } from "../redux/accountManager/accountManagerSlice";
import { useAppDispatch } from "../redux/store";
import { getAccontManager } from "../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import { ResponseType } from "../modals/IreduxResponse";
import { AccountManagerType } from "../modals/IaccountManager";
import ConfirmationModal from "../components/modals/confirmationModal";
import LogoutIcon from "../assests/icons/logoutIcon.svg";

export default function Userprofile() {
  const [reset, setReset] = useState(false);
  const userDetail = AccessTokenDecoder();

  const [accountManagerData, setAccountManagerData] =
    useState<AccountManagerType>();
  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const dispatch = useAppDispatch();
  const xx = AccessTokenDecoder();
  useEffect(() => {
    dispatch(getAccontManager(parseInt(xx.userId)));

    return () => {
      dispatch(resetGetAccontManagerState());
    };
  }, []);
  const getAccontManagerRes = useSelector(
    (data: { getAccontManager: ResponseType }) => data.getAccontManager
  );

  useEffect(() => {
    if (getAccontManagerRes.status === "success") {
      setAccountManagerData(getAccontManagerRes.data.data);
    } else if (getAccontManagerRes.status === "failed") {
      console.error(getAccontManagerRes, "getAccontManagerRes");
    }
  }, [getAccontManagerRes]);

  const handlecloseclick = () => {
    setReset(false);
  };
  const logoutFn = () => {
    sessionStorage.clear();
    // navigate("/");
    window.location.reload();
  };

  const AuthoritiesData: any =
    accountManagerData?.rolePermission?.permissions?.filter?.(
      (item: any) => item?.permissionName != "View"
    );

  return (
    <>
      <ConfirmationModal
        isVisible={isLogoutModal}
        onClose={() => setIsLogoutModal(false)}
        onClick={() => logoutFn()}
        actionText={"Logout"}
        title={"Are you sure you want to log out? "}
        icon={LogoutIcon}
      />
      <InnerLayout title="Profile">
        {getAccontManagerRes?.isLoading ? (
          <div className="flex h-[90vh] justify-center items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="px-6 py-5">
            <div className="">
              <ProfileforgotPasswordCard
                isopenvalue={reset}
                handlecloseclick={handlecloseclick}
              />
            </div>
            <div className="lg:grid lg:grid-cols-5 gap-6 mt-6">
              <div className="col-span-10">
                <div className="shadow-sm1 border border-gray-250 rounded-lg">
                  <div className="bg-white rounded-t-lg">
                    <div className="flex gap-4 p-8 items-center">
                      <div className="w-[100px] h-[100px] flex items-center">
                        <img
                          src={
                            accountManagerData?.photo
                              ? accountManagerData?.photo
                              : DefaultImage
                          }
                          className="border-4 rounded-full border-blue-50 shadow-ex1 object-contain w-[100%] h-[100%]"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div className="ps-2">
                        <div>
                          <span className="text-20 font-semibold text-primary">
                            {accountManagerData?.name}
                          </span>
                          <span className="ps-4">
                            <StatusWithColor
                              value={
                                accountManagerData?.isActive === 1
                                  ? enums.active
                                  : enums.deactivated
                              }
                            />
                          </span>
                        </div>
                        <div className="flex max-lg:flex-col items-center">
                          <div className="flex items-center text-secondary text-14 font-medium pt-3">
                            <span className="pe-3 border-r flex items-center gap-1">
                              <ProfileCardIcon
                                styles={{ width: "20px", height: "20px" }}
                              />
                              {accountManagerData?.employeeID}
                            </span>
                            <span className="px-3 border-r flex items-center gap-1">
                              <PersonIcon
                                styles={{ width: "20px", height: "20px" }}
                              />{" "}
                              {accountManagerData?.role}
                            </span>
                            <span className="px-3 lg:border-r flex gap-2 items-center">
                              <img src={GenderIcon} width={20} height={20} />{" "}
                              {accountManagerData?.gender}
                            </span>
                          </div>
                          <div className="flex items-center text-secondary text-14 font-medium pt-3 lg:ps-3 ps-0">
                            <span className="pe-3 border-r flex items-center gap-1">
                              <PhoneEnabledIcons
                                styles={{ width: "20px", height: "20px" }}
                              />
                              <span className="ps-1">
                                +91 {accountManagerData?.phone}
                              </span>
                            </span>
                            <span className="px-3 flex items-center gap-1">
                              <MailOutlineIcons
                                styles={{ width: "20px", height: "20px" }}
                              />{" "}
                              {accountManagerData?.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-3 flex  mx-10 text-blue-300 font-semibold">
                    {userDetail.Roles === enums.Management && (
                      <button
                        type="button"
                        className="flex items-center me-10"
                        onClick={() => setReset(true)}
                      >
                        {" "}
                        <LockOpenIcon />
                        &nbsp;Reset Password
                      </button>
                    )}
                    <button
                      type="button"
                      className="p-3  text-blue-300 font-semibold flex items-center"
                      onClick={() => setIsLogoutModal(true)}
                    >
                      {" "}
                      <LockOpenIcon />
                      &nbsp;Log Out
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <span className="text-20 font-semibold text-primary">
                  Authorities
                </span>
              </div>

              <div className="col-span-10">
                <div className="flex flex-wrap gap-4">
                  {AuthoritiesData?.map((item: any, index: number) => (
                    <div className="border border-gray-250 py-2 px-3 flex justify-center items-center text-center rounded bg-white text-16 font-medium text-primary shadow-sm">
                      {item?.permissionName}
                    </div>
                  ))}

                  {AuthoritiesData?.length <= 0 && (
                    <span className="text-secondary text-14 font-medium">
                      No permission assigned yet
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </InnerLayout>
    </>
  );
}
