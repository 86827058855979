import DashboardCard from "../ui/dashboardCard";
import Logo from "../../assests/images/susscess.svg";
import { PrimaryButton, SecondaryButton } from "../buttons/buttons";
import { LoadingSpinner } from "../ui/loadinSpinner";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onClick?: () => void;
  title?: string;
  description?: string;
  actionText?: string | JSX.Element;
  icon?: string;
  isLoading?: boolean;
};

const ConfirmationModal = ({
  isVisible,
  onClose,
  onClick,
  title,
  description,
  actionText = "Login",
  icon = Logo,
  isLoading,
}: ModalProps) => {
  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="p-[20px] flex flex-col items-center">
        <span>
          <img src={icon} width={100} height={100} />
        </span>
        <span className="text-24 text-primary font-semibold mt-6 text-center">
          {title}
        </span>
        <span className="pt-3 pb-8 text-16 font-medium text-secondary">
          {description}
        </span>
        <div className="flex justify-around gap-7">
          <SecondaryButton name={"Cancel"} onClick={onClose} />
          <PrimaryButton
            name={
              isLoading ? (
                <LoadingSpinner width="w-4" height="h-4" />
              ) : (
                actionText
              )
            }
            onClick={onClick}
          />
        </div>
      </div>
    </DashboardCard>
  );
};

export default ConfirmationModal;
