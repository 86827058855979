import { useState } from "react";
import { DownArrow, UpArrow } from "../muiIconsImport";

interface accordionType {
  title: string;
  component: JSX.Element;
  bgcolor?: string;
  margin?: string;
  padding?: string;
  innerPadding?: string;
  isBorder?: boolean;
}

const CustomAccordion = ({
  title,
  component,
  bgcolor = "bg-gray-50",
  margin = "my-5",
  padding = "",
  innerPadding = "px-5",
  isBorder = true,
}: accordionType) => {
  const [dropdown, setDropdown] = useState(true);
  return (
    <div className={`w-full ${margin} ${padding}`}>
      <div
        className={` ${isBorder && "shadow-sm1 border-gray-250"}  rounded-lg`}
      >
        <div
          className={`${bgcolor} text-start rounded-t-lg p-3 flex justify-between items-center ${innerPadding} cursor-pointer`}
          onClick={() => {
            setDropdown((prev) => !prev);
          }}
        >
          <span className="text-primary text-18 font-semibold">{title}</span>
          <button
            className="text-white rounded-full flex items-center bg-blue-300"
            type="button"
          >
            {dropdown ? <UpArrow /> : <DownArrow />}
          </button>
        </div>
        <hr />
        {dropdown ? (
          <div className={`${innerPadding} py-6 bg-white rounded-b-lg`}>
            {component}
          </div>
        ) : (
          <div className="hidden">{component}</div>
        )}
      </div>
    </div>
  );
};

export default CustomAccordion;
