import { Provider } from "react-redux";
import { Index } from "./routes";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
// import { HubConnectionBuilder } from '@microsoft/signalr';
// import constants from "./constants";
// import { AccessTokenDecoder } from "./utils/accessTokenDecoder";
import { useEffect } from "react";
import { startConnection } from "./utils/signalRService";
//import { startConnection } from "./utils/signalRService";
function App() {
  useEffect(() => {
    startConnection(); // Start SignalR connection when the app mounts
  }, []);
  //   const connection = new HubConnectionBuilder()
  //   .withUrl('https://localhost:7298/chatHub')
  //   .build();

  //   connection.start()
  //     .then(() => console.log('Connected to SignalR hub'))
  //     .catch(err => console.error('Error connecting to hub:', err));

  // connection.on('ReceiveMessage', message => {
  // if(sessionStorage.getItem(constants.accessTokenText) !== null ){
  // const user = AccessTokenDecoder()
  // if(user.userId == message){
  //   window.location.replace("http://http://localhost:3000/");
  // }
  // }
  // });
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Index />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
