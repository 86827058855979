import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

const ProductCheckBox = ({
  data,
  setValue,
  control,
  name,
  defaultValue,
  clearErrors,
}: any) => {
  const initialValue = [
    {
      productId: 1000,
      name: "Management Assistant",
    },
  ];

  const [selectedItems, setSelectedItems] = useState<any>(
    defaultValue ? [...defaultValue, ...initialValue] : initialValue
  );

  const handleCheckboxChange = (item: any, isChecked: any) => {
    clearErrors([name]);
    if (item.productId !== 1000) {
      if (isChecked) {
        setSelectedItems([...selectedItems, item]);
      } else {
        setSelectedItems(
          selectedItems.filter(
            (selectedItem: any) => selectedItem.productId !== item.productId
          )
        );
      }
    }
  };

  useEffect(() => {
    setValue(name, selectedItems);
  }, [selectedItems]);

  return (
    <>
      <div className="flex gap-8">
        {data?.map((item: any) => (
          <div className="flex gap-3" key={item.productId}>
            <input
              type="checkbox"
              id={item.name}
              checked={selectedItems?.some(
                (selectedItem: any) => selectedItem.productId === item.productId
              )}
              onChange={(e) => {
                handleCheckboxChange(item, e.target.checked);
              }}
              className="checkbox-input"
              disabled={item.productId === 1000}
            />
            <label
              htmlFor={item.name}
              className={`text-14 ${
                selectedItems?.some(
                  (selectedItem: any) =>
                    selectedItem.productId === item.productId
                )
                  ? "text-primary font-semibold"
                  : "text-secondary font-medium"
              }`}
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>

      <Controller
        control={control}
        name={name}
        rules={{
          validate: (value) =>
            value.some((item: any) => item.productId === 1000)
              ? true
              : "Please select Management Assistant",
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <div>
              <input
                type="hidden"
                name={name}
                value={JSON.stringify(value) || ""}
              />
            </div>
            <div>
              <span className="text-error text-12">{error?.message}</span>
            </div>
          </>
        )}
      />
    </>
  );
};

export default ProductCheckBox;
