import moment from "moment";
import enums from "../constants/enums";

export const formatNotificationData = (data: any) => {
    let formateddata = data.reduce((curr: any, nxt: any) => {
        if (moment(nxt?.notification_sent).format("DD-MM-YYYY") === moment(new Date()).format("DD-MM-YYYY")) {
            curr = [...curr, { current: enums.today, ...nxt }]
        } else {
            curr = [...curr, { current: enums.older, ...nxt }]
        }
        return curr
    }, []).reduce((acc: any, obj: any) => {
        const key = obj['current'];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
    const { Older, Today } = formateddata;
    if (Older && Older.length > 0 && Today && Today.length > 0) {
        formateddata = { [enums.today]: [...Today], [enums.older]: [...Older] };
    } else if (Older && Older.length > 0) {
        formateddata = { [enums.older]: [...Older] };
    } else if (Today && Today.length > 0) {
        formateddata = { [enums.today]: [...Today] };
    }
    return formateddata
}