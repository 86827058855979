import { useEffect, useState } from "react";

export interface screenSizetype {
  width: number;
  height: number;
}

const useScreenSizeDetector = () => {
  const [screen, setScreen] = useState<screenSizetype>({
    width: window?.innerWidth,
    height: window?.innerHeight,
  });

  const handleResize = () => {
    setScreen({ width: window?.innerWidth, height: window?.innerHeight });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return screen;
};

export default useScreenSizeDetector;
