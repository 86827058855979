import React, { useEffect, useState } from "react";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import { AddCircledIcon } from "../../components/muiIconsImport";
import AddorEditDevice from "../../features/devices/addorEditDevice";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import routesName from "../../constants/routesName";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import { useAppDispatch } from "../../redux/store";
import { postUpdateDevices } from "../../redux/devices/deviceManagmentReducer";
import successToast from "../../utils/successToast";
import errorToast from "../../utils/errorToast";
import { useSelector } from "react-redux";
import { resetpostUpdateDevices } from "../../redux/devices/deviceMangmentSlice";
import useAxios from "../../hooks/useAxios";
import ConfirmationModal from "../../components/modals/confirmationModal";
import DeleteIcon from "../../assests/icons/deleteIcon.png";

const breadcrumbData = [
  {
    label: "Devices",
    navigate: `${routesName.devicesPage}/${routesName.alldevice}`,
    highLight: false,
  },

  {
    label: "Edit Device",
    highLight: true,
  },
];

function EditDevice() {
  const [isDeleteModal, setisDeleteModal] = useState<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const {
    deviceId,
    imeiNumber,
    macAddress,
    modelName,
    status,
    serialNumber,
    islinked,
  } = location.state;
  const userDetails = AccessTokenDecoder();
  const { control, watch, register, setValue, unregister, handleSubmit } =
    useForm<any>();
  const { data, error, loading, deleteFn } = useAxios({
    method: "delete",
    path: `Devices/devices/delete/id?deviceid=${deviceId}`,
  });
  useEffect(() => {
    if (data?.success) {
      successToast("Devices deleted successfully");
      navigate(-1);
    } else if (error) {
      errorToast("Something Went Wrong");
    }
  }, [data, error]);
  const devicesList: any = useSelector((data: any) => data?.postupdateDevices);
  useEffect(() => {
    return () => {
      dispatch(resetpostUpdateDevices());
    };
  }, []);

  const handelsubmits = () => {
    const formData = {
      deviceId: deviceId,
      modelName: watch(`modelNumber1`)?.trim() || modelName?.trim(),
      SerialNumber: watch(`SerialNumber1`)?.trim() || serialNumber?.trim(),
      MACAddress: watch(`MACAddress1`)?.trim() || macAddress?.trim(),
      IMEINumber: watch(`IMEINumber1`)?.trim() || imeiNumber?.trim(),
      status: status,
      userName: userDetails.userName,
    };
    dispatch(postUpdateDevices(formData));
  };
  const handelcancle = () => {
    navigate(-1);
  };
  useEffect(() => {
    // create hospital user password response hnadle
    if (devicesList?.status === "success") {
      successToast("Devices edited successfully");
      navigate(-1);
    } else if (devicesList?.status === "failed") {
      errorToast(`${devicesList.error.data.message}`);
    }
  }, [devicesList]);

  return (
    <>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setisDeleteModal(false);
        }}
        onClick={() => {
          deleteFn();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Device?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={loading}
      />
      <InnerLayout title="Edit Device">
        <div className="py-5">
          <div className="px-6">
            <Breadcrumbs data={breadcrumbData} />
          </div>

          <form onSubmit={handleSubmit(handelsubmits)}>
            <div className="px-6">
              <div className="max-w-sm w-full lg:max-w-full lg:flex bg-white mt-10 relative rounded-lg shadow-sm1">
                <div className="grid grid-cols-1">
                  <div className="mt-1 ps-7">
                    <AddorEditDevice
                      control={control}
                      index={1}
                      isEdit
                      count={2}
                      defaultValue={{
                        imeiNumber,
                        macAddress,
                        modelName,
                        serialNumber,
                      }}
                      handledelete={() => setisDeleteModal(true)}
                      item={1}
                      data={watch()}
                      register={register}
                      setvalue={setValue}
                      unregister={unregister}
                      islinked={islinked}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed bottom-0 fit_width z-20 shadow-bootomSticky">
              <div className="bg-white p-3 flex justify-between">
                <SecondaryButton
                  type="button"
                  name={addHospitalName.Cancel}
                  onClick={() => handelcancle()}
                />

                <div className="flex gap-3">
                  <PrimaryButton
                    loading={false}
                    name={addHospitalName.Submit}
                    // onClick={() => handelsubmits()}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </InnerLayout>
    </>
  );
}

export default EditDevice;
