import DoneIcon from "@mui/icons-material/Done";
import CheckedIcon from "../../assests/icons/checked.svg";
import HospitalInfoIcon from "../../assests/icons/hospital-info.svg";
import ProductActivationIcon from "../../assests/icons/product-activation.svg";
import ProductDisabledIcon from "../../assests/icons/product-disabled.svg";
import HospitalOnboardinIcon from "../../assests/icons/hospital-onboarding.svg";
import HospitalOnboardDisabledIcon from "../../assests/icons/hospital-onboarding-disabled.svg";

interface addHospitalProgressProps {
  active: number;
}

const AddHospitalProgressBar = ({ active }: addHospitalProgressProps) => {
  return (
    <div className="flex justify-around items-center mx-10">
      <div
        className={`${
          active === 1
            ? "text-primary font-bold"
            : active > 1
            ? "text-blue-300 font-semibold"
            : "text-secondary font-normal"
        } flex justify-center items-center gap-2 text-14`}
      >
        <div
          className={`border-blue-300 rounded-[50%]  flex items-center ${
            active <= 1 ? " w-[48px] h-[48px]" : " w-[40px] h-[40px]"
          }`}
        >
          {active <= 1 ? (
            <img src={HospitalInfoIcon} />
          ) : (
            <img src={CheckedIcon} />
          )}
        </div>
        <span className="md:block hidden">Hospital Information</span>
      </div>
      <div
        className={`w-[20%] border-b border-4 rounded m-auto ${
          active === 1
            ? "border-disabled"
            : active > 1
            ? "border-blue-350"
            : "border-disabled"
        }`}
      ></div>
      <div
        className={`${
          active === 2
            ? "text-primary font-bold"
            : active > 2
            ? "text-blue-300 font-semibold"
            : "text-secondary font-normal"
        } flex justify-center items-center gap-2 text-14 ps-2`}
      >
        <div
          className={`border-blue-300 rounded-[50%] flex items-center  ${
            active === 2 ? " w-[48px] h-[48px]" : " w-[40px] h-[40px]"
          }`}
        >
          {active === 2 ? (
            <img src={ProductActivationIcon} />
          ) : active > 2 ? (
            <img src={CheckedIcon} />
          ) : (
            <img src={ProductDisabledIcon} />
          )}
        </div>
        <span className="md:block hidden">Product Activation</span>
      </div>
      <div
        className={`w-[20%] border-b border-4 rounded m-auto ${
          active === 2
            ? "border-disabled"
            : active > 2
            ? "border-blue-350"
            : "border-disabled"
        }`}
      ></div>
      <div
        className={`${
          active === 3
            ? "text-primary font-bold"
            : active > 3
            ? "text-blue-300 font-semibold"
            : "text-secondary font-normal"
        } flex justify-center items-center gap-2 text-14 ps-2`}
      >
        <div
          className={`border-blue-300 rounded-[50%] flex items-center  ${
            active === 3 ? " w-[48px] h-[48px]" : " w-[40px] h-[40px]"
          }`}
        >
          {active === 3 ? (
            <img src={HospitalOnboardinIcon} />
          ) : active > 3 ? (
            <img src={CheckedIcon} />
          ) : (
            <img src={HospitalOnboardDisabledIcon} />
          )}
        </div>
        <span className="md:block hidden">Hospital Onboarding</span>
      </div>
    </div>
  );
};

export default AddHospitalProgressBar;
