import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../datasource/api";
import { loginDataType } from "../modals/IloginCard";

export const getLogin = createAsyncThunk(
  "login/getDetails",
  async (data: loginDataType | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post("Login/login", data);
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);
