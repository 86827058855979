import { useEffect, useRef, useState } from "react";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/buttons/buttons";
import DashboardCard from "../../../components/ui/dashboardCard";
import { useAppDispatch } from "../../../redux/store";
import {
  getHospitalsIdList,
  getHospitalsLinkedAccManager,
  getStatusWiseHospitalsLinkedAccManager,
  linkHospitalToAccManager,
} from "../../../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import { SearchIcons } from "../../../components/muiIconsImport";
import successToast from "../../../utils/successToast";
import errorToast from "../../../utils/errorToast";
import sortValues from "../../../constants/sortColumnValue";
import { resetLinkHospitalToAccManagerState } from "../../../redux/accountManager/accountManagerSlice";
import enums from "../../../constants/enums";
import CloseIcon from "@mui/icons-material/Close";
import { SubmitHandler, useForm } from "react-hook-form";
import SelectForm from "../../../components/forms/selectForm";
import {
  countriesData,
  districtsData,
  hobliData,
  statesData,
  taluksData,
} from "../../../jsonData/addHospitalsData";
import { InputForm } from "../../../components/forms/inputForm";
import AutoCompleteForm from "../../../components/forms/autoCompleteForm";
import { FilterAutoCompleteForm } from "../../../components/forms/filterAutoComplete";
import {
  CountryList,
  DistrictList,
  HobliList,
  StateList,
  TalukList,
} from "../../hospitalManagement/apicalls/locationList";
import { LoadingSpinner } from "../../../components/ui/loadinSpinner";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  userId?: string;
  setActiveTab?: any;
  setReload?: any;
};

const HospitalLinkModal = ({
  isVisible,
  onClose,
  userId,
  setActiveTab,
  setReload,
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const [hospitalIdLists, sethospitalIdLists] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    reset,
    unregister,
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (data) => {};

  const {
    country,
    countryId,
    district,
    districtId,
    hobli,
    state,
    stateId,
    taluk,
    talukId,
  } = watch();

  const { countryLists, countryError, countryLoading, fetchCountry } =
    CountryList();

  const { stateLists, stateError, stateLoading, fetchState } = StateList(
    countryId ?? 0
  );

  const { districtLists, districtError, districtLoading, fetchDistrict } =
    DistrictList(stateId ?? 0);

  const { talukLists, talukError, talukLoading, fetchTaluk } = TalukList(
    districtId ?? 0
  );

  const { hobliLists, hobliError, hobliLoading, fetchHobli } = HobliList(
    talukId ?? 0
  );

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    fetchState();
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  }, [countryId]);

  useEffect(() => {
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  }, [stateId]);

  useEffect(() => {
    fetchTaluk();
    fetchHobli();
  }, [districtId]);

  useEffect(() => {
    fetchHobli();
  }, [talukId]);

  const countryChange = () => {
    setValue("stateId", 0);
    setValue("districtId", 0);
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("state", "");
    setValue("district", "");
    setValue("taluk", "");
    setValue("hobli", "");
  };

  const stateChange = () => {
    setValue("districtId", 0);
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("district", "");
    setValue("taluk", "");
    setValue("hobli", "");
  };
  const districtChange = () => {
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("taluk", "");
    setValue("hobli", "");
  };

  const talukChange = () => {
    setValue("hobliId", 0);
    setValue("hobli", "");
  };

  useEffect(() => {
    dispatch(getHospitalsIdList());
  }, []);

  const getHospitalsIdListRes = useSelector(
    (state: any) => state.getHospitalsIdList
  );

  useEffect(() => {
    if (getHospitalsIdListRes.status === "success") {
      sethospitalIdLists(getHospitalsIdListRes.data.data);
    } else if (getHospitalsIdListRes.status === "failed") {
      console.error(getHospitalsIdListRes, "getHospitalsIdListRes");
    }
  }, [getHospitalsIdListRes]);

  const [filterValues, setFilterValues] = useState(hospitalIdLists);
  const [selectedHospitals, setSelectedHospitals] = useState<any>([]);
  useEffect(() => {
    setFilterValues(hospitalIdLists);
  }, [hospitalIdLists]);

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = hospitalIdLists?.filter((item: any) =>
      item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
    );

    if (filteredValue) {
      setFilterValues(filteredValue);
    } else {
      setFilterValues(hospitalIdLists);
    }
  };

  const handleCheckboxChange = (value: string) => {
    // show and hide column based on checkbox
    if (selectedHospitals?.includes(value)) {
      setSelectedHospitals(
        selectedHospitals.filter((item: any) => item !== value)
      );
    } else {
      setSelectedHospitals([...selectedHospitals, value]);
    }
  };

  useEffect(() => {
    const filteredValue = getHospitalsIdListRes.data.data?.filter(
      (item: any) => {
        if (country && country !== item.country) return false;
        if (state && state !== item.state) return false;
        if (district && district !== item.district) return false;
        if (taluk && taluk !== item.taluk) return false;
        if (hobli && hobli !== item.hobli) return false;
        return true;
      }
    );
    if (filteredValue) {
      sethospitalIdLists(filteredValue);
    } else {
      sethospitalIdLists(getHospitalsIdListRes.data.data);
    }
  }, [country, district, hobli, state, taluk]);

  const linkHospitalFn = () => {
    if (!selectedHospitals?.length) {
      errorToast("Please select Hospital to link with Account Manager");
      return;
    }
    if (userId) {
      dispatch(
        linkHospitalToAccManager({
          userId: parseInt(userId),
          hospitalId: selectedHospitals,
          isToBeLinked: 1,
        })
      );
    }
  };

  const linkHospitalToAccManagerRes = useSelector(
    (data: any) => data.linkHospitalToAccManager
  );

  useEffect(() => {
    if (linkHospitalToAccManagerRes.status === "success") {
      successToast("Hospital linked Successfully");
      setActiveTab("Hospital Linked");
      setReload((prev: boolean) => !prev);
      if (userId) {
        dispatch(
          getHospitalsLinkedAccManager({
            userId: parseInt(userId),
            order: "",
            orderBy: "",
            page: sortValues.page,
            rowsPerPage: sortValues.rowsPerPage,
            searchQuery: "",
            searchKeywords: "",
          })
        );
        dispatch(
          getStatusWiseHospitalsLinkedAccManager({
            userId: parseInt(userId),
            order: "",
            orderBy: "",
            page: sortValues.page,
            status: enums.active,
            rowsPerPage: sortValues.rowsPerPage,
            searchQuery: "",
            searchKeywords: "",
          })
        );
      }

      setSelectedHospitals([]);
      onClose();
    } else if (linkHospitalToAccManagerRes.status === "failed") {
      errorToast("Something Went Wrong");
    }
  }, [linkHospitalToAccManagerRes]);

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setSelectedHospitals([]);
    } else {
      const allHospitalIds = filterValues.map((item: any) => item.hospitalId);
      setSelectedHospitals(allHospitalIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleChange = () => {
    setSelectedHospitals([]);
    setSelectAllChecked(false);
  };

  useEffect(() => {
    return () => {
      dispatch(resetLinkHospitalToAccManagerState());
      setValue("stateId", 0);
      setValue("districtId", 0);
      setValue("talukId", 0);
      setValue("hobliId", 0);
    };
  }, []);

  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="p-[12px] flex flex-col relative">
        <div className="absolute top-1 right-1">
          <button type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="items-start">
          <span className="text-24 text-primary font-semibold mt-6 whitespace-nowrap">
            Link Hospital
          </span>
        </div>
        <hr className="my-4" />

        <form onSubmit={handleSubmit(onSubmit)} className="pt-5">
          <div className="grid lg:grid-cols-5">
            <div className="py-[10px] col-span-1">
              <span className="text-secondary text-14 font-medium ">
                Select Region:
              </span>
            </div>
            <div className="grid md:grid-cols-3 gap-6 col-span-4">
              <div className="lg:w-[250px] md:w-[200px]">
                <div className="hidden w-0 h-0 invisible">
                  <InputForm
                    label={"Country Id"}
                    id="countryId"
                    control={control}
                  />
                </div>

                <AutoCompleteForm
                  label={"Country*"}
                  id={"country"}
                  options={!countryLoading ? countryLists ?? [] : []}
                  control={control}
                  handleChange={(e: any, seletedOption: any) => {
                    setValue("countryId", seletedOption?.value);
                    countryChange();
                  }}
                />
              </div>
              <div className="hidden w-0 h-0 invisible">
                <InputForm label={"State Id"} id="stateId" control={control} />
              </div>

              {stateLoading ? (
                <>
                  <AutoCompleteForm
                    label={"State*"}
                    id={"dummystate"}
                    options={[]}
                    control={control}
                  />
                </>
              ) : (
                <AutoCompleteForm
                  label={"State*"}
                  id={"state"}
                  options={!stateLoading ? stateLists ?? [] : []}
                  control={control}
                  handleChange={(e: any, seletedOption: any) => {
                    setValue("stateId", seletedOption?.value);
                    stateChange();
                  }}
                />
              )}
              <div className="hidden w-0 h-0 invisible">
                <InputForm
                  label={"District Id"}
                  id="districtId"
                  control={control}
                />
              </div>

              {districtLoading ? (
                <>
                  <AutoCompleteForm
                    label={"District"}
                    id={"dummydistrict"}
                    options={[]}
                    control={control}
                  />
                </>
              ) : (
                <AutoCompleteForm
                  label={"District"}
                  id={"district"}
                  options={!districtLoading ? districtLists ?? [] : []}
                  control={control}
                  handleChange={(e: any, seletedOption: any) => {
                    setValue("districtId", seletedOption?.value);
                    districtChange();
                  }}
                />
              )}

              <div className="hidden w-0 h-0 invisible">
                <InputForm label={"Taluk Id"} id="talukId" control={control} />
              </div>

              {talukLoading ? (
                <>
                  <AutoCompleteForm
                    label={"Taluk"}
                    id={"dummytaluk"}
                    options={[]}
                    control={control}
                  />
                </>
              ) : (
                <AutoCompleteForm
                  label={"Taluk"}
                  id={"taluk"}
                  options={!talukLoading ? talukLists ?? [] : []}
                  control={control}
                  handleChange={(e: any, seletedOption: any) => {
                    setValue("talukId", seletedOption?.value);
                    talukChange();
                  }}
                />
              )}

              <div className="hidden w-0 h-0 invisible">
                <InputForm label={"Hobli Id"} id="hobliId" control={control} />
              </div>
              {hobliLoading ? (
                <>
                  <AutoCompleteForm
                    label={"Hobli"}
                    id={"dummyhobli"}
                    options={[]}
                    control={control}
                  />
                </>
              ) : (
                <AutoCompleteForm
                  label={"Hobli"}
                  id={"hobli"}
                  options={!hobliLoading ? hobliLists ?? [] : []}
                  control={control}
                  handleChange={(e: any, seletedOption: any) => {
                    setValue("hobliId", seletedOption?.value);
                  }}
                />
              )}
            </div>
          </div>
          <div className="grid lg:grid-cols-5 pt-10">
            <div className="py-[10px] col-span-1">
              <span className="text-secondary text-14 font-medium ">
                Select Hospitals:
              </span>
            </div>

            <div className="grid md:grid-cols-1 gap-6 col-span-4">
              <div className="w-[100%]">
                <div className={`mt-2 z-20 bg-white p-4 shadow-md rounded`}>
                  <div className="w-[30%] border border-gray-450 py-2 px-3 rounded mb-3 flex">
                    <input
                      className="rounded-none outline-none w-[100%] text-16 font-400 text-primary"
                      onChange={(e) => handleFilterSearch(e)}
                      placeholder="Search"
                    />
                    <span className="text-blue-300">
                      <SearchIcons />
                    </span>
                  </div>

                  <div className="h-[272px] overflow-y-auto scroll_bar_with_side">
                    {filterValues?.length >= 1 && (
                      <div className="p-3 flex gap-3 items-center">
                        <input
                          id="selectAll"
                          type="checkbox"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                          className={`checkbox-input`}
                        />
                        <label
                          htmlFor="selectAll"
                          className={`font-semibold text-14 cursor-pointer ${
                            selectAllChecked ? "text-primary" : "text-secondary"
                          }`}
                        >
                          Select All
                        </label>
                      </div>
                    )}

                    {filterValues?.map((item: any) => (
                      <div
                        className="p-3 flex gap-3 items-center"
                        key={item.hospitalId}
                      >
                        <input
                          type="checkbox"
                          checked={selectedHospitals?.includes(item.hospitalId)}
                          id={item.hospitalId}
                          name={item.hospitalId}
                          onChange={() => handleCheckboxChange(item.hospitalId)}
                          className="checkbox-input"
                        />
                        <label
                          htmlFor={item.hospitalId}
                          className={`font-semibold text-14 ${
                            selectedHospitals?.includes(item.hospitalId)
                              ? "text-primary"
                              : "text-secondary"
                          } cursor-pointer`}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="flex justify-end gap-7 mt-5">
          <SecondaryButton
            type="button"
            name="Cancel"
            onClick={() => {
              onClose();
              // reset();
              reset((formValues: any) => ({
                ...formValues,
                countryId: 0,
                country: "",
                stateId: 0,
                districtId: 0,
                talukId: 0,
                hobliId: 0,
              }));
              setSelectedHospitals([]);
              setSelectAllChecked(false);
            }}
          />
          <PrimaryButton
            type="button"
            name={
              linkHospitalToAccManagerRes.isLoading
                ? "Linking..."
                : "Link  Hospitals"
            }
            onClick={() => linkHospitalFn()}
          />
        </div>
      </div>
    </DashboardCard>
  );
};

export default HospitalLinkModal;
