import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { inputStyles } from "../mui-styles";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { autoCompleteTheme } from "../themeProvider";

const AutoCompleteForm = ({
  id,
  defaultValue,
  required = true,
  label,
  placeholder,
  options,
  control,
  customErrorMessage = `${label} is required`,
  selectValue = "label",
  handleChange = () => {},
}: any) => {
  return (
    <ThemeProvider theme={autoCompleteTheme}>
      <Box sx={{ width: "100%" }}>
        <Controller
          name={id}
          control={control}
          defaultValue={defaultValue || ""}
          rules={{
            required: required ? customErrorMessage : false,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              id={id}
              options={options}
              getOptionLabel={(option) => option?.[selectValue]}
              onChange={(e, newValue) => {
                onChange(newValue?.[selectValue] || "");
                handleChange(e, newValue);
              }}
              value={options.find(
                (option: any) => option?.[selectValue] === value
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={inputStyles.select}
                  id={id}
                  name={id}
                  label={label}
                  placeholder={placeholder}
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}
        />
      </Box>
    </ThemeProvider>
  );
};

export default AutoCompleteForm;
