import { useEffect, useState } from "react";
import { PasswordInput } from "../components/ui/dashboardForms";
import { useForm, SubmitHandler } from "react-hook-form";
import constants from "../constants";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store";

import { useSelector } from "react-redux";
import { LoadingSpinner } from "../components/ui/loadinSpinner";
import { ResetPasswordFormType } from "../modals/IresetPasswordCard";
import { resetPasswordState } from "../redux/forgotPassword/forgotPasswordSlice";
import UserAuditDetails from "../utils/userAuditDetails";
import DetectDevice from "../utils/detectDevice";
import InnerLayout from "../layout/innerLayout";
import { profilresetPassword } from "../redux/profile/profilereducer";
import { AccessTokenDecoder } from "../utils/accessTokenDecoder";
import { PrimaryButton, SecondaryButton } from "../components/buttons/buttons";
import errorToast from "../utils/errorToast";
import successToast from "../utils/successToast";
import { profilresetPasswordState } from "../redux/profile/profileSlice";

function ProfilePasswordReset({ location }: any) {
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profileresetPasswordData = useSelector(
    (data: any) => data.profilresetPassword
  );

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordFormType>();
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const errorString =
    "Atleast 8 Characters required which contain 1 Alphabet + 1 Numeric Digit + 1 Lower & Upper Case with a Special Character";
  const deviceType = DetectDevice();
  const userDetails = AccessTokenDecoder();
  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: userDetails?.userId,
    userName: userDetails?.userName,
    role: userDetails?.Roles,
    customer: userDetails?.name,
    customerCode: userDetails?.userName,
    customerID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: deviceType,
    auditDate: "2024-03-15T06:57:07.737Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };
  const onSubmit: SubmitHandler<ResetPasswordFormType> = async () => {
    const dispatchData = {
      email: (userDetails?.email).toLowerCase(),
      password: watch("password"),
      isTempPassword: false,
      userAuditInfo,
    };
    dispatch(profilresetPassword(dispatchData));
  };
  useEffect(() => {
    return () => {
      dispatch(profilresetPasswordState());
    };
  }, []);
  useEffect(() => {
    if (profileresetPasswordData?.status === "success") {
      successToast("Password updated successfully");
      navigate(-1);
    } else if (profileresetPasswordData?.status === "failed") {
      errorToast(`${profileresetPasswordData.error.data.message}`);
    }
  }, [profileresetPasswordData]);
  useEffect(() => {
    return () => {
      dispatch(resetPasswordState());
    };
  }, []);

  return (
    <InnerLayout title="Reset Password">
      <>
        <div className=" bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-5 dark:bg-gray-800 dark:border-gray-700 mx-6 mt-8">
          <span className="text-20 font-medium text-primary ">
            Password Change
          </span>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="pt-[30px] pb-[56px] px-6"
          id="my-form"
        >
          {/* after design dissuction */}

          <div className="mt-2 w-[360px]">
            <PasswordInput
              label={constants.newpasswordText}
              id="password"
              type="password"
              register={{
                ...register("password", {
                  required: constants.passwordRequired,
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[?!@#$%^&*()_-]).{8,15}$/,
                    message: errorString,
                  },
                  onChange: () => {
                    setErrorMessage("");
                    setError(false);
                  },
                }),
              }}
              errorMessage={errors?.password?.message}
              error={error || Boolean(errorMessage)}
            />
          </div>
          <div className="mt-8 w-[360px]">
            <PasswordInput
              label={constants.confirmNewPassword}
              id="confirmPassword"
              type="password"
              register={{
                ...register("confirmPassword", {
                  required: constants.confirmPasswordRequired,
                  validate: (match) => {
                    const password = getValues("password");
                    return match === password || "Passwords should match!";
                  },
                  onChange: () => {
                    setErrorMessage("");
                    setError(false);
                  },
                }),
              }}
              errorMessage={errors?.confirmPassword?.message}
              error={error || Boolean(errorMessage)}
            />
          </div>
          {/* <div className="text-center my-3">
                <span className="text-red">{errorMessage}</span>
              </div> */}

          {/* <div className="text-center">
                <span className="text-error text-12 font-medium">
                  {errors?.password?.message ??
                    errors?.confirmPassword?.message}
                </span>
              </div> */}

          {/* <div className="flex justify-center mt-6">
                <LoginButton
                  // disabled={!password(watch("password"))}
                  name={
                    profileresetPasswordData?.isLoading ? <LoadingSpinner /> : "Submit"
                  }
                  width="w-[100%]"
                />
              </div> */}
        </form>
        <div className="fixed bottom-0 fit_width z-20 shadow-bootomSticky bg-white">
          <div className="py-3 px-6 gap-6 flex justify-end">
            <SecondaryButton
              name="Cancel"
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            />

            <div className="flex gap-3">
              <PrimaryButton
                name={
                  profileresetPasswordData?.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    "Save"
                  )
                }
                loading={false}
                type="submit"
                form="my-form"
              />
            </div>
          </div>
        </div>
      </>
    </InnerLayout>
  );
}

export default ProfilePasswordReset;
