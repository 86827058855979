import { InputAdornment, TextField } from "@mui/material";
import { SearchIcons } from "../muiIconsImport";
import { inputStyles } from "../mui-styles";

const SearchInput = ({
  setAllSearch,
}: {
  setAllSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  let timer: ReturnType<typeof setTimeout>;

  const handleFilterSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setAllSearch(inputValue?.trim());
    }, 1000);
  };
  return (
    <>
      <TextField
        sx={inputStyles.select}
        fullWidth
        label={"Search"}
        onChange={(e) => handleFilterSearch(e)}
        placeholder={"Enter the value"}
        variant="outlined"
        className="my-2 bg-white"
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcons styles={{ color: "#534FA1" }} />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default SearchInput;
