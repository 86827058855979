import {
  Box,
  Modal,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useEffect, useRef, useState } from "react";
import uploadlogo from "../../assests/images/uploadexcel.svg";
import uploadexellogo from "../../assests/images/exel.svg";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import {
  getUnLinkDevices,
  postDevicesXls,
} from "../../redux/devices/deviceManagmentReducer";
import { useAppDispatch } from "../../redux/store";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import CloseIcon from "@mui/icons-material/Close";

import {
  AddCircledIcon,
  AttachmentIcon,
  MoreOptionIcon,
} from "../../components/muiIconsImport";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/forms/searchInput";
import DynamicTable from "../../components/tables/dynamicTable";
import sortValues from "../../constants/sortColumnValue";
import { TableFilterData } from "../../modals/Itables";
import useDisplayToggle from "../../utils/useDisplayToggle";
import { SearchQuery } from "../../utils/searchQuery";
import { useSelector } from "react-redux";
import errorToast from "../../utils/errorToast";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import successToast from "../../utils/successToast";
import { resetpostDevicesXlsState } from "../../redux/devices/deviceMangmentSlice";
import { sizeFormatter } from "../../utils/sizeformatter";
import { ActionTooltip } from "../../utils/toolTip";
import ConfirmationModal from "../../components/modals/confirmationModal";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import useAxios from "../../hooks/useAxios";
import routesName from "../../constants/routesName";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 445,
  height: 585,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
const mainHeader = [
  {
    label: "Action",
    value: "action",
    sort: false,
    search: false,
    width: "80px",
  },

  {
    label: "Model",
    value: "modelName",
    sort: true,
    search: true,
    width: "300px",
  },
  {
    label: "Serial Number",
    value: "serialNumber",
    sort: true,
    search: true,
    width: "340px",
  },
  {
    label: "MAC Address",
    value: "macAddress",
    sort: true,
    search: true,
    width: "340px",
  },
  {
    label: "IMEI Number",
    value: "imeiNumber",
    sort: true,
    search: true,
    width: "340px",
  },
];
const styles = {
  // borderLeft: "1px solid #F2F4F7",
  borderRight: "1px solid #F2F4F7",
  borderBottom: "1px solid #F2F4F7",
  background: "white",
  paddingLeft: "16px",
  paddingRight: "16px",
  fontsize: "14px",
  color: "#667085",
  fontWeight: 500,
  fontFamily: "Inter, sans-serif",
};
function UnLinkDevice() {
  const [isDragging, setIsDragging] = useState(false);
  const [modalopen, setmodalopen] = useState(false);
  const [xlfile, setFile] = useState<any>();
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
  const [filter, setFilter] = useState<TableFilterData>({});
  const [sortedData, setSortedData] = useState<any>([]);
  const [allSearch, setAllSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const userDetails = AccessTokenDecoder();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  const [isMoreOption, setisMoreOption] = useState<any>(false);
  const searchQuery = SearchQuery({ filter });
  const [isDeleteModal, setisDeleteModal] = useState<any>(false);
  const [deviceId, setDeviceId] = useState<any>();
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisMoreOption,
  });
  const dekletedeviceFn = () => {
    deleteFn();
  };
  const {
    data: devicedeletedata,
    error,
    loading,
    deleteFn,
  } = useAxios({
    method: "delete",
    path: `Devices/devices/delete/id?deviceid=${deviceId}`,
  });
  useEffect(() => {
    if (devicedeletedata?.success) {
      successToast("Devices deleted successfully");
      window.location.reload();
      setisDeleteModal(false);
    } else if (error) {
      errorToast("Something Went Wrong");
    }
  }, [devicedeletedata, error]);
  const toogleFn = (index: number) => {
    if (moreOption === index) {
      setisMoreOption(false);
      setMoreOption(null);
    } else {
      setMoreOption(index);
      setisMoreOption(true);
    }
  };
  const xlsupload = useSelector(
    (data: { postDevicesXlsreducer: any }) => data?.postDevicesXlsreducer
  );

  useEffect(() => {
    if (xlsupload?.status === "success") {
      successToast("Successfully added devices");
      setmodalopen(false);
      setFile(null);
      window.location.reload();
    } else if (xlsupload?.status === "failed") {
      errorToast(`${xlsupload?.error?.data?.error?.message}`);
    }
  }, [xlsupload]);

  useEffect(() => {
    return () => {
      dispatch(resetpostDevicesXlsState());
    };
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    dispatch(
      getUnLinkDevices({
        order: order,
        orderBy: orderBy,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
  }, [order, orderBy, page, rowsPerPage, allSearch, searchQuery]);
  const linkedDevicesRes = useSelector(
    (data: { getAllUnLinkDevicesreducer: any }) =>
      data?.getAllUnLinkDevicesreducer
  );

  useEffect(() => {
    if (linkedDevicesRes?.status === "success") {
      setSortedData(linkedDevicesRes?.data?.data);
    } else if (linkedDevicesRes?.status === "failed") {
      errorToast("Something went wrong");
      setErrorMessage("Something went wrong");
    }
  }, [linkedDevicesRes]);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      // const reader: FileReader = new FileReader();
      // reader.readAsArrayBuffer(file);
      // reader.onload = (e: any) => {
      //   const binarystr = new Uint8Array(e.target.result);
      //   const wb: XLSX.WorkBook = XLSX.read(binarystr, {
      //     type: "array",
      //     raw: true,
      //     cellFormula: false,
      //   });
      //   const wsname = wb.SheetNames[0];
      //   const data = XLSX.utils.sheet_to_json(wb.Sheets[wsname]);
      //   setFile(file);
      //   event.target.value = "";
      // };
      setFile(file);
      event.target.value = "";
    } else {
      event.target.value = "";
    }
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer;
    if (files) {
      setFile(files.files[0]);
    }
  };
  const submitDispatch = () => {
    if (xlfile) {
      if (
        xlfile.size <= 10000 * 1024 &&
        xlfile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const formData = new FormData();
        formData.append("file", xlfile);
        const dataxsls = { formData, userid: userDetails.userName };
        dispatch(postDevicesXls(dataxsls));
      } else {
        errorToast("Please add a file with proper format");
      }
    } else {
      errorToast("Please add a file");
    }
  };

  const editDeviceFn = (
    deviceId: number,
    imeiNumber: string,
    macAddress: string,
    modelName: string,
    status: string,
    serialNumber: string
  ) => {
    navigate(`${routesName.devicesPage}/editdevice`, {
      state: {
        deviceId,
        imeiNumber,
        macAddress,
        modelName,
        status: "Unlinked",
        serialNumber,
      },
    });
  };
  return (
    <div>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setisDeleteModal(false);
        }}
        onClick={() => {
          dekletedeviceFn();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Device?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={false}
      />
      <div className="flex max-lg:flex-col justify-between px-5 gap-4 pt-5  mb-5">
        <div className="flex max-lg:justify-end">
          <div style={{ maxWidth: "250px" }}>
            <SearchInput setAllSearch={setAllSearch} />
          </div>
        </div>
        <div className="flex max-lg:flex-col items-end gap-4 ">
          <SecondaryButton
            name="Upload Devices in Bulk"
            padding="py-3 px-6"
            icon={<AttachmentIcon />}
            type="button"
            onClick={() => {
              setmodalopen(true);
            }}
          />
          <PrimaryButton
            name="Add Device"
            padding="py-3 px-6"
            icon={<AddCircledIcon />}
            type="button"
            onClick={() => navigate(routesName.addDevice)}
          />
        </div>
      </div>
      <div className="h-[55vh]">
        <DynamicTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setorder={setorder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filter={filter}
          setFilter={setFilter}
          columnData={mainHeader}
          children={
            <TableBody>
              {sortedData?.map((device: any, ind: any) => (
                <TableRow key={ind}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      ...styles,
                      width: "80px",
                      minWidth: "80px",
                      maxWidth: "80px",
                      textAlign: "center",
                      height: "80px",
                      position: "relative",
                      // position: "sticky",
                      left: "0px",
                    }}
                  >
                    <ActionTooltip
                      content={
                        <div
                          className={`text-14  absolute left-8 z-30  ${
                            ind >= sortedData?.length - 5
                              ? "bottom-[-20px]"
                              : "top-[-20px]"
                          } left-5 flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                          ref={insideClickRef}
                        >
                          <button
                            type="button"
                            className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-lg"
                            onClick={() => {
                              editDeviceFn(
                                device?.deviceId,
                                device?.imeiNumber,
                                device?.macAddress,
                                device?.modelName,
                                device?.status,
                                device?.serialNumber
                              );
                            }}
                          >
                            Edit
                          </button>

                          <button
                            type="button"
                            className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-lg "
                            onClick={() => {
                              setisDeleteModal(true);
                              setDeviceId(device?.deviceId);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      }
                    >
                      <button
                        type="button"
                        onClick={() => toogleFn(ind)}
                        ref={onClickRef}
                        className="text-blue-300 w-[24px] h-[24px]"
                      >
                        <MoreOptionIcon />
                      </button>
                    </ActionTooltip>
                  </TableCell>

                  <TableCell style={styles}> {device?.modelName}</TableCell>
                  <TableCell style={styles}> {device?.serialNumber}</TableCell>
                  <TableCell style={styles}>{device?.macAddress}</TableCell>
                  <TableCell style={styles}>{device?.imeiNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          status={linkedDevicesRes?.isLoading}
          errorMessage={errorMessage}
          dataLength={linkedDevicesRes?.data?.count ?? 10}
        />
      </div>

      <Modal
        open={modalopen}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="content-center w-[632px]"
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Box sx={style} justifyContent="center" alignItems="center">
            <div className="flex justify-center">
              <span className="mb-6 text-24 font-[600] leading-9">
                Upload Devices in Bulk
              </span>
            </div>
            <div
              style={{ border: "1px dashed #A9AFB8", borderRadius: "8px" }}
              className="border-dashed border-2 border-gray-300 h-[321px]"
            >
              <Box justifyContent="center" alignItems="center">
                <div className="grid justify-items-center content-center pt-10">
                  <img src={uploadlogo} height={50} width={50} />
                  <span className="mb-2 text-20 font-[600] leading-7">
                    Drag files to upload
                  </span>
                  <span>or</span>
                  <input
                    id="logo_upload"
                    className="invisible w-0 h-0"
                    type="file"
                    accept=".xlsx"
                    onChange={handleFileChange}
                  />
                  <div className="flex flex-col gap-2 pt-3">
                    <label className="cursor-pointer" htmlFor="logo_upload">
                      <span className="bg-blue-300 text-white py-3 px-7 rounded-full flex items-center justify-center gap-2 hover:shadow-xl">
                        {false ? (
                          <span className="flex items-center gap-3 ">
                            <LoadingSpinner width="w-4" height="h-4" />{" "}
                            Loading...
                          </span>
                        ) : (
                          <span>Browse File</span>
                        )}
                      </span>
                    </label>
                    <span className="mt-4 text-14 text-gray-400 font-medium">
                      Max file size :
                      <span className="text-primary font-[600]"> 10MB </span>
                    </span>
                    <span className="text-14 text-gray-400 font-medium">
                      Supported file types:
                      <span className="text-primary font-[700]"> .xlsx </span>
                    </span>
                  </div>

                  <div className="flex gap-6 absolute bottom-5">
                    <SecondaryButton
                      padding="py-3 px-15"
                      type="button"
                      name={"Cancel"}
                      onClick={() => {
                        setmodalopen(false);
                        setFile(null);
                      }}
                    />
                    <PrimaryButton
                      padding="py-3 px-15"
                      loading={xlsupload.isLoading}
                      type="button"
                      name={"Upload"}
                      onClick={() => submitDispatch()}
                    />
                  </div>
                </div>
              </Box>
            </div>

            {xlfile && (
              <div
                style={{
                  border: "1px solid #534FA1",
                  borderRadius: "4px",
                  padding: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <div className="flex flex-col items-center">
                  <div className="flex items-center">
                    <img src={uploadexellogo} height="32px" width="32px" />
                    <div className="flex flex-col ps-2">
                      <span className="text-14 text-primary font-[600]">
                        {xlfile?.name || ""}
                      </span>
                      <span className="text-12 text-secondary font-medium">
                        {sizeFormatter(xlfile?.size)}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <button onClick={() => setFile(null)}>
                    <CloseIcon style={{ color: "#212121", margin: "5px" }} />
                  </button>
                </div>
              </div>
            )}
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default UnLinkDevice;
