import { Location, useLocation } from "react-router-dom";
import ResetPasswordCard from "../features/resetPasswordCard";
import "./pages.css";

const SetNewPassword = () => {
  const location: Location<any> = useLocation();
  return (
    <div className="login_page">
      <ResetPasswordCard location={location} />
    </div>
  );
};

export default SetNewPassword;
