import { useState } from "react";
import { DownloadIcons } from "../components/muiIconsImport";
import { LoadingSpinner } from "../components/ui/loadinSpinner";

export const DownloadLink = ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  const [loading, setLoading] = useState(false);
  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await fetch(url);
      setLoading(false);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName || "downloaded-file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      setLoading(false);
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div>
      <button type="button" onClick={handleDownload}>
        {loading ? (
          <LoadingSpinner width="w-4" height="h-4" />
        ) : (
          <DownloadIcons />
        )}
      </button>
    </div>
  );
};
