import SelectForm from "../../components/forms/selectForm";
import { InputForm } from "../../components/forms/inputForm";
import { useSelector } from "react-redux";
import { labelObject } from "../../helper";

const OnBoardSource = ({ control, setValue, previousData }: any) => {
  const enumData = useSelector((data: any) => data?.enumData?.data);
  const SourceTypeList = labelObject(enumData?.SourceType?.data);
  return (
    <div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
        <SelectForm
          label={"Source Type*"}
          id={"sourceType"}
          options={SourceTypeList}
          control={control}
          defaultValue={previousData?.sourceType}
        />
        <InputForm
          label={"Source Name*"}
          id="sourceName"
          control={control}
          defaultValue={previousData?.sourceName}
          maxLength={50}
        />
      </div>
    </div>
  );
};

export default OnBoardSource;
