import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import {
  getAccontManager,
  getHospitalsLinkedAccManager,
  getStatusWiseHospitalsLinkedAccManager,
} from "../../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import routesName from "../../constants/routesName";
import AccountManagerProfileCard from "../../features/accountManager/profile/profileCard";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import MainDoughnutChart from "../../components/charts/mainDoughtChart";
import DynamicTabs from "../../components/dynamicTabs";
import AccManagerAuthorites from "../../features/accountManager/profile/authorites";
import AccManagerHospitalLinked from "../../features/accountManager/profile/hospitalLinked";
import AccManagerAuditTrails from "../../features/accountManager/profile/auditTrails";
import { resetGetAccontManagerState } from "../../redux/accountManager/accountManagerSlice";
import { AccountManagerType } from "../../modals/IaccountManager";
import { ResponseType } from "../../modals/IreduxResponse";
import RoundedDynamicTabs from "../../components/roundedDynamicTabs";

const AccountManagerProfile = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(state?.audittab || "Authorities");
  const [accountManagerData, setAccountManagerData] =
    useState<AccountManagerType | null>();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch(getAccontManager(parseInt(params.id)));
    }
  }, [reload]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAccontManagerState());
    };
  }, []);

  const getAccontManagerRes = useSelector(
    (data: { getAccontManager: ResponseType }) => data.getAccontManager
  );

  useEffect(() => {
    if (getAccontManagerRes.status === "success") {
      setAccountManagerData(getAccontManagerRes.data.data);
    } else if (getAccontManagerRes.status === "failed") {
      console.error(getAccontManagerRes, "getAccontManagerRes");
    } else if (getAccontManagerRes.status === "pending") {
      setAccountManagerData(null);
    }
  }, [getAccontManagerRes]);

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getHospitalsLinkedAccManager({
          userId: parseInt(params?.id),
          order: "",
          orderBy: "",
          page: 1,
          rowsPerPage: 10,
          searchQuery: "",
          searchKeywords: "",
        })
      );
    }
  }, []);
  const getAllHospitalRes = useSelector(
    (data: { getHospitalsLinkedAccManager: ResponseType }) =>
      data?.getHospitalsLinkedAccManager
  );

  const [linkedHospitalCount, setLinkedHospitalCount] = useState<number>(0);

  useEffect(() => {
    if (getAllHospitalRes?.status === "success") {
      setLinkedHospitalCount(getAllHospitalRes?.data?.count);
    } else if (getAllHospitalRes?.status === "failed") {
    }
  }, [getAllHospitalRes]);

  const tabsData = [
    {
      name: "Authorities",
      component: (
        <AccManagerAuthorites
          authorities={accountManagerData?.rolePermission?.permissions}
        />
      ),
    },
    {
      name: `Hospital Linked`,
      subName: `(${linkedHospitalCount})`,
      component: (
        <AccManagerHospitalLinked
          setActiveTab={setActiveTab}
          setReload={setReload}
        />
      ),
    },
    { name: "Audit Trail", component: <AccManagerAuditTrails /> },
  ];

  const breadcrumbData = [
    {
      label: "Account Managers",
      navigate: `${routesName.accountManagerPage}`,
      highLight: false,
    },

    {
      label: accountManagerData?.name || "",
      highLight: true,
    },
  ];

  const { active, expired, inactive, inprogress } =
    accountManagerData?.hospitalStatusWiseCount || {};

  return (
    <>
      <InnerLayout title={accountManagerData?.name}>
        {getAccontManagerRes?.isLoading ? (
          <div className="flex h-[90vh] justify-center items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="px-6 py-5">
            <Breadcrumbs data={breadcrumbData} />

            <div className="lg:grid lg:grid-cols-5 gap-6 mt-6">
              <div className="col-span-3">
                <AccountManagerProfileCard
                  accountManagerData={accountManagerData}
                />
              </div>
              <div className="col-span-2 border shadow-sm1 bg-white border-gray-250 rounded-lg p-4 lg:mt-0 mt-5">
                <div className="">
                  <span className="txet-18 font-medium text-primary">
                    Hospitals
                  </span>
                </div>

                <div className="pt-3">
                  {accountManagerData?.hospitalStatusWiseCount ? (
                    <>
                      <MainDoughnutChart
                        width="w-[40%]"
                        chartData={{
                          Onboarded: active ?? 0,
                          "In Progress": inprogress ?? 0,
                          Expired: expired ?? 0,
                          Deactivated: inactive ?? 0,
                        }}
                        totalValue={accountManagerData?.hospitalTotalCount}
                        colors={["#F3A11E", "#764FA0", "#1FAC86", "#C0374B"]}
                      />
                    </>
                  ) : (
                    <div className="flex justify-center py-3">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <RoundedDynamicTabs
              tabs={tabsData}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        )}
      </InnerLayout>
    </>
  );
};

export default AccountManagerProfile;
