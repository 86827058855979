import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type ChartData = {
  [key: string]: number;
};

type PercentageDisplay = {
  labels: string[];
  values: number[];
  colors: string[];
};

const DoughnutChart = ({
  chartData,
  colors,
  width = "w-[100px]",
}: {
  chartData: ChartData;
  colors: string[];
  width?: string;
}) => {
  const labels = Object.keys(chartData);
  const values = Object.values(chartData);

  const PercentageDisplay = ({ labels, values, colors }: PercentageDisplay) => {
    const total = values.reduce((acc, curr) => acc + curr, 0);
    return (
      <div>
        {labels.map((label, index) => (
          <div key={index} className="whitespace-nowrap flex items-center">
            <span className="text-24" style={{ color: colors[index] }}>
              &#8226;
            </span>
            <span className="text-14 font-normal text-secondary">
              {label} :
            </span>{" "}
            <span className="text-14 font-semibold text-primary">
              {values[index]}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const totalValue = values.reduce((acc: any, curr) => acc + curr, 0);

  const data = {
    // maintainAspectRatio: false,
    labels: labels,
    datasets: [
      {
        label: "value",
        data: values,
        backgroundColor: colors,
        // borderWidth: 0,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      arc: {
        spacing: 0.5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="flex items-center gap-1">
      <div className="flex flex-col">
        <PercentageDisplay labels={labels} values={values} colors={colors} />
      </div>
      <div className={`${width}`}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default DoughnutChart;
