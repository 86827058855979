import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routesName from "../../constants/routesName";
import enums from "../../constants/enums";

const AccountManagerEditTab = ({ id }: { id: number }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("personal_info");

  useEffect(() => {
    if (location.pathname.includes("personal-info")) {
      setActiveTab("personal_info");
    } else if (location.pathname.includes("roles-authorities")) {
      setActiveTab("roles_authorities");
    }
  }, [location.pathname]);


  const handleClick = (name: string) => {
    setActiveTab(name);
    if (name === "personal_info") {
      navigate(
        `${routesName.accountManagerPage}${routesName.personalInfoPage}/${enums.edit}`,
        { state: { id } }
      );
    } else if (name === "roles_authorities") {
      navigate(
        `${routesName.accountManagerPage}${routesName.rolesAuthoritiesPage}/${enums.edit}`,
        { state: { id } }
      );
    }
  };

  return (
    <div className="flex gap-4 bg-gray-50 mt-5 rounded-t">
      <button
        type="button"
        onClick={() => handleClick("personal_info")}
        className={`py-3.5 px-4 text-16 ${
          activeTab === "personal_info"
            ? "text-primary font-semibold border-b-2 border-blue-350"
            : "text-gray-400 font-medium"
        }`}
      >
        Personal Information
      </button>
      <button
        type="button"
        onClick={() => handleClick("roles_authorities")}
        className={`py-3.5 px-4 text-16 ${
          activeTab === "roles_authorities"
            ? "text-primary font-semibold border-b-2 border-blue-350"
            : "text-gray-400 font-medium"
        }`}
      >
        Roles and Authorities
      </button>
    </div>
  );
};

export default AccountManagerEditTab;
