import ForgotPasswordCard from "../features/forgotPasswordCard";

const ForgotPassword = () => {
  return (
    <div className="login_page">
      <ForgotPasswordCard />
    </div>
  );
};

export default ForgotPassword;
