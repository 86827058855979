import { DeleteIcons } from "../muiIconsImport";
import { LoadingSpinner } from "../ui/loadinSpinner";

interface ButtonType {
  onClick?: () => void;
  name?: string | JSX.Element;
  type?: "submit" | "reset" | "button" | undefined;
  width?: string;
  fontSize?: string;
  px?: string;
  py?: string;
  bgcolor?: string;
  bordercolor?: string;
  color?: string;
  borderRadius?: string;
  loading?: boolean;
}
export function Button({
  onClick = () => {},
  name = "Ok",
  type = "submit",
  width = "",
  fontSize = "",
  px = "px-4",
  py = "py-2",
  bgcolor = "bg-black",
  bordercolor = "black",
  color = "white",
  borderRadius = "md",
  loading = false,
}: ButtonType) {
  return (
    <button
      className={`${bgcolor} border-${bordercolor} text-${color} border-2 font-medium ${px} ${py} rounded-${borderRadius} w-[${width}] ${fontSize} flex justify-center  `}
      type={type}
      onClick={onClick}
    >
      {loading ? <LoadingSpinner width="w-6" height="h-6" /> : name}
    </button>
  );
}

type PrimaryButton = {
  name?: string | JSX.Element;
  icon?: JSX.Element;
  onClick?: () => void;
  type?: "submit" | "button" | "reset";
  padding?: string;
  loading?: boolean;
  fontSize?: string;
  disabled?: boolean;
  form?: any;
};

export const PrimaryButton = ({
  name,
  icon,
  onClick,
  type = "submit",
  padding = "py-3 px-18",
  loading = false,
  fontSize = "text-16",
  disabled = false,
  form,
}: PrimaryButton) => {
  return (
    <button
      className={`bg-blue-300 whitespace-nowrap hover:shadow-xl text-white ${fontSize} font-semibold  ${padding} rounded-full flex items-center gap-2`}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      form={form}
    >
      {loading ? (
        <>
          <LoadingSpinner width="w-4" height="h-4" /> Loading...
        </>
      ) : (
        <span className="flex gap-2 items-center">
          {icon && <span>{icon}</span>}
          {name}
        </span>
      )}
    </button>
  );
};

export const SecondaryButton = ({
  name,
  icon,
  onClick,
  type = "submit",
  padding = "py-3 px-18",
  loading = false,
  fontSize = "text-16",
  disabled = false,
}: PrimaryButton) => {
  return (
    <button
      className={`border-blue-300 border-2 hover:shadow-xl ${fontSize} font-semibold text-blue-300  ${padding} rounded-full flex items-center gap-2`}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
    >
      {loading ? (
        <>
          <LoadingSpinner width="w-4" height="h-4" /> Loading...
        </>
      ) : (
        <span className="flex gap-2 items-center text-secondary">
          {icon && <span>{icon}</span>}
          {name}
        </span>
      )}
    </button>
  );
};
export const ErrorWithDeleteButton = ({
  name,
  icon,
  onClick,
  padding = "py-3 px-6",
  type = "submit",
  disabled = false,
  loading = false,
}: PrimaryButton) => {
  return (
    <button
      className={`border-red-300 border-2 ${padding} text-16 font-semibold text-red-300 py-1 rounded-full flex items-center`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {loading ? (
        <>
          <LoadingSpinner width="w-4" height="h-4" /> Loading...
        </>
      ) : (
        <>
          <DeleteIcons styles={{ color: "#D33C52" }} />
          &nbsp;{name}
        </>
      )}
    </button>
  );
};

export const ButtonWithIcon = ({
  onClick,
  type = "button",
  icon,
}: PrimaryButton) => {
  const color = "#C0C2C6";
  return (
    <button
      className={`border hover:shadow-xl border-${color}-300 px-4 py-4 rounded-md flex items-center`}
      type={type}
      onClick={onClick}
    >
      <span>{icon}</span>
    </button>
  );
};
