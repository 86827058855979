interface childrenProps {
  children: React.ReactNode;
  title?: string;
  customButton?: React.ReactNode;
}

const InnerLayout = ({ children, title, customButton }: childrenProps) => {
  return (
    <div className="">
      <div className="h-[80px] sticky top-0 bg-blue-50 flex justify-between items-center z-20">
        <span className="ps-7 text-24 font-medium text-primary ">{title}</span>
        {customButton}
      </div>
      {children}
    </div>
  );
};

export default InnerLayout;
