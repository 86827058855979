import { FormControl, InputLabel, MenuItem, Select, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { selectStyles } from "../mui-styles";
import { useRef, useState } from "react";
import "./forms.css";
import { ThemeProvider } from "@mui/material/styles";
import { selectTheme } from "../themeProvider";

interface SelectOptionsType {
  label: string;
  value: number | string;
}

interface SelectFormProps {
  label: string;
  id: string;
  options: SelectOptionsType[];
  control: any;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: any;
  defaultValue?: any;
}

const SelectForm = ({
  label,
  id,
  options,
  control,
  placeholder = `Select ${label}`,
  disabled = false,
  required = true,
  onChange = () => {},
  defaultValue,
}: SelectFormProps) => {
  const inputLabelRef = useRef<HTMLLabelElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  return (
    <ThemeProvider theme={selectTheme}>
      <Box sx={{ maxWidth: "100%" }}>
        <Controller
          name={id}
          control={control}
          rules={{
            required: required ? `Please select a ${label}` : false,
          }}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <FormControl fullWidth error={Boolean(fieldState.error?.message)}>
                <InputLabel
                  id={`${id}-label`}
                  style={{
                    color: isFocused
                      ? "#322F61"
                      : fieldState?.error?.message
                      ? "#D33C52"
                      : "#757B84",
                    fontSize: "16px",
                    fontFamily: "Inter",
                  }}
                  ref={inputLabelRef}
                >
                  {label}
                </InputLabel>
                <Select
                  labelId={`${id}-label`}
                  id={id}
                  value={field?.value || ""}
                  label={label}
                  disabled={disabled}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    onChange(e);
                  }}
                  sx={selectStyles}
                >
                  <MenuItem value="" disabled>
                    {placeholder}
                  </MenuItem>
                  {options?.map((option: SelectOptionsType, index) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className={`${index === hoveredIndex ? "hovered" : ""} ${
                        option.value === field.value ? "backgroundColor" : ""
                      }`}
                      style={{ fontFamily: "Inter, sans-serif" }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {fieldState?.invalid && (
                <div className="text-error text-12 pt-1">
                  <span className="ps-4">{fieldState?.error?.message}</span>
                </div>
              )}
            </>
          )}
        />
      </Box>
    </ThemeProvider>
  );
};

export default SelectForm;
