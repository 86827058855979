import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../redux/store";
import { getAllLinkDevices } from "../../redux/devices/deviceManagmentReducer";
import { TableFilterData } from "../../modals/Itables";
import sortValues from "../../constants/sortColumnValue";
import { SearchQuery } from "../../utils/searchQuery";
import { resetgetAllLinkDevicesState } from "../../redux/devices/deviceMangmentSlice";
import { useSelector } from "react-redux";
import { ResponseType } from "../../modals/IreduxResponse";
import errorToast from "../../utils/errorToast";
import useDisplayToggle from "../../utils/useDisplayToggle";
import {
  Collapse,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  AddCircleOutlineIcons,
  AddCircledIcon,
  MoreOptionIcon,
  RemoveCircleOutlineIcons,
} from "../../components/muiIconsImport";
import DefaultImage from "../../assests/images/defaultImage.svg";
import NormalTable from "../../components/tables/normalTable";
import DynamicTable from "../../components/tables/dynamicTable";
import { linkedDevicesDataFormatter } from "../../features/devices/dataFormator";
import { removeInstalment, removeUnderScore } from "../../helper";
import enums from "../../constants/enums";
import StatusWithColor from "../../utils/StatusWithColor";
import SearchInput from "../../components/forms/searchInput";
import { PrimaryButton } from "../../components/buttons/buttons";
import { useNavigate } from "react-router-dom";
import routesName from "../../constants/routesName";
import { formatIndianRupee } from "../../utils/formatIndianRupee";
import { ActionTooltip } from "../../utils/toolTip";

const LinkDevices = () => {
  const styles = {
    // borderLeft: "1px solid #F2F4F7",
    borderRight: "1px solid #F2F4F7",
    borderBottom: "1px solid #F2F4F7",
    background: "white",
    minWidth: "120px",
    maxWidth: "120px",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontsize: "14px",
    color: "#667085",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [filter, setFilter] = useState<TableFilterData>({});
  const [allSearch, setAllSearch] = useState("");
  const [currentDevicesData, setCurrentDevicesData] = useState<any>();
  const searchQuery = SearchQuery({ filter });

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    dispatch(
      getAllLinkDevices({
        order: order,
        orderBy: orderBy,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
    return () => {
      dispatch(resetgetAllLinkDevicesState());
    };
  }, [order, orderBy, page, rowsPerPage, allSearch, searchQuery]);

  useEffect(() => {
    return () => {
      dispatch(resetgetAllLinkDevicesState());
    };
  }, []);

  const linkedDevicesRes = useSelector(
    (data: { getAllLinkDevices: ResponseType }) => data?.getAllLinkDevices
  );

  useEffect(() => {
    if (linkedDevicesRes?.status === "success") {
      setCurrentDevicesData(linkedDevicesRes?.data?.data);
    } else if (linkedDevicesRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [linkedDevicesRes]);

  const devicesData = linkedDevicesDataFormatter(currentDevicesData);

  const DeviceTable = (props: any) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
    const [isMoreOption, setisMoreOption] = useState<any>(false);
    const onClickRef = useRef(null);
    const insideClickRef = useRef(null);
    useDisplayToggle({
      onClickRef,
      insideClickRef,
      setDisplay: setisMoreOption,
    });

    useEffect(() => {
      if (isMoreOption === false) {
        setMoreOption(null);
      }
    }, [isMoreOption]);

    const toogleFn = (index: number) => {
      if (moreOption === index) {
        setisMoreOption(false);
        setMoreOption(null);
      } else {
        setMoreOption(index);
        setisMoreOption(true);
      }
    };

    const editDeviceFn = (
      deviceId: number,
      imeiNumber: string,
      macAddress: string,
      modelName: string,
      status: string,
      serialNumber: string
    ) => {
      navigate(`${routesName.devicesPage}/editdevice`, {
        state: {
          deviceId,
          imeiNumber,
          macAddress,
          modelName,
          status: "Linked",
          serialNumber,
          islinked: true,
        },
      });
    };

    const unLinkDeviceFn = (hospitalId: number) => {
      navigate(
        `${routesName.hospitalManagementPage}/edit-product-plan/${hospitalId}`,
        { state: "linkedDevice-edit" }
      );
    };

    const [sortedData, setSortedData] = useState<any>(row?.devices);
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            style={{
              ...styles,
              width: "80px",
              minWidth: "80px",
              maxWidth: "80px",
              textAlign: "center",
              height: "80px",
              position: "relative",
              left: "0px",
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <span className="text-blue-300">
                {open ? (
                  <RemoveCircleOutlineIcons />
                ) : (
                  <AddCircleOutlineIcons />
                )}
              </span>
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            style={{
              ...styles,
              minWidth: "250px",
              maxWidth: "250px",
            }}
          >
            <div className={`grid grid-cols-5 items-center`}>
              <div className="col-span-1 ">
                <img
                  src={row?.logoURL ? row?.logoURL : DefaultImage}
                  className="rounded-full w-[36px] h-[36px] border"
                  alt="hospital-logo"
                />
              </div>

              <div className="col-span-4 ps-2">
                <div>
                  <span className="text-blue-300 text-16 font-semibold">
                    {row?.hospitalName}
                  </span>
                </div>

                <span className="text-secondary text-14 font-medium">
                  ID: {row?.code}
                </span>
              </div>
            </div>
          </TableCell>
          <TableCell
            style={{
              ...styles,
              minWidth: "150px",
              maxWidth: "150px",
            }}
          >
            <StatusWithColor
              value={
                row?.status === enums.inactive
                  ? enums?.deactivated
                  : row?.status
              }
              subValue={row?.productStatus}
            />
          </TableCell>
          <TableCell
            style={{
              ...styles,
              minWidth: "160px",
              maxWidth: "160px",
            }}
          >
            {row?.location}
          </TableCell>

          <TableCell
            style={{ ...styles, minWidth: "180px", maxWidth: "180px" }}
          >
            <div className="flex flex-col">
              <span className="text-secondary">
                Total :{" "}
                <span className="text-primary">{row?.devicesCount?.total}</span>
              </span>
              <span>
                <span className="text-secondary">
                  Package :{" "}
                  <span className="text-primary">
                    {row?.devicesCount?.package}
                  </span>
                </span>{" "}
                |{" "}
                <span className="text-secondary">
                  Paid :{" "}
                  <span className="text-primary">
                    {row?.devicesCount?.paid}
                  </span>
                </span>
              </span>
            </div>
          </TableCell>
          <TableCell style={styles}>
            {formatIndianRupee(row?.deviceCost)}
          </TableCell>
          <TableCell style={styles}>
            {removeInstalment(row?.instalment)}
          </TableCell>
          <TableCell style={styles}>
            {formatIndianRupee(row?.securityDeposit)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              padding: 0,
              border: "none",
            }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <NormalTable
                columnData={mainHeader}
                setSortedData={setSortedData}
                data={row?.devices}
                // searchValue={searchInput}
                columnsName={[
                  "deviceType",
                  "imeiNumber",
                  "MACAddress",
                  "modelName",
                  "serialNumber",
                ]}
                height={sortedData.length >= 1 ? "" : "h-[200px]"}
                errorMessage={
                  sortedData.length < 1 ? "No devices are linked" : ""
                }
              >
                <TableBody>
                  {sortedData?.map((device: any, ind: any) => (
                    <TableRow key={ind}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          ...styles,
                          width: "80px",
                          minWidth: "80px",
                          maxWidth: "80px",
                          textAlign: "center",
                          height: "80px",
                          position: "relative",
                          // position: "sticky",
                          left: "0px",
                          background: "#F9FAFB",
                        }}
                      >
                        <ActionTooltip
                          content={
                            <div
                              className={`text-14  absolute z-30  ${
                                ind >= row?.devices?.length - 5
                                  ? "bottom-[-20px]"
                                  : "top-[0px]"
                              } left-7 flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                              ref={insideClickRef}
                            >
                              <button
                                type="button"
                                className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                                onClick={() => {
                                  editDeviceFn(
                                    device?.deviceId,
                                    device?.imeiNumber,
                                    device?.macAddress,
                                    device?.modelName,
                                    device?.status,
                                    device?.serialNumber
                                  );
                                }}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg"
                                onClick={() => {
                                  unLinkDeviceFn(device?.hospitalIdmain);
                                }}
                              >
                                Unlink
                              </button>
                            </div>
                          }
                        >
                          <button
                            type="button"
                            onClick={() => toogleFn(ind)}
                            ref={onClickRef}
                            className="text-blue-300 w-[24px] h-[24px]"
                          >
                            <MoreOptionIcon />
                          </button>
                        </ActionTooltip>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ ...styles, background: "#F9FAFB" }}
                      >
                        {device?.deviceType}
                      </TableCell>
                      <TableCell style={{ ...styles, background: "#F9FAFB" }}>
                        {device?.modelName}
                      </TableCell>
                      <TableCell style={{ ...styles, background: "#F9FAFB" }}>
                        {device?.serialNumber}
                      </TableCell>
                      <TableCell style={{ ...styles, background: "#F9FAFB" }}>
                        {device?.macAddress}
                      </TableCell>
                      <TableCell style={{ ...styles, background: "#F9FAFB" }}>
                        {device?.imeiNumber}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </NormalTable>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div className="">
      <div className="flex max-lg:flex-col justify-between px-5 gap-4 py-5">
        <div className="flex max-lg:justify-end">
          <div style={{ maxWidth: "250px" }}>
            <SearchInput setAllSearch={setAllSearch} />
          </div>
        </div>
        <div className="flex max-lg:flex-col items-end gap-4">
          <PrimaryButton
            name="Add Device"
            padding="py-3 px-6"
            icon={<AddCircledIcon />}
            type="button"
            onClick={() => navigate(routesName.addDevice)}
          />
        </div>
      </div>
      <div className="h-[55vh]">
        <DynamicTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          columnData={subHeader}
          order={order}
          setorder={setorder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filter={filter}
          setFilter={setFilter}
          children={
            <TableBody>
              {devicesData?.map((item: any, index: any) => (
                <DeviceTable key={index} row={item} />
              ))}
            </TableBody>
          }
          status={linkedDevicesRes?.isLoading}
          dataLength={linkedDevicesRes?.data?.count ?? 10}
          stickyWidth={[{ width: "0px" }]}
        />
      </div>
    </div>
  );
};

export default LinkDevices;

const subHeader = [
  {
    label: "Action",
    value: "action",
    sort: false,
    search: false,
    width: "80px",
  },
  {
    label: "Hospital",
    value: "name",
    sort: true,
    search: true,
    width: "250px",
  },
  {
    label: "Status",
    value: "status",
    sort: true,
    search: false,
    width: "150px",
  },
  {
    label: "Location",
    value: "city",
    sort: true,
    search: true,
    width: "160px",
  },
  {
    label: "Devices",
    value: "devices",
    sort: false,
    search: false,
    width: "180px",
  },
  {
    label: "Device Cost",
    value: "device_cost",
    sort: false,
    search: false,
    width: "120px",
  },
  {
    label: "Instalments",
    value: "instalments",
    sort: false,
    search: false,
    width: "120px",
  },
  {
    label: "Security Deposit",
    value: "security_deposits",
    sort: false,
    search: false,
    width: "160px",
  },
];

const mainHeader = [
  {
    label: "Action",
    value: "action",
    sort: false,
    search: false,
  },
  {
    label: "Device Type",
    value: "deviceType",
    sort: true,
    search: true,
  },
  {
    label: "Model",
    value: "modelName",
    sort: true,
    search: true,
  },
  {
    label: "Serial Number",
    value: "serialNumber",
    sort: true,
    search: true,
  },
  {
    label: "MAC Address",
    value: "macAddress",
    sort: true,
    search: true,
  },
  {
    label: "IMEI Number",
    value: "imeiNumber",
    sort: true,
    search: true,
  },
];
