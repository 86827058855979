import { Controller } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { inputStyles } from "../mui-styles";
import { CalendarIconOutlined } from "../muiIconsImport";
import { Box } from "@mui/material";

interface InputProps {
  label: string;
  id: string;
  control: any;
  defaultValue?: any;
  disabled?: boolean;
  required?: boolean;
  width?: string;
  minDate?: any;
}

const DataPickForm = ({
  label,
  id,
  control,
  defaultValue,
  disabled = false,
  required = true,
  width = "w-[100%]",
  minDate = null,
}: InputProps) => {
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || null}
        rules={{
          required: required ? "Please select the date" : false,
        }}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                name={id}
                label={label}
                className={width}
                value={field.value ? moment(field.value) : null}
                onChange={(newValue: moment.Moment | null) => {
                  field.onChange(
                    newValue ? moment(newValue).format("YYYY-MM-DD") : null
                  );
                }}
                disabled={disabled}
                sx={inputStyles.select}
                minDate={moment(minDate ?? null)}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: CalendarIconOutlined,
                }}
              />
            </DemoContainer>
            {error && (
              <div>
                <span className="text-12 text-error font-medium">
                  {error.message}
                </span>
              </div>
            )}
          </LocalizationProvider>
        )}
      />
    </Box>
  );
};

export default DataPickForm;
