import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { inputStyles } from "../mui-styles";
import { VisibilityIcon, VisibilityOffIcon } from "../muiIconsImport";

interface InputProps {
  label: string;
  id: string;
  type?: string;
  control: any;
  errorMessage?: string | undefined;
  placeholder?: string;
  defaultValue?: string | number | undefined;
  disabled?: boolean;
  prefix?: boolean;
  prefixText?: string;
  required?: boolean;
  customErrorMessage?: string;
  applyNumberValidation?: boolean;
  maxLength?: number;
}

export const InputForm: React.FC<InputProps> = ({
  label,
  id,
  type = "text",
  control,
  errorMessage,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
  prefix = false,
  prefixText,
  required = true,
  customErrorMessage = `${label} is required`,
  applyNumberValidation = false, // Default value is false
  maxLength = 200,
}) => {
  const patternRule = applyNumberValidation
    ? {
        pattern: {
          value: /^\d+$/,
          message: "Please enter a valid number.",
        },
      }
    : {};

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      ((e.target as HTMLInputElement).value.length >= maxLength ||
        e.key === "e" ||
        e.key === "E") &&
      type === "number"
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || ""}
        rules={{
          required: required ? customErrorMessage : false,
          ...patternRule,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            sx={inputStyles.select}
            fullWidth
            id={id}
            label={label}
            {...field}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            type={type}
            placeholder={placeholder}
            onKeyPress={handleKeyPress}
            disabled={disabled}
            error={Boolean(error?.message) || Boolean(errorMessage)}
            helperText={error?.message}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            InputProps={{
              startAdornment: prefix && (
                <InputAdornment position="start">{prefixText}</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: maxLength,
            }}
          />
        )}
      />
    </>
  );
};

export const PhoneNumInputForm: React.FC<InputProps> = ({
  label,
  id,
  type = "text",
  control,
  errorMessage,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
  prefix = true,
  prefixText = "+91 |",
  required = true,
  customErrorMessage = `${label} is required`,
}) => {
  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || ""}
        rules={{
          required: required ? customErrorMessage : false,
          pattern: {
            value: /^\d{10}$/,
            message: "Please enter a valid 10-digit mobile number",
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            sx={inputStyles.select}
            fullWidth
            id={id}
            label={label}
            {...field}
            value={field.value}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, "");
              const newValue = numericValue.slice(0, 10);
              field.onChange(newValue);
            }}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            error={Boolean(error?.message) || Boolean(errorMessage)}
            helperText={error?.message}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            InputProps={{
              startAdornment: prefix && (
                <InputAdornment position="start">{prefixText}</InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        )}
      />
    </>
  );
};

export const EmailInputForm: React.FC<InputProps> = ({
  label,
  id,
  type = "text",
  control,
  errorMessage,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
  required = true,
  customErrorMessage = `${label} is required`,
  maxLength = 200,
}) => {
  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || ""}
        rules={{
          required: required ? customErrorMessage : false,
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Please enter a valid email address.",
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            sx={inputStyles.select}
            fullWidth
            id={id}
            label={label}
            {...field}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            error={Boolean(error?.message) || Boolean(errorMessage)}
            helperText={error?.message}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            inputProps={{
              maxLength: maxLength,
            }}
          />
        )}
      />
    </>
  );
};

export const AmountInputForm: React.FC<InputProps> = ({
  label,
  id,
  type = "number",
  control,
  errorMessage,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
  prefix = true,
  prefixText = "₹ ",
  required = true,
  customErrorMessage = `${label} is required`,
  maxLength = 200,
}) => {
  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || ""}
        rules={{
          required: required ? customErrorMessage : false,
          pattern: {
            value: required ? /^(?!0(\.0+)?$)\d+(\.\d+)?$/ : /^\d+$/,
            message: "Please enter a valid number.",
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            sx={inputStyles.select}
            fullWidth
            id={id}
            label={label}
            {...field}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            error={Boolean(error?.message) || Boolean(errorMessage)}
            helperText={error?.message}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            InputProps={{
              startAdornment: prefix && (
                <InputAdornment position="start">{prefixText}</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: maxLength,
            }}
          />
        )}
      />
    </>
  );
};

export const PasswordInput: React.FC<InputProps> = ({
  label,
  id,
  type = "password",
  control,
  errorMessage,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || ""}
        rules={{
          required: `${label} is required`,
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <FormControl
              fullWidth
              variant="outlined"
              error={Boolean(error?.message)}
            >
              <InputLabel htmlFor={id}>{label}</InputLabel>
              <OutlinedInput
                id={id}
                {...field}
                label={label}
                type={showPassword ? "password" : "text"}
                placeholder={placeholder}
                disabled={disabled}
                error={Boolean(error?.message) || Boolean(errorMessage)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      onMouseUp={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                      sx={{ color: "#534FA1" }}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                autoComplete="off"
              />
            </FormControl>
            {error?.message &&
              error?.message !== "password regex should match" && (
                <div className="text-red">
                  <span className="text-sm ps-2">{error?.message}</span>
                </div>
              )}
          </>
        )}
      />
    </>
  );
};
