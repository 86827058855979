import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../redux/store";
import { getAllDepartmentsList } from "../../redux/mis/misReducer";
import { SearchQuery } from "../../utils/searchQuery";
import sortValues from "../../constants/sortColumnValue";
import { TableFilterData } from "../../modals/Itables";
import { useSelector } from "react-redux";
import errorToast from "../../utils/errorToast";
import InnerLayout from "../../layout/innerLayout";
import { removeUnderScore } from "../../helper";
import WithInHospitalsDepartments from "../../features/mis/departmentAndDiagnosis/withInHospitals";
import AcrossHospitalsDepartments from "../../features/mis/departmentAndDiagnosis/acrossHospitals";
import {
  DownArrow,
  FilterListIcons,
  UpArrow,
} from "../../components/muiIconsImport";
import useDisplayToggle from "../../utils/useDisplayToggle";
import MisDepartmentDiagnosisFilter from "../../features/mis/misDepartmentDiagnosisTableFilter";
import moment, { Moment } from "moment";
import Breadcrumbs from "../../components/breadCrumbs";
import { departmentsTableBreadcrumData } from "../../jsonData/breadcrumbData/mis";

const MISDepartments = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [filter, setFilter] = useState<TableFilterData>({});
  const [allSearch, setAllSearch] = useState("");
  const searchQuery = SearchQuery({ filter });
  const [departmentLists, setDepartmentLists] = useState<any>({});
  const [acrossWithin, setAcrossWithin] = useState<
    "within_hospitals" | "across_hospitals"
  >("within_hospitals");
  const [acrossWithinShow, setAcrossWithinShow] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [taluk, setTaluk] = useState("");
  const [hobli, setHobli] = useState("");
  const currentDate = new Date();
  // const currentYearStart = moment(currentDate).startOf("year");
  // const currentYearEnd = moment(currentDate).endOf("year");
  const currentYearStart = moment(currentDate)
    .subtract(11, "month")
    .subtract(1, "day");
  const currentYearEnd = moment(currentDate);

  const [fromDate, setFromDate] = useState<Moment | null>(currentYearStart);
  const [toDate, setToDate] = useState<Moment | null>(currentYearEnd);
  const [filterApplyToggle, setFilterApplyToggle] = useState(false);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setAcrossWithinShow,
  });

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    dispatch(
      getAllDepartmentsList({
        country: country,
        state: state,
        district: district,
        taluk: taluk,
        hobli: hobli,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        order: order,
        orderBy: orderBy,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    allSearch,
    searchQuery,
    filterApplyToggle,
  ]);

  const getAllDepartmenttsListRes = useSelector(
    (data: any) => data?.getAllDepartmentsList
  );

  useEffect(() => {
    if (getAllDepartmenttsListRes?.status === "success") {
      setDepartmentLists(getAllDepartmenttsListRes?.data?.data);
    } else if (getAllDepartmenttsListRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [getAllDepartmenttsListRes]);

  const [filterShow, setFilterShow] = useState(false);

  const FilterButton = () => {
    return (
      <button
        className="text-blue-300 border me-6 py-2 px-3 bg-white rounded-lg text-14 font-medium shadow-sm border-gray-300 flex items-center gap-2"
        type="button"
        onClick={() => setFilterShow((prev) => !prev)}
      >
        <FilterListIcons styles={{ width: "20px", height: "20px" }} />
        <span className="text-primary">Filter</span>
      </button>
    );
  };

  return (
    <>
      <InnerLayout title="MIS" customButton={<FilterButton />}>
        <>
          <div className="p-6">
            <Breadcrumbs data={departmentsTableBreadcrumData} />
          </div>

          <MisDepartmentDiagnosisFilter
            country={country}
            setCountry={setCountry}
            state={state}
            setState={setState}
            district={district}
            setDistrict={setDistrict}
            taluk={taluk}
            setTaluk={setTaluk}
            hobli={hobli}
            setHobli={setHobli}
            setFilterApplyToggle={setFilterApplyToggle}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
          />
          <div className="m-6 rounded-lg py-6 bg-white shadow-sm1 pb-12">
            <div className="px-6">
              <div className="flex items-center">
                <span className="text-18 font-semibold text-primary">
                  Departments -{" "}
                </span>
                <span className="text-18 font-semibold text-primary">
                  {removeUnderScore(acrossWithin)}
                </span>
                <div className="relative">
                  <button
                    className="text-white bg-blue-300 rounded-full flex justify-center items-center ms-3"
                    type="button"
                    onClick={() => {
                      setAcrossWithinShow((prev) => !prev);
                    }}
                    ref={onClickRef}
                  >
                    {acrossWithinShow ? (
                      <UpArrow styles={{ width: "30px", height: "30px" }} />
                    ) : (
                      <DownArrow styles={{ width: "30px", height: "30px" }} />
                    )}
                  </button>

                  {acrossWithinShow && (
                    <div
                      className={`text-14  absolute z-30 top-10 flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                      ref={insideClickRef}
                    >
                      <button
                        type="button"
                        className={`p-3 px-5 text-start ${
                          acrossWithin === "within_hospitals"
                            ? "text-primary bg-blue-200 font-semibold"
                            : "text-secondary"
                        }  font-medium   rounded-t-lg hover:text-primary hover:bg-blue-150 hover:font-semibold`}
                        onClick={() => {
                          setAcrossWithin("within_hospitals");
                          setAcrossWithinShow(false);
                        }}
                      >
                        Departments - Within Hospitals
                      </button>

                      <button
                        type="button"
                        className={`p-3 px-5 text-start ${
                          acrossWithin === "across_hospitals"
                            ? "text-primary bg-blue-200 font-semibold"
                            : "text-secondary"
                        }  font-medium   rounded-b-lg hover:text-primary hover:bg-blue-150 hover:font-semibold`}
                        onClick={() => {
                          setAcrossWithin("across_hospitals");
                          setAcrossWithinShow(false);
                        }}
                      >
                        Departments - Across Hosptials
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="text-16 text-secondary pt-2">
                <span className="font-medium text-secondary">Cases :</span>
                <span className="font-semibold text-primary pe-3">
                  {departmentLists?.totalNos?.toLocaleString("en-IN")}
                </span>
                <span className="text-secondary">|</span>
                <span className="font-medium text-secondary ps-3">
                  Patients Served :
                </span>
                <span className="font-semibold text-primary">
                  {departmentLists?.patientsCount?.toLocaleString("en-IN")}
                </span>
              </div>
            </div>
            <div className="pt-8">
              {acrossWithin === "within_hospitals" ? (
                <WithInHospitalsDepartments
                  data={departmentLists}
                  dataLength={getAllDepartmenttsListRes?.data?.count ?? 10}
                  loading={getAllDepartmenttsListRes?.isLoading}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  order={order}
                  setorder={setorder}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  filter={filter}
                  setFilter={setFilter}
                  setAllSearch={setAllSearch}
                />
              ) : (
                <AcrossHospitalsDepartments
                  data={departmentLists}
                  dataLength={getAllDepartmenttsListRes?.data?.count ?? 10}
                  loading={getAllDepartmenttsListRes?.isLoading}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  order={order}
                  setorder={setorder}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  filter={filter}
                  setFilter={setFilter}
                  setAllSearch={setAllSearch}
                />
              )}
            </div>
          </div>
        </>
      </InnerLayout>
    </>
  );
};

export default MISDepartments;
