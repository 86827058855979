import DashboardCard from "../ui/dashboardCard";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../assests/images/susscess.svg";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onClick?: () => void;
  title?: string;
  description?: string;
  icon?: string;
};

const ErrorModal = ({
  isVisible,
  onClose,
  onClick,
  title,
  description,
  icon = Logo,
}: ModalProps) => {
  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="sm:w-[460px] text-center relative p-[20px]">
        <div className="absolute top-3 right-3">
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="px-5 flex flex-col items-center">
          <span>
            <img src={icon} width={100} height={100} />
          </span>
          <span className="text-24 text-primary font-semibold pt-6">
            {title}
          </span>
          <span className="pt-4 text-18 font-medium text-secondary">
            {description}
          </span>
        </div>
      </div>
    </DashboardCard>
  );
};

export default ErrorModal;
