import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import {
  EmailOtpDataType,
  forgotPasswordDispatchType,
} from "../../modals/IforgotPasswordCard";
import { resetPasswordDataType } from "../../modals/IresetPasswordCard";
import routesName from "../../constants/routesName";

export const ec = createAsyncThunk(
  "profileemailCheck",
  async (data: forgotPasswordDispatchType, { rejectWithValue }) => {
    try {
      const response = await API.post("Profile/forgotpasswordadmincheck", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const profilforgotPassword = createAsyncThunk(
  "profilforgotPassword",
  async (data: forgotPasswordDispatchType, { rejectWithValue }) => {
    try {
      const response = await API.post("Profile/forgotpassword", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const profilverifyOtp = createAsyncThunk(
  "profilverifyOtp",
  async (data: EmailOtpDataType, { rejectWithValue }) => {
    try {
      const response = await API.post("Profile/verifyotp", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const profilresetPassword = createAsyncThunk(
  "profileresetPassword",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API.post("Profile/passwordreset", data);
      return response.data;
    } catch (error : any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);
