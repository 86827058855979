import React, { useEffect, useState } from "react";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import routesName from "../../constants/routesName";
import { SubmitHandler, useForm } from "react-hook-form";
import RolePermissionCheckBox from "../../components/accountManager/rolePermissionCheckBox";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import { useAppDispatch } from "../../redux/store";
import {
  getAccontManager,
  getPermissions,
  updateAccontManager,
} from "../../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import successToast from "../../utils/successToast";
import errorToast from "../../utils/errorToast";
import {
  resetAddAccountManagerState,
  resetGetAccontManagerState,
  resetGetPermissionsState,
} from "../../redux/accountManager/accountManagerSlice";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { editAccountManagerData } from "../../jsonData/breadcrumbData/accountManager";
import AccountManagerEditTab from "../../features/accountManager/editTab";
import { addAccManagerDispatchData } from "../../features/accountManager/dataformatter";
import {
  AccountManagerRolePermission,
  AccountManagerType,
  RolePermissionType,
} from "../../modals/IaccountManager";
import { ResponseType } from "../../modals/IreduxResponse";
import UserAuditDetails from "../../utils/userAuditDetails";
import DetectDevice from "../../utils/detectDevice";

const EditRolesAuthorities = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userDetails = AccessTokenDecoder();
  const dispatch = useAppDispatch();
  const [permissionData, setPermissionData] = useState<RolePermissionType>();
  const [getAllPermissionsData, setGetAllPermissionsData] = useState<
    RolePermissionType[]
  >([]);
  const [roleType, setRoleType] = useState<number>();
  const [selectedItems, setSelectedItems] = useState<
    AccountManagerRolePermission[] | undefined
  >([]);
  const [accountManagerData, setAccountManagerData] =
    useState<AccountManagerType>();
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const deviceType = DetectDevice();

  useEffect(() => {
    // calling role permission api
    dispatch(getPermissions());
    dispatch(getAccontManager(location.state.id));
  }, []);
  const getPermissionsRes = useSelector(
    (data: { getPermissions: ResponseType }) => data.getPermissions
  );
  const getAccontManagerRes = useSelector(
    (data: { getAccontManager: ResponseType }) => data.getAccontManager
  );

  useEffect(() => {
    // handling get permission response
    if (
      getPermissionsRes?.status === "success" &&
      getAccontManagerRes.status === "success"
    ) {
      const roleId = getAccontManagerRes?.data.data?.rolePermission?.roleId;
      const data = getPermissionsRes?.data?.data?.find(
        (item: any) => item?.roleId === parseInt(roleId)
      );
      setGetAllPermissionsData(getPermissionsRes?.data?.data);
      setPermissionData(data);
      setRoleType(getAccontManagerRes?.data?.data?.rolePermission?.roleId);
      setValue(
        "permissions",
        getAccontManagerRes?.data?.data?.rolePermission?.permissions
      );
      setSelectedItems(
        getAccontManagerRes?.data?.data?.rolePermission?.permissions
      );
      setAccountManagerData(getAccontManagerRes?.data?.data);
    } else if (getPermissionsRes?.status === "failed") {
      errorToast("Something Went Wrong");
    }
  }, [getPermissionsRes, getAccontManagerRes]);

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: accountManagerData?.userId,
    userName: accountManagerData?.userName,
    role: accountManagerData?.role,
    customer: accountManagerData?.name,
    customerCode: accountManagerData?.userName,
    customerID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: deviceType,
    auditDate: "2024-03-15T06:57:07.737Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const handleRolePermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    // role based permission data displaying
    const data = getAllPermissionsData?.find(
      (item) => item?.roleId === parseInt(e?.target?.value)
    );
    setPermissionData(data);
    setRoleType(parseInt(e?.target?.value));
    if (parseInt(e?.target?.value) === 1000) {
      setValue("permissions", data?.permissions);
      setSelectedItems(data?.permissions);
    } else if (parseInt(e?.target?.value) === 1005) {
      setValue("permissions", permissionData?.permissions);
      setSelectedItems(permissionData?.permissions);
    } else {
      setValue("permissions", accountManagerData?.rolePermission?.permissions);
      setSelectedItems([]);
    }
  };
  const cancelFn = () => {
    navigate(routesName.accountManagerPage);
  };

  const { handleSubmit, control, watch, setValue } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (data) => {
    const permissions = { ...permissionData, permissions: data?.permissions };
    const finalData = {
      ...accountManagerData,
      ...data,
      permissions: permissions,
    };
    const dispatchData = addAccManagerDispatchData(
      finalData,
      userDetails,
      userAuditInfo
    );
    dispatch(updateAccontManager(dispatchData));
  };

  const updateAccontManagerRes = useSelector(
    (data: { updateAccontManager: ResponseType }) => data?.updateAccontManager
  );

  useEffect(() => {
    if (updateAccontManagerRes?.status === "success") {
      successToast("Updated Successfully");
      navigate(routesName.accountManagerPage);
    } else if (updateAccontManagerRes?.status === "failed") {
      errorToast(
        updateAccontManagerRes?.error?.data?.message ?? "Something Went Wrong"
      );
    }
  }, [updateAccontManagerRes]);

  useEffect(() => {
    return () => {
      dispatch(resetAddAccountManagerState());
      dispatch(resetGetPermissionsState());
      dispatch(resetGetAccontManagerState());
    };
  }, []);

  const filterGetPermissionData = getAllPermissionsData?.filter(
    (item: any) => item?.roleId !== 1005
  );

  return (
    <>
      <InnerLayout title="Edit Account Manager">
        <div className="px-6 py-5 z-10 sticky top-[80px] bg-blue-50">
          <Breadcrumbs data={editAccountManagerData} />
          <AccountManagerEditTab id={location.state.id} />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-6 pb-25 mt-6">
            <span className="text-16 text-primary font-semibold">
              {roleType === 1005 ? "Role" : "Select Role"}
            </span>

            {getPermissionsRes?.isLoading ? (
              <div className="flex justify-center py-3">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="flex gap-4">
                {permissionData?.roleId === 1005 ? (
                  <>
                    <span className="text-16 font-semibold text-secondary py-3">
                      {getAccontManagerRes?.data?.data?.role}
                    </span>
                  </>
                ) : (
                  <>
                    {filterGetPermissionData?.map(
                      (item: RolePermissionType, index: number) => {
                        return (
                          <div
                            className="flex items-center gap-3 my-3"
                            key={index}
                          >
                            <input
                              type="radio"
                              value={item?.roleId}
                              id={item?.roleId?.toString()}
                              name="role_type"
                              checked={roleType == item?.roleId}
                              onChange={(e) => handleRolePermission(e)}
                            />
                            <label
                              htmlFor={item?.roleId?.toString()}
                              className={`text-14 font-semibold ${
                                roleType == item?.roleId
                                  ? "text-primary"
                                  : "text-secondary"
                              }`}
                            >
                              {item?.roleName}
                            </label>
                          </div>
                        );
                      }
                    )}
                  </>
                )}
              </div>
            )}

            <div className="mt-2">
              <div>
                <span className="text-primary text-16 font-semibold">
                  {roleType === 1005 ? "Authority" : "Select Authority"}
                </span>
              </div>
              {getAccontManagerRes?.isLoading ? (
                <div>
                  <LoadingSpinner />
                </div>
              ) : (
                <RolePermissionCheckBox
                  data={permissionData?.permissions}
                  setValue={setValue}
                  control={control}
                  name="permissions"
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  disabled={roleType === 1000 || roleType === 1005}
                />
              )}
            </div>
          </div>
          <div className="fixed bottom-0 fit_width z-20 shadow-bootomSticky">
            <div className="bg-white p-3 px-7 flex gap-3 justify-end">
              <SecondaryButton
                name={addHospitalName.Cancel}
                type="button"
                onClick={() => {
                  cancelFn();
                }}
              />
              <PrimaryButton
                name={addHospitalName.Save}
                loading={updateAccontManagerRes?.isLoading}
              />
            </div>
          </div>
        </form>
      </InnerLayout>
    </>
  );
};

export default EditRolesAuthorities;
