import { useEffect, useRef, useState } from "react";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import routesName from "../../constants/routesName";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import { AddCircledIcon } from "../../components/muiIconsImport";
import AddorEditDevice from "../../features/devices/addorEditDevice";
import { useForm } from "react-hook-form";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useAppDispatch } from "../../redux/store";
import { postAddDevices } from "../../redux/devices/deviceManagmentReducer";
import { useSelector } from "react-redux";
import errorToast from "../../utils/errorToast";
import successToast from "../../utils/successToast";
import { resetpostAddDevices } from "../../redux/devices/deviceMangmentSlice";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import { useNavigate } from "react-router-dom";

interface DeviceData {
  modelName: string;
  serialNumber: string;
  macAddress: string;
  imeiNumber: string;
  deviceId: number;
}

const breadcrumbData = [
  {
    label: "Devices",
    navigate: `${routesName.devicesPage}/${routesName.alldevice}`,
    highLight: false,
  },

  {
    label: "Add Device",
    highLight: true,
  },
];
function AddDevice() {
  const el = useRef<null | HTMLDivElement>(null);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const [count, setCount] = useState([1]);
  useEffect(() => {
    if (el.current) {
      el?.current?.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [toggle, count]);

  const userDetails = AccessTokenDecoder();
  const [requeied, setRequried] = useState(true);
  const [actionType, setActionType] = useState<"AddDevice" | "Submit">();

  const dispatch = useAppDispatch();
  const {
    control,
    watch,
    getValues,
    register,
    setError,
    handleSubmit,
    setValue,
    unregister,
  } = useForm<any>({ mode: "onChange" });

  const devicesList: any = useSelector((data: any) => data?.postAddDevices);
  useEffect(() => {
    return () => {
      dispatch(resetpostAddDevices());
    };
  }, []);

  const handeladd = () => {
    if (actionType === "AddDevice") {
      if (count.length >= 10) {
        errorToast("Devices cannot be added more than 10");
      } else {
        if (count.length == 0) {
          setCount([1]);
          setRequried(true);
        } else {
          setCount([...count, count[count.length - 1] + 1]);
          setRequried(true);
        }
      }
    } else if (actionType === "Submit") {
      if (count.length > 0) {
        const formData = count.map((item) => ({
          deviceId: 0,
          modelName: watch(`modelNumber${item}`)?.trim(),
          SerialNumber: watch(`SerialNumber${item}`)?.trim(),
          MACAddress: watch(`MACAddress${item}`)?.trim(),
          IMEINumber: watch(`IMEINumber${item}`)?.trim(),
          status: "Unlinked",
          userName: userDetails.userName,
        }));
        dispatch(postAddDevices(formData));
      }
    }
  };

  const handeldelete = (i: number, item: any) => {
    setRequried(false);
    const arr = count.filter((it) => it !== item);
    setCount(arr);
  };
  const getKeyByValue = (object: any, value: any) => {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (
          object[prop] === value.serialNumber ||
          object[prop] === value.macAddress ||
          object[prop] === value.imeiNumber
        )
          if (!prop.toString().startsWith("Model")) {
            setError(`${prop}`, {
              type: "manual",
              message: "These values already exists",
            });
          }
      }
    }
  };
  useEffect(() => {
    if (devicesList?.status === "success") {
      successToast("Devices added successfully");
      navigate(-1);
    } else if (devicesList?.status === "failed") {
      errorToast(`${devicesList?.error?.data?.error?.message}`);
      if (devicesList?.error?.data?.data) {
        devicesList?.error?.data?.data?.map((item: any) =>
          getKeyByValue(watch(), item)
        );
      }
    }
  }, [devicesList]);

  return (
    <>
      <InnerLayout title="Add Device">
        <div className=" py-5">
          <div className="px-6">
            <Breadcrumbs data={breadcrumbData} />
          </div>

          <form onSubmit={handleSubmit(handeladd)}>
            <div className="px-6">
              <div className=" max-w-sm w-full lg:max-w-full lg:flex bg-white mt-10 relative rounded-lg mb-16 shadow-sm1">
                <div className="absolute top-6 right-5 ">
                  <PrimaryButton
                    name="Add Device"
                    padding="py-3 px-6"
                    icon={<AddCircledIcon />}
                    type="submit"
                    onClick={() => {
                      setToggle((prev) => !prev);
                      setActionType("AddDevice");
                    }}
                  />
                </div>
                <div className="grid grid-cols-1">
                  <div className="h-[10px] mb-10 ps-3 pt-7 ">
                    <span className="ps-3 pt-7 text-20 font-[600] text-primary ">
                      Total Device ({count.length})
                    </span>{" "}
                  </div>
                  <div className="mt-6 ps-7 h-[60vh] overflow-y-auto scroll_bar_with_side">
                    {count.map((item: number, index: number) => (
                      <>
                        <AddorEditDevice
                          control={control}
                          index={index + 1}
                          item={item}
                          data={watch()}
                          handledelete={handeldelete}
                          register={register}
                          setvalue={setValue}
                          unregister={unregister}
                          getValue={getValues}
                          count={count.length}
                          dataarray={count}
                          requried={requeied}
                        />

                        <div ref={el}></div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="fixed bottom-0 fit_width z-20 shadow-bootomSticky">
              <div className="bg-white p-3 flex justify-between">
                <SecondaryButton
                  type="button"
                  name={addHospitalName.Cancel}
                  onClick={() => navigate(-1)}
                />

                <div className="flex gap-3">
                  <PrimaryButton
                    loading={devicesList?.isLoading}
                    name={addHospitalName.Submit}
                    onClick={() => setActionType("Submit")}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </InnerLayout>
    </>
  );
}

export default AddDevice;
