import { createSlice } from "@reduxjs/toolkit";
import {
  getDevicePageDetails,
  postAddDevices,
  postDevicesXls,
  getAllLinkDevices,
  postUpdateDevices,
  getUnLinkDevices,
  getAllDevices,
} from "./deviceManagmentReducer";
export const getDevicePageDetailsSlice = createSlice({
  name: "getDevicePageDetails",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetDevicePageDetailsState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDevicePageDetails.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getDevicePageDetails.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getDevicePageDetails.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const postDevicesXlsSlice = createSlice({
  name: "postDevicesXls",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetpostDevicesXlsState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDevicesXls.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(postDevicesXls.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(postDevicesXls.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const postAddDevicesSlice = createSlice({
  name: "postAddDevices",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetpostAddDevices: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAddDevices.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(postAddDevices.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(postAddDevices.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const postupdateDevicesSlice = createSlice({
  name: "postUpdateDevices",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetpostUpdateDevices: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUpdateDevices.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(postUpdateDevices.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(postUpdateDevices.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getAllLinkDevicesSlice = createSlice({
  name: "getAllLinkDevices",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetAllLinkDevicesState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLinkDevices.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllLinkDevices.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getAllLinkDevices.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getUnLinkDevicesSlice = createSlice({
  name: "getUnLinkDevices",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetUnLinkDevicesState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnLinkDevices.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getUnLinkDevices.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getUnLinkDevices.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getAllDevicesSlice = createSlice({
  name: "getAllDevices",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetAllDevicesState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDevices.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllDevices.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getAllDevices.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getAllLinkDevicesreducer = getAllLinkDevicesSlice.reducer;
export const getAllUnLinkDevicesreducer = getUnLinkDevicesSlice.reducer;
export const getAllDevicesreducer = getAllDevicesSlice.reducer;
export const { resetgetUnLinkDevicesState } = getUnLinkDevicesSlice.actions;
export const { resetgetAllDevicesState } = getAllDevicesSlice.actions;
export const { resetpostDevicesXlsState } = postDevicesXlsSlice.actions;
export const { resetgetAllLinkDevicesState } = getAllLinkDevicesSlice.actions;
export const { resetpostAddDevices } = postAddDevicesSlice.actions;
export const { resetpostUpdateDevices } = postupdateDevicesSlice.actions;
export const postupdateDevicesreducer = postupdateDevicesSlice.reducer;
export const postDevicesXlsreducer = postDevicesXlsSlice.reducer;
export const postAddDevicesreducer = postAddDevicesSlice.reducer;
export const getDevicePageDetailsReducer = getDevicePageDetailsSlice.reducer;

export const commanDevicesSlice = createSlice({
  name: "commanDevices",
  initialState: {
    data: [],
  },
  reducers: {
    commonDevices: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const commamDeviceSliceReducer = commanDevicesSlice.reducer;
export const { commonDevices } = commanDevicesSlice?.actions;
