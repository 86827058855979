import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import loginSlice from "./loginslice";
import {
  emailCheckSlicereducer,
  forgotPasswordSliceReducer,
  resetPasswordSliceReducer,
  verifyOtpSliceReducer,
} from "./forgotPassword/forgotPasswordSlice";
import {
  activateOrDeactivatereducer,
  addHospitalreducer,
  createHospitalIdreducer,
  createHospitalUserIdreducer,
  deLinkDevicereducer,
  deleteHospitalByIdreducer,
  enumDataReducer,
  gerAllHospitalreducer,
  getAccountAllListreducer,
  getActiveHosCountreducer,
  getActivePaidCountreducer,
  getActivePilotCountreducer,
  getDevicesreducer,
  getExpiredHosCountreducer,
  getHospitalByIdreducer,
  getHospitalByStatusreducer,
  getHospitalUserPasswordreducer,
  getInactiveHosCountreducer,
  getInprogressHosCountreducer,
  getOwnershipTypereducer,
  getProductServicereducer,
  getProductTypereducer,
  getSpecialisationreducer,
  managementStatusSliceReducer,
  posthospitalLogoreducer,
  updateHospitalreducer,
} from "./addHospital/hospitalManagementSlice";
import {
  activateDeactiveAccManagerreducer,
  addAccountManagerreducer,
  deleteAccManagerreducer,
  getAccontManagerreducer,
  getAllAccontManagerreducer,
  getHospitalsIdListreducer,
  getHospitalsLinkedAccManagerreducer,
  getPermissionsreducer,
  getStatusWiseHospitalsLinkedAccManagerreducer,
  getUserAuditLinkedAccManagerreducer,
  linkHospitalToAccManagerreducer,
  resetPasswordAccManagerreducer,
  updateAccontManagerreducer,
} from "./accountManager/accountManagerSlice";
import {
  getAllDepartmentsListreducer,
  getAllDiagnosisListreducer,
  getNewHospitalsGraphDatareducer,
  getReasonsGraphDatareducer,
  getProductAndPlanGraphDatareducer,
  getBedSizeGraphDatareducer,
  getPatientDetailsDepartmentsGraphDatareducer,
  getPatientDetailsDiagnosisGraphDatareducer,
  getSpecialityGraphDatareducer,
  getCustomersGraphDatareducer,
  getOnboardingSourceGraphDatareducer,
  getActiveHospitalsGraphDatareducer,
  getExpiredAndDeactivatedHospitalsGraphDatareducer,
} from "./mis/misSlice";
import { getAllFeedbackreducer } from "./feedback/feedbackSlice";
import {
  notificationbyStatusreducer,
  deleteNotificationsreducer,
  shareNotificationsreducer,
  sendNotificationsreducer,
} from "./notifications/notificationSlice";
import { getAllAudithLoginreducer } from "./auditLogin/audithLoginSlice";
import {
  ecSlicereducer,
  profilforgotPasswordSliceReducer,
  profilresetPasswordSliceReducer,
  profilverifyOtpSliceReducer,
} from "./profile/profileSlice";
import {
  commamDeviceSliceReducer,
  getAllDevicesreducer,
  getAllLinkDevicesreducer,
  getAllUnLinkDevicesreducer,
  getDevicePageDetailsReducer,
  postAddDevicesreducer,
  postDevicesXlsreducer,
  postupdateDevicesreducer,
} from "./devices/deviceMangmentSlice";
import {
  getCityDatareducer,
  getCountryDatareducer,
  getDistrictDatareducer,
  getHobliDatareducer,
  getStateDatareducer,
  getTalukDatareducer,
} from "./location/locationSlice";

export const store = configureStore({
  reducer: {
    login: loginSlice,
    forgotPassword: forgotPasswordSliceReducer,
    verifyOtp: verifyOtpSliceReducer,
    resetPassword: resetPasswordSliceReducer,
    emailCheck: emailCheckSlicereducer,
    getOwnershipType: getOwnershipTypereducer,
    enumData: enumDataReducer,
    getProductType: getProductTypereducer,
    getProductService: getProductServicereducer,
    getSpecialisation: getSpecialisationreducer,
    getDevices: getDevicesreducer,
    addHospital: addHospitalreducer,
    updateHospital: updateHospitalreducer,
    getAllHospital: gerAllHospitalreducer,
    getHospitalById: getHospitalByIdreducer,
    getHospitalByStatus: getHospitalByStatusreducer,
    getActiveHosCount: getActiveHosCountreducer,
    getExpiredHosCount: getExpiredHosCountreducer,
    getInactiveHosCount: getInactiveHosCountreducer,
    getInprogressHosCount: getInprogressHosCountreducer,
    getActivePaidCount: getActivePaidCountreducer,
    getActivePilotCount: getActivePilotCountreducer,
    deleteHospitalById: deleteHospitalByIdreducer,
    posthospitalLogo: posthospitalLogoreducer,
    createHospitalId: createHospitalIdreducer,
    createHospitalUserId: createHospitalUserIdreducer,
    getHospitalUserPassword: getHospitalUserPasswordreducer,
    activateOrDeactivate: activateOrDeactivatereducer,
    deLinkDevice: deLinkDevicereducer,
    addAccountManager: addAccountManagerreducer,
    getPermissions: getPermissionsreducer,
    getAllAccontManager: getAllAccontManagerreducer,
    getAccontManager: getAccontManagerreducer,
    updateAccontManager: updateAccontManagerreducer,
    getHospitalsLinkedAccManager: getHospitalsLinkedAccManagerreducer,
    getHospitalsIdList: getHospitalsIdListreducer,
    activateDeactiveAccManager: activateDeactiveAccManagerreducer,
    deleteAccManager: deleteAccManagerreducer,
    linkHospitalToAccManager: linkHospitalToAccManagerreducer,
    resetPasswordAccManager: resetPasswordAccManagerreducer,
    getStatusWiseHospitalsLinkedAccManager:
      getStatusWiseHospitalsLinkedAccManagerreducer,
    getUserAuditLinkedAccManager: getUserAuditLinkedAccManagerreducer,
    ec: ecSlicereducer,
    profilforgotPassword: profilforgotPasswordSliceReducer,
    profilverifyOtp: profilverifyOtpSliceReducer,
    profilresetPassword: profilresetPasswordSliceReducer,
    getDevicePageDetailsReducer: getDevicePageDetailsReducer,
    postDevicesXlsreducer: postDevicesXlsreducer,
    getAllLinkDevices: getAllLinkDevicesreducer,
    postAddDevices: postAddDevicesreducer,
    postupdateDevices: postupdateDevicesreducer,
    getAllUnLinkDevicesreducer: getAllUnLinkDevicesreducer,
    getAllDevicesreducer: getAllDevicesreducer,
    getAccountAllList: getAccountAllListreducer,
    getAllDepartmentsList: getAllDepartmentsListreducer,
    getAllDiagnosisList: getAllDiagnosisListreducer,

    //newHospitals data
    getNewHospitalsGraphData: getNewHospitalsGraphDatareducer,
    getReasonsGraphData: getReasonsGraphDatareducer,
    getProductAndPlanGraphData: getProductAndPlanGraphDatareducer,
    getBedSizeGraphData: getBedSizeGraphDatareducer,
    getSpecialityGraphData: getSpecialityGraphDatareducer,
    getPatientDetailsDepartmentsGraphData:
      getPatientDetailsDepartmentsGraphDatareducer,
    getPatientDetailsDiagnosisGraphData:
      getPatientDetailsDiagnosisGraphDatareducer,
    getCustomersGraphData: getCustomersGraphDatareducer,
    getOnboardingSourceGraphData: getOnboardingSourceGraphDatareducer,
    getActiveHospitalsGraphData: getActiveHospitalsGraphDatareducer,
    getExpiredAndDeactivatedHospitalsGraphData:
      getExpiredAndDeactivatedHospitalsGraphDatareducer,
    getAllAudithLogin: getAllAudithLoginreducer,
    getAllFeedback: getAllFeedbackreducer,
    getnotificationbyStatus: notificationbyStatusreducer,
    deleteNotifications: deleteNotificationsreducer,
    shareNotifications: shareNotificationsreducer,
    sendNotifications: sendNotificationsreducer,
    getCountryData: getCountryDatareducer,
    getStateData: getStateDatareducer,
    getDistrictData: getDistrictDatareducer,
    getTalukData: getTalukDatareducer,
    getHobliData: getHobliDatareducer,
    getCityData: getCityDatareducer,
    commanDevices: commamDeviceSliceReducer,
    managementStatus: managementStatusSliceReducer,
  },
});

// dispatch does not take types for thunks into account and thus the return type is typed incorrectly. Please use the actual Dispatch type from the store as decsribed in the documentation. Ref: https://stackoverflow.com/questions/63811401/property-then-does-not-exist-on-type-asyncthunkaction-redux-toolkit
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
