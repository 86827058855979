import { useEffect, useState } from "react";
import {
  Grid,
  LinearProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { LoadingSpinner } from "../../../components/ui/loadinSpinner";
import { getPatientDetailsDepartmentsGraphData } from "../../../redux/mis/misReducer";
import SelectComponent from "../../../components/forms/selectComponent";

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#eeeeee00",
        },
        bar: {
          background: "linear-gradient(to right, #FADEB0 30%, #F5B44B 70%)",
        },
      },
    },
  },
});

const departmentList = [
  { label: "All Departments", value: "all" },
  { label: "Top 5 Departments", value: 5 },
  { label: "Top 10 Departments", value: 10 },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 22,
  borderRadius: 0,
}));

function DepartmentList({
  country,
  state,
  district,
  taluk,
  hobli,
  filterApplyToggle,
  hospitalArray,
}: any) {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [departmentsGraphData, setdepartmentsGraphData] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const departmentsData = useSelector(
    (data: any) => data.getPatientDetailsDepartmentsGraphData
  );

  let highestPercentage = 0;

  departmentsGraphData?.patientDetailsSummary?.patientsCountList?.forEach(
    (hospital: any) => {
      if (hospital.percentage > highestPercentage) {
        highestPercentage = hospital.percentage;
      }
    }
  );

  const casesNoShow =
    departmentsGraphData?.patientDetailsSummary?.patientsCountList.length;

  useEffect(() => {
    if (departmentsData?.status === "success") {
      setErrorMessage("");
      setdepartmentsGraphData(departmentsData?.data?.data ?? []);
    } else if (departmentsData?.status === "failed") {
      setErrorMessage("Something went Wrong");
    }
  }, [departmentsData]);

  useEffect(() => {
    dispatch(
      getPatientDetailsDepartmentsGraphData({
        country: country,
        state: state,
        district: district,
        taluk: taluk,
        hobli: hobli,
        serviceType: "",
        fromDate: "",
        toDate: "",
        hospitals: hospitalArray ?? [],
      })
    );
  }, [
    // country,
    //  state, district,
    //   taluk, hobli,
    filterApplyToggle,
  ]);

  return (
    <div className="p-6 bg-white shadow-sm1 rounded-lg">
      {departmentsData.isLoading ? (
        <div className="flex justify-center py-5">
          <LoadingSpinner />
        </div>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <span className="text-18 font-medium text-primary">
              Total Departments
            </span>

            <div style={{ display: "flex", marginTop: "8px" }}>
              <span className="text-14 font-[400] text-secondary">Cases :</span>
              <span className="text-14 font-[700] text-primary ml-3">
                {departmentsGraphData?.patientDetailsSummary?.totalNos?.toLocaleString(
                  "en-IN"
                )}
              </span>

              <div className="border-r border-secondary ml-5" />

              <span className="text-14 font-[400] text-secondary ml-5">
                Patients Served :
              </span>
              <span className="text-14 font-[700] text-primary pl-1">
                {departmentsGraphData?.totalPatientsCount?.toLocaleString(
                  "en-IN"
                )}
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <SelectComponent
              label="Select"
              id="status"
              value={selectedValue}
              onChange={handleChange}
              options={departmentList}
            />
          </Grid>
          {departmentsGraphData?.patientDetailsSummary?.patientsCountList
            .length > 0 ? (
            <Grid item xs={12} sm={12} md={12}>
              {departmentsGraphData?.patientDetailsSummary?.patientsCountList
                ?.slice(
                  0,
                  selectedValue === "all"
                    ? casesNoShow
                    : parseInt(selectedValue)
                )
                .map((item: any, index: any) => {
                  return (
                    <>
                      <div key={index}>
                        <ThemeProvider theme={theme}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={1.8}>
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.name}
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9.5}>
                              <span
                                style={{
                                  zIndex: 1,
                                  position: "relative",
                                  color: "#212121",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.patientsCount?.toLocaleString("en-IN")}
                              </span>
                              <BorderLinearProgress
                                style={{
                                  right: "6px",
                                  position: "relative",
                                  bottom: 22,
                                  width: "100%",
                                }}
                                variant="determinate"
                                value={
                                  (item.percentage / highestPercentage) * 100
                                }
                              />
                            </Grid>
                            <Grid
                              style={{ display: "flex", justifyContent: "end" }}
                              item
                              xs={12}
                              sm={12}
                              md={0.7}
                            >
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.percentage}%
                              </span>
                            </Grid>
                          </Grid>
                        </ThemeProvider>
                      </div>
                    </>
                  );
                })}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <div className="flex justify-center">
                <span className="text-18 font-medium text-primary">
                  No Data Found
                </span>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

export default DepartmentList;
