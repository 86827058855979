import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchInput from "../../../../components/forms/searchInput";
import { ButtonWithIcon } from "../../../../components/buttons/buttons";
import {
  ArrowBackIos,
  ArrowForwardIos,
  DeleteOutlinedIcons,
} from "../../../../components/muiIconsImport";
import NotificationDetailCard from "../systemNotification/notificationDetailCard";
import { getNotifications } from "../../../../redux/notifications/notificationReducer";
import ConfirmationModal from "../../../../components/modals/confirmationModal";
import SuccessMessage from "../../../../components/modals/successMessage";
import { formatNotificationData } from "../../../../utils/notificationformatter";
import enums from "../../../../constants/enums";
import Delete from "../../../../assests/icons/deleteIcon.png";
import sortValues from "../../../../constants/sortColumnValue";
import { TableFilterData } from "../../../../modals/Itables";
import { SearchQuery } from "../../../../utils/searchQuery";
import errorToast from "../../../../utils/errorToast";
import { LoadingSpinner } from "../../../../components/ui/loadinSpinner";
import useAxios from "../../../../hooks/useAxios";
import successToast from "../../../../utils/successToast";
import { useAppDispatch } from "../../../../redux/store";
import { resetNotificationbystatus } from "../../../../redux/notifications/notificationSlice";
import EmptyNOtificationImage from "../../../../assests/images/empty-notification-icon.svg";

const SentNotifications = () => {
  const [page, setPage] = useState(sortValues?.page);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState<TableFilterData>({});
  const searchQuery = SearchQuery({ filter });
  const [allSearch, setAllSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [status, setStatus] = useState<number>(3);
  const [notificationData, setNotificationData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forwardModal, setForwardModal] = useState(false);
  const dispatch = useAppDispatch();
  const getnotificationbyStatus = useSelector(
    (data: { getnotificationbyStatus: any }) => data.getnotificationbyStatus
  );
  const totalcount = getnotificationbyStatus?.data?.count ?? 10;
  const actualCount = getnotificationbyStatus?.data?.data?.length;

  useEffect(() => {
    dispatch(
      getNotifications({
        status: status,
        order: "notification_sent",
        orderBy: "desc",
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
  }, [status, page, rowsPerPage, allSearch]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch]);

  useEffect(() => {
    if (getnotificationbyStatus?.status === "success") {
      const { data } = getnotificationbyStatus?.data;
      if (data?.length > 0) {
        setNotificationData(formatNotificationData(data));
      } else {
        setNotificationData([]);
      }
    } else if (getnotificationbyStatus?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [getnotificationbyStatus]);

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: confirmDelete,
    toggle,
  } = useAxios({
    method: "delete",
    path: `Notification/notifications/delete`,
    body: [...selected],
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Notification deleted successfully");
      setDeleteModal(false);
      setSelected([]);
      dispatch(
        getNotifications({
          status: status,
          order: "notification_sent",
          orderBy: "desc",
          page: page,
          rowsPerPage: rowsPerPage,
          searchQuery: searchQuery,
          searchKeywords: allSearch,
        })
      );
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  function handlePagination(type: string) {
    if (type === "+") {
      if (page + 1 < totalcount / rowsPerPage) setPage((prev) => prev + 1);
    } else if (type === "-") if (page > 0) setPage((prev) => prev - 1);
  }

  const totalRowCount = rowsPerPage * (page + 1);
  const pageCount = `${rowsPerPage * page + 1} - ${
    totalRowCount > totalcount ? totalcount : totalRowCount
  } of ${totalcount}`;
  const nextPage = page + 1 < totalcount / rowsPerPage;

  useEffect(() => {
    return () => {
      dispatch(resetNotificationbystatus());
    };
  }, []);

  return (
    <>
      <ConfirmationModal
        isVisible={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        // onClick={confirmDelete}
        onClick={() => confirmDelete()}
        icon={Delete}
        title={`Are you sure you want to delete these notifications ?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      <SuccessMessage
        isVisible={forwardModal}
        onClose={() => {
          setForwardModal(false);
          setSelected([]);
        }}
        title={`The notifications have been successfully forwarded!`}
      />

      {getnotificationbyStatus?.data?.count < 1 ? (
        <div className="flex h-[60vh] justify-center items-center">
          <img src={EmptyNOtificationImage} />
        </div>
      ) : (
        <>
          <div className="flex justify-between py-3 items-center">
            <div className="py-3 text-secondary font-semibold text-16">
              <span className="text-primary">{selected.length}</span>{" "}
              <span>{enums.selected}</span>
            </div>
            <div className="flex max-lg:flex-col gap-6 lg:items-center items-end">
              <div className="flex gap-6 items-center">
                <SearchInput setAllSearch={setAllSearch} />
              </div>

              <div className="flex gap-6 items-center ">
                <ButtonWithIcon
                  icon={
                    <DeleteOutlinedIcons
                      styles={{
                        color: selected?.length > 0 ? `#534FA1` : `#C0C2C6`,
                      }}
                    />
                  }
                  onClick={() => {
                    if (selected?.length > 0) {
                      setDeleteModal(true);
                    } else {
                      errorToast("Please select any notification to delete");
                    }
                  }}
                />
              </div>

              <div className="flex gap-6 items-center">
                <span className="text-primary text-16 font-semibold whitespace-nowrap">
                  {pageCount}
                </span>
                <button
                  className={`text-blue-300 ${page === 0 && "opacity-50"}`}
                  type="button"
                  onClick={() => {
                    handlePagination("-");
                  }}
                  disabled={page === 0}
                >
                  <ArrowBackIos styles={{ width: "20px", height: "20px" }} />
                </button>
                <button
                  className={`text-blue-300 ${!nextPage && "opacity-50"}`}
                  type="button"
                  onClick={() => {
                    handlePagination("+");
                  }}
                  disabled={!nextPage}
                >
                  <ArrowForwardIos styles={{ width: "20px", height: "20px" }} />
                </button>
              </div>
            </div>
          </div>
          <>
            {getnotificationbyStatus?.isLoading ? (
              <div className="flex justify-center py-10 h-[60vh] items-center">
                <LoadingSpinner />
              </div>
            ) : actualCount < 1 ? (
              <>
                <div className="flex justify-center absolute top-[50%] left-[50%]">
                  <span className="text-24 text-secondary">No data found</span>
                </div>
              </>
            ) : (
              <NotificationDetailCard
                notificationData={notificationData}
                setSelected={setSelected}
                selected={selected}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                allSearch={allSearch}
                isSendOption={false}
                type="SentNotification"
              />
            )}
          </>
        </>
      )}
    </>
  );
};

export default SentNotifications;
