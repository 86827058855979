import { createSlice } from "@reduxjs/toolkit";
import {
  emailCheck,
  forgotPassword,
  resetPassword,
  verifyOtp,
} from "./forgotPasswordReducer";

export const emailCheckSlice = createSlice({
  name: "emailCheck",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetEmailCheckState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(emailCheck.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(emailCheck.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(emailCheck.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetForgotPasswordState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetVerifyOtpState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(verifyOtp.rejected, (state, { payload }: any) => {
    
        
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    status: "",
    isLoading: false,
    userdetails: [],
    error: {},
  },
  reducers: {
    resetPasswordState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        userdetails: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userdetails = payload;
        state.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const verifyOtpSliceReducer = verifyOtpSlice.reducer;
export const forgotPasswordSliceReducer = forgotPasswordSlice.reducer;
export const resetPasswordSliceReducer = resetPasswordSlice.reducer;
export const emailCheckSlicereducer = emailCheckSlice.reducer;

export const { resetForgotPasswordState } = forgotPasswordSlice.actions;
export const { resetVerifyOtpState } = verifyOtpSlice.actions;
export const { resetPasswordState } = resetPasswordSlice.actions;
export const { resetEmailCheckState } = emailCheckSlice.actions;
