import moment from "moment";
import { useSelector } from "react-redux";
import { accessTokenType } from "../../modals/IloginCard";
import { istDate } from "../../helper";

export const OnBoardDispatchData = (
  finalData: any,
  specialisationList: any,
  userDetails?: accessTokenType,
  userAuditInfo?: any
) => {
  const managementAssistant = finalData?.productInfo?.find(
    (item: any) => item.productId === 1000
  );
  const clinicalAssistant = finalData?.productInfo?.find(
    (item: any) => item.productId === 1001
  );
  const MAaddOnService = finalData?.MAaddOnService?.map((item: any) => {
    if (item.serviceId === 1000) {
      return { ...item, addOnServiceType: finalData?.reportType };
    }
    return item;
  });
  const filteredSpecialisations = specialisationList?.data?.data?.filter(
    (spec: any) => finalData?.specialisation?.includes(spec.specialisationId)
  );

  const dispatchData = {
    hospitalInfo: {
      name: finalData?.hospitalName ?? "",
      code: finalData?.hospitalID ?? "",
      licenseNo: finalData?.licenseNumber ?? "",
      licenseAuthority: finalData?.licenseAuthority ?? "",
      hfrId: finalData?.hfrId ?? "",
      buildingNo: finalData?.buildingNumber ?? "",
      street: finalData?.streetName ?? "",
      locality: finalData?.locality ?? "",
      hobli: finalData?.hobli ?? "",
      hobliId: finalData?.hobliId ?? "",
      taluk: finalData?.taluk ?? "",
      talukId: finalData?.talukId ?? "",
      district: finalData?.district ?? "",
      districtId: finalData?.districtId ?? "",
      city: finalData?.city ?? "",
      // cityId: finalData?.cityId ?? 0,
      cityId: 0,
      state: finalData?.state ?? "",
      stateId: finalData?.stateId ?? "",
      country: finalData?.country ?? "",
      countryId: finalData?.countryId ?? "",
      logoURL: finalData?.logoURL ?? "",
      isActive: finalData?.isActive ?? 0,
      isDelete: finalData?.isDelete ?? 0,
      postalCode: +finalData?.pincode,
      phone: finalData?.mobileNumber ?? "",
      email: finalData?.emailID ?? "",
      websiteURL: finalData?.url ?? "",
      bedSize: finalData?.bedSize ?? "",
      // onboardingDate: moment(finalData?.onboardingDate).utc().format() ?? "",
      onboardingDate: finalData?.onboardingDate
        ? // ? new Date(finalData?.onboardingDate).toISOString()
          finalData?.onboardingDate
        : new Date("0001-01-01").toISOString(),

      status: finalData?.status ?? "active",
      speciality: filteredSpecialisations ?? [],
      managementUserInfo: [
        {
          name: finalData?.hospiatlManagementName ?? "",
          phone: finalData?.hospiatlManagementMobileNumber ?? "",
          email: finalData?.hospiatlManagementEmail ?? "",
        },
      ],
      accountManager: {
        name: finalData?.jeevAccountName ?? "",
        phone: finalData?.jeevAccountMobileNumber ?? "",
        email: finalData?.jeevAccountEmail ?? "",
        userId: parseInt(finalData?.jeevAccountUserId) ?? 0,
        roleName: finalData?.jeevAccountUserRole ?? "",
      },
      onBoardingSource: {
        sourceType: finalData?.sourceType ?? "",
        sourceName: finalData?.sourceName ?? "",
      },
      ownerInfo: {
        name: finalData?.hospitalGroupName ?? "",
        phone: finalData?.hospiatlManagementMobileNumber ?? "",
        email: finalData?.hospiatlManagementEmail ?? "",
        ownershipType: finalData?.ownershipType ?? "",
        isOperatedByOwner:
          finalData?.operator_by_owner === "Yes" ? true : false,
        operatorInfo: {
          name: finalData?.operatorName ?? "",
          phone: finalData?.operatorMobileNumber ?? "",
          email: "string" ?? "",
        },
      },
    },
    productandPlanInfos: [
      ...(managementAssistant
        ? [
            {
              productInfo: managementAssistant ?? "",
              addOnServices: MAaddOnService ?? [],
              paidDevices:
                finalData?.maPaidDevices === "noDevices"
                  ? []
                  : finalData?.maPaidDevices,
              packageDevices:
                finalData?.maPackageDevices === "noDevices"
                  ? []
                  : finalData?.maPackageDevices,
              planInfo: {
                packageDevicesCount: +finalData?.maPackageDevicesCount,
                paidDevicesCount: +finalData?.maPaidDevicesCount,
                planTotalAmount: +finalData?.maAllAmount,
                serviceType: finalData?.maServiceType ?? "",
                planType: finalData?.maPlanType ?? "",
                patientType: finalData?.maPatientType ?? "",
                inPatientAmount: +finalData?.maInPatientAmount,
                outPatientAmount: +finalData?.maOutPatientAmount,
                validityPeriod: finalData?.maValidityPeriod ?? "",
                // validityStart: finalData?.maValidityFrom
                //   ? moment(finalData?.maValidityFrom).utc().format()
                //   : "",
                validityStart: finalData?.maValidityFrom
                  ? new Date(finalData?.maValidityFrom).toISOString()
                  : new Date("0001-01-01").toISOString(),
                // validityEnd: finalData?.maValidityto
                //   ? moment(finalData?.maValidityto).utc().format()
                //   : "",
                validityEnd: finalData?.maValidityto
                  ? new Date(finalData?.maValidityto).toISOString()
                  : new Date("0001-01-01").toISOString(),
              },
            },
          ]
        : []),
      ...(clinicalAssistant
        ? [
            {
              productInfo: clinicalAssistant ?? "",
              addOnServices: finalData?.CLaddOnService ?? [],
              paidDevices:
                finalData?.clPaidDevices === "noDevices"
                  ? []
                  : finalData?.clPaidDevices,
              packageDevices:
                finalData?.clPackageDevices === "noDevices"
                  ? []
                  : finalData?.clPackageDevices,
              planInfo: {
                packageDevicesCount: +finalData?.clPackageDevicesCount,
                paidDevicesCount: +finalData?.clPaidDevicesCount,
                planTotalAmount: +finalData?.clAllAmount,
                serviceType: finalData?.clServiceType ?? "",
                planType: finalData?.clPlanType ?? "",
                patientType: finalData?.clPatientType ?? "",
                inPatientAmount: +finalData?.clInPatientAmount,
                outPatientAmount: +finalData?.clOutPatientAmount,
                validityPeriod: finalData?.clValidityPeriod ?? "",
                // validityStart: finalData?.clValidityFrom
                //   ? moment(finalData?.clValidityFrom).utc().format()
                //   : "",
                validityStart: finalData?.clValidityFrom
                  ? new Date(finalData?.clValidityFrom).toISOString()
                  : new Date("0001-01-01").toISOString(),
                // validityEnd: finalData?.clValidityto
                //   ? moment(finalData?.clValidityto).utc().format()
                //   : "",
                validityEnd: finalData?.clValidityto
                  ? new Date(finalData?.clValidityto).toISOString()
                  : new Date("0001-01-01").toISOString(),
              },
            },
          ]
        : []),
    ],
    deviceCost: {
      instalments: finalData?.instalments ?? "",
      totalPaidDevicesCost: +finalData?.deviceCost,
      securityDeposit: +finalData?.securityDeposit,
      oneTimeCost: +finalData?.onetimeCost,
    },
    hospitalAdminInfo: {
      userId: finalData?.hospitalUserId ?? 0,
      name: finalData?.hospitalAdminManagementName ?? "",
      phone: finalData?.hospitalAdminManagementMobileNumber ?? "",
      email: finalData?.hospitalAdminManagementEmail ?? "",
      userName: finalData?.userID ?? "",
      password: finalData?.userPassword ?? null,
      isTempPassword: finalData?.setNewPAssword ? true : false,
    },
    groupName: finalData?.hospitalGroupName ?? "",
    isPartOfGroup: finalData?.Hospital_group ?? "",
    userName: userDetails?.userName,
    userAuditInfo,
  };

  return dispatchData;
};

export const HosiptalInfoObject = (data: any) => {
  const {
    name,
    licenseNo,
    licenseAuthority,
    buildingNo,
    street,
    locality,
    taluk,
    hobli,
    district,
    country,
    state,
    city,
    talukId,
    hobliId,
    districtId,
    countryId,
    stateId,
    cityId,
    postalCode,
    phone,
    email,
    websiteURL,
    logoURL,
    hfrId,
  } = data?.hospitalInfo || {};

  const hosiptalInfoObject = {
    hospitalName: name,
    licenseNumber: licenseNo,
    licenseAuthority: licenseAuthority,
    buildingNumber: buildingNo,
    streetName: street,
    locality: locality,
    taluk: taluk,
    hobli: hobli,
    district: district,
    country: country,
    state: state,
    city: city,
    talukId: talukId,
    hobliId: hobliId,
    districtId: districtId,
    countryId: countryId,
    stateId: stateId,
    cityId: cityId,
    pincode: postalCode,
    mobileNumber: phone,
    emailID: email,
    url: websiteURL,
    logoURL: logoURL,
    hfrId: hfrId,
  };

  return hosiptalInfoObject;
};

export const SpecialisationObject = (data: any) => {
  const specialisation = data?.hospitalInfo?.speciality?.map((item: any) => {
    return item.specialisationId;
  });

  return { specialisation };
};

export const OwnerOperatorObject = (data: any) => {
  const { ownershipType, name, phone, isOperatedByOwner, operatorInfo } =
    data?.hospitalInfo?.ownerInfo || {};

  const ownerOperatorDetailsObject = {
    Hospital_group: data?.isPartOfGroup,
    hospitalGroupName: data?.groupName,
    ownershipType: ownershipType,
    ownerName: name,
    ownerMobileNumber: phone,
    operator_by_owner: isOperatedByOwner ? "Yes" : "No",
    operatorName: operatorInfo?.name,
    operatorMobileNumber: operatorInfo?.phone,
  };

  return ownerOperatorDetailsObject;
};

export const OnBoardSourceObject = (data: any) => {
  const { sourceType, sourceName } = data?.hospitalInfo?.onBoardingSource || {};
  const onBoardSourceObject = {
    sourceType: sourceType,
    sourceName: sourceName,
  };

  return onBoardSourceObject;
};

export const HospitalManagementObject = (data: any) => {
  const hospitalManagementObject = {
    hospiatlManagementName: data?.hospitalInfo?.managementUserInfo?.[0]?.name,
    hospiatlManagementMobileNumber:
      data?.hospitalInfo?.managementUserInfo?.[0]?.phone,
    hospiatlManagementEmail: data?.hospitalInfo?.managementUserInfo?.[0]?.email,
  };

  return hospitalManagementObject;
};

export const AccountManagerObject = (data: any) => {
  const { name, phone, email, userId, roleName } =
    data?.hospitalInfo?.accountManager || {};

  const accountManagerObject = {
    jeevAccountName: name,
    jeevAccountMobileNumber: phone,
    jeevAccountEmail: email,
    jeevAccountUserId: userId,
    jeevAccountUserRole: roleName,
  };
  return accountManagerObject;
};

export const ProductDetailsObjects = (data: any) => {
  const managementAssistantData = data?.productandPlanInfos?.find(
    (item: any) => item?.productInfo?.productId === 1000
  );

  const clinicalAssistantData = data?.productandPlanInfos?.find(
    (item: any) => item?.productInfo?.productId === 1001
  );

  const reportTypeData = managementAssistantData?.addOnServices?.find(
    (item: any) => item?.serviceId === 1000
  )?.addOnServiceType;

  const planInfoData = managementAssistantData?.planInfo;
  const clinicalPlanInfoData = clinicalAssistantData?.planInfo;

  const {
    serviceType,
    planType,
    patientType,
    planTotalAmount,
    inPatientAmount,
    outPatientAmount,
    validityPeriod,
    validityStart,
    validityEnd,
    packageDevicesCount,
    paidDevicesCount,
  } = planInfoData || {};

  const managementAssistantObject = {
    MAaddOnService: managementAssistantData?.addOnServices,
    reportType: reportTypeData ?? "none",
    maServiceType: serviceType,
    maPlanType: planType,
    maPatientType: patientType,
    maAllAmount: planTotalAmount,
    maInPatientAmount: inPatientAmount,
    maOutPatientAmount: outPatientAmount,
    maValidityPeriod: validityPeriod,
    // maValidityFrom: moment.utc(validityStart).format("YYYY-MM-DD"),
    maValidityFrom: moment(istDate(validityStart)).format("YYYY-MM-DD"),

    // maValidityto: moment.utc(validityEnd).format("YYYY-MM-DD"),
    maValidityto: moment(istDate(validityEnd)).format("YYYY-MM-DD"),

    maTotalDevicesCount:
      ((packageDevicesCount as number) ?? 0) +
      ((paidDevicesCount as number) ?? 0),
    maPackageDevicesCount: packageDevicesCount,
    maPaidDevicesCount: paidDevicesCount,
    maPackageDevices: managementAssistantData?.packageDevices,
    maPaidDevices: managementAssistantData?.paidDevices,
  };

  const clinicalAssistantObject = {
    CLaddOnService: clinicalAssistantData?.addOnServices,
    clServiceType: clinicalPlanInfoData?.serviceType,
    clPlanType: clinicalPlanInfoData?.planType,
    clPatientType: clinicalPlanInfoData?.patientType,
    clAllAmount: clinicalPlanInfoData?.planTotalAmount,
    clInPatientAmount: clinicalPlanInfoData?.inPatientAmount,
    clOutPatientAmount: clinicalPlanInfoData?.outPatientAmount,
    clValidityPeriod: clinicalPlanInfoData?.validityPeriod,
    // clValidityFrom: moment
    //   .utc(clinicalPlanInfoData?.validityStart)
    //   .format("YYYY-MM-DD"),
    clValidityFrom: moment(istDate(clinicalPlanInfoData?.validityStart)).format(
      "YYYY-MM-DD"
    ),
    // clValidityto: moment
    //   .utc(clinicalPlanInfoData?.validityEnd)
    //   .format("YYYY-MM-DD"),
    clValidityto: moment(istDate(clinicalPlanInfoData?.validityEnd)).format(
      "YYYY-MM-DD"
    ),
    clTotalDevicesCount:
      ((clinicalPlanInfoData?.packageDevicesCount as number) ?? 0) +
      ((clinicalPlanInfoData?.paidDevicesCount as number) ?? 0),
    clPackageDevicesCount: clinicalPlanInfoData?.packageDevicesCount,
    clPaidDevicesCount: clinicalPlanInfoData?.paidDevicesCount,
    clPackageDevices: clinicalAssistantData?.packageDevices,
    clPaidDevices: clinicalAssistantData?.paidDevices,
  };

  return { managementAssistantObject, clinicalAssistantObject };
};

export const CostDetailsObject = (data: any) => {
  const { totalPaidDevicesCost, instalments, securityDeposit, oneTimeCost } =
    data?.deviceCost || {};
  if (data) {
    const costDetailsObject = {
      deviceCost: totalPaidDevicesCost,
      instalments: instalments,
      securityDeposit: securityDeposit,
      onetimeCost: oneTimeCost,
    };
    return costDetailsObject;
  }
  return null;
};

export const HospitalOnboardingObject = (data: any) => {
  const { name, phone, email, userName, password, userId } =
    data?.hospitalAdminInfo || {};
  const hospitalOnboardingObject = {
    hospitalID: data?.hospitalInfo?.code,
    hospitalAdminManagementName: name,
    hospitalAdminManagementMobileNumber: phone,
    hospitalAdminManagementEmail: email,
    userID: userName,
    userPassword: password,
    hospitalUserId: userId,
  };
  return hospitalOnboardingObject;
};

export const managementAssistantListData = (data: any) => {
  const managementAssistantList: any = [];
  const seenServiceIds = new Set();

  data?.data?.data?.forEach((item: any) => {
    if (
      item.productId === 1000 &&
      [1000, 1001, 1002].includes(item.serviceId)
    ) {
      if (!seenServiceIds.has(item.serviceId)) {
        managementAssistantList.push(item);
        seenServiceIds.add(item.serviceId);
      }
    }
  });

  return { managementAssistantList, seenServiceIds };
};

export const clinicalAssistantListData = (data: any) => {
  const clinicalAssistantList = data?.filter(
    (item: any) =>
      item.productId === 1001 && [1003, 1004].includes(item.serviceId)
  );
  return { clinicalAssistantList };
};
