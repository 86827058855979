import "./charts.css";

const TableEquipmentsBar = ({ Equipments, colors, legends }: any) => {
  // const Equipments = [
  //   {
  //     equipmentId: 1000,
  //     name: "Ventilator",
  //     used: 50,
  //     booked: 80,
  //     unused: 70,
  //   },
  //   {
  //     equipmentId: 1001,
  //     name: "Dialyzer",
  //     used: 10,
  //     booked: 20,
  //     unused: 50,
  //   },
  //   {
  //     equipmentId: 1002,
  //     name: "Oxygen Cylinder",
  //     used: 16,
  //     booked: 4,
  //     unused: 80,
  //   },
  // ];

  //   const colors = ["#72CDCC", "#534FA1", "#6CAFE7"];
  //   const legends = ["used", "booked", "unused"];

  const modifiedEquipments = Equipments?.map((equipment: any, i: any) => ({
    equipmentId: equipment.equipmentId,
    name: equipment.name,
    status: [equipment.used, equipment.booked, equipment.unused],
    total: equipment.used + equipment.booked + equipment.unused,
    colors: colors[i],
  }));

  const ProgressBar = ({ percentages }: { percentages: number[] }) => {
    const totalPercentage = percentages.reduce((acc, curr) => acc + curr, 0);

    let currentWidth = 0;
    return (
      <div className="progress-bar-container flex gap-0.5 items-center">
        {percentages?.map((percentage, index) => {
          const width = (percentage / totalPercentage) * 100;
          const style = {
            width: `${width}%`,
            left: `${currentWidth}%`,
            backgroundColor: colors[index],
          };
          currentWidth += width;
          return (
            <div key={index} className="progress" style={style}>
              <span
                className={`progress-label ${
                  index % 2 === 0 ? "text-primary" : "text-white"
                } p-1 text-12 font-semibold`}
              >
                {percentage}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  // const getColor = (index: number) => {
  //   switch (index) {
  //     case 0:
  //       return "#72CDCC";
  //     case 1:
  //       return "#534FA1";
  //     case 2:
  //       return "#6CAFE7";
  //     default:
  //       return "black";
  //   }
  // };
  return (
    <div className="w-[550px]">
      {modifiedEquipments?.map((item: any, i: any) => (
        <div className="grid grid-cols-8 gap-3 py-1" key={i}>
          <div className="whitespace-nowrap col-span-2 text-secondary text-14 font-medium">
            {item.name}
          </div>
          <div className="col-span-4">
            <ProgressBar percentages={item.status} />
          </div>
          <div className="col-span-2">
            <div className="grid grid-cols-3 gap-1">
              <div className="col-span-1 text-primary text-12 font-semibold">
                {item.total}
              </div>
              <div className="col-span-2">
                <span style={{ color: colors[i] }}>●</span>{" "}
                <span className="text-secondary text-14 font-medium"></span>
                {legends[i]}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableEquipmentsBar;
