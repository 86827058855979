import { useEffect, useState } from "react";
import { PasswordInput } from "../components/ui/dashboardForms";
import { useForm, SubmitHandler } from "react-hook-form";
import constants from "../constants";
import { LoginButton } from "../components/ui/loginButton";
import SuccessModal from "../components/modals/successModal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { resetPassword } from "../redux/forgotPassword/forgotPasswordReducer";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../components/ui/loadinSpinner";
import { ResetPasswordFormType } from "../modals/IresetPasswordCard";
import { resetPasswordState } from "../redux/forgotPassword/forgotPasswordSlice";
import {
  isaplhanumeric,
  passwordRegex,
  specialchar,
  upperandlowercase,
} from "../utils/regex";
import { GridCloseIcon } from "@mui/x-data-grid";
import DoneIcon from "@mui/icons-material/Done";
import UserAuditDetails from "../utils/userAuditDetails";
import DetectDevice from "../utils/detectDevice";
import routesName from "../constants/routesName";

const ResetPasswordCard = ({ location }: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resetPasswordData = useSelector((data: any) => data.resetPassword);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordFormType>();
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const deviceType = DetectDevice();

  const onSubmit: SubmitHandler<ResetPasswordFormType> = async (data) => {
    const dispatchData = {
      email: (location?.state?.email)?.toLowerCase(),
      password: data.password,
      appSourceType: "customermanagement",
      moduleType: "login",
      os: operatingSystem,
      ipAddress: ipAddress,
      imeiNumber: "",
      osVersion: osVersion,
      macAddress: "",
      model: "",
      userAction: "",
      device: deviceType,
      auditDate: "2024-03-13T15:28:11.407Z",
    };
    dispatch(resetPassword(dispatchData));
  };

  useEffect(() => {
    if (resetPasswordData.status === "success") {
      setShowModal(true);
    } else if (resetPasswordData?.status === "failed") {
      if (resetPasswordData?.error?.status === 503) {
        setShowModal(true);
      } else {
        setErrorMessage(
          resetPasswordData?.error?.data?.message ?? "Something went wrong"
        );
      }
    }
  }, [resetPasswordData]);

  useEffect(() => {
    return () => {
      dispatch(resetPasswordState());
    };
  }, []);

  const passwordLength = watch("password")?.length

  return (
    <>
      <SuccessModal
        isVisible={ShowModal}
        onClose={() => {
          setShowModal(false);
        }}
        onClick={() => {
          navigate(routesName.cmpHomePage);
          sessionStorage.clear();
        }}
        title="Password Updated Successfully!"
        description="Use your new password to login"
        text="Login"
      />

      <div className="h-screen flex items-center justify-center">
        <div className="bg-white sm:w-[470px] h-auto flex items-center justify-center rounded-lg relative">
          {!ShowModal && (
            <div className="w-[100%]">
              <div className="absolute top-5 right-5">
                <button
                  className=""
                  onClick={() => {
                    navigate(routesName.cmpHomePage);
                  }}
                >
                  <GridCloseIcon />
                </button>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="pt-[40px] pb-[56px] px-[56px]"
              >
                {/* after design dissuction */}
                <div className="pb-8 flex justify-center">
                  <span className="text-[#212121] text-24 font-semibold">
                    {constants.setNewPassword}
                  </span>
                </div>
                <div className="">
                  <PasswordInput
                    label={constants.newpasswordText}
                    id="password"
                    type="password"
                    register={{
                      ...register("password", {
                        required: constants.newpasswordRequired,
                        pattern: {
                          value: passwordRegex,
                          message: "Enter the valid password",
                        },
                        onChange: () => {
                          setErrorMessage("");
                          setError(false);
                        },
                      }),
                    }}
                    errorMessage={errors?.password?.message}
                    error={error || Boolean(errorMessage)}
                  />
                </div>
                <div className="mt-6">
                  <PasswordInput
                    label={constants.confirmNewPassword}
                    id="confirmPassword"
                    type="password"
                    register={{
                      ...register("confirmPassword", {
                        required: constants.confirmNewPasswordRequired,
                        validate: (match) => {
                          const password = getValues("password");
                          return (
                            match === password || "Passwords should match"
                          );
                        },
                        onChange: () => {
                          setErrorMessage("");
                          setError(false);
                        },
                      }),
                    }}
                    errorMessage={errors?.confirmPassword?.message}
                    error={error || Boolean(errorMessage)}
                  />
                </div>

                <div className="grid grid-cols-4 w-[100%] mt-3">
                  {passwordLength >= 1 && (
                    <div
                      className={`h-[5px] ${
                        passwordLength <= 4
                          ? "bg-error"
                          : passwordLength <= 7
                          ? "bg-yellow-300"
                          : "bg-green-300"
                      }   me-1  rounded`}
                    >
                      &nbsp;
                    </div>
                  )}
                  {passwordLength >= 5 && (
                    <div
                      className={`h-[5px] ${
                        passwordLength <= 7
                          ? "bg-yellow-300"
                          : "bg-green-300"
                      }   mx-1  rounded`}
                    >
                      &nbsp;
                    </div>
                  )}
                  {passwordLength > 7 && (
                    <div className="h-[5px]  bg-green-300  mx-1 rounded">
                      &nbsp;
                    </div>
                  )}
                  {passwordLength > 10 && (
                    <div className="h-[5px]  bg-green-300  ms-1 rounded">
                      &nbsp;
                    </div>
                  )}
                </div>
                {passwordLength >= 1 && (
                  <div className="flex justify-end">
                    <span
                      className={`text-14 font-normal ${
                        passwordLength <= 4
                          ? "text-error"
                          : passwordLength <= 7
                          ? "text-yellow-300"
                          : passwordLength <= 9
                          ? "text-green-300 "
                          : "text-green-300 "
                      }`}
                    >
                      {passwordLength <= 4
                        ? "Weak"
                        : passwordLength <= 7
                        ? "Medium"
                        : passwordLength <= 9
                        ? "Good"
                        : "Strong"}
                    </span>
                  </div>
                )}

                <div
                  className={`${
                    errors?.password?.message === "password regex should match"
                      ? "text-red"
                      : ""
                  } my-4`}
                >
                  <div className="pb-2">
                    <span className="text-14 font-semibold text-primary">
                      Password should have atleast:
                    </span>
                  </div>
                  <ul className="font-normal text-14 ps-3">
                    <li>
                      {passwordLength >= 7 &&
                        passwordLength <= 15 && (
                          <DoneIcon style={{ color: "green" }} />
                        )}
                      <span
                        className={
                          passwordLength >= 7 &&
                          passwordLength <= 15
                            ? "ps-0 text-primary"
                            : "ps-[24px] text-error"
                        }
                      >
                        &nbsp;8-15 Characters
                      </span>
                    </li>
                    <li>
                      {isaplhanumeric(watch("password")) && (
                        <DoneIcon style={{ color: "green" }} />
                      )}
                      <span
                        className={
                          isaplhanumeric(watch("password"))
                            ? "ps-0 text-primary"
                            : "ps-[24px] text-error"
                        }
                      >
                        &nbsp;1 Alphabet + 1 Numeric Digit
                      </span>
                    </li>
                    <li>
                      {upperandlowercase(watch("password")) && (
                        <DoneIcon style={{ color: "green" }} />
                      )}
                      <span
                        className={
                          upperandlowercase(watch("password"))
                            ? "ps-0 text-primary"
                            : "ps-[24px] text-error"
                        }
                      >
                        &nbsp;1 Lower + Upper Case
                      </span>
                    </li>
                    <li>
                      {specialchar(watch("password")) && (
                        <DoneIcon style={{ color: "green" }} />
                      )}
                      <span
                        className={
                          specialchar(watch("password"))
                            ? "ps-0 text-primary"
                            : "ps-[24px] text-error"
                        }
                      >
                        &nbsp;One special character !@#$%^&*()_
                      </span>
                    </li>
                  </ul>
                </div>

                {/* <div className="text-center">
                <span className="text-error text-12 font-medium">
                  {errors?.password?.message ??
                    errors?.confirmPassword?.message}
                </span>
              </div> */}
                <div className="text-center">
                  <span className="text-error text-12 font-medium">
                    {errorMessage ?? errorMessage}
                  </span>
                </div>

                <div className="flex justify-center mt-6">
                  <LoginButton
                    name={
                      resetPasswordData?.isLoading ? (
                        <span className="flex items-center gap-2">
                          <LoadingSpinner width="w-4" height="h-4" />
                          Loading...
                        </span>
                      ) : (
                        "Submit"
                      )
                    }
                    width="w-[100%]"
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPasswordCard;
