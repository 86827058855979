import { useEffect, useState } from "react";
import enums from "../constants/enums";
import { StatusCard } from "../features/hospitalManagement/hospitalManagement/statusCard";
import InnerLayout from "../layout/innerLayout";
import { useAppDispatch } from "../redux/store";
import { getDevicePageDetails } from "../redux/devices/deviceManagmentReducer";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import routesName from "../constants/routesName";

const Devices = ({ children }: any) => {
  const navigate = useNavigate();
  const [hocstatus, setHocstatus] = useState("active");
  const dispatch = useAppDispatch();
  let location = useLocation();

  useEffect(() => {
    if (
      location?.pathname?.toString() === `${routesName.devicesPage}/alldevice`
    ) {
      setHocstatus(`active`);
    } else if (
      location?.pathname?.toString() === `${routesName.devicesPage}/linkdevice`
    ) {
      setHocstatus(`linked`);
    } else if (
      location?.pathname?.toString() === `${routesName.devicesPage}/unlinkdevice`
    ) {
      setHocstatus(`Unlinked`);
    }
  }, [location]);

  const getDevicecount = useSelector(
    (data: any) => data.getDevicePageDetailsReducer
  );

  useEffect(() => {
    dispatch(getDevicePageDetails());
  }, []);

  const route = (route: string) => {
    navigate(`${route}`);
  };

  return (
    <>
      <InnerLayout title="Devices">
        <div className="px-6">
          <div className="grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-9 gap-6">
            <div className="md:col-span-2 lg:col-span-3 ">
              <StatusCard
                status="Total"
                count={getDevicecount?.data?.data?.totalDeviceCount}
                value={enums.active}
                active="Active"
                hosStatus={hocstatus}
                onClick={() => route(`${routesName.devicesPage}/alldevice`)}
                loading={getDevicecount.isLoading}
                localstorageboolean={true}
              />
            </div>
            <div className="md:col-span-2 lg:col-span-3 ">
              <StatusCard
                status="Linked"
                count={
                  getDevicecount?.data?.data?.linkedDeviceCounts?.totalCount
                }
                value={"linked"}
                paidCount={
                  getDevicecount?.data?.data?.linkedDeviceCounts
                    ?.packagedDeviceCount
                }
                pilotCount={
                  getDevicecount?.data?.data?.linkedDeviceCounts
                    ?.paidDeviceCount
                }
                devices
                active={enums.active}
                hosStatus={hocstatus}
                onClick={() => route(`${routesName.devicesPage}/linkdevice`)}
                loading={getDevicecount.isLoading}
                localstorageboolean={true}
              />
            </div>
            <div className="md:col-span-2 lg:col-span-3 ">
              <StatusCard
                status="Unlinked"
                count={getDevicecount?.data?.data?.unlinkedDeviceCount}
                value={"Unlinked"}
                active={"enums.active"}
                hosStatus={hocstatus}
                onClick={() => route(`${routesName.devicesPage}/unlinkdevice`)}
                loading={getDevicecount.isLoading}
                localstorageboolean={true}
              />
            </div>
          </div>
          <div className="bg-white mt-5 rounded-lg shadow-sm1 pb-14">
            {children}
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default Devices;
