import { TableBody, TableCell, TableRow } from "@mui/material";
import DynamicTable from "../../../components/tables/dynamicTable";
import { DateProgressBar } from "../../../components/hospitalManagement/dateProgressBar";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import routesName from "../../../constants/routesName";
import { useAppDispatch } from "../../../redux/store";
import { getHospitalById } from "../../../redux/addHospital/hospitalManagementReducer";
import successToast from "../../../utils/successToast";
import errorToast from "../../../utils/errorToast";
import { LoadingSpinner } from "../../../components/ui/loadinSpinner";
import { removeInstalment, removeUnderScore } from "../../../helper";
import StatusWithColor from "../../../utils/StatusWithColor";
import enums from "../../../constants/enums";
import { PrimaryButton } from "../../../components/buttons/buttons";
import ColumnSettingBtn from "../../../components/columnSetting/columnSettingBtn";
import ColumnSetting from "../../../components/columnSetting/columnSetting";
import { TableFilterData } from "../../../modals/Itables";
import useDisplayToggle from "../../../utils/useDisplayToggle";
import ConfirmationModal from "../../../components/modals/confirmationModal";
import DeleteIcon from "../../../assests/icons/deleteIcon.png";
import useAxios from "../../../hooks/useAxios";
import { hospitalApi } from "../../../jsonData/apiPathData/hospital";
import { EditIcon } from "../../../components/muiIconsImport";
import { formatIndianRupee } from "../../../utils/formatIndianRupee";
import { ActionTooltip } from "../../../utils/toolTip";

const HosPlanInformation = ({ hospitalDetailsRes }: any) => {
  const styles = {
    // borderLeft: "1px solid #F2F4F7",
    borderRight: "1px solid #F2F4F7",
    borderBottom: "1px solid #F2F4F7",
    background: "white",
    minWidth: "150px",
    maxWidth: "150px",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#667085",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
    height: "70px",
  };
  const currentDate = new Date();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<TableFilterData>({});
  const [productId, setProductId] = useState<number>();
  const [action, setAction] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const hospitalId = hospitalDetailsRes?.data?.data?.hospitalId;
  const [clinicalAssitantStatus, setClinicalAssitantStatus] = useState(false);
  const [managmentAssitantStatus, setManagmentAssitantStatus] = useState(false);

  const productAndPlanInfo =
    hospitalDetailsRes?.data?.data?.productandPlanInfos;
  const costDetailsInfo = hospitalDetailsRes?.data?.data?.deviceCost;

  const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
  const [isMoreOption, setisMoreOption] = useState<any>(false);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisMoreOption,
  });

  useEffect(() => {
    if (isMoreOption === false) {
      setMoreOption(null);
    }
  }, [isMoreOption]);

  const toogle = (index: number) => {
    if (moreOption === index) {
      setisMoreOption(false);
      setMoreOption(null);
    } else {
      setMoreOption(index);
      setisMoreOption(true);
    }
  };
  const editHospitalFn = () => {
    navigate(
      `${routesName.hospitalManagementPage}/edit-product-plan/${hospitalId}`
    );
  };

  // checking for cilincial assistant is active or not
  useEffect(() => {
    const clinicalAssitStatus =
      productAndPlanInfo?.find(
        (item: any) => item?.productInfo?.productId === 1001
      )?.planInfo?.isActive === 1;
    setClinicalAssitantStatus(clinicalAssitStatus);
    const managementAssitStatus =
      productAndPlanInfo?.find(
        (item: any) => item?.productInfo?.productId === 1000
      )?.planInfo?.isActive === 1;
    setManagmentAssitantStatus(managementAssitStatus);
  }, [productAndPlanInfo]);

  const hospitalActiveDeactivateFn = (productId: number, action: string) => {
    if (productId === 1000 && clinicalAssitantStatus) {
      errorToast(
        "You can't deactivate Management Assistant while Clinical Assistant is still active"
      );
    } else if (productId === 1001 && !managmentAssitantStatus) {
      errorToast(
        "You can't activate Clinical Assistant while Management Assistant is deactive"
      );
    } else {
      setProductId(productId);
      setAction(action);
      setIsShowModal(true);
    }
  };
  // activate deactivate product axios call
  const {
    data: activateOrDeactivateSuccess,
    error: activateOrDeactivateError,
    loading: activateOrDeactivateLoading,
    postFn: activateOrDeactivateFn,
    toggle,
  } = useAxios({
    method: "post",
    path: `${
      hospitalApi.activateOrDeactivate
    }hospitalid=${hospitalId}&productid=${productId}&isactive=${
      action === "Deactivate" ? 0 : 1
    }`,
  });

  useEffect(() => {
    if (activateOrDeactivateSuccess?.success) {
      successToast(activateOrDeactivateSuccess?.message);
      dispatch(getHospitalById(hospitalId));
    } else if (activateOrDeactivateError) {
      errorToast(activateOrDeactivateError?.message ?? "Something Went Wrong");
    }
  }, [activateOrDeactivateSuccess, activateOrDeactivateError, toggle]);

  const {
    labelData,
    selectedValuesOrder,
    filterValues,
    initialCHKVal,
    handleCheckboxChange,
    handleCancelFilter,
    handleApplyFilter,
    handleFilterSearch,
  } = ColumnSetting(headerData, checkBoxData);

  const TblBodyProductPlan = ({ labelData, selectedValuesOrder }: any) => {
    const filteredLabelData = labelData?.filter((item: any) =>
      selectedValuesOrder?.includes(item.value)
    );

    return (
      <TableBody>
        {productAndPlanInfo?.map((item: any, index: any) => {
          const validityEnd = new Date(item?.planInfo?.validityEnd);
          return (
            <React.Fragment key={index}>
              <TableRow key={index}>
                {filteredLabelData?.map((columnValue: any) => {
                  return (
                    <React.Fragment key={`${index}-${columnValue.value}`}>
                      {columnValue.value === "action" && (
                        <TableCell
                          style={{
                            ...styles,
                            minWidth: "80px",
                            maxWidth: "80px",
                            textAlign: "center",
                            left: "0px",
                          }}
                        >
                          <ActionTooltip
                            content={
                              <div
                                className="bg-white absolute left-[-16px] bottom-[-40px] mt-1 ms-12 border z-30 flex flex-col shadow-lg rounded-lg whitespace-nowrap"
                                ref={insideClickRef}
                              >
                                {validityEnd < currentDate &&
                                item?.planInfo?.planType === "per_annum" ? (
                                  <button
                                    type="button"
                                    className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-t-lg rounded-b-lg"
                                    onClick={() => editHospitalFn()}
                                  >
                                    Renew
                                  </button>
                                ) : (
                                  <>
                                    {item?.planInfo?.isActive === 1 && (
                                      <button
                                        type="button"
                                        className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                                        onClick={() => editHospitalFn()}
                                      >
                                        Edit
                                      </button>
                                    )}
                                    {item?.planInfo?.isActive === 1 && (
                                      <button
                                        type="button"
                                        className={`p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg ${
                                          validityEnd > currentDate && ""
                                        } `}
                                        onClick={() =>
                                          hospitalActiveDeactivateFn(
                                            item?.productInfo?.productId,
                                            "Deactivate"
                                          )
                                        }
                                      >
                                        {activateOrDeactivateLoading ? (
                                          <LoadingSpinner
                                            width="w-4"
                                            height="h-4"
                                          />
                                        ) : (
                                          "Deactivate"
                                        )}
                                      </button>
                                    )}
                                    {item?.planInfo?.isActive === 0 && (
                                      <>
                                        <button
                                          type="button"
                                          className={`p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-t-lg  ${
                                            validityEnd > currentDate && ""
                                          } `}
                                          onClick={() => editHospitalFn()}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          type="button"
                                          className={`p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-b-lg ${
                                            validityEnd > currentDate && ""
                                          } `}
                                          onClick={() =>
                                            hospitalActiveDeactivateFn(
                                              item?.productInfo?.productId,
                                              "Activate"
                                            )
                                          }
                                        >
                                          Reactivate
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            }
                          >
                            <button
                              className="text-blue-300 w-[24px] h-[24px]"
                              type="button"
                              onClick={() => toogle(index)}
                              ref={onClickRef}
                            >
                              <MoreVertIcon />
                            </button>
                          </ActionTooltip>
                        </TableCell>
                      )}
                      {columnValue.value === "products" && (
                        <TableCell style={{ ...styles, minWidth: "250px" }}>
                          <span> {item?.productInfo?.name}</span>
                          <div className="mt-3">
                            {item?.planInfo?.isActive === 1 ? (
                              <StatusWithColor value={enums.active} />
                            ) : (
                              <StatusWithColor value={enums.deactivated} />
                            )}
                          </div>
                        </TableCell>
                      )}
                      {columnValue.value === "add_on_services" && (
                        <TableCell style={{ ...styles, minWidth: "200px" }}>
                          {item?.addOnServices?.length <= 0 && "-"}
                          {item?.addOnServices?.map((ser: any, index: any) => {
                            return (
                              <span key={index}>
                                {ser?.serviceName}
                                {index !== item.addOnServices.length - 1 &&
                                  ", "}
                              </span>
                            );
                          })}
                        </TableCell>
                      )}
                      {columnValue.value === "devices" && (
                        <TableCell
                          style={{
                            ...styles,
                            minWidth: "200px",
                            maxWidth: "200px",
                          }}
                        >
                          {item?.packageDevices?.length > 0
                            ? `Package : ${item?.packageDevices?.length + " "}`
                            : `Package : Nill `}
                          |
                          {item?.paidDevices?.length > 0 ? (
                            <span className="ps-1">
                              <span className="text-blue-300">Paid :</span>{" "}
                              {item?.paidDevices?.length}
                            </span>
                          ) : (
                            <span className="ps-1">
                              <span className="text-blue-300">Paid :</span> Nill
                            </span>
                          )}
                        </TableCell>
                      )}
                      {columnValue.value === "service_type" && (
                        <TableCell style={styles}>
                          {item?.planInfo?.serviceType
                            ? removeUnderScore(item?.planInfo?.serviceType)
                            : "-"}
                        </TableCell>
                      )}
                      {columnValue.value === "plan_type" && (
                        <TableCell style={styles}>
                          {item?.planInfo?.planType
                            ? removeUnderScore(item?.planInfo?.planType)
                            : "-"}
                        </TableCell>
                      )}
                      {columnValue.value === "patient_type" && (
                        <TableCell style={styles}>
                          {item?.planInfo?.patientType
                            ? removeUnderScore(item?.planInfo?.patientType)
                            : "-"}
                        </TableCell>
                      )}
                      {columnValue.value === "price" && (
                        <TableCell style={styles}>
                          {item?.planInfo?.patientType === "all"
                            ? item?.planInfo?.planTotalAmount === 0
                              ? "Free"
                              : formatIndianRupee(
                                  item?.planInfo?.planTotalAmount
                                )
                            : item?.planInfo?.patientType === "in_patient"
                            ? item?.planInfo?.inPatientAmount === 0
                              ? "Free"
                              : formatIndianRupee(
                                  item?.planInfo?.inPatientAmount
                                )
                            : item?.planInfo?.patientType === "out_patient"
                            ? item?.planInfo?.outPatientAmount === 0
                              ? "Free"
                              : formatIndianRupee(
                                  item?.planInfo?.outPatientAmount
                                )
                            : "Free"}
                        </TableCell>
                      )}
                      {columnValue.value === "plan_validity" && (
                        <TableCell style={{ ...styles, minWidth: "320px" }}>
                          <div className="flex gap-3 items-center whitespace-nowrap">
                            {item?.planInfo?.planType !== "per_annum" ? (
                              "NA"
                            ) : (
                              <>
                                <DateProgressBar
                                  validityStart={item?.planInfo?.validityStart}
                                  validityEnd={item?.planInfo?.validityEnd}
                                />
                                <span>
                                  Exp. Date:
                                  <span className="text-primary font-semibold">
                                    {moment(item?.planInfo?.validityEnd).format(
                                      "DD MMM YYYY"
                                    )}
                                  </span>
                                </span>
                              </>
                            )}
                          </div>
                        </TableCell>
                      )}
                      {columnValue.value === "security_deposit" && (
                        <TableCell style={styles}>
                          {formatIndianRupee(costDetailsInfo?.securityDeposit)}
                        </TableCell>
                      )}
                      {columnValue.value === "device_cost" && (
                        <TableCell style={styles}>
                          {formatIndianRupee(
                            costDetailsInfo?.totalPaidDevicesCost
                          )}
                        </TableCell>
                      )}
                      {columnValue.value === "instalments" && (
                        <TableCell style={styles}>
                          {removeInstalment(costDetailsInfo?.instalments)}
                        </TableCell>
                      )}
                      {columnValue.value === "one_time_cost" && (
                        <TableCell style={styles}>
                          {formatIndianRupee(costDetailsInfo?.oneTimeCost)}
                        </TableCell>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    );
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isShowModal}
        onClose={() => {
          setIsShowModal(false);
        }}
        onClick={() => {
          activateOrDeactivateFn();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to ${action} this plan?`}
        description=""
        actionText={action}
        isLoading={activateOrDeactivateLoading}
      />
      <div className="mt-4">
        <div className="py-5 bg-white shadow-sm1 rounded-lg">
          <div className="flex items-center gap-4 justify-end px-5">
            <ColumnSettingBtn
              filterValues={filterValues}
              initialCHKVal={initialCHKVal}
              handleCheckboxChange={handleCheckboxChange}
              handleCancelFilter={handleCancelFilter}
              handleApplyFilter={handleApplyFilter}
              handleFilterSearch={handleFilterSearch}
            />
            <div>
              <PrimaryButton
                icon={<EditIcon />}
                name="Edit"
                type="button"
                onClick={() => editHospitalFn()}
                padding="py-3 px-12"
              />
            </div>
          </div>

          <div className="pt-4">
            <DynamicTable
              filter={filter}
              setFilter={setFilter}
              columnData={labelData}
              children={
                <TblBodyProductPlan
                  labelData={labelData}
                  selectedValuesOrder={selectedValuesOrder}
                />
              }
              pagination={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HosPlanInformation;

const headerData = [
  {
    label: "Action",
    value: "action",
    sort: false,
    search: false,
  },
  {
    label: "Products",
    value: "products",
    sort: false,
    search: false,
  },
  {
    label: "Add-on Services",
    value: "add_on_services",
    sort: false,
    search: false,
  },
  {
    label: "Devices",
    value: "devices",
    sort: false,
    search: false,
  },
  {
    label: "Service Type",
    value: "service_type",
    sort: false,
    search: false,
  },
  {
    label: "Plan Type",
    value: "plan_type",
    sort: false,
    search: false,
  },
  {
    label: "Patient Type",
    value: "patient_type",
    sort: false,
    search: false,
  },
  {
    label: "Price",
    value: "price",
    sort: false,
    search: false,
  },
  {
    label: "Plan Validity",
    value: "plan_validity",
    sort: false,
    search: false,
  },
  {
    label: "Security",
    value: "security_deposit",
    sort: false,
    search: false,
  },
  {
    label: "Device Cost",
    value: "device_cost",
    sort: false,
    search: false,
  },
  {
    label: "Instalments",
    value: "instalments",
    sort: false,
    search: false,
  },
  {
    label: "One-time Cost",
    value: "one_time_cost",
    sort: false,
    search: false,
  },
];

export const checkBoxData = [
  {
    label: "Action",
    value: "action",
    display: false,
  },
  {
    label: "Products",
    value: "products",
    display: true,
  },
  {
    label: "Add-on Services",
    value: "add_on_services",
    display: true,
  },
  {
    label: "Devices",
    value: "devices",
    display: true,
  },
  {
    label: "Service Type",
    value: "service_type",
    display: true,
  },
  {
    label: "Plan Type",
    value: "plan_type",
    display: true,
  },
  {
    label: "Patient Type",
    value: "patient_type",
    display: true,
  },
  {
    label: "Price",
    value: "price",
    display: true,
  },
  {
    label: "Plan Validity",
    value: "plan_validity",
    display: true,
  },
  {
    label: "Security Deposit",
    value: "security_deposit",
    display: true,
  },
  {
    label: "Device Cost",
    value: "device_cost",
    display: true,
  },
  {
    label: "Instalments",
    value: "instalments",
    display: true,
  },
  {
    label: "One-time Cost",
    value: "one_time_cost",
    display: true,
  },
];
