import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import { AxiosError } from "axios";
import routesName from "../../constants/routesName";

export const getDevicePageDetails = createAsyncThunk(
  "get/deviceCount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get("/Devices/devices/DeviceCount");
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

//xls upload
export const postDevicesXls = createAsyncThunk(
  "post/devicesxls",
  async (data: any | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `Devices/devices/FileUpload?UserName=${data.userid}`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const postAddDevices = createAsyncThunk(
  "post/adddevice",
  async (data: any | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post(`Devices/devices/Add`, data);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const postUpdateDevices = createAsyncThunk(
  "post/updatedevice",
  async (data: any | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post(`Devices/devices/UpdateDevice`, data);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 500) {
        window.location.href = routesName.errorPage;
      }
      return rejectWithValue(error);
    }
  }
);

export const getAllLinkDevices = createAsyncThunk(
  "get/all-Linked-devices",
  async (
    data: {
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `Devices/devices/LinkedDevice?order=${data.order}&orderBy=${
          data.orderBy
        }${data.searchQuery ?? ""}&page=${data.page + 1}&pageSize=${
          data.rowsPerPage
        }&searchKeywords=${data?.searchKeywords}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }

      return rejectWithValue(error);
    }
  }
);

export const getAllDevices = createAsyncThunk(
  "get/all-devices",
  async (
    data: {
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/Devices/devices/AllDevice?order=${data.order}&orderBy=${
          data.orderBy
        }${data.searchQuery ?? ""}&page=${data.page + 1}&pageSize=${
          data.rowsPerPage
        }&searchKeywords=${data?.searchKeywords}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }

      return rejectWithValue(error);
    }
  }
);
export const getUnLinkDevices = createAsyncThunk(
  "get/all-Un-devices",
  async (
    data: {
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/Devices/devices/UnlinkDevicePaginated?order=${data.order}&orderBy=${
          data.orderBy
        }${data.searchQuery ?? ""}&page=${data.page + 1}&pageSize=${
          data.rowsPerPage
        }&searchKeywords=${data?.searchKeywords}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }

      return rejectWithValue(error);
    }
  }
);
