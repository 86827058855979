import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

const ProductServiceCheckBox = ({
  data,
  setValue,
  control,
  name,
  defaultValue,
  disabled = false,
}: any) => {
  const [selectedItems, setSelectedItems] = useState<any>(defaultValue ?? []);

  const handleCheckboxChange = (item: any, isChecked: any) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter(
          (selectedItem: any) => selectedItem.serviceId !== item.serviceId
        )
      );
    }
  };
  useEffect(() => {
    const selectedServices = selectedItems?.map(
      ({ serviceId, serviceName, addOnServiceType }: any) => ({
        serviceId,
        serviceName,
        addOnServiceType,
      })
    );
    setValue(name, selectedServices);
  }, [selectedItems]);

  return (
    <>
      {data?.map((item: any) => (
        <div className="flex gap-3" key={item.serviceId}>
          <input
            type="checkbox"
            id={item.serviceName}
            disabled={disabled}
            checked={selectedItems?.some(
              (selectedItem: any) => selectedItem.serviceId === item.serviceId
            )}
            onChange={(e) => {
              handleCheckboxChange(item, e.target.checked);
            }}
            className="checkbox-input"
          />
          <label
            htmlFor={item.serviceName}
            className={`text-14 ${
              selectedItems?.some(
                (selectedItem: any) => selectedItem.serviceId === item.serviceId
              )
                ? "text-primary font-semibold"
                : "text-secondary font-medium"
            }`}
          >
            {item.serviceName}
          </label>
        </div>
      ))}

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div>
            <input
              type="hidden"
              name={name}
              value={JSON.stringify(value) || ""}
            />
          </div>
        )}
      />
    </>
  );
};

export default ProductServiceCheckBox;
