import DashboardCard from "../ui/dashboardCard";
import logo from "../../assests/images/susscess.svg";
import { PrimaryButton, SecondaryButton } from "../buttons/buttons";
import { ClearIcon } from "../muiIconsImport";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onClick?: () => void;
  title?: string;
  description?: string;
  actionText?: string | JSX.Element;
};

const SuccessMessage = ({
  isVisible,
  onClose,
  onClick,
  title,
  description,
  actionText = "Login",
}: ModalProps) => {
  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="p-[20px] flex flex-col items-center text-center w-[550px] relative">
        <div className="absolute top-0 right-0">
          <button className="" type="button" onClick={onClose}>
            <ClearIcon/>
          </button>
        </div>
        <div className="w-[100px] h-[100px]">
          <img src={logo} className="w-[100%]" />
        </div>
        <span className="text-24 text-primary font-semibold mt-6 ">
          {title}
        </span>
        <span className="py-3 px-7 text-16 font-medium text-secondary">
          {description}
        </span>
      </div>
    </DashboardCard>
  );
};

export default SuccessMessage;
