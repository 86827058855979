import { useEffect, useState } from "react";
import {
  DashBoardForminput,
  PasswordInput,
} from "../components/ui/dashboardForms";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginButton } from "../components/ui/loginButton";
import { useNavigate } from "react-router-dom";
import "./features.css";
import routesName from "../constants/routesName";
import constants from "../constants";
import { combinedRegex, emailRegex } from "../utils/regex";
import { jwtDecode } from "jwt-decode";
import { useAppDispatch } from "../redux/store";
import { getLogin } from "../redux/loginreducer";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../components/ui/loadinSpinner";
import { LoginFormDataType, accessTokenType } from "../modals/IloginCard";
import axios from "axios";
import { resetLoginState } from "../redux/loginslice";
import logo from "../assests/images/Logo2.png";
import UserAuditDetails from "../utils/userAuditDetails";
import DetectDevice from "../utils/detectDevice";

export default function LoginCard() {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginFormDataType>();
  const dispatch = useAppDispatch();
  const loginData = useSelector((data: any) => data.login);
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const deviceType = DetectDevice();
  const onSubmit: SubmitHandler<LoginFormDataType> = async (data) => {
    const userDetailsValue = watch("userDetails");
    const isEmail = userDetailsValue.match(emailRegex);

    const userData = {
      [isEmail ? "email" : "phone"]: isEmail
        ? userDetailsValue.toLowerCase()
        : userDetailsValue,
      password: watch("password"),
      appSourceType: "customermanagement",
      moduleType: "login",
      os: operatingSystem,
      ipAddress: ipAddress,
      imeiNumber: "",
      osVersion: osVersion,
      macAddress: "",
      model: "",
      userAction: "Login",
      device: deviceType,
      auditDate: "2024-03-13T15:28:11.407Z",
    };
    dispatch(getLogin(userData));
  };

  useEffect(() => {
    if (loginData.status === "success") {
      const decodeToken: accessTokenType = jwtDecode(
        loginData?.userdetails?.accessToken || ""
      );
      if (loginData?.userdetails?.passwordreset === true) {
        navigate(routesName.resetPasswordPage, {
          state: {
            email: decodeToken?.email,
            passwordreset: loginData?.userdetails?.passwordreset,
            accessToken: loginData?.userdetails?.accessToken,
          },
        });
      } else {
        // navigate("/dashBoard");
        sessionStorage.setItem(
          constants.accessTokenText,
          loginData?.userdetails?.accessToken
        );
        sessionStorage.setItem(constants.isLoginLocalStorage, "true");
        axios.defaults.headers[
          "Authorization"
        ] = `Bearer ${loginData?.userdetails?.accessToken}`;
        window.location.reload();
      }
      setEmailError("");
      setPasswordError("");
      setErrorMessage("");
    }
    if (loginData.status === "failed") {
      const emailError =
        loginData?.error?.data?.status === 401
          ? "Email ID/Mobile Number is not registered with us"
          : "";

      const passwordError =
        loginData?.error?.data?.message === "Wrong password"
          ? "Password is wrong"
          : "";

      setEmailError(emailError);
      setPasswordError(passwordError);
      if (!emailError && !passwordError) {
        setErrorMessage(
          loginData?.error?.data?.message ?? "something went wrong"
        );
      }
    }
  }, [loginData]);

  useEffect(() => {
    return () => {
      dispatch(resetLoginState());
    };
  }, []);

  return (
    <>
      <div className="h-screen flex items-center justify-center">
        <div className="bg-white h-auto sm:w-[470px] flex items-center justify-center rounded-lg pt-[40px] pb-[56px] px-[56px]">
          <form onSubmit={handleSubmit(onSubmit)} className="w-[100%]">
            <div className="flex justify-center">
              <img src={logo} className="h-[41px] w-[114px] mb-[50px]" />
            </div>
            <div className="">
              <DashBoardForminput
                label={constants.userNameText}
                id="userDetails"
                type="text"
                register={{
                  ...register("userDetails", {
                    required: constants.userNameRequired,
                    pattern: {
                      value: combinedRegex,
                      message: "Invalid Email ID or Phone Number",
                    },
                    onChange: () => {
                      setEmailError("");
                      setErrorMessage("");
                    },
                  }),
                }}
                errorMessage={errors?.userDetails?.message || emailError}
                error={emailError || errorMessage ? true : false}
              />
            </div>
            <div className="mt-10 ">
              <PasswordInput
                label={constants.passwordText}
                id="password"
                type="password"
                register={{
                  ...register("password", {
                    required: constants.passwordRequired,
                    onChange: () => {
                      setPasswordError("");
                      setErrorMessage("");
                    },
                  }),
                }}
                errorMessage={errors?.password?.message || passwordError}
                error={passwordError || errorMessage ? true : false}
              />
            </div>
            <div className="flex justify-end mt-3">
              <button
                type="button"
                data-testid="forgot_button"
                className="text-12 font-semibold text-blue-300"
                onClick={() => navigate(routesName.forgotPasswordPage)}
              >
                {constants.forgotPasswordText}
              </button>
            </div>
            <div className="text-center">
              <span className="text-error text-12 font-medium">
                {errorMessage}
              </span>
            </div>
            <div className="flex justify-center pt-8">
              <LoginButton
                name={
                  loginData.isLoading ? (
                    <span className="flex items-center gap-2">
                      <LoadingSpinner width="w-4" height="h-4" /> Loading...
                    </span>
                  ) : (
                    constants.loginText
                  )
                }
                width="w-[100%]"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
