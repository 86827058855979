export const inputStyles = {
  select: {
    "& label.Mui-focused": {
      color: "#101828",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "Inter !important",
    },

    "& label": {
      color: "#757B84",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Inter !important",
    },

    "& .MuiInputLabel-root.Mui-error": {
      color: "#D33C52",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Inter !important",
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      color: "#C0C2C6 !important",
      "& fieldset": {
        borderColor: "#C0C2C6 !important",
      },
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#91959D",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#91959D",
      },
      "&:hover fieldset": {
        borderColor: "#322F61",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#322F61",
      },
      "& .MuiSvgIcon-root": {
        color: "#534FA1",
      },
    },
    "& .MuiInputBase-root": {
      color: "#101828",
      fontWeight: 600,
      fontSize: "16px",
      fontFamily: "Inter !important",
      backgroundColor: "#FFFF",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#322F61",
      borderWidth: "1px",
    },
    "& .MuiInputBase-input::placeholder": {
      fontWeight: 400,
    },
    "& .MuiFormHelperText-root": {
      color: "#D33C52 !important",
      fontFamily: "Inter !important",
    },
  },
};

export const selectStyles = {
  "& fieldset": {
    borderColor: "#91959D",
  },
  "& .MuiSelect-select.MuiSelect-select": {
    fontWeight: 600,
    color: "#101828 !important",
    fontSize: "16px",
    borderColor: "#91959D !important",
    fontFamily: "Inter !important",
    backgroundColor: "#FFFF",
  },

  "&.Mui-focused fieldset": {
    borderColor: "#322F61 !important",
  },

  // "&:hover fieldset": {
  //   borderColor: "#322F61 !important",
  // },

  "& .MuiSelect-icon": {
    color: "#534FA1 !important",
  },
  "& .MuiOutlinedInput-input": {
    fontWeight: 600,
    color: "#101828",
    fontSize: "16px",
    fontFamily: "Inter !important",
    backgroundColor: "#FFFF",
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontWeight: 400,
  },
  "& label.Mui-focused": {
    color: "#101828",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Inter !important",
  },
};
