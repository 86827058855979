import {
  MailOutlineIcons,
  PersonIcon,
  PhoneEnabledIcons,
} from "../muiIconsImport";
import DashboardCard from "../ui/dashboardCard";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  managementInfo?: any;
  accountManagerInfo?: any;
  hospitalAdminInfo?: any;
};
const AccountManagerViewAllModal = ({
  isVisible,
  onClose,
  managementInfo,
  accountManagerInfo,
  hospitalAdminInfo,
}: ModalProps) => {
  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="p-5">
        <div className="flex justify-between ">
          <span className="text-primary text-18 font-semibold">
            Hospital Management
          </span>
          <button
            type="button"
            className="text-blue-300 font-bold text-14"
            onClick={onClose}
          >
            View Less
          </button>
        </div>

        <hr className="mt-2 mb-4" />
        {managementInfo?.map((item: any) => (
          <div className="grid grid-cols-3 gap-3 items-center overflow-x-auto scroll_bar whitespace-nowrap text-secondary text-14 font-medium">
            <div className="col-span-1">
              <span className="border-r-2 pe-5 flex items-center gap-2 ">
                <PersonIcon />
                {item?.name}
              </span>
            </div>
            <div className="col-span-1">
              <span className="border-r-2 px-5 flex items-center gap-2">
                <PhoneEnabledIcons />
                +91 {item?.phone}
              </span>
            </div>
            <div className="col-span-1">
              <span className="ps-5 flex items-center gap-2">
                <MailOutlineIcons />
                {item?.email}
              </span>
            </div>
          </div>
        ))}

        <div className="grid grid-cols-3 gap-3 items-center overflow-x-auto scroll_bar whitespace-nowrap text-secondary text-14 font-medium mt-4">
          <div className="col-span-1">
            <span className="border-r-2 pe-5 flex items-center gap-2 ">
              <PersonIcon />
              {hospitalAdminInfo?.name}
            </span>
          </div>
          <div className="col-span-1">
            <span className="border-r-2 px-5 flex items-center gap-2">
              <PhoneEnabledIcons />
              +91 {hospitalAdminInfo?.phone}
            </span>
          </div>
          <div className="col-span-1">
            <span className="ps-5 flex items-center gap-2">
              <MailOutlineIcons />
              {hospitalAdminInfo?.email}
            </span>
          </div>
        </div>
      </div>
    </DashboardCard>
  );
};

export default AccountManagerViewAllModal;
