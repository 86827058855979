import { useEffect, useState } from "react";
export const SearchQuery = ({ filter }: any) => {
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData: any = {};

  for (const key in filter) {
    if (filter[key]) {
      filteredData[key] = filter[key];
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const key = Object.keys(filteredData)[0];
      const value = Object.values(filteredData)[0];
      if (key && value) {
        setSearchQuery(`&field=${key}&value=${value}`);
      } else {
        setSearchQuery("");
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [Object.keys(filteredData)[0], Object.values(filteredData)[0]]);
  return searchQuery ? `${searchQuery}` : "";
};
