import React from "react";

export const linkedDevicesDataFormatter = (data: any) => {
  const devicesData = data?.map((item: any) => {
    const paidDevicesCount = item?.productandPlanInfos?.flatMap(
      (product: any) => product?.paidDevices
    )?.length;
    const packageDevicesCount = item?.productandPlanInfos?.flatMap(
      (product: any) => product?.packageDevices
    )?.length;
    const totalDevicesCount = paidDevicesCount + packageDevicesCount;
    const devices = item?.productandPlanInfos?.flatMap((product: any) => {
      const paidDevices = product.paidDevices.map((device: any) => ({
        productId: product?.productInfo?.productId,
        deviceType: "Paid",
        hospitalIdmain: item?.hospitalId,
        ...device,
      }));
      const packageDevices = product?.packageDevices?.map((device: any) => ({
        productId: product?.productInfo?.productId,
        deviceType: "Package",
        hospitalIdmain: item?.hospitalId,
        ...device,
      }));
      return [...paidDevices, ...packageDevices];
    });

    return {
      hospitalName: item?.hospitalInfo?.name,
      logoURL: item?.hospitalInfo?.logoURL,
      code: item?.hospitalInfo?.code,
      status: item?.hospitalInfo?.status,
      productStatus: item?.hospitalInfo?.productStatus,
      location: item?.hospitalInfo?.city,
      devicesCount: {
        total: totalDevicesCount,
        paid: paidDevicesCount,
        package: packageDevicesCount,
      },
      devices: devices,
      deviceCost: item?.deviceCost?.totalPaidDevicesCost,
      instalment: item?.deviceCost?.instalments,
      securityDeposit: item?.deviceCost?.securityDeposit,
    };
  });
  return devicesData;
};
