import { useEffect, useState } from "react";
import RadioForm from "../../components/forms/radioForm";
import CustomAccordion from "../../components/ui/accordion";
import ProductActivationDevices from "../../features/hospitalManagement/devices";
import SelectForm from "../../components/forms/selectForm";
import { serviceTypeData, validityData } from "../../jsonData/addHospitalsData";
import { AmountInputForm } from "../../components/forms/inputForm";
import { SelectChangeEvent } from "@mui/material";
import DataPickForm from "../../components/forms/dataPickForm";
import moment from "moment";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useSelector } from "react-redux";
import ProductServiceCheckBox from "../../components/hospitalManagement/productServiceCheckBox";
import { labelObject } from "../../helper";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { managementAssistantListData } from "./dataformatter";
import { ThemeProvider } from "@mui/material/styles";
import { dateErrorTheme } from "../../components/themeProvider";

const ManagementAssistant = ({
  control,
  setValue,
  watch,
  previousDataPA,
  clearErrors,
}: any) => {
  const currentDate = new Date();
  const formatedCurrentDate = moment(currentDate).format("YYYY-MM-DD");
  const prdouctServiceList = useSelector(
    (data: any) => data?.getProductService
  );
  const enumData = useSelector((data: any) => data?.enumData?.data);
  const devicesList: any = useSelector((data: any) => data?.getDevices);

  const planTypeList = labelObject(enumData?.PlanType?.data);
  const patientTypeList = labelObject(enumData?.PatientType?.data);

  const { managementAssistantList } =
    managementAssistantListData(prdouctServiceList);

  const [priceOption, setpriceOption] = useState(
    previousDataPA?.maInPatientAmount && previousDataPA?.maOutPatientAmount
      ? "maIndividual"
      : ""
  );

  const maPatientType = watch("maPatientType");
  const maServiceType = watch("maServiceType");
  const maValidityFrom = watch("maValidityFrom");
  const maValidityPeriod = watch("maValidityPeriod");
  const maInPatientAmount = watch("maInPatientAmount");
  const maOutPatientAmount = watch("maOutPatientAmount");

  useEffect(() => {
    if (maServiceType === "pilot") {
      setValue("maAllAmount", "");
      setValue("maInPatientAmount", "");
      setValue("maOutPatientAmount", "");
    }
  }, [maServiceType]);

  useEffect(() => {
    if (maValidityFrom) {
      let toDateValidityUpdate;
      switch (maValidityPeriod) {
        case "validity_3_months":
          toDateValidityUpdate = moment(maValidityFrom)
            .add(3, "months")
            .format("YYYY-MM-DD");
          break;
        case "validity_6_months":
          toDateValidityUpdate = moment(maValidityFrom)
            .add(6, "months")
            .format("YYYY-MM-DD");
          break;
        case "validity_12_months":
          toDateValidityUpdate = moment(maValidityFrom)
            .add(12, "months")
            .format("YYYY-MM-DD");
          break;
        case "custom":
          toDateValidityUpdate = previousDataPA?.maValidityto ?? "";
          break;
        case "lifetime":
          toDateValidityUpdate = moment(maValidityFrom)
            .add(20, "years")
            .format("YYYY-MM-DD");
          break;
        default:
          toDateValidityUpdate = "";
      }
      setValue("maValidityto", toDateValidityUpdate);
    }
  }, [maValidityFrom, maValidityPeriod]);

  const handleChange = (e: SelectChangeEvent<any>) => {
    if (e.target.value === "all") {
      setValue("maInPatientAmount", "");
      setValue("maOutPatientAmount", "");
      setpriceOption("maBulk");
    }
    if (e.target.value === "in_patient") {
      setValue("maAllAmount", "");
      setValue("maOutPatientAmount", "");
      setpriceOption("");
    }
    if (e.target.value === "out_patient") {
      setValue("maAllAmount", "");
      setValue("maInPatientAmount", "");
      setpriceOption("");
    }
  };

  const handlePlanTypeChange = (e: SelectChangeEvent<any>) => {
    if (e.target.value === "per_patient" || e.target.value === "per_month") {
      setValue("maValidityPeriod", "none");
      setValue("maValidityFrom", "0001-01-01");
      setValue("maValidityto", "0001-01-01");
    } else if (e.target.value === "per_annum") {
      setValue("maValidityPeriod", "");
      setValue("maValidityFrom", formatedCurrentDate);
      setValue("maValidityto", "");
    }
  };

  const displayreportType = watch("MAaddOnService")?.find(
    (item: any) => item.serviceId === 1000
  );

  const handleValidityPeriod = (value: string) => {
    clearErrors("maValidityto");
  };

  const priceOptionList = [
    { value: "maBulk", label: "Bulk" },
    { value: "maIndividual", label: "Individual" },
  ];

  const handlePriceOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setpriceOption(e.target.id);
    setValue("maInPatientAmount", "");
    setValue("maOutPatientAmount", "");
    setValue("maAllAmount", "");
  };

  useEffect(() => {
    if (priceOption === "maIndividual") {
      const maAllAmountValue = +maInPatientAmount + +maOutPatientAmount;
      setValue("maAllAmount", maAllAmountValue === 0 ? "" : maAllAmountValue);
    }
  }, [priceOption, maPatientType, maInPatientAmount, maOutPatientAmount]);

  useEffect(() => {
    if (maServiceType === "paid" && maPatientType === "all") {
      setpriceOption(
        previousDataPA?.maInPatientAmount && previousDataPA?.maOutPatientAmount
          ? "maIndividual"
          : "maBulk"
      );
    } else if (maServiceType === "pilot") {
      setpriceOption("");
    }
  }, [maServiceType, maPatientType]);



  return (
    <div>
      <span className="text-16 text-primary font-semibold">
        {addHospitalName.AddonService}
      </span>

      {prdouctServiceList?.status === "success" ? (
        <div className="flex max-md:flex-col  gap-4 py-4">
          <ProductServiceCheckBox
            data={managementAssistantList}
            setValue={setValue}
            control={control}
            name="MAaddOnService"
            defaultValue={previousDataPA?.MAaddOnService}
          />
        </div>
      ) : (
        <div className="flex justify-center py-2">
          <LoadingSpinner />
        </div>
      )}

      {displayreportType && (
        <div className={`  grid lg:grid-cols-3 md:grid-cols-2 gap-4 my-4`}>
          <div className="flex gap-3 my-3">
            <RadioForm
              label="Report Type"
              id="reportType"
              control={control}
              option1="weekly"
              option2="monthly"
              required={displayreportType ? true : false}
              defaultValue={previousDataPA?.reportType ?? "monthly"}
              className="flex-col"
            />
          </div>
        </div>
      )}

      <div>
        <CustomAccordion
          isBorder={false}
          bgcolor=""
          innerPadding=""
          title="Devices"
          component={
            !devicesList?.isLoading ? (
              <ProductActivationDevices
                control={control}
                watch={watch}
                setValue={setValue}
                totalDevicesCount="maTotalDevicesCount"
                packageDevicesCount="maPackageDevicesCount"
                paidDevicesCount="maPaidDevicesCount"
                packageDevices="maPackageDevices"
                paidDevices="maPaidDevices"
                previousDataPA={previousDataPA}
                clearErrors={clearErrors}
              />
            ) : (
              <div className="flex justify-center py-2">
                <LoadingSpinner />
              </div>
            )
          }
        />

        <div className="my-3">
          <span className="text-16 text-primary font-semibold">
            {addHospitalName.PlanDetails}
          </span>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <SelectForm
              label="Service Type*"
              id="maServiceType"
              options={serviceTypeData}
              control={control}
              defaultValue={previousDataPA?.maServiceType}
            />

            <SelectForm
              label="Plan Type*"
              id="maPlanType"
              options={planTypeList}
              control={control}
              onChange={(e: SelectChangeEvent<any>) => handlePlanTypeChange(e)}
              defaultValue={previousDataPA?.maPlanType}
            />
            <SelectForm
              label={"Patient Type*"}
              id={"maPatientType"}
              options={patientTypeList}
              control={control}
              onChange={(e: SelectChangeEvent<any>) => handleChange(e)}
              defaultValue={previousDataPA?.maPatientType}
            />
          </div>
        </div>

        <div className="my-3">
          <div className="flex gap-5">
            <span className="text-16 text-primary font-semibold">
              {addHospitalName.Price}
            </span>
            {priceOption && (
              <div className="flex gap-3">
                {priceOptionList?.map((item: any) => (
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id={item.value}
                      checked={item.value === priceOption}
                      name="maPriceOption"
                      onChange={(e) => handlePriceOption(e)}
                    />
                    <label
                      className={`font-14 font-medium ${
                        item.value === priceOption
                          ? "text-primary"
                          : "text-secondary"
                      }`}
                      htmlFor={item.value}
                    >
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <AmountInputForm
              label={"All Amount"}
              id="maAllAmount"
              control={control}
              disabled={
                maPatientType === "all" &&
                maServiceType !== "paid" &&
                priceOption === "maBulk"
                  ? true
                  : maPatientType === "all" &&
                    maServiceType !== "pilot" &&
                    priceOption !== "maIndividual"
                  ? false
                  : true
              }
              required={
                maPatientType === "all" &&
                maServiceType === "paid" &&
                priceOption !== "maIndividual"
                  ? true
                  : false
              }
              defaultValue={previousDataPA?.maAllAmount}
              maxLength={50}
            />
            <AmountInputForm
              label={"In-Patient Amount"}
              id="maInPatientAmount"
              control={control}
              disabled={
                priceOption === "maIndividual" &&
                maServiceType === "paid" &&
                maPatientType === "all"
                  ? false
                  : maPatientType === "in_patient" && maServiceType !== "pilot"
                  ? false
                  : true
              }
              required={
                priceOption === "maIndividual" &&
                maServiceType === "paid" &&
                maPatientType === "all"
                  ? true
                  : maPatientType === "in_patient" && maServiceType === "paid"
                  ? true
                  : false
              }
              defaultValue={previousDataPA?.maInPatientAmount}
              maxLength={50}
            />
            <AmountInputForm
              label={"Out-Patient Amount"}
              id="maOutPatientAmount"
              control={control}
              disabled={
                priceOption === "maIndividual" &&
                maServiceType === "paid" &&
                maPatientType === "all"
                  ? false
                  : maPatientType === "out_patient" && maServiceType !== "pilot"
                  ? false
                  : true
              }
              required={
                priceOption === "maIndividual" &&
                maServiceType === "paid" &&
                maPatientType === "all"
                  ? true
                  : maPatientType === "out_patient" && maServiceType === "paid"
                  ? true
                  : false
              }
              defaultValue={previousDataPA?.maOutPatientAmount}
              maxLength={50}
            />
          </div>
        </div>

        <div
          className={`${
            watch("maPlanType") === "per_annum" ? "visible" : "hidden"
          } `}
        >
          <hr />

          <div className="my-3">
            <span className="text-16 text-primary font-semibold">
              {addHospitalName.Validity}
            </span>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6 items-center">
              <div className="mt-2">
                <SelectForm
                  label={"Validity Period"}
                  id={"maValidityPeriod"}
                  options={validityData}
                  control={control}
                  onChange={handleValidityPeriod}
                  defaultValue={previousDataPA?.maValidityPeriod}
                  required={watch("maPlanType") === "per_annum" ? true : false}
                />
              </div>
              <ThemeProvider theme={dateErrorTheme}>
                <DataPickForm
                  label={"From"}
                  id="maValidityFrom"
                  control={control}
                  defaultValue={
                    previousDataPA?.maValidityFrom ?? formatedCurrentDate
                  }
                  required={watch("maPlanType") === "per_annum" ? true : false}
                  minDate={moment(new Date())}
                />
              </ThemeProvider>

              <DataPickForm
                label={"To"}
                id="maValidityto"
                control={control}
                disabled={watch("maValidityPeriod") !== "custom" ? true : false}
                required={
                  watch("maValidityPeriod") === "lifetime" ||
                  watch("maPlanType") !== "per_annum"
                    ? false
                    : true
                }
                defaultValue={previousDataPA?.maValidityto}
                minDate={watch("maValidityFrom")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementAssistant;
