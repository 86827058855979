import LoginCard from "../features/loginCard";
import "./pages.css";

export default function Login() {
  return (
    <div className="login_page">
      <LoginCard />
    </div>
  );
}
