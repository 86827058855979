import { useEffect, useRef, useState } from "react";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import {
  EmailInputForm,
  InputForm,
  PhoneNumInputForm,
} from "../../components/forms/inputForm";
import AutoGeneratePassword from "../../components/autoGeneratePassword";
import { Emailicons, PhoneEnabledIcons } from "../../components/muiIconsImport";

const EditHospitalOnboard = ({
  control,
  setValue,
  previousData,
  watch,
  clearErrors,
  formError,
}: any) => {
  const hospitalUserIdRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState({
    hospitalId: true,
    userId: true,
    password: true,
  });

  // create hospital user id (for time being this option is not in use)
  // const handleCreateUserID = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const email = watch("hospitalAdminManagementEmail");
  //   if (email) {
  //     dispatch(createHospitalUserId(email));
  //   } else {
  //     // unchecking the radio button if api fails
  //     if (hospitalUserIdRef.current) {
  //       hospitalUserIdRef.current.checked = false;
  //     }
  //     errorToast("Please provide email address to create User ID");
  //   }
  // };
  // const hospitalUserIdRes = useSelector(
  //   (data: any) => data?.createHospitalUserId
  // );
  // useEffect(() => {
  //   // create hospital user id response hnadle
  //   if (hospitalUserIdRes?.status === "success") {
  //     setErrors((prev) => ({
  //       ...prev,
  //       userId: false,
  //     }));
  //     setValue("userID", hospitalUserIdRes?.data?.data);
  //     clearErrors(["userID"]);
  //   } else if (hospitalUserIdRes?.status === "failed") {
  //     setErrors((prev) => ({
  //       ...prev,
  //       userId: true,
  //     }));
  //     if (hospitalUserIdRef.current) {
  //       hospitalUserIdRef.current.checked = false;
  //     }
  //     errorToast("Something went worng wile creating hospital User Id");
  //   }
  // }, [hospitalUserIdRes]);

  // const [createUserIdDisplay, setCreateUserIdDisplay] = useState(false);

  useEffect(() => {
    if (
      previousData?.hospitalAdminManagementMobileNumber !==
        watch("hospitalAdminManagementMobileNumber") &&
      previousData?.hospitalAdminManagementEmail !==
        watch("hospitalAdminManagementEmail")
    ) {
      // setCreateUserIdDisplay(true);
      if (hospitalUserIdRef.current) {
        hospitalUserIdRef.current.checked = false;
      }
      setValue("userID", "");
      setValue("hospitalUserId", 0);
      setErrors((prev) => ({
        ...prev,
        userId: true,
      }));
    } else {
      // setCreateUserIdDisplay(false);
      setValue("userID", previousData?.userID);
    }
  }, [
    watch("hospitalAdminManagementMobileNumber"),
    watch("hospitalAdminManagementEmail"),
  ]);

  return (
    <div>
      <span className="text-16 font-semibold text-primary">
        {addHospitalName.HospitalID}
      </span>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
        <InputForm
          label={"Hospital ID"}
          id="hospitalID"
          control={control}
          disabled={true}
          defaultValue={previousData?.hospitalID}
        />
      </div>
      <hr />
      <div className="mt-5">
        <span className="text-16 font-semibold text-primary">
          {addHospitalName.HospitalManagement}
        </span>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
        <InputForm
          label={"Name"}
          id="hospitalAdminManagementName"
          control={control}
          defaultValue={previousData?.hospitalAdminManagementName}
          maxLength={50}
        />
        <PhoneNumInputForm
          label={"Mobile Number"}
          id="hospitalAdminManagementMobileNumber"
          control={control}
          defaultValue={previousData?.hospitalAdminManagementMobileNumber}
          maxLength={50}
        />
        <EmailInputForm
          label={"Email ID"}
          id="hospitalAdminManagementEmail"
          control={control}
          defaultValue={previousData?.hospitalAdminManagementEmail}
          maxLength={50}
        />
      </div>
      <div className="grid lg:grid-cols-11 md:grid-cols-4 gap-8 py-6">
        <div className="md:pe-8 md:border-r lg:col-span-4 col-span-2">
          <div className="my-3">
            <span className="text-16 font-semibold text-primary">
              {addHospitalName.UserID}
            </span>
          </div>
          <div className="text-14 font-semibold text-secondary pt-3">
            <div className="flex items-center gap-2">
              <span>
                <PhoneEnabledIcons />
              </span>
              <span>{watch("hospitalAdminManagementMobileNumber")}</span>
            </div>

            <div className="ps-4 py-3">or</div>

            <div className="flex items-center gap-2">
              <span>
                <Emailicons />
              </span>
              <span>{watch("hospitalAdminManagementEmail")}</span>
            </div>
          </div>
        </div>

        <div className="lg:col-span-4 col-span-2">
          <AutoGeneratePassword
            name="userPassword"
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            errors={formError}
            required={
              previousData?.hospitalAdminManagementMobileNumber !==
                watch("hospitalAdminManagementMobileNumber") &&
              previousData?.hospitalAdminManagementEmail !==
                watch("hospitalAdminManagementEmail")
                ? true
                : false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EditHospitalOnboard;
