import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import { AxiosError } from "axios";
import routesName from "../../constants/routesName";

// get all hospital Departments
export const getAllDepartmentsList = createAsyncThunk(
  "get/all-department-list",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      fromDate: moment.Moment | null | string;
      toDate: moment.Moment | null | string;
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `CMMIS/cmmis/HospitalListByDepartment?Country=${data.country}&State=${
          data.state
        }&District=${data.district}&Taluk=${data.taluk}&Hobli=${
          data.hobli
        }&fromDate=${data.fromDate}&toDate=${data.toDate}&page=${
          data.page + 1
        }&pageSize=${data.rowsPerPage}&searchKeywords=${
          data?.searchKeywords
        }&order=${data.order}&orderBy=${data.orderBy}${data.searchQuery ?? ""}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

// get all hospital Diagnosis
export const getAllDiagnosisList = createAsyncThunk(
  "get/all-diagnosis-list",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      fromDate: moment.Moment | null | string;
      toDate: moment.Moment | null | string;
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `CMMIS/ccmis/HospitalListByDiagnosis?Country=${data.country}&State=${
          data.state
        }&District=${data.district}&Taluk=${data.taluk}&Hobli=${
          data.hobli
        }&fromDate=${data.fromDate}&toDate=${data.toDate}&page=${
          data.page + 1
        }&pageSize=${data.rowsPerPage}&searchKeywords=${
          data?.searchKeywords
        }&order=${data.order}&orderBy=${data.orderBy}${data.searchQuery ?? ""}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

//new hosptials api

export const getNewHospitalsGraphData = createAsyncThunk(
  "get/new-hospitals-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: number;
      fromDate: moment.Moment | null | string;
      toDate: moment.Moment | null | string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/NewHospitals?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}&fromDate=${data.fromDate}&toDate=${data.toDate}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

//////////////////////

export const getReasonsGraphData = createAsyncThunk(
  "get/reasons-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/cmmis/RegionWiseActiveHospitals?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

/////////////////////////

export const getProductAndPlanGraphData = createAsyncThunk(
  "get/product-and-plan-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/ccmis/ProductAndPlanWiseActiveHospitals?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

/////////////
export const getBedSizeGraphData = createAsyncThunk(
  "get/bed-size-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/ccmis/ActiveHospitalsByBedSize?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

///////////////////////////////
export const getSpecialityGraphData = createAsyncThunk(
  "get/Speciality-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/ccmis/ActiveHospitalsBySpeciality?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const getPatientDetailsDepartmentsGraphData = createAsyncThunk(
  "get/patient-details-departments-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: any;
      fromDate: any;
      toDate: any;
      hospitals: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.post(
        `/CMMIS/cmmis/PatientDetailsDepartments?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}&fromDate=${data.fromDate}&toDate=${data.toDate}`,
        data?.hospitals
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const getPatientDetailsDiagnosisGraphData = createAsyncThunk(
  "get/patient-details-diagnosis-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: any;
      fromDate: any;
      toDate: any;
      hospitals: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.post(
        `/CMMIS/cmmis/PatientDetailsDiagnosis?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}&fromDate=${data.fromDate}&toDate=${data.toDate}`,
        data?.hospitals
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);
///////////////////////////////

export const getCustomersGraphData = createAsyncThunk(
  "get/customers-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: number;
      groupType: any
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/ccmis/ActiveHospitalsByCustomers?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}&groupType=${data?.groupType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);
///////////////////////////////////////
export const getOnboardingSourceGraphData = createAsyncThunk(
  "get/onboarding-source-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/ccmis/ActiveHospitalsByOnboardingSource?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);
/////////////////////////////////////////////////////

export const getActiveHospitalsGraphData = createAsyncThunk(
  "get/active-hospitals-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/cmmis/ActiveHospitals?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);
/////////////////////////////
export const getExpiredAndDeactivatedHospitalsGraphData = createAsyncThunk(
  "get/expired-and-deactivated-hospitals-graph-data",
  async (
    data: {
      country: string;
      state: string;
      district: string;
      taluk: string;
      hobli: string;
      serviceType: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `/CMMIS/cmmis/ExpiredAndDeActivatedHospitals?Country=${data.country}&State=${data.state}&District=${data.district}&Taluk=${data.taluk}&Hobli=${data.hobli}&serviceType=${data.serviceType}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          // window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);
