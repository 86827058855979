import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import routesName from "../../constants/routesName";

export const getOwnershipType = createAsyncThunk(
  "get/ownership-type",
  async (_: any, { rejectWithValue }) => {
    try {
      const response = await API.get("ownership-type");
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const getProductType = createAsyncThunk(
  "get/product-type",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("Products/products");
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const getProductServiceType = createAsyncThunk(
  "get/product-serivce",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("Products/services");
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const getSpecialisationType = createAsyncThunk(
  "get/getspecialisationsType",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("Specialisation/specialisations");
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const getDevicesList = createAsyncThunk(
  "get/devices-list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("Devices/devices");
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const addHospital = createAsyncThunk(
  "post/hospital",
  async (data: any | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `Hospital/hospitals/Add?isSaveAsDraft=${data.isSaveAsDraft}`,
        data.dispatchData
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const updateHospital = createAsyncThunk(
  "update/hospital",
  async (data: any | undefined, { rejectWithValue }) => {
    try {
      const response = await API.put(
        `Hospital/hospital/Update/Id?hospitalid=${data?.id}&isSaveAsDraft=${data.isSaveAsDraft}`,
        data.dispatchData
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// get hospital
export const getAllHospital = createAsyncThunk(
  "get/allHospital",
  async (
    data: {
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `Hospital/hospitals?order=${data.order}&orderBy=${data.orderBy}${
          data.searchQuery ?? ""
        }&page=${data.page + 1}&pageSize=${data.rowsPerPage}&searchKeywords=${
          data?.searchKeywords
        }`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);
export const getHospitalById = createAsyncThunk(
  "get/hospital-by-id",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await API.get(`Hospital/hospital/id?hospitalid=${id}`);
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// delete hospital
export const deleteHospitalById = createAsyncThunk(
  "delete/hospital-by-id",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `Hospital/hospital/delete/id?hospitalid=${id}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// get hospital status count
export const getHospitalByStatus = createAsyncThunk(
  "get/hospital-by-status",
  async (
    data: {
      status: string;
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `Hospital/hospitals/Status?status=${data.status}&order=${
          data.order
        }&orderBy=${data.orderBy}${data.searchQuery ?? ""}&page=${
          data.page + 1
        }&pageSize=${data.rowsPerPage}&searchKeywords=${data?.searchKeywords}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const getActiveHosCount = createAsyncThunk(
  "get/active-hos-count",
  async (status: string, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `Hospital/hospitals/Status/Count?status=${status}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);
export const getExpiredHosCount = createAsyncThunk(
  "get/expired-hos-count",
  async (status: string, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `Hospital/hospitals/Status/Count?status=${status}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);
export const getInactiveHosCount = createAsyncThunk(
  "get/inactive-hos-count",
  async (status: string, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `Hospital/hospitals/Status/Count?status=${status}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);
export const getInprogressHosCount = createAsyncThunk(
  "get/inprogress-hos-count",
  async (status: string, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `Hospital/hospitals/Status/Count?status=${status}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

export const getActivePaidCount = createAsyncThunk(
  "get/active-paid-count",
  async (
    data: { status: string; servicetype: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `Hospital/hospitals/Status/ServiceType/Count?status=${data.status}&servicetype=${data.servicetype}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);
export const getActivePilotCount = createAsyncThunk(
  "get/active-pilot-count",
  async (
    data: { status: string; servicetype: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `Hospital/hospitals/Status/ServiceType/Count?status=${data.status}&servicetype=${data.servicetype}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// image upload
export const posthospitalLogo = createAsyncThunk(
  "post/hospital-logo",
  async (data: any | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post(`UploadImage/uploadimage`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// create hopitalId
export const createHospitalId = createAsyncThunk(
  "create/hospital-id",
  async (data: any | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `Hospital/hospitals/GenerateHospitalId`,
        data
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// create user id
export const createHospitalUserId = createAsyncThunk(
  "create/hospital-user-id",
  async (email: string | undefined, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `Hospital/hospitals/GenerateuserId?email=${email}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// get hospital user passowrd
export const getHospitalUserPassword = createAsyncThunk(
  "get/hospital-user-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(`Hospital/hospitals/GeneratePassword`);
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// product activate or deactivate
export const activateOrDeactivateAction = createAsyncThunk(
  "post/hospital-product-activeOrDeactivate",
  async (
    data: { hospitalId: string; productId: number; isactive: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.post(
        `Hospital/hospitals/hospitalplan/ActivateOrDeActivate?hospitalid=${data.hospitalId}&productid=${data.productId}&isactive=${data.isactive}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// de link devices
export const deLinkDeviceAction = createAsyncThunk(
  "post/hospital-device-delink",
  async (
    data: { hospitalId: string; productId: number; deviceId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.post(
        `Hospital/hospital/DeLinkDevice?hospitalid=${data.hospitalId}&productid=${data.productId}&deviceid=${data.deviceId}`
      );
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);

// get account manager list

export const getAccountAllList = createAsyncThunk(
  "get/account-manager-all-list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get("Hospital/hospital/AccountManagerList");
      return response.data;
    } catch (error: any) {
      // if (error?.response?.status === 500) {
      //   window.location.href = routesName.errorPage;
      // }
      return rejectWithValue(error);
    }
  }
);
