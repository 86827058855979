export const loginColumnData = [
  {
    label: "User",
    value: "customer",
    width: "300px",
  },
  {
    label: "Devices",
    value: "device",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "Model",
    value: "model",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "OS",
    value: "os",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "OS Version",
    value: "osversion",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "IP Address",
    value: "ipaddress",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "IMEI Number",
    value: "imeinumber",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "MAC Address",
    value: "macaddress",
    removeSticky: true,
    width: "160px",
  },
  {
    label: "Last Login",
    value: "auditdate",
    search: false,
    removeSticky: true,
    width: "160px",
  },
];
