import {
  DeleteOutlinedIcons,
  EditIcon,
  LockOpenIcon,
  MailOutlineIcons,
  PersonIcon,
  PhoneEnabledIcons,
  ProfileCardIcon,
  RemovePersonIcon,
} from "../../../components/muiIconsImport";
import enums from "../../../constants/enums";
import StatusWithColor from "../../../utils/StatusWithColor";
import GenderIcon from "../../../assests/icons/gender.svg";
import { useNavigate } from "react-router-dom";
import routesName from "../../../constants/routesName";
import { useEffect, useState } from "react";
import AccountMResetPassword from "../resetPassword";
import DefaultImage from "../../../assests/images/default-profile.jpg";
import { useSelector } from "react-redux";
import {
  activateDeactiveAccManager,
  deleteAccManager,
  getAccontManager,
} from "../../../redux/accountManager/accountManagerReducer";
import { useAppDispatch } from "../../../redux/store";
import successToast from "../../../utils/successToast";
import errorToast from "../../../utils/errorToast";
import {
  resetActivateDeactiveAccManagerState,
  resetDeleteAccManagerState,
  resetResetPasswordAccManagerState,
} from "../../../redux/accountManager/accountManagerSlice";
import ConfirmationModal from "../../../components/modals/confirmationModal";
import DeleteIcon from "../../../assests/icons/deleteIcon.png";
import ActivateDeactivateIcon from "../../../assests/icons/activateDeactivateIcon.svg";
import { AccessTokenDecoder } from "../../../utils/accessTokenDecoder";
import { Tooltip } from "@mui/material";

const AccountManagerProfileCard = ({ accountManagerData }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [resetPassword, setResetPassword] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activateDeactiveModal, setActivateDeactiveModal] = useState(false);
  const userDetails = AccessTokenDecoder();
  const editAccountManagerFn = () => {
    navigate(
      `${routesName.accountManagerPage}${routesName.personalInfoPage}/${enums.edit}`,
      { state: { id: accountManagerData?.userId } }
    );
  };

  const deleteDispatchAccManagerFn = () => {
    dispatch(deleteAccManager(accountManagerData?.userId));
  };
  const deleteAccManagerRes = useSelector(
    (data: any) => data?.deleteAccManager
  );
  useEffect(() => {
    if (deleteAccManagerRes?.status === "success") {
      successToast(deleteAccManagerRes?.data?.message);
      dispatch(resetDeleteAccManagerState());
      navigate(routesName.accountManagerPage);
    } else if (deleteAccManagerRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [deleteAccManagerRes]);

  const activeDeactiveDispatchFn = () => {
    dispatch(
      activateDeactiveAccManager({
        userId: accountManagerData?.userId,
        isActive: accountManagerData?.isActive === 1 ? 0 : 1,
      })
    );
  };

  const activateDeactiveAccManagerRes = useSelector(
    (data: any) => data?.activateDeactiveAccManager
  );

  useEffect(() => {
    if (activateDeactiveAccManagerRes?.status === "success") {
      successToast(activateDeactiveAccManagerRes?.data?.message);
      dispatch(getAccontManager(accountManagerData?.userId));
    } else if (activateDeactiveAccManagerRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [activateDeactiveAccManagerRes]);

  useEffect(() => {
    return () => {
      dispatch(resetActivateDeactiveAccManagerState());
      dispatch(resetDeleteAccManagerState());
      dispatch(resetResetPasswordAccManagerState());
    };
  }, []);

  const editPermission =
    userDetails?.Roles === enums?.SuperAdmin &&
    accountManagerData?.role === enums?.Management;

  return (
    <>
      <AccountMResetPassword
        isVisible={resetPassword}
        onClick={() => {
          deleteDispatchAccManagerFn();
        }}
        onClose={() => {
          setResetPassword(false);
        }}
        data={{
          name: accountManagerData?.name,
          employeeID: accountManagerData?.employeeID,
          photo: accountManagerData?.photo ?? DefaultImage,
          email: accountManagerData?.email,
          userId: accountManagerData?.userId,
          userName: accountManagerData?.userName,
          role: accountManagerData?.role,
        }}
      />
      <ConfirmationModal
        isVisible={activateDeactiveModal}
        onClose={() => {
          setActivateDeactiveModal(false);
        }}
        onClick={() => {
          activeDeactiveDispatchFn();
        }}
        icon={ActivateDeactivateIcon}
        title={`Are you sure you want to ${
          accountManagerData?.isActive === 1 ? "Deactivate" : "Activate"
        }  this Account Manager?`}
        description=""
        actionText={
          accountManagerData?.isActive === 1 ? "Deactivate" : "Activate"
        }
        isLoading={activateDeactiveAccManagerRes?.isLoading}
      />

      <ConfirmationModal
        isVisible={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onClick={() => {
          deleteDispatchAccManagerFn();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Account Manager?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteAccManagerRes?.isLoading}
      />
      <div className="shadow-sm1 border border-gray-250 rounded-lg">
        <div className="bg-white rounded-t-lg">
          <div className="flex gap-4 p-8 items-center">
            <div className="w-[100px] h-[100px] flex items-center">
              <img
                src={
                  accountManagerData?.photo
                    ? accountManagerData?.photo
                    : DefaultImage
                }
                className="border-4 rounded-full border-blue-50 shadow-ex1 object-contain w-[100%] h-[100%]"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="ps-2">
              <div>
                <span className="text-20 font-semibold text-primary">
                  {accountManagerData?.name}
                </span>
                <span className="ps-4">
                  <StatusWithColor
                    value={
                      accountManagerData?.isActive === 1
                        ? enums.active
                        : enums.deactivated
                    }
                  />
                </span>
              </div>
              <div className="flex items-center text-secondary text-14 font-medium pt-3">
                <span className="pe-3 border-r flex items-center gap-1">
                  <ProfileCardIcon styles={{ width: "20px", height: "20px" }} />
                  {accountManagerData?.employeeID}
                </span>
                <span className="px-3 border-r flex items-center gap-1">
                  <PersonIcon styles={{ width: "20px", height: "20px" }} />{" "}
                  {accountManagerData?.role}
                </span>
                <span className="px-3 flex gap-2 items-center">
                  <img src={GenderIcon} width={20} height={20} />{" "}
                  {accountManagerData?.gender}
                </span>
              </div>
              <div className="flex items-center text-secondary text-14 font-medium pt-2">
                <span className="pe-3 border-r flex items-center gap-1">
                  <PhoneEnabledIcons
                    styles={{ width: "20px", height: "20px" }}
                  />
                  <span className="ps-1">+91 {accountManagerData?.phone}</span>
                </span>
                <span className="px-3 flex items-center gap-1">
                  <MailOutlineIcons
                    styles={{ width: "20px", height: "20px" }}
                  />{" "}
                  {accountManagerData?.email}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-purple-50 rounded-b-lg flex gap-3 overflow-y-auto scroll_bar justify-around text-blue-300 text-14 font-semibold py-4">
          <button
            className={`flex gap-2 items-center whitespace-nowrap ${
              editPermission && "cursor-not-allowed"
            }`}
            type="button"
            onClick={() => editAccountManagerFn()}
            disabled={editPermission}
          >
            <Tooltip
              title={
                editPermission ? (
                  <div className="p-1 text-white font-normal text-14">
                    User are not authorized to edit
                  </div>
                ) : (
                  ""
                )
              }
              placement="top"
            >
              <span className="flex gap-2 items-center whitespace-nowrap">
                <EditIcon /> Edit User
              </span>
            </Tooltip>
          </button>
          <button
            className={`flex gap-2 items-center whitespace-nowrap ${
              editPermission && "cursor-not-allowed"
            }`}
            type="button"
            onClick={() => setActivateDeactiveModal(true)}
            disabled={editPermission}
          >
            <Tooltip
              title={
                editPermission ? (
                  <div className="p-1 text-white font-normal text-14">
                    User are not authorized to activate or deactivate
                  </div>
                ) : (
                  ""
                )
              }
              placement="top"
            >
              <span className="flex gap-2 items-center whitespace-nowrap">
                <RemovePersonIcon />{" "}
                {accountManagerData?.isActive === 1
                  ? "Deactivate User"
                  : "Activate User"}
              </span>
            </Tooltip>
          </button>
          <button
            className={`flex gap-2 items-center whitespace-nowrap ${
              editPermission && "cursor-not-allowed"
            }`}
            type="button"
            onClick={() => setResetPassword(true)}
            disabled={editPermission}
          >
            <Tooltip
              title={
                editPermission ? (
                  <div className="p-1 text-white font-normal text-14">
                    User are not authorized to reset password
                  </div>
                ) : (
                  ""
                )
              }
              placement="top"
            >
              <span className="flex gap-2 items-center whitespace-nowrap">
                <LockOpenIcon /> Reset Password
              </span>
            </Tooltip>
          </button>
          <button
            className={`flex gap-2 items-center whitespace-nowrap ${
              editPermission && "cursor-not-allowed"
            }`}
            type="button"
            onClick={() => setDeleteModal(true)}
            disabled={editPermission}
          >
            <Tooltip
              title={
                editPermission ? (
                  <div className="p-1 text-white font-normal text-14">
                    User are not authorized to delete
                  </div>
                ) : (
                  ""
                )
              }
              placement="top"
            >
              <span className="flex gap-2 items-center whitespace-nowrap">
                <DeleteOutlinedIcons /> Delete User
              </span>
            </Tooltip>
          </button>
        </div>
      </div>
    </>
  );
};

export default AccountManagerProfileCard;
