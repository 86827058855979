import { useEffect, useState } from "react";
import Sidebar from "../components/ui/sidebar";
import ModalHeader from "../utils/modalHeader";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import "../components/components.css";
import useScreenSizeDetector from "../utils/useScreenSizeDetector";
import { AccessTokenDecoder } from "../utils/accessTokenDecoder";
import { addListener, removeListener } from "../utils/signalRService";

interface childrenProps {
  children: React.ReactNode;
}

const DefaultLayout = ({ children }: childrenProps) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const logoutFn = () => {
    sessionStorage.clear();
    // navigate("/");
    window.location.reload();
  };
  const handleOnIdle = () => {
    // setIsVisible(true);
    logoutFn();
  };

  const userdetails = AccessTokenDecoder();

  useEffect(() => {
    const handleSignalRData = (message: any) => {
      if (message === parseInt(userdetails.userId)) {
        logoutFn();
      }
    };

    addListener("messageReceived", handleSignalRData);

    return () => {
      removeListener("messageReceived", handleSignalRData);
    };
  }, []);
  const { start } = useIdleTimer({
    timeout: 1 * 60 * 60 * 1000,
    onIdle: handleOnIdle,
    crossTab: true,
    stopOnIdle: true,
    timers: workerTimers,
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { width } = useScreenSizeDetector();

  useEffect(() => {
    if (width <= 1024) {
      setSidebarOpen(true);
    }
  }, [width]);

  return (
    <>
      {isVisible && (
        <ModalHeader>
          <div className="w-[400px] p-5">
            <div className="flex flex-col ">
              <span className="text-2xl pb-4">
                You have been Idle for a while
              </span>
              <span className="py-3 text-lg">You'll be logged out soon</span>

              <div className="flex justify-center gap-3 mt-3">
                <button
                  className="border rounded p-2"
                  onClick={() => {
                    logoutFn();
                  }}
                >
                  Log me out
                </button>
                <button
                  className="border rounded p-2"
                  onClick={() => {
                    setIsVisible(false);
                    start();
                  }}
                >
                  Keep me signed in
                </button>
              </div>
            </div>
          </div>
        </ModalHeader>
      )}
      <div className="h-screen flex">
        <div className={`${sidebarOpen ? "w-[80px]" : "w-[264px]"} bg-white`}>
          <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
        </div>

        <div
          style={{ width: `calc(100% - ${sidebarOpen ? "80px" : "264px"})` }}
        >
          <div className="h-[100vh] overflow-y-scroll scroll_bar border bg-blue-50 ">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
