import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {
  AddCircleOutlineIcons,
  CancelOutlinedIcons,
  CheckCircleIcon,
  Closeicon,
  DeleteOutlinedIcons,
  ErrorMessageIcon,
  RemoveCircleOutlineIcons,
} from "../muiIconsImport";
import { PrimaryButton } from "../buttons/buttons";
import { inputStyles, selectStyles } from "../mui-styles";
import { useSelector } from "react-redux";

interface DeviceData {
  modelName: string;
  serialNumber: string;
  macAddress: string;
  imeiNumber: string;
  deviceId: number;
}

interface DynamicTabsProps {
  deviceCount?: any;
  setValue?: any;
  devicesNames?: any;
  previousDataPA?: any;
  devicesList?: any;
  clearErrors: any;
  disabled?: boolean;
}

const DynamicTabsComponent: React.FC<DynamicTabsProps> = ({
  deviceCount,
  setValue,
  devicesNames,
  previousDataPA,
  devicesList,
  clearErrors,
  disabled = false,
}) => {
  const inputLabelRef = useRef<HTMLLabelElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const [deviceData, setDeviceData] = useState<DeviceData[]>(() =>
    initializedeviceData(0)
  );
  const [activeTab, setActiveTab] = useState<number>(1);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [isDeviceValid, setDeviceValid] = useState<boolean[]>([]);

  function initializedeviceData(numTabs: number): DeviceData[] {
    return Array.from({ length: numTabs }, () => ({
      modelName: "",
      serialNumber: "",
      macAddress: "",
      imeiNumber: "",
      deviceId: +"",
    }));
  }

  useEffect(() => {
    setDeviceData(initializedeviceData(0));
    setActiveTab(1);
  }, []);

  const handleTabChange = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const handleInputChange = (field: string, value: string) => {
    setDeviceData((prevData) => {
      const updatedData = [...prevData];
      updatedData[activeTab - 1] = {
        ...updatedData[activeTab - 1],
        [field]: value,
      };

      if (field === "serialNumber") {
        const selectedSerial = devicesList?.find(
          (item: any) => item.serialNumber === value
        );
        if (selectedSerial) {
          updatedData[activeTab - 1] = {
            ...updatedData[activeTab - 1],
            modelName: selectedSerial.modelName,
            macAddress: selectedSerial.macAddress,
            imeiNumber: selectedSerial.imeiNumber,
            deviceId: selectedSerial.deviceId,
          };
        }
      }

      return updatedData;
    });
    clearErrors([devicesNames]);
  };

  const validateFields = () => {
    const errors: { [key: string]: string } = {};
    const isValid: boolean[] = new Array(deviceData?.length).fill(true);

    if (Array.isArray(deviceData)) {
      deviceData?.forEach((person, index) => {
        if (!person?.modelName) {
          errors[`modelName_${index + 1}`] = "Mobile Model is required";
          isValid[index] = false;
        }
        if (!person?.serialNumber) {
          errors[`serialNumber_${index + 1}`] = "Serial Number is required";
          isValid[index] = false;
        }
        if (!person?.macAddress) {
          errors[`macAddress_${index + 1}`] = "MAC Address is required";
          isValid[index] = false;
        }
        if (!person?.imeiNumber) {
          errors[`imeiNumber_${index + 1}`] = "IMEI Number is required";
          isValid[index] = false;
        }
      });
      setDeviceValid(isValid);
    } else {
      setDeviceValid([]);
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleRemoveTab = (tabNumber: number) => {
    setDeviceData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(tabNumber - 1, 1);
      return updatedData;
    });
    setActiveTab(tabNumber - 1 === 0 ? activeTab : activeTab - 1);
  };

  const handleAddDevice = () => {
    if ((deviceData as any) === "noDevices") {
      setDeviceData((prevData) => [initializedeviceData(1)[0]]);
    } else {
      setDeviceData((prevData) => [...prevData, initializedeviceData(1)[0]]);
      setActiveTab(deviceData?.length + 1);
    }
  };

  const dispatchFormData = () => {};

  useEffect(() => {
    if (validateFields()) {
      dispatchFormData();
    }

    if ((deviceData as any) === "noDevices") {
      setValue(deviceCount, 0);
    } else setValue(deviceCount, deviceData?.length);

    const value = Object.keys(validationErrors).length >= 1 ? "" : deviceData;

    if (deviceData?.length >= 1) {
      setValue(devicesNames, value);
    } else {
      setValue(devicesNames, "noDevices");
    }
  }, [deviceData, Object.keys(validationErrors).length]);

  useEffect(() => {
    setValue(deviceCount, previousDataPA?.[deviceCount]);
    setValue(
      devicesNames,
      previousDataPA?.[devicesNames] === "noDevices"
        ? []
        : previousDataPA?.[devicesNames] ?? []
    );
    setDeviceData(previousDataPA?.[devicesNames] ?? []);
  }, []);

  const renderFields = (tabNumber: number) => {
    const currentDeviceData = deviceData[tabNumber - 1] || {
      modelName: "",
      serialNumber: "",
      macAddress: "",
      imeiNumber: "",
    };

    return (
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 my-4">
        <div className="">
          <TextField
            fullWidth
            label={`Model`}
            value={currentDeviceData?.modelName}
            onChange={(e) => handleInputChange("modelName", e.target.value)}
            type="text"
            placeholder={`Model ${tabNumber}`}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            disabled={true}
            sx={inputStyles.select}
          />
        </div>
        <div>
          <FormControl fullWidth>
            <InputLabel
              id={"serialNumber"}
              style={{
                color: isFocused ? "#322F61" : "#757B84",
                fontSize: "16px",
                fontFamily: "Inter",
              }}
              ref={inputLabelRef}
            >
              {"Serial Number"}
            </InputLabel>
            <Select
              defaultValue={currentDeviceData?.serialNumber}
              value={currentDeviceData?.serialNumber}
              disabled={disabled}
              label={"Serial Number"}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) =>
                handleInputChange("serialNumber", e.target.value)
              }
              sx={selectStyles}
            >
              <MenuItem value="" disabled>
                Select Serial Number
              </MenuItem>
              {devicesList?.map((option: any, index: number) => (
                <MenuItem
                  className={`${index === hoveredIndex ? "hovered" : ""} ${
                    option.value === currentDeviceData?.serialNumber
                      ? "backgroundColor"
                      : ""
                  }`}
                  key={option.serialNumber}
                  value={option.serialNumber}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  {option.serialNumber}
                </MenuItem>
              ))}
              {/* Render the value even if it's not in devicesList */}
              {!devicesList?.some(
                (option: any) =>
                  option.serialNumber === currentDeviceData?.serialNumber
              ) && (
                <MenuItem value={currentDeviceData?.serialNumber}>
                  {currentDeviceData?.serialNumber}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>

        <div className="">
          <TextField
            fullWidth
            label={`Mac Address`}
            value={currentDeviceData?.macAddress}
            onChange={(e) => handleInputChange("macAddress", e.target.value)}
            type="text"
            placeholder={`Mac Address ${tabNumber}`}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            disabled={true}
            sx={inputStyles.select}
          />
        </div>
        <div className="">
          <TextField
            fullWidth
            label={`IMEI Number`}
            value={currentDeviceData?.imeiNumber}
            onChange={(e) => handleInputChange("imeiNumber", e.target.value)}
            type="text"
            placeholder={`IMEI Number ${tabNumber}`}
            variant="outlined"
            className="my-2"
            autoComplete="off"
            disabled={true}
            sx={inputStyles.select}
          />
        </div>
      </div>
    );
  };

  // Count the number of true values
  const trueCount = isDeviceValid.filter((item) => item === true).length;

  // Total length of the array
  const totalLength = isDeviceValid.length;

  const commanDevicess = useSelector((data: any) => data?.commanDevices);
  const [commanDevices, setCommanDevices] = useState<any>([]);
  useEffect(() => {
    setCommanDevices(commanDevicess?.data);
  }, [commanDevicess]);
  return (
    <div>
      <div className="flex justify-end">
        {/* <div>
          {commanDevices?.length >= 1 ? (
            <div>
              <span className="text-error">
                These devices with this serial numbers are been duplicated:
              </span>
              {commanDevices?.map((item: any, index: number) => {
                return (
                  <span>
                    {item} {index !== commanDevices?.length - 1 && ","}
                  </span>
                );
              })}
            </div>
          ) : null}
        </div> */}
        <div>
          <PrimaryButton
            type="button"
            name="Add Devices"
            icon={<AddCircleOutlineIcons />}
            onClick={handleAddDevice}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <div>
          <span className="text-secondary font-medium text-18"> Linked: </span>
          <span className="text-primary font-bold text-18">
            {trueCount ?? 0} / {totalLength ?? 0}
          </span>
        </div>
        <div className="">
          {Object.keys(validationErrors).length >= 1 ? (
            <span className="flex gap-1 items-center text-16 font-normal text-error">
              <ErrorMessageIcon /> All Devices have not been linked!
            </span>
          ) : null}
        </div>
      </div>

      <div className="flex gap-4 justify-between mt-4">
        <div className="flex gap-4 overflow-x-auto scroll_bar">
          {Array.isArray(deviceData) &&
            deviceData?.map((item, index) => {
              const isCommon = commanDevices?.includes(item?.serialNumber);

              return (
                <div
                  key={index + 1}
                  className={`flex items-center gap-2 p-3 ${
                    activeTab === index + 1 && "border-b-2 border-blue-350"
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleTabChange(index + 1)}
                    className={`flex items-center gap-1 ${
                      activeTab === index + 1 ? " " : ""
                    }`}
                  >
                    {isDeviceValid[index] ? (
                      <span className="text-green-600">
                        {isCommon ? (
                          <CancelOutlinedIcons color={"#D33C52"} />
                        ) : (
                          <CheckCircleIcon />
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                    <span
                      className={`${
                        isCommon
                          ? "text-error font-semibold"
                          : activeTab === index + 1
                          ? "text-primary font-semibold"
                          : "text-secondary font-medium"
                      } whitespace-nowrap text-14 font-medium`}
                    >
                      Device {index + 1}
                    </span>
                  </button>
                  {activeTab === index + 1 && (
                    <button
                      type="button"
                      disabled={disabled}
                      className="text-red-500"
                      onClick={() => handleRemoveTab(index + 1)}
                    >
                      <DeleteOutlinedIcons />
                    </button>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {totalLength ? <hr /> : <></>}

      <div className="my-6">
        {Array.isArray(deviceData) &&
          deviceData?.length >= 1 &&
          renderFields(activeTab)}
      </div>
    </div>
  );
};

export default DynamicTabsComponent;
