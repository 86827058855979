import React from "react";
import ModalHeader from "../../utils/modalHeader";

type DashBoardCardProps = {
  children: React.ReactNode;
};

export default function DashboardCard({ children }: DashBoardCardProps) {
  return (
    <ModalHeader>
      <div className="items-center px-5 py-5 sticky top-0 bg-white">{children}</div>
    </ModalHeader>
  );
}
