import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  ThemeProvider,
  createTheme,
  Autocomplete,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { getOnboardingSourceGraphData } from "../../../redux/mis/misReducer";
import { LoadingSpinner } from "../../../components/ui/loadinSpinner";
import SelectComponent from "../../../components/forms/selectComponent";
import { ukUA } from "@mui/x-data-grid";

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#eeeeee00",
        },
        bar: {
          background: "linear-gradient(to right, #EFDADC 30%, #EBA5AF 70%)",
        },
      },
    },
  },
});

const allSourceNameList = [
  { label: "All Source Names", value: "all" },
  { label: "Top 5 Source Names", value: 5 },
  { label: "Top 10 Source Names", value: 10 },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 22,
  borderRadius: 0,
}));

function AllSourceNames({
  country,
  state,
  district,
  taluk,
  hobli,
  filterApplyToggle,
}: any) {
  const dispatch = useAppDispatch();
  const [selectedValue, setSelectedValue] = useState("all");
  const [activeHospitalsCount, setActiveHospitalsCount] = useState<any>("");
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const onboardingSource = useSelector(
    (data: any) => data?.getOnboardingSourceGraphData
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [onboardingSourceData, setOnboardingSourceGraphData] = useState<any>(
    []
  );

  interface HospitalData {
    onBoardingSource: string;
    onBoardingSourceTotalCount: number;
    onBoardingSourceIndividualCounts: {
      name: string;
      totalCount: number;
      percentage: number;
    }[];
  }

  useEffect(() => {
    onboardingSourceData?.data?.map((hospital: HospitalData) => {
      if (hospital.onBoardingSource === "Name") {
        setActiveHospitalsCount(hospital.onBoardingSourceTotalCount);
      }
      return null;
    });
  }, [onboardingSourceData]);

  let highestPercentage = 0;

  onboardingSourceData?.data?.map((hospital: HospitalData) => {
    if (hospital.onBoardingSource === "Name") {
      return hospital.onBoardingSourceIndividualCounts.map((item, index) => {
        if (item.percentage > highestPercentage) {
          highestPercentage = item.percentage;
        }
      });
    }
    return null;
  });

  useEffect(() => {
    if (onboardingSource?.status === "success") {
      setErrorMessage("");
      setOnboardingSourceGraphData(onboardingSource?.data ?? []);
    } else if (onboardingSource?.status === "failed") {
      setErrorMessage("Something went Wrong");
    }
  }, [onboardingSource]);

  useEffect(() => {
    dispatch(
      getOnboardingSourceGraphData({
        country: country,
        state: state,
        district: district,
        taluk: taluk,
        hobli: hobli,
        serviceType: "",
      })
    );
  }, [filterApplyToggle]);

  return (
    <div className="p-6 bg-white shadow-sm1 rounded-lg">
      {onboardingSource.isLoading ? (
        <div className="flex justify-center py-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <span className="text-18 font-medium text-primary">
                All Source Names
              </span>

              <div>
                <span className="text-14 font-[400] text-secondary">
                  Active Hospitals :
                </span>
                <span className="text-14 font-[700] text-primary pl-1">
                  {activeHospitalsCount?.toLocaleString("en-IN")}
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <SelectComponent
                label="Select"
                id="status"
                value={selectedValue}
                onChange={handleChange}
                options={allSourceNameList}
              />
            </Grid>
          </Grid>

          {activeHospitalsCount && activeHospitalsCount > 0 ? (
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: "2rem" }}>
              {onboardingSourceData?.data?.map((hospital: HospitalData) => {
                if (hospital.onBoardingSource === "Name") {
                  const casesNoShow =
                    hospital.onBoardingSourceIndividualCounts.length;
                  return hospital.onBoardingSourceIndividualCounts
                    .slice(
                      0,
                      selectedValue === "all"
                        ? casesNoShow
                        : parseInt(selectedValue)
                    )
                    .map((item, index) => (
                      <div key={index} style={{ marginBottom: "12px" }}>
                        <ThemeProvider theme={theme}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={1.8}>
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.name}
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9.5}>
                              <span
                                style={{
                                  zIndex: 1,
                                  position: "relative",
                                  color: "#212121",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.totalCount?.toLocaleString("en-IN")}
                              </span>
                              <BorderLinearProgress
                                style={{
                                  right: "6px",
                                  position: "relative",
                                  bottom: 22,
                                  width: "100%",
                                }}
                                variant="determinate"
                                value={
                                  (item.percentage / highestPercentage) * 100
                                }
                              />
                            </Grid>
                            <Grid
                              style={{ display: "flex", justifyContent: "end" }}
                              item
                              xs={12}
                              sm={12}
                              md={0.7}
                            >
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {(item.percentage * 2)?.toFixed(2)}%
                              </span>
                            </Grid>
                          </Grid>
                        </ThemeProvider>
                      </div>
                    ));
                }
                return null;
              })}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <div className="flex justify-center">
                <span className="text-18 font-medium text-primary">
                  No Data Found
                </span>
              </div>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

export default AllSourceNames;
