import ActiveIcon from "../../../assests/icons/active-icon.svg";
import ExpiredIcon from "../../../assests/icons/expired-icon.svg";
import InactiveIcon from "../../../assests/icons/deactivated-icon.svg";
import InprogressIcon from "../../../assests/icons/inprogress-Icon.svg";
import DoughnutChart from "../../../components/charts/doughnutChart";
// import LinkIcon from "../../../assests/icons/linked.svg";
import unlinked from "../../../assests/icons/unlinked.svg";

import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { IconButton } from "@mui/material";
import "../../../components/components.css";

export const StatusCard = ({
  status,
  count,
  value,
  paidCount,
  pilotCount,
  active,
  devices = false,
  hosStatus,
  onClick = () => {},
  loading,
  localstorageboolean,
}: {
  status: string;
  count: number;
  value: string;
  paidCount?: number;
  pilotCount?: number;
  active?: string;
  devices?: boolean;
  hosStatus: string;
  onClick: (value: string) => void;
  loading: boolean;
  localstorageboolean?: boolean;
}) => {
  return (
    <div
      className={`${
        hosStatus === value ? "border border-gray-200 bg-[#F1DFF8]" : "bg-white"
      } shadow-md1 rounded-lg  flex justify-between py-4 cursor-pointer px-5 h-[98px] status_card
     `}
      onClick={() => {
        if (loading) {
        } else {
          onClick(value);
        }
      }}
    >
      <div className="flex gap-3 ">
        <div className="pt-1">
          {value === "active" || value === "allStatus" ? (
            <img src={ActiveIcon} width="40px" height="40px" />
          ) : value === "expired" ? (
            <img src={ExpiredIcon} width="40px" height="40px" />
          ) : value === "inprogress" ? (
            <img src={InprogressIcon} width="40px" height="40px" />
          ) : value === "inactive" ? (
            <img src={InactiveIcon} width="40px" height="40px" />
          ) : value === "linked" ? (
            <>
              <IconButton style={{ backgroundColor: "#EAFDFF" }}>
                <LinkIcon style={{ color: "#31946E" }} />
              </IconButton>
            </>
          ) : value === "Unlinked" ? (
            <>
              <IconButton style={{ backgroundColor: "#FFF4F6" }}>
                <LinkOffIcon style={{ color: "#D33C52" }} />
              </IconButton>
            </>
          ) : (
            ""
          )}
        </div>
        {localstorageboolean && (
          <div className="flex flex-col gap-3">
            <span className="text-14 font-medium text-secondary">{status}</span>
            <span className="text-24 font-semibold text-primary">
              {count !== undefined && !isNaN(count) ? count : 0}
            </span>
          </div>
        )}
      </div>
      {value === active && (
        <div className="flex flex-col">
          <DoughnutChart
            width="w-[50px]"
            chartData={{
              Pilot: pilotCount ?? (0 as number),
              Paid: paidCount ?? (0 as number),
            }}
            colors={["#F5B44B", "#6B4892"]}
          />
        </div>
      )}
      {devices && (
        <div className="flex flex-col">
          <DoughnutChart
            width="w-[50px]"
            chartData={{
              Package: paidCount ?? (0 as number),
              Paid: pilotCount ?? (0 as number),
            }}
            colors={["#F5B44B", "#6B4892"]}
          />
        </div>
      )}
    </div>
  );
};
