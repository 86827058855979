import { useEffect, useState } from "react";
import { InputForm } from "../../components/forms/inputForm";
import DynamicTabsComponent from "../../components/hospitalManagement/dynamicTabs";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useSelector } from "react-redux";

interface productDevicesType {
  control: any;
  watch: any;
  setValue: any;
  totalDevicesCount: string;
  packageDevicesCount: string;
  paidDevicesCount: string;
  packageDevices: any;
  paidDevices: any;
  previousDataPA?: any;
  clearErrors: any;
  disabled?: boolean;
}

const ProductActivationDevices = ({
  control,
  watch,
  setValue,
  totalDevicesCount = "maTotalDevicesCount",
  packageDevicesCount = "maPackageDevicesCount",
  paidDevicesCount = "maPaidDevicesCount",
  packageDevices = "maPackageDevices",
  paidDevices = "maPaidDevices",
  previousDataPA,
  clearErrors,
  disabled = false,
}: productDevicesType) => {
  const [deviceType, setDeviceType] = useState<string>("Package Devices");
  const devicesList: any = useSelector(
    (data: any) => data?.getDevices?.data?.data
  );

  useEffect(() => {
    setValue(
      totalDevicesCount,
      (isNaN(watch(packageDevicesCount)) ? 0 : +watch(packageDevicesCount)) +
        (isNaN(watch(paidDevicesCount)) ? 0 : +watch(paidDevicesCount))
    );
  }, [watch(packageDevicesCount), watch(paidDevicesCount)]);

  return (
    <div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 mt-2 mb-6">
        <InputForm
          label={"Package Devices*"}
          id={packageDevicesCount}
          control={control}
          disabled={true}
          defaultValue={previousDataPA?.[packageDevicesCount]}
        />
        <InputForm
          label={"Paid Devices*"}
          id={paidDevicesCount}
          control={control}
          disabled={true}
          defaultValue={previousDataPA?.[paidDevicesCount]}
        />
        <div className="hidden">
          <InputForm
            label={"Total Devices*"}
            id={totalDevicesCount}
            disabled={true}
            control={control}
            defaultValue={previousDataPA?.[totalDevicesCount]}
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-secondary text-14 font-medium">
            Total Devices
          </span>
          <span className="text-primary text-16 font-bold">
            {watch(totalDevicesCount)}
          </span>
        </div>
      </div>

      <div className=" gap-6 my-6 hidden">
        <div>
          <InputForm
            label={"Package Devices"}
            id={packageDevices}
            control={control}
            defaultValue={previousDataPA?.[packageDevices]}
            customErrorMessage="Devices have not been linked"
          />
        </div>
        <div>
          <InputForm
            label={"Paid Devices"}
            id={paidDevices}
            control={control}
            disabled={true}
            defaultValue={previousDataPA?.[paidDevices]}
            customErrorMessage="Devices have not been linked"
          />
        </div>
      </div>

      <div className="flex gap-4">
        <div>
          <div className="flex shadow-md w-fit rounded-lg p-2">
            <button
              className={`px-4 py-1 text-14 font-medium rounded-lg ${
                deviceType === "Package Devices"
                  ? "bg-green-100 text-primary"
                  : "text-secondary"
              } `}
              type="button"
              onClick={() => setDeviceType("Package Devices")}
            >
              {addHospitalName.Package}
            </button>
            <button
              className={`px-4 py-1 text-14 font-medium rounded-lg ${
                deviceType === "Paid Devices"
                  ? "bg-green-100 text-primary"
                  : "text-secondary"
              } `}
              type="button"
              onClick={() => setDeviceType("Paid Devices")}
            >
              {addHospitalName.Paid}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${deviceType === "Package Devices" ? "block" : "hidden"}`}
      >
        <DynamicTabsComponent
          setValue={setValue}
          deviceCount={packageDevicesCount}
          devicesNames={packageDevices}
          previousDataPA={previousDataPA}
          devicesList={devicesList}
          clearErrors={clearErrors}
          disabled={disabled}
        />
      </div>
      <div className={`${deviceType === "Paid Devices" ? "block" : "hidden"}`}>
        <DynamicTabsComponent
          setValue={setValue}
          deviceCount={paidDevicesCount}
          devicesNames={paidDevices}
          previousDataPA={previousDataPA}
          devicesList={devicesList}
          clearErrors={clearErrors}
          disabled={disabled}
        />
      </div>

      <hr />
    </div>
  );
};

export default ProductActivationDevices;
