import enums from "../constants/enums";
import { baseURL } from "../datasource/api";

export const ownerShipType = [
  { value: "government", label: "Government" },
  { value: "govt_aided", label: "Govt Aided" },
  { value: "corporate", label: "Corporate" },
  { value: "trust", label: "Trust" },
  {
    value: "private_individual_partnership",
    label: "Private Individual Partnership",
  },
  {
    value: "medical_institution",
    label: "Medical Institution",
  },
  { value: "research_institute", label: "Research Institute" },
];

export const statesData = [
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Raijur", label: "Raijur" },
  { value: "Kerala", label: "Kerala" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Telangana", label: "Telangana" },
  { value: "Goa", label: "Goa" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Bihar", label: "Bihar" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Odisha", label: "Odisha" },
  { value: "West Bengal", label: "West Bengal" },
];

export const citiesDate = [
  { value: "Mysuru", label: "Mysuru" },
  { value: "Mysore", label: "Mysore" },
  { value: "Bangalore", label: "Bangalore" },
  { value: "Mandya", label: "Mandya" },
  { value: "Chennai", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Pune", label: "Pune" },
  { value: "Delhi", label: "Delhi" },
  { value: "Kolkata", label: "Kolkata" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Ahmedabad", label: "Ahmedabad" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Patna", label: "Patna" },
  { value: "Ranchi", label: "Ranchi" },
  { value: "Bhubaneswar", label: "Bhubaneswar" },
  { value: "Raipur", label: "Raipur" },
  { value: "Panaji", label: "Panaji" },
  { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
  { value: "Bhopal", label: "Bhopal" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Shimla", label: "Shimla" },
  { value: "Dehradun", label: "Dehradun" },
  { value: "Gangtok", label: "Gangtok" },
  { value: "Itanagar", label: "Itanagar" },
  { value: "Dispur", label: "Dispur" },
  { value: "Imphal", label: "Imphal" },
  { value: "Aizawl", label: "Aizawl" },
  { value: "Kohima", label: "Kohima" },
  { value: "Agartala", label: "Agartala" },
  { value: "Shillong", label: "Shillong" },
  { value: "Srinagar", label: "Srinagar" },
  { value: "Jammu", label: "Jammu" },
  { value: "Chennai", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
];

export const countriesData = [
  { value: "India", label: "India" },
  { value: "America", label: "America" },
  { value: "South Korea", label: "South Korea" },
  { value: "Japan", label: "Japan" },
  { value: "China", label: "China" },
  { value: "Russia", label: "Russia" },
  { value: "Germany", label: "Germany" },
  { value: "France", label: "France" },
  { value: "Italy", label: "Italy" },
  { value: "Spain", label: "Spain" },
  { value: "Portugal", label: "Portugal" },
  { value: "Brazil", label: "Brazil" },
  { value: "Argentina", label: "Argentina" },
  { value: "Australia", label: "Australia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "South Africa", label: "South Africa" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Egypt", label: "Egypt" },
];
export const districtsData = [
  { value: "Bidar", label: "Bidar" },
  { value: "Bengaluru Rural", label: "Bengaluru Rural" },
  { value: "Kolar", label: "Kolar" },
  { value: "Belagavi", label: "Belagavi" },
  { value: "Hassan", label: "Hassan" },
  { value: "Ballari", label: "Ballari" },
  { value: "Bagalkote", label: "Bagalkote" },
  { value: "Bengaluru Urban", label: "Bengaluru Urban" },
  { value: "Chamarajanagar", label: "Chamarajanagar" },
  { value: "Chikkaballapura", label: "Chikkaballapura" },
  { value: "Chikkamagaluru", label: "Chikkamagaluru" },
  { value: "Chitradurga", label: "Chitradurga" },
  { value: "Dakshina Kannada", label: "Dakshina Kannada" },
  { value: "Davanagere", label: "Davanagere" },
  { value: "Dharwad", label: "Dharwad" },
  { value: "Gadag", label: "Gadag" },
  { value: "Kalaburagi", label: "Kalaburagi" },
  { value: "Haveri", label: "Haveri" },
  { value: "Kodagu", label: "Kodagu" },
  { value: "Koppal", label: "Koppal" },
  { value: "Mandya", label: "Mandya" },
  { value: "Mysuru", label: "Mysuru" },
  { value: "Raichur", label: "Raichur" },
  { value: "Ramanagara", label: "Ramanagara" },
  { value: "Shivamogga", label: "Shivamogga" },
  { value: "Tumakuru", label: "Tumakuru" },
  { value: "Udupi", label: "Udupi" },
  { value: "Uttara Kannada", label: "Uttara Kannada" },
  { value: "Vijayapura", label: "Vijayapura" },
  { value: "Yadgir", label: "Yadgir" },
  { value: "Kodagu", label: "Kodagu" },
  { value: "Koppal", label: "Koppal" },
  { value: "Mandya", label: "Mandya" },
];
export const taluksData = [
  { value: "Bagalkote", label: "Bagalkote" },
  { value: "Jamkhandi", label: "Jamkhandi" },
  { value: "Athani", label: "Athani" },
  { value: "Bailhongal", label: "Bailhongal" },
  { value: "Chikkodi", label: "Chikkodi" },
  { value: "Gokak", label: "Gokak" },
  { value: "Khanapura", label: "Khanapura" },
  { value: "Mudalgi", label: "Mudalgi" },
  { value: "Raibag", label: "Raibag" },
  { value: "Belagavi", label: "Belagavi" },
  { value: "Hukkeri", label: "Hukkeri" },
  { value: "Raybag", label: "Raybag" },
  { value: "Saundatti", label: "Saundatti" },
  { value: "Yadgir", label: "Yadgir" },
  { value: "Shahapur", label: "Shahapur" },
  { value: "Surpur", label: "Surpur" },
  { value: "Aland", label: "Aland" },
  { value: "Chincholi", label: "Chincholi" },
  { value: "Chitapur", label: "Chitapur" },
  { value: "Gulbarga", label: "Gulbarga" },
  { value: "Jevargi", label: "Jevargi" },
  { value: "Sedam", label: "Sedam" },
  { value: "Afzalpur", label: "Afzalpur" },
  { value: "Aurad", label: "Aurad" },
  { value: "Basavakalyan", label: "Basavakalyan" },
  { value: "Bhalki", label: "Bhalki" },
  { value: "Bidar", label: "Bidar" },
  { value: "Chitaguppa", label: "Chitaguppa" },
  { value: "Humnabad", label: "Humnabad" },
  { value: "Aurad", label: "Aurad" },
  { value: "Basavakalyan", label: "Basavakalyan" },
  { value: "Bhalki", label: "Bhalki" },
  { value: "Bidar", label: "Bidar" },
  { value: "Chitaguppa", label: "Chitaguppa" },
  { value: "Humnabad", label: "Humnabad" },
  { value: "Aurad", label: "Aurad" },
  { value: "Basavakalyan", label: "Basavakalyan" },
];
export const hobliData = [
  { value: "Sampaje", label: "Sampaje" },
  { value: "Bhagamandala", label: "Bhagamandala" },
  { value: "Napoklu", label: "Napoklu" },
  { value: "Suntikoppa", label: "Suntikoppa" },
  { value: "Somwarpet", label: "Somwarpet" },
  { value: "Shanthally", label: "Shanthally" },
  { value: "Shanivarsante", label: "Shanivarsante" },
  { value: "Kodlipet", label: "Kodlipet" },
  { value: "Madikeri", label: "Madikeri" },
  { value: "Virajpet", label: "Virajpet" },
  { value: "Ponnampet", label: "Ponnampet" },
  { value: "Siddapura", label: "Siddapura" },
  { value: "Sullia", label: "Sullia" },
  { value: "Puttur", label: "Puttur" },
  { value: "Beltangadi", label: "Beltangadi" },
  { value: "Mangalore", label: "Mangalore" },
  { value: "Bantwal", label: "Bantwal" },
  { value: "Moodabidri", label: "Moodabidri" },
  { value: "Kundapura", label: "Kundapura" },
  { value: "Udupi", label: "Udupi" },
  { value: "Karkala", label: "Karkala" },
];

export const soureTypeData = [
  { value: "distribution_partners", label: "Distribution Partners" },
  { value: "on_field_sales", label: "On Field Sales" },
  { value: "inside_sales", label: "Inside Sales" },
  { value: "conference", label: "Conference" },
];

export const serviceTypeData = [
  { value: "pilot", label: "Pilot" },
  { value: "paid", label: "Paid" },
];

export const planTypeData = [
  { value: "per_patient", label: "Per Patient" },
  { value: "per_month", label: "Per Month" },
  { value: "per_annum", label: "Per Annum" },
];

export const patientTypeData = [
  { value: "all", label: "All" },
  { value: "in_patient", label: "In Patient" },
  { value: "out_patient", label: "Out Patient" },
];

export const validityData = [
  { value: "lifetime", label: "Life Time" },
  { value: "validity_3_months", label: "3 Months" },
  { value: "validity_6_months", label: "6 Months" },
  { value: "validity_12_months", label: "12 Months" },
  { value: "custom", label: "Custom" },
];

export const instalmentsData = [
  { value: "full_advance", label: "Full Advance" },
  { value: "Installment_3_months", label: "3 Months" },
  { value: "Installment_5_months", label: "5 Months" },
];

export const specialisationTypeData = [
  { value: "cardiology", label: "Cardiology" },
  { value: "obg", label: "Obg" },
  { value: "orthopedic", label: "Orthopedic" },
  { value: "neuro", label: "Neuro" },
  { value: "general_medicine", label: "General Medicine" },
];

export const enumDataApiList = [
  `${baseURL}Enums/OwnershipType`,
  `${baseURL}Enums/AddOnServiceType`,
  `${baseURL}Enums/BedStatus`,
  `${baseURL}Enums/DeviceType`,
  `${baseURL}Enums/HospitalsStatus`,
  `${baseURL}Enums/Instalments`,
  `${baseURL}Enums/PatientType`,
  `${baseURL}Enums/SourceType`,
  `${baseURL}Enums/PlanType`,
  `${baseURL}Enums/ValidityPeriod`,
  `${baseURL}Enums/BedSizeType`,
];

export const hositalMatrixOptions = [
  {
    label: "Product and Plan",
    value: enums.productPlan,
  },
  {
    label: "Hospital Occupancy",
    value: enums.hospitalOccupancy,
  },
  {
    label: "All",
    value: enums.hospitalMatrixTypeAll,
  },
];
