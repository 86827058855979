import React from "react";
import { Controller } from "react-hook-form";
import { removeUnderScore } from "../../helper";

interface InputProps {
  label: string;
  id: string;
  control: any;
  errorMessage?: string | undefined;
  errors?: boolean;
  placeholder?: string;
  defaultValue?: string | undefined;
  disabled?: boolean;
  option1?: string;
  option2?: string;
  required?: boolean;
  className?: string;
}

export const RadioForm: React.FC<InputProps> = ({
  label,
  id,
  control,
  errorMessage,
  errors = false,
  defaultValue,
  disabled = false,
  option1 = "Yes",
  option2 = "No",
  required = true,
  className = "",
}) => {
  return (
    <div className="">
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: required ? `Please select ${label}` : false,
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className={`flex ${className} gap-3`}>
              <label
                htmlFor="operat"
                className="text-16 font-semibold text-primary"
              >
                {label}
              </label>
              <div className="flex items-center gap-6">
                <div className="flex items-center gap-2">
                  <input
                    id={`${id}-${option1}`}
                    type="radio"
                    value={option1}
                    onChange={(e) => field.onChange(e)}
                    checked={field.value === option1}
                    disabled={disabled}
                  />
                  <label
                    htmlFor={`${id}-${option1}`}
                    className={`font-14 font-medium ${
                      field.value === option1
                        ? "text-primary"
                        : "text-secondary"
                    }`}
                  >
                    {removeUnderScore(option1)}
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    id={`${id}-${option2}`}
                    type="radio"
                    value={option2}
                    onChange={(e) => field.onChange(e)}
                    checked={field.value === option2}
                    disabled={disabled}
                  />
                  <label
                    htmlFor={`${id}-${option2}`}
                    className={`font-14 font-medium ${
                      field.value === option2
                        ? "text-primary"
                        : "text-secondary"
                    }`}
                  >
                    {removeUnderScore(option2)}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <span className="text-error font-medium text-12">
                {error?.message}
              </span>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default RadioForm;
