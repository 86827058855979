import { createSlice } from "@reduxjs/toolkit";
import {
  getCityData,
  getCountryData,
  getDistrictData,
  getHobliData,
  getStateData,
  getTalukData,
} from "./locationReducer";

export const getCountryDataSlice = createSlice({
  name: "getCountryData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetCountryDataState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getCountryData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getCountryData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getCountryDatareducer = getCountryDataSlice.reducer;
export const { resetGetCountryDataState } = getCountryDataSlice.actions;

export const getStateDataSlice = createSlice({
  name: "getStateData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetStateDataState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStateData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getStateData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getStateData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getStateDatareducer = getStateDataSlice.reducer;
export const { resetGetStateDataState } = getStateDataSlice.actions;

export const getDistrictDataSlice = createSlice({
  name: "getDistrictData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetDistrictDataState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistrictData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getDistrictData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getDistrictData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getDistrictDatareducer = getDistrictDataSlice.reducer;
export const { resetGetDistrictDataState } = getDistrictDataSlice.actions;

export const getTalukDataSlice = createSlice({
  name: "getTalukData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetTalukDataState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTalukData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getTalukData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getTalukData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getTalukDatareducer = getTalukDataSlice.reducer;
export const { resetGetTalukDataState } = getTalukDataSlice.actions;

export const getHobliDataSlice = createSlice({
  name: "getHobliData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetHobliDataState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHobliData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getHobliData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getHobliData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getHobliDatareducer = getHobliDataSlice.reducer;
export const { resetGetHobliDataState } = getHobliDataSlice.actions;

export const getCityDataSlice = createSlice({
  name: "getCityData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetCityDataState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCityData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getCityData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getCityData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getCityDatareducer = getCityDataSlice.reducer;
export const { resetGetCityDataState } = getCityDataSlice.actions;
