import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import {
  getCityData,
  getCountryData,
  getDistrictData,
  getHobliData,
  getStateData,
  getTalukData,
} from "../../../redux/location/locationReducer";
import {
  resetGetCityDataState,
  resetGetCountryDataState,
  resetGetDistrictDataState,
  resetGetHobliDataState,
  resetGetStateDataState,
  resetGetTalukDataState,
} from "../../../redux/location/locationSlice";

export const CountryList = () => {
  const [countryLists, setCountryLists] = useState<any>([]);
  const [countryError, setCountryError] = useState<any>([]);
  const [countryLoading, setCountryLoading] = useState(true);
  const dispatch = useAppDispatch();

  const fetchCountry = () => {
    setCountryLoading(true);
    dispatch(getCountryData());
  };

  useEffect(() => {
    return () => {
      dispatch(resetGetCountryDataState());
    };
  }, []);

  const countryListsRes = useSelector((data: any) => data?.getCountryData);

  useEffect(() => {
    if (countryListsRes?.status === "success") {
      const filterData = countryListsRes?.data?.data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      setCountryLists(filterData);
      setCountryLoading(false);
    } else if (countryListsRes?.status === "failed") {
      setCountryError("Something Went wrong");
      setCountryLoading(false);
    }
  }, [countryListsRes]);

  return { countryLists, countryError, countryLoading, fetchCountry };
};

export const StateList = (countryId: number) => {
  const [stateLists, setStateLists] = useState<any>([]);
  const [stateError, setStateError] = useState<any>([]);
  const [stateLoading, setStateLoading] = useState(false);
  const dispatch = useAppDispatch();

  const fetchState = () => {
    setStateLoading(true);
    dispatch(getStateData(countryId ? countryId : 0));
  };
  useEffect(() => {
    return () => {
      dispatch(resetGetStateDataState());
    };
  }, []);
  const stateListsRes = useSelector((data: any) => data?.getStateData);

  useEffect(() => {
    if (stateListsRes?.status === "success") {
      const filterData = stateListsRes?.data?.data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      setStateLists(filterData);
      setStateLoading(false);
    } else if (stateListsRes?.status === "failed") {
      setStateError("Something Went wrong");
      setStateLoading(false);
    }
  }, [stateListsRes]);

  return { stateLists, stateError, stateLoading, fetchState };
};

export const DistrictList = (stateId: number) => {
  const [districtLists, setDistrictLists] = useState<any>([]);
  const [districtError, setDistrictError] = useState<any>([]);
  const [districtLoading, setDistrictLoading] = useState(false);
  const dispatch = useAppDispatch();

  const fetchDistrict = () => {
    setDistrictLoading(true);
    dispatch(getDistrictData(stateId ? stateId : 0));
  };
  useEffect(() => {
    return () => {
      dispatch(resetGetDistrictDataState());
    };
  }, []);
  const districtListsRes = useSelector((data: any) => data?.getDistrictData);

  useEffect(() => {
    if (districtListsRes?.status === "success") {
      const filterData = districtListsRes?.data?.data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      setDistrictLists(filterData);
      setDistrictLoading(false);
    } else if (districtListsRes?.status === "failed") {
      setDistrictError("Something Went wrong");
      setDistrictLoading(false);
    }
  }, [districtListsRes]);

  return { districtLists, districtError, districtLoading, fetchDistrict };
};

export const TalukList = (districtId: number) => {
  const [talukLists, setTalukLists] = useState<any>([]);
  const [talukError, setTalukError] = useState<any>([]);
  const [talukLoading, setTalukLoading] = useState(false);
  const dispatch = useAppDispatch();

  const fetchTaluk = () => {
    setTalukLoading(true);
    dispatch(getTalukData(districtId ? districtId : 0));
  };
  useEffect(() => {
    return () => {
      dispatch(resetGetTalukDataState());
    };
  }, []);
  const talukListsRes = useSelector((data: any) => data?.getTalukData);

  useEffect(() => {
    if (talukListsRes?.status === "success") {
      const filterData = talukListsRes?.data?.data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      setTalukLists(filterData);
      setTalukLoading(false);
    } else if (talukListsRes?.status === "failed") {
      setTalukError("Something Went wrong");
      setTalukLoading(false);
    }
  }, [talukListsRes]);

  return { talukLists, talukError, talukLoading, fetchTaluk };
};

export const HobliList = (talukId: number) => {
  const [hobliLists, setHobliLists] = useState<any>([]);
  const [hobliError, setHobliError] = useState<any>([]);
  const [hobliLoading, setHobliLoading] = useState(false);
  const dispatch = useAppDispatch();

  const fetchHobli = () => {
    setHobliLoading(true);
    dispatch(getHobliData(talukId ? talukId : 0));
  };
  useEffect(() => {
    return () => {
      dispatch(resetGetHobliDataState());
    };
  }, []);
  const hobliListsRes = useSelector((data: any) => data?.getHobliData);

  useEffect(() => {
    if (hobliListsRes?.status === "success") {
      const filterData = hobliListsRes?.data?.data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      setHobliLists(filterData);
      setHobliLoading(false);
    } else if (hobliListsRes?.status === "failed") {
      setHobliError("Something Went wrong");
      setHobliLoading(false);
    }
  }, [hobliListsRes]);

  return { hobliLists, hobliError, hobliLoading, fetchHobli };
};

export const CityList = (countryId: number) => {
  const [cityLists, setCityLists] = useState<any>([]);
  const [cityError, setCityError] = useState<any>([]);
  const [cityLoading, setCityLoading] = useState(false);
  const dispatch = useAppDispatch();

  const fetchCity = () => {
    setCityLoading(true);
    dispatch(getCityData(countryId ? countryId : 0));
  };
  useEffect(() => {
    return () => {
      dispatch(resetGetCityDataState());
    };
  }, []);
  const cityListsRes = useSelector((data: any) => data?.getCityData);

  useEffect(() => {
    if (cityListsRes?.status === "success") {
      const filterData = cityListsRes?.data?.data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      setCityLists(filterData);
      setCityLoading(false);
    } else if (cityListsRes?.status === "failed") {
      setCityError("Something Went wrong");
      setCityLoading(false);
    }
  }, [cityListsRes]);

  return { cityLists, cityError, cityLoading, fetchCity };
};
