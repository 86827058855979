
const DashBoard = () => {
  
  return (
    <div className="h-[100vh] flex justify-center items-center text-[50px] text-secondary">
      WIP
    </div>
  );
};

export default DashBoard;
