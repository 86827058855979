import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux/store";
import { getAccountAllList } from "../../../redux/addHospital/hospitalManagementReducer";
import { useSelector } from "react-redux";

const AccountManagerList = () => {
  const [AccountManagerLists, setAccountManagerLists] = useState<any>([]);
  const [error, setError] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getAccountAllList());

    return () => {};
  }, []);

  const getAccountAllListRes = useSelector(
    (data: any) => data?.getAccountAllList
  );

  useEffect(() => {
    if (getAccountAllListRes?.status === "success") {
      const filterData = getAccountAllListRes?.data?.data?.map((item: any) => ({
        userId: item?.userId,
        name: item?.name,
        email: item?.email,
        phone: item?.phone,
        role: item?.role,
      }));

      setAccountManagerLists(filterData);
      setLoading(false);
    } else if (getAccountAllListRes?.status === "failed") {
      setError("Something Went wrong");
      setLoading(false);
    }
  }, [getAccountAllListRes]);

  return { data: AccountManagerLists, error, loading };
};

export default AccountManagerList;
