import SearchInput from "../components/forms/searchInput";
import InnerLayout from "../layout/innerLayout";
import { useEffect, useState } from "react";
import DynamicTable from "../components/tables/dynamicTable";
import sortValues from "../constants/sortColumnValue";
import { useAppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { feedbackColumnData } from "../features/feedback/headerData";
import TblFeeback from "../features/feedback/tableBody";
import { getAllFeedback } from "../redux/feedback/feedbackReducer";
import { SearchQuery } from "../utils/searchQuery";
import { TableFilterData } from "../modals/Itables";
import { useSelector } from "react-redux";
import errorToast from "../utils/errorToast";
import { ResponseType } from "../modals/IreduxResponse";
const FeedBack = () => {
  const [allSearch, setAllSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [filter, setFilter] = useState<TableFilterData>({});
  const [orderBy, setOrderBy] = useState("");
  const searchQuery = SearchQuery({ filter });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [feedbackData, setFeedbackData] = useState<any>([]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch,orderBy, order]);

  useEffect(() => {
    dispatch(
      getAllFeedback({
        order: order,
        orderBy: orderBy,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
  }, [order, orderBy, page, rowsPerPage, allSearch, searchQuery]);

  const getAllFeedbackRes = useSelector(
    (data: { getAllFeedback: ResponseType }) => data.getAllFeedback
  );
  useEffect(() => {
    if (getAllFeedbackRes?.status === "success") {
      setFeedbackData(getAllFeedbackRes?.data?.data);
    } else if (getAllFeedbackRes?.status === "failed") {
      setErrorMessage("Something Went Wrong");
      errorToast("Something Went Wrong");
    }
  }, [getAllFeedbackRes]);

  return (
    <>
      <InnerLayout title="FeedBack">
        <div className="bg-white shadow-md1 rounded-lg pt-6 pb-14 mx-6 ">
          <div className="grid grid-cols-12">
            <div className="xl:col-span-3 lg:col-span-4 md:col-span-5 sm:col-span-6 col-span-12 px-6 pb-6 ">
              <SearchInput setAllSearch={setAllSearch} />
            </div>
          </div>
          <div className="h-[70vh]">
            <DynamicTable
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              order={order}
              setorder={setorder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              filter={filter}
              setFilter={setFilter}
              columnData={feedbackColumnData}
              children={<TblFeeback data={feedbackData} />}
              isFirstSticky={true}
              stickyWidth={[{ width: "0px" }, { width: "250px" }]}
              status={getAllFeedbackRes?.isLoading}
              errorMessage={errorMessage}
              dataLength={getAllFeedbackRes?.data?.count ?? 10}
              noData={getAllFeedbackRes?.data?.data?.length < 1}
            />
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default FeedBack;
