import { useEffect, useRef, useState } from "react";
import useDisplayToggle from "../../../utils/useDisplayToggle";
import {
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { inputStyles } from "../../../components/mui-styles";
import DefaulthospitalLogo from "../../../assests/images/default-hospital-logo.png";
import enums from "../../../constants/enums";
import StatusWithColor from "../../../utils/StatusWithColor";

const TblBodyDepartments = ({
  data,
  setorder,
  setOrderBy,
  setFilter,
  cases,
  casesNoShow,
  order,
  orderBy,
  filter,
}: any) => {
  const styles = {
    // borderLeft: "1px solid #F2F4F7",
    borderRight: "1px solid #F2F4F7",
    borderBottom: "1px solid #F2F4F7",
    background: "white",
    minWidth: "150px",
    maxWidth: "150px",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#667085",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
    height: "70px",
  };
  const [searchShow, setSearchShow] = useState<any>(null);
  const [isSearchOption, setisSearchOption] = useState<any>(false);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisSearchOption,
  });

  useEffect(() => {
    if (isSearchOption === false) {
      setSearchShow(null);
    }
  }, [isSearchOption]);

  const toggleFn = (index: any) => {
    if (searchShow === index) {
      setisSearchOption(false);
      setSearchShow(null);
    } else {
      setSearchShow(index);
      setisSearchOption(true);
    }
  };

  const handleRequestSort = (value: string, index: number) => {
    setorder(value);
    setOrderBy((prev: any) => {
      if (prev === "asc") {
        return "desc";
      } else {
        return "asc";
      }
    });
  };

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const newFilter: any = {};
    newFilter[name] = value;
    setFilter(newFilter);
  };

  const headers = data?.[0]?.cMCasesAndDiagnosisBaseModels
    ?.slice(0, cases === "all" ? casesNoShow : parseInt(cases))
    ?.map((specialty: any, index: any) => ({
      label: `Top ${index + 1}`,
      value: `top_${index + 1}`,
    }));

  return (
    <TableBody className="">
      <TableRow className="" style={{ minWidth: "580px", maxWidth: "580px" }}>
        <TableCell
          style={{
            ...styles,
            zIndex: 5,
            minWidth: "250px",
            maxWidth: "250px",
            position: "sticky",
            left: "0px",
            height: "20px",
            top: "56px",
            borderRight: "",
          }}
        ></TableCell>
        <TableCell
          style={{
            ...styles,
            zIndex: 5,
            minWidth: "150px",
            maxWidth: "150px",
            position: "sticky",
            left: "250px",
            height: "20px",
            top: "56px",
            borderRight: "",
          }}
        ></TableCell>
        <TableCell
          style={{
            ...styles,
            zIndex: 5,
            minWidth: "180px",
            maxWidth: "180px",
            position: "sticky",
            left: "400px",
            height: "20px",
            top: "56px",
          }}
        ></TableCell>
        {headers?.map((items: any, index: any) => (
          <TableCell
            style={{
              ...styles,
              color: "#212121",
              fontWeight: 600,
              height: "20px",
              position: "relative",
            }}
            key={index}
          >
            <div className="flex gap-4 items-center justify-between">
              <span className="text-14 font-semibold text-primary">
                Top {index + 1}
              </span>
            </div>

            {searchShow === index && isSearchOption && (
              <div
                className="absolute top-16 bg-white p-3 rounded-lg shadow-lg"
                ref={insideClickRef}
              >
                <TextField
                  id={`top_${index + 1}`}
                  label={`Search by Top ${index + 1}`}
                  variant="outlined"
                  type="search"
                  sx={inputStyles.select}
                  style={{ width: "200px" }}
                  name={`top_${index + 1}`}
                  value={filter[`top_${index + 1}`] || ""}
                  onChange={(e: any) => {
                    handleFilterChange(e, index);
                  }}
                />
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
      {data?.map((item: any, index: any) => {
        return (
          <TableRow key={index}>
            <TableCell
              style={{
                ...styles,
                zIndex: 5,
                minWidth: "250px",
                maxWidth: "250px",
                position: "sticky",
                left: "0px",
              }}
            >
              <div className={`grid grid-cols-5 items-center cursor-pointer`}>
                <div className="col-span-1 ">
                  <img
                    src={item?.logo ?? DefaulthospitalLogo}
                    className="rounded-full w-[36px] h-[36px] border"
                    alt="hospital-logo"
                  />
                </div>

                <div className="col-span-4 ps-2">
                  <div>
                    <span className="text-blue-300 text-16 font-semibold">
                      {item?.hospitalName}
                    </span>
                  </div>

                  <span className="text-secondary text-14 font-medium">
                    ID: {item?.hospitalCode}
                  </span>
                </div>
              </div>
            </TableCell>
            <TableCell
              style={{
                ...styles,
                zIndex: 5,
                minWidth: "150px",
                maxWidth: "150px",
                position: "sticky",
                left: "250px",
              }}
            >
              <StatusWithColor
                value={
                  item?.hospitalStatus === enums.inactive
                    ? enums?.deactivated
                    : item?.hospitalStatus
                }
                subValue={item?.productStatus}
              />
            </TableCell>
            <TableCell
              style={{
                ...styles,
                zIndex: 5,
                minWidth: "180px",
                maxWidth: "180px",
                position: "sticky",
                left: "400px",
              }}
            >
              {item?.hospitalLocation}
            </TableCell>
            {item?.cMCasesAndDiagnosisBaseModels
              ?.slice(0, cases === "all" ? casesNoShow : parseInt(cases))
              ?.map((specialty: any, index: any) => {
                return (
                  <TableCell
                    key={index}
                    style={{
                      ...styles,
                      minWidth: "250px",
                      maxWidth: "250px",
                    }}
                  >
                    <div>{specialty ? `${specialty?.name}` : "-"}</div>
                    {specialty
                      ? `(${specialty.individualCount} cases | ${(
                          (specialty.individualCount / specialty?.totalCount) *
                          100
                        ).toFixed(0)}%)`
                      : "-"}
                  </TableCell>
                );
              })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TblBodyDepartments;
