import { createSlice } from "@reduxjs/toolkit";
import { getAllFeedback } from "./feedbackReducer";

export const getAllFeedbackSlice = createSlice({
  name: "getAllFeedback",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetAllFeedbackState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFeedback.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllFeedback.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getAllFeedback.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getAllFeedbackreducer = getAllFeedbackSlice.reducer;
export const { resetgetAllFeedbackState } = getAllFeedbackSlice.actions;
