import { useEffect, useState } from "react";
import { NotificationCheckBoxForm } from "../../../../components/forms/checkBoxForm";
import { useForm } from "react-hook-form";
import {
  DeleteOutlinedIcons,
  FileIcons,
} from "../../../../components/muiIconsImport";
import {
  getNotifications,
  shareNotifications,
} from "../../../../redux/notifications/notificationReducer";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../../components/modals/confirmationModal";
import SuccessMessage from "../../../../components/modals/successMessage";
import moment from "moment";
import Delete from "../../../../assests/icons/deleteIcon.png";
import { formatNotificationData } from "../../../../utils/notificationformatter";
import errorToast from "../../../../utils/errorToast";
import successToast from "../../../../utils/successToast";
import useAxios from "../../../../hooks/useAxios";
import { ResponseType } from "../../../../modals/IreduxResponse";
import { useAppDispatch } from "../../../../redux/store";
import { LoadingSpinner } from "../../../../components/ui/loadinSpinner";
import { resetSharenotifications } from "../../../../redux/notifications/notificationSlice";
import ForwardIcon from "../../../../assests/icons/forwardIcon.svg";
import DescriptionSeeMoreModal from "../../seemoreModal";
import { BootstrapTooltip } from "../../../../utils/toolTip";
import { CharacterHidder, istDate } from "../../../../helper";
import DefaultImage from "../../../../assests/images/defaultImage.svg";
import { DownloadLink } from "../../../../utils/downloadLink";

const NotificationDetailCard = (props: any) => {
  const {
    notificationData,
    setSelected,
    selected,
    status,
    page,
    rowsPerPage,
    allSearch,
    isSendOption,
    type,
    systenCountFn,
  } = props;

  const { control } = useForm<any>();
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [forwardModal, setForwardModal] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [notificationDescription, setNotificationDescription] =
    useState<string>("");

  const getnotificationbyStatus = useSelector(
    (data: { getnotificationbyStatus: any }) => data.getnotificationbyStatus
  );

  useEffect(() => {
    if (getnotificationbyStatus?.status === "success") {
      const { data } = getnotificationbyStatus?.data;
      if (data.length > 0) {
        setNotifications(formatNotificationData(data));
      } else {
        setNotifications([]);
      }
    } else if (getnotificationbyStatus?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [getnotificationbyStatus]);

  useEffect(() => {
    setNotifications(notificationData);
  }, [notificationData]);

  const setSelectedIds = (ids: any, event: any) => {
    const isChecked = event.target.checked;
    if (typeof ids === "string") {
      if (isChecked) {
        setSelected([...selected, ids]);
      } else {
        setSelected([
          ...selected.filter((v: any) => {
            return v != ids;
          }),
        ]);
      }
    } else {
      if (isChecked) {
        const isTemp = selected.filter((v: any) => {
          return !ids.includes(v);
        });
        setSelected([...isTemp, ...ids]);
      } else {
        setSelected([
          ...selected.filter((v: any) => {
            return !ids.includes(v);
          }),
        ]);
      }
    }
  };

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: confirmDelete,
    toggle,
  } = useAxios({
    method: "delete",
    path: `Notification/notifications/delete`,
    body: [notificationId],
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Notification deleted successfully");
      setDeleteModal(false);
      setSelected([]);
      setNotificationId("");
      dispatch(
        getNotifications({
          status: status,
          order: "notification_sent",
          orderBy: "desc",
          page: page,
          rowsPerPage: rowsPerPage,
          searchQuery: "",
          searchKeywords: allSearch,
        })
      );
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
    if (type === "systemNotification") {
      systenCountFn();
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  const confirmShare = (id: string) => {
    dispatch(shareNotifications([id]));
  };

  const shareNotificationsRes = useSelector(
    (data: { shareNotifications: ResponseType }) => data?.shareNotifications
  );

  useEffect(() => {
    if (type === "systemNotification") {
      if (shareNotificationsRes?.status === "success") {
        setForwardModal(true);
        setSelected([]);
      } else if (shareNotificationsRes?.status === "failed") {
        errorToast("Failed to send message. Please try again later.");
      }
      systenCountFn();
    }
  }, [shareNotificationsRes]);

  useEffect(() => {
    return () => {
      dispatch(resetSharenotifications());
    };
  }, []);

  const isToday = (date: any) => {
    return moment(date).isSame(moment(), "day");
  };

  const toArray = (data: string) => {
    return data?.split(",");
  };

  return (
    <>
      <DescriptionSeeMoreModal
        isVisible={isModal}
        onClose={() => setIsModal(false)}
        data={notificationDescription}
      />
      {Object.keys(notificationData).map((v: any, i: any) => {
        return (
          <div
            className="overflow-hidden rounded-md shadow-md mt-3 mb-8"
            key={v}
          >
            <div
              className="bg-blue-150 flex items-center px-7"
              style={{ height: "56px" }}
            >
              <NotificationCheckBoxForm
                labelSize="text-18 ps-4"
                label={v}
                id={v}
                type="checkbox"
                control={control}
                required={false}
                onChange={(event: any) => {
                  let ids = notificationData[v].map((k: any) => {
                    return k.id;
                  });
                  setSelectedIds(ids, event);
                }}
                checked={notificationData[v]
                  .map((k: any) => {
                    return k.id;
                  })
                  .every((r: any) => {
                    return selected.includes(r);
                  })}
              />
            </div>
            <div className="bg-white">
              {notificationData[v].map((value: any, index: any) => {
                return (
                  <div
                    className="flex items-center px-4 mx-3 py-3"
                    key={value?.id}
                    style={{
                      borderBottom:
                        index != notificationData[v].length - 1
                          ? "solid 1px #EAECF0"
                          : "",
                    }}
                  >
                    <NotificationCheckBoxForm
                      label=""
                      id={value?.id}
                      type="checkbox"
                      control={control}
                      required={false}
                      onChange={(event: any) => {
                        setSelectedIds(value?.id, event);
                      }}
                      checked={selected.includes(value?.id)}
                    />
                    <div className="px-3">
                      <img
                        src={value?.logourl ? value?.logourl : DefaultImage}
                        width={56}
                        height={56}
                        className="border border-blue-50 rounded-full"
                        alt="Nofication Logo"
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <div
                        className={`flex max-lg:flex-col lg:items-center justify-between w-full ${
                          type === "SentNotification" ? "py-1" : "py-2"
                        }`}
                      >
                        {type === "systemNotification" ? (
                          <div className="flex flex-column">
                            <span className="font-semibold text-primary text-18">
                              {value?.subject}{" "}
                            </span>
                            {value?.notify_type === 2 && (
                              <div className="px-2">
                                <span
                                  className="p-1 px-3 rounded-full text-14"
                                  style={{
                                    color: "#C48117",
                                    backgroundColor: "#FFF8EC",
                                  }}
                                >
                                  Forwarded
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <div className="flex flex-column">
                              <span className="font-medium text-primary text-18">
                                <span className="font-semibold">To: </span>
                                {toArray(value?.hospital_name)
                                  ?.slice(0, 3)
                                  .map((item: any, index: number) => (
                                    <span key={index}>
                                      {index != 0 && ","}
                                      {item}
                                    </span>
                                  ))}
                                {toArray(value?.hospital_name)?.length > 3 && (
                                  <BootstrapTooltip
                                    title={
                                      <div className="p-1">
                                        {toArray(value?.hospital_name)?.map(
                                          (ser: any) => (
                                            <div
                                              className="text-secondary p-0.5 text-14 font-medium flex items-center gap-2"
                                              key={index}
                                            >
                                              <span className="text-18">
                                                &#x2022;
                                              </span>
                                              {ser}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    }
                                    placement="top"
                                  >
                                    <span className="ps-2 text-blue-300 font-semibold cursor-pointer">
                                      +
                                      {toArray(value?.hospital_name)?.length -
                                        3}{" "}
                                      more
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        <div
                          className={`flex ${
                            type === "SentNotification" && "justify-end"
                          } align-right text-primary text-14 font-semibold`}
                        >
                          <span
                            className={`font-medium px-2 text-14 ${
                              type === "systemNotification" &&
                              "border-r border-gray-250"
                            }`}
                          >
                            {isToday(value?.notification_sent)
                              ? moment(
                                  istDate(value?.notification_sent)
                                ).format("LT")
                              : moment(
                                  istDate(value?.notification_sent)
                                ).format("MMMM D, YYYY, LT")}
                          </span>
                          {type === "systemNotification" && (
                            <span className="font-medium ml-1">
                              <span className="">
                                {toArray(value?.hospital_name)
                                  ?.slice(0, 2)
                                  .map((item: any, index: number) => (
                                    <span key={index}>
                                      {index != 0 && ","}
                                      {item}
                                    </span>
                                  ))}
                                {toArray(value?.hospital_name)?.length > 2 && (
                                  <BootstrapTooltip
                                    title={
                                      <div className="p-1">
                                        {toArray(value?.hospital_name)?.map(
                                          (ser: any) => (
                                            <div
                                              className="text-secondary p-0.5 text-14 font-medium flex items-center gap-2"
                                              key={index}
                                            >
                                              <span className="text-18">
                                                &#x2022;
                                              </span>
                                              {ser}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    }
                                    placement="top"
                                  >
                                    <span className="ps-2 text-blue-300 font-semibold cursor-pointer">
                                      +
                                      {toArray(value?.hospital_name)?.length -
                                        2}{" "}
                                      more
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 justify-between w-full">
                        <div className="xl:col-span-11 col-span-10 text-secondary text-14 font-medium">
                          {type === "SentNotification" && (
                            <div className="flex pb-1">
                              <span className="font-semibold text-primary text-16">
                                {value?.subject}
                              </span>
                            </div>
                          )}
                          {value?.description?.length > 150 ? (
                            <span>
                              {value?.description?.substring(0, 150)} ...{" "}
                              <button
                                className="text-blue-300 font-semibold"
                                type="button"
                                onClick={() => {
                                  setNotificationDescription(
                                    value?.description
                                  );
                                  setIsModal(true);
                                }}
                              >
                                see more
                              </button>
                            </span>
                          ) : (
                            value?.description
                          )}
                          {type === "SentNotification" &&
                            value?.blobStorageDetail?.fileName && (
                              <div className="py-1 w-[250px]">
                                <div className="border-blue-300 border flex justify-between p-2 rounded">
                                  <div className="flex">
                                    <FileIcons />
                                    <div>
                                      <span className="px-1">
                                        {CharacterHidder(
                                          value?.blobStorageDetail?.fileName,
                                          18
                                        )}
                                        <span>
                                          {value?.blobStorageDetail?.fileType}
                                        </span>
                                      </span>
                                    </div>
                                  </div>

                                  <DownloadLink
                                    url={value?.blobStorageDetail?.blobUrl}
                                    fileName={`${value?.blobStorageDetail?.fileName}${value?.blobStorageDetail?.fileType}`}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="xl:col-span-1 col-span-2 flex gap-3 items-center justify-end ">
                          {isSendOption && (
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  confirmShare(value?.id);
                                  setNotificationId(value?.id);
                                }}
                                className="flex items-center"
                              >
                                {shareNotificationsRes?.isLoading &&
                                notificationId === value?.id ? (
                                  <LoadingSpinner width="w-4" height="h-4" />
                                ) : (
                                  <img src={ForwardIcon} />
                                )}
                              </button>
                            </div>
                          )}

                          <div>
                            <button
                              type="button"
                              onClick={() => {
                                setNotificationId(value?.id);
                                setDeleteModal(true);
                              }}
                              className={`${
                                type === "SentNotification" && "pe-3"
                              } flex items-center`}
                            >
                              <DeleteOutlinedIcons
                                styles={{ color: "#534FA1" }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <ConfirmationModal
        isVisible={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onClick={confirmDelete}
        icon={Delete}
        title={`Are you sure you want to delete these notifications ?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      <SuccessMessage
        isVisible={forwardModal}
        onClose={() => {
          setForwardModal(false);
          dispatch(
            getNotifications({
              status: status,
              order: "notification_sent",
              orderBy: "desc",
              page: page,
              rowsPerPage: rowsPerPage,
              searchQuery: "",
              searchKeywords: allSearch,
            })
          );
        }}
        title={`The notifications have been successfully forwarded!`}
      />
    </>
  );
};

export default NotificationDetailCard;
