import sideBarName from "../constants/sidebar";
import { AccessTokenDecoder } from "./accessTokenDecoder";
import enums from "../constants/enums";

const useManagementRoleType = () => {
  const userDetails = AccessTokenDecoder();
  const canWrite = (permission: string) => {
    const userRoles = [enums.SuperAdmin, enums.Management];
    const userPermissions = [
      sideBarName.mis,
      sideBarName.accountManagers,
      sideBarName.Logins,
      sideBarName.Feedback,
      "OnboardingHospital",
    ];
    const commonPermissions = [
      sideBarName.hospitalManagement,
      sideBarName.Devices,
      sideBarName.Notification,
    ];

    if (
      userPermissions?.includes(permission) &&
      userRoles?.includes(userDetails?.Roles)
    ) {
      return true;
    }

    if (commonPermissions?.includes(permission)) {
      return true;
    }

    return false;
  };

  return { canWrite };
};

export default useManagementRoleType;
