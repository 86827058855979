import { Navigate, Outlet } from "react-router-dom";
import DefaultLayout from "../layout/defaultLayout";
import constanst from "../constants";
import routesName from "../constants/routesName";

const PrivateRoutes = () => {
  return sessionStorage.getItem(constanst.isLoginLocalStorage) === "true" ? (
    <>
      <DefaultLayout>
        <Outlet />
      </DefaultLayout>
    </>
  ) : (
    <>
      <Navigate to={routesName.cmpHomePage} />
    </>
  );
};
export default PrivateRoutes;
