import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/store";
import useScreenSizeDetector from "../../../utils/useScreenSizeDetector";
import React, { useEffect, useRef, useState } from "react";
import { TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import DefaultProfileImage from "../../../assests/images/default-profile.jpg";
import DeleteIcon from "../../../assests/icons/deleteIcon.png";
import moment from "moment";
import { MoreOptionIcon } from "../../../components/muiIconsImport";
import routesName from "../../../constants/routesName";
import enums from "../../../constants/enums";
import StatusWithColor from "../../../utils/StatusWithColor";
import ConfirmationModal from "../../../components/modals/confirmationModal";
import AccountMResetPassword from "../resetPassword";
import {
  activateDeactiveAccManager,
  deleteAccManager,
  getAllAccontManager,
} from "../../../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import successToast from "../../../utils/successToast";
import errorToast from "../../../utils/errorToast";
import {
  resetActivateDeactiveAccManagerState,
  resetDeleteAccManagerState,
  resetResetPasswordAccManagerState,
} from "../../../redux/accountManager/accountManagerSlice";
import sortValues from "../../../constants/sortColumnValue";
import { TableHeaderType } from "../../../modals/Itables";
import { AccountManagerType } from "../../../modals/IaccountManager";
import { HospitalCount } from "../../../utils/hospitalCount";
import ActivateDeactivateIcon from "../../../assests/icons/activateDeactivateIcon.svg";
import useDisplayToggle from "../../../utils/useDisplayToggle";
import { istDate } from "../../../helper";
import useManagementRoleType from "../../../utils/managementRoleType";
import { AccessTokenDecoder } from "../../../utils/accessTokenDecoder";
import { ActionTooltip, DisabledTooltip } from "../../../utils/toolTip";

type SelectedUserData = {
  id?: number;
  isActive?: string;
  name?: string;
  employeeID?: string;
  photo?: string;
  isDelete?: boolean;
  email?: string;
  userName?: string;
  role?: string;
};

export const TblBodyAccountManager = ({
  labelData,
  selectedValuesOrder,
  accountManagerData,
  rowsPerPage,
}: {
  labelData: TableHeaderType[];
  selectedValuesOrder: string[];
  accountManagerData: AccountManagerType[] | undefined;
  rowsPerPage: number;
}) => {
  const styles = {
    // borderLeft: "1px solid #F2F4F7",
    borderRight: "1px solid #F2F4F7",
    borderBottom: "1px solid #F2F4F7",
    background: "white",
    minWidth: "120px",
    maxWidth: "120px",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontsize: "14px",
    color: "#667085",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
  };
  const filteredLabelData = labelData?.filter((item: any) =>
    selectedValuesOrder?.includes(item.value)
  );
  const navigate = useNavigate();
  const { width } = useScreenSizeDetector();
  const { canWrite } = useManagementRoleType();
  const userDetails = AccessTokenDecoder();
  const dispatch = useAppDispatch();
  const [resetPassword, setResetPassword] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activateDeactiveModal, setActivateDeactiveModal] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState<SelectedUserData>(
    {}
  );
  const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
  const [isMoreOption, setisMoreOption] = useState<any>(false);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisMoreOption,
  });

  useEffect(() => {
    if (isMoreOption === false) {
      setMoreOption(null);
    }
  }, [isMoreOption]);

  const toogleFn = (index: number) => {
    if (moreOption === index) {
      setisMoreOption(false);
      setMoreOption(null);
    } else {
      setMoreOption(index);
      setisMoreOption(true);
    }
  };

  const accountManagerViewFn = (id: number) => {
    navigate(`${routesName.accountManagerPage}/${id}`);
  };

  const editAccountManagerFn = (id: number) => {
    navigate(
      `${routesName.accountManagerPage}${routesName.personalInfoPage}/${enums.edit}`,
      { state: { id } }
    );
  };

  const deleteAccManagerFn = (id: number) => {
    setDeleteModal(true);
    setSelectedUserData({ id, isDelete: true });
  };

  const deleteDispatchAccManagerFn = () => {
    if (selectedUserData?.id) {
      dispatch(deleteAccManager(selectedUserData?.id));
    }
  };
  const deleteAccManagerRes = useSelector(
    (data: any) => data?.deleteAccManager
  );
  useEffect(() => {
    if (deleteAccManagerRes?.status === "success") {
      successToast(deleteAccManagerRes?.data?.message);
      dispatch(
        getAllAccontManager({
          order: "",
          orderBy: "",
          page: sortValues.page,
          rowsPerPage: rowsPerPage,
          searchQuery: "",
          searchKeywords: "",
        })
      );
    } else if (deleteAccManagerRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [deleteAccManagerRes]);

  const resetAccManagerFn = (data: {
    name: string;
    employeeID: string;
    photo: string;
    email: string;
    id: number;
    userName: string;
    role: string;
  }) => {
    setSelectedUserData(data);
    setResetPassword((prev: boolean) => !prev);
  };

  const activateeactivateFn = (data: { id: number; isActive: string }) => {
    setSelectedUserData(data);
    setActivateDeactiveModal(true);
  };

  const activeDeactiveDispatchFn = () => {
    if (selectedUserData?.id) {
      dispatch(
        activateDeactiveAccManager({
          userId: selectedUserData?.id,
          isActive: selectedUserData?.isActive === "deactivate" ? 0 : 1,
        })
      );
    }
  };

  const activateDeactiveAccManagerRes = useSelector(
    (data: any) => data?.activateDeactiveAccManager
  );

  useEffect(() => {
    if (activateDeactiveAccManagerRes?.status === "success") {
      successToast(activateDeactiveAccManagerRes?.data?.message);
      dispatch(
        getAllAccontManager({
          order: "",
          orderBy: "",
          page: sortValues.page,
          rowsPerPage: rowsPerPage,
          searchQuery: "",
          searchKeywords: "",
        })
      );
    } else if (activateDeactiveAccManagerRes?.status === "failed") {
      errorToast("Something went wrong");
    }
  }, [activateDeactiveAccManagerRes]);

  useEffect(() => {
    return () => {
      dispatch(resetActivateDeactiveAccManagerState());
      dispatch(resetDeleteAccManagerState());
      dispatch(resetResetPasswordAccManagerState());
    };
  }, []);

  const currentDate = new Date();
  const oneMonthsAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    currentDate.getDate()
  );

  return (
    <>
      <AccountMResetPassword
        isVisible={resetPassword}
        onClick={() => {}}
        onClose={() => {
          setResetPassword(false);
        }}
        data={{
          name: selectedUserData?.name ?? "",
          employeeID: selectedUserData?.employeeID ?? "",
          photo: selectedUserData?.photo ?? "",
          email: selectedUserData?.email ?? "",
          userId: selectedUserData?.id,
          userName: selectedUserData?.userName,
          role: selectedUserData?.role,
        }}
      />
      <ConfirmationModal
        isVisible={activateDeactiveModal}
        onClose={() => {
          setActivateDeactiveModal(false);
          setSelectedUserData({ isDelete: false });
        }}
        onClick={() => {
          activeDeactiveDispatchFn();
        }}
        icon={ActivateDeactivateIcon}
        title={`Are you sure you want to ${
          selectedUserData?.isActive === "deactivate"
            ? "Deactivate"
            : "Activate"
        }  this Account Manager?`}
        description=""
        actionText={
          selectedUserData?.isActive === "deactivate"
            ? "Deactivate"
            : "Activate"
        }
        isLoading={activateDeactiveAccManagerRes?.isLoading}
      />

      <ConfirmationModal
        isVisible={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onClick={() => {
          deleteDispatchAccManagerFn();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Account Manager?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteAccManagerRes?.isLoading}
      />
      <TableBody>
        {accountManagerData?.map((item: any, index: number) => (
          <React.Fragment key={index}>
            <TableRow key={index} className="" style={{ position: "relative" }}>
              {filteredLabelData?.map((columValue: any) => {
                const lastSignedIn = new Date(
                  item.lastSignedIn ?? item?.joiningDate
                );
                const isActive =
                  lastSignedIn > oneMonthsAgo && lastSignedIn <= currentDate;

                const editPermission =
                  userDetails?.Roles === enums.SuperAdmin &&
                  item?.role === enums.Management;
                return (
                  <React.Fragment key={`${index}-${columValue.value}`}>
                    {columValue.value === "action" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "80px",
                          maxWidth: "80px",
                          textAlign: "center",
                          height: "80px",
                          position: "relative",
                          // position: "sticky",
                          left: "0px",
                        }}
                      >
                        <ActionTooltip
                          // position={
                          //   index >= filteredLabelData?.length - 5
                          //     ? "bottom-0"
                          //     : "top-0"
                          // }
                          content={
                            editPermission ? (
                              <div
                                className={`p-2 absolute  ${
                                  index >= filteredLabelData?.length - 5
                                    ? "bottom-[-20px] "
                                    : "top-[-20px] "
                                } text-white text-14 bg-[#555] rounded-md ms-10 whitespace-nowrap`}
                              >
                                User are not authorized
                              </div>
                            ) : (
                              <div
                                className={`text-14 absolute ${
                                  index >= filteredLabelData?.length - 5
                                    ? "bottom-[-40px] left-8"
                                    : "top-[-20px] left-8"
                                } z-30 flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                                ref={insideClickRef}
                              >
                                <button
                                  type="button"
                                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                                  onClick={() => {
                                    editAccountManagerFn(item?.userId);
                                  }}
                                >
                                  Edit User
                                </button>
                                <button
                                  type="button"
                                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold"
                                  onClick={() => {
                                    resetAccManagerFn({
                                      name: item?.name,
                                      employeeID: item?.employeeID,
                                      photo: item?.photo,
                                      email: item?.email,
                                      id: item?.userId,
                                      userName: item?.userName,
                                      role: item?.role,
                                    });
                                  }}
                                >
                                  Reset Password
                                </button>
                                <button
                                  type="button"
                                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold"
                                  onClick={() => {
                                    activateeactivateFn({
                                      id: item?.userId,
                                      isActive:
                                        item?.isActive === 1
                                          ? "deactivate"
                                          : "activate",
                                    });
                                  }}
                                >
                                  {item?.isActive === 1
                                    ? "Deactivate User"
                                    : "Activate User"}
                                </button>

                                <button
                                  type="button"
                                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg"
                                  onClick={() => {
                                    deleteAccManagerFn(item?.userId);
                                  }}
                                >
                                  Delete User
                                </button>
                              </div>
                            )
                          }
                        >
                          <button
                            disabled={editPermission}
                            onClick={() => toogleFn(index)}
                            ref={onClickRef}
                            className={`text-blue-300 w-[24px] h-[24px] ${
                              editPermission && "cursor-not-allowed"
                            }`}
                            type="button"
                          >
                            {/* <BootstrapTooltip
                              title={
                                userDetails?.Roles === enums.SuperAdmin &&
                                item?.role === enums.Management ? (
                                  <div className="p-2 text-primary text-16">
                                    User are not authorized
                                  </div>
                                ) : (
                                  ""
                                )
                              }
                              placement="top"
                            > */}
                            <span>
                              <MoreOptionIcon />
                            </span>
                            {/* </BootstrapTooltip> */}
                          </button>
                        </ActionTooltip>

                        {/* {moreOption === index && isMoreOption && (
                          <div
                            className={`text-14  absolute z-30  ${
                              index >= filteredLabelData?.length - 5
                                ? "bottom-0"
                                : "top-15"
                            } left-15 flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                            ref={insideClickRef}
                          >
                            <button
                              type="button"
                              className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                              onClick={() => {
                                editAccountManagerFn(item?.userId);
                              }}
                            >
                              Edit User
                            </button>
                            <button
                              type="button"
                              className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold"
                              onClick={() => {
                                resetAccManagerFn({
                                  name: item?.name,
                                  employeeID: item?.employeeID,
                                  photo: item?.photo,
                                  email: item?.email,
                                  id: item?.userId,
                                  userName: item?.userName,
                                  role: item?.role,
                                });
                              }}
                            >
                              Reset Password
                            </button>
                            <button
                              type="button"
                              className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold"
                              onClick={() => {
                                activateeactivateFn({
                                  id: item?.userId,
                                  isActive:
                                    item?.isActive === 1
                                      ? "deactivate"
                                      : "activate",
                                });
                              }}
                            >
                              {item?.isActive === 1
                                ? "Deactivate User"
                                : "Activate User"}
                            </button>

                            <button
                              type="button"
                              className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg"
                              onClick={() => {
                                deleteAccManagerFn(item?.userId);
                              }}
                            >
                              Delete User
                            </button>
                          </div>
                        )} */}
                      </TableCell>
                    )}
                    {columValue.value === "firstname" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "250px",
                          maxWidth: "250px",
                          // position: `${width > 1536 ? "sticky" : "static"}`,
                          position: "sticky",
                          left: "0px",
                        }}
                      >
                        <Tooltip
                          title={
                            editPermission ? (
                              <div className="p-1 font-normal text-white text-14">
                                User are not authorized to view
                              </div>
                            ) : (
                              ""
                            )
                          }
                          placement="top"
                        >
                          <div
                            className={`grid grid-cols-5 items-center ${
                              editPermission
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } `}
                            onClick={() => {
                              if (editPermission) {
                              } else {
                                accountManagerViewFn(item?.userId);
                              }
                            }}
                          >
                            <div className="col-span-1">
                              <img
                                src={
                                  item?.photo
                                    ? item?.photo
                                    : DefaultProfileImage
                                }
                                className="rounded-full w-[36px] h-[36px]"
                                alt="hospital-logo"
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                            <div className="col-span-4 ps-2">
                              <div>
                                <span className="text-blue-300 text-16 font-semibold">
                                  {item?.name}
                                </span>
                              </div>

                              <span className="text-secondary text-14 font-medium">
                                Emp ID: {item?.employeeID}
                              </span>
                            </div>
                          </div>
                        </Tooltip>
                      </TableCell>
                    )}

                    {columValue.value === "gender" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "160px",
                          maxWidth: "160px",
                          // position: `${width > 1536 ? "sticky" : "static"}`,
                          // left: "530px",
                        }}
                      >
                        {item?.gender}
                      </TableCell>
                    )}

                    {columValue.value === "isactive" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "150px",
                          maxWidth: "150px",
                        }}
                      >
                        <StatusWithColor
                          value={
                            item?.isActive === 0
                              ? enums.deactivated
                              : isActive
                              ? enums.active
                              : enums.inactive
                          }
                        />
                      </TableCell>
                    )}

                    {columValue.value === "phone" && (
                      <TableCell style={styles}>{item?.phone}</TableCell>
                    )}
                    {columValue.value === "email" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "220px",
                          maxWidth: "220px",
                        }}
                      >
                        {item?.email}
                      </TableCell>
                    )}
                    {columValue.value === "no_of_hospitals" && (
                      <TableCell style={styles}>
                        <span className="text-blue-300">
                          {HospitalCount(item?.hospitalStatusWiseCount)}
                        </span>
                      </TableCell>
                    )}
                    {columValue.value === "rolename" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "150px",
                          maxWidth: "150px",
                        }}
                      >
                        {item?.role}
                      </TableCell>
                    )}
                    {columValue.value === "joiningdate" && (
                      <TableCell style={styles}>
                        {moment(istDate(item?.joiningDate)).format(
                          "DD MMM YYYY"
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "lastsignedin" && (
                      <TableCell style={styles}>
                        {item?.lastSignedIn
                          ? moment(istDate(item?.lastSignedIn)).format(
                              "DD MMM YYYY"
                            )
                          : "-"}
                      </TableCell>
                    )}
                  </React.Fragment>
                );
              })}
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </>
  );
};
