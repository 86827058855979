import { createTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";

export const selectTheme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#667085",
          "&.Mui-selected": {
            backgroundColor: "#D7E2FF !important",
            color: "#101828",
            fontWeight: 600,
            fontFamily: "Inter",
            fontSize: "16px",
          },
          "&:hover": {
            backgroundColor: "#ECF1FF !important",
            color: "#101828",
            fontWeight: 500,
            fontFamily: "Inter",
            fontSize: "16px",
          },
        },
      },
    },
  },
});

export const autoCompleteTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          color: "#667085",
        },
        option: {
          color: "#667085",
          "&.Mui-focusVisible, &.Mui-selected, &.Mui-focused": {
            backgroundColor: "#D7E2FF !important",
            color: "#101828",
            fontWeight: 600,
            fontFamily: "Inter",
            fontSize: "16px",
          },
          "&:hover": {
            backgroundColor: "#ECF1FF !important",
            color: "#101828",
            fontWeight: 500,
            fontFamily: "Inter",
            fontSize: "16px",
          },
        },
      },
    },
  },
});

export const multiSelectTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#D7E2FF !important",
            fontWeight: 600,
            fontFamily: "Inter",
            fontSize: "16px",
          },
          "&:hover": {
            backgroundColor: "#ECF1FF !important",
            fontWeight: 500,
            fontFamily: "Inter",
            fontSize: "16px",
          },
        },
      },
    },
  },
});

export const paginationTheme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          "&.Mui-selected": {
            backgroundColor: "#D7E2FF !important",
            fontWeight: 600,
            fontFamily: "Inter",
            fontSize: "16px",
          },
          "&:hover": {
            backgroundColor: "#ECF1FF !important",
            fontWeight: 500,
            fontFamily: "Inter",
            fontSize: "16px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#534FA1",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "#534FA1",
            opacity: "0.5",
          },
          color: "#534FA1",
        },
      },
    },
  },
});

// mis

export const CustomHeight = (theme: Theme) => ({
  [theme.breakpoints.up(2000)]: {
    height: "500px",
  },
  [theme.breakpoints.up(2500)]: {
    height: "600px",
  },
  [theme.breakpoints.up(3000)]: {
    height: "800px",
  },
  [theme.breakpoints.up(3500)]: {
    height: "1000px",
  },
  [theme.breakpoints.up(6000)]: {
    height: "1500px",
  },
});

export const dateErrorTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#91959D !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#101828 !important",
          },
        },
      },
    },
  },
});
