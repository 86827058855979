import { CheckBoxForm } from "../../components/forms/checkBoxForm";
import { InputForm, PhoneNumInputForm } from "../../components/forms/inputForm";
import SelectForm from "../../components/forms/selectForm";
import RadioForm from "../../components/forms/radioForm";
import { useSelector } from "react-redux";
import { labelObject } from "../../helper";

const OwnerOperatorDetails = ({
  control,
  setValue,
  watch,
  previousData,
}: any) => {
  const enumData = useSelector((data: any) => data?.enumData?.data);
  const OwnershipTypeList = labelObject(enumData?.OwnershipType?.data);

  return (
    <div>
      <div>
        <CheckBoxForm
          label={"Hospital is a part of Group"}
          id="Hospital_group"
          type="checkbox"
          control={control}
          required={false}
          defaultValue={previousData?.Hospital_group}
        />
      </div>

      {watch("Hospital_group") || previousData?.Hospital_group ? (
        <>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <div>
              <InputForm
                label={"Hospital Group Name*"}
                id="hospitalGroupName"
                control={control}
                required={watch("Hospital_group")}
                defaultValue={previousData?.hospitalGroupName}
                maxLength={50}
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <SelectForm
              label={"Ownership Type*"}
              id={"ownershipType"}
              options={OwnershipTypeList}
              control={control}
              required={watch("Hospital_group")}
              defaultValue={previousData?.ownershipType}
            />
            <InputForm
              label={"Owner Name*"}
              id="ownerName"
              control={control}
              required={watch("Hospital_group")}
              defaultValue={previousData?.ownerName}
              maxLength={50}
            />
            <PhoneNumInputForm
              label={"Mobile Number"}
              id="ownerMobileNumber"
              control={control}
              // required={watch("Hospital_group")}
              required={false}
              defaultValue={previousData?.ownerMobileNumber}
            />
          </div>
        </>
      ) : null}

      <div className="flex gap-3 my-3">
        <RadioForm
          label={"Operated by Owner: "}
          id="operator_by_owner"
          control={control}
          defaultValue={previousData?.operator_by_owner ?? "No"}
        />
      </div>
      {watch("operator_by_owner") === "No" ||
      previousData?.operator_by_owner === "No" ? (
        <>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 mt-6">
            <InputForm
              label={"Operator Name*"}
              id="operatorName"
              control={control}
              required={watch("operator_by_owner") === "Yes" ? false : true}
              defaultValue={previousData?.operatorName}
              maxLength={50}
            />
            <PhoneNumInputForm
              label={"Mobile Number"}
              id="operatorMobileNumber"
              control={control}
              // required={watch("operator_by_owner") === "Yes" ? false : true}
              required={false}
              defaultValue={previousData?.operatorMobileNumber}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OwnerOperatorDetails;
