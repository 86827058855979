import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../components/tables/dynamicTable";
import { PrimaryButton } from "../../../components/buttons/buttons";
import { useAppDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import { TblBodyProductPlan } from "../../../features/hospitalManagement/hospitalManagement/tableBody";
import { AllHospitalheaderDatas } from "../../../features/hospitalManagement/hospitalManagement/labelData";
import { allHospitalCheckBoxData } from "../../../features/hospitalManagement/hospitalManagement/checkboxData";
import { resetGetHospitalByIdState } from "../../../redux/addHospital/hospitalManagementSlice";
import sortValues from "../../../constants/sortColumnValue";
import { SearchQuery } from "../../../utils/searchQuery";
import enums from "../../../constants/enums";
import { hositalMatrixOptions } from "../../../jsonData/addHospitalsData";
import { HospitalMatrixType } from "../../../modals/IhospitalManagement";
import { LinkIcon } from "../../../components/muiIconsImport";
import {
  getHospitalsLinkedAccManager,
  getStatusWiseHospitalsLinkedAccManager,
} from "../../../redux/accountManager/accountManagerReducer";
import {
  resetGetHospitalsLinkedAccManagerState,
  resetGetStatusWiseHospitalsLinkedAccManagerState,
} from "../../../redux/accountManager/accountManagerSlice";
import HospitalLinkModal from "./hospitalLinkModal";
import SearchInput from "../../../components/forms/searchInput";
import { ResponseType } from "../../../modals/IreduxResponse";
import useDisplayToggle from "../../../utils/useDisplayToggle";
import SelectComponent from "../../../components/forms/selectComponent";
import ColumnSettingBtn from "../../../components/columnSetting/columnSettingBtn";
import { AccessTokenDecoder } from "../../../utils/accessTokenDecoder";

const AccManagerHospitalLinked = ({
  setActiveTab,
  setReload,
}: {
  setActiveTab: any;
  setReload: any;
}) => {
  const params = useParams();
  const userDetails = AccessTokenDecoder();
  const dispatch = useAppDispatch();
  const getAllHospitalRes = useSelector(
    (data: { getHospitalsLinkedAccManager: ResponseType }) =>
      data?.getHospitalsLinkedAccManager
  );

  const getStatusWiseAllHospitalRes = useSelector(
    (data: { getStatusWiseHospitalsLinkedAccManager: ResponseType }) =>
      data?.getStatusWiseHospitalsLinkedAccManager
  );

  const getAccontManagerRes = useSelector(
    (data: { getAccontManager: ResponseType }) => data.getAccontManager
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [filter, setFilter] = useState<any>({});
  const [hospitalLinkModal, setHospitalLinkModal] = useState(false);
  const [allSearch, setAllSearch] = useState("");
  const [hospitalMatrix, setHospitalMatrix] = useState<HospitalMatrixType>(
    enums.hospitalMatrixTypeAll
  );
  const [status, setStatus] = useState(enums.active);

  const searchQuery = SearchQuery({ filter });

  // all hospital table header data
  const headerData = AllHospitalheaderDatas({
    hospitalMatrix,
    hospitalOccupancy: enums.hospitalOccupancy,
    productPlan: enums.productPlan,
  });

  const [checkboxData, setCheckboxData] = useState(allHospitalCheckBoxData);
  const [labelData, setLabelData] = useState<any>(headerData);

  useEffect(() => {
    // handling ckeckbox and column header data based on inprogress or for all hospital
    setCheckboxData(allHospitalCheckBoxData);
    setLabelData(headerData);
    setSelectedValues(allHospitalCheckBoxData?.map((item) => item.value));
    setSelectedValuesOrder(allHospitalCheckBoxData?.map((item) => item.value));
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(
    checkboxData?.map((item) => item.value)
  );
  const [selectedValuesOrder, setSelectedValuesOrder] = useState<string[]>(
    checkboxData.map((item) => item.value)
  );
  // api call response data for all hospital
  const [currentHosiptalData, setCurrentHosiptalData] = useState<any>([]);

  const addHospitalFn = () => {
    setHospitalLinkModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(resetGetHospitalsLinkedAccManagerState());
      dispatch(resetGetStatusWiseHospitalsLinkedAccManagerState());
      dispatch(resetGetHospitalByIdState());
    };
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    if (params?.id) {
      if (status === "allStatus") {
        dispatch(
          getHospitalsLinkedAccManager({
            userId: parseInt(params?.id),
            order: order,
            orderBy: orderBy,
            page: page,
            rowsPerPage: rowsPerPage,
            searchQuery: searchQuery,
            searchKeywords: allSearch,
          })
        );
      } else if (status !== "allStatus") {
        dispatch(
          getStatusWiseHospitalsLinkedAccManager({
            userId: parseInt(params?.id),
            order: order,
            orderBy: orderBy,
            page: page,
            status: status,
            rowsPerPage: rowsPerPage,
            searchQuery: searchQuery,
            searchKeywords: allSearch,
          })
        );
      }
    }
  }, [order, orderBy, page, rowsPerPage, searchQuery, status, allSearch]);

  useEffect(() => {
    // for all hospiatl api call handling response

    if (status === "allStatus") {
      if (getAllHospitalRes?.status === "success") {
        setErrorMessage("");
        setCurrentHosiptalData(getAllHospitalRes?.data?.data ?? []);
      } else if (getAllHospitalRes?.status === "failed") {
        setErrorMessage("Something went Wrong");
      }
    } else if (status !== "allStatus") {
      if (getStatusWiseAllHospitalRes?.status === "success") {
        setErrorMessage("");
        setCurrentHosiptalData(getStatusWiseAllHospitalRes?.data?.data ?? []);
      } else if (getStatusWiseAllHospitalRes?.status === "failed") {
        setErrorMessage("Something went Wrong");
      }
    }
  }, [getAllHospitalRes, status, getStatusWiseAllHospitalRes]);

  useEffect(() => {
    // handling ckeckbox and column header data based on inprogress or for all hospital
    const updatedLabelData = headerData.filter((item: any) =>
      selectedValues.includes(item.value)
    );
    setLabelData(updatedLabelData);
  }, [selectedValues, hospitalMatrix]);

  const [initialCHKVal, setInitialCHKVal] = useState(selectedValues);
  const [initialCHKOrder, setInitialCHKOrder] = useState(selectedValuesOrder);

  const handleCheckboxChange = (value: string) => {
    // show and hide column based on checkbox
    if (initialCHKVal.includes(value)) {
      setInitialCHKVal(initialCHKVal.filter((item) => item !== value));
    } else {
      setInitialCHKVal([...initialCHKVal, value]);
    }

    const newOrder = initialCHKOrder.includes(value)
      ? initialCHKOrder.filter((item) => item !== value)
      : [...initialCHKOrder, value];
    setInitialCHKOrder(newOrder);
  };

  const handleApplyFilter = () => {
    setSelectedValues(initialCHKVal);
    setSelectedValuesOrder(initialCHKOrder);
  };

  const handleCancelFilter = () => {
    setInitialCHKVal(checkboxData?.map((item) => item.value));
    setInitialCHKOrder(checkboxData?.map((item) => item.value));
    setSelectedValues(checkboxData?.map((item) => item.value));
    setSelectedValuesOrder(checkboxData?.map((item) => item.value));
  };
  const [filterValues, setFilterValues] = useState(checkboxData);
  let hospitallinkSearchtimer: ReturnType<typeof setTimeout>;
  const handleFilterSearch = (inputValue: string) => {
    // const inputValue = e.target.value;
    clearTimeout(hospitallinkSearchtimer);
    hospitallinkSearchtimer = setTimeout(() => {
      const filteredValue = checkboxData.filter((item) =>
        item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );

      if (filteredValue) {
        setFilterValues(filteredValue);
      } else {
        setFilterValues(checkboxData);
      }
    }, 1000);
  };

  const statusOption = [
    { value: enums.active, label: "Onboarded" },
    { value: enums.inprogress, label: "In Progress" },
    { value: enums.expired, label: "Expired" },
    { value: enums.inactive, label: "Deactivated" },
    { value: "allStatus", label: "All" },
  ];

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setShowDropdown,
  });

  const editPermission =
    userDetails?.Roles === enums.SuperAdmin &&
    getAccontManagerRes?.data?.data?.role === enums.Management;

  return (
    <>
      <HospitalLinkModal
        isVisible={hospitalLinkModal}
        onClose={() => setHospitalLinkModal(false)}
        userId={params?.id}
        setActiveTab={setActiveTab}
        setReload={setReload}
      />
      <div className="shadow-sm1 border border-gray-250 mt-5 rounded-lg bg-white">
        <div className="flex justify-between items-center gap-6 my-4 px-6 max-md:flex-col max-lg:items-start">
          <div>
            <SearchInput setAllSearch={setAllSearch} />
          </div>
          <div className="flex items-center gap-3 max-xl:flex-col max-lg:items-start">
            <div>
              <SelectComponent
                label="Select"
                id="status"
                defaultValue={enums.active}
                value={status}
                onChange={(e: any) => {
                  setStatus(e.target.value);
                }}
                options={statusOption}
              />
            </div>
            <ColumnSettingBtn
              filterValues={filterValues}
              initialCHKVal={initialCHKVal}
              handleCheckboxChange={handleCheckboxChange}
              handleCancelFilter={handleCancelFilter}
              handleApplyFilter={handleApplyFilter}
              handleFilterSearch={handleFilterSearch}
            />
            <div>
              <SelectComponent
                label="Select"
                id="label"
                defaultValue={enums.hospitalMatrixTypeAll}
                value={hospitalMatrix}
                onChange={(e: any) => {
                  setHospitalMatrix(e.target.value as HospitalMatrixType);
                }}
                options={hositalMatrixOptions}
              />
            </div>

            <PrimaryButton
              type="button"
              padding="px-5 py-3"
              name=" Link a Hospital"
              icon={<LinkIcon />}
              onClick={addHospitalFn}
              disabled={editPermission}
            />
          </div>
        </div>

        <div className="h-[62vh]">
          <DynamicTable
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setorder={setorder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            filter={filter}
            setFilter={setFilter}
            columnData={labelData}
            children={
              <TblBodyProductPlan
                labelData={labelData}
                selectedValuesOrder={selectedValuesOrder}
                currentHosiptalData={currentHosiptalData}
              />
            }
            status={
              getAllHospitalRes?.isLoading ||
              getStatusWiseAllHospitalRes?.isLoading
            }
            errorMessage={
              currentHosiptalData?.length < 1 ? "No Data Found" : errorMessage
            }
            dataLength={
              status === "allStatus"
                ? getAllHospitalRes?.data?.count
                : getStatusWiseAllHospitalRes?.data?.count ?? 0
            }
            stickyWidth={[{ width: "0px" }, { width: "0px" }]}
          />
        </div>
      </div>
    </>
  );
};

export default AccManagerHospitalLinked;
