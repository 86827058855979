import CloseIcon from "@mui/icons-material/Close";
import DashboardCard from "../../components/ui/dashboardCard";
import ErrorIcon from "../../assests/icons/errorIcon.png";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onClick?: () => void;
  notificationError?: any;
};

const NotificationErrorModal = ({
  isVisible,
  onClose,
  onClick,
  notificationError,
}: ModalProps) => {
  const filterError = new Set(notificationError);
  const updatedFilterData = [...filterError];

  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="relative p-[20px]">
        <div className="absolute top-1 right-1">
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>

        <div className="px-3 flex flex-col items-center">
          <span>
            <img src={ErrorIcon} width={100} height={100} />
          </span>
          <span className="text-24 text-primary font-semibold pt-3">
            Mail Delivery Failure
          </span>
          <span className="text-secondary text-18 font-normal pt-3">
            Your messgae wasn't delivered to below hospitals
          </span>
        </div>
        <div className="px-7 text-start pt-2">
          {updatedFilterData?.map((item: any, index: number) => (
            <div
              className="text-start text-secondary text-18 font-normal"
              key={index}
            >
              {index + 1}. {item}
            </div>
          ))}
        </div>
      </div>
    </DashboardCard>
  );
};

export default NotificationErrorModal;
