import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDepartmentsList,
  getAllDiagnosisList,
  getNewHospitalsGraphData,
  getReasonsGraphData,
  getProductAndPlanGraphData,
  getBedSizeGraphData,
  getPatientDetailsDepartmentsGraphData,
  getPatientDetailsDiagnosisGraphData,
  getSpecialityGraphData,
  getCustomersGraphData,
  getOnboardingSourceGraphData,
  getActiveHospitalsGraphData,
  getExpiredAndDeactivatedHospitalsGraphData,
} from "./misReducer";

// get all hospital Departments
export const getAllDepartmentsListSlice = createSlice({
  name: "getAllDepartmentsList",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetAllDepartmentsListState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDepartmentsList.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllDepartmentsList.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getAllDepartmentsList.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getAllDepartmentsListreducer = getAllDepartmentsListSlice.reducer;
export const { resetGetAllDepartmentsListState } =
  getAllDepartmentsListSlice.actions;

// get all hospital Diagnosis
export const getAllDiagnosisListSlice = createSlice({
  name: "getAllDiagnosisList",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetAllDiagnosisListState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDiagnosisList.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllDiagnosisList.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getAllDiagnosisList.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getAllDiagnosisListreducer = getAllDiagnosisListSlice.reducer;
export const { resetGetAllDiagnosisListState } =
  getAllDiagnosisListSlice.actions;

// new hospitals api

export const getNewHospitalGraphDataSlice = createSlice({
  name: "getNewHospitalsGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetNewHospitalGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewHospitalsGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getNewHospitalsGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getNewHospitalsGraphData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getNewHospitalsGraphDatareducer =
  getNewHospitalGraphDataSlice.reducer;

export const { resetNewHospitalGraphData } =
  getNewHospitalGraphDataSlice.actions;

///////////////////////

export const getReasonsGraphDataSlice = createSlice({
  name: "getReasonsGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetReasonsGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReasonsGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getReasonsGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getReasonsGraphData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getReasonsGraphDatareducer = getReasonsGraphDataSlice.reducer;

export const { resetReasonsGraphData } = getReasonsGraphDataSlice.actions;

///////////////////////

export const getProductAndPlanGraphDataSlice = createSlice({
  name: "getProductAndPlanGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetProductAndPlanGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductAndPlanGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getProductAndPlanGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(
        getProductAndPlanGraphData.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});
export const getProductAndPlanGraphDatareducer =
  getProductAndPlanGraphDataSlice.reducer;

export const { resetProductAndPlanGraphData } =
  getProductAndPlanGraphDataSlice.actions;

////////////////////////////////////

export const getBedSizeGraphDataSlice = createSlice({
  name: "getBedSizeGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetBedSizeGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBedSizeGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getBedSizeGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getBedSizeGraphData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getBedSizeGraphDatareducer = getBedSizeGraphDataSlice.reducer;
export const { resetBedSizeGraphData } = getBedSizeGraphDataSlice.actions;

////////////////////

export const getSpecialityGraphDataSlice = createSlice({
  name: "getSpecialityGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetSpecialityGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpecialityGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getSpecialityGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getSpecialityGraphData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getSpecialityGraphDatareducer =
  getSpecialityGraphDataSlice.reducer;
export const { resetSpecialityGraphData } = getSpecialityGraphDataSlice.actions;

////////////////

export const getPatientDetailsDepartmentsGraphDataSlice = createSlice({
  name: "getPatientDetailsDepartmentsGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetPatientDetailsDepartmentsGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientDetailsDepartmentsGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(
        getPatientDetailsDepartmentsGraphData.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(
        getPatientDetailsDepartmentsGraphData.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});

export const getPatientDetailsDepartmentsGraphDatareducer =
  getPatientDetailsDepartmentsGraphDataSlice.reducer;
export const { resetPatientDetailsDepartmentsGraphData } =
  getPatientDetailsDepartmentsGraphDataSlice.actions;

///////////////

export const getPatientDetailsDiagnosisGraphDataSlice = createSlice({
  name: "getPatientDetailsDiagnosisGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetPatientDetailsDiagnosisGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientDetailsDiagnosisGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(
        getPatientDetailsDiagnosisGraphData.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(
        getPatientDetailsDiagnosisGraphData.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});

export const getPatientDetailsDiagnosisGraphDatareducer =
  getPatientDetailsDiagnosisGraphDataSlice.reducer;
export const { resetGetPatientDetailsDiagnosisGraphData } =
  getPatientDetailsDiagnosisGraphDataSlice.actions;

/////////////////////

export const getCustomersGraphDataSlice = createSlice({
  name: "getCustomersGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetCustomersGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getCustomersGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getCustomersGraphData.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getCustomersGraphDatareducer = getCustomersGraphDataSlice.reducer;
export const { resetgetCustomersGraphData } =
  getCustomersGraphDataSlice.actions;

//////////////////////////////////

export const getOnboardingSourceGraphDataSlice = createSlice({
  name: "getOnboardingSourceGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetOnboardingSourceGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOnboardingSourceGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getOnboardingSourceGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(
        getOnboardingSourceGraphData.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});

export const getOnboardingSourceGraphDatareducer =
  getOnboardingSourceGraphDataSlice.reducer;
export const { resetgetOnboardingSourceGraphData } =
  getOnboardingSourceGraphDataSlice.actions;

/////////////////////////////

export const getActiveHospitalsGraphDataSlice = createSlice({
  name: "getActiveHospitalsGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetActiveHospitalsGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveHospitalsGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getActiveHospitalsGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(
        getActiveHospitalsGraphData.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});

export const getActiveHospitalsGraphDatareducer =
  getActiveHospitalsGraphDataSlice.reducer;
export const { resetGetActiveHospitalsGraphData } =
  getActiveHospitalsGraphDataSlice.actions;

///////////////////////////////////////

export const getExpiredAndDeactivatedHospitalsGraphDataSlice = createSlice({
  name: "getExpiredAndDeactivatedHospitalsGraphData",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetExpiredAndDeactivatedHospitalsGraphData: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpiredAndDeactivatedHospitalsGraphData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(
        getExpiredAndDeactivatedHospitalsGraphData.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(
        getExpiredAndDeactivatedHospitalsGraphData.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});

export const getExpiredAndDeactivatedHospitalsGraphDatareducer =
  getExpiredAndDeactivatedHospitalsGraphDataSlice.reducer;
export const { resetGetExpiredAndDeactivatedHospitalsGraphData } =
  getExpiredAndDeactivatedHospitalsGraphDataSlice.actions;
