import Breadcrumbs from "../../components/breadCrumbs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddHospitalProgressBar from "../../features/hospitalManagement/addHospitalProgressBar";
import { SubmitHandler, useForm } from "react-hook-form";
import CustomAccordion from "../../components/ui/accordion";
import ManagementAssistant from "../../features/hospitalManagement/managementAssistant";
import {
  ErrorWithDeleteButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import ClinicalAssistant from "../../features/hospitalManagement/clinicalAssistant";
import CostDetails from "../../features/hospitalManagement/costDetails";
import { useEffect, useRef, useState } from "react";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import {
  addHospital,
  getDevicesList,
  getHospitalById,
  getProductServiceType,
  getProductType,
  getSpecialisationType,
  updateHospital,
} from "../../redux/addHospital/hospitalManagementReducer";
import ProductCheckBox from "../../components/hospitalManagement/productCheckbox";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import errorToast from "../../utils/errorToast";
import {
  managementStatus,
  resetAddHospitalState,
  resetUpdateHospitalState,
} from "../../redux/addHospital/hospitalManagementSlice";
import routesName from "../../constants/routesName";
import {
  CostDetailsObject,
  ProductDetailsObjects,
  OnBoardDispatchData,
} from "../../features/hospitalManagement/dataformatter";
import successToast from "../../utils/successToast";
import SuccessMessageModal from "../../components/hospitalManagement/successMessageModal";
import enums from "../../constants/enums";
import InnerLayout from "../../layout/innerLayout";
import {
  addHospitalNavData,
  editHospitalNavData,
} from "../../jsonData/breadcrumbData/hospitalManagement";
import useManagementRoleType from "../../utils/managementRoleType";
import useAxios from "../../hooks/useAxios";
import { hospitalApi } from "../../jsonData/apiPathData/hospital";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import ConfirmationModal from "../../components/modals/confirmationModal";
import UserAuditDetails from "../../utils/userAuditDetails";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import { ErrorMessageIcon } from "../../components/muiIconsImport";
import { commonDevices } from "../../redux/devices/deviceMangmentSlice";
import { findRepeatedValues } from "../../utils/findRepeatedValue";

const HospitalProductActivation = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathAction = location?.state?.action;
  const loactionId = location?.state?.id;
  const { canWrite } = useManagementRoleType();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [submitType, setSubmitType] = useState<string>(enums.continue);
  const [previousDataPA, setPreviousDataPA] = useState<any>(
    localStorage.getItem(addHospitalName.productActivation)
  );
  const [ShowModal, setShowModal] = useState(false);
  const [firstFormError, setFirstFormError] = useState("");
  const userDetails = AccessTokenDecoder();
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const scrollDown = useRef<null | HTMLDivElement>(null);
  // duplicate devices inditification
  const commanDevicesRes = useSelector((data: any) => data?.commanDevices);
  const [commanDevices, setCommanDevices] = useState<any>([]);
  useEffect(() => {
    setCommanDevices(commanDevicesRes?.data);
  }, [commanDevicesRes]);
  useEffect(() => {
    // getting product activation data from localStorage if there go in add flow with continue
    const previousData = localStorage.getItem(
      addHospitalName.productActivation
    );
    if (previousData) {
      setPreviousDataPA(previousData);
    }
    // product type api call
    dispatch(getProductType());
    // product service type api call
    dispatch(getProductServiceType());
    // device type api call
    dispatch(getDevicesList());
    // specialization type api call
    dispatch(getSpecialisationType());
    // for edit and inprogress initail data from api call
    if (pathAction === enums.edit || pathAction === enums.inprogress) {
      dispatch(getHospitalById(loactionId));
    }

    // return () => {
    //   resetGetHospitalByIdState();
    // };
  }, []);

  const hospitalDetailsRes = useSelector((data: any) => data?.getHospitalById);

  const productsData: any = useSelector((data: any) => data?.getProductType);
  const specialisationList = useSelector(
    (data: any) => data?.getSpecialisation
  );
  // const deleteHospitalRes = useSelector(
  //   (data: any) => data?.deleteHospitalById
  // );
  const hospitalInformation = localStorage.getItem(
    addHospitalName.hospitalInformation
  );
  const formattedHospitalInfo = JSON.parse(hospitalInformation as string);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors: formErrors },
  } = useForm<any>();
  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: userDetails?.userId,
    userName: userDetails?.userName,
    role: userDetails?.Roles,
    customer:
      pathAction === enums.add
        ? formattedHospitalInfo?.hospitalName
        : hospitalDetailsRes?.data?.data?.hospitalInfo?.name,
    customerCode: "",
    customerID:
      hospitalDetailsRes?.data?.data?.hospitalId ??
      "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: "string",
    auditDate: "2024-04-11T10:44:38.662Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    // getting hospital info from localStorage (used in add flow)
    const hospitalInformation = localStorage.getItem(
      addHospitalName.hospitalInformation
    );

    if (commanDevices?.length >= 1) {
      return errorToast("The devices are been duplicated");
    }

    // product type validation
    if (!data?.productInfo) {
      errorToast("Choose any Products");
    } else {
      const stringiftData = JSON.stringify(data);
      // storing product Activation in localstorage while only in adding flow
      if (pathAction === enums.add && submitType !== enums.saveAsDraft) {
        localStorage.setItem(addHospitalName.productActivation, stringiftData);
      }

      // adding hospital in production activation and saving as draft
      if (
        pathAction === enums.add &&
        submitType === enums.saveAsDraft &&
        hospitalInformation
      ) {
        const formattedHospitalInfo = JSON.parse(hospitalInformation);
        const finalData = { ...formattedHospitalInfo, ...data };
        const dispatchData = OnBoardDispatchData(
          finalData,
          specialisationList,
          userDetails,
          userAuditInfo
        );
        // removed hospiatl admin info while adding in product activation
        const dataWithoutHospitalAdminInfo = {
          ...dispatchData,
          hospitalAdminInfo: {},
        };
        dispatch(
          addHospital({
            isSaveAsDraft: true,
            dispatchData: dataWithoutHospitalAdminInfo,
          })
        );
      }
      // the inprogress hospital which is saved in hosital info as save as draft again saving as save as draft in product activation
      else if (
        pathAction === enums.inprogress &&
        submitType === enums.saveAsDraft
      ) {
        const finalData = {
          ...location?.state?.data,
          ...data,
          status: hospitalDetailsRes?.data?.data?.hospitalInfo?.status,
          isActive: hospitalDetailsRes?.data?.data?.hospitalInfo?.isActive,
          isDelete: hospitalDetailsRes?.data?.data?.hospitalInfo?.isDelete,
        };
        const dispatchData = OnBoardDispatchData(
          finalData,
          specialisationList,
          userDetails,
          userAuditInfo
        );
        // removed hospiatl admin info while adding in product activation
        const dataWithoutHospitalAdminInfo = {
          ...dispatchData,
          hospitalAdminInfo: {},
        };

        dispatch(
          updateHospital({
            id: loactionId,
            isSaveAsDraft: true,
            dispatchData: dataWithoutHospitalAdminInfo,
          })
        );
      }
      // the inprogress hospital which is saved in hosital info as save as draft continuing the flow
      else if (
        pathAction === enums.inprogress &&
        submitType === enums.continue
      ) {
        navigate(
          `${routesName.hospitalManagementPage}/hospital/${pathAction}${routesName.productActivationPage}${routesName.hospitalOnboardPage}`,
          {
            state: {
              id: loactionId,
              action: pathAction,
              data: { ...location.state.data, ...data },
            },
          }
        );
      }
      // for normal edit and add flow of hosipatl for this in add flow local storage also will be added for production activation
      else {
        navigate(
          `${routesName.hospitalManagementPage}/hospital/${pathAction}${routesName.productActivationPage}${routesName.hospitalOnboardPage}`,
          {
            state:
              pathAction === enums.edit
                ? {
                    id: loactionId,
                    action: pathAction,
                    data: { ...location?.state?.data, ...data },
                  }
                : pathAction === enums.add
                ? { action: pathAction }
                : null,
          }
        );
      }
    }
  };

  const addHospitalRes = useSelector((data: any) => data?.addHospital);
  const updateHospitalRes = useSelector((data: any) => data?.updateHospital);

  useEffect(() => {
    // add hospital Response handle
    if (!addHospitalRes?.isLoading && addHospitalRes?.data?.success) {
      successToast("Hospital Added Successfully");
      // navigate(routesName.hospitalManagementPage);
      setShowModal(true);
      localStorage.removeItem(addHospitalName.hospitalInformation);
      localStorage.removeItem(addHospitalName.productActivation);
    } else if (addHospitalRes?.status === "failed") {
      if (addHospitalRes?.error?.status === 503) {
        successToast("Hospital Added Successfully");
        setShowModal(true);
        localStorage.removeItem(addHospitalName.hospitalInformation);
        localStorage.removeItem(addHospitalName.productActivation);
      } else {
        errorToast(
          addHospitalRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [addHospitalRes]);
  useEffect(() => {
    // update hospital Response handle
    if (!updateHospitalRes?.isLoading && updateHospitalRes?.data?.success) {
      successToast("Hospital Updated Successfully");
      // navigate(routesName.hospitalManagementPage);
      setShowModal(true);
      localStorage.removeItem(addHospitalName.hospitalInformation);
      localStorage.removeItem(addHospitalName.productActivation);
    } else if (updateHospitalRes?.status === "failed") {
      if (updateHospitalRes?.error?.status === 503) {
        successToast("Hospital Updated Successfully");
        setShowModal(true);
        localStorage.removeItem(addHospitalName.hospitalInformation);
        localStorage.removeItem(addHospitalName.productActivation);
      } else {
        errorToast(
          updateHospitalRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [updateHospitalRes]);

  useEffect(() => {
    return () => {
      dispatch(resetAddHospitalState());
      dispatch(resetUpdateHospitalState());
    };
  }, []);

  // ckecking to show the clinical Assistant component based on checkbox tick
  const displayClinicalAssis = watch("productInfo")?.find(
    (item: any) => item.productId === 1001
  );

  const hospitalInfo = hospitalDetailsRes?.data?.data;
  const productAndPlanData = hospitalInfo?.productandPlanInfos;

  const productInfoData = productAndPlanData?.map((item: any) => {
    return item?.productInfo;
  });

  // getting product details
  const { managementAssistantObject, clinicalAssistantObject } =
    ProductDetailsObjects(hospitalInfo);

  const costDetailsObject = CostDetailsObject(hospitalInfo);

  const cancelHospitalFn = () => {
    localStorage.removeItem(addHospitalName.hospitalInformation);
    localStorage.removeItem(addHospitalName.productActivation);
    localStorage.removeItem(addHospitalName.hospitalOnboarding);
    navigate(routesName.hospitalManagementPage);
  };
  const deleteHospitalFn = () => {
    setIsDeleteModal(true);
  };

  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${loactionId}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital Deleted successfully");
      navigate(routesName.hospitalManagementPage);
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  const firstErrorField = Object.keys(formErrors).find(
    (fieldName) => formErrors[fieldName]
  );

  useEffect(() => {
    if (firstErrorField) {
      const errorMessage = formErrors?.[firstErrorField]?.message;
      setFirstFormError(errorMessage as string);
    }
  }, [firstErrorField]);

  const editHospitalNavData = [
    {
      label: "Hospital Management",
      navigate: `${routesName.hospitalManagementPage}`,
      highLight: false,
    },

    {
      label: `Edit ${hospitalInfo?.hospitalInfo?.name}`,
      highLight: true,
    },
  ];

  const errorShowOnBottom = () => {
    if (firstFormError) {
      scrollDown?.current?.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  };

  const getdeviceValue = (value: any) => {
    const deviceValue = watch(value);
    return deviceValue === "noDevices" || deviceValue === undefined
      ? []
      : deviceValue;
  };

  const maPackageDevices = getdeviceValue("maPackageDevices");
  const maPaidDevices = getdeviceValue("maPaidDevices");
  const clPackageDevices = getdeviceValue("clPackageDevices");
  const clPaidDevices = getdeviceValue("clPaidDevices");

  useEffect(() => {
    const mergedArray = [
      ...maPackageDevices,
      ...maPaidDevices,
      ...clPackageDevices,
      ...clPaidDevices,
    ];

    const serialNumberArray = mergedArray?.map(
      (item: any) => item?.serialNumber
    );
    const commonArray = findRepeatedValues(serialNumberArray);
    dispatch(commonDevices(commonArray));
  }, [
    watch("maPackageDevices"),
    watch("maPaidDevices"),
    watch("clPackageDevices"),
    watch("clPaidDevices"),
  ]);

  useEffect(() => {
    const isMaActive =
      productAndPlanData?.length <= 0
        ? true
        : productAndPlanData?.find(
            (item: any) => item?.productInfo?.productId === 1000
          )?.planInfo?.isActive === 1
        ? true
        : false;
    if (productAndPlanData) {
      dispatch(managementStatus(isMaActive));
    }
  }, [hospitalDetailsRes]);

  return (
    <>
      <SuccessMessageModal
        isVisible={ShowModal}
        onClose={() => {
          setShowModal(false);
          navigate(routesName.hospitalManagementPage);
          dispatch(resetAddHospitalState());
        }}
        title={"The hospital has been successfully activated!"}
        inprogress={true}
        productActivation={true}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      {(hospitalDetailsRes?.status === "pending" ||
        hospitalDetailsRes?.status === "") &&
      (pathAction === enums.edit || pathAction === enums.inprogress) ? (
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <InnerLayout
            title={
              hospitalInfo ? hospitalInfo?.hospitalInfo?.name : "Add Hospital"
            }
          >
            <div className="h-full relative">
              <div className="p-6 bg-blue-150 z-10 sticky top-[80px]">
                <Breadcrumbs
                  data={
                    pathAction === enums.add
                      ? addHospitalNavData
                      : editHospitalNavData
                  }
                />
                <div className="py-5">
                  <AddHospitalProgressBar active={2} />
                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="pb-20">
                <div className="px-6">
                  <div className="mt-5">
                    <div className="border-gray-250 border rounded-lg px-8 pt-5 pb-3 shadow-sm1">
                      <span className="text-18 font-semibold text-primary">
                        {addHospitalName.ChooseProducts}
                      </span>
                      {productsData?.status === "success" ? (
                        // <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 pt-4">
                        <div className="pt-4">
                          <ProductCheckBox
                            data={productsData?.data?.data}
                            setValue={setValue}
                            control={control}
                            name="productInfo"
                            defaultValue={
                              pathAction === enums.edit ||
                              pathAction === enums.inprogress
                                ? productInfoData
                                : JSON.parse(previousDataPA)?.productInfo
                            }
                            clearErrors={clearErrors}
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center py-4">
                          <LoadingSpinner />
                        </div>
                      )}
                    </div>
                  </div>
                  <CustomAccordion
                    title="Management Assistant"
                    component={
                      <ManagementAssistant
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        previousDataPA={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? managementAssistantObject
                            : JSON.parse(previousDataPA)
                        }
                        clearErrors={clearErrors}
                      />
                    }
                  />
                  {displayClinicalAssis && (
                    <CustomAccordion
                      title="Clinical Assistant"
                      component={
                        <ClinicalAssistant
                          control={control}
                          setValue={setValue}
                          watch={watch}
                          previousDataPA={
                            pathAction === enums.edit ||
                            pathAction === enums.inprogress
                              ? clinicalAssistantObject
                              : JSON.parse(previousDataPA)
                          }
                          clearErrors={clearErrors}
                        />
                      }
                    />
                  )}
                  <CustomAccordion
                    title="Cost Details"
                    component={
                      <CostDetails
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        previousDataPA={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? costDetailsObject
                            : JSON.parse(previousDataPA)
                        }
                      />
                    }
                  />
                  <div className="h-[2.5rem] flex justify-center">
                    {firstErrorField && (
                      <span className="text-error text-12 flex items-center">
                        <ErrorMessageIcon styles={{ width: "15px" }} /> :{" "}
                        {/* {firstFormError} */}
                        Mandatory fields are required to be filled
                      </span>
                    )}
                  </div>
                </div>

                <div className="fixed bottom-0 fit_width z-20 shadow-2xl">
                  <div className="bg-white p-3 px-7 flex justify-between">
                    {pathAction === enums.edit ||
                    pathAction === enums.inprogress ? (
                      <>
                        <ErrorWithDeleteButton
                          name={addHospitalName.DeleteHospital}
                          padding="lg:px-12 py-3 px-5"
                          type="button"
                          onClick={() => deleteHospitalFn()}
                        />
                      </>
                    ) : (
                      <SecondaryButton
                        padding="lg:px-18 py-3 px-5"
                        type="button"
                        name={addHospitalName.Cancel}
                        onClick={() => cancelHospitalFn()}
                      />
                    )}

                    <div className="flex gap-3">
                      {pathAction === enums.edit ||
                      // pathAction === enums.inprogress ||
                      productAndPlanData?.length > 0 ? (
                        <>
                          <SecondaryButton
                            padding="lg:px-18 py-3 px-5"
                            type="button"
                            name={addHospitalName.Cancel}
                            onClick={() => cancelHospitalFn()}
                          />
                        </>
                      ) : (
                        <SecondaryButton
                          padding="lg:px-18 py-3 px-5"
                          loading={
                            addHospitalRes?.isLoading ||
                            updateHospitalRes?.isLoading
                          }
                          name={addHospitalName.SaveAsDraft}
                          onClick={() => {
                            setSubmitType(enums.saveAsDraft);
                            errorShowOnBottom();
                          }}
                        />
                      )}
                      {canWrite("OnboardingHospital") && (
                        <PrimaryButton
                          padding="lg:px-18 py-3 px-5"
                          name={addHospitalName.Continue}
                          onClick={() => {
                            setSubmitType(enums.continue);
                            errorShowOnBottom();
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div ref={scrollDown}></div>
            </div>
          </InnerLayout>
        </>
      )}
    </>
  );
};

export default HospitalProductActivation;
