import { createSlice } from "@reduxjs/toolkit";
import {
  activateOrDeactivateAction,
  addHospital,
  createHospitalId,
  createHospitalUserId,
  deLinkDeviceAction,
  deleteHospitalById,
  getAccountAllList,
  getActiveHosCount,
  getActivePaidCount,
  getActivePilotCount,
  getAllHospital,
  getDevicesList,
  getExpiredHosCount,
  getHospitalById,
  getHospitalByStatus,
  getHospitalUserPassword,
  getInactiveHosCount,
  getInprogressHosCount,
  getOwnershipType,
  getProductServiceType,
  getProductType,
  getSpecialisationType,
  posthospitalLogo,
  updateHospital,
} from "./hospitalManagementReducer";

export const enumDataSlice = createSlice({
  name: "enumData",
  initialState: {
    data: {
      OwnershipType: {
        success: true,
        message: "OwnershipType retrieved successfully",
        data: [
          "government",
          "govt_aided",
          "corporate",
          "trust",
          "private_individual_partnership",
          "medical_institution",
          "research_institute",
        ],
        error: null,
      },
      AddOnServiceType: {
        success: true,
        message: "AddOnServiceType retrieved successfully",
        data: ["weekly", "monthly"],
        error: null,
      },
      BedStatus: {
        success: true,
        message: "BedStatus retrieved successfully",
        data: ["vaccant", "occupied", "booked"],
        error: null,
      },
      DeviceType: {
        success: true,
        message: "DeviceType retrieved successfully",
        data: ["package", "paid"],
        error: null,
      },
      HospitalsStatus: {
        success: true,
        message: "HospitalsStatus retrieved successfully",
        data: ["active", "expired", "inactive", "inprogress"],
        error: null,
      },
      Instalments: {
        success: true,
        message: "Instalments retrieved successfully",
        data: ["full_advance", "Installment_3_months", "Installment_5_months"],
        error: null,
      },
      PatientType: {
        success: true,
        message: "PatientType retrieved successfully",
        data: ["all", "in_patient", "out_patient"],
        error: null,
      },
      SourceType: {
        success: true,
        message: "SourceType retrieved successfully",
        data: [
          "distribution_partners",
          "on_field_sales",
          "inside_sales",
          "conference",
        ],
        error: null,
      },
      PlanType: {
        success: true,
        message: "PlanType retrieved successfully",
        data: ["per_patient", "per_month", "per_annum"],
        error: null,
      },

      ValidityPeriod: {
        success: true,
        message: "ValidityPeriod retrieved successfully",
        data: [
          "lifetime",
          "validity_12_months",
          "validity_3_months",
          "validity_6_months",
          "custom",
        ],
        error: null,
      },
      BedSizeType: {
        success: true,
        message: "BedSizeType retrieved successfully",
        data: [
          "bedsize_upto_30",
          "bedsize_31_to_50",
          "bedsize_51_to_100",
          "bedsize_more_than_100",
        ],
        error: null,
      },
    },
  },
  reducers: {
    addEnumData: (state, action) => {
      state.data = action.payload;
    },
    removeEnumData: (state) => {
      // state.data = {};
    },
  },
});

export const { addEnumData, removeEnumData } = enumDataSlice.actions;
export const enumDataReducer = enumDataSlice.reducer;

export const getOwnershipTypeSlice = createSlice({
  name: "getOwnershipType",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOwnershipType.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getOwnershipType.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getOwnershipType.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getOwnershipTypereducer = getOwnershipTypeSlice.reducer;

export const getProductTypeSlice = createSlice({
  name: "getProductType",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductType.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getProductType.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getProductType.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getProductTypereducer = getProductTypeSlice.reducer;

export const getProductServiceSlice = createSlice({
  name: "getProductService",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductServiceType.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getProductServiceType.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getProductServiceType.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getProductServicereducer = getProductServiceSlice.reducer;

export const getSpecialisationSlice = createSlice({
  name: "getSpecialisation",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecialisationType.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getSpecialisationType.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getSpecialisationType.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getSpecialisationreducer = getSpecialisationSlice.reducer;

export const getDevicesListSlice = createSlice({
  name: "getDevices",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDevicesList.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getDevicesList.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getDevicesList.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getDevicesreducer = getDevicesListSlice.reducer;

export const addHospitalSlice = createSlice({
  name: "addHospital",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetAddHospitalState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addHospital.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(addHospital.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(addHospital.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const addHospitalreducer = addHospitalSlice.reducer;
export const { resetAddHospitalState } = addHospitalSlice.actions;

export const updateHospitalSlice = createSlice({
  name: "updateHospital",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetUpdateHospitalState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateHospital.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(updateHospital.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(updateHospital.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const updateHospitalreducer = updateHospitalSlice.reducer;
export const { resetUpdateHospitalState } = updateHospitalSlice.actions;

// get hospital

export const getAllHospitalSlice = createSlice({
  name: "getAllHospital",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetAllHospitalState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllHospital.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllHospital.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getAllHospital.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const gerAllHospitalreducer = getAllHospitalSlice.reducer;
export const { resetGetAllHospitalState } = getAllHospitalSlice.actions;

export const getHospitalByIdSlice = createSlice({
  name: "getHospitalById",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetHospitalByIdState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHospitalById.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getHospitalById.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getHospitalById.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getHospitalByIdreducer = getHospitalByIdSlice.reducer;
export const { resetGetHospitalByIdState } = getHospitalByIdSlice.actions;

// delete hospital

export const deleteHospitalByIdSlice = createSlice({
  name: "deleteHospitalById",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetDeleteHospitalByIdState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteHospitalById.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(deleteHospitalById.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(deleteHospitalById.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const deleteHospitalByIdreducer = deleteHospitalByIdSlice.reducer;
export const { resetDeleteHospitalByIdState } = deleteHospitalByIdSlice.actions;

// get hospital status count
export const getHospitalByStatusSlice = createSlice({
  name: "getHospitalByStatus",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetHospitalByStatusState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHospitalByStatus.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getHospitalByStatus.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getHospitalByStatus.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getHospitalByStatusreducer = getHospitalByStatusSlice.reducer;
export const { resetgetHospitalByStatusState } =
  getHospitalByStatusSlice.actions;

export const getActiveHosCountSlice = createSlice({
  name: "getActiveHosCount",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetActiveHosCountState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveHosCount.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getActiveHosCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getActiveHosCount.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getActiveHosCountreducer = getActiveHosCountSlice.reducer;
export const { resetGetActiveHosCountState } = getActiveHosCountSlice.actions;

export const getExpiredHosCountSlice = createSlice({
  name: "getExpiredHosCount",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetExpiredHosCountState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpiredHosCount.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getExpiredHosCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getExpiredHosCount.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getExpiredHosCountreducer = getExpiredHosCountSlice.reducer;
export const { resetGetExpiredHosCountState } = getExpiredHosCountSlice.actions;

export const getInactiveHosCountSlice = createSlice({
  name: "getInactiveHosCount",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetInactiveHosCountState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInactiveHosCount.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getInactiveHosCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getInactiveHosCount.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getInactiveHosCountreducer = getInactiveHosCountSlice.reducer;
export const { resetGetInactiveHosCountState } =
  getInactiveHosCountSlice.actions;

export const getInprogressHosCountSlice = createSlice({
  name: "getInprogressHosCount",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetInprogressHosCountState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInprogressHosCount.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getInprogressHosCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getInprogressHosCount.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getInprogressHosCountreducer = getInprogressHosCountSlice.reducer;
export const { resetGetInprogressHosCountState } =
  getInprogressHosCountSlice.actions;

export const getActivePaidCountSlice = createSlice({
  name: "getActivePaidCount",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetActivePaidCountState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivePaidCount.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getActivePaidCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getActivePaidCount.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getActivePaidCountreducer = getActivePaidCountSlice.reducer;
export const { resetgetActivePaidCountState } = getActivePaidCountSlice.actions;

export const getActivePilotCountSlice = createSlice({
  name: "getActivePilotCount",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetActivePilotCountState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivePilotCount.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getActivePilotCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getActivePilotCount.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getActivePilotCountreducer = getActivePilotCountSlice.reducer;
export const { resetgetActivePilotCountState } =
  getActivePilotCountSlice.actions;

// image upload
export const posthospitalLogoSlice = createSlice({
  name: "posthospitalLogo",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetPosthospitalLogoState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(posthospitalLogo.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(posthospitalLogo.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(posthospitalLogo.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const posthospitalLogoreducer = posthospitalLogoSlice.reducer;
export const { resetPosthospitalLogoState } = posthospitalLogoSlice.actions;

// create hospital id
export const createHospitalIdSlice = createSlice({
  name: "createHospitalId",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetCreateHospitalIdState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHospitalId.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(createHospitalId.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(createHospitalId.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const createHospitalIdreducer = createHospitalIdSlice.reducer;
export const { resetCreateHospitalIdState } = createHospitalIdSlice.actions;

// create hospital user id
export const createHospitalUserIdSlice = createSlice({
  name: "createHospitalUserId",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetCreateHospitalUserIdState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHospitalUserId.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(createHospitalUserId.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(createHospitalUserId.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const createHospitalUserIdreducer = createHospitalUserIdSlice.reducer;
export const { resetCreateHospitalUserIdState } =
  createHospitalUserIdSlice.actions;

// get hospital user password
export const getHospitalUserPasswordSlice = createSlice({
  name: "getHospitalUserPassword",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetHospitalUserPasswordState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHospitalUserPassword.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getHospitalUserPassword.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getHospitalUserPassword.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getHospitalUserPasswordreducer =
  getHospitalUserPasswordSlice.reducer;
export const { resetGetHospitalUserPasswordState } =
  getHospitalUserPasswordSlice.actions;

// product activate or deactivate
export const activateOrDeactivateSlice = createSlice({
  name: "activateOrDeactivate",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetActivateOrDeactivateState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activateOrDeactivateAction.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(activateOrDeactivateAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(
        activateOrDeactivateAction.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});

export const activateOrDeactivatereducer = activateOrDeactivateSlice.reducer;
export const { resetActivateOrDeactivateState } =
  activateOrDeactivateSlice.actions;

// de link devices
export const deLinkDeviceSlice = createSlice({
  name: "deLinkDevice",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetDeLinkDeviceState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deLinkDeviceAction.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(deLinkDeviceAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(deLinkDeviceAction.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const deLinkDevicereducer = deLinkDeviceSlice.reducer;
export const { resetDeLinkDeviceState } = deLinkDeviceSlice.actions;

// get account manager list
export const getAccountAllListSlice = createSlice({
  name: "getAccountAllList",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetAccountAllListState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountAllList.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAccountAllList.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;

        state.isLoading = false;
      })
      .addCase(getAccountAllList.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getAccountAllListreducer = getAccountAllListSlice.reducer;
export const { resetGetAccountAllListState } = getAccountAllListSlice.actions;

export const managementStatusSlice = createSlice({
  name: "managementStatus",
  initialState: {
    data: true,
  },
  reducers: {
    managementStatus: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const managementStatusSliceReducer = managementStatusSlice.reducer;
export const { managementStatus } = managementStatusSlice.actions;
