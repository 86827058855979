import { useEffect, useRef, useState } from "react";
import useDisplayToggle from "../../utils/useDisplayToggle";
import {
  ArrowDropDown,
  ArrowDropUp,
  SearchIcons,
} from "../../components/muiIconsImport";
import { useAppDispatch } from "../../redux/store";
import { getHospitalsIdList } from "../../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";

const HospitalNameSelectDropdown = ({
  name,
  setValue,
  watch,
  clearErrors,
  isReset,
  widthWithPosition = "w-[100%]",
}: {
  name: any;
  setValue: any;
  watch: any;
  clearErrors: any;
  isReset: any;
  widthWithPosition?: any;
}) => {
  const { country, district, hobli, state, taluk } = watch();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedHospitals, setSelectedHospitals] = useState<any>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hospitalIdLists, sethospitalIdLists] = useState<any>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getHospitalsIdList());
  }, []);
  const getHospitalsIdListRes = useSelector(
    (state: any) => state.getHospitalsIdList
  );
  useEffect(() => {
    if (getHospitalsIdListRes.status === "success") {
      sethospitalIdLists(getHospitalsIdListRes.data.data);
    } else if (getHospitalsIdListRes.status === "failed") {
      console.error(getHospitalsIdListRes, "getHospitalsIdListRes");
    }
  }, [getHospitalsIdListRes]);
  const [filterValues, setFilterValues] = useState(hospitalIdLists);

  useEffect(() => {
    setFilterValues(hospitalIdLists);
  }, [hospitalIdLists]);

  useEffect(() => {
    setSelectedHospitals([]);
    setSelectAllChecked(false);
  }, [isReset]);

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = hospitalIdLists?.filter((item: any) =>
      item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
    );

    if (filteredValue) {
      setFilterValues(filteredValue);
    } else {
      setFilterValues(hospitalIdLists);
    }
  };

  useEffect(() => {
    const filteredValue = getHospitalsIdListRes.data.data?.filter(
      (item: any) => {
        if (country && country !== item.country) return false;
        if (state && state !== item.state) return false;
        if (district && district !== item.district) return false;
        if (taluk && taluk !== item.taluk) return false;
        if (hobli && hobli !== item.hobli) return false;
        return true;
      }
    );
    if (filteredValue) {
      sethospitalIdLists(filteredValue);
    } else {
      sethospitalIdLists(getHospitalsIdListRes.data.data);
    }
  }, [country, district, hobli, state, taluk]);

  const selectedHospitalNames = selectedHospitals.map(
    (name: any) =>
      filterValues.find((hospital: any) => hospital.name === name)?.name
  );

  const handleCheckboxChange = (value: string) => {
    // show and hide column based on checkbox
    if (selectedHospitals?.includes(value)) {
      setSelectedHospitals(
        selectedHospitals.filter((item: any) => item !== value)
      );
    } else {
      setSelectedHospitals([...selectedHospitals, value]);
    }
  };
  useEffect(() => {
    if (selectedHospitalNames?.length === hospitalIdLists?.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedHospitalNames, hospitalIdLists]);
  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setSelectedHospitals([]);
    } else {
      const allHospitalIds = filterValues.map((item: any) => item.name);
      setSelectedHospitals(allHospitalIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  useEffect(() => {
    setValue(name, selectedHospitals);
    if (selectedHospitals?.length > 0) {
      clearErrors(name);
    }
  }, [selectedHospitals]);

  useEffect(() => {
    setSelectedHospitals([]);
  }, [country, state, district, taluk, hobli]);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setShowDropdown,
  });

  const CharacterHidder = (value: string, count: number) => {
    if (value && value.length > count) {
      return `${value.substring(0, count)}...`;
    } else {
      return value;
    }
  };

  const truncatedName = CharacterHidder(selectedHospitalNames[0], 8);
  const remainingCount = selectedHospitalNames.length - 1;
  const namess = `${truncatedName} (+ ${remainingCount} more)`;
  const fristValue = CharacterHidder(selectedHospitalNames[0], 15);

  return (
    <div className="relative w-[100%]">
      <button
        type="button"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        ref={onClickRef}
        className={` ${
          showDropdown
            ? "border outline outline-1 border-blue-350"
            : "border hover:border-blue-350 border-[#C5C7C8]"
        }  text-primary ps-5 pe-2 py-3 rounded w-full text-16 font-semibold text-start flex justify-between bg-white`}
      >
        {/* {selectedHospitalNames?.length === filterValues?.length ? "All Hospitals" : selectedHospitalNames?.join(", ")} */}
        {filterValues?.length === 0 ? (
          <span className="text-[757B84] font-normal">Select Hospital</span>
        ) : selectedHospitalNames?.length === filterValues?.length ? (
          "All Hospitals"
        ) : selectedHospitalNames?.length === 0 ? (
          <span className="text-[757B84] font-normal">Select Hospital</span>
        ) : selectedHospitalNames?.length === 1 ? (
          fristValue
        ) : (
          namess
        )}

        <span className="text-blue-300">
          {showDropdown ? <ArrowDropUp /> : <ArrowDropDown />}
        </span>
      </button>

      <label className="absolute text-12 font-normal text-primary left-3 px-1 bg-white top-[-8px] ">
        {/* List of Hospitals */}
      </label>

      <div
        className={` absolute z-20 top-16 bg-white px-2 py-4 shadow-md rounded-lg ${
          showDropdown ? "visible" : "invisible"
        }`}
        ref={insideClickRef}
      >
        {getHospitalsIdListRes?.isLoading ? (
          <div className="flex justify-center py-4">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="w-[100%] border border-gray-450 py-2 px-3 rounded mb-3 flex">
              <input
                className="rounded-none outline-none w-[100%] text-16 font-400 text-primary"
                onChange={(e) => handleFilterSearch(e)}
                placeholder="Search"
              />
              <span className="text-blue-300">
                <SearchIcons />
              </span>
            </div>

            <div className="h-[272px] overflow-y-auto scroll_bar_with_side">
              {filterValues?.length >= 1 && (
                <div
                  className={`${
                    selectAllChecked ? "bg-blue-150" : ""
                  } p-3 flex gap-3 items-center`}
                >
                  <input
                    id="selectAll"
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                    className={`checkbox-input`}
                  />
                  <label
                    htmlFor="selectAll"
                    className={`font-semibold text-14 cursor-pointer ${
                      selectAllChecked ? "text-primary" : "text-secondary"
                    }`}
                  >
                    All Hospitals
                  </label>
                </div>
              )}

              {filterValues?.map((item: any) => (
                <div
                  className={`${
                    selectedHospitals?.includes(item.hospitalId)
                      ? "bg-blue-150"
                      : ""
                  } p-3 flex gap-3 items-center`}
                  key={item.hospitalId}
                >
                  <input
                    type="checkbox"
                    checked={selectedHospitals?.includes(item.name)}
                    id={item.hospitalId}
                    name={item.hospitalId}
                    onChange={() => handleCheckboxChange(item.name)}
                    className="checkbox-input"
                  />
                  <label
                    htmlFor={item.hospitalId}
                    className={`font-semibold text-14 ${
                      selectedHospitals?.includes(item.hospitalId)
                        ? "text-primary"
                        : "text-secondary"
                    } cursor-pointer`}
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HospitalNameSelectDropdown;
