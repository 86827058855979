import HospiatDetailsCard from "../../features/hospitalManagement/hospiatDetailsCard";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HospitalDevices from "../../features/hospitalManagement/hospitalHomePageTabs/devices";
import { useAppDispatch } from "../../redux/store";
import { getHospitalById } from "../../redux/addHospital/hospitalManagementReducer";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { resetGetHospitalByIdState } from "../../redux/addHospital/hospitalManagementSlice";
import HospitalDashBoardTab from "../../features/hospitalManagement/hospitalHomePageTabs/dashBoard";
import HosPlanInformation from "../../features/hospitalManagement/hospitalHomePageTabs/planInformation";
import Breadcrumbs from "../../components/breadCrumbs";
import InnerLayout from "../../layout/innerLayout";
import routesName from "../../constants/routesName";
import DynamicTabs from "../../components/dynamicTabs";
import HospitalInformation from "../../features/hospitalManagement/hospitalHomePageTabs/hospitalInformation";
import Infrastructure from "../../features/hospitalManagement/hospitalHomePageTabs/infrastructure";
import Staff from "../../features/hospitalManagement/hospitalHomePageTabs/staff";
import ServicePricing from "../../features/hospitalManagement/hospitalHomePageTabs/servicePricing";
import PharmaDatabase from "../../features/hospitalManagement/hospitalHomePageTabs/pharmaDatabase";
import LoginsHospitalInfo from "../../features/hospitalManagement/hospitalHomePageTabs/loginsHospitalInfo";
import RoundedDynamicTabs from "../../components/roundedDynamicTabs";

const HospitalDetails = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("Dashboard");

  useEffect(() => {
    if (params?.id) {
      dispatch(getHospitalById(params?.id));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetGetHospitalByIdState());
    };
  }, []);

  const hospitalDetailsRes = useSelector((data: any) => data?.getHospitalById);

  const hospitalBasicInfo = hospitalDetailsRes?.data?.data?.hospitalInfo;
  const managementInfo =
    hospitalDetailsRes?.data?.data?.hospitalInfo?.managementUserInfo;
  const accountManagerInfo =
    hospitalDetailsRes?.data?.data?.hospitalInfo?.accountManager;
  const hospitalAdminInfo = hospitalDetailsRes?.data?.data?.hospitalAdminInfo;

  const HospitalNavData = [
    {
      label: "Hospital Management",
      navigate: `${routesName.hospitalManagementPage}`,
      highLight: false,
    },

    {
      label: `${hospitalBasicInfo?.name}`,
      highLight: true,
    },
  ];
  const tabsData = [
    {
      name: "Dashboard",
      component: (
        <HospitalDashBoardTab hospitalDetailsRes={hospitalDetailsRes} />
      ),
    },
    {
      name: "Hospital Information",
      component: <HospitalInformation />,
    },
    {
      name: "Plan Information",
      component: <HosPlanInformation hospitalDetailsRes={hospitalDetailsRes} />,
    },
    {
      name: "Devices",
      component: <HospitalDevices hospitalDetailsRes={hospitalDetailsRes} />,
    },
    {
      name: "Infrastructure",
      component: <Infrastructure />,
    },
    {
      name: "Staff",
      component: <Staff />,
    },
    {
      name: "Services & Pricing",
      component: <ServicePricing />,
    },
    {
      name: "Pharma Database",
      component: <PharmaDatabase />,
    },
    {
      name: "Logins",
      component: <LoginsHospitalInfo />,
    },
  ];

  return (
    <>
      {hospitalDetailsRes?.isLoading ? (
        <div className="h-full flex justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <InnerLayout title={`${hospitalBasicInfo?.name}`}>
            <div className="p-6 z-10">
              <Breadcrumbs data={HospitalNavData} />
              <div className="mt-5">
                <HospiatDetailsCard
                  hospitalBasicInfo={hospitalBasicInfo}
                  managementInfo={managementInfo}
                  accountManagerInfo={accountManagerInfo}
                  hospitalAdminInfo={hospitalAdminInfo}
                  params={params}
                />
              </div>

              <div className="mt-6 relative">
                <RoundedDynamicTabs
                  tabs={tabsData}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          </InnerLayout>
        </>
      )}
    </>
  );
};

export default HospitalDetails;
