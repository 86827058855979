export function formatIndianRupee(amount: number | null): string {
    if (amount === null) {
        return "₹ 0";
    }

    // Convert the number to a string
    const amountStr: string = amount.toString();

    // Get the last three digits
    const lastThree: string = amountStr.substring(amountStr.length - 3);

    // Get the other numbers
    let otherNumbers: string = amountStr.substring(0, amountStr.length - 3);

    // Add a comma before the last three digits if otherNumbers is not empty
    if (otherNumbers !== '') {
        otherNumbers += ',';
    }

    // Group the other numbers in pairs of two
    const formattedOtherNumbers: string = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

    // Combine the other numbers and the last three digits
    const formattedAmount: string = formattedOtherNumbers + lastThree;

    // Return the formatted amount with the currency symbol
    return `₹ ${formattedAmount}`;
}
