// import { useState, useEffect, ReactNode } from "react";
// import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
// import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
// import constants from "../constants";

import axios from "axios";
import { useEffect, useState } from "react";
import constants from "../constants";
import api, { baseURL } from "../datasource/api";

// axios.defaults.baseURL = "https://localhost:7032/";
// axios.defaults.headers["x-api-key"] = "abcdef12345";
// axios.defaults.headers["Content-Type"] = "application/json";
// axios.defaults.withCredentials = true;

// const useAxios = (axiosParams: AxiosRequestConfig) => {
//   const [response, setResponse] = useState<AxiosResponse>();
//   const [error, setError] = useState<AxiosError | unknown | ReactNode>();
//   const [loading, setLoading] = useState(
//     axiosParams.method === "GET" || axiosParams.method === "get"
//   );
//   const [reload, setReload] = useState(false);
//   useEffect(() => {
//     // axios.defaults.headers["Authorization"] = `Bearer ${Cookies.get(constants.accessTokenText)}`;
//   }, [reload]);

//   const navigate = useNavigate();
//   const fetchData = async (params: AxiosRequestConfig) => {
//     try {
//       const result = await axios.request(params);
//       setResponse(result);
//     } catch (err: any) {
//       setError(err);
//       const errMessage = err?.response?.data;
//       // if (errMessage === "{Status:401,message:not autherized}") {
//       //   // fetchData(params)
//       //   Cookies.remove("accessToken");
//       //   sessionStorage.clear();
//       //   navigate("/");
//       // }
//       if (errMessage?.Status === "Authentication Invalid") {
//         await axios
//           .post("https://localhost:7032/Login/refreshtoken", {
//             refreshToken: Cookies.get("refreshToken123"),
//             email: localStorage.getItem(constants.emailTextLocalStorage),
//           })
//           .then((data) => {
//             setReload(!reload);
//             sessionStorage.setItem(constants.accessTokenText, data?.data?.accessToken);
//             fetchData(params);
//           })
//           .catch((data) => {
//             console.log(data);
//           });
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const sendData = () => {
//     fetchData(axiosParams);
//   };

//   useEffect(() => {
//     if (axiosParams.method === "GET" || axiosParams.method === "get") {
//       fetchData(axiosParams);
//     }
//   }, []);

//   return { response, error, loading, sendData };
// };

// export default useAxios;

const useAxios = ({
  method,
  path,
  body = {},
}: {
  method: string;
  path: string;
  body?: any;
}) => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const axiosData = {
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem(
        constants.accessTokenText
      )}`,
    },
    withCredentials: true,
  };

  useEffect(() => {
    if (method === "get" || method === "GET") {
      setLoading(true);
      api
        .get(`${baseURL}${path}`)
        .then((response) => {
          setLoading(false);
          setError(null);
          setData(response?.data);
        })
        .catch((error) => {
          setLoading(false);
          setData(null);
          setError(error);
        });
    }
  }, []);

  const deleteFn = () => {
    setLoading(true);
    api
      .delete(`${baseURL}${path}`, { data: body ?? null })
      .then((response) => {
        setLoading(false);
        setData(response?.data);
        setError(null);
        setToggle((prev) => !prev);
      })
      .catch((error) => {
        setLoading(false);
        setData(null);
        setError(error?.response?.data);
        setToggle((prev) => !prev);
      });
  };

  const postFn = () => {
    setLoading(true);
    api
      .post(`${baseURL}${path}`, body)
      .then((response) => {
        setLoading(false);
        setData(response?.data);
        setError(null);
        setToggle((prev) => !prev);
      })
      .catch((error) => {
        setLoading(false);
        setData(null);
        setError(error?.response?.data);
        setToggle((prev) => !prev);
      });
  };

  return { data, error, loading, deleteFn, toggle, postFn };
};

export default useAxios;
