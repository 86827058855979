const ErrorPage = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <span className="text-primary text-24 font-semibold">Something went Wrong</span>
      <p className="text-primary text-24 font-semibold">Please try again some time</p>
    </div>
  );
};

export default ErrorPage;
