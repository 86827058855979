import { useEffect, useState } from "react";
import CustomAccordion from "../../components/ui/accordion";
import ProductActivationDevices from "../../features/hospitalManagement/devices";
import SelectForm from "../../components/forms/selectForm";
import {
  patientTypeData,
  planTypeData,
  serviceTypeData,
  validityData,
} from "../../jsonData/addHospitalsData";
import { AmountInputForm, InputForm } from "../../components/forms/inputForm";
import { SelectChangeEvent } from "@mui/material";
import DataPickForm from "../../components/forms/dataPickForm";
import moment from "moment";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useSelector } from "react-redux";
import ProductServiceCheckBox from "../../components/hospitalManagement/productServiceCheckBox";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { clinicalAssistantListData } from "./dataformatter";
import { ThemeProvider } from "@mui/material/styles";
import { dateErrorTheme } from "../../components/themeProvider";

const ClinicalAssistant = ({
  control,
  setValue,
  watch,
  previousDataPA,
  clearErrors,
}: any) => {
  const currentDate = new Date();
  const formatedCurrentDate = moment(currentDate).format("YYYY-MM-DD");
  const prdoctServiceList = useSelector(
    (data: any) => data?.getProductService?.data?.data
  );
  const devicesList: any = useSelector((data: any) => data?.getDevices);
  const managementStatusValue = useSelector(
    (data: any) => data?.managementStatus?.data
  );

  const { clinicalAssistantList } =
    clinicalAssistantListData(prdoctServiceList);

  const [priceOption, setpriceOption] = useState(
    previousDataPA?.clInPatientAmount && previousDataPA?.clOutPatientAmount
      ? "clIndividual"
      : ""
  );

  const clPatientType = watch("clPatientType");
  const clServiceType = watch("clServiceType");
  const clValidityFrom = watch("clValidityFrom");
  const clValidityPeriod = watch("clValidityPeriod");
  const clInPatientAmount = watch("clInPatientAmount");
  const clOutPatientAmount = watch("clOutPatientAmount");

  useEffect(() => {
    if (clServiceType === "pilot") {
      setValue("clAllAmount", "");
      setValue("clInPatientAmount", "");
      setValue("clOutPatientAmount", "");
    }
  }, [clServiceType]);

  const handleChange = (e: SelectChangeEvent<any>) => {
    if (e.target.value === "all") {
      setValue("clInPatientAmount", "");
      setValue("clOutPatientAmount", "");
      setpriceOption("clBulk");
    }
    if (e.target.value === "in_patient") {
      setValue("clAllAmount", "");
      setValue("clOutPatientAmount", "");
      setpriceOption("");
    }
    if (e.target.value === "out_patient") {
      setValue("clAllAmount", "");
      setValue("clInPatientAmount", "");
      setpriceOption("");
    }
  };

  useEffect(() => {
    if (clValidityFrom) {
      let toDateCAValidityUpdate;
      switch (clValidityPeriod) {
        case "validity_3_months":
          toDateCAValidityUpdate = moment(clValidityFrom)
            .add(3, "months")
            .format("YYYY-MM-DD");
          break;
        case "validity_6_months":
          toDateCAValidityUpdate = moment(clValidityFrom)
            .add(6, "months")
            .format("YYYY-MM-DD");
          break;
        case "validity_12_months":
          toDateCAValidityUpdate = moment(clValidityFrom)
            .add(12, "months")
            .format("YYYY-MM-DD");
          break;
        case "custom":
          toDateCAValidityUpdate = previousDataPA?.maValidityto ?? "";
          break;
        case "lifetime":
          toDateCAValidityUpdate = moment(clValidityFrom)
            .add(20, "years")
            .format("YYYY-MM-DD");
          break;
        default:
          toDateCAValidityUpdate = "";
      }
      setValue("clValidityto", toDateCAValidityUpdate);
    }
  }, [clValidityFrom, clValidityPeriod]);

  const handlePlanTypeChange = (e: SelectChangeEvent<any>) => {
    if (e.target.value === "per_patient" || e.target.value === "per_month") {
      setValue("clValidityPeriod", "none");
      setValue("clValidityFrom", "0001-01-01");
      setValue("clValidityto", "0001-01-01");
    } else if (e.target.value === "per_annum") {
      setValue("clValidityPeriod", "");
      setValue("clValidityFrom", formatedCurrentDate);
      setValue("clValidityto", "");
    }
  };

  const handleValidityPeriod = (value: string) => {
    clearErrors("clValidityto");
  };

  const priceOptionList = [
    { value: "clBulk", label: "Bulk" },
    { value: "clIndividual", label: "Individual" },
  ];

  const handlePriceOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setpriceOption(e.target.id);
    setValue("clInPatientAmount", "");
    setValue("clOutPatientAmount", "");
    setValue("clAllAmount", "");
  };

  useEffect(() => {
    if (priceOption === "clIndividual") {
      const clAllAmountValue = +clInPatientAmount + +clOutPatientAmount;
      setValue("clAllAmount", clAllAmountValue === 0 ? "" : clAllAmountValue);
    }
  }, [priceOption, clPatientType, clInPatientAmount, clOutPatientAmount]);

  useEffect(() => {
    if (clServiceType === "paid" && clPatientType === "all") {
      setpriceOption(
        previousDataPA?.clInPatientAmount && previousDataPA?.clOutPatientAmount
          ? "clIndividual"
          : "clBulk"
      );
    } else if (clServiceType === "pilot") {
      setpriceOption("");
    }
  }, [clServiceType, clPatientType]);

  return (
    <div>
      {!managementStatusValue && (
        <div className="pb-3">
          <span className="text-16 text-error">
            Management Assistant should be active, to edit Clinical Assistant.
          </span>
        </div>
      )}

      <span className="text-16 text-primary font-semibold">
        {addHospitalName.AddonService}
      </span>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 my-4">
        <ProductServiceCheckBox
          data={clinicalAssistantList}
          setValue={setValue}
          control={control}
          name="CLaddOnService"
          defaultValue={previousDataPA?.CLaddOnService}
          disabled={!managementStatusValue}
        />
      </div>
      <div>
        {devicesList?.isLoading ? (
          <div className="flex justify-center py-2">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="my-3">
            <CustomAccordion
              isBorder={false}
              bgcolor=""
              innerPadding=""
              title="Devices"
              component={
                <ProductActivationDevices
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  totalDevicesCount="clTotalDevicesCount"
                  packageDevicesCount="clPackageDevicesCount"
                  paidDevicesCount="clPaidDevicesCount"
                  packageDevices="clPackageDevices"
                  paidDevices="clPaidDevices"
                  previousDataPA={previousDataPA}
                  clearErrors={clearErrors}
                  disabled={!managementStatusValue}
                />
              }
            />
          </div>
        )}

        <div className="my-3">
          <span className="text-16 text-primary font-semibold">
            {addHospitalName.PlanDetails}
          </span>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <SelectForm
              label={"Service Type*"}
              id={"clServiceType"}
              options={serviceTypeData}
              control={control}
              defaultValue={previousDataPA?.clServiceType}
              disabled={!managementStatusValue}
            />

            <SelectForm
              label={"Plan Type*"}
              id={"clPlanType"}
              options={planTypeData}
              control={control}
              onChange={(e: SelectChangeEvent<any>) => handlePlanTypeChange(e)}
              defaultValue={previousDataPA?.clPlanType}
              disabled={!managementStatusValue}
            />
            <SelectForm
              label={"Patient Type*"}
              id={"clPatientType"}
              options={patientTypeData}
              control={control}
              onChange={(e: SelectChangeEvent<any>) => handleChange(e)}
              defaultValue={previousDataPA?.clPatientType}
              disabled={!managementStatusValue}
            />
          </div>
        </div>

        <div className="my-3">
          <div className="flex gap-5">
            <span className="text-16 text-primary font-semibold">
              {addHospitalName.Price}
            </span>
            {priceOption && (
              <div className="flex gap-3">
                {priceOptionList?.map((item: any) => (
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id={item.value}
                      checked={item.value === priceOption}
                      name="clPriceOption"
                      onChange={(e) => handlePriceOption(e)}
                      disabled={!managementStatusValue}
                    />
                    <label
                      className={`font-14 font-medium ${
                        item.value === priceOption
                          ? "text-primary"
                          : "text-secondary"
                      }`}
                      htmlFor={item.value}
                    >
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <AmountInputForm
              label={"All Amount"}
              id="clAllAmount"
              control={control}
              disabled={
                !managementStatusValue
                  ? true
                  : clPatientType === "all" &&
                    clServiceType !== "paid" &&
                    priceOption === "clBulk"
                  ? true
                  : clPatientType === "all" &&
                    clServiceType !== "pilot" &&
                    priceOption !== "clIndividual"
                  ? false
                  : true
              }
              required={
                clPatientType === "all" &&
                clServiceType === "paid" &&
                priceOption !== "clIndividual"
                  ? true
                  : false
              }
              defaultValue={previousDataPA?.clAllAmount}
              maxLength={50}
            />
            <AmountInputForm
              label={"In-Patient Amount"}
              id="clInPatientAmount"
              control={control}
              disabled={
                !managementStatusValue
                  ? true
                  : priceOption === "clIndividual" &&
                    clServiceType === "paid" &&
                    clPatientType === "all"
                  ? false
                  : clPatientType === "in_patient" && clServiceType !== "pilot"
                  ? false
                  : true
              }
              required={
                priceOption === "clIndividual" &&
                clServiceType === "paid" &&
                clPatientType === "all"
                  ? true
                  : clPatientType === "in_patient" && clServiceType === "paid"
                  ? true
                  : false
              }
              defaultValue={previousDataPA?.clInPatientAmount}
              maxLength={50}
            />
            <AmountInputForm
              label={"Out-Patient Amount"}
              id="clOutPatientAmount"
              control={control}
              disabled={
                !managementStatusValue
                  ? true
                  : priceOption === "clIndividual" &&
                    clServiceType === "paid" &&
                    clPatientType === "all"
                  ? false
                  : clPatientType === "out_patient" && clServiceType !== "pilot"
                  ? false
                  : true
              }
              required={
                priceOption === "clIndividual" &&
                clServiceType === "paid" &&
                clPatientType === "all"
                  ? true
                  : clPatientType === "out_patient" && clServiceType === "paid"
                  ? true
                  : false
              }
              defaultValue={previousDataPA?.clOutPatientAmount}
              maxLength={50}
            />
          </div>
        </div>

        <div
          className={`${
            watch("clPlanType") === "per_annum" ? "visible" : "hidden"
          } `}
        >
          <hr />
          <div className="my-3">
            <span className="text-16 text-primary font-semibold">
              {addHospitalName.Validity}
            </span>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6 items-center">
              <div className="mt-2">
                <SelectForm
                  label={"Validity Period"}
                  id={"clValidityPeriod"}
                  options={validityData}
                  disabled={!managementStatusValue}
                  control={control}
                  onChange={handleValidityPeriod}
                  defaultValue={previousDataPA?.clValidityPeriod}
                  required={watch("clPlanType") === "per_annum" ? true : false}
                />
              </div>
              <ThemeProvider theme={dateErrorTheme}>
                <DataPickForm
                  label={"From"}
                  id="clValidityFrom"
                  control={control}
                  defaultValue={
                    previousDataPA?.clValidityFrom ?? formatedCurrentDate
                  }
                  required={watch("clPlanType") === "per_annum" ? true : false}
                  minDate={moment(new Date())}
                  disabled={!managementStatusValue}
                />
              </ThemeProvider>
              <div className="">
                <DataPickForm
                  label={"To"}
                  id="clValidityto"
                  control={control}
                  disabled={
                    clValidityPeriod !== "custom" || !managementStatusValue
                      ? true
                      : false
                  }
                  required={
                    clValidityPeriod === "lifetime" ||
                    watch("clPlanType") !== "per_annum"
                      ? false
                      : true
                  }
                  defaultValue={previousDataPA?.clValidityto}
                  minDate={watch("clValidityFrom")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalAssistant;
