import { useEffect, useRef, useState } from "react";
import useDisplayToggle from "../../../../utils/useDisplayToggle";
import {
  ArrowDropDown,
  ArrowDropUp,
} from "../../../../components/muiIconsImport";
import { useAppDispatch } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { getProductType } from "../../../../redux/addHospital/hospitalManagementReducer";
import { LoadingSpinner } from "../../../../components/ui/loadinSpinner";

const ProductSelectDropdown = ({
  formErrors,
  name,
  setValue,
  watch,
  clearErrors,
  isReset,
}: {
  formErrors: any;
  name: any;
  setValue: any;
  watch: any;
  clearErrors: any;
  isReset: any;
}) => {
  const required = formErrors?.[name];
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [productLists, setProductLists] = useState<any>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProductType());
  }, []);
  const productsDataRes: any = useSelector(
    (data: { getProductType: any }) => data?.getProductType
  );
  useEffect(() => {
    if (productsDataRes.status === "success") {
      setProductLists(productsDataRes.data.data);
    } else if (productsDataRes.status === "failed") {
      console.error(productsDataRes);
    }
  }, [productsDataRes]);

  const handleCheckboxChange = (value: string) => {
    // show and hide column based on checkbox
    if (selectedProducts?.includes(value)) {
      setSelectedProducts(
        selectedProducts.filter((item: any) => item !== value)
      );
    } else {
      setSelectedProducts([...selectedProducts, value]);
    }
  };

  useEffect(() => {
    if (selectedProducts?.length === productLists?.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedProducts, productLists]);

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setSelectedProducts([]);
    } else {
      const allHospitalIds = productLists.map((item: any) => item.productId);
      setSelectedProducts(allHospitalIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  useEffect(() => {
    setSelectedProducts([]);
    setSelectAllChecked(false);
  }, [isReset]);

  useEffect(() => {
    // if (selectedProducts) {
    //   const products = selectedProducts.map((item: any) =>
    //     productLists?.find((product: any) => product?.productId === item)
    //   );
    setValue(name, selectedProducts);
    // }

    if (selectedProducts?.length > 0) {
      clearErrors(name);
    }
  }, [selectedProducts]);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setShowDropdown,
  });

  const arrayofNames = selectedProducts?.map(
    (item: any) =>
      productLists?.find((product: any) => product?.productId === item)?.name
  );
  const displayName = arrayofNames?.join(", ");

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        ref={onClickRef}
        className={` ${
          required
            ? `border hover:border-blue-350 ${
                required && showDropdown ? "border-blue-350" : "border-error"
              } `
            : showDropdown
            ? "border outline outline-1 border-blue-350"
            : "border hover:border-blue-350 border-gray-450"
        }  text-primary ps-5 pe-2 py-[14px] rounded w-full text-16 font-semibold text-start  flex justify-between bg-white`}
      >
        {selectedProducts?.length === productLists?.length ? (
          "All Products"
        ) : selectedProducts?.length === 0 ? (
          <span className="text-[#757B84] font-normal">Select Products</span>
        ) : (
          displayName
        )}

        <span className="text-blue-300">
          {showDropdown ? <ArrowDropUp /> : <ArrowDropDown />}
        </span>
      </button>

      <div className="text-error text-12 ps-3 pb-3 pt-1">
        {required?.message}
      </div>

      <label className="absolute text-12 font-normal text-primary left-3 px-1 bg-white top-[-8px] ">
        Products
      </label>

      <div
        className={`w-[100%] absolute z-20 top-16 bg-white px-2 py-4 shadow-md rounded-lg ${
          showDropdown ? "visible" : "invisible"
        }`}
        ref={insideClickRef}
      >
        {productsDataRes?.isLoading ? (
          <div className="flex justify-center py-4">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="overflow-y-auto scroll_bar_with_side">
            {productLists?.length >= 1 && (
              <div
                className={`${
                  selectAllChecked ? "bg-blue-150" : "hover:bg-blue-50"
                } p-3 grid grid-cols-12 gap-3 items-center`}
              >
                <div className="col-span-2 flex items-center">
                  <input
                    id="selectAllProduct"
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                    className={`checkbox-input`}
                  />
                </div>
                <div className="col-span-10 flex items-center">
                  <label
                    htmlFor="selectAllProduct"
                    className={`font-semibold text-14 cursor-pointer ${
                      selectAllChecked ? "text-primary" : "text-secondary"
                    }`}
                  >
                    All Products
                  </label>
                </div>
              </div>
            )}

            {productLists?.map((item: any) => (
              <div
                className={`${
                  selectedProducts?.includes(item.productId)
                    ? "bg-blue-150"
                    : "hover:bg-blue-50"
                } p-3 grid grid-cols-12 gap-3 items-center`}
                key={item.productId}
              >
                <div className="col-span-2 flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedProducts?.includes(item.productId)}
                    id={item.productId}
                    name={item.productId}
                    onChange={() => handleCheckboxChange(item.productId)}
                    className="checkbox-input"
                  />
                </div>
                <div className="col-span-10 flex items-center">
                  <label
                    htmlFor={item.productId}
                    className={`font-semibold text-14 ${
                      selectedProducts?.includes(item.productId)
                        ? "text-primary"
                        : "text-secondary"
                    } cursor-pointer`}
                  >
                    {item.name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSelectDropdown;
