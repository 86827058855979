import { Navigate, Outlet } from "react-router-dom";
import constansts from "../constants";
import routesName from "../constants/routesName";
import { useEffect, useState } from "react";
import constants from "../constants";
import { accessTokenType } from "../modals/IloginCard";
import { jwtDecode } from "jwt-decode";
import { AsyncAccessTokenDecoder } from "../utils/accessTokenDecoder";

const PublicRoutes = () => {
  // const [accessToken, setAccessToken] = useState<string | null>(
  //   sessionStorage.getItem(constants.accessTokenText)
  // );
  // useEffect(() => {
  //   setAccessToken(sessionStorage.getItem(constants.accessTokenText));
  // }, []);

  // let decodeToken: accessTokenType | null = null;
  // if (accessToken) {
  //   try {
  //     decodeToken = jwtDecode(accessToken);
  //   } catch (error) {
  //     console.error("Error decoding token:", error);
  //   }
  // }

  const decodeToken = AsyncAccessTokenDecoder()

  return sessionStorage.getItem(constansts.isLoginLocalStorage) === "false" ||
    !sessionStorage.getItem(constansts.isLoginLocalStorage) ? (
    <>
      <Outlet />
    </>
  ) : (
    <>
      <Navigate
        to={
          decodeToken?.Roles === "Super Admin" || decodeToken?.Roles === "Management"
            ? `${routesName.managementInfoSystemPage}`
            : `${routesName.hospitalManagementPage}`
        }
      />
    </>
  );
};

export default PublicRoutes;
