export const AllAccountManagerHeaderDatas = () => {
  const LabelData = [
    {
      label: "Action",
      value: "action",
      sort: false,
      search: false,
      width: "80px",
    },
    {
      label: "A/C Manager",
      value: "firstname",
      display: false,
      width: "250px",
    },
    {
      label: "Gender",
      value: "gender",
      display: false,
      removeSticky: true,
      search: false,
      width: "160px",
    },
    {
      label: "Status",
      value: "isactive",
      removeSticky: true,
      search: false,
      width: "150px",
    },
    {
      label: "Mobile Number",
      value: "phone",
      removeSticky: true,
      width: "220px",
    },
    {
      label: "Email ID",
      value: "email",
      removeSticky: true,
      width: "220px",
    },
    {
      label: "No. of Hospitals",
      value: "no_of_hospitals",
      removeSticky: true,
      sort: false,
      search: false,
      width: "150px",
    },
    {
      label: "Role",
      value: "rolename",
      removeSticky: true,
      width: "150px",
    },
    {
      label: "Joining Date",
      value: "joiningdate",
      removeSticky: true,
      search: false,
      width: "150px",
    },
    {
      label: "Last Signed In",
      value: "lastsignedin",
      removeSticky: true,
      search: false,
      width: "150px",
    },
  ];

  return LabelData;
};
