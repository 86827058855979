import { createSlice } from "@reduxjs/toolkit";
import { getAllAudithLogin } from "./auditLoginReducer";

export const getAllAudithLoginSlice = createSlice({
  name: "getAllAudithLogin",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetAllAudithLoginState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAudithLogin.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllAudithLogin.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getAllAudithLogin.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});

export const getAllAudithLoginreducer = getAllAudithLoginSlice.reducer;
export const { resetgetAllAudithLoginState } = getAllAudithLoginSlice.actions;
