import React, { useEffect, useState } from "react";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import AccountManagerProgressBar from "../../features/accountManager/accountManagerProgressBar";
import routesName from "../../constants/routesName";
import { SubmitHandler, useForm } from "react-hook-form";
import RolePermissionCheckBox from "../../components/accountManager/rolePermissionCheckBox";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import { useAppDispatch } from "../../redux/store";
import {
  addAccountManager,
  getPermissions,
} from "../../redux/accountManager/accountManagerReducer";
import { useSelector } from "react-redux";
import errorToast from "../../utils/errorToast";
import {
  resetAddAccountManagerState,
  resetGetPermissionsState,
} from "../../redux/accountManager/accountManagerSlice";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { breadcrumbData } from "../../jsonData/breadcrumbData/accountManager";
import { addAccManagerDispatchData } from "../../features/accountManager/dataformatter";
import { ResponseType } from "../../modals/IreduxResponse";
import {
  AccountManagerRolePermission,
  RolePermissionType,
} from "../../modals/IaccountManager";
import SuccessMessage from "../../components/modals/successMessage";
import UserAuditDetails from "../../utils/userAuditDetails";
import DetectDevice from "../../utils/detectDevice";

const AddEditRolesAuthorities = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userDetails = AccessTokenDecoder();
  const dispatch = useAppDispatch();
  const [permissionData, setPermissionData] = useState<RolePermissionType>();
  const [successModal, setSuccessModal] = useState(false);
  const [emailService, setEmailService] = useState(false);

  const [getAllPermissionsData, setGetAllPermissionsData] = useState<
    RolePermissionType[]
  >([]);
  const [roleType, setRoleType] = useState<number>();
  const [selectedItems, setSelectedItems] = useState<
    AccountManagerRolePermission[] | undefined
  >([]);

  useEffect(() => {
    // calling role permission api
    dispatch(getPermissions());
  }, []);
  const getPermissionsRes = useSelector(
    (data: { getPermissions: ResponseType }) => data.getPermissions
  );

  useEffect(() => {
    // handling get permission response
    if (getPermissionsRes?.status === "success") {
      setGetAllPermissionsData(getPermissionsRes?.data?.data);
      setPermissionData(getPermissionsRes?.data?.data[0]);
      setRoleType(parseInt(getPermissionsRes?.data?.data?.[0]?.roleId));
    } else if (getPermissionsRes?.status === "failed") {
      errorToast("Something Went Wrong While Fetching Permissions");
    }
  }, [getPermissionsRes]);

  const handleRolePermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    // role based permission data displaying
    const data = getAllPermissionsData?.find(
      (item) => item?.roleId === parseInt(e?.target?.value)
    );
    setPermissionData(data);
    setRoleType(parseInt(e?.target?.value));
    setSelectedItems([]);
  };
  const cancelFn = () => {
    navigate(routesName.accountManagerPage);
  };
  useEffect(() => {
    // setting all permission checked for super aadmin only
    if (roleType === 1000) {
      setValue("permissions", permissionData?.permissions);
      setSelectedItems(permissionData?.permissions);
    } else if (roleType === 1005) {
      setValue("permissions", permissionData?.permissions);
      setSelectedItems(permissionData?.permissions);
    } else {
      setValue("permissions", []);
      setSelectedItems([]);
    }
  }, [roleType]);
  const { handleSubmit, control, watch, setValue } = useForm<any>();

  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const deviceType = DetectDevice();

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: parseInt(userDetails?.userId),
    userName: userDetails?.userName,
    role: userDetails?.Roles,
    customer: userDetails?.name,
    customerCode: userDetails?.userName,
    customerID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: deviceType,
    auditDate: "2024-03-15T06:57:07.737Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    const permissions = { ...permissionData, permissions: data?.permissions };
    const finalData = {
      ...location?.state?.data,
      ...data,
      permissions: permissions,
    };
    const dispatchData = addAccManagerDispatchData(
      finalData,
      userDetails,
      userAuditInfo
    );
    dispatch(addAccountManager(dispatchData));
  };

  const addAccountManagerRes = useSelector(
    (data: { addAccountManager: ResponseType }) => data.addAccountManager
  );

  useEffect(() => {
    // handling add account manager response
    if (addAccountManagerRes?.status === "success") {
      // successToast("Account Manager Added Successfully");
      setSuccessModal(true);
      // navigate(routesName.accountManagerPage);
    } else if (addAccountManagerRes?.status === "failed") {
      if (addAccountManagerRes?.error?.status === 503) {
        setEmailService(true);
        setSuccessModal(true);
      } else {
        errorToast(
          addAccountManagerRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [addAccountManagerRes]);

  useEffect(() => {
    return () => {
      dispatch(resetAddAccountManagerState());
      dispatch(resetGetPermissionsState());
    };
  }, []);

  const filterGetPermissionData = getAllPermissionsData?.filter(
    (item: any) => item?.roleId !== 1005
  );

  return (
    <>
      <SuccessMessage
        isVisible={successModal}
        onClose={() => {
          setSuccessModal(false);
          navigate(routesName.accountManagerPage);
        }}
        title={`${location?.state?.data?.name} has been successfully added!`}
        description={
          emailService
            ? `Please contact your Admin for the user credentials`
            : `An email has been sent to ${addAccountManagerRes?.data?.data} with User ID and Password`
        }
      />
      <InnerLayout title="Add Account Manager">
        <div className="px-6 py-5 bg-blue-150 z-10 sticky top-[80px]">
          <Breadcrumbs data={breadcrumbData} />
          <div className="py-5 lg:grid grid-cols-6 gap-4">
            <div className="col-start-2 col-span-4">
              <AccountManagerProgressBar active={2} />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-6 pb-25 mt-6">
            <span className="text-16 text-primary font-semibold">
              Select Role
            </span>

            {getPermissionsRes?.isLoading ? (
              <div className="flex justify-center py-3">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="flex gap-4">
                {filterGetPermissionData?.map(
                  (item: RolePermissionType, index: number) => {
                    return (
                      <div className="flex items-center gap-3 my-3" key={index}>
                        <input
                          type="radio"
                          value={item?.roleId}
                          id={item?.roleId?.toString()}
                          name="role_type"
                          checked={roleType == item?.roleId}
                          onChange={(e) => handleRolePermission(e)}
                        />
                        <label
                          htmlFor={item?.roleId?.toString()}
                          className={`text-14 font-semibold ${
                            roleType == item?.roleId
                              ? "text-primary"
                              : "text-secondary"
                          }`}
                        >
                          {item?.roleName}
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
            )}

            <div className="mt-2">
              <div>
                <span className="text-primary text-16 font-semibold">
                  Select Authority
                </span>
              </div>
              <RolePermissionCheckBox
                data={permissionData?.permissions}
                setValue={setValue}
                control={control}
                name="permissions"
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                disabled={roleType === 1000 || roleType === 1005}
              />
            </div>
          </div>
          <div className="fixed bottom-0 fit_width z-20 shadow-bootomSticky">
            <div className="bg-white p-3 px-7 flex gap-3 justify-end">
              <SecondaryButton
                name={addHospitalName.Cancel}
                type="button"
                onClick={() => {
                  cancelFn();
                }}
              />
              <PrimaryButton
                name={addHospitalName.Submit}
                loading={addAccountManagerRes?.isLoading}
              />
            </div>
          </div>
        </form>
      </InnerLayout>
    </>
  );
};

export default AddEditRolesAuthorities;
