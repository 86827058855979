import moment from "moment";
import enums from "./constants/enums";

export const removeUnderScore = (str: string) => {
  if (str) {
    // return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.replace(/_/g, " ");
    return str
      ?.replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char?.toUpperCase());
  }
  return str;
};

export const addSpacesToCamelCase = (str: string) => {
  if (str) {
    return str.replace(/([A-Z])/g, " $1").trim();
  }
  return str;
};

export const labelObject = (data: string[]) => {
  return data?.map((item) => ({ label: removeInstalment(item), value: item }));
};

export const CharacterHidder = (value: string, count: number = 15) => {
  if (value && value.length > count) {
    return <span title={value}>{value.substring(0, count) + "..."}</span>;
  } else {
    return value;
  }
};

export const statusColor = (value: string) => {
  if (value === enums.active) {
    return "text-green-600 p-1 px-3 bg-green-100 rounded-full";
  } else if (value === enums.expired) {
    return "text-red-400 p-1 px-3 bg-green-100 rounded-full";
  }
};

export const istDate = (date: string) => {
  const utcDate = new Date(date);
  return new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000);
};
// export const removeBedSize = (str: string) => {
//   if (str) {
//     let withoutBedSize = str.replace("bedsize_", "");
//     withoutBedSize = withoutBedSize.replace("_to_", " - ").replace(/_/g, " ");
//     withoutBedSize =
//       withoutBedSize.charAt(0).toUpperCase() + withoutBedSize.slice(1);

//     return withoutBedSize;
//   } else {
//     return str;
//   }
// };
export const removeBedSize = (str: string) => {
  if (!str) return str;

  // Ensure the string replacement is consistent
  let withoutBedSize = str.replace(/bedsize_/g, "")
                          .replace(/_to_/g, " - ")
                          .replace(/_/g, " ");
  
  // Capitalize the first letter
  withoutBedSize = withoutBedSize.charAt(0).toUpperCase() + withoutBedSize.slice(1);

  return withoutBedSize;
};

export const removeInstalment = (str: string) => {
  if (str) {
    let withoutInstalment = str.replace("Installment_", "");
    withoutInstalment = withoutInstalment
      ?.replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char?.toUpperCase());

    return withoutInstalment;
  } else {
    return str;
  }
};

const currentDate = new Date();
export const currentYearStart = moment(currentDate).startOf("year");
export const currentYearEnd = moment(currentDate).endOf("year");
