import DashboardCard from "../ui/dashboardCard";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assests/images/susscess.svg";
import { DoneIcons } from "../muiIconsImport";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  title?: string;
  inprogress?: boolean;
  productActivation?: boolean;
  onboarding?: boolean;
  email?: string;
  emailFailedMess?: string;
};

const SuccessMessageModal = ({
  isVisible,
  onClose,
  title,
  inprogress = false,
  productActivation = false,
  onboarding = false,
  email,
  emailFailedMess,
}: ModalProps) => {
  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="flex justify-end">
        <button type="button" onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="flex justify-center">
        <div className="w-[100px] h-[100px]">
          <img src={logo} className="w-[100%]" />
        </div>
      </div>
      <div className="px-5 py-3 flex flex-col items-center">
        <span className="text-24 font-semibold text-primary">{title}</span>
        {emailFailedMess && (
          <div className="py-3">
            <span className="text-18 font-normal text-secondary">
              {emailFailedMess}
            </span>
          </div>
        )}

        {inprogress && (
          <div className="py-3">
            <span className="text-18 font-normal text-secondary">
              The following details have been sent the Super Admin for approval
            </span>
            <ul className="pt-3 text-18 font-normal text-secondary">
              <li>
                <span className="pe-3">
                  <DoneIcons styles={{ color: "green" }} />
                </span>
                Hospital Information
              </li>
              {productActivation && (
                <li>
                  <span className="pe-3">
                    <DoneIcons styles={{ color: "green" }} />
                  </span>
                  Product Activation
                </li>
              )}
            </ul>
          </div>
        )}

        {onboarding && (
          <div className="py-3">
            <span className="text-18 font-normal text-secondary">
              An email with has been sent to {email} with:
            </span>
            <ul className="pt-3 text-18 font-normal text-secondary">
              <li>
                <span className="pe-3">
                  <DoneIcons styles={{ color: "green" }} />
                </span>
                User ID and Password
              </li>
              <li>
                <span className="pe-3">
                  <DoneIcons styles={{ color: "green" }} />
                </span>
                Product & Plan Activation details
              </li>
            </ul>
          </div>
        )}
      </div>
    </DashboardCard>
  );
};

export default SuccessMessageModal;
