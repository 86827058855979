import { useEffect, useState } from "react";
import {
  AddCircleOutlineIcons,
  RemoveCircleOutlineIcons,
} from "../../components/muiIconsImport";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import {
  CountryList,
  DistrictList,
  HobliList,
  StateList,
  TalukList,
} from "../hospitalManagement/apicalls/locationList";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputForm } from "../../components/forms/inputForm";
import HospitalNameSelectDropdown from "./hospitalNameSelectDropdown";
import { FilterAutoCompleteForm } from "../../components/forms/filterAutoComplete";

const MisGraphFilter = ({
  country,
  setCountry,
  state,
  setState,
  district,
  setDistrict,
  taluk,
  setTaluk,
  hobli,
  setHobli,
  setFilterApplyToggle,
  label,
  hospitalArray,
  setHospitalArray,
}: {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  district: string;
  setDistrict: React.Dispatch<React.SetStateAction<string>>;
  taluk: string;
  setTaluk: React.Dispatch<React.SetStateAction<string>>;
  hobli: string;
  setHobli: React.Dispatch<React.SetStateAction<string>>;
  setFilterApplyToggle: React.Dispatch<React.SetStateAction<boolean>>;
  hospitalArray: any;
  setHospitalArray: React.Dispatch<React.SetStateAction<[]>>;
  label: any;
}) => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<any>();
  const [filterShow, setFilterShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const countryId: number = watch("countryId");
  const stateId: number = watch("stateId");
  const districtId: number = watch("districtId");
  const talukId: number = watch("talukId");

  const { countryLists, countryError, countryLoading, fetchCountry } =
    CountryList();

  const { stateLists, stateError, stateLoading, fetchState } = StateList(
    countryId ?? 0
  );

  const { districtLists, districtError, districtLoading, fetchDistrict } =
    DistrictList(stateId ?? 0);

  const { talukLists, talukError, talukLoading, fetchTaluk } = TalukList(
    districtId ?? 0
  );

  const { hobliLists, hobliError, hobliLoading, fetchHobli } = HobliList(
    talukId ?? 0
  );

  useEffect(() => {
    setHospitalArray(watch("hospitalsName"));
  }, [watch("hospitalsName")]);

  useEffect(() => {
    fetchCountry();
  }, [filterShow]);

  useEffect(() => {
    fetchState();
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  }, [countryId]);

  useEffect(() => {
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  }, [stateId]);

  useEffect(() => {
    fetchTaluk();
    fetchHobli();
  }, [districtId]);

  useEffect(() => {
    fetchHobli();
  }, [talukId]);

  const countryUnderArray = ["country", "state", "district", "taluk", "hobli"];
  const stateUnderArray = ["state", "district", "taluk", "hobli"];
  const districtUnderArray = ["district", "taluk", "hobli"];
  const talukUnderArray = ["taluk", "hobli"];
  const hobliUnderArray = ["hobli"];

  const filterSetValueFn = (value: string) => {
    setValue(`${value}Id`, 0);
    setValue(`${value}`, "");
  };

  const handleChangeFn = (type: string) => {
    let regionsArrayGraph: any[] = [];
    switch (type) {
      case "country":
        regionsArrayGraph = stateUnderArray;
        setState("");
        setDistrict("");
        setTaluk("");
        setHobli("");
        break;
      case "state":
        regionsArrayGraph = districtUnderArray;
        setDistrict("");
        setTaluk("");
        setHobli("");
        break;
      case "district":
        regionsArrayGraph = talukUnderArray;
        setTaluk("");
        setHobli("");
        break;
      case "taluk":
        regionsArrayGraph = hobliUnderArray;
        setHobli("");
        break;
      default:
        regionsArrayGraph = [];
    }
    regionsArrayGraph?.forEach((value) => {
      filterSetValueFn(value);
    });
  };

  useEffect(() => {
    return () => {
      sessionStorage.setItem("MisGraphFilter", "false");
    };
  }, []);

  const onSubmit: SubmitHandler<any> = async (data) => {};

  const [isReset, setIsReset] = useState(false);

  const clearFn = () => {
    setErrorMessage("");
    setFilterShow((prev) => !prev);
    setCountry("");
    setState("");
    setDistrict("");
    setTaluk("");
    setHobli("");
    countryUnderArray.forEach((value) => {
      filterSetValueFn(value);
    });
    setFilterApplyToggle((prev) => !prev);
  };

  return (
    <div className=" rounded-lg py-3 px-5 bg-white shadow-sm1">
      <div className="">
        <div className="">
          <button
            className="text-blue-300 flex justify-between w-[100%]"
            type="button"
            onClick={() => {
              setFilterShow((prev) => !prev);
              sessionStorage.setItem(
                "MisGraphFilter",
                filterShow ? "false" : "true"
              );
            }}
          >
            <span className="text-primary text-16 font-semibold">Filters</span>
            {filterShow ? (
              <>
                <RemoveCircleOutlineIcons />
              </>
            ) : (
              <>
                <AddCircleOutlineIcons />
              </>
            )}
          </button>
        </div>

        {filterShow && (
          <>
            <hr className={` my-2`} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={` grid 2xl:grid-cols-12 gap-4`}>
                <div
                  className={`lg:col-span-10 md:col-span-6 sm:col-span-10 col-span-12 ${
                    label === "Departments" || label === "Diagnosis"
                      ? "2xl:border-r-2"
                      : ""
                  } 2xl:pe-4`}
                >
                  <div>
                    <span className="text-primary text-16 font-semibold">
                      Region
                    </span>
                  </div>
                  <div className="grid lg:grid-cols-10 md:grid-cols-9 sm:grid-cols-8 gap-4 pt-3">
                    <div className="lg:col-span-2 md:col-span-3 sm:col-span-4">
                      <div className="hidden w-0 h-0 invisible">
                        <InputForm
                          label={"Country Id"}
                          id="countryId"
                          control={control}
                          defaultValue={""}
                        />
                      </div>
                      {countryLoading ? (
                        <>
                          <FilterAutoCompleteForm
                            label={"Country*"}
                            id={"dummycountry"}
                            options={[]}
                            control={control}
                          />
                        </>
                      ) : (
                        <FilterAutoCompleteForm
                          label={"Country*"}
                          id={"country"}
                          options={countryLists ?? []}
                          control={control}
                          defaultValue={""}
                          value={country}
                          handleChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            newValue: any
                          ) => {
                            setValue("countryId", newValue?.value ?? 0);
                            handleChangeFn("country")
                            setCountry(newValue?.label ?? "");
                            setErrorMessage("");
                          }}
                        />
                      )}
                    </div>
                    <div className="lg:col-span-2 md:col-span-3 sm:col-span-4">
                      <div className="hidden w-0 h-0 invisible">
                        <InputForm
                          label={"State Id"}
                          id="stateId"
                          control={control}
                        />
                      </div>
                      {stateLoading ? (
                        <>
                          <FilterAutoCompleteForm
                            label={"State"}
                            id={"dummystate"}
                            options={[]}
                            control={control}
                          />
                        </>
                      ) : (
                        <FilterAutoCompleteForm
                          label={"State"}
                          id={"state"}
                          options={country ? stateLists ?? [] : []}
                          control={control}
                          value={state}
                          handleChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            newValue: any
                          ) => {
                            setValue("stateId", newValue?.value ?? 0);
                            handleChangeFn("state")
                            setState(newValue?.label ?? "");
                            setErrorMessage("");
                          }}
                        />
                      )}
                    </div>
                    <div className="lg:col-span-2 md:col-span-3 sm:col-span-4">
                      <div className="hidden w-0 h-0 invisible">
                        <InputForm
                          label={"District Id"}
                          id="districtId"
                          control={control}
                        />
                      </div>
                      {districtLoading ? (
                        <>
                          <FilterAutoCompleteForm
                            label={"District"}
                            id={"dummydistrict"}
                            options={[]}
                            control={control}
                          />
                        </>
                      ) : (
                        <FilterAutoCompleteForm
                          label={"District"}
                          id={"district"}
                          options={districtLists ?? []}
                          control={control}
                          value={district}
                          handleChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            newValue: any
                          ) => {
                            setValue("districtId", newValue?.value ?? 0);
                            handleChangeFn("district")
                            setDistrict(newValue?.label ?? "");
                            setErrorMessage("");
                          }}
                        />
                      )}
                    </div>
                    <div className="lg:col-span-2 md:col-span-3 sm:col-span-4">
                      <div className="hidden w-0 h-0 invisible">
                        <InputForm
                          label={"Taluk Id"}
                          id="talukId"
                          control={control}
                        />
                      </div>
                      {talukLoading ? (
                        <>
                          <FilterAutoCompleteForm
                            label={"Taluk"}
                            id={"dummytaluk"}
                            options={[]}
                            control={control}
                          />
                        </>
                      ) : (
                        <FilterAutoCompleteForm
                          label={"Taluk"}
                          id={"taluk"}
                          options={talukLists ?? []}
                          control={control}
                          value={taluk}
                          handleChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            newValue: any
                          ) => {
                            setTaluk(newValue?.label ?? "");
                            setErrorMessage("");
                            setValue("talukId", newValue?.value ?? 0);
                            handleChangeFn("taluk")
                          }}
                        />
                      )}
                    </div>
                    <div className="lg:col-span-2 md:col-span-3 sm:col-span-4">
                      <div className="hidden w-0 h-0 invisible">
                        <InputForm
                          label={"Hobli Id"}
                          id="hobliId"
                          control={control}
                        />
                      </div>
                      {hobliLoading ? (
                        <>
                          <FilterAutoCompleteForm
                            label={"Hobli"}
                            id={"dummyhobli"}
                            options={[]}
                            control={control}
                          />
                        </>
                      ) : (
                        <FilterAutoCompleteForm
                          label={"Hobli"}
                          id={"hobli"}
                          options={hobliLists ?? []}
                          control={control}
                          value={hobli}
                          handleChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            newValue: any
                          ) => {
                            setHobli(newValue?.label ?? "");
                            setErrorMessage("");
                            setValue("hobliId", newValue?.value ?? 0);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {label === "Departments" || label === "Diagnosis" ? (
                  <div className="2xl:col-span-2 xl:col-span-1 md:col-span-1 sm:col-span-2 col-span-12">
                    <div>
                      <span className="text-primary text-16 font-semibold">
                        List of Hospitals
                      </span>
                    </div>
                    <div className="pt-3">
                      <HospitalNameSelectDropdown
                        name={"hospitalsName"}
                        setValue={setValue}
                        watch={watch}
                        clearErrors={clearErrors}
                        isReset={isReset}
                        widthWithPosition="w-[280px] right-0"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
            <div className="flex justify-end pt-4 items-center gap-4">
              <div>
                <span className="text-error text-12">{errorMessage}</span>
              </div>
              <SecondaryButton
                name="Clear"
                type="button"
                padding="py-2 px-6"
                onClick={() => {
                  clearFn();
                }}
              />
              <PrimaryButton
                name="Apply"
                type="button"
                padding="py-2 px-6"
                onClick={() => {
                  setFilterApplyToggle((prev) => !prev);
                  // if (country) {
                  //   setFilterApplyToggle((prev) => !prev);
                  // } else {
                  //   setErrorMessage(
                  //     "Please select atleast country to apply filter"
                  //   );
                  // }
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MisGraphFilter;
