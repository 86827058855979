import {
  EmailInputForm,
  InputForm,
  PhoneNumInputForm,
} from "../../components/forms/inputForm";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import ImageUpload from "../../components/forms/imageUpload";
import AutoCompleteForm from "../../components/forms/autoCompleteForm";
import {
  CountryList,
  DistrictList,
  HobliList,
  StateList,
  TalukList,
} from "./apicalls/locationList";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import enums from "../../constants/enums";
import { isObjectEmpty } from "../../utils/isObject";

const BasicInformation = ({ control, watch, setValue, previousData }: any) => {
  const location = useLocation();
  const pathAction = location?.state?.action;
  const countryId: number = watch("countryId");
  const stateId: number = watch("stateId");
  const districtId: number = watch("districtId");
  const talukId: number = watch("talukId");
  const cityId: number = watch("cityId");
  const { countryLists, countryError, countryLoading, fetchCountry } =
    CountryList();
  const { stateLists, stateError, stateLoading, fetchState } =
    StateList(countryId);

  const { districtLists, districtError, districtLoading, fetchDistrict } =
    DistrictList(stateId);

  const { talukLists, talukError, talukLoading, fetchTaluk } =
    TalukList(districtId);

  const { hobliLists, hobliError, hobliLoading, fetchHobli } =
    HobliList(talukId);

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (
      countryId ||
      (pathAction === enums.add && isObjectEmpty(previousData))
    ) {
      fetchState();
      fetchDistrict();
      fetchTaluk();
      fetchHobli();
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId || (pathAction === enums.add && isObjectEmpty(previousData))) {
      fetchDistrict();
      fetchTaluk();
      fetchHobli();
    }
  }, [stateId]);

  useEffect(() => {
    if (
      districtId ||
      (pathAction === enums.add && isObjectEmpty(previousData))
    ) {
      fetchTaluk();
      fetchHobli();
    }
  }, [districtId]);
  useEffect(() => {
    if (cityId || (pathAction === enums.add && isObjectEmpty(previousData))) {
      fetchTaluk();
      fetchHobli();
    }
  }, [cityId]);

  useEffect(() => {
    if (talukId || (pathAction === enums.add && isObjectEmpty(previousData))) {
      fetchHobli();
    }
  }, [talukId]);

  const countryChange = () => {
    setValue("stateId", 0);
    setValue("districtId", 0);
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("state", "");
    setValue("district", "");
    setValue("taluk", "");
    setValue("hobli", "");
    fetchState();
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  };
  const stateChange = () => {
    setValue("districtId", 0);
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("district", "");
    setValue("taluk", "");
    setValue("hobli", "");
    fetchDistrict();
    fetchTaluk();
    fetchHobli();
  };
  const districtChange = () => {
    setValue("talukId", 0);
    setValue("hobliId", 0);
    setValue("taluk", "");
    setValue("hobli", "");
    fetchTaluk();
    fetchHobli();
  };
  const talukChange = () => {
    setValue("hobliId", 0);
    setValue("hobli", "");
    fetchHobli();
  };

  return (
    <>
      <div className="">
        <div className="grid lg:grid-cols-3 gap-6 items-center">
          <div className="col-span-1">
            <InputForm
              label={"Hospital Name*"}
              id="hospitalName"
              control={control}
              defaultValue={previousData?.hospitalName}
              maxLength={50}
            />
          </div>
          <ImageUpload
            name="logoURL"
            setValue={setValue}
            control={control}
            watch={watch}
            image={previousData?.logoURL}
            buttonText="Upload Hospital Logo"
          />
        </div>
        <hr className="my-6" />

        <div>
          <span className="text-16 text-primary font-semibold">
            {addHospitalName.LicenseDetails}
          </span>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <InputForm
              label={"License Number*"}
              type="number"
              id="licenseNumber"
              control={control}
              defaultValue={previousData?.licenseNumber}
              maxLength={50}
            />
            <InputForm
              label={"License Authority*"}
              id="licenseAuthority"
              control={control}
              defaultValue={previousData?.licenseAuthority}
              maxLength={50}
            />
            <InputForm
              label={"HFR ID* (Health Facility Registry)"}
              id="hfrId"
              control={control}
              defaultValue={previousData?.hfrId}
              maxLength={50}
            />
          </div>
        </div>

        <hr className="my-6" />

        <div>
          <span className="text-16 text-primary font-semibold">
            {addHospitalName.Address}
          </span>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 my-6">
            <InputForm
              label={"Building Number"}
              id="buildingNumber"
              control={control}
              defaultValue={previousData?.buildingNumber}
              maxLength={50}
              required={false}
            />
            <InputForm
              label={"Street Name*"}
              id="streetName"
              control={control}
              defaultValue={previousData?.streetName}
              maxLength={50}
            />
            <InputForm
              label={"Locality*"}
              id="locality"
              control={control}
              defaultValue={previousData?.locality}
              maxLength={50}
            />
            <div className="hidden w-0 h-0 invisible">
              <InputForm
                label={"Country Id"}
                id="countryId"
                control={control}
                defaultValue={previousData?.countryId}
              />
            </div>
            {countryLoading ? (
              <>
                <AutoCompleteForm
                  label={"Country*"}
                  id={"dummycountry"}
                  options={[]}
                  control={control}
                />
              </>
            ) : (
              <AutoCompleteForm
                label={"Country*"}
                id={"country"}
                options={countryLists ?? []}
                control={control}
                defaultValue={previousData?.country}
                handleChange={(e: any, seletedOption: any) => {
                  setValue("countryId", seletedOption?.value);
                  countryChange();
                }}
              />
            )}

            <div className="hidden w-0 h-0 invisible">
              <InputForm
                label={"State Id"}
                id="stateId"
                control={control}
                defaultValue={previousData?.stateId}
              />
            </div>
            {stateLoading ? (
              <>
                <AutoCompleteForm
                  label={"State*"}
                  id={"dummystate"}
                  options={[]}
                  control={control}
                />
              </>
            ) : (
              <AutoCompleteForm
                label={"State*"}
                id={"state"}
                options={!stateLoading ? stateLists ?? [] : []}
                control={control}
                defaultValue={previousData?.state}
                handleChange={(e: any, seletedOption: any) => {
                  setValue("stateId", seletedOption?.value);
                  stateChange();
                }}
              />
            )}

            <div className="hidden w-0 h-0 invisible">
              <InputForm
                label={"District Id"}
                id="districtId"
                control={control}
                defaultValue={previousData?.districtId}
              />
            </div>
            {districtLoading ? (
              <>
                <AutoCompleteForm
                  label={"District*"}
                  id={"dummydistrict"}
                  options={[]}
                  control={control}
                />
              </>
            ) : (
              <AutoCompleteForm
                label={"District*"}
                id={"district"}
                options={!districtLoading ? districtLists ?? [] : []}
                control={control}
                defaultValue={previousData?.district}
                handleChange={(e: any, seletedOption: any) => {
                  setValue("districtId", seletedOption?.value);
                  districtChange();
                }}
              />
            )}

            <div className="hidden w-0 h-0 invisible">
              <InputForm
                label={"Taluk Id"}
                id="talukId"
                control={control}
                defaultValue={previousData?.talukId}
              />
            </div>
            {talukLoading ? (
              <>
                <AutoCompleteForm
                  label={"Taluk*"}
                  id={"dummytaluk"}
                  options={[]}
                  control={control}
                />
              </>
            ) : (
              <AutoCompleteForm
                label={"Taluk*"}
                id={"taluk"}
                options={!talukLoading ? talukLists ?? [] : []}
                control={control}
                defaultValue={previousData?.taluk}
                handleChange={(e: any, seletedOption: any) => {
                  setValue("talukId", seletedOption?.value);
                  talukChange();
                }}
              />
            )}

            <div className="hidden w-0 h-0 invisible">
              <InputForm
                label={"Hobli Id"}
                id="hobliId"
                control={control}
                defaultValue={previousData?.hobliId}
              />
            </div>
            {hobliLoading ? (
              <>
                <AutoCompleteForm
                  label={"Hobli*"}
                  id={"dummyhobli"}
                  options={[]}
                  control={control}
                />
              </>
            ) : (
              <AutoCompleteForm
                label={"Hobli*"}
                id={"hobli"}
                options={!hobliLoading ? hobliLists ?? [] : []}
                control={control}
                defaultValue={previousData?.hobli}
                handleChange={(e: any, seletedOption: any) => {
                  setValue("hobliId", seletedOption?.value);
                }}
              />
            )}
            <div className="hidden w-0 h-0 invisible">
              <InputForm
                label={"City Id"}
                id="cityId"
                control={control}
                defaultValue={previousData?.cityId}
                required={false}
              />
            </div>
            <InputForm
              label={"Town*"}
              id={"city"}
              control={control}
              defaultValue={previousData?.city}
              maxLength={50}
            />

            <InputForm
              label={"Pincode*"}
              id="pincode"
              type="number"
              control={control}
              defaultValue={previousData?.pincode}
              applyNumberValidation={true}
              maxLength={50}
            />
          </div>
        </div>

        <hr className="my-6" />

        <div>
          <span className="text-16 text-primary font-semibold">
            {addHospitalName.ContactDetails}
          </span>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 mt-6">
            <PhoneNumInputForm
              label={"Mobile Number*"}
              id="mobileNumber"
              control={control}
              defaultValue={previousData?.mobileNumber}
            />
            <EmailInputForm
              label={"Email ID*"}
              id="emailID"
              control={control}
              defaultValue={previousData?.emailID}
              maxLength={50}
            />
            <InputForm
              label={"URL/ Website Name"}
              id="url"
              control={control}
              defaultValue={previousData?.url}
              maxLength={255}
              required={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInformation;
