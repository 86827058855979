import axios from "axios";
import detectOS from "./platform";
import { useEffect, useState } from "react";

const UserAuditDetails = () => {
  const [ipAddress, setIpAddress] = useState("");
  const userAgent = window.navigator.userAgent;
  useEffect(() => {
    const fetchIpData = async () => {
      try {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIpAddress(res.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIpAddress("Unknown");
      }
    };

    fetchIpData();
  }, []);
  let osVersion = "Unknown";

  if (/Windows NT/i.test(userAgent)) {
    // Extracting Windows version
    const match = /Windows NT ([\d.]+)/.exec(userAgent);
    if (match) {
      osVersion = match[1];
    }
  } else if (/Mac OS X/i.test(userAgent)) {
    // Extracting macOS version
    const match = /Mac OS X ([\d_.]+)/.exec(userAgent);
    if (match) {
      osVersion = match[1].replace(/_/g, ".");
    }
  } else if (/Linux/i.test(userAgent)) {
    // Extracting Linux version
    osVersion = "Linux";
  } else if (/iPhone/i.test(userAgent)) {
    // Extracting iOS version
    const match = /OS (\d+)_(\d+)_?(\d+)?/.exec(navigator.appVersion);
    if (match) {
      osVersion = match[1] + "." + match[2] + "." + (match[3] || 0);
    }
  } else if (/Android/i.test(userAgent)) {
    // Extracting Android version
    const match = /Android\s([\d.]+)/.exec(userAgent);
    if (match) {
      osVersion = match[1];
    }
  }

  const operatingSystem = detectOS();

  // const ipAddress = await getIpData();
  return {
    osVersion,
    operatingSystem,
    ipAddress: ipAddress || "Unknown",
  };
};

export default UserAuditDetails;
