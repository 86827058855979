import { TableBody, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateProgressBar } from "../../../components/hospitalManagement/dateProgressBar";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/store";
import {
  getActiveHosCount,
  getActivePaidCount,
  getActivePilotCount,
  getAllHospital,
  getExpiredHosCount,
  getHospitalByStatus,
  getInactiveHosCount,
  getInprogressHosCount,
} from "../../../redux/addHospital/hospitalManagementReducer";
import successToast from "../../../utils/successToast";
import errorToast from "../../../utils/errorToast";
import { LoadingSpinner } from "../../../components/ui/loadinSpinner";
import { resetDeleteHospitalByIdState } from "../../../redux/addHospital/hospitalManagementSlice";
import DefaultImage from "../../../assests/images/defaultImage.svg";
import {
  removeBedSize,
  removeUnderScore,
  istDate,
  removeInstalment,
} from "../../../helper";
import enums from "../../../constants/enums";
import sortValues from "../../../constants/sortColumnValue";
import { MoreOptionIcon } from "../../../components/muiIconsImport";
import StatusWithColor from "../../../utils/StatusWithColor";
import {
  ActionTooltip,
  BootstrapTooltip,
  CustomTooltip,
} from "../../../utils/toolTip";
import routesName from "../../../constants/routesName";
import TableDoughnutChart from "../../../components/charts/tableDoughnutChart";
import TableEquipmentsBar from "../../../components/charts/tableEquipmentsBar";
import useDisplayToggle from "../../../utils/useDisplayToggle";
import ConfirmationModal from "../../../components/modals/confirmationModal";
import DeleteIcon from "../../../assests/icons/deleteIcon.png";
import useAxios from "../../../hooks/useAxios";
import { hospitalApi } from "../../../jsonData/apiPathData/hospital";
import { formatIndianRupee } from "../../../utils/formatIndianRupee";
const styles = {
  // borderLeft: "1px solid #F2F4F7",
  borderRight: "1px solid #F2F4F7",
  borderBottom: "1px solid #F2F4F7",
  background: "white",
  minWidth: "150px",
  maxWidth: "150px",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "0px",
  paddingBottom: "0px",
  color: "#667085",
  fontWeight: 500,
  fontFamily: "Inter, sans-serif",
};
export const TblBodyProductPlan = ({
  labelData,
  selectedValuesOrder,
  currentHosiptalData,
}: any) => {
  const filteredLabelData = labelData?.filter((item: any) =>
    selectedValuesOrder?.includes(item.value)
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentStatus, setCurrentStatus] = useState("allStatus");
  const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
  const [isMoreOption, setisMoreOption] = useState<any>(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [hospitalId, setHospitalId] = useState<string>();

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisMoreOption,
  });

  useEffect(() => {
    if (isMoreOption === false) {
      setMoreOption(null);
    }
  }, [isMoreOption]);

  const toogle = (index: number) => {
    if (moreOption === index) {
      setisMoreOption(false);
      setMoreOption(null);
    } else {
      setisMoreOption(true);
      setMoreOption(index);
    }
  };
  const editHospitalFn = (id: string, status?: string) => {
    navigate(`${routesName.hospitalManagementPage}/hospital/edit`, {
      state: {
        id: id,
        action: status === enums.inprogress ? enums.inprogress : enums.edit,
      },
    });
  };

  const resetHospitalFn = (id: string) => {
    navigate(`${routesName.hospitalManagementPage}/edit-hospital-info/${id}`, {
      state: { action: "resetPassword" },
    });
  };

  const viewHospitalFn = (id: string) => {
    navigate(`${routesName.hospitalManagementPage}/${id}`);
  };

  const deleteHospitalFn = (id: string) => {
    setHospitalId(id);
    setIsDeleteModal(true);
  };

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${hospitalId}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital Deleted Successfully");
      dispatch(
        getAllHospital({
          orderBy: sortValues.orderBy,
          order: sortValues.order,
          page: sortValues.page,
          rowsPerPage: sortValues.rowsPerPage,
          searchKeywords: "",
        })
      );
      dispatch(
        getHospitalByStatus({
          status: currentStatus,
          orderBy: sortValues.orderBy,
          order: sortValues.order,
          page: sortValues.page,
          rowsPerPage: sortValues.rowsPerPage,
          searchKeywords: "",
        })
      );
      dispatch(getActiveHosCount("active"));
      dispatch(getExpiredHosCount("expired"));
      dispatch(getInactiveHosCount("inactive"));
      dispatch(getInprogressHosCount(enums.inprogress));
      dispatch(getActivePaidCount({ status: "active", servicetype: "paid" }));
      dispatch(getActivePilotCount({ status: "active", servicetype: "pilot" }));
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  return (
    <>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      <TableBody>
        {currentHosiptalData?.map((hos: any, index: number) => (
          <React.Fragment key={hos?.hospitalId}>
            <TableRow
              // key={hos?.hospitalId}
              // className="whitespace-nowrap"
              style={{ position: "relative" }}
            >
              {filteredLabelData?.map((columValue: any) => {
                const rowspan =
                  hos?.productandPlanInfos?.length < 1
                    ? null
                    : hos?.productandPlanInfos?.length;
                return (
                  <React.Fragment
                    key={`${hos?.hospitalId}-${columValue.value}`}
                  >
                    {columValue.value === "action" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "80px",
                          maxWidth: "80px",
                          textAlign: "center",
                          height: "100px",
                          position: "relative",
                          // position: `${width > 1536 ? "sticky" : "static"}`,
                          left: "0px",
                        }}
                        rowSpan={rowspan}
                      >
                        <ActionTooltip
                          // position={
                          //   index >= currentHosiptalData?.length - 5
                          //   ? "bottom-0 left-[60px]"
                          //   : "top-0 left-[60px]"
                          // }
                          content={
                            <div
                              className={`text-14 absolute ${
                                index >= currentHosiptalData?.length - 5
                                  ? "bottom-[-40px] left-8"
                                  : "top-[-20px] left-8"
                              } z-30 w-[100] flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                              ref={insideClickRef}
                            >
                              <button
                                className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                                type="button"
                                onClick={() => {
                                  editHospitalFn(
                                    hos?.hospitalId,
                                    hos?.hospitalInfo?.status
                                  );
                                }}
                              >
                                Edit
                              </button>
                              {hos?.hospitalInfo?.status !==
                                enums.inprogress && (
                                <button
                                  className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold"
                                  type="button"
                                  onClick={() => {
                                    // editHospitalFn(
                                    //   hos?.hospitalId,
                                    //   hos?.hospitalInfo?.status
                                    // );
                                    resetHospitalFn(hos?.hospitalId);
                                  }}
                                >
                                  Reset Password
                                </button>
                              )}

                              <button
                                className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg"
                                type="button"
                                onClick={() => {
                                  deleteHospitalFn(hos?.hospitalId);
                                  setCurrentStatus(hos?.hospitalInfo?.status);
                                }}
                              >
                                {deleteLoading ? <LoadingSpinner /> : "Delete"}
                              </button>
                            </div>
                          }
                        >
                          <button
                            className="text-blue-300 w-[24px] h-[24px]"
                            onClick={() => toogle(index)}
                            type="button"
                            ref={onClickRef}
                          >
                            <MoreOptionIcon />
                          </button>
                        </ActionTooltip>
                      </TableCell>
                    )}
                    {columValue.value === "name" && !columValue.hide && (
                      <TableCell
                        rowSpan={rowspan}
                        style={{
                          ...styles,
                          minWidth: "250px",
                          maxWidth: "250px",
                          position: "sticky",
                          left: "0px",
                        }}
                        onClick={() => {
                          if (hos?.hospitalInfo?.status !== enums.inprogress) {
                            viewHospitalFn(hos?.hospitalId);
                          }
                        }}
                      >
                        <CustomTooltip
                          content={
                            hos?.hospitalInfo.status === enums?.inprogress
                              ? "It's in progress. Please complete the Onboarding Process"
                              : ""
                          }
                        >
                          <div
                            className={`grid grid-cols-5 items-center ${
                              hos?.hospitalInfo.status === enums?.inprogress
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          >
                            <div className="col-span-1 ">
                              <img
                                src={
                                  hos?.hospitalInfo?.logoURL
                                    ? hos?.hospitalInfo?.logoURL
                                    : DefaultImage
                                }
                                className="rounded-full w-[36px] h-[36px] border"
                                alt="hospital-logo"
                              />
                            </div>

                            <div className="col-span-4 ps-2">
                              <div>
                                <span className="text-blue-300 text-16 font-semibold">
                                  {hos?.hospitalInfo.name}
                                </span>
                              </div>

                              <span className="text-secondary text-14 font-medium">
                                {hos?.hospitalInfo.code
                                  ? `ID: ${hos?.hospitalInfo.code}`
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </CustomTooltip>
                      </TableCell>
                    )}
                    {columValue.value === "status" && !columValue.hide && (
                      <TableCell
                        rowSpan={rowspan}
                        style={{
                          ...styles,
                          minWidth: "150px",
                          maxWidth: "150px",
                        }}
                      >
                        <StatusWithColor
                          value={
                            hos?.hospitalInfo.status === enums.inactive
                              ? enums?.deactivated
                              : hos?.hospitalInfo.status
                          }
                          subValue={hos?.hospitalInfo?.productStatus}
                        />
                      </TableCell>
                    )}
                    {columValue.value === "city" && !columValue.hide && (
                      <TableCell
                        rowSpan={rowspan}
                        style={{
                          ...styles,
                          minWidth: "180px",
                          maxWidth: "180px",
                        }}
                        className="text-14 font-medium text-secondary"
                      >
                        {hos?.hospitalInfo?.city}
                      </TableCell>
                    )}
                    {columValue.value === "onboarding_date" &&
                      !columValue.hide && (
                        <TableCell
                          rowSpan={
                            hos?.productandPlanInfos?.length < 1
                              ? null
                              : hos?.productandPlanInfos?.length
                          }
                          style={{
                            ...styles,
                            minWidth: "200px",
                            maxWidth: "200px",
                          }}
                        >
                          {hos?.hospitalInfo.status !== enums.inprogress
                            ? moment(
                                istDate(hos?.hospitalInfo?.onboardingDate)
                              ).format("DD-MM-YYYY")
                            : "-"}
                        </TableCell>
                      )}
                    {columValue.value === "products" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "200px",
                          maxWidth: "200px",
                        }}
                      >
                        {hos?.productandPlanInfos?.[0]?.productInfo.name ?? "-"}
                      </TableCell>
                    )}
                    {columValue.value === "add_on_service" &&
                      !columValue.hide && (
                        <TableCell
                          style={{
                            ...styles,
                            minWidth: "250px",
                            maxWidth: "250px",
                          }}
                        >
                          {hos?.productandPlanInfos?.length <= 0 && "-"}
                          {hos?.productandPlanInfos?.[0]?.addOnServices
                            ?.length < 1 && "-"}
                          {hos?.productandPlanInfos?.[0]?.addOnServices
                            ?.slice(0, 1)
                            .map((item: any, index: number) => (
                              <span key={index}>
                                {item?.serviceName ?? "-"}
                              </span>
                            ))}
                          {hos?.productandPlanInfos?.[0]?.addOnServices
                            ?.length > 1 && (
                            <BootstrapTooltip
                              title={
                                <div className="p-1">
                                  {hos?.productandPlanInfos?.[0]?.addOnServices?.map(
                                    (ser: any, serIndex: number) => (
                                      <div
                                        className="text-secondary p-0.5 text-14 font-medium flex items-center gap-2"
                                        key={serIndex}
                                      >
                                        <span className="text-18">
                                          &#x2022;
                                        </span>
                                        {ser?.serviceName}
                                      </div>
                                    )
                                  )}
                                </div>
                              }
                              placement="top"
                            >
                              <span className="ps-2 text-blue-300 font-semibold cursor-pointer">
                                +
                                {hos?.productandPlanInfos?.[0]?.addOnServices
                                  ?.length - 1}{" "}
                                more
                              </span>
                            </BootstrapTooltip>
                          )}
                        </TableCell>
                      )}
                    {columValue.value === "devices" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "220px",
                          maxWidth: "220px",
                        }}
                      >
                        {hos?.productandPlanInfos?.[0]?.packageDevices?.length >
                        0
                          ? `Package : ${
                              hos?.productandPlanInfos?.[0]?.packageDevices
                                ?.length + " "
                            }`
                          : `Package : Nill `}
                        |
                        {hos?.productandPlanInfos?.[0]?.paidDevices?.length >
                        0 ? (
                          <span className="ps-1">
                            <span className="text-blue-300">Paid :</span>{" "}
                            {hos?.productandPlanInfos?.[0]?.paidDevices?.length}
                          </span>
                        ) : (
                          <span className="ps-1">
                            <span className="text-blue-300">Paid :</span> Nill
                          </span>
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "service_type" &&
                      !columValue.hide && (
                        <TableCell style={styles}>
                          {hos?.productandPlanInfos?.[0]?.planInfo?.serviceType
                            ? removeUnderScore(
                                hos?.productandPlanInfos?.[0]?.planInfo
                                  ?.serviceType
                              )
                            : "-"}
                        </TableCell>
                      )}
                    {columValue.value === "plan_type" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "150px",
                          maxWidth: "150px",
                        }}
                      >
                        {hos?.productandPlanInfos?.[0]?.planInfo?.planType
                          ? removeUnderScore(
                              hos?.productandPlanInfos?.[0]?.planInfo?.planType
                            )
                          : "-"}
                      </TableCell>
                    )}
                    {columValue.value === "patient_type" &&
                      !columValue.hide && (
                        <TableCell
                          style={{
                            ...styles,
                            minWidth: "150px",
                            maxWidth: "150px",
                          }}
                        >
                          {hos?.productandPlanInfos?.[0]?.planInfo?.patientType
                            ? removeUnderScore(
                                hos?.productandPlanInfos?.[0]?.planInfo
                                  ?.patientType
                              )
                            : "-"}
                        </TableCell>
                      )}
                    {columValue.value === "price" && !columValue.hide && (
                      <TableCell style={styles}>
                        {hos?.productandPlanInfos?.[0]?.planInfo
                          ?.patientType === "all"
                          ? hos?.productandPlanInfos?.[0]?.planInfo
                              ?.planTotalAmount === 0
                            ? "Free"
                            : formatIndianRupee(
                                hos?.productandPlanInfos?.[0]?.planInfo
                                  ?.planTotalAmount
                              )
                          : hos?.productandPlanInfos?.[0]?.planInfo
                              ?.patientType === "in_patient"
                          ? hos?.productandPlanInfos?.[0]?.planInfo
                              ?.inPatientAmount === 0
                            ? "Free"
                            : formatIndianRupee(
                                hos?.productandPlanInfos?.[0]?.planInfo
                                  ?.inPatientAmount
                              )
                          : hos?.productandPlanInfos?.[0]?.planInfo
                              ?.patientType === "out_patient"
                          ? hos?.productandPlanInfos?.[0]?.planInfo
                              ?.outPatientAmount === 0
                            ? "Free"
                            : formatIndianRupee(
                                hos?.productandPlanInfos?.[0]?.planInfo
                                  ?.outPatientAmount
                              )
                          : "Free"}
                      </TableCell>
                    )}
                    {columValue.value === "plan_validity" &&
                      !columValue.hide && (
                        <TableCell style={{ ...styles, minWidth: "320px" }}>
                          <div className="flex gap-3 items-center whitespace-nowrap">
                            {hos?.productandPlanInfos?.[0]?.planInfo
                              ?.planType !== "per_annum" ? (
                              "NA"
                            ) : (
                              <>
                                <DateProgressBar
                                  validityStart={
                                    hos?.productandPlanInfos?.[0]?.planInfo
                                      ?.validityStart ?? 0
                                  }
                                  validityEnd={
                                    hos?.productandPlanInfos?.[0]?.planInfo
                                      ?.validityEnd ?? 0
                                  }
                                />
                                <span className="border-l border-gray-300 ps-2">
                                  <span>Exp. Date:</span>
                                  <span className="font-semibold text-primary">
                                    {hos?.productandPlanInfos?.[0]?.planInfo
                                      ?.validityEnd
                                      ? moment(
                                          hos?.productandPlanInfos?.[0]
                                            ?.planInfo?.validityEnd
                                        ).format("DD MMM YYYY")
                                      : " -"}
                                  </span>
                                </span>
                              </>
                            )}
                          </div>
                        </TableCell>
                      )}
                    {columValue.value === "ownerShip" && !columValue.hide && (
                      <TableCell style={styles} rowSpan={rowspan}>
                        {removeUnderScore(
                          hos?.hospitalInfo?.ownerInfo?.ownershipType
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "onboarding_source" &&
                      !columValue.hide && (
                        <TableCell
                          style={styles}
                          rowSpan={
                            hos?.productandPlanInfos?.length < 1
                              ? null
                              : hos?.productandPlanInfos?.length
                          }
                        >
                          {removeUnderScore(
                            hos?.hospitalInfo?.onBoardingSource?.sourceType
                          )}
                        </TableCell>
                      )}
                    {columValue.value === "onboarding_source_name" &&
                      !columValue.hide && (
                        <TableCell
                          style={styles}
                          rowSpan={
                            hos?.productandPlanInfos?.length < 1
                              ? null
                              : hos?.productandPlanInfos?.length
                          }
                        >
                          {hos?.hospitalInfo?.onBoardingSource?.sourceName}
                        </TableCell>
                      )}
                    {columValue.value === "security_deposit" &&
                      !columValue.hide && (
                        <TableCell
                          style={styles}
                          rowSpan={
                            hos?.productandPlanInfos?.length < 1
                              ? null
                              : hos?.productandPlanInfos?.length
                          }
                        >
                          {formatIndianRupee(hos?.deviceCost?.securityDeposit)}
                        </TableCell>
                      )}
                    {columValue.value === "device_cost" && !columValue.hide && (
                      <TableCell style={styles} rowSpan={rowspan}>
                        {formatIndianRupee(
                          hos?.deviceCost?.totalPaidDevicesCost
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "instalments" && !columValue.hide && (
                      <TableCell style={styles} rowSpan={rowspan}>
                        {removeInstalment(hos?.deviceCost?.instalments)}
                      </TableCell>
                    )}
                    {columValue.value === "one_time_cost" &&
                      !columValue.hide && (
                        <TableCell
                          style={styles}
                          rowSpan={
                            hos?.productandPlanInfos?.length < 1
                              ? null
                              : hos?.productandPlanInfos?.length
                          }
                        >
                          {formatIndianRupee(hos?.deviceCost?.oneTimeCost)}
                        </TableCell>
                      )}
                    {columValue.value === "speciality" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "200px",
                          maxWidth: "200px",
                        }}
                        rowSpan={rowspan}
                      >
                        {hos?.hospitalInfo?.speciality?.length < 1 && "Null"}
                        {hos?.hospitalInfo?.speciality
                          ?.slice(0, 1)
                          .map((item: any, index: number) => (
                            <span key={index}>{item?.name}</span>
                          ))}
                        {hos?.hospitalInfo?.speciality?.length > 1 && (
                          <BootstrapTooltip
                            title={
                              <div className="p-1">
                                {hos?.hospitalInfo?.speciality?.map(
                                  (ser: any) => (
                                    <div
                                      className="text-secondary p-0.5 text-14 font-medium flex items-center gap-2"
                                      key={index}
                                    >
                                      <span className="text-18">&#x2022;</span>
                                      {ser?.name}
                                    </div>
                                  )
                                )}
                              </div>
                            }
                            placement="top"
                          >
                            <span className="ps-2 text-blue-300 font-semibold cursor-pointer">
                              +{hos?.hospitalInfo?.speciality?.length - 1} more
                            </span>
                          </BootstrapTooltip>
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "bed_size" && !columValue.hide && (
                      <TableCell style={styles} rowSpan={rowspan}>
                        {removeBedSize(hos?.hospitalInfo?.bedSize)}
                      </TableCell>
                    )}
                    {columValue.value === "ICU_beds" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "300px",
                          maxWidth: "300px",
                        }}
                        rowSpan={rowspan}
                      >
                        <TableDoughnutChart
                          chartData={{
                            Vacant: 6,
                            Occupied: 12,
                            Booked: 6,
                          }}
                          width="w-[70px]"
                          colors={["#E27C8B", "#F7C068", "#A389BF"]}
                        />
                        {/* ICU_beds */}
                      </TableCell>
                    )}
                    {columValue.value === "ward_beds" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "300px",
                          maxWidth: "300px",
                        }}
                        rowSpan={rowspan}
                      >
                        <TableDoughnutChart
                          chartData={{
                            Vacant: 6,
                            Occupied: 12,
                            Booked: 6,
                          }}
                          width="w-[70px]"
                          colors={["#E27C8B", "#F7C068", "#A389BF"]}
                        />
                        {/* ward_beds */}
                      </TableCell>
                    )}
                    {columValue.value === "OT_tables" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "420px",
                          maxWidth: "420px",
                        }}
                        rowSpan={rowspan}
                      >
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col gap-3 border-r-2 px-3 text-center">
                            <span className="bg-green-100 p-1 rounded">
                              <span className="text-secondary text-14 font-normal">
                                Tables:
                              </span>{" "}
                              <span className="text-primary text-14 font-semibold">
                                10
                              </span>
                            </span>
                            <span>
                              <span className="text-secondary text-14 font-normal">
                                Surgeries:
                              </span>{" "}
                              <span className="text-primary text-14 font-semibold">
                                14
                              </span>
                            </span>
                          </div>
                          <TableDoughnutChart
                            chartData={{
                              "Booked Hrs": 10,
                              "Available Hrs": 14,
                            }}
                            width="w-[70px]"
                            colors={["#F7C068", "#A389BF"]}
                          />
                        </div>
                        {/* OT_tables */}
                      </TableCell>
                    )}
                    {columValue.value === "equipments" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "600px",
                          maxWidth: "600px",
                        }}
                        rowSpan={rowspan}
                      >
                        <TableEquipmentsBar
                          Equipments={[
                            {
                              equipmentId: 1000,
                              name: "Ventilator",
                              used: 50,
                              booked: 80,
                              unused: 70,
                            },
                            {
                              equipmentId: 1001,
                              name: "Dialyzer",
                              used: 10,
                              booked: 20,
                              unused: 50,
                            },
                            {
                              equipmentId: 1002,
                              name: "Oxygen Cylinder",
                              used: 16,
                              booked: 4,
                              unused: 80,
                            },
                          ]}
                          colors={["#F7C068", "#A389BF", "#E27C8B"]}
                          legends={["Used", "Booked", "Unused"]}
                        />
                        {/* equipments */}
                      </TableCell>
                    )}
                  </React.Fragment>
                );
              })}
            </TableRow>

            {hos?.productandPlanInfos?.slice(1).map((app: any, jIndex: any) => (
              <TableRow
                key={`${hos?.hospitalInfo.code}-app-${jIndex}`}
                className="whitespace-nowrap"
              >
                {filteredLabelData.map((columValue: any) => (
                  <React.Fragment
                    key={`${hos?.hospitalInfo.code}-${columValue.value}`}
                  >
                    {columValue.value === "products" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "200px",
                          maxWidth: "200px",
                        }}
                      >
                        {app?.productInfo.name}
                      </TableCell>
                    )}
                    {columValue.value === "add_on_service" &&
                      !columValue.hide && (
                        <TableCell style={styles}>
                          {app?.addOnServices?.length < 1 && "-"}
                          {app?.addOnServices
                            ?.slice(0, 1)
                            .map((item: any, index: number) => (
                              <span key={index}>{item?.serviceName}</span>
                            ))}

                          {app?.addOnServices?.length > 1 && (
                            <BootstrapTooltip
                              title={
                                <div className="p-1">
                                  {app?.addOnServices?.map(
                                    (ser: any, serIndex: number) => (
                                      <div
                                        className="text-secondary p-0.5 text-14 font-medium flex items-center gap-2"
                                        key={serIndex}
                                      >
                                        <span className="text-18">
                                          &#x2022;
                                        </span>
                                        {ser?.serviceName}
                                      </div>
                                    )
                                  )}
                                </div>
                              }
                              placement="top"
                            >
                              <span className="ps-2 text-blue-300 font-semibold cursor-pointer">
                                +{app?.addOnServices?.length - 1} more
                              </span>
                            </BootstrapTooltip>
                          )}
                        </TableCell>
                      )}
                    {columValue.value === "devices" && !columValue.hide && (
                      <TableCell style={styles}>
                        {app.packageDevices?.length > 0
                          ? `Package : ${app.packageDevices?.length + " "}`
                          : `Package : Nill `}
                        |
                        {app.paidDevices?.length > 0 ? (
                          <span className="ps-1">
                            <span className="text-blue-300">Paid :</span>{" "}
                            {app.paidDevices?.length}
                          </span>
                        ) : (
                          <span className="ps-1">
                            <span className="text-blue-300">Paid :</span> Nill
                          </span>
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "service_type" &&
                      !columValue.hide && (
                        <TableCell style={styles}>
                          {app?.planInfo?.serviceType
                            ? removeUnderScore(app?.planInfo?.serviceType)
                            : "-"}
                        </TableCell>
                      )}
                    {columValue.value === "plan_type" && !columValue.hide && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "150px",
                          maxWidth: "150px",
                        }}
                      >
                        {app?.planInfo?.planType
                          ? removeUnderScore(app?.planInfo?.planType)
                          : "-"}
                      </TableCell>
                    )}
                    {columValue.value === "patient_type" &&
                      !columValue.hide && (
                        <TableCell
                          style={{
                            ...styles,
                            minWidth: "150px",
                            maxWidth: "150px",
                          }}
                        >
                          {app?.planInfo?.patientType
                            ? removeUnderScore(app?.planInfo?.patientType)
                            : "-"}
                        </TableCell>
                      )}

                    {columValue.value === "price" && !columValue.hide && (
                      <TableCell style={styles}>
                        {app?.planInfo?.patientType === "all"
                          ? app?.planInfo?.planTotalAmount === 0
                            ? "Free"
                            : formatIndianRupee(app?.planInfo?.planTotalAmount)
                          : app?.planInfo?.patientType === "in_patient"
                          ? app?.planInfo?.inPatientAmount === 0
                            ? "Free"
                            : formatIndianRupee(app?.planInfo?.inPatientAmount)
                          : app?.planInfo?.patientType === "out_patient"
                          ? app?.planInfo?.outPatientAmount === 0
                            ? "Free"
                            : formatIndianRupee(app?.planInfo?.outPatientAmount)
                          : "Free"}
                      </TableCell>
                    )}
                    {columValue.value === "plan_validity" &&
                      !columValue.hide && (
                        <TableCell style={{ ...styles, minWidth: "320px" }}>
                          <div className="flex gap-3 items-center whitespace-nowrap">
                            {app?.planInfo?.planType !== "per_annum" ? (
                              "NA"
                            ) : (
                              <>
                                <DateProgressBar
                                  validityStart={
                                    app?.planInfo?.validityStart ?? 0
                                  }
                                  validityEnd={app?.planInfo?.validityEnd ?? 0}
                                />
                                <span className="border-l border-gray-300 ps-2">
                                  <span>Exp. Date:</span>
                                  <span className="font-semibold text-primary">
                                    {app?.planInfo?.validityEnd
                                      ? moment(
                                          app?.planInfo?.validityEnd
                                        ).format("DD MMM YYYY")
                                      : " -"}
                                  </span>
                                </span>
                              </>
                            )}
                          </div>
                        </TableCell>
                      )}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </React.Fragment>
        ))}
      </TableBody>
    </>
  );
};

export const TblBodyInProgressProductPlan = ({
  labelData,
  selectedValuesOrder,
  currentHosiptalData,
}: any) => {
  const filteredLabelData = labelData?.filter((item: any) =>
    selectedValuesOrder?.includes(item.value)
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [moreOption, setMoreOption] = useState<null | number | boolean>(null);
  const [isMoreOption, setisMoreOption] = useState<any>(false);
  const [hospitalId, setHospitalId] = useState<string>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);
  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setisMoreOption,
  });

  useEffect(() => {
    if (isMoreOption === false) {
      setMoreOption(null);
    }
  }, [isMoreOption]);

  const toogle = (index: number) => {
    if (moreOption === index) {
      setisMoreOption(false);
      setMoreOption(null);
    } else {
      setMoreOption(index);
      setisMoreOption(true);
    }
  };

  const editHospitalFn = (id: string) => {
    navigate(`hospital/edit`, { state: { id: id, action: enums.inprogress } });
  };

  const viewHospitalFn = (id: string) => {
    navigate(`${id}`);
  };
  const deleteHospitalFn = (id: string) => {
    setHospitalId(id);
    setIsDeleteModal(true);
  };

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${hospitalId}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital Deleted Successfully");
      dispatch(
        getHospitalByStatus({
          status: enums.inprogress,
          orderBy: sortValues.orderBy,
          order: sortValues.order,
          page: sortValues.page,
          rowsPerPage: sortValues.rowsPerPage,
          searchKeywords: "",
        })
      );
      dispatch(getInprogressHosCount(enums.inprogress));
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  return (
    <>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      <TableBody>
        {currentHosiptalData?.map((hos: any, index: number) => (
          <React.Fragment key={hos?.hospitalId}>
            <TableRow
              style={{ position: "relative" }}
            >
              {filteredLabelData?.map((columValue: any) => {
                return (
                  <React.Fragment
                    key={`${hos?.hospitalId}-${columValue.value}`}
                  >
                    {columValue.value === "action" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "80px",
                          maxWidth: "80px",
                          textAlign: "center",
                          height: "100px",
                        }}
                      >
                        <ActionTooltip
                          content={
                            <div
                              className={`text-14  absolute z-30  ${
                                index >= currentHosiptalData?.length - 5
                                  ? "bottom-[-40px] left-8"
                                  : "top-[-20px] left-8"
                              } flex flex-col  shadow-lg rounded-lg bg-white whitespace-nowrap`}
                              ref={insideClickRef}
                            >
                              <button
                                className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold  rounded-t-lg"
                                onClick={() => {
                                  editHospitalFn(hos?.hospitalId);
                                }}
                              >
                                Edit
                              </button>

                              <button
                                className="p-3 px-5 text-start text-secondary bg-white hover:text-primary hover:bg-blue-200 font-medium hover:font-semibold rounded-b-lg"
                                onClick={() => {
                                  deleteHospitalFn(hos?.hospitalId);
                                }}
                              >
                                {deleteLoading ? <LoadingSpinner /> : "Delete"}
                              </button>
                            </div>
                          }
                        >
                          <button
                            onClick={() => toogle(index)}
                            className="text-blue-300 w-[24px] h-[24px]"
                            type="button"
                            ref={onClickRef}
                          >
                            <MoreOptionIcon />
                          </button>
                        </ActionTooltip>
                      </TableCell>
                    )}
                    {columValue.value === "name" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "250px",
                          maxWidth: "250px",
                          position: "sticky",
                          left: "0px",
                        }}
                      >
                        <div className={`grid grid-cols-5 items-center `}>
                          <div className="col-span-1 ">
                            <img
                              src={
                                hos?.hospitalInfo?.logoURL
                                  ? hos?.hospitalInfo?.logoURL
                                  : DefaultImage
                              }
                              className="rounded-full w-[36px] h-[36px] border"
                              alt="hospital-logo"
                            />
                          </div>

                          <div className="col-span-4 ps-2">
                            <div>
                              <span className="text-blue-300 text-16 font-semibold">
                                {hos?.hospitalInfo.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    )}

                    {columValue.value === "city" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "180px",
                          maxWidth: "180px",
                        }}
                      >
                        {hos?.hospitalInfo?.city}
                      </TableCell>
                    )}

                    {columValue.value === "speciality" && (
                      <TableCell
                        style={{
                          ...styles,
                          minWidth: "200px",
                          maxWidth: "200px",
                        }}
                      >
                        {hos?.hospitalInfo?.speciality?.length < 1 && "Null"}
                        {hos?.hospitalInfo?.speciality
                          ?.slice(0, 1)
                          .map((item: any, index: number) => (
                            <span key={index}>{item?.name}</span>
                          ))}
                        {hos?.hospitalInfo?.speciality?.length > 1 && (
                          <BootstrapTooltip
                            title={
                              <div className="p-1">
                                {hos?.hospitalInfo?.speciality?.map(
                                  (ser: any) => (
                                    <div className="text-secondary p-0.5 text-14 font-medium flex items-center gap-2">
                                      <span className="text-18">&#x2022;</span>
                                      {ser?.name}
                                    </div>
                                  )
                                )}
                              </div>
                            }
                            placement="top"
                          >
                            <span className="ps-2 text-blue-300 font-semibold cursor-pointer">
                              + {hos?.hospitalInfo?.speciality?.length - 1}
                              more
                            </span>
                          </BootstrapTooltip>
                        )}
                      </TableCell>
                    )}

                    {columValue.value === "bed_size" && (
                      <TableCell style={styles}>
                        {removeBedSize(hos?.hospitalInfo?.bedSize)}
                      </TableCell>
                    )}
                    {columValue.value === "owner_operator" && (
                      <TableCell style={styles}>
                        {hos?.hospitalInfo?.ownerInfo?.operatorInfo?.name ? (
                          <div className="flex flex-col">
                            <span className="text-primary">
                              {hos?.hospitalInfo?.ownerInfo?.operatorInfo?.name}
                            </span>
                            <span>
                              {
                                hos?.hospitalInfo?.ownerInfo?.operatorInfo
                                  ?.phone
                              }
                            </span>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <span className="text-primary">
                              {hos?.hospitalInfo?.ownerInfo?.name}
                            </span>
                            <span>{hos?.hospitalInfo?.ownerInfo?.phone}</span>
                          </div>
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "source_of_onboarding" && (
                      <TableCell style={styles}>
                        {removeUnderScore(
                          hos?.hospitalInfo?.onBoardingSource?.sourceType
                        )}
                      </TableCell>
                    )}
                    {columValue.value === "management_name" && (
                      <TableCell style={styles}>
                        <div className="flex flex-col">
                          <span className="text-primary">
                            {hos?.hospitalInfo?.managementUserInfo?.[0]?.name}
                          </span>
                          <span>
                            {hos?.hospitalInfo?.managementUserInfo?.[0]?.phone}
                          </span>
                        </div>
                      </TableCell>
                    )}
                    {columValue.value === "account_manager" && (
                      <TableCell style={styles}>
                        <div className="flex flex-col">
                          <span className="text-primary">
                            {hos?.hospitalInfo?.accountManager?.name}
                          </span>
                          <span>
                            {hos?.hospitalInfo?.accountManager?.phone}
                          </span>
                        </div>
                      </TableCell>
                    )}
                  </React.Fragment>
                );
              })}
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </>
  );
};
