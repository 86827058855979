import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  ErrorWithDeleteButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../redux/store";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import axios from "axios";
import { enumDataApiList } from "../../jsonData/addHospitalsData";
import {
  addEnumData,
  managementStatus,
  resetDeleteHospitalByIdState,
  resetGetHospitalByIdState,
  resetUpdateHospitalState,
} from "../../redux/addHospital/hospitalManagementSlice";
import ProductCheckBox from "../../components/hospitalManagement/productCheckbox";
import { useSelector } from "react-redux";
import {
  deleteHospitalById,
  getDevicesList,
  getHospitalById,
  getProductServiceType,
  getProductType,
  updateHospital,
} from "../../redux/addHospital/hospitalManagementReducer";
import CustomAccordion from "../../components/ui/accordion";
import ManagementAssistant from "../../features/hospitalManagement/managementAssistant";
import ClinicalAssistant from "../../features/hospitalManagement/clinicalAssistant";
import CostDetails from "../../features/hospitalManagement/costDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CostDetailsObject,
  ProductDetailsObjects,
  OnBoardDispatchData,
} from "../../features/hospitalManagement/dataformatter";
import successToast from "../../utils/successToast";
import errorToast from "../../utils/errorToast";
import constants from "../../constants";
import routesName from "../../constants/routesName";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import ConfirmationModal from "../../components/modals/confirmationModal";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import { hospitalApi } from "../../jsonData/apiPathData/hospital";
import useAxios from "../../hooks/useAxios";
import UserAuditDetails from "../../utils/userAuditDetails";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import { ErrorMessageIcon } from "../../components/muiIconsImport";
import { findRepeatedValues } from "../../utils/findRepeatedValue";
import { commonDevices } from "../../redux/devices/deviceMangmentSlice";

const EditProductAndPlan = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const productsData: any = useSelector((data: any) => data?.getProductType);
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const [firstFormError, setFirstFormError] = useState("");
  const userDetails = AccessTokenDecoder();
  const scrollDown = useRef<null | HTMLDivElement>(null);
  // duplicate devices inditification
  const commanDevicesRes = useSelector((data: any) => data?.commanDevices);
  const [commanDevices, setCommanDevices] = useState<any>([]);
  useEffect(() => {
    setCommanDevices(commanDevicesRes?.data);
  }, [commanDevicesRes]);

  useEffect(() => {
    axios
      .all(
        enumDataApiList.map((endpoint) =>
          axios.get(endpoint, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem(
                constants.accessTokenText
              )}`,
            },
          })
        )
      )
      .then(
        axios.spread(
          (
            { data: OwnershipType },
            { data: AddOnServiceType },
            { data: BedStatus },
            { data: DeviceType },
            { data: HospitalsStatus },
            { data: Instalments },
            { data: PatientType },
            { data: SourceType },
            { data: PlanType },
            { data: ValidityPeriod },
            { data: BedSizeType }
          ) => {
            const data = {
              OwnershipType,
              AddOnServiceType,
              BedStatus,
              DeviceType,
              HospitalsStatus,
              Instalments,
              PatientType,
              SourceType,
              PlanType,
              ValidityPeriod,
              BedSizeType,
            };

            dispatch(addEnumData(data));
            setLoading(false);
          }
        )
      )
      .catch((err) => {
        setLoading(false);
      });

    dispatch(getProductType());
    dispatch(getProductServiceType());
    dispatch(getDevicesList());
    dispatch(getHospitalById(params?.id as string));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetGetHospitalByIdState());
    };
  }, []);

  const hospitalDetailsRes = useSelector((data: any) => data?.getHospitalById);
  const hospitalInfo = hospitalDetailsRes?.data?.data;
  const productAndPlanData = hospitalInfo?.productandPlanInfos;

  const productInfoData = productAndPlanData?.map((item: any) => {
    return item?.productInfo;
  });

  const { managementAssistantObject, clinicalAssistantObject } =
    ProductDetailsObjects(hospitalInfo);

  const costDetailsObject = CostDetailsObject(hospitalInfo);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors: formErrors },
  } = useForm<any>();

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: userDetails?.userId,
    userName: userDetails?.userName,
    role: userDetails?.Roles,
    customer: hospitalInfo?.hospitalInfo?.name,
    customerCode: hospitalInfo?.hospitalInfo?.code,
    customerID:
      hospitalInfo?.hospitalId ?? "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: "string",
    auditDate: "2024-04-11T10:44:38.662Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (commanDevices?.length >= 1) {
      return errorToast("The devices are been duplicated");
    }
    const dispatchData = OnBoardDispatchData(
      data,
      null,
      userDetails,
      userAuditInfo
    );
    const finalDispatchData = {
      ...dispatchData,
      hospitalInfo: hospitalDetailsRes?.data?.data?.hospitalInfo,
      hospitalAdminInfo: hospitalDetailsRes?.data?.data?.hospitalAdminInfo,
      groupName: hospitalDetailsRes?.data?.data?.groupName,
      isPartOfGroup: hospitalDetailsRes?.data?.data?.isPartOfGroup,
    };
    dispatch(
      updateHospital({
        id: params?.id,
        isSaveAsDraft: false,
        dispatchData: finalDispatchData,
      })
    );
  };

  const updateHospitalRes = useSelector((data: any) => data?.updateHospital);

  useEffect(() => {
    if (updateHospitalRes?.status === "success") {
      // navigating back to device screen if user clicks on unlink button from linked device screen
      if (location?.state === "linkedDevice-edit") {
        navigate(`${routesName.devicesPage}/${routesName.linkDevice}`);
      } else {
        successToast("Hospital Updated Successfully");
        navigate(-1);
      }
    } else if (updateHospitalRes?.status === "failed") {
      if (updateHospitalRes?.error?.status === 503) {
        successToast("Hospital Updated Successfully");
        navigate(-1);
      } else if (
        updateHospitalRes?.error?.data?.error?.code === "DUPLICATE_DEVICE_ERROR"
      ) {
        errorToast(updateHospitalRes?.error?.data?.error?.message);
        dispatch(commonDevices(updateHospitalRes.error?.data?.data));
      } else {
        errorToast(
          updateHospitalRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [updateHospitalRes]);

  const isClinicalAssistant = watch("productInfo") ?? productInfoData;

  const displayClinicalAssis = isClinicalAssistant?.find(
    (item: any) => item.productId === 1001
  );
  useEffect(() => {
    return () => {
      dispatch(resetUpdateHospitalState());
    };
  }, []);

  const cancelHospitalFn = () => {
    navigate(-1);
  };

  const editHospitalNavData = [
    {
      label: "Hospital Management",
      navigate: `${routesName.hospitalManagementPage}`,
      highLight: false,
    },

    {
      label: `Edit ${hospitalInfo?.hospitalInfo?.name}`,
      highLight: true,
    },
  ];

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${params?.id}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital deleted successfully");
      navigate(routesName.hospitalManagementPage);
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  const firstErrorField = Object.keys(formErrors).find(
    (fieldName) => formErrors[fieldName]
  );

  useEffect(() => {
    if (firstErrorField) {
      const errorMessage = formErrors?.[firstErrorField]?.message;
      setFirstFormError(errorMessage as string);
    }
  }, [firstErrorField]);

  const errorShowOnBottom = () => {
    if (firstFormError) {
      scrollDown?.current?.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  };

  // useEffect(() => {
  //   if (scrollDown.current && firstFormError) {
  //     scrollDown?.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  //   }
  // }, [scrollDown, firstFormError]);

  const getdeviceValue = (value: any) => {
    const deviceValue = watch(value);
    return deviceValue === "noDevices" || deviceValue === undefined
      ? []
      : deviceValue;
  };

  const maPackageDevices = getdeviceValue("maPackageDevices");
  const maPaidDevices = getdeviceValue("maPaidDevices");
  const clPackageDevices = getdeviceValue("clPackageDevices");
  const clPaidDevices = getdeviceValue("clPaidDevices");

  useEffect(() => {
    const mergedArray = [
      ...maPackageDevices,
      ...maPaidDevices,
      ...clPackageDevices,
      ...clPaidDevices,
    ];

    const serialNumberArray = mergedArray?.map(
      (item: any) => item?.serialNumber
    );
    const commonArray = findRepeatedValues(serialNumberArray);
    dispatch(commonDevices(commonArray));
  }, [
    watch("maPackageDevices"),
    watch("maPaidDevices"),
    watch("clPackageDevices"),
    watch("clPaidDevices"),
  ]);

  useEffect(() => {
    const isMaActive =
      productAndPlanData?.find(
        (item: any) => item?.productInfo?.productId === 1000
      )?.planInfo?.isActive === 1
        ? true
        : false;
    if (productAndPlanData) {
      dispatch(managementStatus(isMaActive));
    }
  }, [hospitalDetailsRes]);

  return (
    <>
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      {loading || hospitalDetailsRes?.isLoading ? (
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="">
          <InnerLayout title={`${hospitalInfo?.hospitalInfo?.name}`}>
            <div className="p-6  z-10 top-[80px]">
              <Breadcrumbs data={editHospitalNavData} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="pb-20">
              <div className="mx-6">
                <div className="mt-5">
                  <div className="border-gray-250 border rounded-lg px-8 pt-5 pb-3 shadow-sm1">
                    <span className="text-18 font-semibold text-primary">
                      {addHospitalName.ChooseProducts}
                    </span>
                    {productsData?.status === "success" ? (
                      <div className="pt-4">
                        <ProductCheckBox
                          data={productsData?.data?.data}
                          setValue={setValue}
                          control={control}
                          name="productInfo"
                          defaultValue={productInfoData}
                          clearErrors={clearErrors}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center py-4">
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>
                </div>
                <div className="" id="management_assistant">
                  <CustomAccordion
                    title="Management Assistant"
                    component={
                      <ManagementAssistant
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        previousDataPA={managementAssistantObject}
                        clearErrors={clearErrors}
                      />
                    }
                  />
                </div>

                {displayClinicalAssis && (
                  <div className="" id="clinical_assistant">
                    <CustomAccordion
                      title="Clinical Assistant"
                      component={
                        <ClinicalAssistant
                          control={control}
                          setValue={setValue}
                          watch={watch}
                          previousDataPA={clinicalAssistantObject}
                          clearErrors={clearErrors}
                        />
                      }
                    />
                  </div>
                )}
                <CustomAccordion
                  title="Cost Details"
                  component={
                    <CostDetails
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      previousDataPA={costDetailsObject}
                    />
                  }
                />
                <div className="h-[2.5rem] flex justify-center">
                  {firstErrorField && (
                    <span className="text-error text-12 flex items-center">
                      <ErrorMessageIcon styles={{ width: "15px" }} /> :{" "}
                      {/* {firstFormError} */}
                      Mandatory fields are required to be filled
                    </span>
                  )}
                </div>
              </div>

              <div className="fixed bottom-0 fit_width z-20 shadow-2xl">
                <div className="bg-white p-3 px-7 flex justify-between">
                  <ErrorWithDeleteButton
                    padding="lg:px-12 py-3 px-5"
                    loading={deleteLoading}
                    name={addHospitalName.DeleteHospital}
                    type="button"
                    onClick={() => setIsDeleteModal(true)}
                  />

                  <div className="flex gap-3">
                    <SecondaryButton
                      padding="lg:px-18 py-3 px-5"
                      type="button"
                      onClick={() => cancelHospitalFn()}
                      name={addHospitalName.Cancel}
                    />
                    <PrimaryButton
                      padding="lg:px-18 py-3 px-5"
                      loading={updateHospitalRes?.isLoading}
                      name={addHospitalName.Save}
                      onClick={() => errorShowOnBottom()}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div ref={scrollDown}></div>
          </InnerLayout>
        </div>
      )}
    </>
  );
};

export default EditProductAndPlan;
