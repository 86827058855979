import { useEffect, useState } from "react";
import InnerLayout from "../../layout/innerLayout";
import Breadcrumbs from "../../components/breadCrumbs";
import routesName from "../../constants/routesName";
import AccountManagerProgressBar from "../../features/accountManager/accountManagerProgressBar";
import { SubmitHandler, useForm } from "react-hook-form";
import ImageUpload from "../../components/forms/imageUpload";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import {
  EmailInputForm,
  InputForm,
  PhoneNumInputForm,
} from "../../components/forms/inputForm";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import { useAppDispatch } from "../../redux/store";
import { CheckBoxForm } from "../../components/forms/checkBoxForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SelectForm from "../../components/forms/selectForm";
import { genderData } from "../../jsonData/accountManager";
import AutoGeneratePassword from "../../components/autoGeneratePassword";
import enums from "../../constants/enums";
import {
  getAccontManager,
  updateAccontManager,
} from "../../redux/accountManager/accountManagerReducer";
import {
  resetActivateDeactiveAccManagerState,
  resetDeleteAccManagerState,
  resetGetAccontManagerState,
  resetUpdateAccontManagerState,
} from "../../redux/accountManager/accountManagerSlice";
import { useSelector } from "react-redux";
import errorToast from "../../utils/errorToast";
import AccountManagerEditTab from "../../features/accountManager/editTab";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { isObjectEmpty } from "../../utils/isObject";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import successToast from "../../utils/successToast";
import ConfirmationModal from "../../components/modals/confirmationModal";
import { addAccManagerDispatchData } from "../../features/accountManager/dataformatter";
import { ResponseType } from "../../modals/IreduxResponse";
import {
  AccountManagerType,
  FormdataAccountManagerPersonelInfo,
} from "../../modals/IaccountManager";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import ActivateDeactivateIcon from "../../assests/icons/activateDeactivateIcon.svg";
import UserAuditDetails from "../../utils/userAuditDetails";
import DetectDevice from "../../utils/detectDevice";
import useAxios from "../../hooks/useAxios";

const AddEditPersonalInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const pathAction = params.action;
  const userDetails = AccessTokenDecoder();
  const [actionType, setActionType] = useState("reset_password");
  const [activateDeactiveModal, setActivateDeactiveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [accountManagerData, setAccountManagerData] =
    useState<AccountManagerType>();
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const deviceType = DetectDevice();

  useEffect(() => {
    // getting user data in edit flow
    if (pathAction === enums.edit) {
      dispatch(getAccontManager(location.state.id));
    }

    return () => {
      dispatch(resetGetAccontManagerState());
      dispatch(resetUpdateAccontManagerState());
      dispatch(resetActivateDeactiveAccManagerState());
      dispatch(resetDeleteAccManagerState());
    };
  }, []);

  // account manager ressponse data
  const getAccontManagerRes = useSelector(
    (data: { getAccontManager: ResponseType }) => data.getAccontManager
  );

  useEffect(() => {
    // handling account manager ressponse
    if (getAccontManagerRes.status === "success") {
      setAccountManagerData(getAccontManagerRes.data.data);
    } else if (getAccontManagerRes.status === "failed") {
      errorToast("Something went wrong while fetching Account Manager");
      console.error(getAccontManagerRes, "getAccontManagerRes");
    }
  }, [getAccontManagerRes]);

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: accountManagerData?.userId,
    userName: accountManagerData?.userName,
    role: accountManagerData?.role,
    customer: accountManagerData?.name,
    customerCode: accountManagerData?.userName,
    customerID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: deviceType,
    auditDate: "2024-03-15T06:57:07.737Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormdataAccountManagerPersonelInfo>();

  const onSubmit: SubmitHandler<any> = async (data) => {
    //add flow
    if (pathAction === enums.add) {
      navigate(
        `${routesName.accountManagerPage}${routesName.rolesAuthoritiesPage}/${pathAction}`,
        {
          state: { action: pathAction, data: data },
        }
      );
    }

    // edit flow
    else if (pathAction === enums.edit) {
      const finalData = {
        ...accountManagerData,
        ...data,
        permissions: accountManagerData?.rolePermission,
      };
      const dispatchData = addAccManagerDispatchData(
        finalData,
        userDetails,
        userAuditInfo
      );
      dispatch(updateAccontManager(dispatchData));
    }
  };

  // updated account manager resposne data
  const updateAccontManagerRes = useSelector(
    (data: { updateAccontManager: ResponseType }) => data?.updateAccontManager
  );

  useEffect(() => {
    // handling updated account manager resposne data
    if (updateAccontManagerRes?.status === "success") {
      successToast("Updated Successfully");
      navigate(routesName.accountManagerPage);
    } else if (updateAccontManagerRes?.status === "failed") {
      if (updateAccontManagerRes?.error?.status === 503) {
        successToast("Updated Successfully");
      } else {
        errorToast(
          updateAccontManagerRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [updateAccontManagerRes]);

  const cancelFn = () => {
    navigate(routesName.accountManagerPage);
  };

  //  breadcrumb data
  const breadcrumbData = [
    {
      label: "Account Managers",
      navigate: `${routesName.accountManagerPage}`,
      highLight: false,
    },

    {
      label:
        pathAction === enums.edit
          ? "Edit Account Manager"
          : "Add Account Manager",
      highLight: true,
    },
  ];

  // handling role permission
  const handleRolePermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setActionType(value);
    setValue("password", "");

    if (value === "deactivate_user") {
      setActivateDeactiveModal(true);
    } else if (value === "delete_user") {
      setDeleteModal(true);
    }
  };

  // account manager activate and deactivate function
  const {
    data: activateDeactiveSuccess,
    error: activateDeactiveError,
    loading: activateDeactiveLoading,
    postFn: activateDeactiveUserFn,
    toggle,
  } = useAxios({
    method: "post",
    path: `AccountManager/AccountManager/ActivateOrDeActivate?userid=${
      accountManagerData?.userId
    }&isactive=${accountManagerData?.isActive === 1 ? 0 : 1}`,
  });

  useEffect(() => {
    if (activateDeactiveSuccess?.success) {
      successToast(activateDeactiveSuccess?.message);
      navigate(routesName.accountManagerPage);
    } else if (activateDeactiveError) {
      errorToast("Something went wrong");
    }
  }, [activateDeactiveSuccess, activateDeactiveError, toggle]);

  // user delete axios call
  const {
    data: deleteUserSuccess,
    error: deleteUserError,
    loading: deleteUserLoading,
    deleteFn: deleteUserFn,
    toggle: deletetoggle,
  } = useAxios({
    method: "delete",
    path: `AccountManager/AccountManager/delete/id?userid=${accountManagerData?.userId}`,
  });

  useEffect(() => {
    if (deleteUserSuccess?.success) {
      successToast(deleteUserSuccess?.message);
      navigate(routesName.accountManagerPage);
    } else if (deleteUserError) {
      errorToast("Something went wrong");
    }
  }, [deleteUserSuccess, deleteUserError, deletetoggle]);

  const actionTypeData = [
    { label: "Reset Password", value: "reset_password" },
    {
      label:
        accountManagerData?.isActive === 1
          ? "Deactivate User"
          : "Activate User",
      value: "deactivate_user",
    },
    { label: "Delete User", value: "delete_user" },
  ];

  const autoGenerateDisable = [
    "email",
    "employeeID",
    "phone",
    "name",
    "gender",
  ].some((field: any) => watch(field) === "" || watch(field) === undefined);

  return (
    <>
      <ConfirmationModal
        isVisible={activateDeactiveModal}
        onClose={() => {
          setActivateDeactiveModal(false);
          setActionType("reset_password");
        }}
        onClick={() => {
          activateDeactiveUserFn();
        }}
        icon={ActivateDeactivateIcon}
        title={`Are you sure you want to ${
          accountManagerData?.isActive === 1 ? "Deactivate" : "Activate"
        }  this Account Manager?`}
        description=""
        actionText={
          accountManagerData?.isActive === 1 ? "Deactivate" : "Activate"
        }
        isLoading={activateDeactiveLoading}
      />

      <ConfirmationModal
        isVisible={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          setActionType("reset_password");
        }}
        onClick={() => {
          deleteUserFn();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Account Manager?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteUserLoading}
      />
      <InnerLayout
        title={
          pathAction === enums.edit
            ? "Edit Account Manager"
            : "Add Account Manager"
        }
      >
        {pathAction === enums.add && (
          <div className="px-6 py-5 bg-blue-150 z-10 sticky top-[80px]">
            <Breadcrumbs data={breadcrumbData} />
            <div className="py-5 lg:grid grid-cols-6 gap-4">
              <div className="col-start-2 col-span-4">
                <AccountManagerProgressBar active={1} />
              </div>
            </div>
          </div>
        )}

        {pathAction === enums.edit && (
          <div className="px-6 py-5 z-10 sticky top-[80px] bg-blue-50">
            <Breadcrumbs data={breadcrumbData} />
            <AccountManagerEditTab id={location.state.id} />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {getAccontManagerRes?.isLoading ||
            (isObjectEmpty(accountManagerData || {}) &&
              pathAction === enums.edit) ? (
              <div className="flex h-full justify-center items-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="px-6 pb-25">
                  <div className={pathAction === enums.edit ? `my-2` : `my-6`}>
                    <ImageUpload
                      name="photo"
                      setValue={setValue}
                      control={control}
                      watch={watch}
                      buttonText={`Upload Photo`}
                      image={accountManagerData?.photo}
                      imageSize="w-[100px] h-[100px]"
                    />
                  </div>

                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8 my-6">
                    <InputForm
                      label={"Name"}
                      id="name"
                      control={control}
                      defaultValue={accountManagerData?.name}
                    />

                    <SelectForm
                      label={"Gender"}
                      id={"gender"}
                      options={genderData}
                      control={control}
                      defaultValue={accountManagerData?.gender}
                    />
                    <InputForm
                      label={"Employee ID"}
                      id="employeeID"
                      control={control}
                      defaultValue={accountManagerData?.employeeID}
                    />
                    <PhoneNumInputForm
                      label={"Mobile Number"}
                      id="phone"
                      control={control}
                      defaultValue={accountManagerData?.phone}
                    />
                    <EmailInputForm
                      label={"Email ID"}
                      id="email"
                      control={control}
                      defaultValue={accountManagerData?.email}
                    />
                  </div>
                  {pathAction === enums.edit && (
                    <>
                      <div className="mt-5">
                        <span className="text-primary text-14 font-semibold">
                          Action
                        </span>
                      </div>
                      <div className="flex gap-4">
                        {actionTypeData?.map(
                          (
                            item: { label: string; value: string },
                            index: number
                          ) => {
                            return (
                              <div
                                className="flex items-center gap-3 my-3"
                                key={index}
                              >
                                <input
                                  type="radio"
                                  value={item?.value}
                                  id={item?.value}
                                  name="role_type"
                                  checked={actionType == item?.value}
                                  onChange={(e) => handleRolePermission(e)}
                                />
                                <label
                                  htmlFor={item?.value}
                                  className={`text-14 font-semibold ${
                                    actionType == item?.value
                                      ? "text-primary font-semibold"
                                      : "text-secondary font-medium"
                                  }`}
                                >
                                  {item?.label}
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <hr />
                    </>
                  )}

                  {actionType === "reset_password" && (
                    <>
                      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 my-3 mb-5">
                        <AutoGeneratePassword
                          name="password"
                          control={control}
                          setValue={setValue}
                          disabled={autoGenerateDisable}
                          required={pathAction === enums.add ? true : false}
                          title={pathAction === enums.add ? true : false}
                          clearErrors={clearErrors}
                          errors={errors}
                        />
                      </div>

                      <CheckBoxForm
                        label={"Set a new password after logging in"}
                        id="setNewPAssword"
                        type="checkbox"
                        control={control}
                        required={false}
                        defaultValue={pathAction === enums.edit ? false : true}
                      />
                    </>
                  )}
                </div>
                <div className="fixed bottom-0 fit_width z-20 shadow-bootomSticky">
                  <div className="bg-white p-3 px-7 flex gap-3 justify-end">
                    <SecondaryButton
                      name={addHospitalName.Cancel}
                      type="button"
                      onClick={() => {
                        cancelFn();
                      }}
                    />
                    <PrimaryButton
                      name={
                        pathAction === enums.edit
                          ? "Save"
                          : addHospitalName.Continue
                      }
                      loading={updateAccontManagerRes?.isLoading}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </form>
      </InnerLayout>
    </>
  );
};

export default AddEditPersonalInfo;
