import { TableBody, TableCell, TableRow } from "@mui/material";
import PersonImage from "../../assests/images/Logo1.png";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DescriptionSeeMoreModal from "../../components/feedback/descriptionSeeMoreModal";
import { istDate } from "../../helper";
import routesName from "../../constants/routesName";
const styles = {
  background: "white",
  minWidth: "160px",
  maxWidth: "160px",
  borderRight: "1px solid #F2F4F7",
  borderBottom: "1px solid #F2F4F7",
  paddingLeft: "16px",
  paddingRight: "16px",
  fontsize: "14px",
  color: "#667085",
  fontWeight: 500,
  fontFamily: "Inter, sans-serif",
};

const TblFeeback = ({ data }: any) => {
  const navigate = useNavigate();
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const seeMoreFn = (message: string) => {
    setFeedbackMessage(message);
    setShowSeeMore(true);
  };

  return (
    <>
      <DescriptionSeeMoreModal
        isVisible={showSeeMore}
        onClose={() => setShowSeeMore(false)}
        data={feedbackMessage}
      />
      <TableBody>
        {data?.map((item: any, index: any) => {
          return (
            <TableRow key={index}>
              <TableCell
                style={{
                  ...styles,
                  position: "sticky",
                  left: "0px",
                  minWidth: "250px",
                  maxWidth: "250px",
                }}
              >
                <div
                  className="grid grid-cols-5 items-center gap-3 cursor-pointer"
                  onClick={() =>
                    navigate(`${routesName.hospitalManagementPage}/${item?.hospitalID}`)
                  }
                >
                  <div className="col-span-1 ">
                    <img
                      src={item?.logoUrl}
                      className="rounded-full w-[36px] h-[36px]"
                      alt="hospital-logo"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="col-span-4 ps-2 flex flex-col">
                    <span className="text-blue-300 text-16 font-semibold">
                      {item?.hospitalName}
                    </span>
                    <span className="text-secondary text-14 font-medium">
                      ID: {item?.hospitalCode}
                    </span>
                  </div>
                </div>
              </TableCell>
              <TableCell
                style={{
                  ...styles,
                  position: "sticky",
                  left: "250px",
                  minWidth: "220px",
                  maxWidth: "220px",
                }}
              >
                {item?.productName}
              </TableCell>

              <TableCell
                style={{ ...styles, minWidth: "500px", maxWidth: "500px" }}
              >
                <div className="flex flex-col">
                  <span className="text-primary text-16 font-semibold">
                    {item?.errorCode}
                  </span>
                  <div className="text-secondary text-14 font-medium">
                    {item?.errorDescription &&
                    item?.errorDescription?.length > 50
                      ? item?.errorDescription.substring(0, 50) + "... "
                      : item?.errorDescription}

                    {item?.errorDescription?.length > 50 && (
                      <button
                        className="text-blue-300 font-semibold"
                        onClick={() => seeMoreFn(item?.errorDescription)}
                      >
                        see more
                      </button>
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell style={{ ...styles }}>{item?.user}</TableCell>
              <TableCell style={{ ...styles }}>{item?.device}</TableCell>
              <TableCell style={{ ...styles }}>{item?.model}</TableCell>
              <TableCell style={{ ...styles }}>{item?.ipAddress}</TableCell>
              <TableCell style={{ ...styles }}>
                <div className="flex flex-col">
                  <span className="text-primary text-16 font-semibold">
                    {moment(istDate(item.feedBackDate)).format("DD MMM YYYY")}
                  </span>
                  <span className="text-secondary text-14 font-medium">
                    {moment(istDate(item.feedBackDate)).format("hh:mm a")}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};
export default TblFeeback;
