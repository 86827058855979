import routesName from "../../constants/routesName";

export const breadcrumbData = [
  {
    label: "Account Managers",
    navigate: `${routesName.accountManagerPage}`,
    highLight: false,
  },

  {
    label: "Add Account Manager",
    highLight: true,
  },
];

export const editAccountManagerData = [
  {
    label: "Account Managers",
    navigate: `${routesName.accountManagerPage}`,
    highLight: false,
  },

  {
    label: "Edit Account Manager",
    highLight: true,
  },
];