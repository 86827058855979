import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../datasource/api";
import { AxiosError } from "axios";
import routesName from "../../constants/routesName";

export const getNotifications = createAsyncThunk(
  "getNotifications",
  async (
    data: {
      status: number;
      order: string;
      orderBy: string;
      searchQuery?: string;
      page: number;
      rowsPerPage: number;
      searchKeywords?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.get(
        `Notification/notificationspaginated?notifyType=${data?.status}&page=${
          data?.page + 1
        }&pageSize=${data?.rowsPerPage}&order=${data?.order}&orderby=${
          data?.orderBy
        }&searchKeywords=${data?.searchKeywords}${data?.searchQuery}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteNotifications = createAsyncThunk(
  "deleteNotifications",
  async (ids: any, { rejectWithValue }) => {
    try {
      const response = await API.delete(`Notification/notifications/delete`, {
        data: ids,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const shareNotifications = createAsyncThunk(
  "shareNotifications",
  async (ids: any, { rejectWithValue }) => {
    try {
      const response = await API.post(`Notification/notifications/email`, ids);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const sendNotifications = createAsyncThunk(
  "sendNotifications",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `Notification/notifications/sendemail`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 500) {
          window.location.href = routesName.errorPage;
        }
      }
      return rejectWithValue(error);
    }
  }
);
