import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type ChartData = {
  [key: string]: number;
};

type PercentageDisplay = {
  labels: string[];
  values: number[];
  colors: string[];
};

const MainDoughnutChart = ({
  chartData,
  colors,
  width = "w-[120px]",
  title,
  totalValue,
}: {
  chartData: ChartData;
  colors: string[];
  width?: string;
  title?: string;
  totalValue: number;
}) => {
  const labels = Object.keys(chartData);
  const values = Object.values(chartData);

  const PercentageDisplay = ({ labels, values, colors }: PercentageDisplay) => {
    const total = values.reduce((acc, curr) => acc + curr, 0);
    return (
      <div className="grid grid-cols-2 xl:gap-5 gap-2">
        {labels.map((label, index) => (
          <div key={index} className="whitespace-nowrap  items-center">
            <div className="flex items-center gap-1">
              <span className="text-24" style={{ color: colors[index] }}>
                &#8226;
              </span>
              <span className="text-14 font-medium text-secondary">
                {label}
              </span>
            </div>

            <div>
              <span className="text-16 font-semibold text-primary ps-4">
                {values[index]}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // const totalValue = values.reduce((acc: any, curr) => acc + curr, 0);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "value",
        data: values,
        backgroundColor: colors,
      },
    ],
  };

  // const options = {
  //   maintainAspectRatio: false,
  //   layout: {
  //     padding: {
  //       left: 0,
  //       right: 0,
  //       top: 0,
  //       bottom: 0,
  //     },
  //   },
  //   elements: {
  //     arc: {
  //       spacing: 0.5,
  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  // };
  const options = {
    maintainAspectRatio: false,
    animation: false,
    aspectRatio: 1,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      arc: {
        spacing: 0.5,
      },
    },

    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
            family: "Inter",
          },
        },
      },

      tooltip: {
        enabled: true,
        position: "nearest" as "nearest",
        backgroundColor: "#FFFFFF",
        titleColor: "#101828",
        titleFont: {
          size: 14,
          weight: 600 as const,
          family: "Inter",
        },
        bodyColor: "#101828",
        boxPadding: 4,
        bodyFont: {
          size: 12,
          weight: 500 as const,
          family: "Inter",
        },
        padding: 10,
        cornerRadius: 6,
        borderColor: "#EAECF0",
        borderWidth: 2,
        callbacks: {
          label: function (context: any) {
            const label = context?.label;
            const value = context.formattedValue;
            return `${label}: ${value}`;
          },
          title: function (context: any) {
            return "";
          },
        },
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart: any, args: any, pluginOptions: any) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = "bolder 16px Inter";
      ctx.fillStyle = "#101828";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        totalValue !== 0 ? totalValue : "",
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };

  return (
    <div className="flex justify-between items-center w-full">
      <div className={`${width}`}>
        <Doughnut data={data} options={options} plugins={[textCenter]} />
      </div>
      <div className="flex justify-center w-[100%]">
        <PercentageDisplay labels={labels} values={values} colors={colors} />
      </div>
    </div>
  );
};

export default MainDoughnutChart;
