import { SubmitHandler, useForm } from "react-hook-form";
import AutoGeneratePassword from "../../components/autoGeneratePassword";
import { CheckBoxForm } from "../../components/forms/checkBoxForm";
import DashboardCard from "../../components/ui/dashboardCard";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import DefaultImage from "../../assests/images/default-profile.jpg";
import { useAppDispatch } from "../../redux/store";
import { resetPasswordAccManager } from "../../redux/accountManager/accountManagerReducer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ResponseType } from "../../modals/IreduxResponse";
import successToast from "../../utils/successToast";
import { resetResetPasswordAccManagerState } from "../../redux/accountManager/accountManagerSlice";
import UserAuditDetails from "../../utils/userAuditDetails";
import DetectDevice from "../../utils/detectDevice";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import errorToast from "../../utils/errorToast";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onClick?: () => void;
  data: {
    name: string;
    employeeID: string;
    photo: string;
    email: string;
    userId: number | undefined;
    userName: string | undefined;
    role: string | undefined;
  };
};

const AccountMResetPassword = ({
  isVisible,
  onClose,
  onClick,
  data,
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();
  const deviceType = DetectDevice();
  const userDetails = AccessTokenDecoder();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<any>();

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: data?.userId,
    userName: data?.userName,
    role: data?.role,
    customer: data?.name,
    customerCode: data?.userName,
    customerID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: deviceType,
    auditDate: "2024-03-15T06:57:07.737Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };
  const onSubmit: SubmitHandler<any> = async (value) => {
    dispatch(
      resetPasswordAccManager({
        email: (data?.email).toLowerCase(),
        password: value.password,
        isTempPassword: value.setNewPAssword,
        userAuditInfo,
      })
    );
  };

  const resetPasswordAccManagerRes = useSelector(
    (data: { resetPasswordAccManager: ResponseType }) =>
      data?.resetPasswordAccManager
  );

  useEffect(() => {
    if (resetPasswordAccManagerRes?.status === "success") {
      successToast("Password Reset Successfully");
      reset();
      onClose();
    } else if (resetPasswordAccManagerRes?.status === "failed") {
      if (resetPasswordAccManagerRes?.error?.status === 503) {
        successToast("Password Reset Successfully");
        reset();
        onClose();
      } else {
        errorToast(
          resetPasswordAccManagerRes?.error?.data?.message ??
            "Something Went Wrong"
        );
      }
    }
  }, [resetPasswordAccManagerRes]);

  useEffect(() => {
    if (!isVisible) {
      dispatch(resetResetPasswordAccManagerState());
    }
    return () => {
      dispatch(resetResetPasswordAccManagerState());
    };
  }, [isVisible]);

  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="w-100">
        <div className=" py-5 px-5">
          <div className="text-center">
            <span className="text-primary text-24 font-semibold">
              Reset Password
            </span>
          </div>

          <div className="flex mt-4 bg-blue-150 p-3 items-center rounded">
            <div className="w-[40px] h-[40px] flex items-center">
              <img
                src={data?.photo ? data?.photo : DefaultImage}
                className="w-[100%] h-[100%] object-contain rounded-full"
              />
            </div>
            <div className="flex flex-col ps-6">
              <div>
                <span className="text-primary text-16 font-semibold">
                  {data.name}
                </span>
              </div>
              <div>
                <span className="text-secondary text-12 font-medium">
                  Emp ID: {data.employeeID}
                </span>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <AutoGeneratePassword
                name="password"
                control={control}
                setValue={setValue}
                title={false}
                clearErrors={clearErrors}
                errors={errors}
                disabled={resetPasswordAccManagerRes?.isLoading}
              />
            </div>
            <div className="mt-5">
              <CheckBoxForm
                label={"Set a new password after logging in"}
                id="setNewPAssword"
                type="checkbox"
                control={control}
                required={false}
                defaultValue={false}
                disabled={resetPasswordAccManagerRes?.isLoading}
              />
            </div>

            <div className="flex justify-around mt-5 gap-5">
              <SecondaryButton
                padding="py-3 px-14"
                type="button"
                name="Cancel"
                onClick={() => {
                  onClose();
                  reset();
                }}
              />
              <PrimaryButton
                padding={
                  resetPasswordAccManagerRes?.isLoading
                    ? "py-3 px-8"
                    : "py-3 px-16"
                }
                type="submit"
                name="Save"
                loading={resetPasswordAccManagerRes?.isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </DashboardCard>
  );
};

export default AccountMResetPassword;
