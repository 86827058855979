import { SubmitHandler, useForm } from "react-hook-form";
import Breadcrumbs from "../../components/breadCrumbs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddHospitalProgressBar from "../../features/hospitalManagement/addHospitalProgressBar";
import CustomAccordion from "../../components/ui/accordion";
import BasicInformation from "../../features/hospitalManagement/basicInformation";
import BedSize from "../../features/hospitalManagement/bedSize";
import OwnerOperatorDetails from "../../features/hospitalManagement/ownerOperatorDetails";
import Specialisation from "../../features/hospitalManagement/specialisation";
import OnBoardSource from "../../features/hospitalManagement/onBoardSource";
import AddHospitalManagemnt from "../../features/hospitalManagement/hospitalManagement";
import JeevAccountManager from "../../features/hospitalManagement/jeevAccountManager";
import {
  ErrorWithDeleteButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/buttons";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../redux/store";
import axios from "axios";
import {
  addEnumData,
  resetAddHospitalState,
  resetDeleteHospitalByIdState,
  resetGetHospitalByIdState,
} from "../../redux/addHospital/hospitalManagementSlice";
import { LoadingSpinner } from "../../components/ui/loadinSpinner";
import { enumDataApiList } from "../../jsonData/addHospitalsData";
import addHospitalName from "../../constants/hospitalManagement/addHospital";
import {
  addHospital,
  getHospitalById,
  getSpecialisationType,
} from "../../redux/addHospital/hospitalManagementReducer";
import errorToast from "../../utils/errorToast";
import { useSelector } from "react-redux";
import routesName from "../../constants/routesName";
import {
  AccountManagerObject,
  HosiptalInfoObject,
  HospitalManagementObject,
  OnBoardDispatchData,
  OnBoardSourceObject,
  OwnerOperatorObject,
  SpecialisationObject,
} from "../../features/hospitalManagement/dataformatter";
import successToast from "../../utils/successToast";
import SuccessMessageModal from "../../components/hospitalManagement/successMessageModal";
import constants from "../../constants";
import enums from "../../constants/enums";
import InnerLayout from "../../layout/innerLayout";
import {
  addHospitalNavData,
  editHospitalNavData,
} from "../../jsonData/breadcrumbData/hospitalManagement";
import { AccessTokenDecoder } from "../../utils/accessTokenDecoder";
import useAxios from "../../hooks/useAxios";
import { hospitalApi } from "../../jsonData/apiPathData/hospital";
import ConfirmationModal from "../../components/modals/confirmationModal";
import DeleteIcon from "../../assests/icons/deleteIcon.png";
import { ResponseType } from "../../modals/IreduxResponse";
import UserAuditDetails from "../../utils/userAuditDetails";
import { ErrorMessageIcon } from "../../components/muiIconsImport";

const AddHospital = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathAction = location?.state?.action;
  // locationid is hospitalId
  const locationId = location?.state?.id;

  const [loading, setLoading] = useState(true);
  const [ShowModal, setShowModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [submitType, setSubmitType] = useState<string>(enums.continue);
  const [previousData, setPreviousData] = useState<string | null>();
  const [firstFormError, setFirstFormError] = useState("");
  const scrollDown = useRef<null | HTMLDivElement>(null);

  const userDetails = AccessTokenDecoder();
  const { osVersion, operatingSystem, ipAddress } = UserAuditDetails();

  useEffect(() => {
    // getting hospitalInfo data from localStorage if there go in add flow with continue
    const previousData = localStorage.getItem(
      addHospitalName.hospitalInformation
    );
    if (previousData) {
      setPreviousData(previousData);
    }
    axios
      .all(
        enumDataApiList.map((endpoint) =>
          axios.get(endpoint, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem(
                constants.accessTokenText
              )}`,
            },
          })
        )
      )
      .then(
        axios.spread(
          (
            { data: OwnershipType },
            { data: AddOnServiceType },
            { data: BedStatus },
            { data: DeviceType },
            { data: HospitalsStatus },
            { data: Instalments },
            { data: PatientType },
            { data: SourceType },
            { data: PlanType },
            { data: ValidityPeriod },
            { data: BedSizeType }
          ) => {
            const data = {
              OwnershipType,
              AddOnServiceType,
              BedStatus,
              DeviceType,
              HospitalsStatus,
              Instalments,
              PatientType,
              SourceType,
              PlanType,
              ValidityPeriod,
              BedSizeType,
            };

            dispatch(addEnumData(data));
            setLoading(false);
          }
        )
      )
      .catch((err) => {
        setLoading(false);
      });
    // specialisationList action dispatch
    dispatch(getSpecialisationType());
    // for edit and inprogress initail data from api call
    if (pathAction === enums.edit || pathAction === enums.inprogress) {
      dispatch(getHospitalById(locationId));
    }

    // return () => {
    //   resetGetHospitalByIdState();
    // };
  }, []);

  const hospitalDetailsRes = useSelector((data: any) => data?.getHospitalById);
  const specialisationList = useSelector(
    (data: any) => data?.getSpecialisation
  );

  const hospitalInfo = hospitalDetailsRes?.data?.data;
  const hosiptalInfoObject = HosiptalInfoObject(hospitalInfo);
  const specialisationObject = SpecialisationObject(hospitalInfo);
  const ownerOperatorDetailsObject = OwnerOperatorObject(hospitalInfo);
  const onBoardSourceObject = OnBoardSourceObject(hospitalInfo);
  const hospitalManagementObject = HospitalManagementObject(hospitalInfo);
  const accountManagerObject = AccountManagerObject(hospitalInfo);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors: formErrors },
  } = useForm<any>();

  const userAuditInfo = {
    os: osVersion,
    ipAddress: ipAddress,
    imeiNumber: "",
    osVersion: operatingSystem,
    macAddress: "",
    model: "",
    userAction: "",
    userID: userDetails?.userId,
    userName: userDetails?.userName,
    role: userDetails?.Roles,
    customer: watch("hospitalName"),
    customerCode: "",
    customerID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    device: "string",
    auditDate: "2024-04-11T10:44:38.662Z",
    loggedInUserName: userDetails?.userName,
    appSourceType: "",
    moduleType: "",
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    // validation for specialisation
    if (!data?.specialisation) {
      errorToast("Specialization need to be selected");
    } else {
      const stringiftData = JSON.stringify(data);
      // storing hospital info in localstorage while only in adding flow
      if (pathAction === enums.add && submitType !== enums.saveAsDraft) {
        localStorage.setItem(
          addHospitalName.hospitalInformation,
          stringiftData
        );
      }
      // adding only hospital info in save as draft
      if (pathAction === enums.add && submitType === enums.saveAsDraft) {
        const dispatchData = OnBoardDispatchData(
          data,
          specialisationList,
          userDetails,
          userAuditInfo
        );
        // removed hospiatl admin info while adding in hospital info
        const dataWithoutHospitalAdminInfo = {
          ...dispatchData,
          hospitalAdminInfo: {},
        };
        dispatch(
          addHospital({
            isSaveAsDraft: true,
            dispatchData: dataWithoutHospitalAdminInfo,
          })
        );
      }
      //  from inprogress hospital (save as drafted hospital) to continue
      else if (
        pathAction === enums.inprogress &&
        submitType === enums.continue
      ) {
        navigate(
          `${routesName.hospitalManagementPage}/hospital/${pathAction}${routesName.productActivationPage}`,
          {
            state: { id: locationId, action: pathAction, data: data },
          }
        );
      }
      // for normal edit and add flow of hosipatl for this in add flow local storage also will be added
      else {
        navigate(
          `${routesName.hospitalManagementPage}/hospital/${pathAction}${routesName.productActivationPage}`,
          {
            state:
              pathAction === enums.edit
                ? { id: locationId, action: pathAction, data: data }
                : pathAction === enums.add
                ? { action: pathAction }
                : null,
          }
        );
      }
    }
  };

  const addHospitalRes = useSelector(
    (data: { addHospital: ResponseType }) => data?.addHospital
  );

  useEffect(() => {
    // add Hospital Response handle
    if (addHospitalRes?.status === "success") {
      successToast("Hospital Added Successfully");
      // navigate(routesName.hospitalManagementPage);
      setShowModal(true);
      localStorage.removeItem(addHospitalName.hospitalInformation);
    } else if (addHospitalRes?.status === "failed") {
      if (addHospitalRes?.error?.status === 503) {
        successToast("Hospital Added Successfully");
        setShowModal(true);
      } else {
        errorToast(
          addHospitalRes?.error?.data?.message ?? "Something Went Wrong"
        );
      }
    }
  }, [addHospitalRes]);

  useEffect(() => {
    return () => {
      dispatch(resetAddHospitalState());
      dispatch(resetDeleteHospitalByIdState());
    };
  }, []);

  const cancelHospitalFn = () => {
    localStorage.removeItem(addHospitalName.hospitalInformation);
    localStorage.removeItem(addHospitalName.productActivation);
    localStorage.removeItem(addHospitalName.hospitalOnboarding);
    navigate(routesName.hospitalManagementPage);
  };

  // hospital delete axios call
  const {
    data: deleteHospitalSuccess,
    error: deleteHospitalError,
    loading: deleteLoading,
    deleteFn: deleteHospital,
    toggle,
  } = useAxios({
    method: "delete",
    path: `${hospitalApi.deleteHos}${locationId}`,
  });

  useEffect(() => {
    if (deleteHospitalSuccess?.success) {
      successToast("Hospital Deleted successfully");
      navigate(routesName.hospitalManagementPage);
    } else if (deleteHospitalError) {
      errorToast("Something Went Wrong");
    }
  }, [deleteHospitalSuccess, deleteHospitalError, toggle]);

  const firstErrorField = Object.keys(formErrors).find(
    (fieldName) => formErrors[fieldName]
  );

  useEffect(() => {
    if (firstErrorField) {
      const errorMessage = formErrors?.[firstErrorField]?.message;
      setFirstFormError(errorMessage as string);
    }
  }, [firstErrorField]);

  const editHospitalNavData = [
    {
      label: "Hospital Management",
      navigate: `${routesName.hospitalManagementPage}`,
      highLight: false,
    },

    {
      label: `Edit ${hospitalInfo?.hospitalInfo?.name}`,
      highLight: true,
    },
  ];

  const errorShowOnBottom = () => {
    if (firstFormError) {
      scrollDown?.current?.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  };

  return (
    <>
      <SuccessMessageModal
        isVisible={ShowModal}
        onClose={() => {
          setShowModal(false);
          navigate(routesName.hospitalManagementPage);
          dispatch(resetAddHospitalState());
        }}
        title={"The hospital has been successfully activated!"}
        inprogress={true}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
        }}
        onClick={() => {
          deleteHospital();
        }}
        icon={DeleteIcon}
        title={`Are you sure you want to Delete this Hospital?`}
        description="Please note that you will not be able to recover these later."
        actionText={"Delete"}
        isLoading={deleteLoading}
      />
      {loading || hospitalDetailsRes?.isLoading ? (
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <InnerLayout
            title={
              pathAction === enums.add
                ? `Add Hospital`
                : hospitalInfo?.hospitalInfo?.name
            }
          >
            <div className="h-full relative">
              <div className="p-6 bg-blue-150 z-10 sticky top-[80px]">
                <Breadcrumbs
                  data={
                    pathAction === enums.add
                      ? addHospitalNavData
                      : editHospitalNavData
                  }
                />
                <div className="py-5">
                  <AddHospitalProgressBar active={1} />
                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-6 pb-20">
                  <CustomAccordion
                    title={addHospitalName.BasicInformation}
                    component={
                      <BasicInformation
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        previousData={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? hosiptalInfoObject
                            : JSON.parse(previousData ?? "{}")
                        }
                      />
                    }
                  />

                  <CustomAccordion
                    title={addHospitalName.Specialisation}
                    component={
                      <Specialisation
                        control={control}
                        setValue={setValue}
                        previousData={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? specialisationObject
                            : JSON.parse(previousData ?? "{}")
                        }
                      />
                    }
                  />

                  <CustomAccordion
                    title={addHospitalName.BedSize}
                    component={
                      <BedSize
                        control={control}
                        setValue={setValue}
                        previousData={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? hospitalDetailsRes?.data?.data?.hospitalInfo
                            : JSON.parse(previousData ?? "{}")
                        }
                      />
                    }
                  />

                  <CustomAccordion
                    title={addHospitalName.OwnerOperatorDetails}
                    component={
                      <OwnerOperatorDetails
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        previousData={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? ownerOperatorDetailsObject
                            : JSON.parse(previousData ?? "{}")
                        }
                      />
                    }
                  />

                  <CustomAccordion
                    title={addHospitalName.OnboardingSource}
                    component={
                      <OnBoardSource
                        control={control}
                        setValue={setValue}
                        previousData={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? onBoardSourceObject
                            : JSON.parse(previousData ?? "{}")
                        }
                      />
                    }
                  />

                  <CustomAccordion
                    title={addHospitalName.HospitalManagement}
                    component={
                      <AddHospitalManagemnt
                        control={control}
                        setValue={setValue}
                        previousData={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? hospitalManagementObject
                            : JSON.parse(previousData ?? "{}")
                        }
                      />
                    }
                  />

                  <CustomAccordion
                    padding=""
                    title={addHospitalName.JeevAccountManager}
                    component={
                      <JeevAccountManager
                        control={control}
                        setValue={setValue}
                        previousData={
                          pathAction === enums.edit ||
                          pathAction === enums.inprogress
                            ? accountManagerObject
                            : JSON.parse(previousData ?? "{}")
                        }
                        clearErrors={clearErrors}
                      />
                    }
                  />
                  <div className="h-[2.5rem] flex justify-center">
                    {firstErrorField && (
                      <span className="text-error text-12 flex items-center">
                        <ErrorMessageIcon styles={{ width: "15px" }} /> :{" "}
                        {/* {firstFormError} */}
                        Mandatory fields are required to be filled
                      </span>
                    )}
                  </div>
                </div>

                <div className="fixed bottom-0 fit_width z-20 shadow-bootomSticky">
                  <div className="bg-white p-3 px-7 flex justify-between">
                    {pathAction === enums.edit ||
                    pathAction === enums.inprogress ? (
                      <>
                        <ErrorWithDeleteButton
                          loading={deleteLoading}
                          name={addHospitalName.DeleteHospital}
                          padding="lg:px-12 py-3 px-5"
                          type="button"
                          onClick={() => setIsDeleteModal(true)}
                        />
                      </>
                    ) : (
                      <SecondaryButton
                        padding="lg:px-18 py-3 px-5"
                        type="button"
                        name={addHospitalName.Cancel}
                        onClick={() => cancelHospitalFn()}
                      />
                    )}

                    <div className="flex gap-3">
                      {pathAction === enums.edit ||
                      pathAction === enums.inprogress ? (
                        <>
                          <SecondaryButton
                            padding="lg:px-18 py-3 px-5"
                            type="button"
                            name={addHospitalName.Cancel}
                            // onClick={() => cancelHospitalFn()}
                            onClick={() =>
                              navigate(routesName.hospitalManagementPage)
                            }
                          />
                        </>
                      ) : (
                        <SecondaryButton
                          padding="lg:px-18 py-3 px-5"
                          loading={addHospitalRes?.isLoading}
                          name={addHospitalName.SaveAsDraft}
                          onClick={() => {
                            setSubmitType(enums.saveAsDraft);
                            errorShowOnBottom();
                          }}
                        />
                      )}
                      <PrimaryButton
                        padding="lg:px-18 py-3 px-5"
                        name={addHospitalName.Continue}
                        onClick={() => {
                          setSubmitType(enums.continue);
                          errorShowOnBottom();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div ref={scrollDown}></div>
            </div>
          </InnerLayout>
        </>
      )}
    </>
  );
};

export default AddHospital;
