import React from "react";
import { Controller } from "react-hook-form";

interface InputProps {
  label: string;
  id: any;
  type?: string;
  control: any;
  errorMessage?: string | undefined;
  errors?: boolean;
  placeholder?: string;
  defaultValue?: any;
  disabled?: boolean;
  required?: boolean;
  onChange?: any;
  checked?: boolean;
  labelSize?: string;
}

export const CheckBoxForm: React.FC<InputProps> = ({
  label,
  id,
  type = "checkbox",
  control,
  errorMessage,
  errors = false,
  defaultValue = false,
  disabled = false,
  required = true,
  onChange = () => {},
  labelSize = "text-14",
}) => {
  return (
    <>
      <div className="">
        <Controller
          name={id}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required: required ? `Please select the checkbox` : false,
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <div className="flex items-center gap-3">
                <input
                  type={type}
                  id={id}
                  {...field}
                  checked={ field.value ??defaultValue}
                  disabled={disabled}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    onChange(e);
                  }}
                  className="checkbox-input"
                />
                <label
                  htmlFor={id}
                  className={`${labelSize} font-medium ${
                    field.value ? "text-primary" : "text-secondary"
                  } cursor-pointer`}
                >
                  {label}
                </label>
              </div>

              <div>
                <span className="text-error text-14 font-normal">
                  {error?.message}
                </span>
              </div>
              <div className="">
                <span className="text-error text-14 font-normal">
                  {errorMessage}
                </span>
              </div>
            </>
          )}
        />
      </div>
    </>
  );
};

export const NotificationCheckBoxForm: React.FC<InputProps> = ({
  label,
  id,
  type = "checkbox",
  control,
  errorMessage,
  errors = false,
  defaultValue = false,
  disabled = false,
  required = true,
  checked = false,
  onChange = () => {},
  labelSize = "text-14",
}) => {
  return (
    <>
      <div className="">
        <Controller
          name={id}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required: required ? `Please select the checkbox` : false,
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <div className="flex items-center gap-3">
                <input
                  type={type}
                  id={id}
                  checked={checked || false}
                  {...field}
                  disabled={disabled}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    onChange(e);
                  }}
                  className="checkbox-input"
                />
                <label
                  htmlFor={id}
                  className={`${labelSize} font-semibold text-primary cursor-pointer`}
                >
                  {label}
                </label>
              </div>

              <div>
                <span className="text-error text-14 font-normal">
                  {error?.message}
                </span>
              </div>
              <div className="">
                <span className="text-error text-14 font-normal">
                  {errorMessage}
                </span>
              </div>
            </>
          )}
        />
      </div>
    </>
  );
};
