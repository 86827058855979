import SearchInput from "../components/forms/searchInput";
import InnerLayout from "../layout/innerLayout";
import { useEffect, useState } from "react";
import DynamicTable from "../components/tables/dynamicTable";
import sortValues from "../constants/sortColumnValue";
import { useAppDispatch } from "../redux/store";
import { SearchQuery } from "../utils/searchQuery";
import { TableFilterData } from "../modals/Itables";
import { useSelector } from "react-redux";
import errorToast from "../utils/errorToast";
import { ResponseType } from "../modals/IreduxResponse";
import TblLogin from "../features/loginDetails/tableBody";
import { loginColumnData } from "../features/loginDetails/headerData";
import { getAllAudithLogin } from "../redux/auditLogin/auditLoginReducer";
const FeedBack = () => {
  const [allSearch, setAllSearch] = useState("");
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(sortValues.page);
  const [rowsPerPage, setRowsPerPage] = useState(sortValues.rowsPerPage);
  const [order, setorder] = useState("createddate");
  const [filter, setFilter] = useState<TableFilterData>({});
  const [orderBy, setOrderBy] = useState("desc");
  const searchQuery = SearchQuery({ filter });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [audithLoginData, setAudithLoginData] = useState<any>([]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, allSearch, orderBy, order]);

  useEffect(() => {
    dispatch(
      getAllAudithLogin({
        order: order,
        orderBy: orderBy,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: searchQuery,
        searchKeywords: allSearch,
      })
    );
  }, [order, orderBy, page, rowsPerPage, allSearch, searchQuery]);

  const getAllAudithLoginRes = useSelector(
    (data: { getAllAudithLogin: ResponseType }) => data.getAllAudithLogin
  );
  useEffect(() => {
    if (getAllAudithLoginRes?.status === "success") {
      setAudithLoginData(getAllAudithLoginRes?.data?.data);
    } else if (getAllAudithLoginRes?.status === "failed") {
      setErrorMessage("Something Went Wrong");
      errorToast("Something Went Wrong");
    }
  }, [getAllAudithLoginRes]);

  return (
    <>
      <InnerLayout title="Logins">
        <div className="bg-white shadow-md1 rounded-lg pt-6 pb-14 mx-6 ">
          <div className="grid grid-cols-12">
            <div className="xl:col-span-3 lg:col-span-4 md:col-span-5 sm:col-span-6 col-span-12 px-6 pb-6 ">
              <SearchInput setAllSearch={setAllSearch} />
            </div>
          </div>

          <div className="h-[70vh]">
            <DynamicTable
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              order={order}
              setorder={setorder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              filter={filter}
              setFilter={setFilter}
              columnData={loginColumnData}
              children={<TblLogin data={audithLoginData} />}
              isFirstSticky={true}
              stickyWidth={[{ width: "0px" }]}
              status={getAllAudithLoginRes?.isLoading}
              errorMessage={errorMessage}
              dataLength={getAllAudithLoginRes?.data?.count ?? 10}
              noData={getAllAudithLoginRes?.data?.data?.length < 1}
            />
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default FeedBack;
