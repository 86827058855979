import { createSlice } from "@reduxjs/toolkit";
import {
  activateDeactiveAccManager,
  addAccountManager,
  deleteAccManager,
  getAccontManager,
  getAllAccontManager,
  getHospitalsIdList,
  getHospitalsLinkedAccManager,
  getPermissions,
  getStatusWiseHospitalsLinkedAccManager,
  getUserAuditLinkedAccManager,
  linkHospitalToAccManager,
  resetPasswordAccManager,
  updateAccontManager,
} from "./accountManagerReducer";

// add account manager
export const addAccountManagerSlice = createSlice({
  name: "addAccountManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetAddAccountManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAccountManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(addAccountManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(addAccountManager.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const addAccountManagerreducer = addAccountManagerSlice.reducer;
export const { resetAddAccountManagerState } = addAccountManagerSlice.actions;

// get permissions of user
export const getPermissionsSlice = createSlice({
  name: "getPermissions",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetPermissionsState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getPermissions.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getPermissions.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getPermissionsreducer = getPermissionsSlice.reducer;
export const { resetGetPermissionsState } = getPermissionsSlice.actions;

// get all account manager
export const getAllAccontManagerSlice = createSlice({
  name: "getAllAccontManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetAllAccontManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccontManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAllAccontManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getAllAccontManager.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getAllAccontManagerreducer = getAllAccontManagerSlice.reducer;
export const { resetGetAllAccontManagerState } =
  getAllAccontManagerSlice.actions;

// get account manager
export const getAccontManagerSlice = createSlice({
  name: "getAccontManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetAccontManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccontManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getAccontManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getAccontManager.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getAccontManagerreducer = getAccontManagerSlice.reducer;
export const { resetGetAccontManagerState } = getAccontManagerSlice.actions;

// update account manager
export const updateAccontManagerSlice = createSlice({
  name: "updateAccontManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetUpdateAccontManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAccontManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(updateAccontManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(updateAccontManager.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const updateAccontManagerreducer = updateAccontManagerSlice.reducer;
export const { resetUpdateAccontManagerState } =
  updateAccontManagerSlice.actions;

// get hospital data by account manager linked
export const getHospitalsLinkedAccManagerSlice = createSlice({
  name: "getHospitalsLinkedAccManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetHospitalsLinkedAccManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHospitalsLinkedAccManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getHospitalsLinkedAccManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(
        getHospitalsLinkedAccManager.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});
export const getHospitalsLinkedAccManagerreducer =
  getHospitalsLinkedAccManagerSlice.reducer;
export const { resetGetHospitalsLinkedAccManagerState } =
  getHospitalsLinkedAccManagerSlice.actions;

//get user audit data by account amnager linked
export const getUserAuditLinkedAccManagerSlice = createSlice({
  name: "getUserAuditLinkedAccManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetgetUserAuditLinkedAccManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAuditLinkedAccManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getUserAuditLinkedAccManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(
        getUserAuditLinkedAccManager.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});
export const getUserAuditLinkedAccManagerreducer =
  getUserAuditLinkedAccManagerSlice.reducer;
export const { resetgetUserAuditLinkedAccManagerState } =
  getUserAuditLinkedAccManagerSlice.actions;

// get status wise hospital data by account manager linked
export const getStatusWiseHospitalsLinkedAccManagerSlice = createSlice({
  name: "getStatusWiseHospitalsLinkedAccManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetStatusWiseHospitalsLinkedAccManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatusWiseHospitalsLinkedAccManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(
        getStatusWiseHospitalsLinkedAccManager.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(
        getStatusWiseHospitalsLinkedAccManager.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});
export const getStatusWiseHospitalsLinkedAccManagerreducer =
  getStatusWiseHospitalsLinkedAccManagerSlice.reducer;
export const { resetGetStatusWiseHospitalsLinkedAccManagerState } =
  getStatusWiseHospitalsLinkedAccManagerSlice.actions;

// get hospital list

export const getHospitalsIdListSlice = createSlice({
  name: "getHospitalsIdList",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetGetHospitalsIdListState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHospitalsIdList.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(getHospitalsIdList.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(getHospitalsIdList.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const getHospitalsIdListreducer = getHospitalsIdListSlice.reducer;
export const { resetGetHospitalsIdListState } = getHospitalsIdListSlice.actions;

// activate deactivate account manager user
export const activateDeactiveAccManagerSlice = createSlice({
  name: "activateDeactiveAccManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetActivateDeactiveAccManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activateDeactiveAccManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(activateDeactiveAccManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(
        activateDeactiveAccManager.rejected,
        (state, { payload }: any) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = payload?.response;
        }
      );
  },
});
export const activateDeactiveAccManagerreducer =
  activateDeactiveAccManagerSlice.reducer;
export const { resetActivateDeactiveAccManagerState } =
  activateDeactiveAccManagerSlice.actions;

// activate deactivate account manager user
export const deleteAccManagerSlice = createSlice({
  name: "deleteAccManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetDeleteAccManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteAccManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(deleteAccManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(deleteAccManager.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const deleteAccManagerreducer = deleteAccManagerSlice.reducer;
export const { resetDeleteAccManagerState } = deleteAccManagerSlice.actions;

//  delete account manager user
export const linkHospitalToAccManagerSlice = createSlice({
  name: "linkHospitalToAccManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetLinkHospitalToAccManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(linkHospitalToAccManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(linkHospitalToAccManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(linkHospitalToAccManager.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const linkHospitalToAccManagerreducer =
  linkHospitalToAccManagerSlice.reducer;
export const { resetLinkHospitalToAccManagerState } =
  linkHospitalToAccManagerSlice.actions;

//  delete account manager user
export const resetPasswordAccManagerSlice = createSlice({
  name: "resetPasswordAccManager",
  initialState: {
    status: "",
    isLoading: false,
    data: [],
    error: {},
  },
  reducers: {
    resetResetPasswordAccManagerState: (state) => {
      return {
        ...state,
        status: "",
        isLoading: false,
        data: [],
        error: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordAccManager.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(resetPasswordAccManager.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(resetPasswordAccManager.rejected, (state, { payload }: any) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = payload?.response;
      });
  },
});
export const resetPasswordAccManagerreducer =
  resetPasswordAccManagerSlice.reducer;
export const { resetResetPasswordAccManagerState } =
  resetPasswordAccManagerSlice.actions;
