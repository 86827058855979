export const userAuditHeaderData = [
  {
    label: "Date & Time",
    value: "auditdate",
    search: false,
    width: "150px",
  },
  {
    label: "Customer",
    value: "customer",
    width: "250px",
  },
  {
    label: "Action",
    value: "useraction",
    width: "250px",
  },
  {
    label: "Device",
    value: "device",
    width: "150px",
  },
  {
    label: "IP Address",
    value: "ipaddress",
    width: "150px",
  },
];
