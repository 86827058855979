import { useEffect, useState } from "react";
import {
  Grid,
  LinearProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { LoadingSpinner } from "../../../components/ui/loadinSpinner";
import { getPatientDetailsDiagnosisGraphData } from "../../../redux/mis/misReducer";
import SelectComponent from "../../../components/forms/selectComponent";

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#eeeeee00",
        },
        bar: {
          background: "linear-gradient(to right, #D2EEE6 30%, #69C7AE 70%)",
        },
      },
    },
  },
});

const totalDiagonisisList = [
  { label: "All Diagnosis", value: "all" },
  { label: "Top 5 Diagnosis", value: 5 },
  { label: "Top 10 Diagnosis", value: 10 },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 22,
  borderRadius: 0,
}));

function TotalDiagonisis({
  country,
  state,
  district,
  taluk,
  hobli,
  filterApplyToggle,
  hospitalArray,
}: any) {
  const dispatch = useAppDispatch();
  const diagnosisData = useSelector(
    (data: any) => data.getPatientDetailsDiagnosisGraphData
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [diagnosisGraphData, setDiagnosisGraphData] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  let highestPercentage = 0;
  diagnosisGraphData?.patientDetailsSummary?.patientsCountList?.forEach(
    (region: any) => {
      if (region.percentage > highestPercentage) {
        highestPercentage = region.percentage;
      }
    }
  );
  const casesNoShow =
    diagnosisGraphData?.patientDetailsSummary?.patientsCountList.length;

  useEffect(() => {
    if (diagnosisData?.status === "success") {
      setErrorMessage("");
      setDiagnosisGraphData(diagnosisData?.data?.data ?? []);
    } else if (diagnosisData?.status === "failed") {
      setErrorMessage("Something went Wrong");
    }
  }, [diagnosisData]);

  useEffect(() => {
    dispatch(
      getPatientDetailsDiagnosisGraphData({
        country: country,
        state: state,
        district: district,
        taluk: taluk,
        hobli: hobli,
        serviceType: "",
        fromDate: "",
        toDate: "",
        hospitals: hospitalArray ?? [],
      })
    );
  }, [
    // country, state, district, taluk, hobli,
    filterApplyToggle,
  ]);
  return (
    <div className="p-6 bg-white shadow-sm1 rounded-lg">
      {diagnosisData.isLoading ? (
        <div className="flex justify-center py-5">
          <LoadingSpinner />
        </div>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <span className="text-18 font-medium text-primary">
              Total Diagnosis
            </span>

            <div style={{ display: "flex", marginTop: "8px" }}>
              <span className="text-14 font-[400] text-secondary">
                Diagnosis :
              </span>
              <span className="text-14 font-[700] text-primary pl-1">
                {diagnosisGraphData?.patientDetailsSummary?.totalNos?.toLocaleString(
                  "en-IN"
                )}
              </span>

              <div className="border-r border-secondary ml-5" />

              <span className="text-14 font-[400] text-secondary ml-5">
                Patients Served :
              </span>
              <span className="text-14 font-[700] text-primary pl-1">
                {diagnosisGraphData?.totalPatientsCount?.toLocaleString(
                  "en-IN"
                )}
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <SelectComponent
              label="Select"
              id="status"
              value={selectedValue}
              onChange={handleChange}
              options={totalDiagonisisList}
            />
          </Grid>
          {diagnosisGraphData?.patientDetailsSummary?.patientsCountList.length >
          0 ? (
            <Grid item xs={12} sm={12} md={12}>
              {diagnosisGraphData?.patientDetailsSummary?.patientsCountList
                ?.slice(
                  0,
                  selectedValue === "all"
                    ? casesNoShow
                    : parseInt(selectedValue)
                )
                .map((item: any, index: any) => {
                  return (
                    <>
                      <div key={index}>
                        <ThemeProvider theme={theme}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={1.8}>
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.name}
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9.5}>
                              <span
                                style={{
                                  zIndex: 1,
                                  position: "relative",
                                  color: "#212121",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.patientsCount?.toLocaleString("en-IN")}
                              </span>
                              <BorderLinearProgress
                                style={{
                                  right: "5px",
                                  position: "relative",
                                  bottom: 22,
                                  width: "100%",
                                }}
                                variant="determinate"
                                value={
                                  (item.percentage / highestPercentage) * 100
                                }
                              />
                            </Grid>
                            <Grid
                              style={{ display: "flex", justifyContent: "end" }}
                              item
                              xs={12}
                              sm={12}
                              md={0.7}
                            >
                              <span
                                style={{
                                  color: "#212121",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.percentage}%
                              </span>
                            </Grid>
                          </Grid>
                        </ThemeProvider>
                      </div>
                    </>
                  );
                })}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <div className="flex justify-center">
                <span className="text-18 font-medium text-primary">
                  No Data Found
                </span>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

export default TotalDiagonisis;
