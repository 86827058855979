import { useSelector } from "react-redux";
import SelectForm from "../../components/forms/selectForm";
import { labelObject, removeBedSize } from "../../helper";

const BedSize = ({ control, setValue, previousData }: any) => {
  const enumData = useSelector((data: any) => data?.enumData?.data);
  const bedsizeList = labelObject(enumData?.BedSizeType?.data);

  const modifiedBedsizeList = bedsizeList?.map((item: any) => ({
    label: `${removeBedSize(item?.value)}`,
    value: item?.value,
  }));

  return (
    <div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
        <div>
          <SelectForm
            defaultValue={previousData?.bedSize}
            label={"Bed Size*"}
            id={"bedSize"}
            options={modifiedBedsizeList}
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default BedSize;
