import { useEffect, useState } from "react";
import AccountManagerList from "../../features/hospitalManagement/apicalls/accountManagerList";
import { Closeicon, SearchIcons } from "../muiIconsImport";
import DashboardCard from "../ui/dashboardCard";
import useAxios from "../../hooks/useAxios";
import { getHospitalById } from "../../redux/addHospital/hospitalManagementReducer";
import { useAppDispatch } from "../../redux/store";
import { useParams } from "react-router-dom";
import successToast from "../../utils/successToast";
import errorToast from "../../utils/errorToast";
import { hospitalApi } from "../../jsonData/apiPathData/hospital";
import { PrimaryButton, SecondaryButton } from "../buttons/buttons";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
};
const LinkAccountManagerModal = ({ isVisible, onClose }: ModalProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { data, error, loading } = AccountManagerList();
  const [reload, setReload] = useState(false);
  const [filterData, setFilterData] = useState<any>(data);
  const [selectedValue, setSelectedValue] = useState<any>();

  useEffect(() => {
    setFilterData(data);
  }, [data, reload]);

  const handleFilterSearch = (value: string) => {
    const filterData = data?.filter((item: any) =>
      item?.name?.toLowerCase()?.includes(value)
    );
    setFilterData(filterData);
  };

  const handleCheckboxChange = (id: number) => {
    if (selectedValue === id) {
      setSelectedValue(null);
    } else {
      setSelectedValue(id);
    }
  };

  //   delink account manager axios call
  const {
    data: linkAccmanagerSuccess,
    error: linkAccmanagerError,
    loading: linkAccmanagerLoading,
    postFn: linkAccManagerFn,
    toggle: linkAccmanagerToggle,
  } = useAxios({
    method: "post",
    path: `${hospitalApi.delinkAccountManger}userid=${selectedValue}&isToBeLinked=1`,
    body: [params?.id],
  });

  useEffect(() => {
    if (linkAccmanagerSuccess?.success) {
      onClose();
      if (params?.id) {
        dispatch(getHospitalById(params?.id));
      }
      successToast("Acount Manager linked Successfully");
    } else if (linkAccmanagerError) {
      errorToast("Something Went Wrong");
    }
  }, [linkAccmanagerSuccess, linkAccmanagerError, linkAccmanagerToggle]);

  if (!isVisible) return null;
  return (
    <DashboardCard>
      <div className="p-[12px] lg:w-[600px] w-[400px] flex flex-col relative">
        <div className="absolute top-1 right-1">
          <button onClick={onClose}>
            <Closeicon />
          </button>
        </div>
        <div className="items-start">
          <span className="text-24 text-primary font-semibold mt-6 whitespace-nowrap">
            Link Account Manager
          </span>
        </div>
        <hr className="my-2" />
        <div className={`mt-2 z-20 `}>
          <div className="pb-2">
            <span className="text-secondary text-16 font-normal">
              Select Account Manager
            </span>
          </div>

          <div className="w-[60%] border border-gray-450 py-2 px-3 rounded mb-3 flex">
            <input
              className="rounded-none outline-none w-[100%] text-16 font-400 text-primary"
              onChange={(e) => handleFilterSearch(e.target.value)}
              placeholder="Search"
            />
            <span className="text-blue-300">
              <SearchIcons />
            </span>
          </div>

          <div className="h-[272px] overflow-y-auto scroll_bar_with_side">
            {filterData?.map((item: any) => (
              <div className="p-3 flex gap-3 items-center" key={item.userId}>
                <input
                  type="checkbox"
                  checked={item?.userId === selectedValue}
                  id={item?.userId}
                  name={item?.userId}
                  onChange={() => handleCheckboxChange(item?.userId)}
                  className="checkbox-input"
                />
                <label
                  htmlFor={item?.userId}
                  className={`font-semibold text-14 ${
                    item?.userId === selectedValue
                      ? "text-primary"
                      : "text-secondary"
                  } cursor-pointer`}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-end gap-7 mt-5">
          <SecondaryButton
            type="button"
            name="Cancel"
            onClick={() => {
              onClose();
              setSelectedValue(null);
              setReload((prev) => !prev);
            }}
          />
          <PrimaryButton
            type="button"
            padding={`py-3 ${linkAccmanagerLoading ? "px-16" : "px-8"}`}
            name={linkAccmanagerLoading ? "Linking..." : "Link Account Manager"}
            onClick={() => {
              if (selectedValue) {
                linkAccManagerFn();
              } else {
                errorToast("Please select Account Manager");
              }
            }}
          />
        </div>
      </div>
    </DashboardCard>
  );
};

export default LinkAccountManagerModal;
