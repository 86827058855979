export const findRepeatedValues = (arr: any) => {
  const counts: any = {};
  const repeatedValues: any = new Set();

  arr.forEach((item: any) => {
    if (counts[item]) {
      repeatedValues.add(item);
    } else {
      counts[item] = 1;
    }
  });

  return Array.from(repeatedValues);
};
