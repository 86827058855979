import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../datasource/api";
import { AxiosError } from "axios";
import routesName from "../../constants/routesName";

export const getAllAudithLogin = createAsyncThunk(
    "get/all-AudithLogin",
    async (
      data: {
        order: string;
        orderBy: string;
        searchQuery?: string;
        page: number;
        rowsPerPage: number;
        searchKeywords?: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const response = await api.get(
          `LoginStatus/logins/Status?order=${data.order}&orderBy=${
            data.orderBy
          }${data.searchQuery ?? ""}&page=${data.page + 1}&pageSize=${
            data.rowsPerPage
          }&searchKeywords=${data?.searchKeywords}`
        );
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          if (error?.response?.status === 500) {
            window.location.href = routesName.errorPage;
          }
        }
        return rejectWithValue(error);
      }
    }
  );