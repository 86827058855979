import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { inputStyles } from "./mui-styles";
import "../components/components.css";
import { CalendarIconOutlined } from "./muiIconsImport";
import { useDisplayToggleProps } from "../utils/useDisplayToggle";

const DataRangeSelector = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  py = "py-[15px]",
  border = "border-gray-450",
  borderRadius = "rounded",
  shadow = false,
  position = "2xl:right-0",
}: {
  fromDate: moment.Moment | null;
  setFromDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  toDate: moment.Moment | null;
  setToDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  py?: string;
  border?: string;
  borderRadius?: string;
  shadow?: boolean;
  position?: string;
}) => {
  const [showDate, setShowDate] = useState(false);
  const [calenderTitle, setCalenderTitle] = useState("");
  useEffect(() => {
    const formYear = moment(fromDate).format("YYYY");
    const toYear = moment(toDate).format("YYYY");
    const formMonthYear = moment(fromDate).format("MMM YYYY");
    const toMonthYear = moment(toDate).format("MMM YYYY");

    if (formYear === toYear) {
      setCalenderTitle("Current Year");
    } else {
      setCalenderTitle(`${formMonthYear} - ${toMonthYear}`);
    }
  }, [fromDate, toDate]);

  const onClickRef = useRef(null);
  const insideClickRef = useRef(null);

  const useDisplayToggle = ({
    onClickRef,
    insideClickRef,
    setDisplay,
  }: useDisplayToggleProps) => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        onClickRef.current &&
        !onClickRef?.current?.contains(event.target as Node) &&
        !insideClickRef?.current?.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".MuiDateCalendar-root")
      ) {
        setDisplay(false);
      }
    };
    useEffect(() => {
      document?.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document?.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);
  };

  useDisplayToggle({
    onClickRef,
    insideClickRef,
    setDisplay: setShowDate,
  });

  return (
    <div className="relative ">
      <button
        ref={onClickRef}
        className={` ${
          showDate
            ? `border outline outline-1 border-blue-350`
            : `border hover:border-blue-350 ${border}`
        } text-primary px-5 ${py}  ${borderRadius} ${
          shadow && "shadow-sm1"
        } w-full text-16 font-medium z-10 flex items-center gap-2 justify-between overflow-y-auto whitespace-nowrap scroll_bar mt-2 bg-white`}
        type="button"
        onClick={() => {
          setShowDate((prev) => !prev);
        }}
      >
        <span className="text-blue-300">
          <CalendarIconOutlined />
        </span>
        {calenderTitle}
      </button>
      {showDate && (
        <div
          ref={insideClickRef}
          className={`absolute z-10 ${position} bg-white mt-2 shadow-sm1 p-2 rounded-lg`}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={(newValue: any) => setFromDate(newValue)}
                sx={inputStyles.select}
                slots={{
                  openPickerIcon: CalendarIconOutlined,
                }}
                format="DD/MM/YYYY"
                maxDate={moment(new Date())}
              />
              <div className="flex items-center">-</div>
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={(newValue: any) => setToDate(newValue)}
                sx={inputStyles.select}
                minDate={moment(fromDate)}
                maxDate={moment(new Date())}
                slots={{
                  openPickerIcon: CalendarIconOutlined,
                }}
                format="DD/MM/YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      )}
    </div>
  );
};

export default DataRangeSelector;
