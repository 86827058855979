import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { ChangeHandler } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { inputStyles, selectStyles } from "../mui-styles";
import { VisibilityIcon, VisibilityOffIcon } from "../muiIconsImport";

interface InputProps {
  label: string;
  id: string;
  type: string;
  register: {
    name?: string;
    onChange?: ChangeHandler;
    onBlur?: ChangeHandler;
    ref?: React.Ref<any>;
  };
  errorMessage?: string | undefined;
  error?: boolean;
  placeholder?: string;
  defaultValue?: string | undefined;
  disabled?: boolean;
}

export const DashBoardForminput: React.FC<InputProps> = ({
  label,
  id,
  type = "text",
  register,
  errorMessage,
  error = false,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
}) => {
  return (
    <>
      <TextField
        fullWidth
        id={id}
        name={id}
        label={`${label}`}
        {...register}
        type={type}
        sx={inputStyles.select}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={register.onChange}
        onBlur={register.onBlur}
        error={error || Boolean(errorMessage)}
        helperText={errorMessage}
        variant="outlined"
        className="w-[360px] h-[48px]"
        autoComplete="off"
        style={errorMessage ? { borderColor: "red" } : { borderColor: "black" }}
      />
    </>
  );
};

export const PasswordInput: React.FC<InputProps> = ({
  label,
  id,
  type = "password",
  register,
  errorMessage,
  error = false,
  placeholder = `Enter the ${label}`,
  defaultValue,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const inputLabelRef = useRef<HTMLLabelElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <FormControl fullWidth variant="outlined">
        <InputLabel
          htmlFor={id}
          style={{
            color: isFocused
              ? "#322F61"
              : errorMessage || error
              ? "#D33C52"
              : "#757B84",
              fontSize: "16px",
              fontFamily: "Inter",
          }}
          ref={inputLabelRef}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          id={id}
          sx={selectStyles}
          name={id}
          {...register}
          label={`${label}`}
          type={showPassword ? "password" : "text"}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={register.onChange}
          error={error || Boolean(errorMessage)}
          // style={errorMessage ? {borderColor:'red'}:{borderColor:'black'}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                onMouseUp={(event) => {
                  event.preventDefault();
                }}
                edge="end"
                sx={{ color: "#534FA1" }}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete="off"
        />
      </FormControl>
      {errorMessage && errorMessage != "password regex should match" && (
        <div className="">
          <span className="text-12 text-error ps-3">{errorMessage}</span>
        </div>
      )}
    </>
  );
};
