import React from "react";
import WhiteLogo from "../../assests/jeevwebsite/icons/whiteLogo.png";
import FollowUsIcon from "../../assests/jeevwebsite/icons/followUsIcon.svg";
import "./layout.css";
import "../../pages/jeevwebiste/commonJeev.css";
import LinkedInImage from "../../assests/jeevwebsite/icons/linkedin.png";
import YouTubeImage from "../../assests/jeevwebsite/icons/youtube.png";
import PmJayIcon from "../../assests/jeevwebsite/icons/pmJayIcon.png";
import Hl7Icon from "../../assests/jeevwebsite/icons/HL7Icon.png";
import MakeInIndiaIcon from "../../assests/jeevwebsite/icons/makeInIndia.png";
import BottomRightIcon from "../../assests/jeevwebsite/icons/footerBottomLeft.png";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  const contactUsFn = () => {
    if (location.pathname === "/contact") {
      window.scrollTo(0, 0);
    } else {
      navigate("/contact");
    }
  };

  return (
    <div className="relative footer_conatiner px-0 lg:px-20 md:px-10 sm:px-5 xsm:px-2 font_family">
      <div className="absolute bottom-0 left-0">
        <img
          src={BottomRightIcon}
          alt="BottomRightIcon"
          className="BottomRightIcon"
          onContextMenu={disableRightClick}
        />
      </div>
      <div className="flex flex-wrap items-start lg:px-4 md:px-2 sm:px-5 pt-4">
        <div className="w-full md:w-5/12">
          <div className="flex gap-3 items-center ps-5 sm:ps-0">
            <img
              src={WhiteLogo}
              width={54}
              height={54}
              className="footer_white_logo"
              alt="WhiteLogo"
              onContextMenu={disableRightClick}
            />
            <span className="text-white lg:text-[32px] md:text-[24px] text-[18px] font-medium jeev_text">
              jeev.ai
            </span>
          </div>
          <div className="pt-2 ps-5 sm:ps-0">
            <span className="text-white lg:text-[20px] md:text-[16px] text-[14px] font-normal ps-1">
              Empowering Healthcare Providers
            </span>
          </div>
        </div>
        <div className="hidden md:flex w-full md:w-7/12 justify-end items-center gap-6">
          <span className="want_to_know_text text-white font-normal">
            Want to know more about us?
          </span>
          <button
            type="button"
            className="contact_us_btn"
            onClick={contactUsFn}
          >
            Contact us
          </button>
        </div>
        <div className="flex md:hidden w-full justify-center my-4 items-center gap-4">
          <img
            src={PmJayIcon}
            className="footer_logos"
            alt="PmJayIcon"
            onContextMenu={disableRightClick}
          />
          <img
            src={Hl7Icon}
            className="footer_logos"
            alt="Hl7Icon"
            onContextMenu={disableRightClick}
          />
          <img
            src={MakeInIndiaIcon}
            className="MakeInIndiaIcon_footer_logos"
            alt="MakeInIndiaIcon"
            onContextMenu={disableRightClick}
          />
        </div>
      </div>
      <div className="flex flex-wrap px-2 sm:px-5 md:pt-11">
        <div className="hidden md:flex w-full md:w-6/12 flex-col gap-2">
          <div className="flex items-center gap-2">
            <span className="text-white lg:text-[20px] md:text-[18px] text-[16px] font-medium">
              Follow us
            </span>
            <img
              src={FollowUsIcon}
              className="follow_us_logo"
              alt="FollowUsIcon"
              onContextMenu={disableRightClick}
            />
          </div>
          <div className="flex gap-3 mt-2">
            <a href="https://www.linkedin.com/company/jeev-ai/" target="_blank">
              <img
                src={LinkedInImage}
                className="social_media_icons"
                alt="LinkedInImage"
                onContextMenu={disableRightClick}
              />
            </a>
            <a href="#">
              <img
                src={YouTubeImage}
                className="social_media_icons"
                alt="YouTubeImage"
                onContextMenu={disableRightClick}
              />
            </a>
          </div>
        </div>
        <div className="hidden md:flex w-full md:w-6/12 justify-end items-center gap-6">
          <img
            src={PmJayIcon}
            className="footer_logos"
            alt="PmJayIcon"
            onContextMenu={disableRightClick}
          />
          <img
            src={Hl7Icon}
            className="footer_logos"
            alt="Hl7Icon"
            onContextMenu={disableRightClick}
          />
          <img
            src={MakeInIndiaIcon}
            className="MakeInIndiaIcon_footer_logos"
            alt="MakeInIndiaIcon"
            onContextMenu={disableRightClick}
          />
        </div>
      </div>
      <div className="flex md:hidden justify-around px-2 sm:px-5">
        <div className="flex flex-col items-start gap-4">
          <span className="text-white want_to_know_text font-normal">
            Want to know more about us?
          </span>
          <button
            type="button"
            className="contact_us_btn"
            onClick={contactUsFn}
          >
            Contact us
          </button>
        </div>
        <div>
          <div className="flex items-center gap-2">
            <span className="text-white lg:text-[20px] md:text-[18px] text-[16px] font-semibold">
              Follow us
            </span>
            <img
              src={FollowUsIcon}
              className="follow_us_logo"
              alt="FollowUsIcon"
              onContextMenu={disableRightClick}
            />
          </div>

          <div className="flex gap-3 mt-3">
            <a href="https://www.linkedin.com/company/jeev-ai/" target="_blank">
              <img
                src={LinkedInImage}
                className="social_media_icons"
                alt="LinkedInImage"
                onContextMenu={disableRightClick}
              />
            </a>
            <a href="#">
              <img
                src={YouTubeImage}
                className="social_media_icons"
                alt="YouTubeImage"
                onContextMenu={disableRightClick}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-3">
        <span className="copy_right_text sm:text-[16px] text-[10px] font-normal jeev_text">
          © Copyright 2024 Jeev.ai. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
