import { useEffect, useState } from "react";
import { DashBoardForminput } from "../components/ui/dashboardForms";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginButton } from "../components/ui/loginButton";
import { emailRegex } from "../utils/regex";
import constants from "../constants";
import { useAppDispatch } from "../redux/store";
import {
  emailCheck,
  forgotPassword,
  verifyOtp,
} from "../redux/forgotPassword/forgotPasswordReducer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../components/ui/loadinSpinner";
import {
  EmailOtpDataType,
  ForgotPasswordFormData,
  otpFormType,
} from "../modals/IforgotPasswordCard";
import CloseIcon from "@mui/icons-material/Close";
import {
  resetEmailCheckState,
  resetForgotPasswordState,
  resetVerifyOtpState,
} from "../redux/forgotPassword/forgotPasswordSlice";
import ErrorModal from "../components/modals/errorModal";
import ForgotPasswordIcon from "../assests/icons/forgotPasswordIcon.svg";
import routesName from "../constants/routesName";

export default function ForgotPasswordCard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [otpValue, setOtpValue] = useState<otpFormType>({
    otp: ["", "", "", ""],
    error: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [otpverrifyError, setOtpverrifyError] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const forgotPasswordData = useSelector((data: any) => data.forgotPassword);
  const verifyOtpData = useSelector((data: any) => data.verifyOtp);
  const emailCheckData = useSelector((data: any) => data.emailCheck);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>();
  const onSubmit: SubmitHandler<ForgotPasswordFormData> = async (data) => {
    const userDetailsValue = data.email;
    const isEmail = userDetailsValue.match(emailRegex);
    const userData = {
      [isEmail ? "email" : "phone"]: isEmail
        ? userDetailsValue.toLowerCase()
        : userDetailsValue,
    };
    dispatch(emailCheck(userData));
  };

  const handleInputChange = (e: any, index: any) => {
    setOtpverrifyError("");
    setErrorMessage("");
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }
    const newOtp = [...otpValue.otp];
    newOtp[index] = value;
    setOtpValue((prev) => ({
      error: "",
      otp: newOtp,
    }));

    if (index < otpValue.otp?.length - 1 && value !== "") {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
    if (value === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };

  const verifyOtpFn: SubmitHandler<any> = async (data) => {
    if (otpValue?.otp?.every((value) => value === "")) {
      setErrorMessage("Please enter the OTP");
    } else {
      setErrorMessage("");
      const data: EmailOtpDataType = {
        email: watch("email").toLowerCase(),
        otp: otpValue.otp.join(""),
      };
      dispatch(verifyOtp(data));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (emailCheckData.status === "success") {
      setOtpModalShow(true);
      const userDetailsValue = watch("email");
      const isEmail = userDetailsValue.match(emailRegex);
      const userData = {
        [isEmail ? "email" : "phone"]: isEmail
          ? userDetailsValue.toLowerCase()
          : userDetailsValue,
      };
      dispatch(forgotPassword(userData));
      setErrorMessage("");
    } else if (emailCheckData.status === "failed") {
      if (emailCheckData?.error?.data?.message === "user not authorized") {
        setShowModal(true);
      } else if (emailCheckData?.error?.data?.title === "Unauthorized") {
        setErrorMessage("Email ID is not registered with us");
      } else {
        setErrorMessage(
          emailCheckData?.error?.data?.message ?? "Something went wrong"
        );
      }
    }
  }, [emailCheckData]);

  useEffect(() => {
    if (forgotPasswordData.status === "success") {
      setErrorMessage("");
    } else if (forgotPasswordData.status === "failed") {
      setErrorMessage(
        forgotPasswordData?.error?.data?.message ??
          "Unable to send email, Please try again"
      );
    }
  }, [forgotPasswordData]);

  useEffect(() => {
    if (verifyOtpData.status === "success") {
      navigate(routesName.resetPasswordPage, { state: { email: watch("email") } });
    } else if (verifyOtpData.status === "failed") {
      setOtpverrifyError(verifyOtpData?.error?.data?.message);
    }
  }, [verifyOtpData]);

  useEffect(() => {
    return () => {
      dispatch(resetEmailCheckState());
      dispatch(resetVerifyOtpState());
      dispatch(resetForgotPasswordState());
    };
  }, []);

  const otpsendFn = () => {
    const userDetailsValue = watch("email");
    const isEmail = userDetailsValue.match(emailRegex);
    const userData = {
      [isEmail ? "email" : "phone"]: isEmail
        ? userDetailsValue.toLowerCase()
        : userDetailsValue,
    };
    dispatch(forgotPassword(userData));
  };

  return (
    <>
      <ErrorModal
        isVisible={ShowModal}
        icon={ForgotPasswordIcon}
        onClose={() => {
          setShowModal(false);
          navigate(routesName.cmpHomePage);
        }}
        title="Forgot Your Password!"
        description="Kindly contact the management to receive the temporary password to Login"
      />
      <div className="h-screen flex items-center justify-center">
        {!ShowModal && (
          <div className="bg-white sm:w-[480px]  h-auto flex flex-col mx-3 rounded-lg relative">
            <>
              <div className="absolute top-5 right-5">
                <button
                  className=""
                  onClick={() => {
                    navigate(routesName.cmpHomePage);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              {otpModalShow ? (
                <>
                  <form
                    onSubmit={handleSubmit(verifyOtpFn)}
                    className="w-[100%] sm:p-[56px] p-[40px]"
                  >
                    <div className="flex flex-col items-center justify-center text-center">
                      <span className="text-[#212121] text-24 font-semibold">
                        {constants.otpSentText}
                      </span>
                      <span className="py-2">
                        <span className="text-16 font-normal text-[#6D7476]">
                          Enter the 4-digit OTP sent to&nbsp;
                        </span>

                        <span className="text-[#212121] font-medium">
                          {watch("email")}
                        </span>
                      </span>
                    </div>
                    <div className="flex gap-[15px] justify-center my-8">
                      {otpValue.otp.map((digit, index) => (
                        <input
                          key={index}
                          type="number"
                          id={`otp-input-${index}`}
                          value={digit}
                          onChange={(e) => handleInputChange(e, index)}
                          className={` sm:w-[72px] sm:h-[60px] w-[50px] h-[40px] text-center ${
                            otpverrifyError || errorMessage
                              ? "border-red-500/100 border-2"
                              : "border-[#6D747692] border-2"
                          }  rounded outline-none`}
                          onKeyPress={handleKeyPress}
                          onInput={(e) => {
                            const inputValue = (e.target as HTMLInputElement)
                              .value;
                            (e.target as HTMLInputElement).value =
                              inputValue.slice(0, 1);
                          }}
                        />
                      ))}
                    </div>
                    <div className="flex justify-center items-center gap-2">
                      <button
                        type="button"
                        className=""
                        onClick={() => {
                          otpsendFn();
                          setErrorMessage("");
                          setOtpverrifyError("");
                          setOtpValue((prev) => ({
                            error: "",
                            otp: ["", "", "", ""],
                          }));
                        }}
                        disabled={forgotPasswordData?.isLoading}
                      >
                        <span className="text-14 font-normal text-[#6D7476]">
                          Didn't receive code?
                        </span>

                        <span
                          className={`${
                            forgotPasswordData?.isLoading
                              ? "text-blue-250"
                              : "text-blue-300"
                          }  font-semibold text-14`}
                        >
                          &nbsp;&nbsp;Resend OTP
                        </span>
                      </button>
                      <span>
                        {forgotPasswordData?.isLoading ? (
                          <LoadingSpinner width="w-4" height="h-4" />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    {otpverrifyError && (
                      <div className="text-center">
                        <span className="text-error text-12 font-medium">
                          {otpverrifyError}
                        </span>
                      </div>
                    )}
                    {errorMessage && (
                      <div className="text-center">
                        <span className="text-error text-12 font-medium">
                          {errorMessage}
                        </span>
                      </div>
                    )}

                    <div className="flex justify-center mt-4">
                      <LoginButton
                        name={
                          verifyOtpData?.isLoading ? (
                            <span className="flex items-center gap-2">
                              <LoadingSpinner width="w-4" height="h-4" />{" "}
                              Loading...
                            </span>
                          ) : (
                            constants.verifyText
                          )
                        }
                        width="w-[100%]"
                        fontSize="text-lg"
                      />
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-[100%] p-[56px]"
                  >
                    <div className="flex justify-center">
                      <span className="text-black text-24 text-[#212121] font-semibold">
                        {constants.enterEmailText}
                      </span>
                    </div>
                    <div className="mt-8 mb-12">
                      <DashBoardForminput
                        label={constants.emailIdText}
                        id="email"
                        type="text"
                        register={{
                          ...register("email", {
                            required: constants.emailIdRequired,
                            pattern: {
                              value: emailRegex,
                              message: constants.inavalidEmail,
                            },
                            onChange: () => {
                              setErrorMessage("");
                            },
                          }),
                        }}
                        errorMessage={errors?.email?.message || errorMessage}
                        error={errorMessage ? true : false}
                      />
                    </div>

                    <div className="flex justify-center">
                      <LoginButton
                        name={
                          emailCheckData.isLoading ? (
                            <span className="flex items-center gap-2">
                              <LoadingSpinner width="w-4" height="h-4" />{" "}
                              Loading...
                            </span>
                          ) : (
                            constants.sendOtpText
                          )
                        }
                        width="w-[100%]"
                      />
                    </div>
                  </form>
                </>
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}
