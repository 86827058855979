const constanstObject = {
  // login card
  jeevText: "Jeev.ai",
  loginText: "Login",
  userNameText: "Email ID/Mobile Number",
  userNameRequired: "Email ID/Mobile Number is required",
  emailIdText: "Email ID",
  emailIdRequired: "Email ID is required",

  passwordText: "Password",
  newpasswordText: "New Password",
  passwordRequired: "Password is required",
  newpasswordRequired: "New Password is required",
  signInText: "Sign In",
  forgotPasswordText: "Forgot Password?",
  isLoginLocalStorage: "isLogin",
  userTypeLocalStorage: "userType",
  userNameLocalStorage: "userName",
  userRoleText: "userRole",
  accessTokenText: "accessToken",
  emailTextLocalStorage: "email",
  // forgot password card
  emailText: "Email",
  emailRequired: "Email is required",
  inavalidEmail: "Invalid Email ID",
  sendText: "Send",
  sendOtpText: "Send OTP",
  enterEmailText: "Enter Email ID",
  otpSentText: "OTP sent to Registered Email ID",
  verifyText: "Verify",
  setNewPassword: "Set New Password",
  confirmPassword: "Confirm Password",
  confirmNewPassword: "Confirm New Password",
  confirmPasswordRequired: "Confirm Password is required",
  confirmNewPasswordRequired: "Confirm New Password is required",

  // navbar
  logOutText: "Log Out",
  resetPasswordText: "Reset Password",

  // user management
  userListtext: "User List",
  superAdminText: "Super Admin",
  accountManagerText: "Account Manager",

  nameLabel: "Name",
  genderLabel: "Gender",
  emp_idLabel: "Emp ID",
  mobileNoLabel: "Mobile No",
  emailLabel: "E-mail",
  userTypeLabel: "User Type",
  featuresLabel: "Features",
  rolesLabel: "Roles",
  hospitalsLabel: "Hospitals",

  // general
  deleteText: "Delete",
  editText: "Edit",
  addUserText: "Add User",
  submitText: "Submit",
  resetText: "Reset",

  // user page
  notificationText: "Notification",
  roles_featuresText: "Roles/Features",
  rolesText: "Roles",
  featuresText: "Fetaures",
  hospitalText: "Hospitals",
  profile_infoText: "Profile info",
};

export default constanstObject;
