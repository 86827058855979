export const HospitalCount = (data: { [key: string]: number }) => {
  let total = 0;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      total += data[key];
    }
  }
  return total;
};
