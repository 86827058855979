import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../redux/store";
import { getHospitalUserPassword } from "../redux/addHospital/hospitalManagementReducer";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import errorToast from "../utils/errorToast";
import { resetGetHospitalUserPasswordState } from "../redux/addHospital/hospitalManagementSlice";
import addHospitalName from "../constants/hospitalManagement/addHospital";
import { LoadingSpinner } from "./ui/loadinSpinner";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { selectStyles } from "./mui-styles";
import { passwordRegex } from "../utils/regex";
import { VisibilityIcon, VisibilityOffIcon } from "./muiIconsImport";
import { InputForm } from "./forms/inputForm";

type AutoGeneratePasswordProps = {
  name: string;
  control: any;
  setValue: any;
  disabled?: boolean;
  required?: boolean;
  title?: boolean;
  clearErrors?: any;
  errors?: any;
};

const AutoGeneratePassword = ({
  name,
  control,
  setValue,
  disabled = false,
  required = true,
  title = true,
  clearErrors,
  errors,
}: AutoGeneratePasswordProps) => {
  const dispatch = useAppDispatch();
  const hospitalUserPassRef = useRef<HTMLInputElement>(null);
  const [passwordType, setPasswordType] = useState("");
  const [passwordError, setPasswordError] = useState(true);

  const handleGeneateUserPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearErrors(["type_of_password"]);
    if (e?.target?.value === "generate_password") {
      dispatch(getHospitalUserPassword());
      setPasswordType(e?.target?.value);
      setValue("type_of_password", e?.target?.value);
      clearErrors([name]);
    } else if (e?.target?.value === "create_password") {
      setPasswordError(true);
      setPasswordType(e?.target?.value);
      setValue("type_of_password", e?.target?.value);
      setValue(name, "");
    }
  };

  const hospitalUserPassRes = useSelector(
    (data: any) => data?.getHospitalUserPassword
  );

  useEffect(() => {
    if (hospitalUserPassRes?.status === "success") {
      setPasswordError(false);
      setValue(name, hospitalUserPassRes?.data?.data?.result);
    } else if (hospitalUserPassRes?.status === "failed") {
      setPasswordError(true);
      if (hospitalUserPassRef.current) {
        hospitalUserPassRef.current.checked = false;
      }
      errorToast("Something went wrong while creating Password");
    }
  }, [hospitalUserPassRes]);
  useEffect(() => {
    return () => {
      dispatch(resetGetHospitalUserPasswordState());
    };
  }, []);

  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const inputLabelRef = useRef<HTMLLabelElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputClick = () => {
    if (!passwordType) {
      errorToast("Please select password setting");
    }
  };

  return (
    <div className="">
      {title && (
        <div className="my-3">
          <span className="text-16 font-semibold text-primary">
            {addHospitalName.Password}
          </span>
        </div>
      )}

      <div className="hidden">
        <InputForm
          label={"Type of passsword"}
          id="type_of_password"
          control={control}
          required={required}
        />
      </div>

      <div className="flex items-center gap-3 my-4">
        <input
          type="radio"
          value="generate_password"
          id="generate_password"
          name="generate_password"
          checked={passwordType === "generate_password"}
          onChange={(e) => handleGeneateUserPassword(e)}
          disabled={disabled}
        />
        <label
          htmlFor="generate_password"
          className={`text-14 font-semibold ${
            errors?.type_of_password
              ? "text-error"
              : passwordType === "generate_password"
              ? "text-primary"
              : "text-secondary"
          }`}
        >
          {addHospitalName.AutogeneratePassword}
        </label>
        {hospitalUserPassRes?.isLoading && (
          <LoadingSpinner width="w-4" height="h-4" />
        )}
      </div>
      <div className="flex items-center gap-3 my-4">
        <input
          type="radio"
          value="create_password"
          id="create_password"
          name="generate_password"
          checked={passwordType === "create_password"}
          onChange={(e) => handleGeneateUserPassword(e)}
          disabled={disabled}
        />
        <label
          htmlFor="create_password"
          className={`text-14 font-semibold ${
            errors?.type_of_password
              ? "text-error"
              : passwordType === "create_password"
              ? "text-primary"
              : "text-secondary"
          }`}
        >
          {addHospitalName.CreateaPassword}
        </label>
      </div>
      <div className="">
        <div className="relative">
          {!passwordType && (
            <div
              className="absolute inset-0 z-10 cursor-pointer"
              onClick={handleInputClick}
              aria-hidden="true"
            ></div>
          )}

          <Controller
            name={name}
            control={control}
            //   defaultValue={previousData?.userPassword}
            rules={{
              required:
                passwordType === "create_password"
                  ? `Please Create or Generate Password`
                  : false,
              pattern: {
                value: passwordRegex,
                message:
                  "Atleast 8 Characters required which contain 1 Alphabet + 1 Numeric Digit + 1 Lower & Upper Case with a Special Character",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={Boolean(error?.message)}
                >
                  <InputLabel
                    htmlFor={name}
                    style={{
                      color: isFocused
                        ? "#322F61"
                        : error?.message
                        ? "#D33C52"
                        : "#757B84",
                      fontSize: "16px",
                      fontFamily: "Inter",
                    }}
                    ref={inputLabelRef}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id={name}
                    {...field}
                    label={"Password"}
                    type={showPassword ? "password" : "text"}
                    placeholder={`Enter the Password`}
                    disabled={
                      passwordType === "generate_password" ||
                      passwordType !== "create_password"
                    }
                    error={Boolean(error?.message)}
                    value={field.value || ""}
                    sx={selectStyles}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          onMouseUp={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                          sx={{ color: "#534FA1" }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    autoComplete="off"
                  />
                </FormControl>

                <div className="text-error text-12 ps-2 pt-1">
                  <span className="">{passwordError && error?.message}</span>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoGeneratePassword;
