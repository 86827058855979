interface DateProgressBarType {
  validityStart: string;
  validityEnd: string;
}

export const DateProgressBar = ({
  validityStart,
  validityEnd,
}: DateProgressBarType) => {
  const startDate = new Date(validityStart);
  const endDate = new Date(validityEnd);
  const currentDate = new Date();
  const toCompareDate = endDate < currentDate ? endDate : currentDate;

  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
  const usedDuration = toCompareDate.getTime() - startDate.getTime();
  const percentage = (usedDuration / differenceInMilliseconds) * 100;
  const millisecondsInMonth = 1000 * 60 * 60 * 24 * 30.44;
  const totalMonths = Math.round(
    differenceInMilliseconds / millisecondsInMonth
  );
  const usedMonths = Math.round(usedDuration / millisecondsInMonth);
  return (
    <div>
      <div>
        {differenceInMilliseconds <= 0 || startDate > currentDate
          ? 0
          : usedMonths}{" "}
        of {totalMonths} months
      </div>
      <div
        style={{
          width: "100%",
          borderRadius: "5px",
          overflow: "hidden",
          background: "#D7E2FF",
        }}
      >
        <div
          style={{
            width: `${
              differenceInMilliseconds <= 0 || startDate > currentDate
                ? 0
                : percentage
            }%`,
            height: "4px",
            background: "#9172B3",
          }}
        />
      </div>
    </div>
  );
};
